import React, { memo, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Label, Menu, Popup } from 'semantic-ui-react';
import GeneralHelpers from '../../../../helpers/GeneralHelpers';
import LayoutHelpers from '../../../../helpers/LayoutHelpers';
import { useFullScreen } from '../../../../context/FullScreenContext';
import { DISPLAY_TIME_FORMAT } from '../constants/DISPLAY_TIME_FORMAT';

const TimeFormatControls = ({
  t,
  controlState,
  onChange,
  popupSize = 'tiny'
}) => {
  const fullScreenContext = useFullScreen();
  const [menuPopupPosition, setMenuPopupPosition] = useState(false);

  const selectedFormat = controlState.timeDisplayUnit;

  const styles = {
    popup: {
      borderRadius: '0.28rem',
      opacity: 0.8,
    }
  }

  const buildTimeFormatList = () => {
    return Object.values(DISPLAY_TIME_FORMAT).map(tf => {
      return {
        key: tf.value,
        value: tf.value, // key is always undefined on onItemClick, so adding this to pick it up there. key is used for react rendering only
        content: t(tf.labelTranslationKey, tf.labelDefault),
        active: (tf.value === selectedFormat)
      }
    })
  }

  const timeFormatList = useMemo(buildTimeFormatList, [selectedFormat]);

  const handleChange = (e, timeFormat) => {
    GeneralHelpers.stopEvent(e);
    setIsPopupOpen(false);
    if (onChange != null) {
      onChange(timeFormat);
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const buildLabelComponent = () => {
    const selectedFormatDef = DISPLAY_TIME_FORMAT[selectedFormat];
    return (<Label
      size='medium'
      style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}
      className='mouse-pointer'
      onClick={handleMenuPopupClick}
    >
      {t(selectedFormatDef.labelTranslationKey, selectedFormatDef.labelDefault)}
    </Label>);
  }

  const handleMenuPopupClick = (e) => {
    GeneralHelpers.stopEvent(e);
    setIsPopupOpen(true)
    setMenuPopupPosition(LayoutHelpers.createRectFromEvent(e));
  };

  const labelComponent = buildLabelComponent();

  const popupMountNode = fullScreenContext?.containerRef?.current || document.body;

  return (
    <>
      <Popup
        mountNode={popupMountNode}
        trigger={labelComponent}
        on='hover'
        size={popupSize}
        offset={'[0, 5]'}
        style={styles.popup}
        content={t('ENHANCED_VIDEO_PLAYER_CONTROLS_TIMEFORMAT_ICON_TOOLTIP', 'Select time format')}
        inverted
      />
      <Popup
        mountNode={popupMountNode}
        context={{
          getBoundingClientRect: () => menuPopupPosition
        }}
        on='click'
        offset={'[0, 5]'}
        style={styles.popup}
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        inverted
      >
        <Menu
          inverted
          items={timeFormatList}
          onItemClick={(e, data) => {
            handleChange(e, data.value);
          }}
          secondary
          vertical
        />
      </Popup>
    </>
  );
};

export default memo(withTranslation()(TimeFormatControls));
