import { typeHelper } from "atom5-branching-questionnaire";

export default {
  shouldShowDefinition: (
    definition,
    subjectPathways,
    questionnaireCountByCode,
    isSuperAdmin,
    config,
    module
  ) => {
    const isInSubjectPathway =
      definition.config?.pathways == null ||
      subjectPathways?.some((subjectPathway) => {
        return definition.config?.pathways?.includes(subjectPathway);
      });

    // Never show the questionnaire if it's not in the subject's pathway
    if (!isInSubjectPathway) {
      return false;
    }

    const hideUnusedFromStaffMenu =
      typeHelper.parseBool(config.ui.questionnaires?.hideUnusedFromStaffMenu);

    if (!hideUnusedFromStaffMenu) {
      return true;
    }

    // Otherwise, always show it if the user is a super admin
    if (isSuperAdmin) {
      return true;
    }

    const supportQuestionnaireDefCode = config.ui.support?.questionnaireCode;
    if (supportQuestionnaireDefCode && supportQuestionnaireDefCode === definition.code && definition.type === "DATA") {
      const defModules = definition.config?.modules;
      if (Array.isArray(defModules) && module && defModules.includes(module)) {
        // only allow support definition to be viewed via module
        return true;
      }
      // hide support definition by default if not being viewed within module defined or has no module defined
      return false;
    }

    // Don't show to non-superadmins if a questionnaire hasn't been created already
    if (
      definition.type !== "DATA" &&
      (!questionnaireCountByCode ||
        !questionnaireCountByCode[definition.code] ||
        questionnaireCountByCode[definition.code] === 0)
    ) {
      return false;
    }
    return true;
  },
};
