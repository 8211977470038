import { QUESTION_TYPES } from 'atom5-branching-questionnaire';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Table } from 'semantic-ui-react';
import QUESTION_RENDERERS from '../../questionnaires/question-renderers/QuestionnaireRenderers';
import DateTimeService from '../../services/DateTimeService';

const ViewPreviousValuesModal = ({t, open, onClose, question, previousValues}) => {

  const previousValueEntries = Object.entries(previousValues);

  const previousValueRows = previousValueEntries
    .sort((entryA, entryB) => entryA[1].changedAt < entryB[1].changedAt)
    .map(([_id, values], index) => {
      let {changedAt, value} = values[0];
      if (question.type === QUESTION_TYPES.FIXED_VALUE) {
        value = values.map(v => v.value);
      }

      const renderer = QUESTION_RENDERERS[question.type];
      const newValueQuestion = React.createElement(renderer, {
        question,
        value,
        isReadOnly: true,
        showLabel: false,
      });

      return (
        <Table.Row>
          <Table.Cell>
            <p>{previousValueEntries.length - index}</p>
          </Table.Cell>
          <Table.Cell>
            {newValueQuestion}
          </Table.Cell>
          <Table.Cell>
            <p>{DateTimeService.build.asDisplayDateTime(changedAt)}</p>
          </Table.Cell>
        </Table.Row>
      );
    });

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{padding: 15}}
    >
      <h2>{t("DATA_QUERY_VIEW_PREVIOUS_VALUES_HEADER")}</h2>
      <p>{t("DATA_QUERY_VIEW_PREVIOUS_VALUES_DESCRIPTION")}</p>

      <Table>
        <Table.Header>
          <Table.HeaderCell>{t("DATA_QUERY_VIEW_PREVIOUS_VALUES_INDEX")}</Table.HeaderCell>
          <Table.HeaderCell>{t("DATA_QUERY_VIEW_PREVIOUS_VALUES_VALUE")}</Table.HeaderCell>
          <Table.HeaderCell>{t("DATA_QUERY_VIEW_PREVIOUS_VALUES_CHANGED_AT")}</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {previousValueRows}
        </Table.Body>
      </Table>
    </Modal>
  );
};

export default withTranslation()(ViewPreviousValuesModal);