import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withPseudoStyleHints from '../../../hocs/withPseudoStyleHints';
import { Popup } from 'semantic-ui-react';
import { useFullScreen } from '../../../../context/FullScreenContext';

const ControlButton = ({
  buttonText,
  iconName,
  iconRotationAngle,
  iconSize = 'xs',
  additionalText,
  action,
  onClick,
  pseudoStyleEvents,
  pseudoStyleHints,
  popupSize = 'tiny'
}) => {
  const fullScreenContext = useFullScreen();
  const styles = {
    icon: {
      color: pseudoStyleHints?.hover ? 'white' : 'lightGrey',
      marginLeft: 10,
      marginRight: 10,
    },
    additionalText: {
      color: pseudoStyleHints?.hover ? 'white' : 'lightGrey',
      fontSize: 10,
      marginLeft: -10
    },
    popup: {
      borderRadius: '0.28rem',
      opacity: 0.8,
    }
  }

  const handleClick = (e) => {
    if (onClick) {
      onClick(action, e);
    }
  };

  const popupMountNode = fullScreenContext?.containerRef?.current || document.body;

  return (
    <Popup
      mountNode={popupMountNode}
      trigger={
        <span>
          <FontAwesomeIcon
            {...pseudoStyleEvents}
            style={styles.icon}
            aria-label={buttonText}
            className='mouse-pointer'
            onClick={handleClick}
            size={iconSize}
            icon={['fas', iconName]}
            transform={iconRotationAngle != null ? { rotate: iconRotationAngle } : null}
          />
          {additionalText && (
            <span style={styles.additionalText}>
              {additionalText}
            </span>
          )}
        </span>
      }
      on='hover'
      content={buttonText}
      style={styles.popup}
      size={popupSize}
      inverted
    />
  );
};

export default memo(withPseudoStyleHints(ControlButton));
