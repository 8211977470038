import React, { useState } from "react";
import { Button, Confirm } from "semantic-ui-react";

const ConfirmButton = (props) => {
  const {
    onConfirm,
    onCancel,
    buttonText,
    headerText,
    contentText,
    confirmButtonText,
    cancelButtonText,
    size,
    color,
    disabled,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = () => {
    setIsOpen(false);
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const theButton = (
    <Button
      color={color ? color : "primary"}
      disabled={disabled}
      size={size}
      onClick={() => setIsOpen(true)}
    >
      {buttonText}
    </Button>
  );

  return (
    <>
      {theButton}
      <Confirm
        open={isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        header={headerText}
        content={contentText}
        confirmButton={confirmButtonText}
        cancelButton={{ content: cancelButtonText, primary: true, basic: true }}
        size={size}
      />
    </>
  );
};

export default ConfirmButton;
