import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Accordion } from "semantic-ui-react";


function ResponseErrorPanel({ t, errorResponse, showErrors, title }) {
    const [panelState, setPanelState] = useState();

    useEffect(() => {
        if (showErrors && errorResponse !== undefined) {
            setPanelState([
                {
                    key: 'Errors',
                    title: title,
                    content: {
                        content: (
                            <div>
                                <ul>
                                    {(typeof errorResponse?.message !== 'undefined') && <li key="1" >{errorResponse?.message}</li>}
                                    {errorResponse.errors?.QuestionnaireDefinition?.map((error, i) => <li key={i} >{error}</li>)}
                                    {errorResponse.errors?.QuestionDefinition?.map((error, i) => <li key={i} >{error}</li>)}
                                    {errorResponse.errors?.AnswerDefinition?.map((error, i) => <li key={i} >{error}</li>)}
                                    {errorResponse.errors?.TranslationCodeValidatorStep?.map((error, i) => <li key={i} >{error}</li>)}
                                </ul>
                            </div>
                        ),
                    },
                }]
            );
        }
    }, [showErrors, errorResponse,title]);


    return (<>
        {showErrors &&
            <div >
                <Accordion className="error" styled panels={panelState} />
            </div>
        }</>)
}

export default withTranslation()(ResponseErrorPanel);