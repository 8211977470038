import React, { memo } from 'react';
import { DISPLAY_TIME_FORMAT } from '../constants/DISPLAY_TIME_FORMAT';
import { withTranslation } from 'react-i18next';

const TimeDisplay = ({
  t,
  controlState,
  viewingProgress,
}) => {
  const duration = DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(viewingProgress.durationMilliseconds);
  const currentTime = DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(viewingProgress.currentTimeMilliseconds);
  return (
    <div style={{ color: 'white', fontSize: '0.9rem' }}>
      {currentTime} / {duration}
      {/* TODO: AT-2039 */}
      {/* <br />
      {t('ENHANCED_VIDEO_PLAYER_CONTROLS_TIMEDISPLAY_FRAME_LABEL', 'Frame')}: {Math.ceil(viewingProgress.currentFrameIndex)}
      <br />
      {viewingProgress.frameRate} {t('ENHANCED_VIDEO_PLAYER_CONTROLS_TIMEDISPLAY_FRAME_UNITS', 'fps')} */}
    </div>
  );
};

export default memo(withTranslation()(TimeDisplay));
