import React, { useEffect, useState } from "react";
import {withTranslation} from "react-i18next";
import {Button, Dropdown, Form, Grid, GridRow, Loader, Message, Table} from "semantic-ui-react";
import Page from "../../components/page/Page";
import ScreenshotService from "../../services/ScreenshotService";
import Pagination from "semantic-ui-react/dist/commonjs/addons/Pagination";
import InternationalisationService from "../../InternationalisationService";
import { saveAs } from 'file-saver'
import _ from "lodash";
import SubjectCodeLookup from "../../components/subjects/SubjectCodeLookup";
import ConfigService from "../../services/admin/ConfigService";

function ScreenshotsListPage({t}){
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [newJob, setNewJob] = useState({});
    const [screenshotJobs, setScreenshotJobs] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);

    const [branch, setBranch] = useState("master");
    const [branchOptions, setBranchOptions] = useState([
        { key: "master", value: "master", text: "master" },
        { key: "development", value: "development", text: "development" },
        { key: "test", value: "test", text: "test" },
    ]);
    const [module, setModule] = useState([]);
    const [moduleOptions, setModuleOptions] = useState([]);
    const [language, setLanguage] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [subject, setSubject] = useState(undefined);


    useEffect( () => {
        async function init() {
            try {
                setLoading(true);
                setPageSize(20);
                await deriveModules();
                
                const allLanguages = await InternationalisationService.getMobileLanguages();
                processLanguages(allLanguages);

                const pagedData = await ScreenshotService.getJobs(pageNo-1, pageSize);
                setTotalPages(pagedData.totalPages);
                setScreenshotJobs(pagedData.content);
            } catch (err) {
                setError({message: err, fatal: true});
            } finally {
                setLoading(false);
                setError({});
            }
        }
        init();
    }, [pageNo, pageSize, newJob]);

    const deriveModules = async () => {
        let nav = Window.configuration?.home?.navigation; //Use local config if we have it, electron-subject-common
        if (typeof(nav) === 'undefined'){ //get from server if not defined
            const config = await ConfigService.getClientConfig('electron-subject-mobile')
            nav = config.home.navigation;
        }
        const modules = nav.map(navItem => {
            const moduleName = navItem.screen.replace('/', '-');
            return {key: moduleName, text: moduleName, value: moduleName};
        });
        setModuleOptions(modules);
    }

    const processLanguages = (allLanguages) => {
        const languages = allLanguages.map(lang => {
            return {key: lang.code, text: lang.name, value: lang.code};
        });
        if (languages.length === 1) {
            languages.unshift({key: 'skip', text: 'skip (use when only 1 language is defined)', value: 'skip'});
        }
        setLanguageOptions(languages);
    }

    const handleBranchAddition = (e, {value}) => {
        const newOptions = [{key: value, text: value, value: value}, ...branchOptions];
        setBranchOptions(newOptions);
    }

    const handlePaginationChange = (e, { activePage }) => {
        setPageNo(activePage)
    }

    const resetNewJobInputs = () => {
        setBranch("master");
        setModule([]);
        setLanguage([]);
        setSubject(undefined);
    }

    const submitScreenshotJob = async () => {
        if (branch && module && language && subject) {
            try {
                setLoading(true)

                const response = await ScreenshotService.submitJob(branch, module.join(), language.join(), subject[0].subjectId);
                setNewJob(response);
                resetNewJobInputs();
                setLoading(false);
                setError({});
            } catch (err) {
                setLoading(false);
                if (typeof err === 'object'){
                    setError({message: err.message, fatal: true});
                }else
                {
                    setError({message: err, fatal: true});
                }
            }
        } else {
            setError({message: 'You need to select a branch, module, language and subject!', fatal: false});
        }
    }

    const handleDownloadClick = async (pipelineId, jobId) => {
        setDownloading(true);
        ScreenshotService.downloadArtifact(jobId)
            .then((blob) => {
                saveAs(blob, "screenshots-" + pipelineId + ".zip");
                setDownloading(false);
            }).catch((error) => {
                setDownloading(false);
                console.log('Error while trying to download artifact: ', error);
            });
    }

    const createTable = () => {
        const headers = [
            {title: ["ADMIN_SCREENSHOT_LIST_DATE", "Created Date & Time"], config: {width: 2}},
            {title: ["ADMIN_SCREENSHOT_LIST_STARTEDBY", "Started By"], config: {width: 2}},
            {title: ["ADMIN_SCREENSHOT_LIST_PIPELINEID", "Pipeline ID"], config: {width: 1}},
            {title: ["ADMIN_SCREENSHOT_LIST_DURATION", "Duration (secs)"], config: {width: 1}},
            {title: ["ADMIN_SCREENSHOT_LIST_STATUS", "Job Status"], config: {width: 2}},
            {title: ["ADMIN_SCREENSHOT_LIST_ACTION", "Action"], config: {width: 2}}
        ];

        const headerRow = (
            <Table.Header>
                <Table.Row>
                    {headers.map((header,index) => (
                        <Table.HeaderCell key={index} {...header?.config}>
                            {t(header.title)}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
        );

        const configListRows = screenshotJobs?.map((screenshotJob) => {
            return (
                <Table.Row
                    key={screenshotJob.id}>
                    <Table.Cell>
                        { new Date(screenshotJob.createdDate).toUTCString()}
                    </Table.Cell>
                    <Table.Cell>
                        {screenshotJob.startedBy}
                    </Table.Cell>
                    <Table.Cell>
                        {screenshotJob.pipelineId}
                    </Table.Cell>
                    <Table.Cell>
                        {screenshotJob.duration}
                    </Table.Cell>
                    <Table.Cell>
                        {screenshotJob.status}
                    </Table.Cell>
                    <Table.Cell>
                        {(screenshotJob.status === 'success' || screenshotJob.status === 'failed')  && (
                        <Button onClick={() => handleDownloadClick(screenshotJob.pipelineId, screenshotJob.jobId)} type="submit" loading={downloading} primary>
                            {t("ADMIN_SCREENSHOT_LIST_DOWNLOAD_SCREENSHOTS", "Download Screenshots")}
                        </Button>)}
                    </Table.Cell>
                </Table.Row>
            )}
        );

        return (
            <>
                {headerRow}
                <Table.Body>
                    {configListRows}
                </Table.Body>
            </>
        );
    }

    return (
        <Page
            name="Screenshot Jobs"
            header={t("ADMIN_SCREENSHOT_LIST_PAGETITLE", "Screenshot Jobs")}>
            {error && error.fatal && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={"Error, if it persist contact support:" + error.message}
                />
            )}
            {error && !error.fatal && error.message && (
                <Message
                    warning
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={error.message}
                />
            )}
            {loading && (
                <Loader active={loading} />
            )}
            {!_.isEmpty(newJob) && (
                <Message
                    positive
                    content={
                        t("ADMIN_SCREENSHOT_LIST_JOB_SUBMITTED", "Screenshot job submitted")
                    }
                />
            )}

            <Form onSubmit={submitScreenshotJob}>
                <Grid>
                    <GridRow>
                        <Grid.Column width={2}>
                            <Form.Field>
                                <label>{t("ADMIN_SCREENSHOT_BRANCH", "Branch")}</label>
                                <Dropdown
                                    name="branch"
                                    selection
                                    options={branchOptions}
                                    search
                                    allowAdditions
                                    fluid
                                    onAddItem={handleBranchAddition}
                                    value={branch}
                                    onChange={(e, data) => {
                                        e.preventDefault();
                                        setBranch(data.value);
                                    }}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field>
                                <label>{t("ADMIN_SCREENSHOT_MODULE", "Module")}</label>
                                <Dropdown
                                    name="module"
                                    selection
                                    multiple
                                    options={moduleOptions}
                                    value={module}
                                    onChange={(e, data) => {
                                        e.preventDefault();
                                        setModule(data.value);
                                    }}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field>
                                <label>{t("ADMIN_SCREENSHOT_LANGUAGE", "Language")}</label>
                                <Dropdown
                                    name="language"
                                    selection
                                    multiple
                                    options={languageOptions}
                                    value={language}
                                    onChange={(e, data) => {
                                        e.preventDefault();
                                        setLanguage(data.value);
                                    }}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Form.Field>
                                <label>{t("ADMIN_SCREENSHOT_SUBJECT", "Subject")}</label>
                                <SubjectCodeLookup
                                    onChange={(subjectAry) => {
                                        if (subjectAry?.length > 0) {
                                            setSubject(subjectAry);
                                        }
                                    }}
                                    value={subject}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Button type="submit" loading={loading} primary>
                                    {t("GLOBAL_BUTTON_SUBMIT_JOB", "Submit Job")}
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                    </GridRow>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Pagination
                                activePage={pageNo}
                                onPageChange={handlePaginationChange}
                                totalPages={totalPages}
                                ellipsisItem={totalPages > 5}
                                disabled={typeof screenshotJobs === 'undefined' || totalPages < 2}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            <Table fixed id={"adminScreenshotList"}>
                {createTable()}
            </Table>
        </Page>)

}

export default withTranslation()(ScreenshotsListPage);