import {DateTime} from 'luxon';
import i18next from 'i18next';

const DynamicTextService = {
  getDateInFormat(date, format) {
    if (format === 'WEEKDAY') {
      let day = DateTime.fromISO(date, {locale: 'en'}).toFormat('EEEE').toUpperCase();
      return i18next.t(day);
    } else {
      return DateTime.fromISO(date, {locale: i18next.language.replace('_', '-')}).toFormat(format);
    }
  },
};

export default DynamicTextService;
