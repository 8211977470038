import { createSelector, createSlice } from "@reduxjs/toolkit";
import questionnaireHelper from "./helpers/questionnaireHelper";

const subjectQuestionnairesSlice = createSlice({
  name: "questionnaires",
  initialState: {
    status: "INITIAL",
    pros: [],
    contents: [],
    events: [],
    datas: [],
    subjectTimezone: null,
  },
  reducers: {
    resetState: (state, action) => {
      state.status = "INITIAL";
      state.pros = [];
      state.contents = [];
      state.events = [];
      state.datas = [];
    },
    setQuestionnaires: (state, action) => {
      const { payload } = action;
      const { pros, contents, events, datas } = payload;

      if (pros) {
        state.pros = pros;
      }

      if (contents) {
        state.contents = contents;
      }

      if (events) {
        state.events = events;
      }

      if (datas) {
        state.datas = datas;
      }

      state.status = "LOADED";
    },
    setTimezone: (state, action) => {
      const { payload } = action;
      state.subjectTimezone = payload;
    },
  },
});

const getStatus = (store) => store.subjectQuestionnaires.status;

const getQuestionnaires = (store) => [
  ...store.subjectQuestionnaires.pros,
  ...store.subjectQuestionnaires.contents,
  ...store.subjectQuestionnaires.events,
  ...store.subjectQuestionnaires.datas,
];
const getPros = (store) => store.subjectQuestionnaires.pros;
const getContents = (store) => store.subjectQuestionnaires.contents;
const getEvents = (store) => store.subjectQuestionnaires.events;
const getDatas = (store) => store.subjectQuestionnaires.datas;

const getTimezone = (store) => store.subjectQuestionnaires.subjectTimezone;

// https://github.com/reduxjs/reselect/blob/master/README.md#q-how-do-i-create-a-selector-that-takes-an-argument
const getQuestionnaireWithCodeCreator = createSelector(
  [getQuestionnaires],
  (questionnaires) => {
    return (code) => {
      let found = questionnaires.find((def) => def.code === code);
      if (found) {
        return found;
      }
      // TODO: error dispatching
      return false;
    };
  }
);

const getCurrent = (questionnaires, subjectTimezone) => {
  return questionnaires.filter(
    (q) =>
      !questionnaireHelper.isComplete(q) &&
      questionnaireHelper.isInDate(q, subjectTimezone) &&
      !questionnaireHelper.otherQuestionnaireWithSameDefinitionAndNewerActivationToDate(
        q,
        questionnaires
      )
  );
};

const getCompleted = (questionnaires) => {
  return questionnaires.filter((q) => questionnaireHelper.isComplete(q));
};

const getCurrentFull = createSelector(
  [getQuestionnaires, getTimezone],
  getCurrent
);
const getCurrentPros = createSelector([getPros, getTimezone], getCurrent);
const getCurrentContents = createSelector(
  [getContents, getTimezone],
  getCurrent
);
const getCurrentEvents = createSelector([getEvents, getTimezone], getCurrent);
const getCurrentDatas = createSelector([getDatas, getTimezone], getCurrent);

const getCompletedFull = createSelector([getQuestionnaires], getCompleted);
const getCompletedPros = createSelector([getPros], getCompleted);
const getCompletedContents = createSelector([getContents], getCompleted);
const getCompletedEvents = createSelector([getEvents], getCompleted);
const getCompletedDatas = createSelector([getDatas], getCompleted);

export {
  getStatus,
  getQuestionnaires,
  getPros,
  getContents,
  getEvents,
  getDatas,
  getTimezone,
  getQuestionnaireWithCodeCreator,
  getCurrentFull,
  getCurrentPros,
  getCurrentContents,
  getCurrentEvents,
  getCurrentDatas,
  getCompletedFull,
  getCompletedPros,
  getCompletedContents,
  getCompletedEvents,
  getCompletedDatas,
};

export const { resetState, setQuestionnaires, setTimezone } =
  subjectQuestionnairesSlice.actions;

export default subjectQuestionnairesSlice;
