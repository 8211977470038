import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Loader,
  Message,
} from "semantic-ui-react";
import Page from "../../components/page/Page";
import AuthService from "../../services/AuthService";
import AdminAttachmentService from "../../services/admin/AdminAttachmentService";

function AdminAttachmentBlurringPage(props) {
  const [loading, setLoading] = useState(true);
  const [blurred, setBlurred] = useState(true);
  const [attachmentIds, setAttachmentIds] = useState();
  const [error, setError] = useState({});
  const [profile, setProfile] = useState({});
  const [result, setResult] = useState();

  const { t } = props;

  function parseError(e, fatal) {
    if (e.message) {
      setError({ message: e.message, fatal: fatal });
    } else if (typeof e == "string") {
      setError({ message: e, fatal: fatal });
    } else {
      setError({ message: JSON.stringify(e), fatal: fatal });
    }
  }

  const doUpdate = async () => {
    try {
      await AdminAttachmentService.updateAttachmentsBlurredFlag(attachmentIds, blurred);
      setResult(t("ADMIN_ATTACHMENT_BLURRING_UPDATED", "Attachments updated"));
    } catch (e) {
      console.log("Error setting blurred flag on attachments", e);
      parseError(e, true);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const profile = await AuthService.getMyProfile();
        setProfile(profile);

        setLoading(false);
        setResult();
      } catch (e) {
        parseError(e, true);
      } finally {
      }
    };
    return init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      name="Admin Attachment Blurring"
      header={t("ADMIN_ATTACHMENT_BLURRING", "Admin Attachment Blurring")}
    >
      {result && (
        <Message
          positive
          content={
            result
          }
        />
      )}
      {error && error.fatal && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={
            "Fatal error, if it persist contact support:" + error.message
          }
        />
      )}
      {loading && <Loader active={loading} />}
      {!loading && !profile.superAdmin && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
        />
      )}

      {!loading && profile.superAdmin && (
        <>
          <Grid columns={"equal"}>
            <Grid.Column>
              <Form onSubmit={doUpdate}>
                <Form.Field>
                  <label>
                    {t("ADMIN_ATTACHMENT_BLURRING_IDS", "Attachment ID (or comma separated list)")}
                  </label>

                  <input
                      name="attachmentIds"
                      value={attachmentIds}
                      onChange={(e) => {
                        e.preventDefault();
                        const ary = e.target.value.split(",");
                        setResult();
                        setAttachmentIds(ary);
                      }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    {t("ADMIN_ATTACHMENT_BLURRING_FLAG", "Set 'blurred' flag to")}
                  </label>

                  <Dropdown
                    name="blurred"
                    selection
                    options={[
                      { key: "true", value: true, text: "TRUE" },
                      { key: "false", value: false, text: "FALSE" },
                    ]}
                    value={blurred}
                    onChange={(e, data) => {
                      e.preventDefault();
                      setResult();
                      setBlurred(data.value);
                    }}
                  />
                </Form.Field>

                <Button type="submit" primary>
                  {t("GLOBAL_BUTTON_UPDATE", "Update")}
                </Button>
              </Form>
            </Grid.Column>
          </Grid>
        </>
      )}
    </Page>
  );
}

export default withTranslation()(AdminAttachmentBlurringPage);
