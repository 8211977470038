import React, { Component } from "react";
import { Button, Icon, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";

class CreateSubjectButton extends Component {
  constructor(props, context) {
    super(props, context);

    this._isMounted = false;

    this.state = {
      hasCreateSubjectPermission: null,
    };

    this.checkForPermission();
  }

  checkForPermission = async () => {
    const hasCreateSubjectPermission = await PermissionsService.hasPermissionInGroup(this.props.groupCode, GroupPermission.CREATE_SUBJECT);
    this.setState({ hasCreateSubjectPermission });
  };

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.groupCode !== this.props.groupCode) {
      this.checkForPermission();
    }
  };

  render() {
    const { t, groupCode } = this.props;

    if (this.state.hasCreateSubjectPermission == null) {
      return null;
    }

    return (
      <>
        {this.state.hasCreateSubjectPermission ? (
          <Button
            primary
            icon
            as={Link}
            labelPosition="left"
            to={"/app/subjects/" + groupCode + "/new"}
          >
            {t(["CREATE_SUBJECT_ADD_NEW", "GLOBAL_BUTTON_ADD_NEW"])} <Icon name="address book" />
          </Button>
        ) : (
          <Message>{t("CREATE_SUBJECT_MISSING_UPDATE_PERMISSION")}</Message>
        )}
      </>
    );
  }
}

export default withTranslation()(CreateSubjectButton);
