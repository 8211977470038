import _ from "lodash";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Segment } from "semantic-ui-react";
import CronExpressionBuilderExamplesPopup from "./CronExpressionBuilderExamplesPopup";
import CronExpressionBuilderField from "./CronExpressionBuilderField";
import cronstrue from "cronstrue";

export const CRON_CONSTANTS = {
  DB_CRON_EXPRESSION_PREFIX: "cron://",
  DB_CRON_FIELD_SEPARATOR: "_",
};

const CronExpressionBuilder = (props) => {
  const { t, initialValue, onSaveClick, onCancelClick } = props;

  const COMPLETE_EXAMPLES = {
    "At 8am every Monday": "0_0_8_*_*_MON",
    "the top of every hour of every day": "0_0_*_*_*_*",
    "8, 9 and 10 o'clock of every day": "0_0_8-10_*_*_*",
    "6:00 AM and 7:00 PM every day": "0_0_6,19_*_*_*",
    "8:00, 8:30, 9:00, 9:30, 10:00 and 10:30 every day": "0_0/30_8-10_*_*_*",
    "on the hour nine-to-five weekdays": "0_0_9-17_*_*_MON-FRI",
    "every Christmas Day at midnight": "0_0_0_25_12_?",
  };

  const EXPRESSION_FIELDS = {
    second: {
      index: 0,
      name: "second",
      defaultValue: "*",
      label: ["ADMIN_UTILS_CRONEXPRESSIONINPUT_SECOND", "second"],
    },
    minute: {
      index: 1,
      name: "minute",
      defaultValue: "*",
      label: ["ADMIN_UTILS_CRONEXPRESSIONINPUT_MINUTE", "minute"],
      possibleValues: _.range(0, 60, 5),
      examples: {
        "At the beginning of the hour": "0",
        "At 15 minutes past the hour": "15",
        "At 30 minutes past the hour": "35",
        "At 45 minutes past the hour": "45",
        "On the hour and 30 minutes past": "0,30",
        "At every 10th minute": "*/10",
        "At every 15th minute": "*/15",
        "At every 20th minute": "*/20",
      },
    },
    hour: {
      index: 2,
      name: "hour",
      defaultValue: "*",
      label: ["ADMIN_UTILS_CRONEXPRESSIONINPUT_HOUR", "hour"],
      possibleValues: _.range(0, 24),
      examples: {
        "Every Hour": "*",
        "Every Other Hour": "*/2",
        "Every Third Hour": "*/3",
        "Every Fourth Hou": "*/4",
        "Every Sixth Hour": "*/6",
        "Every Twelve Hours": "0/12",
        "Subject's Preferred Notification Time (set default!)":
          "{{#subject.notificationTime != null ? #subject.notificationTime : 9}}",
      },
    },
    dayOfTheMonth: {
      index: 3,
      name: "dayOfTheMonth",
      defaultValue: "?",
      label: [
        "ADMIN_UTILS_CRONEXPRESSIONINPUT_DAYOFTHEMONTH",
        "day Of The Month",
      ],
      possibleValues: _.range(1, 31),
      examples: {
        "Every Day": "*",
        "Every Other Day": "*/2",
        "On the 1st and 15th of the Month": "1/15",
        "On the 1st, 8th, 15th, 22nd and 29th of the Month": "1/8,15,22,29",
      },
    },
    month: {
      index: 4,
      name: "month",
      defaultValue: "*",
      label: ["ADMIN_UTILS_CRONEXPRESSIONINPUT_MONTH", "month"],
      possibleValues: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      examples: {
        "Every Month": "*",
        "Every Other Month": "*/2",
        "Every 4th Month": "*/4",
      },
    },
    dayOfTheWeek: {
      index: 5,
      name: "dayOfTheWeek",
      defaultValue: "?",
      label: [
        "ADMIN_UTILS_CRONEXPRESSIONINPUT_DAYOFTHEWEEK",
        "day Of The Week",
      ],
      possibleValues: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
      examples: {
        "Every Day": "*",
        "Every Weekday": "1-5",
        "Every Weekend Day": "6,0",
        "Every Monday, Wednesday, and Friday": "1,3,5",
        "Every Tuesday and Thursday": "2,4",
      },
    },
  };

  const getDefaultValues = () => {
    const defaults = {};
    for (var field of Object.values(EXPRESSION_FIELDS)) {
      defaults[field.name] = field.defaultValue;
    }
    return defaults;
  };

  const getExpressionFieldByIndex = (index) => {
    return Object.values(EXPRESSION_FIELDS).filter((field) => {
      return field.index === index;
    })[0];
  };

  const getExpressionFieldByName = (name) => {
    return Object.values(EXPRESSION_FIELDS).filter((field) => {
      return field.name === name;
    })[0];
  };

  const [workingValue, setWorkingValue] = useState();
  const [expressionFields, setExpressionFields] = useState(getDefaultValues());
  const [plainEnglishValue, setPlainEnglishValue] = useState("");

  const parseExpression = (expression) => {
    const parsedExpressionFields = getDefaultValues();
    if (!expression) {
      return parsedExpressionFields;
    }

    const parts = expression
      ?.replace(CRON_CONSTANTS.DB_CRON_EXPRESSION_PREFIX, "")
      .trim()
      .split(CRON_CONSTANTS.DB_CRON_FIELD_SEPARATOR);
    if (!parts || parts.length < 1) {
      return parsedExpressionFields;
    }

    for (let i = 0; i < parts.length; i++) {
      const field = getExpressionFieldByIndex(i);
      parsedExpressionFields[field.name] = parts[i].trim();
    }

    return parsedExpressionFields;
  };

  const generateExpression = () => {
    let configExpression = CRON_CONSTANTS.DB_CRON_EXPRESSION_PREFIX;
    let cronExpression = "";
    for (var field of Object.values(EXPRESSION_FIELDS)) {
      configExpression += expressionFields[field.name] || "";
      configExpression += CRON_CONSTANTS.DB_CRON_FIELD_SEPARATOR;

      cronExpression += expressionFields[field.name] || "";
      cronExpression += " ";
    }
    return {
      configExpression: configExpression.substring(
        0,
        configExpression.length - 1
      ),
      cronExpression: cronExpression.substring(0, cronExpression.length - 1),
    };
  };

  useEffect(() => {
    const fields = parseExpression(initialValue);
    setExpressionFields(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWorkingValue(undefined);
    const workingExpression = generateExpression();
    setWorkingValue(workingExpression.configExpression);
    generatePlainEnglishValue(workingExpression.cronExpression);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expressionFields]);

  const generatePlainEnglishValue = (expression) => {
    try {
      const plain = cronstrue.toString(expression);
      setPlainEnglishValue(plain);
    } catch (ex) {
      setPlainEnglishValue("");
    }
  };

  const onFieldChange = (e, value, fieldName) => {
    const field = getExpressionFieldByName(fieldName);
    const newExpressionFields = { ...expressionFields };
    newExpressionFields[field.name] = value;
    setExpressionFields(newExpressionFields);
  };

  const onExampleSelect = (e, value) => {
    const fields = parseExpression(value);
    setExpressionFields(fields);
  };

  const fieldsToRender = Object.values(EXPRESSION_FIELDS).map(
    (field, index) => {
      return (
        <CronExpressionBuilderField
          key={index}
          field={field}
          onChange={(e, value) => {
            onFieldChange(e, value, field.name);
          }}
          value={expressionFields?.[field.name]}
        />
      );
    }
  );

  return (
    <div>
      <Segment size={"big"}>
        <Form.Field>
          <span style={styles.label}>{"Original"}</span> {initialValue}
        </Form.Field>
      </Segment>
      <Segment>
        <Form>
          <Form.Group widths="equal">{fieldsToRender}</Form.Group>
          <div>{plainEnglishValue}</div>
        </Form>
      </Segment>
      <Segment size={"big"}>
        <Form.Field>
          <span style={styles.label}>{"Current expression"}</span>{" "}
          {workingValue}
        </Form.Field>
      </Segment>
      <div style={{ float: "right" }}>
        <CronExpressionBuilderExamplesPopup
          examples={COMPLETE_EXAMPLES}
          buttonText={t(
            "ADMIN_UTILS_CRONEXPRESSIONINPUT_COMPLETEEXAMPLES",
            "Complete examples"
          )}
          onSelect={onExampleSelect}
        />
      </div>
      <div>
        <Button
          primary
          onClick={(e) => {
            onSaveClick(e, workingValue);
          }}
        >
          {t("GLOBAL_BUTTON_SAVE", "Save")}
        </Button>
        <Button onClick={onCancelClick}>
          {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
        </Button>
      </div>
    </div>
  );
};

const styles = {
  label: {
    display: "inline-block",
    width: 190,
  },
};

export default withTranslation()(CronExpressionBuilder);
