import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import { Button, Form, Grid, Header, Icon, Message } from "semantic-ui-react";
import AuthService from "../../services/AuthService";
import ConfigService from "../../services/ConfigService";

class SubjectEditEmailPage extends Component {
  constructor(props, context) {
    super(props, context);
    if (ConfigService.canStaffViewAndEditSubjectEmail() === false) {
      AuthService.logout();
    }
    this.state = {
      email: "",
      subjectData: {},
      loading: true,
      errorMessage: false,
    };

    SubjectService.getSubjectData(this.props.match.params.subjectId)
      .then((subjectData) => {
        this.setState({
          email: subjectData.email || "",
          subjectData,
          loading: false,
        });
      })
      .catch(this.errorHandler);
  }


  errorHandler = (_error) => {
    let messageText;
    const { t } = this.props;

    if (_error?.message?.indexOf("Email exists") >= 0) {
      messageText = t("SUBJECT_EMAIL_EXISTS_ERROR_MESSAGE", "A patient with that email address already exists.");
    }

    if (!messageText) {
      messageText = t(
        "GLOBAL_ERROR_GENERIC_MESSAGE",
        "Please fill in all the required fields"
      );
    }
    this.setState({ loading: false, errorMessage: messageText });
  };

  onSubmit = () => {
    this.setState({ loading: true });
    return SubjectService.updateSubjectEmail(
      this.props.match.params.subjectId,
      this.state.email
    )
      .then(this.onCancel)
      .catch(this.errorHandler);
  };

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateEmail = () => {
    return AuthService.EMAIL_REGEX.test(this.state.email);
  };

  onCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { t } = this.props;

    return (
      <Page
        header={() => (
          <Header as="h3">
            <Button
              color="orange"
              style={{ padding: "0.25rem 1rem " }}
              onClick={this.onCancel}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon name="arrow left" />
                <h3 style={{ margin: "0rem", marginLeft: "0.5rem" }}>
                  {this.state.subjectData.subjectCode}
                </h3>
              </div>
            </Button>
            {" " + t("SUBJECT_EDIT_EMAIL_TITLE")}
          </Header>
        )}
        loading={this.state.loading}
      >
        {this.state.errorMessage && (
          <Message
            visible={true}
            error
            header={t("GLOBAL_ERROR_TITLE")}
            >
            {this.state.errorMessage}
          </Message>
        )}

        <Grid columns={"equal"}>
          <Grid.Column>
            <Form onSubmit={this.onSubmit}>
              <Form.Field>
                <label>{t("STAFF_FORM_EMAIL_LABEL")}</label>
                <input
                  name="email"
                  required
                  value={this.state.email}
                  onChange={this.inputChanged}
                />
              </Form.Field>

              <Button
                type="submit"
                disabled={!this.validateEmail()}
                loading={this.state.loading}
                primary
              >
                {t("GLOBAL_BUTTON_SAVE")}
              </Button>
              <Button primary basic type="button" onClick={this.onCancel}>
                {t("GLOBAL_BUTTON_CANCEL")}
              </Button>
            </Form>
          </Grid.Column>
        </Grid>
      </Page>
    );
  }
}

export default withTranslation()(SubjectEditEmailPage);
