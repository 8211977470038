import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import DateTimeField from "../../components/DateTimeField";
import withQuestionRequiredText from "../../questionnaires/question-renderers/hocs/withQuestionRequiredText";
import ScheduledItemService from "../../services/ScheduledItemService";

class SubjectVideoCallCreatePage extends Component {
  constructor(props, context) {
    super(props, context);
    
    this.state = {
      startDateValid: true,
      endDateValid: true,
      error: false,

      name: "",
      startDate: null,
      endDate: null,
    };
  }

  selectChanged = (event, option) => {
    this.setState({ [option.name]: option.value });
  };

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  onSubmit = () => {
    const startDate = moment(this.state.startDate);
    const endDate = moment(this.state.endDate);

    const now = moment();

    let startDateValid = !isNaN(startDate) && startDate > now;
    let endDateValid = !isNaN(endDate) && endDate > now;

    this.setState({startDateValid, endDateValid});

    if (!startDateValid || !endDateValid) {
      return;
    }

    const payload = {
      name: this.state.name,
      startDate: startDate / 1000,
      endDate: endDate / 1000,
      type: "videocall",
    };

    ScheduledItemService.createScheduledItem(this.props.match.params.subjectId, payload)
      .then(this.onCancel)
      .catch((error) => {
        console.error(error);
        this.setState({ error: { ...error, submitted: this.state.name } });
      });
  };

  onCancel = () => {
    window.location.href =
      window.location.origin +
      "/app/subject/" +
      this.props.match.params.subjectId +
      "/tabs/videocalls";
  };

  render() {
    const { t } = this.props;
    let errorMessage = "";
    
    if (!this.state.startDateValid) {
      errorMessage = t("VIDEO_CALL_START_DATE_INVALID");
    } else if (!this.state.endDateValid) {
      errorMessage = t("VIDEO_CALL_END_DATE_INVALID");
    } else if (this.state.error) {
      errorMessage = t("GLOBAL_ERROR_GENERIC_MESSAGE");
    }

    const submitDisabled = this.state.name === undefined;

    return (
      <Segment>
        <Form
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          error={errorMessage.length > 0}
        >
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE")}
            content={errorMessage}
          />
          <Grid columns={"equal"}>
            <Grid.Column>
              <Header>{t("VIDEO_CALL_CREATE_VIDEO_CALL_SECTION_HEADER")}</Header>
              <Form.Field>
                <label>{t("VIDEO_CALL_NAME") + ' ' + this.props.questionRequiredText}</label>
                <Form.Input
                  type="text"
                  name="name"
                  id="name"
                  required
                  onChange={this.inputChanged}
                  value={this.state.name} 
                  />
              </Form.Field>
              <DateTimeField
                label={t("VIDEO_CALL_START_DATE") + ' ' + this.props.questionRequiredText}
                min={new Date()}
                onChange={d => this.setState({startDate: d.toISOString()})}
                value={this.state.startDate}>
              </DateTimeField>
              <DateTimeField
                label={t("VIDEO_CALL_END_DATE") + ' ' + this.props.questionRequiredText}
                min={new Date()}
                onChange={d => this.setState({endDate: d.toISOString()})}
                value={this.state.endDate}>
              </DateTimeField>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column>
                <Button
                  type="submit"
                  loading={this.state.submitting}
                  disabled={submitDisabled}
                  primary
                >
                  {t("GLOBAL_BUTTON_SAVE")}
                </Button>
                {this.props.onCancel && (
                  <Button primary basic type="button" onClick={this.onCancel}>
                    {t("GLOBAL_BUTTON_CANCEL")}
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

const enhance = compose(withTranslation(), withQuestionRequiredText)

export default enhance(SubjectVideoCallCreatePage);
