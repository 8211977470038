import React from 'react';
import {NEXT_BUTTON_TEXT} from 'atom5-branching-questionnaire';

import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import AdditionalContent from './AdditionalContent';
import withContainer from "./hocs/withContainer";

const BottomButtons = ({t, showBack, onBack, nextEnabled, nextText, onNext, isBlocked, shouldShowSubmit = true, shouldHideAdditionalText, customSubmitText, definition}) => {
  const nextButtonText = (() => {
    if (nextText === NEXT_BUTTON_TEXT.NEXT) {
      return t('GLOBAL_BUTTON_NEXT');
    }
    if (customSubmitText) {
      return customSubmitText;
    }
    return t("GLOBAL_BUTTON_SUBMIT", "Submit");
  })();

  const hideNext = (!shouldShowSubmit && nextText === NEXT_BUTTON_TEXT.SUBMIT) || isBlocked;
  const additionalContent = definition?.config?.additionalContent?.label;
  const configStyle = definition?.config?.additionalContent?.style;
  const showOnlyOnLastPage = definition?.config?.additionalContent?.showOnlyOnLastPage;
  
  return (
    <>
      <div>
        {showBack && (
          <Button primary onClick={onBack}>
            {t("GLOBAL_BUTTON_BACK")}
          </Button>
        )}
        {hideNext || (
          <Button primary disabled={!nextEnabled} onClick={onNext}>
            {nextButtonText}
          </Button>
        )}
      </div>

      {additionalContent &&
        showOnlyOnLastPage &&
        nextButtonText === t("GLOBAL_BUTTON_SUBMIT", "Submit") && (
          <AdditionalContent
            content={additionalContent}
            configStyle={configStyle}
          />
        )}

      {additionalContent && !showOnlyOnLastPage && (
        <AdditionalContent
          content={additionalContent}
          configStyle={configStyle}
        />
      )}
    </>
  );
};

export default withContainer(withTranslation()(BottomButtons));