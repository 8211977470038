import React, { Component } from "react";
import { Button, Container, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";

class DateRangeControls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start: props.start,
      end: props.end,
      rangeInDays: props.end.diff(props.start, "days"),
    };

    const notifyParentOnLoad =
      this.props.notifyParentOnLoad !== undefined
        ? this.props.notifyParentOnLoad
        : true;
    if (notifyParentOnLoad) {
      this.notifyParent();
    }

    // This component is causing an overall form.subit when the primary button is pressed.
    //  this can be disabled with disableFormSubmit={true}
    this.disableFormSubmit = this.props.disableFormSubmit || false;
  }

  componentDidUpdate(prevProps) {
    // Make this component update on props.start or props.end changing
    if (
      (prevProps.start !== this.props.start)
      ||
      (prevProps.end !== this.props.end)
    )
    {
      // Ensure we have moment objects, as something passes in strings
      const start = moment.isMoment(this.props.start) ? this.props.start : moment(this.props.start);
      const end = moment.isMoment(this.props.end) ? this.props.end : moment(this.props.end);
      this.setState({
        start: start,
        end: end,
      });
    }
  }

  onChange = (event) => {
    if (!event.target.value) {
      // Don't update when field is cleared
      return;
    }

    this.setState(
      {
        [event.target.name]: moment(event.target.value),
      },
      () => {
        // Check is user has set start date after end date (or end date before start date)
        if (this.state.start > this.state.end) {
          this.setState(
            {
              start: this.state.end,
              end: this.state.start,
            },
            this.notifyParent
          );
        } else {
          this.notifyParent();
        }
      }
    );
  };

  shiftDates = (amount) => {
    this.setState(
      {
        start: this.state.start.add(amount, "days"),
        end: this.state.end.add(amount, "days"),
      },
      this.notifyParent
    );
  };

  notifyParent = () => {
    this.props.onChange(
      this.state.start.format("YYYY-MM-DD"),
      this.state.end.format("YYYY-MM-DD")
    );
  };

  render() {
    const { t } = this.props;
    return (
      <Container textAlign="center">
        <Button
          icon
          primary
          size='tiny'
          onClick={(e) => {
            this.shiftDates(-this.state.rangeInDays);
            this.disableFormSubmit && e.preventDefault();
          }}
        >
          <Icon name="arrow left" />
        </Button>
        <input
          type="date"
          name="start"
          value={this.state.start.format("YYYY-MM-DD")}
          onChange={this.onChange}
        />
        &nbsp;{t("MEDICATION_DATE_RANGE_TO")}&nbsp;
        <input
          type="date"
          name="end"
          value={this.state.end.format("YYYY-MM-DD")}
          onChange={this.onChange}
        />
        &nbsp;
        <Button
          icon
          primary
          size='tiny'
          onClick={(e) => {
            this.shiftDates(this.state.rangeInDays);
            this.disableFormSubmit && e.preventDefault();
          }}
        >
          <Icon name="arrow right" />
        </Button>
      </Container>
    );
  }
}

export default withTranslation()(DateRangeControls);

DateRangeControls.defaultProps = {
  onChange: function () {},
};
