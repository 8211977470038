import LocalStorageHelper from "../helpers/LocalStorageHelper";

export const LOCALDATA_KEYS = {
  LANGUAGE: "LANGUAGE",
  NOTIFICATION_PREFERENCE: "NOTIFICATION_PREFERENCE",
  ACCOUNT_TYPE: "ACCOUNT_TYPE",
  NOTIFICATION_DATA: "NOTIFICATION_DATA",
  HAS_HIT_LANDING_PAGE: "HAS_HIT_LANDING_PAGE",
  ATTACHMENT_SEARCH_CRITERIA: "ATTACHMENT_SEARCH_CRITERIA",
  AUDIT_SEARCH_CRITERIA: "AUDIT_SEARCH_CRITERIA",
  ADMIN_UTILS_SPELCHECKER: "ADMIN_UTILS_SPELCHECKER",
};

const LocalDataService = {
  getLanguage: function () {
    return LocalStorageHelper.getString(LOCALDATA_KEYS.LANGUAGE);
  },
  setLanguage: function (value) {
    LocalStorageHelper.setString(LOCALDATA_KEYS.LANGUAGE, value);
  },
  getAccountType: function () {
    return LocalStorageHelper.getString(LOCALDATA_KEYS.ACCOUNT_TYPE);
  },
  setAccountType: function (value) {
    LocalStorageHelper.setString(LOCALDATA_KEYS.ACCOUNT_TYPE, value);
  },
  clearAccountType: function () {
    LocalStorageHelper.deleteValue(LOCALDATA_KEYS.ACCOUNT_TYPE);
  },
  getNotificationData: function () {
    return LocalStorageHelper.getJsonObject(LOCALDATA_KEYS.NOTIFICATION_DATA);
  },
  setNotificationData: function (value) {
    LocalStorageHelper.setJson(LOCALDATA_KEYS.NOTIFICATION_DATA, value);
  },
  clearNotificationData: function () {
    LocalStorageHelper.deleteValue(LOCALDATA_KEYS.NOTIFICATION_DATA);
  },

  getHasHitLandingPage: function () {
    return LocalStorageHelper.getBoolean(
      LOCALDATA_KEYS.HAS_HIT_LANDING_PAGE,
      false
    );
  },
  setHasHitLandingPage: function (value) {
    LocalStorageHelper.setBoolean(LOCALDATA_KEYS.HAS_HIT_LANDING_PAGE, value);
  },
};

export default LocalDataService;
