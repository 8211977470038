import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import SubjectService from "../../SubjectService";
import { Button, Grid, Header, Icon, Segment, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import DateTimeService from "../../services/DateTimeService";
import MedicationComplianceGraph from "./graphs/MedicationComplianceGraph";
import MedicationNotTakenTable from "./MedicationNotTakenTable";
import AsNeededMedicationTable from "./AsNeededMedicationTable";
import DateRangeControls from "../../components/DateRangeControls";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";

class SubjectViewMedicationTab extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      hasManageMedicationPermission: false,
      medications: [],
      startDate: moment().subtract(30, "days"),
      endDate: moment(),
    };

    this.reload(false);
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.match.params.subjectId !== this.props.match.params.subjectId
    ) {
      this.reload();
    }
  };

  reload = (pageInitialised = true) => {
    if (pageInitialised) {
      this.setState({ loading: true });
    }

    SubjectService.getMedicationList(this.props.match.params.subjectId)
      .then((medications) => {
        this.setState({ medications, loading: false });
      })
      .catch(this.errorHandler);

    PermissionsService.hasPermissionForSubject(this.props.subjectData.groups, GroupPermission.MANAGE_SUBJECT_MEDICATION)
      .then((hasManageMedicationPermission) => {
        this.setState({hasManageMedicationPermission});
      });
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  edit = (medication) => {
    if (this.state.hasManageMedicationPermission) {
      this.props.history.push(
        "/app/subject/" +
          this.props.match.params.subjectId +
          "/tabs/medication/edit/" +
          medication.id
      );
    }
  };

  delete = (medication) => {
    SubjectService.deleteMedication(
      this.props.match.params.subjectId,
      medication.id
    )
      .then(() => {
        SubjectService.getMedicationList(this.props.match.params.subjectId)
          .then((medications) => {
            this.setState({ medications, loading: false });
          })
          .catch(this.errorHandler);
      })
      .catch(this.errorHandler);
  };

  onDateRangeChange = (startDate, endDate) => {
    this.setState({ startDate, endDate });
  };

  render() {
    const { t } = this.props;

    const medicationRows = this.state.medications.map((medication) => (
      <Table.Row key={medication.id} style={{ cursor: "pointer" }}>
        <Table.Cell textAlign={"left"} onClick={() => this.edit(medication)}>
          {medication.drug.name}
        </Table.Cell>
        <Table.Cell textAlign={"left"} onClick={() => this.edit(medication)}>
          {medication.dosageAmount}
        </Table.Cell>
        <Table.Cell textAlign={"left"} onClick={() => this.edit(medication)}>
          {medication.dosageUnit
            ? t("MEDICATION_DOSAGE_UNIT_" + medication.dosageUnit)
            : ""}
        </Table.Cell>
        <Table.Cell textAlign={"left"} onClick={() => this.edit(medication)}>
          {medication.dosageRoute
            ? t("MEDICATION_DOSAGE_ROUTE_" + medication.dosageRoute)
            : ""}
        </Table.Cell>
        <Table.Cell textAlign={"left"} onClick={() => this.edit(medication)}>
          {medication.dosageFrequency
            ? t("MEDICATION_DOSAGE_FREQUENCY_" + medication.dosageFrequency)
            : ""}
        </Table.Cell>
        <Table.Cell textAlign={"left"} onClick={() => this.edit(medication)}>
          {medication.startDate
            ? DateTimeService.build.asDisplayDate(medication.startDate)
            : ""}
        </Table.Cell>
        <Table.Cell textAlign={"left"} onClick={() => this.edit(medication)}>
          {medication.endDate
            ? DateTimeService.build.asDisplayDate(medication.endDate)
            : ""}
        </Table.Cell>
        <Table.Cell textAlign={"right"}>
          {this.state.hasManageMedicationPermission && (
            <Button
              type="button"
              floated="right"
              size="tiny"
              onClick={() => this.delete(medication)}
              color="red"
            >
              {t("GLOBAL_BUTTON_REMOVE")}
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <>
        <Segment style={{ overflow: "auto" }}>
          <Header as="h4">{t("MEDICATION_PRESCRIBED_MEDICATION")}</Header>

          <Table selectable columns={8} compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("MEDICATION_TABLE_HEADER_DRUG")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("MEDICATION_TABLE_HEADER_DOSAGE_AMOUNT")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("MEDICATION_TABLE_HEADER_DOSAGE_UNIT")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("MEDICATION_TABLE_HEADER_DOSAGE_ROUTE")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("MEDICATION_TABLE_HEADER_DOSAGE_FREQUENCY")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("MEDICATION_TABLE_HEADER_START_DATE")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("MEDICATION_TABLE_HEADER_END_DATE")}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"}>
                  {this.state.hasManageMedicationPermission && (
                    <Button
                      floated="right"
                      primary
                      icon
                      as={Link}
                      labelPosition="left"
                      to={
                        "/app/subject/" +
                        this.props.match.params.subjectId +
                        "/tabs/medication/new"
                      }
                    >
                      {t(["SUBJECT_TAB_ADD_NEW_MEDICATION", "GLOBAL_BUTTON_ADD_NEW"])}
                      <Icon name="syringe" />
                    </Button>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{medicationRows}</Table.Body>
          </Table>
        </Segment>

        <Segment>
          <DateRangeControls
            onChange={this.onDateRangeChange}
            start={this.state.startDate}
            end={this.state.endDate}
            align="center"
          />

          <Header as="h4">
            {t("MEDICATION_CHART_REGULAR_MEDICATION_COMPLIANCE")}
          </Header>

          <Grid>
            <Grid.Row>
              <Grid.Column largeScreen={8} mobile={16}>
                <MedicationComplianceGraph
                  subjectId={this.props.match.params.subjectId}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
              </Grid.Column>
              <Grid.Column largeScreen={8} mobile={16}>
                <Header as="h5">
                  {t("MEDICATION_CHART_REASONS_NOT_TAKEN")}
                </Header>
                <MedicationNotTakenTable
                  subjectId={this.props.match.params.subjectId}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Header as="h4">{t("MEDICATION_AS_NEEDED_MEDICATION")}</Header>

          <AsNeededMedicationTable
            subjectId={this.props.match.params.subjectId}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />
        </Segment>
      </>
    );
  }
}

export default withTranslation()(SubjectViewMedicationTab);
