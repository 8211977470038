import React, {Fragment, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Placeholder, Segment} from "semantic-ui-react";
import moment from "moment";
import {addPeriod, ensureXAxisShows} from "../../../services/helpers/graphHelper";
import SensorDataService from "../../../services/sensordata/SensorDataService";
import LineChart from "./chartcomponents/generic/LineChart";
import PeriodSelection from "./PeriodSelection";

const SubjectHeartRateGraph = ({subjectId, t}) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [heartRatesStandard, setHeartRatesStandard] = useState([]);
  const [heartRatesResting, setHeartRatesResting] = useState([]);
  const [heartRatesLogged, setHeartRatesLogged] = useState([]);
  const [fromDate, setFromDate] = useState(moment().clone().add(-1, 'week').startOf('day'));
  const [period, setPeriod] = useState('week');

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [subjectId, fromDate, period])

  const getData = async () => {
    try {
      setLoading(true)
      const toDate=addPeriod(fromDate, period)
      const dataStandard = await SensorDataService.getHeartRateInfo(subjectId, 'standard', fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'hour_avg', null, 'none');
      let heartRatesStandard = [];
      if (dataStandard.timeSeries) {
        dataStandard.timeSeries.forEach(({timestamp, value}) => {
          heartRatesStandard.push({x: timestamp, y: value});
        });
      }
      heartRatesStandard = ensureXAxisShows(heartRatesStandard, fromDate, toDate)
      setHeartRatesStandard(heartRatesStandard)

      const dataResting = await SensorDataService.getHeartRateInfo(subjectId, 'resting', fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'hour_avg', null, 'none');
      let heartRatesResting = [];
      if (dataResting.timeSeries) {
        dataResting.timeSeries.forEach(({timestamp, value}) => {
          heartRatesResting.push({x: timestamp, y: value});
        });
      }
      heartRatesResting = ensureXAxisShows(heartRatesResting, fromDate, toDate)
      setHeartRatesResting(heartRatesResting);

      const dataLogged = await SensorDataService.getHeartRateInfo(subjectId, 'logged_data', fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'hour_avg', null, 'none');
      let heartRatesLogged = [];
      if (dataLogged.timeSeries) {
        dataLogged.timeSeries.forEach(({timestamp, value}) => {
          heartRatesLogged.push({x: timestamp, y: value});
        });
      }
      heartRatesLogged = ensureXAxisShows(heartRatesLogged, fromDate, toDate);
      setHeartRatesLogged(heartRatesLogged);

      setLoading(false);
    } catch (e) {
      console.error('Error loading HR data', e);
      setError(true);
      setLoading(false);
    }
  };


  return (
      <Fragment>
        {loading && (
            <Segment>
              <Placeholder fluid>
                <Placeholder.Header image>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                </Placeholder.Paragraph>
              </Placeholder>
            </Segment>
        )}

        {!loading && (
            <>
              <PeriodSelection setFromDate={setFromDate} fromDate={fromDate} period={period} setPeriod={setPeriod} />

              {error && <p>{t("SENSOR_DATA_ERROR", "Error while fetching data, if the problem persists contact support")}</p>}
              {(!heartRatesStandard || heartRatesStandard.length===0)  && (
                  <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY", "There is currently no data to display for selected period")}</p>
              )}

              {(heartRatesStandard && heartRatesStandard.length>0)  && (

                  <LineChart
                      subjectId={subjectId}
                      dataPoints={heartRatesStandard}
                      label={t("GRAPH_HEART_RATE_STANDARD", "Hourly Avg Heart Rate")}
                      yLabel={t("GRAPH_HEART_RATE_Y_LABEL", 'bpm')}
                      color={'steelblue'}
                  />

              )}

              {(!heartRatesResting || heartRatesResting.length===0) && (
                  <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY", "There is currently no data to display for selected period")}</p>
              )}
              {heartRatesResting && heartRatesResting.length>0 && (
                  <LineChart
                      subjectId={subjectId}
                      dataPoints={heartRatesResting}
                      label={t("GRAPH_HEART_RATE_RESTING", "Hourly Avg Resting Heart Rate")}
                      yLabel={t("GRAPH_HEART_RATE_Y_LABEL", 'bpm')}
                      color={'palegreen'}
                  />
              )}

              {(!heartRatesLogged || heartRatesLogged.length===0) && (
                  <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY", "There is currently no logged data to display for selected period")}</p>
              )}
              {heartRatesLogged && heartRatesLogged.length>0 && (
                  <LineChart
                      subjectId={subjectId}
                      dataPoints={heartRatesLogged}
                      label={t("GRAPH_HEART_RATE_LOGGED", "Hourly Avg Logged Heart Rate")}
                      yLabel={t("GRAPH_HEART_RATE_Y_LABEL", 'bpm')}
                      color={'palevioletred'}
                  />
              )}
            </>
        )}
      </Fragment>
  );

}

export default withTranslation()(SubjectHeartRateGraph);
