const TRIGGER_STATE = {
  ALL: {
    value: "all",
    label: ["ADMIN_TRIGGER_FILTER_ALL", "All"],
  },
  ACTIVE: {
    value: "active",
    label: ["ADMIN_TRIGGER_FILTER_ACTIVE", "Active"],
    actionLabel: ["ADMIN_TRIGGER_FILTER_MAKE_ACTIVE", "Activate"],
  },
  INACTIVE: {
    value: "inactive",
    label: ["ADMIN_TRIGGER_FILTER_INACTIVE", "Inactive"],
    actionLabel: ["ADMIN_TRIGGER_FILTER_MAKE_INACTIVE", "Make inactive"],
  },
};

export default TRIGGER_STATE;
