import InternationalisationService from "../InternationalisationService";
import RequestHelper from "../RequestHelper";

export default class BrandingService {
  static getBrandImageForOnBoarding() {
    let imageUrl;
    if (Window?.configuration?.ui?.onboardingBrandImage) {
      imageUrl = Window?.configuration?.ui?.onboardingBrandImage;
    }
    // Workaround for legacy image location
    if (
      imageUrl === undefined ||
      imageUrl === null ||
      imageUrl === "/images/aparito-dark-text-transparent.png"
    ) {
      imageUrl =
        "/images/aparito/logo/aparito-border-darktext-transparentbg-320.png";
    }
    return imageUrl;
  }
  static getBrandImageForDashboard() {
    let imageUrl;
    if (Window?.configuration?.ui?.branding?.client?.images?.logo) {
      imageUrl = Window?.configuration?.ui?.branding?.client?.images?.logo;
    }
    if (imageUrl === undefined || imageUrl === null) {
      imageUrl = BrandingService.getAparitoLogo();
    }
    return imageUrl;
  }
  static getAparitoLogoUrl(options) {
    const defaultOptions = {
      border: 'border',
      text: 'darktext',
      background: 'transparentbg',
      width: '320',
    };
    const combinedOptions = {...defaultOptions, ...options};
    const imageUrl = `/images/aparito/logo/aparito-${combinedOptions.border}-${combinedOptions.text}-${combinedOptions.background}-${combinedOptions.width}.png`;
    return imageUrl;
  }
  static getAparitoLogo() {
    return BrandingService.getAparitoLogoUrl();
  }
  static getAparitoLogoFooter() {
    const options = {
      border: 'noborder',
    }
    return BrandingService.getAparitoLogoUrl(options);
  }
  static getAparitoLogoAlt() {
    const options = {
      text: 'whitetext',
      background: 'transparentbg',
    }
    return BrandingService.getAparitoLogoUrl(options);
  }
  static getStaticPage(key) {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/trial/static-page/" + key,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }
}
