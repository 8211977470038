import { shuffle } from "lodash";
import { QUESTION_TYPES } from "atom5-branching-questionnaire";

export default class ChartService {
  static subjectChartExport(
    document,
    chartInstance,
    subjectData,
    filenameAppend
  ) {
    if (!document || !chartInstance || !subjectData || !filenameAppend) {
      return;
    }
    const base64Image = chartInstance.toBase64Image();
    const fileName = `${subjectData.subjectCode}-${filenameAppend}.png`;
    ChartService.chartExport(base64Image, fileName);
  }

  static questionChartExport(document, chartInstance, fileName) {
    if (!document || !chartInstance || !fileName) {
      return;
    }
    const base64Image = chartInstance.toBase64Image();
    ChartService.chartExport(base64Image, fileName);
  }

  static chartExport(base64Image, fileName) {
    if (!base64Image || !fileName) {
      return;
    }
    // Comment from original implementation in all the chart components...
    // Seems strange but this is the suggested way and even react libraries
    // appear to do the same.
    var a = document.createElement("a");
    a.href = base64Image;
    a.download = fileName;
    a.click();
    a.remove();
  }

  static generateQuestionCategoryQuestionnaireComparisonData(
    questionnaireDefinition,
    questionnaireObjectArray
  ) {
    let selectedQuestions = questionnaireDefinition.questions
      .filter(ChartService._isQuestionChartable)
      .filter(ChartService._shouldShowQuestion);

    let questionLabels = selectedQuestions.map((sQ) => sQ.code);

    let datasets = questionnaireObjectArray.map(
      ({ latestQuestionnaire, subject, subjectCode }, i) => {
        const getColorWithOpacity =
          ChartService._getRandomColorWithOpacityGenerator();
        const data = selectedQuestions.map((question) => {
          return latestQuestionnaire[
            `${questionnaireDefinition.code}_${question.code}`
          ];
        });

        const dataSet = {
          label: subjectCode,
          backgroundColor: getColorWithOpacity(0.05),
          borderColor: getColorWithOpacity(1),
          data,
        };

        return dataSet;
      }
    );

    return { labels: questionLabels, datasets };
  }

  /**
   * Outputs a colour string, min and max allow for greater control over the colors
   * that are outputted.
   * @returns string - format `rgb(x,y,z)`
   */
  static _getRandomColorWithOpacityGenerator() {
    const min = 225;
    const max = 510;
    const brightColorWeight = 1 + Math.random() / 2;

    const a = Math.trunc((Math.random() * brightColorWeight * max) / 2);
    const b = Math.trunc(((Math.random() * max) / 2) * brightColorWeight);

    let minNormalisingFactor = a + b - min < 0 ? 0 : a + b - min;
    let maxNormalisingFactor = a + b - max > 0 ? 0 : -(a + b - max);

    const c = Math.trunc(
      minNormalisingFactor +
        (Math.random() * maxNormalisingFactor - minNormalisingFactor)
    );

    let values = [Math.min(a, 225), Math.min(b, 225), Math.min(c, 225)];

    values = shuffle(values);
    return (opacity) =>
      `rgba(${values[0]},${values[1]},${values[2]}, ${opacity})`;
  }

  /**
   * Checks to see if question is chartable
   * @param question
   * @returns
   */
  static _isQuestionChartable(question) {
    return (
      question.type === QUESTION_TYPES.INTEGER ||
      question.type === QUESTION_TYPES.DECIMAL ||
      question.type === QUESTION_TYPES.FIXED_VALUE ||
      question.type === QUESTION_TYPES.SLIDER
    );
  }

  /**
   * Checks to see if question is hidden from view
   * @param question
   * @returns
   */
  static _shouldShowQuestion(question) {
    if ( typeof (question?.config.showOnDashboard) != "undefined") {
      return question?.config.showOnDashboard;
    }

    if (question.hidden) {
      return false;
    }

    return true;
  }
}
