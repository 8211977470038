import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Message } from "semantic-ui-react";
import Page from "../../components/page/Page";
import AuthService from "../../services/AuthService";
import SignatureService from "../../services/SignatureService";

export class AdminUtilsDiagnosticsPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      profile: {},
      loading: false,
      successMesssage: null,
      failureMessage: null,
      redirectUrl: null,
    };

    AuthService.getMyProfile()
      .then((profile) => {
        this.setState({ profile });
      })
      .catch(() => {
        AuthService.logout();
      });
  }

  handleDocuSignDiagnosticsClick = async () => {
    this.setState({
      loading: true,
      successMesssage: null,
      failureMessage: null,
      redirectUrl: null,
    });

    try {
      const response = await SignatureService.signatureRequestDiagnostics();
      this.setState({ loading: false });

      if (response) {
        if (response.friendlyMessage) {
          this.setState({ failureMessage: response.friendlyMessage });
        } else {
          this.setState({ successMesssage: "All looks good" });
        }
        if (response.redirectUrl) {
          this.setState({ redirectUrl: response.redirectUrl });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Page
        name="Admin Utilities - Diagnostics"
        header={t("ADMIN_UTILS_HEADER")}
        loading={this.state.loading}
      >
        {this.state.profile.superAdmin && (
          <Form>
            <Form.Field>
              {this.state.successMesssage !== null && (
                <Message positive>{this.state.successMesssage}</Message>
              )}
              {this.state.failureMessage !== null && (
                <Message negative>{this.state.failureMessage}</Message>
              )}
              {this.state.redirectUrl !== null && (
                <Message positive>{this.state.redirectUrl}</Message>
              )}

              <h2>DocuSign</h2>
              <Button
                type="button"
                primary
                size="large"
                onClick={this.handleDocuSignDiagnosticsClick}
                disabled={this.state.loading}
                loading={this.state.loading}
              >
                DocuSign
              </Button>
            </Form.Field>
          </Form>
        )}
        {!this.state.profile.superAdmin &&
          t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN")}
      </Page>
    );
  }
}

export default withTranslation()(AdminUtilsDiagnosticsPage);
