import ConfigService from "../../../services/ConfigService";

const isTriggerValidForManualDelivery = (triggerId) => {
  if (!ConfigService.enforceTriggerAuditLogQuestionnaireConstraint()) {
    return true;
  }

  if (typeof (triggerId) !== 'undefined' && triggerId !== '' && !isNaN(triggerId)) {
    return true
  }

  return false;
}

export default isTriggerValidForManualDelivery;