/**
 * @deprecated Use typeHelper from atom5-branching-questionnaire instead
 */
export default class TypeHelper {
  /**
   * @deprecated Use typeHelper.parseBool from atom5-branching-questionnaire instead
   */
  static parseBool(inputValue) {
    return inputValue === true || inputValue === "true" ? true : false;
  }
}
