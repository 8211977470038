import { typeHelper } from "atom5-branching-questionnaire";

export default class GeneralHelpers {
  static stopEvent(e) {
    if (e == null) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
  }

  static arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  static round(value, dp = 2) {
    if (dp == null || !typeHelper.isNumber(value)) {
      return null;
    }
    if (dp < 0) {
      return value;
    }
    const pow = Math.pow(10, dp);
    return Math.round((value + Number.EPSILON) * pow) / pow;
  }

  static errorToString(e) {
    if (!e) {
      return 'Error not specified';
    }
    if (e && e.message) {
      return e.message;
    }
    var errMessage = JSON.stringify(e);
    if (errMessage == null || errMessage === '{}' || errMessage === '') {
      if (e && e.message) {
        errMessage = e.message;
      } else {
        errMessage = e;
      }
    }
    return errMessage;
  }

}
