import {useCallback, useEffect, useMemo, useReducer, useState} from 'react'
import {createNewTranslationsArrayForQuestion, createNewTranslationsArrayForAnswers} from "../components/admin/questionnaireEditor/helpers/createNewTranslationsArray";
import QuestionnaireDefinitionService from "../services/admin/QuestionnaireDefinitionService";
import {bySequenceAndById} from "../helpers/sortFunctions";
import {useParams} from "react-router-dom";
import InternationalisationService from "../InternationalisationService";


const ACTION_TYPES = {
  RESET: "RESET",
  EDIT_QUESTIONNAIRE_DEFINITION: "EDIT_QUESTIONNAIRE_DEFINITION",
  ADD_QUESTION_DEFINITION: "ADD_QUESTION_DEFINITION",
  EDIT_QUESTION_DEFINITION: "EDIT_QUESTION_DEFINITION",
  ADD_TRANSLATION_EDIT: "ADD_TRANSLATION_EDIT",
  MOVE_QUESTION: "MOVE_QUESTION",
  TOGGLE_FOCUS: "TOGGLE_FOCUS"
};

const useCompleteQuestionnaireDefinitionInformation = () => {
  const { questionnaireDefinitionId } = useParams();

  // fetch definition data
  const [definitionData, setDefinitionData] = useState(null);
  const getDefinitonData = useCallback(async () => {
    const questionnaireDefinitionData =
        await QuestionnaireDefinitionService.getQuestionnaireDefinitionData(
            questionnaireDefinitionId
        );

    // in place sorting of questions into display order
    const questions = questionnaireDefinitionData.questionnaireDefinition.questions;
    questions.sort(
        bySequenceAndById
    )

    setDefinitionData(questionnaireDefinitionData);
  }, [questionnaireDefinitionId]);
  useEffect(() => getDefinitonData(), [getDefinitonData]);

  // get languages
  const [languages, setLanguages] = useState(null)
  const getLanguages = useCallback(async () => {
    const languages = await InternationalisationService.getLanguages()
    setLanguages(languages)
  }, [])
  useEffect(()=>{
    getLanguages()
  }, [getLanguages])

  // state management for save call
  const editorStateReducer = (state, action) => {
    switch (action.type) {
      case ACTION_TYPES.RESET:
        if (action.payload === null) return state;
        return {
          questionnaireDefinition: action.payload.questionnaireDefinition,
          translationArray: action.payload.serverTranslations,
          changedTranslationArray: [],
          focusedQuestions: [],
        };
      case ACTION_TYPES.EDIT_QUESTIONNAIRE_DEFINITION:
        return {
          ...state,
          questionnaireDefinition: action.payload,
        };
      case ACTION_TYPES.ADD_TRANSLATION_EDIT:
        const newTranslationObject = action.payload;
        const translationArray = [...state.translationArray];
        const changedTranslationArray = [...state.changedTranslationArray];

        // Replace or add in the changed translation array
        const presentChangedTranslationIndex = changedTranslationArray.findIndex(tO => {
          return tO.language === newTranslationObject.language && tO.code === newTranslationObject.code
        });
        if(presentChangedTranslationIndex === -1){
          changedTranslationArray.push(newTranslationObject);
        }  else {
          changedTranslationArray.splice(presentChangedTranslationIndex, 1, newTranslationObject);
        }

        // replace in the translation array
        const presentTranslationIndex = translationArray.findIndex(tO => {
          return tO.language === newTranslationObject.language && tO.code === newTranslationObject.code
        });
        if(presentTranslationIndex !== -1){
          translationArray.splice(presentTranslationIndex, 1, newTranslationObject);
        }

        return {
          ...state,
          translationArray: translationArray,
          changedTranslationArray: changedTranslationArray,
        };
      case ACTION_TYPES.ADD_QUESTION_DEFINITION:
        const newQuestion = action.payload;
        const questionnaireDefinitionAQD = {...state.questionnaireDefinition};
        const questionsAQD = [...questionnaireDefinitionAQD.questions, newQuestion];
        questionnaireDefinitionAQD.questions = questionsAQD;

        // add required translations
        const resultingTranslationObjectArray = [...state.changedTranslationArray]
        const newTranslationObjectArray = createNewTranslationsArrayForQuestion(
            state.questionnaireDefinition.code,
            newQuestion.code,
            newQuestion.type,
            languages,
            []       // shouldnt be needed if creating a new set of translations
        );
        if(newQuestion?.answers?.length){
          newTranslationObjectArray.push(createNewTranslationsArrayForAnswers(
              state.questionnaireDefinition.code,
              newQuestion.code,
              newQuestion.answers,
              languages,
              []    // shouldnt be needed if creating a new set of translations
          ));
        }

        resultingTranslationObjectArray.push(...newTranslationObjectArray)

        return {
          ...state,
          changedTranslationArray: resultingTranslationObjectArray,
          questionnaireDefinition: questionnaireDefinitionAQD
        }

      case ACTION_TYPES.EDIT_QUESTION_DEFINITION:
        const editedQuestion = action.payload;
        const questionnaireDefinitionEQD = {...state.questionnaireDefinition};
        const questionsEQD = [...questionnaireDefinitionEQD.questions];
        const changedIndex = questionsEQD.findIndex(q => {
          return q.code === editedQuestion.code
        });

        if(changedIndex === -1) return state;

        const [oldQuestion] = questionsEQD.splice(changedIndex, 1, editedQuestion);
        questionnaireDefinitionEQD.questions = questionsEQD;

        const resultingTranslationObjectArrayEQD = [...state.changedTranslationArray]
        if(editedQuestion.type !== oldQuestion.type){
          resultingTranslationObjectArrayEQD.push(...createNewTranslationsArrayForQuestion(
              state.questionnaireDefinition.code,
              editedQuestion.code,
              editedQuestion.type,
              languages,
              [...state.changedTranslationArray, ...state.translationArray]
          ));
        }
        if(editedQuestion?.answers?.length){
          resultingTranslationObjectArrayEQD.push(...createNewTranslationsArrayForAnswers(
              state.questionnaireDefinition.code,
              editedQuestion.code,
              editedQuestion.answers,
              languages,
              [...state.changedTranslationArray, ...state.translationArray]
          ));
        }

        return {
          ...state,
          changedTranslationArray: resultingTranslationObjectArrayEQD,
          questionnaireDefinition: questionnaireDefinitionEQD,
        };
      case ACTION_TYPES.MOVE_QUESTION:
        const {index, direction} = action.payload;
        if(index + direction < 0 || index + direction >= state.questionnaireDefinition.questions.length){
          return state;
        }

        const questionnaireDefinitionMQ = {...state.questionnaireDefinition};
        const questionsMQ = questionnaireDefinitionMQ
            .questions
            .map((q, i)=>{
              return {...q, sequence: i}
            });

        // direction should be 1 or -1 depending on direction
        questionsMQ[index].sequence = questionsMQ[index].sequence + direction;
        questionsMQ[index+direction].sequence = index;
        questionsMQ.sort((a, b) => a.sequence - b.sequence)
        questionnaireDefinitionMQ.questions = questionsMQ;
        return {
          ...state,
          questionnaireDefinition: questionnaireDefinitionMQ,
        };
      case ACTION_TYPES.TOGGLE_FOCUS:
        const focusedQuestions = [...state.focusedQuestions]
        const questionCodeIndex = focusedQuestions.findIndex((fQ) => fQ === action.payload);
        if(questionCodeIndex === -1){
          focusedQuestions.push(action.payload);
        } else {
          focusedQuestions.splice(questionCodeIndex, 1);
        }

        return {
          ...state,
          focusedQuestions
        }
      default:
        console.log("[useCompleteQuestionnaireDefinitionInformation] Action type not found for ", action.type)
    }
  };

  const [state, dispatch] = useReducer(editorStateReducer, {
    questionnaireDefinition: null,
    translationArray: null,
    changedTranslationArray: null,
    focusedQuestions: null,
  });
  useEffect(() => {
    dispatch({ type: ACTION_TYPES.RESET, payload: definitionData });
  }, [definitionData]);


  const hasLoaded = useMemo(() => {
    return [
      state.questionnaireDefinition,
      state.translationArray,
      languages
    ].every((v) => v !== null);
  }, [state, languages]);

  return [hasLoaded, state, dispatch, ACTION_TYPES]
}

export {ACTION_TYPES};
export default useCompleteQuestionnaireDefinitionInformation;