import React, { memo, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Input, TextArea } from 'semantic-ui-react';
import { v4 as uuid_v4 } from "uuid";
import { DISPLAY_TIME_FORMAT } from '../constants/DISPLAY_TIME_FORMAT';
import GeneralHelpers from '../../../../helpers/GeneralHelpers';

const AnnotationAddEditForm = ({
  t,
  controlState,
  annotationOptions,
  annotation: passedAnnotation,
  newAnnotationStartTime,
  newAnnotationEndTime,
  onSave
}) => {
  console.log('AnnotationAddEditForm LOAD');
  const newAnnotation = {
    id: uuid_v4(),
    timeStart: newAnnotationStartTime,
    timeEnd: newAnnotationEndTime
  };

  const [annotation, setAnnotation] = useState({
    ...newAnnotation,
    ...passedAnnotation
  });

  const start = DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeStart);
  const end = DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeEnd);

  let timeString = start.toString();
  if (annotation.timeEnd != null && annotation.timeStart !== annotation.timeEnd) {
    timeString += ` - ${end}`;
  }

  const buildCategoryOptions = () => {
    const options = Object.values(annotationOptions?.categories)
      .map(category => {
        return {
          key: category.code,
          text: t(category.titleTranslationKey, category.titleDefault, category.code),
          value: category.code,
        };
      });
    return options;
  };

  const handleSave = (e) => {
    GeneralHelpers.stopEvent(e);
    if (onSave) {
      onSave(annotation);
    }
  }

  const categoryOptions = useMemo(buildCategoryOptions, [annotationOptions?.categories]);

  return (
    <Form>
      <Form.Field>
        <label>{t("TODO:", "Time")}</label>
        <Input
          value={timeString || ''}
          name={"time"}
          fluid
          required
          disabled
          transparent
        />
      </Form.Field>
      <Dropdown
        fluid
        selection
        options={categoryOptions}
        onChange={(_e, data) => setAnnotation({ ...annotation, category: data.value })}
        value={annotation.category}
      />
      <Form.Field>
        <label>{t("TODO:", "Name")}</label>
        <Input
          value={annotation.name || ''}
          name={"name"}
          fluid
          required
          onChange={(_e, data) => setAnnotation({ ...annotation, name: data.value })}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("TODO:", "Notes")}</label>
        <TextArea
          name={"notes"}
          style={{ minHeight: 200 }}
          value={annotation.notes || ''}
          onChange={(_e, data) => setAnnotation({ ...annotation, notes: data.value })}
        />
      </Form.Field>

      <Button onClick={handleSave} primary>
        {t(["TODO", "GLOBAL_BUTTON_SUBMIT"], "Submit")}
      </Button>

    </Form>
  );
};

export default memo(withTranslation()(AnnotationAddEditForm));
