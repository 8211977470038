import RequestHelper from "../../RequestHelper";

const WorkflowService = {
  addNewQuestionnaireWorkflowDefinition: (jsonValue) => {
    const requestBody = { json: jsonValue };
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-workflow/new",
      {'Accept':'text/plain'},
      "POST",
      null,
      requestBody,
    );
  },
  fetchAllWorkflowDefinitions: () => {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-workflow/list",
        {},
        "GET",
        {}
    );
  },
  getQuestionnaireWorkflowDefinitionJsonExport: (id) => {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-workflow/export",
        {},
        "GET",
        {id: id}
    );
  },
}

export default WorkflowService;
