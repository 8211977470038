import SubjectService from "../../SubjectService";
import { Button, Header, Icon, Placeholder, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import Questionnaire from "../../questionnaires/Questionnaire";
import Page from "../../components/page/Page";
import ConfigContext from "../../context/ConfigContext";

class SubjectDataQuestionnairePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.initialise();
  }

  initialise = () => {
    SubjectService.getSubjectData(this.props.match.params.subjectId).then(
      (subjectData) => {
        this.setState({ subjectData });
      }
    );
    SubjectService.getSubjectDataQuestionnaireDefinitions()
      .then((questionnaireDefinitions) => {
        this.setState({
          definition: questionnaireDefinitions.find(
            (definition) =>
              definition.code === this.props.match.params.definitionCode
          ),
          loading: false,
        });
      })
      .catch(this.errorHandler);

    return {
      error: false,
      loading: true,
      subjectData: { subjectCode: "" },
    };
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.match.params.definitionCode !==
        this.props.match.params.definitionCode ||
      prevProps.match.params.subjectId !== this.props.match.params.subjectId
    ) {
      this.setState(this.initialise());
    }
  };

  onSubmit = (answers) => {
    const { subjectId, definitionCode } = this.props.match.params;
    let answerMap = {};
    Object.entries(answers).forEach((entry) => {
      answerMap[definitionCode + "_" + entry[0]] = entry[1];
    });

    const supportDefCode = this.context.ui?.support?.questionnaireCode;
    if (definitionCode === supportDefCode) {
      SubjectService.createSupportQuestionnaire(subjectId, definitionCode, answerMap)
        .then(() => {
          this.props.history.goBack();
        })
        .catch(this.errorHandler);
    } else {
      SubjectService.createQuestionnaire(subjectId, definitionCode, answerMap)
        .then(() => {
          this.props.history.goBack();
        })
        .catch(this.errorHandler);
    }
  };

  render() {
    return (
      <Page
        name="SUBJECT_VIEW_QUESTIONNAIRE"
        header={() => (
          <Header as="h3">
            <Button
              color="orange"
              style={{ padding: "0.25rem 1rem " }}
              onClick={() => this.props.history.goBack()}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon name="arrow left" />
                <h3 style={{ margin: "0rem", marginLeft: "0.5rem" }}>
                  {this.state.subjectData.subjectCode}
                </h3>
              </div>
            </Button>
          </Header>
        )}
        loading={this.state.loading}
      >
        <Segment>
          {this.state.loading && (
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          )}

          {!this.state.loading && (
            <Questionnaire
              definition={this.state.definition}
              onSubmit={this.onSubmit}
              subjectId={this.props.match.params.subjectId}
            />
          )}
        </Segment>
      </Page>
    );
  }
}

SubjectDataQuestionnairePage.contextType = ConfigContext;

export default withTranslation()(SubjectDataQuestionnairePage);
