import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { updateLogLevel} from '../../../services/admin/LoggingConfigService'
import {
    Button, Dropdown,
    Form,
    Grid, Image, Modal
} from "semantic-ui-react";

const UpdateLogLevelModal = ({open, onClose, t}) => {
    const [logName, setLogName] = useState('com.aparito.atom5.nucleus.app.questionnaire.trigger')
    const [logLevel, setLogLevel] = useState('INFO')
    const doSave = async () => {
        try {
            await updateLogLevel(logName, logLevel)
            onClose()
        }catch (e) {
            console.error("Error updating log level",e)
        }
    }

    return (
    <Modal
        size={"small"}
        open={open}
        onClose={onClose}
        
    >
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <Image
                            style={{ height: "25px" }}
                            src={
                                "../../images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal.Content scrolling style={{height:'120em'}}>
               
                <Form>
                    <Form.Field>
                        <label>{t("ADMIN_LOG_NAME", "Log name")}</label>
                        <Dropdown
                            name="logLevel"
                            selection
                            options={[
                                {
                                    key:"com.aparito.atom5.nucleus.app.questionnaire.trigger",
                                    value:"com.aparito.atom5.nucleus.app.questionnaire.trigger",
                                    text:"com.aparito.atom5.nucleus.app.questionnaire.trigger"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.questionnaire.expression",
                                    value:"com.aparito.atom5.nucleus.app.questionnaire.expression",
                                    text:"com.aparito.atom5.nucleus.app.questionnaire.expression"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.questionnaire",
                                    value:"com.aparito.atom5.nucleus.app.questionnaire",
                                    text:"com.aparito.atom5.nucleus.app.questionnaire"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.subject",
                                    value:"com.aparito.atom5.nucleus.app.subject",
                                    text:"com.aparito.atom5.nucleus.app.subject"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.staff",
                                    value:"com.aparito.atom5.nucleus.app.staff",
                                    text:"com.aparito.atom5.nucleus.app.staff"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.attachment",
                                    value:"com.aparito.atom5.nucleus.app.attachment",
                                    text:"com.aparito.atom5.nucleus.app.attachment"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.processingservice.domain.process.garmin",
                                    value:"com.aparito.atom5.nucleus.app.processingservice.domain.process.garmin",
                                    text:"com.aparito.atom5.nucleus.app.processingservice.domain.process.garmin"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.translation",
                                    value:"com.aparito.atom5.nucleus.app.translation",
                                    text:"com.aparito.atom5.nucleus.app.translation"
                                }, 
                                {
                                    key:"com.aparito.atom5.nucleus.app.communication",
                                    value:"com.aparito.atom5.nucleus.app.communication",
                                    text:"com.aparito.atom5.nucleus.app.communication"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.processingservice.domain.notification",
                                    value:"com.aparito.atom5.nucleus.app.processingservice.domain.notification",
                                    text:"com.aparito.atom5.nucleus.app.processingservice.domain.notification"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.export",
                                    value:"com.aparito.atom5.nucleus.app.export",
                                    text:"com.aparito.atom5.nucleus.app.export"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.auth",
                                    value:"com.aparito.atom5.nucleus.app.auth",
                                    text:"com.aparito.atom5.nucleus.app.auth"
                                },
                                {
                                    key:"com.aparito.atom5.nucleus.app.util",
                                    value:"com.aparito.atom5.nucleus.app.util",
                                    text:"com.aparito.atom5.nucleus.app.util"
                                },
                                {
                                    key:"com.aparito",
                                    value:"com.aparito",
                                    text:"com.aparito"
                                },
                                {
                                    key:"org.springframework.web",
                                    value:"org.springframework.web",
                                    text:"org.springframework.web"
                                },
                                {
                                    key:"org.hibernate.engine",
                                    value:"org.hibernate.engine",
                                    text:"org.hibernate.engine"
                                },
                                {
                                    key:"org.hibernate.SQL_SLOW",
                                    value:"org.hibernate.SQL_SLOW",
                                    text:"org.hibernate.SQL_SLOW (needs hibernate 4.5.4)"
                                },
                                {
                                    key:"org.springframework.security.oauth2",
                                    value:"org.springframework.security.oauth2",
                                    text:"org.springframework.security.oauth2"
                                },
                                {
                                    key:"org.springframework.security",
                                    value:"org.springframework.security",
                                    text:"org.springframework.security"
                                }


                            ]}
                            value={logName}
                            onChange={(e, data) => {
                                e.preventDefault()
                                setLogName(data.value)
                            }}
                        />
                        
                    </Form.Field>
                    <Form.Field>
                        <label>{t("ADMIN_LOG_LEVEL", "Log level")}</label>
                        <Dropdown
                            name="logLevel"
                            selection
                            options={[
                                {
                                    key:"TRACE",
                                    value:"TRACE",
                                    text:"TRACE"
                                },
                                {
                                    key:"DEBUG",
                                    value:"DEBUG",
                                    text:"DEBUG"
                                },
                                {
                                    key:"INFO",
                                    value:"INFO",
                                    text:"INFO"
                                },
                                {
                                    key:"WARN",
                                    value:"WARN",
                                    text:"WARN"
                                },
                                {
                                    key:"ERROR",
                                    value:"ERROR",
                                    text:"ERROR"
                                },
                                {
                                    key:"OFF",
                                    value:"OFF",
                                    text:"OFF"
                                }
                            ]}
                            value={logLevel}
                            onChange={(e, data) => {
                                e.preventDefault()
                                setLogLevel(data.value)
                            }}
                        />
                    </Form.Field>
                    
                </Form>
            </Modal.Content>
            <Grid centered padded>
                <Grid.Row>
                    <Button
                        color={"orange"}
                        size="small"
                        disabled={!logName || logName.length===0}
                        onClick={() => doSave()}
                    >
                        {t("GLOBAL_BUTTON_SAVE", "Save")}
                    </Button>
                    <Button
                        basic
                        color={"orange"}
                        size="small"
                        onClick={onClose}
                    >
                        {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                    </Button>
                </Grid.Row>
            </Grid>

    </Modal>
    )
}

export default withTranslation()(UpdateLogLevelModal);
