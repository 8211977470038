import React, { useContext, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import SubjectService from "../../SubjectService";
import { Message } from "semantic-ui-react";
import MultiQuestionnaire from "../../questionnaires/MultiQuestionnaire";
import TrialService from "../../TrialService";
import ConfigContext from "../../context/ConfigContext";
import UserContext from "../../context/UserContext";
import { getEventDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import { compose } from "redux";
import { connect } from "react-redux";
import DashboardContext from "../../context/DashboardContext";

const SubjectScreeningPage = (props) => {
  const { t, eventDefinitions } = props;

  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const [error, setError] = useState(false);
  const [
    doesSubjectNeedToCompleteScreeningQuestionnaires,
    setDoesSubjectNeedToCompleteScreeningQuestionnaires,
  ] = useState(true);

  const dashboardContext = useContext(DashboardContext);
  const handlePageChange = () => {
    dashboardContext.scrollToTop();
  };

  useEffect(() => {
    determineScreeningQuestions();
  }, [user]);

  const determineScreeningQuestions = async () => {
    const needToCompleteScreeningQuestionnaires =
      await SubjectService.doesSubjectNeedToCompleteScreeningQuestionnaires();
    setDoesSubjectNeedToCompleteScreeningQuestionnaires(
      needToCompleteScreeningQuestionnaires
    );
  };

  const screeningQuestionnaires = SubjectService.getScreeningQuestionnaires(
    eventDefinitions,
    config.ui.selfOnboarding
  );

  const onScreeningQuestionnaireCompleted = (questionnaireAnswerMaps) => {
    const requestBodyJson = {};
    requestBodyJson.questionnaireAnswerMaps = questionnaireAnswerMaps;
    try {
      TrialService.getCurrentTrial().then(async (trial) => {
        SubjectService.selfOnboardSubjectSubmitScreeningQuestionnaires(
          requestBodyJson
        ).then((res) => {
          user.refreshAuthState();
        });
      });
    } catch (error) {
      setError(true);
    }
  };

  if (
    !doesSubjectNeedToCompleteScreeningQuestionnaires ||
    !screeningQuestionnaires ||
    screeningQuestionnaires.length < 1
  ) {
    if (user.isLoggedIn) {
      return <Redirect to="/app/subject/study-consent" />;
    } else {
      return <Redirect to="/login" />;
    }
  }

  return (
    <div style={{ maxWidth: 850, padding: "20px" }}>
      {error && <Message error>{t("CONSENT_ERROR_MESSAGE")}</Message>}
      {!error && screeningQuestionnaires && (
        <div>
          <MultiQuestionnaire
            questionnaires={screeningQuestionnaires}
            onPageChange={handlePageChange}
            onSubmit={onScreeningQuestionnaireCompleted}
            shouldGroupAnswersByQuestionnaire={true}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    eventDefinitions: getEventDefinitions(state),
  };
};

const enhance = compose(connect(mapStateToProps), withTranslation());

export default enhance(SubjectScreeningPage);
