import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import AutoComplete from "./AutoComplete";
import BottomButtons from "./BottomButtons";
import ChartIcon from "./ChartIcon";
import Date from "./Date";
import DateTime from "./DateTime";
import MonthYear from "./MonthYear";
import Decimal from "./Decimal";
import Diary from "./Diary";
import FixedValue from "./FixedValue";
import Heading from "./Heading";
import Image from "./Image";
import Integer from "./Integer";
import Link from "./Link";
import Markup from "./Markup";
import MonitoredVideo from "./MonitoredVideo";
import Paragraph from "./Paragraph";
import RenderImage from "./RenderImage";
import RenderVideo from "./RenderVideo";
import Signature from "./Signature";
import SingleFixedValue from "./SingleFixedValue";
import SliderSwitch from "./SliderSwitch";
import Text from "./Text";
import TextArea from "./TextArea";
import Time from "./Time";
import Video from "./Video";
import File from "./File";
import Authorise from "./Authorise";

const QUESTION_RENDERERS = {};

QUESTION_RENDERERS[QUESTION_TYPES.AUTO_COMPLETE] = AutoComplete;
QUESTION_RENDERERS[QUESTION_TYPES.BOTTOM_BUTTONS] = BottomButtons;
QUESTION_RENDERERS[QUESTION_TYPES.CHART_ICON] = ChartIcon;
QUESTION_RENDERERS[QUESTION_TYPES.DATE] = Date;
QUESTION_RENDERERS[QUESTION_TYPES.DATETIME] = DateTime;
QUESTION_RENDERERS[QUESTION_TYPES.DECIMAL] = Decimal;
QUESTION_RENDERERS[QUESTION_TYPES.DIARY] = Diary;
QUESTION_RENDERERS[QUESTION_TYPES.FIXED_VALUE] = FixedValue;
QUESTION_RENDERERS[QUESTION_TYPES.HEADING] = Heading;
QUESTION_RENDERERS[QUESTION_TYPES.IMAGE] = Image;
QUESTION_RENDERERS[QUESTION_TYPES.INTEGER] = Integer;
QUESTION_RENDERERS[QUESTION_TYPES.LINK] = Link;
QUESTION_RENDERERS[QUESTION_TYPES.MARKUP] = Markup;
QUESTION_RENDERERS[QUESTION_TYPES.MONITORED_VIDEO] = MonitoredVideo;
QUESTION_RENDERERS[QUESTION_TYPES.MONTHYEAR] = MonthYear;
QUESTION_RENDERERS[QUESTION_TYPES.PARAGRAPH] = Paragraph;
QUESTION_RENDERERS[QUESTION_TYPES.RENDER_IMAGE] = RenderImage;
QUESTION_RENDERERS[QUESTION_TYPES.RENDER_VIDEO] = RenderVideo;
QUESTION_RENDERERS[QUESTION_TYPES.SIGNATURE] = Signature;
QUESTION_RENDERERS[QUESTION_TYPES.SINGLE_FIXED_VALUE] = SingleFixedValue;
QUESTION_RENDERERS[QUESTION_TYPES.SLIDER] = SliderSwitch;
QUESTION_RENDERERS[QUESTION_TYPES.TEXT] = Text;
QUESTION_RENDERERS[QUESTION_TYPES.TEXTAREA] = TextArea;
QUESTION_RENDERERS[QUESTION_TYPES.TIME] = Time;
QUESTION_RENDERERS[QUESTION_TYPES.VIDEO] = Video;
QUESTION_RENDERERS[QUESTION_TYPES.FILE] = File;
QUESTION_RENDERERS[QUESTION_TYPES.AUTHORISE] = Authorise;

export default QUESTION_RENDERERS;
