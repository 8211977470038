import React, { useEffect, useState, useContext } from "react";
import {withTranslation} from "react-i18next";
import {Button, Icon, Loader, Message, Table} from "semantic-ui-react";
import Page from "../../components/page/Page";
import UserContext from "../../context/UserContext";
import DigestAlertService from "../../services/DigestAlertService";

const AdminDigestAlertListPage = ({ t }) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({});
    const [forceReload, setForceReload] = useState("");

    const user = useContext(UserContext);
    const isSuperAdmin = user.profile.superAdmin;

    useEffect( () => {
        async function init() {
            try {
                setLoading(true);
                const data = await DigestAlertService.getUnsentDigestAlerts();
                setPageData(data);
            } catch (err) {
                setError(true)
            } finally {
                setLoading(false);
            }
        }
        init();
    }, [forceReload]);

    const timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    const forceSendAllDigestAlerts = async () => {
        await DigestAlertService.forceSendAllDigestAlerts();
        //add a delay as the email sending is async and my not have finished in time
        setLoading(true);
        await timeout(2000);
        setLoading(false);
        setForceReload(new Date().toString());
    };

    const createTable = () => {

        const headers = [
            {title: ["ADMIN_DIGEST_ALERT_EMAIL", "Sending to"], config: {width: 1}},
            {title: ["ADMIN_DIGEST_ALERT_DIGEST_COUNT", "Number of digest emails"], config: {width: 1}},
            {title: ["ADMIN_DIGEST_ALERT_DIGEST_ACTION", "Actions"], config: {width: 1}}
        ];

        const headerRow = (
            <Table.Header>
                <Table.Row>
                    {headers.map((header,index) => (
                        <Table.HeaderCell key={index} {...header?.config}>
                            {t(header.title)}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
        );

        const dataListRows = pageData?.map((item) => {
            return (
                <Table.Row key={item.id}>
                    <Table.Cell >
                        {item.email}
                    </Table.Cell>
                    <Table.Cell >
                        {item.numberOfAlerts}
                    </Table.Cell>
                    <Table.Cell >
                        <Button
                            primary
                            icon
                            labelPosition="left"
                        >
                            {t("ADMIN_DIGEST_ALERT_SEND_INDIVIDUAL", "Send now (not implemented yet)")}
                            <Icon name="send" />
                        </Button>
                    </Table.Cell>
                </Table.Row>
            )}
        );

        return (
            <>
                {headerRow}
                <Table.Body>
                    {dataListRows}
                </Table.Body>
            </>
        );
    }

    return (
        <Page
            name="Unsent Email Digest Alerts"
            header={t("ADMIN_DIGEST_ALERT_PAGE_TITLE", "Admin Digest Email Alerts")}>
            {error && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={"Error, if it persist contact support:" + error.message}
                />
            )}
            {loading && (
                <Loader active={loading} />
            )}
            {!isSuperAdmin && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
                />
            )}

            {!loading && pageData.length > 0 && (
                <>
                    <Table fixed id={"adminDigestAlertList"}>
                        {createTable()}
                    </Table>

                    <Button
                        primary
                        icon
                        labelPosition="left"
                        onClick={forceSendAllDigestAlerts}
                    >
                        {t("ADMIN_DIGEST_ALERT_SEND_ALL", "Send all unsent digest emails now")}
                        <Icon name="send" />
                    </Button>
                </>
            )}

            {!loading && pageData.length === 0 && (
                <Table fixed id={"adminDigestAlertList"}>
                    <Table.Row>
                        <Table.Cell >{t("NO_DIGEST_EMAILS_FOUND", "No digest emails scheduled to be sent!")}</Table.Cell>
                    </Table.Row>
                </Table>
            )}

            <div style={{marginTop:20}}>
                {t("ADMIN_DIGEST_ALERT_NAVIGATE_TO_SEARCH", "Cant find what you're looking for? Try searching the ")}
                <a href="/app/utils/notification-auditactivity">
                    {t("ADMIN_DIGEST_ALERT_AUDIT_LOGS", "Admin Notification Activity Audit")}
                </a>
            </div>
        </Page>)

}

export default withTranslation()(AdminDigestAlertListPage);
