import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Message,
  Segment,
} from "semantic-ui-react";
import GroupPermission from "../../GroupPermission";
import SubjectGroupSelect from "./SubjectGroupSelect";
import PermissionsService from "../../services/PermissionsService";

class SubjectEditGroupsPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      subjectData: {
        subjectCode: "",
        groups: [],
        subjectRecords: [],
      },
      viewOnlyGroups: [],
      selectedGroupCodes: [],
      loading: true,
      error: false,
    };

    SubjectService.getSubjectData(this.props.match.params.subjectId)
      .then(async (subjectData) => {
        const hasManageSubjectGroupsPermission = PermissionsService.hasPermissionForSubject(subjectData.groups, GroupPermission.MANAGE_SUBJECT_GROUPS);
        let selectedGroupCodes = [];
        let viewOnlyGroups = [];
        subjectData.groups.forEach((subjectGroup) => {
          if (hasManageSubjectGroupsPermission) {
            selectedGroupCodes.push(subjectGroup.code);
          } else {
            viewOnlyGroups.push(subjectGroup);
          }
        });
        this.setState({
          subjectData,
          selectedGroupCodes,
          viewOnlyGroups,
          loading: false,
        });
      })
      .catch(this.errorHandler);
  }

  errorHandler = (error) => {
    console.error(error);
    this.setState({ error: true });
  };

  onGroupSelected = (selectedGroupCodes) => {
    this.setState({ selectedGroupCodes });
  };

  onSubmit = () => {
    let groupCodes = this.state.viewOnlyGroups
      .map((group) => group.code)
      .concat(this.state.selectedGroupCodes);

    if (groupCodes.length === 0) {
      this.setState({ error: true, errorMsg: "NO_GROUP_ERROR" });
    } else {
      SubjectService.updateSubjectGroups(
        this.props.match.params.subjectId,
        groupCodes
      )
        .then(() => {
          window.location.href =
            window.location.origin +
            "/app/subject/" +
            this.props.match.params.subjectId +
            "/tabs/subject-record/details";
        })
        .catch(this.errorHandler);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Page
        header={() => (
          <Header as="h3">
            <Button
              color="orange"
              style={{ padding: "0.25rem 1rem " }}
              onClick={() => this.props.history.goBack()}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon name="arrow left" />
                <h3 style={{ margin: "0rem", marginLeft: "0.5rem" }}>
                  {this.state.subjectData.subjectCode}
                </h3>
              </div>
            </Button>
            {" " + t("SUBJECT_EDIT_GROUPS_TITLE")}
          </Header>
        )}
        // header={
        //   t("SUBJECT_EDIT_GROUPS_TITLE") +
        //   " " +
        //   this.state.subjectData.subjectCode
        // }
        loading={this.state.loading}
      >
        {this.state.error && (
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE")}
            content={t(
              this.state.errorMsg
                ? this.state.errorMsg
                : "GLOBAL_ERROR_GENERIC_MESSAGE"
            )}
          />
        )}

        <Grid columns={"equal"}>
          <Grid.Column>
            <Form onSubmit={this.onSubmit}>
              <SubjectGroupSelect
                class="ui padded"
                initialGroupsSelected={this.state.selectedGroupCodes}
                onGroupSelected={this.onGroupSelected}
                requiredPermission={GroupPermission.MANAGE_SUBJECT_GROUPS}
              />

              {this.state.viewOnlyGroups.length > 0 && (
                <Form.Field>
                  <p>{t("SUBJECT_EDIT_GROUPS_VIEWABLE_ONLY")}</p>
                  <Segment>
                    {this.state.viewOnlyGroups.map((group) => (
                      <Label key={group.id}>{group.label}</Label>
                    ))}
                  </Segment>
                </Form.Field>
              )}

              <Button type="submit" primary>
                {t("GLOBAL_BUTTON_SAVE")}
              </Button>
            </Form>
          </Grid.Column>
        </Grid>
      </Page>
    );
  }
}

export default withTranslation()(SubjectEditGroupsPage);
