import React, { useState, useRef } from "react";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-moment";
import { Line, Scatter } from "react-chartjs-2";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Button, Dropdown } from "semantic-ui-react";
import SubjectService from "../../../../../SubjectService";
import ChartService from "../../../../../services/ChartService";

const CHART_TYPES = {
  LINE: "LINE",
  SCATTER: "SCATTER",
};

ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const LineChart = (props) => {
  const { t, subjectId, dataPoints, label, color, yLabel} = props;
  let [chartType, setChartType] = useState(CHART_TYPES.LINE);
  let chartRef = useRef(null);

  let subjectData;
  SubjectService.getSubjectData(subjectId).then((data) => {
    subjectData = data;
  });

  let dataSets = [];
  if (dataPoints) {
    dataSets.push({
      label: label,
      borderColor: color,
      backgroundColour: color,
      fill: "none",
      pointRadius: 4,
      pointHoverRadius: 6,
      data: dataPoints,
    });
  }

  const exportFunction = () => {
    ChartService.subjectChartExport(
        document,
        chartRef.current,
        subjectData,
        "chart-"+label
    );
  };

  const chartOptions = [
    {
      key: CHART_TYPES.LINE,
      text: t("CHART_LINE"),
      value: CHART_TYPES.LINE,
    },
    {
      key: CHART_TYPES.SCATTER,
      text: t("CHART_SCATTER"),
      value: CHART_TYPES.SCATTER,
    },
  ];

  let options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            second: "LTS",
            minute: "LT",
            hour: "LT",
            day: "ll",
            week: "ll",
            month: "ll",
            quarter: "ll",
            year: "YYYY",
          },
        },

        scaleLabel: {
          display: true,
        },
      },
      y: {
        ticks: {
          beginAtZero: false,
        },
        title: {
          display: true,
          text: yLabel,
        },
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const date = moment(tooltipItem.xLabel).format("LLLL");
          return "[" + date + "]:  " + tooltipItem.yLabel;
        },
      },
    },
  };

  const data = {
    datasets: dataSets,
  };

  return (
      <>
        {chartType === CHART_TYPES.LINE && (
            <Line data={data} options={options} ref={chartRef} />
        )}
        {chartType === CHART_TYPES.SCATTER && (
            <Scatter data={data} options={options} ref={chartRef} />
        )}
        <div
            style={{
              padding: "12px 0",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
        >
          <Dropdown
              placeholder={t("CHART_LINE")}
              selection
              onChange={(e, obj) => {
                setChartType(obj.value);
              }}
              options={chartOptions}
          />
          <div>
            <Button
                primary
                onClick={() => {
                  exportFunction();
                }}
            >
              {t("CHART_EXPORT")}
            </Button>
          </div>
        </div>
      </>
  );
};

export default withTranslation()(LineChart);
