import React, {useMemo, useState} from 'react';
import {Button, Dropdown, Modal} from "semantic-ui-react";
import LanguageSelectionGeneric from "../../languageselection/LanguageSelectionGeneric";
import {useTranslation} from "react-i18next";
import LookupEditCard from "./LookupEditCard";
import LookupCreateCard from "./LookupCreateCard";
import LookupItemCreateCard from "./LookupItemCreateCard";
import FileSaver from 'file-saver';
import LookupService from "../../../services/LookupService";

const MODAL_MODES = {
  HIDDEN: "HIDDEN",
  EDIT: "EDIT",
  CREATE: "CREATE",
  CREATE_ITEM: "CREATE_ITEM",
}

const LookupEditorComponent = ({lookupArray, lookup, language, setLanguage, refreshLookups, onLookupChange}) => {
  const {t} = useTranslation()

  const [modalMode, setModalMode] = useState(MODAL_MODES.HIDDEN)
  const [bytesRead,setBytesRead] = useState('');
  const [downloading,setDownloading] = useState(false);
  const isModalOpen = useMemo(
      ()=> modalMode !== MODAL_MODES.HIDDEN,
      [modalMode]
  );

  const lookupOptions = useMemo(()=>{
    return lookupArray.map(lookup => {
      return {
        key: lookup.code,
        text: lookup.name,
        value: lookup.code,
      };
    })
  }, [lookupArray])
  const handleLookupSelect = (e,v) => {
    const newSelectedLookup = lookupArray.find(lookup => lookup.code === v.value);
    if(newSelectedLookup){
      onLookupChange && onLookupChange(newSelectedLookup)
    }
  }

  let timeoutId = 0;
    //ref https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
    const formatBytes = (bytes,decimals) => {
        if(bytes === 0) return '0 Bytes';
        const k = 1024,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
    }

    const downloadProgress = (progress) => {
        clearTimeout(timeoutId);
        if (!downloading) {
            setDownloading(true);
        }
        setBytesRead(formatBytes(progress.transferred,0));
        timeoutId = setTimeout(() => {
            setBytesRead('');
            setDownloading(false);
        }, 3000)
    }

    const handleExport = async () => {
        try{
            const csvContent = await LookupService.downloadLookupItems(language, lookup?.code,downloadProgress);
            let blob = new Blob([csvContent], {
                type: "application/octet-stream"
            });
            let jsDate = new Date();
            FileSaver.saveAs(blob, "lookupitems-"+lookup?.code+"-"+language+ + jsDate.toISOString().slice(0, 19) + ".csv");
        }catch(response){
            console.log("Error exporting lookup items:" + response);
        }
    }

  return <div style={{display: "flex"}}>
    <LanguageSelectionGeneric language={language} callback={setLanguage} useMobileLanguages useWebLanguages />
    <Dropdown selection options={lookupOptions} value={lookup.code} onChange={handleLookupSelect} style={{marginLeft: "6px", marginRight: "6px"}} />
    <Button primary onClick={()=>{setModalMode(MODAL_MODES.EDIT)}}>{t("LOOKUP_EDIT", "Edit Lookup")}</Button>
    <Button primary onClick={()=>{setModalMode(MODAL_MODES.CREATE)}}>{t("LOOKUP_NEW", "Add New Lookup")}</Button>
    <Button primary onClick={()=>{setModalMode(MODAL_MODES.CREATE_ITEM)}}>{t("LOOKUP_ITEM_NEW", "Add New Lookup Item Set")}</Button>
      { !downloading && <Button primary onClick={()=>{handleExport()}}>{t("LOOKUP_ITEM_DOWNLOAD", "Download") + '\n' + bytesRead}</Button>}
      { downloading && <Button primary ><i className="loading spinner icon"></i>{bytesRead}</Button>}
    <Modal open={isModalOpen} onClose={()=>setModalMode(MODAL_MODES.HIDDEN)}>
      {modalMode === MODAL_MODES.EDIT && <LookupEditCard
          language={language}
          lookup={lookup}
          onSubmit={() => {
            setModalMode(MODAL_MODES.HIDDEN)
            refreshLookups && refreshLookups();
          }}
      />}
      {modalMode === MODAL_MODES.CREATE && <LookupCreateCard
          language={language}
          lookup={lookup}
          onSubmit={() => {
            setModalMode(MODAL_MODES.HIDDEN)
            refreshLookups && refreshLookups();
          }}
      />}
      {modalMode === MODAL_MODES.CREATE_ITEM && <LookupItemCreateCard
          language={language}
          lookup={lookup}
          onSubmit={() => {
            setModalMode(MODAL_MODES.HIDDEN)
            refreshLookups && refreshLookups();
          }}
      />}
    </Modal>
  </div>
}

export default LookupEditorComponent;