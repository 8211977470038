import React, { useState, useEffect } from "react";
import { Card, Loader, List, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import DateTimeService from "../../services/DateTimeService";

function StaffCard(props) {
    const { t } = props;
    const staffMember = props.staffEntity;
    const groupLabels = props.groupLabels;
    const roleLabels = props.roleLabels;
    const [isLoading, setIsLoading] = useState(true);
    const [staff, setStaff] = useState();
    const [groupMappings, setGroupMappings] = useState();

    const loadStaffDetails = async () => {
        try {
            setIsLoading(true);
            setGroupMappings(staffMember.groupMappings);
            setStaff(staffMember);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadStaffDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        <Loader inline={true} active={isLoading} />
        {!isLoading && (
            <Card style={{width:'100%'}}>
                <Card.Content>
                    {(staff.createdDate || staff.endDate) && (
                    <>
                        <Card.Meta>
                        {t("STAFF_DETAILS_HEADING", "Staff details")}
                        </Card.Meta>
                        <Card.Description>
                            {staff.createdDate && (<Card.Content>
                                {t("STAFF_CARD_CREATION_DATE", "Staff creation date")}: 
                                    {DateTimeService.build.asDisplayDateTime(staff.createdDate)}
                            </Card.Content>)}
                            {staff.endDate && (
                            <Card.Content>
                                {t("STAFF_CARD_END_DATE", "Staff end date")}:  
                                    {DateTimeService.build.asDisplayDateTime(staff.endDate)}
                            </Card.Content>)}
                            <Card.Content></Card.Content>
                        </Card.Description>
                    </>)}
                    <Card.Meta style={{marginTop:'5px'}}>
                        {t("GROUPS_AND_ROLES_CARD_DESCRIPTION", "Groups and associated Roles")}
                    </Card.Meta>
                    <Card.Description>
                        <List divided>
                            {groupMappings.length === 0 && (<div>{t("GROUPS_ROLES_NOTHING_TO_DISPLAY", "No groups or roles availiable for this staff member")}</div>)}
                            {groupMappings.length !== 0 && groupMappings.sort((a, b) => a.group.code > b.group.code ? 1 : -1)
                                          .map((groupRole) => (
                            <List.Item key={groupRole.group}>
                                <List.Content>
                                <List.Description>
                                    {groupLabels[groupRole.group.code]}: {" "}
                                    {groupRole.roles
                                        .sort((a, b) => a.code > b.code ? 1 : -1)
                                        .map((role, i, arr) => {
                                            let comma = (i !== (arr.length-1) ? ',' : '');
                                            let label = roleLabels[role.code] 
                                                ? roleLabels[role.code] + comma
                                                : role.code.charAt(0).toUpperCase() + role.code.slice(1) + comma;
                                            return <Label>{label}</Label>;
                                    })}
                                </List.Description>
                                </List.Content>
                            </List.Item>
                            ))}
                        </List>
                    </Card.Description>
                </Card.Content>
            </Card>
          )}
        </>
    );
}

export default withTranslation()(StaffCard);
