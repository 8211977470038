import React, {useState} from 'react';
import {Button, Card} from "semantic-ui-react";
import DefinitionOnlyQuestionnaireDisplay from "../../questionnaires/DefinitionOnlyQuestionnaireDisplay";
import {useTranslation} from "react-i18next";

const CardDisplay = ({definitions}) => {
  const {t} = useTranslation()
  const [selectedDefinition, setSelectedDefinition] = useState(null);

  return <Card style={{overflowX: 'hidden'}}>
    {!selectedDefinition &&
    <>
      <h3 style={{paddingLeft: '1em', paddingTop: '8px'}}>{t("HELP_SECTION_TITLE", "Help")}</h3>
      <Button.Group vertical>
        {definitions?.map(definition => <Button style={{backgroundColor: 'white', color: 'orange', textAlign: 'left'}} onClick={()=>setSelectedDefinition(definition)} content={definition.label} />)}
      </Button.Group>
    </>}
    {selectedDefinition && <div >
      <Button style={{backgroundColor: 'white', color: 'orange'}} onClick={()=>setSelectedDefinition(null)} icon={'arrow left'} />
      <div style={{overflowY: 'scroll', maxHeight: '66vh',padding: '16px', paddingTop: '0px', marginRight: '-8px', }}>
        <DefinitionOnlyQuestionnaireDisplay definition={selectedDefinition} />
      </div>
    </div>}
  </Card>;
}

const HelpSectionDisplay = ({definitions}) => {
  const [isOpen, setIsOpen] = useState(false);
  return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
    {isOpen && <CardDisplay definitions={definitions}  />}
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
      <Button primary circular size={'huge'} style={{boxShadow: '0px 0px 18px 0px rgba(0,0,0,0.2)'}} onClick={()=>setIsOpen(!isOpen)} icon={'help'} />
    </div>
  </div> ;
}

export default HelpSectionDisplay;