import moment from "moment";
import React, {useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Header, Icon, Segment, Table } from "semantic-ui-react";

import VideoCall from "../../components/videocall/VideoCall";
import GroupPermission from "../../GroupPermission";
import PermissionsService from "../../services/PermissionsService";
import ScheduledItemService from "../../services/ScheduledItemService";

const VIDEO_CALL_SCHEDULED_ITEM_TYPE = "videocall"; // maybe this wants moving into a separate file if scheduled items are more common?

const SubjectViewVideoCallsTab = ({t, subjectData, match}) => {

  const [items, setItems] = useState([]);
  const [showVideoCall, setShowVideoCall] = useState(false);
  const [hasManageScheduledItemsPermission, setHasManageScheduledItemsPermission] = useState(false);

  useEffect(() => {
    (async () => {
      const allItems = await ScheduledItemService.listScheduledItems(
        VIDEO_CALL_SCHEDULED_ITEM_TYPE,
        match.params.subjectId
      );
      setItems(allItems);
      const hasPermission = await PermissionsService.hasPermissionForSubject(
        subjectData.groups,
        GroupPermission.MANAGE_SUBJECT_SCHEDULED_ITEMS
      );
      setHasManageScheduledItemsPermission(hasPermission);
    })();
  }, [match.params.subjectId, subjectData.groups]);

  const identity = "Clinician";

  const createButton = (
    <Button
      floated="right"
      primary
      icon
      as={Link}
      labelPosition="left"
      to={"/app/subject/" + match.params.subjectId + "/tabs/videocalls/new"}
    >
      {t(["SUBJECT_TAB_ADD_NEW_VIDEOCALL", "GLOBAL_BUTTON_ADD_NEW"])}
      <Icon name="plus" />
    </Button>
  );

  const now = moment().valueOf();

  const deleteItem = (i, itemIndex) => {
    ScheduledItemService.deleteScheduledItem(i.id, match.params.subjectId);
    const updatedItems = [...items];
    updatedItems.splice(itemIndex, 1);
    setItems(updatedItems);
  };

  const tableRows = items.map((i, itemIndex) => {
    const startDate = moment(i.startDate).valueOf();
    const endDate = moment(i.endDate).valueOf();

    const canJoinCall = startDate < now && now < endDate;

    // leaving this for now, but this being a IIFE looks weird and might need changing
    const actionButton = ((canJoinCall, now) => {
      if (canJoinCall) {
        return (
          <Button
            floated="right"
            primary
            icon
            labelPosition="left"
            onClick={() => setShowVideoCall(true)}
          >
            {t("SUBJECT_VIDEO_CALL_JOIN")}
            <Icon name="call" />
          </Button>
        );
      } else {
        if (now <= startDate) {
          return (
            <Button
              floated="right"
              color="red"
              icon
              labelPosition="left"
              onClick={() => {
                deleteItem(i, itemIndex);
              }}
            >
              {t("GLOBAL_BUTTON_REMOVE")}
              <Icon name="trash" />
            </Button>
          );
        }
      }
    })(canJoinCall, now);

    return (
      <Table.Row key={i.id}>
        <Table.Cell>{i.name}</Table.Cell>
        <Table.Cell>{new Date(startDate).toLocaleString()}</Table.Cell>
        <Table.Cell>{new Date(endDate).toLocaleString()}</Table.Cell>
        <Table.Cell>{actionButton}</Table.Cell>
      </Table.Row>
    );
  });

  const table = (
    <Table selectable columns={4} compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ minHeight: "40px" }}>
            {t("SUBJECT_VIEW_VIDEO_CALLS_TABLE_NAME")}
          </Table.HeaderCell>
          <Table.HeaderCell style={{ minHeight: "40px" }}>
            {t("SUBJECT_VIEW_VIDEO_CALLS_TABLE_START_DATE")}
          </Table.HeaderCell>
          <Table.HeaderCell style={{ minHeight: "40px" }}>
            {t("SUBJECT_VIEW_VIDEO_CALLS_TABLE_END_DATE")}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {hasManageScheduledItemsPermission && createButton}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {tableRows}
      </Table.Body>
    </Table>
  );

  return (
    <>
      {showVideoCall ? (
        <Segment style={{ overflow: "auto" }}>
          <Header as="h4">{t("SUBJECT_VIDEO_CALL_HEADER")}</Header>
          <VideoCall identity={identity} subjectId={match.params.subjectId} onLeave={() => setShowVideoCall(false)}/>
        </Segment>
      ) : (
        <Segment style={{ overflow: "auto" }}>
          <Header as="h4">{t("SUBJECT_VIEW_VIDEO_CALLS_HEADER")}</Header>
          {table}
        </Segment>
      )}
    </>
  );
};

export default withTranslation()(SubjectViewVideoCallsTab);
