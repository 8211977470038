import React, { useContext, useMemo } from "react";
import UserContext from "../../context/UserContext";
import GroupsContext from "../../context/GroupsContext";
import GroupService from "../../services/GroupService";
import GroupPermission from "../../GroupPermission";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import { Button, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";

function GroupsEditPage({ t }) {
  const user = useContext(UserContext);
  const groups = useContext(GroupsContext);

  const groupsWithManageGroupsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        user.profile,
        GroupPermission.MANAGE_GROUPS,
      ),
    [user]
  );

  const groupsWithUpdateGroupMetadataPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        user.profile,
        GroupPermission.UPDATE_GROUP_METADATA,
      ),
    [user]
  );

  const editableGroups = groups.map((group) => {
    if (user.profile.superAdmin) {
      return {group, manageGroup: true, updateMetadata: true}
    }

    const manageGroup = groupsWithManageGroupsPermission.some(
      (permittedGroup) => group.code === permittedGroup.code
    );
    const updateMetadata = groupsWithUpdateGroupMetadataPermission.some(
      (permittedGroup) => group.code === permittedGroup.code
    );
    return {group, manageGroup, updateMetadata};
  });

  return (
    <Page
      name="GROUPS_LIST"
      header={t("GROUPS_LIST_HEADER", "List Group")}
      subheader={t("GROUPS_LIST_SUBHEADER", "Groups you are permitted to Edit")}
      loading={false}
    >
      <Table columns={5}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("GROUPS_LIST_GROUP_NAME", "Name")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("GROUPS_LIST_GROUP_CODE", "Code")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("GROUPS_LIST_GROUP_ALERT_LANGUAGE", "Languages")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("GROUPS_LIST_GROUP_ALERT_EMAILS", "Emails")}
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        {editableGroups.map(({group, manageGroup, updateMetadata}) => {
          const {languages, emails} = group.alertInformationAsList.reduce((acc, [email, language]) => {
            if (acc.emails.indexOf(email) === -1) {
              acc.emails.push(email);
            }
            if (acc.languages.indexOf(language) === -1) {
              acc.languages.push(language);
            }
            return acc;
          }, {languages: [], emails:[]});

          return (
            <Table.Row>
              <Table.Cell>{group.label}</Table.Cell>
              <Table.Cell>{group.code}</Table.Cell>
              <Table.Cell>{languages.join(", ")}</Table.Cell>
              <Table.Cell>{emails.join(", ")}</Table.Cell>
              <Table.Cell>
                <div>
                  {manageGroup && (
                    <Button as={Link} to={`/app/groups/edit/${group.id}`} primary>
                      {t("GROUPS_LIST_EDIT", "Edit")}
                    </Button>
                  )}
                  {updateMetadata && (
                    <Button as={Link} to={`/app/groups/updatemetadata/${group.id}`} primary>
                      {t("GROUPS_LIST_UPDATE_METADATA", "Update Metadata")}
                    </Button>
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
      <Button as={Link} primary to={"/app/groups/new"}>
        {t("GROUPS_LIST_NEW", "New")}
      </Button>
    </Page>
  );
}

export default withTranslation()(GroupsEditPage);
