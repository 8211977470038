import React, {Fragment, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Grid, Placeholder, Segment} from "semantic-ui-react";
import moment from "moment";
import DateTimeField from "../../../../components/DateTimeField";
import SensorDataService from "../../../../services/sensordata/SensorDataService";
import YumenHelper from "./YumenHelper";
import {Bar} from "react-chartjs-2";

const YumenWearableGraphDay = ({subjectId, limbType, showHubNotConnected, t}) => {

  const [hubId, setHubId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [fromDate, setFromDate] = useState(moment().clone().startOf('day'));
  const [graphData, setGraphData] = useState();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [subjectId, fromDate])

  const getData = async () => {
    try {
      setLoading(true)

      let theHubId = await YumenHelper.findYumenHubId(subjectId);

      if (!theHubId) {
        setLoading(false);
        console.log("No hub Id found for subject");
        return;
      }

      setHubId(theHubId);

      const wearableData = await SensorDataService.getYumenGraphData(
              subjectId,
              limbType,
              fromDate.format('YYYY-MM-DD'),
              null,
              15);
      let daysPercentage = [];
      let labels = [];
      if (wearableData.series) {
        wearableData.series.forEach(({xaxis, value}) => {
          labels.push(xaxis);
          daysPercentage.push(value);
        });
      }

      setGraphData(null);
      const dataAvailable = !daysPercentage.every(v => v === 0);
      if (dataAvailable) {
        //add an off screen value to raise upto 100
        daysPercentage.push(100);
        const barData = {
          labels: labels,
          datasets: [{
            label: t("YUMEN_WEARABLE_GRAPH_DAY_TITLE", "% of hour wearable worn"),
            borderColor: "orange",
            backgroundColor: "orange",
            data: daysPercentage
          }]
        };
        setGraphData(barData);
      }
      setLoading(false);
    } catch (e) {
      console.error('Error loading Yumen wearable data', e);
      setError(true);
      setLoading(false);
    }
  };

  let options = {
    scales: {
      y: {
        title: {
          display: true,
          text: t("YUMEN_WEARABLE_GRAPH_Y_AXIS", "% of data received"),
        },
      },
      x: {
        title: {
          display: true,
          text: t("YUMEN_WEARABLE_GRAPH_X_AXIS_HOURS", "Time (Clock hours)"),
        },
      },
    }
  };

  return (
      <Fragment>
        {loading && (
            <Segment>
              <Placeholder fluid>
                <Placeholder.Header image>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                </Placeholder.Paragraph>
              </Placeholder>
            </Segment>
        )}

        {!loading && hubId && (
            <>
              <Grid columns={'2'}>
                  <Grid.Row>
                      <Grid.Column>
                        <div style={{marginBottom: "20px"}}>
                          <DateTimeField
                              label={t("GRAPH_SELECT_DATE", "Data for day")}
                              onChange={d => setFromDate(d)}
                              value={fromDate}/>
                        </div>
                      </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                  </Grid.Row>

              </Grid>
              {error && <p>{t("SENSOR_DATA_ERROR", "Error while fetching data, if the problem persists contact support")}</p>}

              {hubId && !graphData && (
                  <p>{t("YUMEN_WEARABLE_DATA_EMPTY_DAY", "There is no data to display for selected day")}</p>
              )}

              {hubId && graphData && (
                  <Bar data={graphData} options={options}/>
              )}

              {!hubId && showHubNotConnected && (<>
                <h2>{t("YUMEN_ARM_HEADER_NOT_CONNECTED", "No Yumen Hub connected")}</h2>
                <h3>{t("YUMEN_ARM_HEADER2_NOT_CONNECTED",
                    "Please define the Yumen Wearable Hub ID in the Subject Records section")}</h3>
              </>)}
            </>
        )}
      </Fragment>
  );

}

export default withTranslation()(YumenWearableGraphDay);
