import React, {createContext, useContext, useState} from 'react';
import {createPortal} from 'react-dom';

const FullPageLayoutContext = createContext(null);
export const FullPageLayoutProvider = ({children}) => {
  // Normally a ref would be used for storing a HtmlElement reference but this
  // is only set on subsequent renders, so is stored in state.
  const [fullPageLayoutElement, setFullPageLayoutElement] = useState(null);

  return <>
    <div ref={setFullPageLayoutElement} id="full-page-layout" style={{zIndex: 1000, position:'fixed', left:0, right:0, top:0, bottom:0, pointerEvents:"none"}} />
    <FullPageLayoutContext.Provider value={fullPageLayoutElement}>
    {fullPageLayoutElement ? children : null}
    </FullPageLayoutContext.Provider>
  </>
}

// If this wrapper component is not used then pointerEvents: 'auto' will
// have to be set manually.
const Position = ({top, right, bottom, left, children}) => {
  return <div style={{top, left, bottom, right, position:'fixed', pointerEvents: 'auto'}}>{children}</div>
}

const useFullPageLayoutPortal = () => {
  const element = useContext(FullPageLayoutContext);

  return [(ReactElement) => {
    return createPortal(ReactElement, element)
  }, Position];
}

export default useFullPageLayoutPortal;