import React, {useEffect, useState} from "react";
import {Button, Dropdown, Grid, Modal} from "semantic-ui-react";
import DateRangeControls from "../../components/DateRangeControls";
import moment from "moment/moment";
import {DateTime} from "luxon";
import { saveAs } from 'file-saver'
import ExportService from "../../services/ExportService";
import exportConstants from "../../constants/EXPORT_MODAL";

const ExportModal = (props) => {
    const [startDate, setStartDate] = useState(DateTime.now().minus({months: 1}).toISODate())
    const [endDate, setEndDate] = useState(DateTime.now().toISODate())
    const [exporting, setExporting] = useState(false);
    const [exportReportTypes,setExportReportTypes] = useState([])
    const [selectedReportTypes,setSelectedReportTypes] = useState(exportConstants.DEFAULT_SELECTED_EXPORT_TYPES)

    const selectedSubjects = props.selectedSubjects;
    const visible = props.show;
    const t = props.t;
    const onClose = props.onClose;
    const groupCode = props.groupCode;
    const modalTitle = props.modalTitle;

    const onReportTypesChanged = (_event, data) => {
        let userSelectedReports;
        if (data.value.includes('select_all')) {
            //add all the reports into the dropdown
            userSelectedReports = exportReportTypes
                .filter(exportType => exportType.value !== 'select_all')
                .map(exportType => exportType.value)
        }else{
            userSelectedReports = data.value;
        }
        setSelectedReportTypes(userSelectedReports);
    }

     useEffect(() => {
        const setupExportableReportTypes = async () => {
            const reportTypes = await ExportService.listExportTypes();
            const exportableReportTypes = reportTypes
                .map((reportType) => {
                    const text = t(
                        `EXPORT_TYPE_${reportType.toUpperCase()}`,
                        reportType
                    );
                    return {
                        key: reportType,
                        text: text,
                        value: reportType,
                    };
                }).sort((a, b) => a.text.localeCompare(b.text))
            exportableReportTypes.unshift({  //Add 'Select All' to the start of the items for the dropDown
                key: 'selectall',
                text: t("EXPORT_MODAL_SELECT_ALL","Select All"),
                value: 'select_all',
            });
            setExportReportTypes(exportableReportTypes);
        };

        setupExportableReportTypes();
    },[t] );

    const handleDateChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const exportData = async () => {
        //If selectSubjects is 1 -> MAX_SUBJECT_SELECTION use the subjectIds otherwise use blank array (which is all subjects)
        const subjectIds = selectedSubjects.length === 0 ? [] : selectedSubjects.length <= exportConstants.MAX_SUBJECT_SELECTION_COUNT? selectedSubjects : []
        const fileDate = DateTime.now().toISODate();
        const criteria = {
            "from": startDate,
            "to":endDate,
            "groupCode":groupCode,
            "subjectIds":subjectIds,
            "questionnaireDefIds":[],
            "datatypes":selectedReportTypes
        }
        try {
            setExporting(true);
            const fileData = await ExportService.triggerRestrictedExport(criteria);
            saveAs(fileData, "atom5-export-" + fileDate + "-filtered.zip");
            onClose();
        }catch (e) {
            console.log("Error executing export with criteria", criteria);
        } finally {
            setExporting(false);
        }
    }

    return (<Modal
        open={visible}
        onClose={onClose}
        closeIcon={true}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
    >
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Content>
            <Grid style={{width: '100%'}}>
                <Grid.Row>
                    <Grid.Column width={16} >
                        <p style={{width: '100%', textAlign: 'center'}}>{t("EXPORT_MODAL_DATE_RANGE", "Export Date Range")}</p>
                        <DateRangeControls
                            onChange={handleDateChange}
                            start={moment(startDate)}
                            end={moment(endDate)}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column  width={10} >
                        <label>
                            {t(
                                "EXPORT_MODAL_EXPORT_REPORT",
                                "Select Report Types to export:"
                            )}
                        </label>
                        <Dropdown
                            placeholder={t("EXPORT_MODAL_EXPORT_REPORT_PLACEHOLDER", "Select Export Type")}
                            fluid
                            selection
                            options={exportReportTypes}
                            multiple={true}
                            onChange={onReportTypesChanged}
                            value={selectedReportTypes}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16} >
                        <p style={{width: '100%', textAlign: 'center'}}>
                            {selectedSubjects.length === 0 ? t("EXPORT_MODAL_ALL", "All") : selectedSubjects.length <= exportConstants.MAX_SUBJECT_SELECTION_COUNT ? selectedSubjects.length : "ALL"} {selectedSubjects.length > 1 || selectedSubjects.length === 0  ?  t("EXPORT_MODAL_PATIENTS_PLURAL_SELECTED", "Patients will be included in export") : t("EXPORT_MODAL_PATIENT_SINGULAR_SELECTED", "Patient will be included in export")}
                        </p>
                        {exporting && (
                            <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>
                                {t("EXPORT_MODAL_PLEASE_WAIT", "Please wait while your export is being generated....")}
                            </p>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column width={12} textAlign="right">
                    <Button
                        primary={selectedReportTypes.length > 0}
                        onClick={exportData}
                        loading={exporting}
                        disabled={selectedReportTypes.length === 0}
                    >
                        {t("EXPORT_MODAL_PERFORM_EXPORT", "Perform Export")}
                    </Button>
                </Grid.Column>
                </Grid.Row>
            </Grid>
        </Modal.Content>
    </Modal>
    );
}

export default ExportModal;
