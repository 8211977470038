import React, { useState, useContext, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import Page from "../../components/page/Page";
import { getDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import { Redirect, useHistory, useParams } from "react-router-dom";
import ConfigContext from "../../context/ConfigContext";
import Questionnaire from "../../questionnaires/Questionnaire";
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";
import QuestionnaireEnd from "../../components/questionnaire/QuestionnaireEnd";
import QuestionnaireContext from "../../context/QuestionnaireContext";
import SubjectService from "../../SubjectService";
import { Button, Header, Icon, Loader, Message } from "semantic-ui-react";
import { cloneDeep } from "lodash";
import UserContext from "../../context/UserContext";
import DashboardContext from "../../context/DashboardContext";
import DataSubmissionStatusModal from "../../components/questionnaire/DataSubmissionStatusModal";
import ParentQuestionnaireDisplay from "../../questionnaires/ParentQuestionnaireDisplay";

const ERROR_TYPE = {
  UNKNOWN: {
    title: "GLOBAL_ERROR_TITLE",
    message: "GLOBAL_ERROR_GENERIC_MESSAGE",
  },
  PERMISSION: {
    title: "GLOBAL_ERROR_TITLE",
    message: "PERMISSION",
    messageFallback: "You do not have permissions for this",
  },
};

const StaffSignOffQuestionnairePage = (props) => {
  const { allDefinitions, t } = props;

  const history = useHistory();
  const { subjectId, questionnaireType, questionnaireId, definitionCode } =
    useParams();
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const returnTo = useContext(QuestionnaireContext)?.returnTo;

  let [isCurrentQuestionnaireSignOffCompleted, setIsCurrentQuestionnaireSignOffCompleted] =
    useState(false);

  let [questionnaire, setQuestionnaire] = useState(null);
  let [definition, setDefinition] = useState(null);
  let [subjectData, setSubjectData] = useState(null);
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(null);

  let [shouldUseAltDisplay, setShouldUseAltDisplay] = useState(false);

  const dashboardContext = useContext(DashboardContext);
  const handlePageChange = () => {
    dashboardContext.scrollToTop();
  };

  const [dataUploadProgress, setDataUploadProgress] = useState(null);

  const handleDataUploadProgress = (dataUploadProgress) => {
    setDataUploadProgress(dataUploadProgress);
  };

  const initialize = async () => {
    if (!loading) {
      await setLoading(true);
    }

    if (isCurrentQuestionnaireSignOffCompleted) {
      setIsCurrentQuestionnaireSignOffCompleted(false);
    }

    const newSubjectData = subjectData
      ? subjectData
      : await SubjectService.getSubjectData(subjectId);

    const questionnaire = await SubjectService.getSubjectQuestionnaireByCodeAndId(
            subjectId,
            definitionCode,
            questionnaireId
          );

    const definition = cloneDeep(
      allDefinitions.find((d) => {
        if (questionnaire) {
          return d.id === questionnaire.definitionId;
        }
        return d.id === parseInt(questionnaireId);
      })
    );

    if (!definition || !definition.questions) {
      setError(ERROR_TYPE.UNKNOWN);
      return;
    }

    // TODO: Do we need new permissions for this?
    const permissions = await SubjectService.getSubjectPermission(subjectId);
    if (
      !SubjectQuestionnaireService.isQuestionnaireSubmittableByStaff(
        config,
        permissions,
        definition
      )
    ) {
      setError(ERROR_TYPE.PERMISSION);
      console.log(
        "[SubjectQuestionnaireTable][initialize] Questionnaire Submission not allowed"
      );
    }

    if (definition.config?.modules) {
      const subjectGroups = newSubjectData.groups;
      const tabsConfig = config.ui?.tabs ? config.ui?.tabs : [];
      const staffProfile = user.profile;

      const isModuleSubmissionAllowed =
        await SubjectQuestionnaireService.canStaffViewQuestionnaireModule(
          definition,
          subjectGroups,
          tabsConfig,
          staffProfile
        );

      if (!isModuleSubmissionAllowed) {
        setError(ERROR_TYPE.PERMISSION);
        console.log(
          "[SubjectQuestionnaireTable][initialize] Module Submission is Not Allowed"
        );
        return;
      }
    }

    const answers = {};
    definition.questions.forEach((question, index) => {
      let questionKey = definition.code + "_" + question.code;
      answers[question.code] = questionnaire[questionKey];

      if (
        questionnaire.customLabels &&
        questionnaire.customLabels.length !== 0
      ) {
        let customLabel = questionnaire.customLabels.find((label) => {
          return label.code === question.code;
        });

        if (customLabel) {
          definition.questions[index].label = customLabel.label;
        }
      }
    });

    // The questionnaire needs to be set before the definition is changed.
    setSubjectData(newSubjectData);
    await setQuestionnaire({ ...questionnaire, answers });
    setDefinition(definition);
    setLoading(false);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId, questionnaireId, definitionCode]);

  const submitQuestionnaire = async (e) => {
    // This will sometimes return true if the calls fails so
    // no way to show user it has failed.
    dashboardContext.scrollToTop();
    const response = await SubjectQuestionnaireService.submitSignOff(
        definition,
        questionnaire,
        e,
        subjectId,
        handleDataUploadProgress,
    );

    if (response.ok) {
      setIsCurrentQuestionnaireSignOffCompleted(true);
    } else {
      setError(ERROR_TYPE.UNKNOWN);
    }
  };

  const goBackToList = () => {
    if (returnTo) {
      history.push(returnTo);
      return;
    }
    history.goBack();
  };

  const goToNext = (questionnaire, definition) => {
    setIsCurrentQuestionnaireSignOffCompleted(false);
    let newQuestionnaireId = questionnaire ? questionnaire.id : "latest";

    history.push(
      `/app/subject/${subjectId}/questionnaire-type/${questionnaireType}/${definition.code}/submit/${newQuestionnaireId}`
    );
  };

  const buildErrorDisplay = (titleKey, messageKey, messageFallback) => {
    return (
      <Message error textAlign="left">
        <Message.Header>{t(titleKey)}</Message.Header>
        <Message.Content>{t(messageKey, messageFallback)}</Message.Content>
      </Message>
    );
  };

  const getErrorDisplay = () => {
    if (error) {
      return buildErrorDisplay(
        error.title,
        error.message,
        error.messageFallback
      );
    }
    return null;
  };

  if (!questionnaire) {
    if (loading) {
      return getErrorDisplay();
    } else {
      return <Redirect to="/app/home" />;
    }
  }

  if (!definition) {
    if (loading) {
      return <Loader active />;
    } else {
      return <Redirect to="/app/home" />;
    }
  }

  if (loading) {
    return <Loader active />;
  }

  return (
    <Page
      key={questionnaireId}
      name="QUESTIONNAIRE_PAGE"
      header={() => (
        <Header as="h3">
          <Button
            color="orange"
            style={{ padding: "0.25rem 1rem " }}
            onClick={() => history.goBack()}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Icon name="arrow left" />
              <h3 style={{ margin: "0rem", marginLeft: "0.5rem" }}>
                {subjectData.subjectCode}
              </h3>
            </div>
          </Button>
          {" " + t("SUBMIT_SUBJECT_QUESTIONNAIRE_HEADER")}
        </Header>
      )}
    >
      {error && getErrorDisplay()}
      {definition && !isCurrentQuestionnaireSignOffCompleted && !error && (
        <div
          style={{
            display: "flex",
            flexDirection: shouldUseAltDisplay ? "column-reverse" : "row",
          }}
        >
          <div style={{ flexGrow: 1, width: "800px", paddingRight: "2rem" }}>
            <Questionnaire
              key={definitionCode}
              definition={definition}
              questionnaire={questionnaire}
              onPageChange={handlePageChange}
              onSubmit={submitQuestionnaire}
              subjectId={subjectId}
            />
          </div>
          <div
            style={{
              paddingBottom: "2rem",
            }}
          >
            <ParentQuestionnaireDisplay
              questionnaire={questionnaire}
              subjectId={subjectId}
              allDefinitions={allDefinitions}
              shouldUseAltDisplay={shouldUseAltDisplay}
              setShouldUseAltDisplay={setShouldUseAltDisplay}
              shouldShow={!isCurrentQuestionnaireSignOffCompleted}
            />
          </div>
        </div>
      )}

      {isCurrentQuestionnaireSignOffCompleted && (
        <QuestionnaireEnd
            definition={definition}
            onFinish={goBackToList}
            onLink={goToNext}
        />
      )}
      <DataSubmissionStatusModal dataUploadProgress={dataUploadProgress} />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    allDefinitions: getDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(StaffSignOffQuestionnairePage);
