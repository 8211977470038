import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Form, FormField, Grid, Input, Loader, Message} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import LookupService from "../../../services/LookupService";
import InternationalisationService from "../../../InternationalisationService";
import {uniqBy} from "lodash";

const LookupCreateCard = ({onSubmit}) => {
  const {t} = useTranslation()

  const [error, setError] = useState(false);
  const [code, setCode] = useState("")
  const [lookupDataArray, setLookupDataArray] = useState(null)

  const [languages, setLanguages] = useState([])
  const getLanguages = useCallback(async ()=>{
    const webLanguages = await InternationalisationService.getLanguages();
    const mobileLanguages = await InternationalisationService.getMobileLanguages();
    setLanguages(uniqBy([...webLanguages, ...mobileLanguages], l=>l.code));
  }, [])
  useEffect(() => {
    getLanguages();
  }, [getLanguages]);
  useEffect(()=>{
    setLookupDataArray(languages.map(l=>{
      return {
        language: l.code,
        name: ""
      }
    }));
  }, [languages]);
  const hasLoaded = useMemo(()=>{
    return Array.isArray(languages) && languages?.length > 0
  }, [languages])

  const handleNameChange = (index, value) => {
    setLookupDataArray(oldLookupData => {
      const newData = [...oldLookupData];
      newData[index].name = value;
      return newData
    })
  }


  const isSubmitAllowed = useMemo(()=>{
    if(!hasLoaded) return false;
    return code && code?.length > 0 && lookupDataArray && Array.isArray(lookupDataArray) && lookupDataArray.every(lookupData => lookupData?.name?.length > 0);
  }, [code, hasLoaded, lookupDataArray])
  const handleSave = useCallback(async () => {
    if(!isSubmitAllowed) return
    try {
      await LookupService.createLookupsByLookupCode(code, lookupDataArray);
      if(error) setError(false);
      onSubmit && onSubmit();
    } catch (error) {
      console.error("[LookupCreateCard] Failed to create Lookup", error)
      setError(true)
    }
  }, [code, error, isSubmitAllowed, lookupDataArray, onSubmit])


  return <div style={{display: "flex", padding: '16px'}}>
    <Form style={{width: '100%'}}>
      <FormField>
        <h4>{t("LOOKUP_CODE", "Code")}</h4>
        <Input fluid value={code} onChange={(e, v)=>{setCode(v.value)}} />
      </FormField>
      {!hasLoaded && <Loader isActive /> }
      {hasLoaded && <FormField>
        <h4>{t("LOOKUP_NAME", "Lookup Names")}</h4>
        <Grid>
          {lookupDataArray.map((lookupData, index) =>
              <Grid.Row key={"lookup-"+index}>
                <Grid.Column width={2}><label>{lookupData.language}</label></Grid.Column>
                <Grid.Column width={14}>
                  <Input
                    fluid
                    value={lookupData.name}
                    onChange={(e, v) => handleNameChange(index, v.value)}/>
                </Grid.Column>
              </Grid.Row>
          )}
        </Grid>
      </FormField>}
      {error && <Message color={"red"} >{t("LOOKUP_CREATE_ERROR_MESSAGE", "There was an error creating the lookups, please contact dev.")}</Message> }
      <Button
          disabled={!isSubmitAllowed}
          primary={isSubmitAllowed}
          onClick={handleSave}
      >{
        t("GLOBAL_SAVE", "Save")
      }</Button>
    </Form>
  </div>
}

export default LookupCreateCard;