import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';
import DataQueryListTab from '../../components/dataquery/DataQueryListTab';
import Page from '../../components/page/Page';
import DATA_QUERY_TABS_TO_STATUSES from '../../constants/dataquery/DATA_QUERY_TABS_TO_STATUSES';
import DataQueryService from '../../services/DataQueryService';

const TABS = {
  OPEN: "OPEN",
  ANSWERED: "ANSWERED",
  CLOSED: "CLOSED",
};

const DataQueryListPage = ({t}) => {

  const [panes, setPanes] = useState([]);

  useEffect(() => {
    const getPane = async (tab) => {
      const queryCount = await DataQueryService.getQueryCount({filterStatus: DATA_QUERY_TABS_TO_STATUSES[tab]});
      return {
        menuItem: `${t("DATA_QUERY_LIST_TAB_" + tab)} (${queryCount})`,
        render: () => <DataQueryListTab tab={tab} queryCount={queryCount} />
      }
    };
    const updatePanes = async () => {
      const panes = await Promise.all(
        Object.values(TABS).map(getPane)
      );
      setPanes(panes);
    };
    updatePanes();
  }, [t]);

  return (
    <Page
      name="DATA_QUERY_LIST"
      header={t("DATA_QUERY_LIST_HEADER")}
      subheader={t("DATA_QUERY_LIST_SUBHEADER")}
      isLoading={panes.length === 0}
    >
      <Tab panes={panes} />
    </Page>
  )
};

export default withTranslation()(DataQueryListPage);