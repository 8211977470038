import RequestHelper, { CONTENT_TYPE } from "../RequestHelper";
import questionnaireHelper from "./helpers/questionnaireHelper";
import DataSubmissionService from "./DataSubmissionService";
import InternationalisationService from "../InternationalisationService";
import TAB_VIEW_PERMISSIONS from "../constants/TAB_VIEW_PERMISSIONS";
import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import PermissionsService from "./PermissionsService";
import { flatten } from "lodash";
import workflowHelper from "../helpers/questionnaireWorkflowHelper";

export const SUBJECT_QUESTIONNAIRE_SERVICE_CONSTANTS = {
  DATA_UPLOAD_QUESTIONNAIRE_ITEM_KEY: "_questionnaire",
};

const SubjectQuestionnaireService = {
  getQuestionnaires: (questionnaireType) => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/questionnaires/" +
        encodeURIComponent(questionnaireType),
      {},
      "GET",
      null
    );
  },

  getDefinitions: (type) => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-definitions",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      { type }
    );
  },

  submitSignOff: async (definition,
                        questionnaire,
                        unProcessedAnswers,
                        subjectId,
                        onDataUploadProgress,
                        renderTimings) => {
    return SubjectQuestionnaireService.submitQuestionnaire(definition,
        questionnaire,
        unProcessedAnswers,
        subjectId,
        onDataUploadProgress,
        renderTimings, true)
  },

  submitQuestionnaire: async (
    definition,
    questionnaire,
    unProcessedAnswers,
    subjectId = null,
    onDataUploadProgress,
    renderTimings,
    isSignOff = false,
  ) => {
    const questionnaireId = questionnaire ? questionnaire.id : definition.id;
    const uri =
      questionnaireHelper.generateUriPrefixByType(definition.type) +
      questionnaireId;

    const processedAnswers =
      await SubjectQuestionnaireService.extractWrappedAnswers(
        definition,
        unProcessedAnswers
      );
    const answers = processedAnswers.answers;
    const mediaItemsForQuestionnaire =
      processedAnswers.mediaItemsForQuestionnaire;
    const dataUploadProgress = processedAnswers.dataUploadProgress;

    if (onDataUploadProgress) {
      onDataUploadProgress(dataUploadProgress);
    }

    await SubjectQuestionnaireService.sendAttachments(
      mediaItemsForQuestionnaire,
      subjectId,
      onDataUploadProgress,
      dataUploadProgress
    );

    const formattedAnswerMap = questionnaireHelper.formatAnswerMap(
      answers,
      definition
    );

    if (renderTimings !== undefined) {
      renderTimings.timing.questionnaireTiming.end = new Date().toISOString();
    }

    const source = null;
    const task = workflowHelper.getTaskFromQuestionnaire(questionnaire);
    const payload = {
      definitionId: definition.id,
      answers: formattedAnswerMap,
      questionnaireWorkflowTaskCode: task?.code,
      ...renderTimings,
    };

    const sendResponse = DataSubmissionService.packageAndSend(
        uri,
        source,
        payload,
        subjectId,
        isSignOff
    );

    if (onDataUploadProgress) {
      /* dataUploadProgress holds the list of things to upload (media items + questionnaire data)
      This marks the questionnaire data item in set as uploaded, i.e. it has been sent, and calls the callback function with the updated object.
      */
      dataUploadProgress.items[
        SUBJECT_QUESTIONNAIRE_SERVICE_CONSTANTS.DATA_UPLOAD_QUESTIONNAIRE_ITEM_KEY
      ].uploaded = true;
      dataUploadProgress.current += 1;
      onDataUploadProgress(dataUploadProgress);
    }

    return sendResponse;
  },

  //record timing for questionnaire submission
  recordTimestamp: async (subjectId, questionnaireId, recordTimestamp) => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        "/questionnaires/recordTimestamp/" +
        questionnaireId,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      recordTimestamp
    );
  },

  sendAttachments: async (
    mediaItemsForQuestionnaire,
    subjectId,
    onDataUploadProgress,
    dataUploadProgress
  ) => {
    if (
      !mediaItemsForQuestionnaire ||
      !(mediaItemsForQuestionnaire instanceof Object)
    ) {
      return true;
    }

    // eslint-disable-next-line no-unused-vars
    for (let [questionCode, answer] of Object.entries(
      mediaItemsForQuestionnaire
    )) {
      const formData = new FormData();
      formData.append("reference", answer.answer);
      formData.append("file", answer.data);
      const headers = {
        "Accept": CONTENT_TYPE.TEXT_PLAIN,
        "Content-Type": CONTENT_TYPE.MULTIPART_FORMDATA,
        "X-FileUpload-File-Reference": answer.answer,
        "X-Subject-Id": subjectId,
      };
      try {
        await DataSubmissionService.sendAttachment(formData, headers);
      }catch (e){
        console.error(`Error sending attachments`, e);

        if (dataUploadProgress) {
          dataUploadProgress.items[questionCode].failed = true;
          dataUploadProgress.current += 1;
          if (onDataUploadProgress) {
            onDataUploadProgress(dataUploadProgress);
          }
        }
        throw e
      }

      if (dataUploadProgress) {
        dataUploadProgress.items[questionCode].uploaded = true;
        dataUploadProgress.current += 1;
        if (onDataUploadProgress) {
          onDataUploadProgress(dataUploadProgress);
        }
      }
    }
  },

  getSubjectRecords: async () => {
    let response = await RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/questionnaires/subject-records",
      {},
      "GET"
    );
    return response;
  },

  canStaffViewModuleAnswers: function (
    subjectGroups,
    tabConfig,
    staffGroupMappings
  ) {
    if (typeof tabConfig === "string") {
      return true;
    }

    if (!tabConfig.roles) {
      return true;
    }

    const staffRolesForSubject = PermissionsService.getStaffRolesForSubject(
      staffGroupMappings,
      subjectGroups
    );
    if (staffRolesForSubject.length === 0) {
      return false;
    }

    if (Array.isArray(tabConfig.roles)) {
      return tabConfig.roles.some((value) =>
        staffRolesForSubject.includes(value)
      );
    }

    return Object.entries(tabConfig.roles).some(
      ([key, value]) =>
        staffRolesForSubject.includes(key) &&
        value === TAB_VIEW_PERMISSIONS.FULL_ACCESS
    );
  },

  canStaffViewQuestionnaireModule: async function (
    definition,
    subjectGroups,
    tabsConfig,
    staffProfile
  ) {
    const staffGroupMappings = staffProfile.groupMappings;

    const rolesForDefinition =
      SubjectQuestionnaireService.getRolesForDefinition(definition, tabsConfig);
    if (rolesForDefinition.length === 0) {
      return true;
    }

    const staffRolesForSubject = PermissionsService.getStaffRolesForSubject(
      staffGroupMappings,
      subjectGroups
    );
    if (staffRolesForSubject.length === 0) {
      return false;
    }

    return rolesForDefinition.some((value) => {
      if (typeof value == "string") {
        return staffRolesForSubject.includes(value);
      }

      return staffRolesForSubject.some((role) => {
        return value[role] !== null;
      });
    });
  },

  getRolesForDefinition: (definition, tabsConfig) => {
    if (!definition?.config?.modules) {
      return [];
    }

    const tabsForDefinition = tabsConfig.filter((tabConfig) =>
      SubjectQuestionnaireService.filterTabConfigForModules(
        tabConfig,
        definition.config.modules
      )
    );

    const rolesForDefinition = tabsForDefinition.map(
      (tabConfig) => tabConfig.roles
    );

    return flatten(rolesForDefinition);
  },

  filterTabConfigForModules: (tabConfig, modulesArray) => {
    return modulesArray.some((module) => {
      if (tabConfig.name) {
        return tabConfig.name.split("/")[1] === module;
      }
      return false;
    });
  },

  isQuestionnaireSubmittableByStaff: (config, permissions, definition) => {
    if (!permissions.length || !definition.code) {
      return false;
    }

    let hasPermission = permissions.includes("SUBMIT_SUBJECT_QUESTIONNAIRES") || permissions.includes("SUBMIT_BLINDED_QUESTIONNAIRES");

    if (!hasPermission) {
      return hasPermission;
    }

    if (
      config?.ui?.questionnaires &&
      "allowStaffSubmissionDefault" in config.ui.questionnaires
    ) {
      if (config.ui.questionnaires.allowStaffSubmissionDefault === "false") {
        hasPermission = false;
      }
    }

    if (definition.config && "allowStaffSubmission" in definition.config) {
      hasPermission = definition.config.allowStaffSubmission;
    }

    return hasPermission;
  },

  extractWrappedAnswers: async (questionnaireDefinition, answerMap) => {
    const values = {
      answers: {},
      mediaItemsForQuestionnaire: {},
      dataUploadProgress: {
        items: {},
        total: 0,
        current: 0,
      },
    };

    const mediaQuestions = questionnaireDefinition.questions.filter(
      (question) => {
        return (
          question.type === QUESTION_TYPES.VIDEO ||
          question.type === QUESTION_TYPES.IMAGE ||
          question.type === QUESTION_TYPES.FILE
        );
      }
    );

    Object.entries(answerMap).forEach(([questionCode, answer]) => {
      const mediaQuestion =
        mediaQuestions && mediaQuestions.find((q) => q.code === questionCode);

      const isWrappedAnswer = typeof answer === "object";

      if (isWrappedAnswer && mediaQuestion) {
        values.mediaItemsForQuestionnaire[questionCode] = answer;
        values.answers[questionCode] = answer.answer;
        values.dataUploadProgress.items[questionCode] = {
          name: answer.data.name,
          uploaded: false,
        };
      } else {
        values.answers[questionCode] = answer;
      }
    });

    // Add questionnaire item last
    values.dataUploadProgress.items[
      SUBJECT_QUESTIONNAIRE_SERVICE_CONSTANTS.DATA_UPLOAD_QUESTIONNAIRE_ITEM_KEY
    ] = { name: "", uploaded: false };

    values.dataUploadProgress.total = Object.entries(
      values.dataUploadProgress.items
    ).length;

    return values;
  },

  createAnswerMapFromQuestionnaire: (questionnaire, definition) => {
    const answers = {};

    definition.questions.forEach((question) => {
      let recordQuestionKey = definition.code + "_" + question.code;
      answers[question.code] = questionnaire[recordQuestionKey];
    });

    return answers;
  },

  getQuestionnaireLabel: (definition) => {
    let text = definition.label;
    if (definition.config?.labelOverrideStaff) {
      text =
        typeof definition.config?.labelOverrideStaff === "object"
          ? definition.label
          : definition.config?.labelOverrideStaff;
    }
    return text;
  },

  getQuestionnaireModuleLabels: (t, definition) => {
    if (
      !definition.config?.modules ||
      !Array.isArray(definition.config?.modules) ||
      definition.config?.modules.length < 1
    ) {
      return undefined;
    }
    const moduleLabels = definition.config?.modules.map((moduleCode) => {
      return t("SUBJECT_TAB_MODULE_" + moduleCode.toUpperCase());
    });
    return moduleLabels.join(", ");
  },

  //record timing for questionnaire submission
  updateActivationDates: async (dates, subjectId, questionnaireId) => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        "/questionnaires/updateActivationDates/" +
        questionnaireId,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      dates
    );
  },
};

export default SubjectQuestionnaireService;
