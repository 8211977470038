import React, {useCallback, useContext, useEffect, useState, useMemo} from "react";
import SubjectService from "../SubjectService";
import {substituteSubjectContext, buildSubjectDataPathArray} from "../utility/textReplacement"
import UserContext from "../context/UserContext";

const SubjectContextContext = React.createContext({});

const helpers = {
  textSubstitution: substituteSubjectContext,
}

const DEFAULT_SUBJECT_CONTEXT = {data:{}, error:false};
const isObjectSubjectRecordFormat = obj => obj !== null && typeof obj === "object" && Object.keys(obj).includes("data")

// This is more complex than the mobile implementation because
// it needs to be fetched for a staff member based on the subject
// they are viewing.

// String is required for staff for matching specific context values
const useSubjectContext = (subjectId, string) => {
  const user = useContext(UserContext);
  const isStaff = user?.accountType === "staff";
  const [subjectContext, setSubjectContext] = useState(null);

  const getSubjectContext = useCallback(async ()=> {
    if(!user?.isLoggedIn){
      setSubjectContext(DEFAULT_SUBJECT_CONTEXT);
      return;
    }

    if(isStaff && (!subjectId || !string)){
      setSubjectContext(DEFAULT_SUBJECT_CONTEXT);
      return;
    }

    const subjectDataPathArray = buildSubjectDataPathArray(string)
    const response = isStaff && subjectDataPathArray.length > 0 ? await SubjectService.getSubjectContextAsStaff(subjectId, subjectDataPathArray) : {};
    setSubjectContext(response)
    // if(isObjectSubjectRecordFormat(response)) {
    //   setSubjectContext(response);
    // } else {
    //   setSubjectContext(DEFAULT_SUBJECT_CONTEXT);
    // }
  }, [isStaff, string, subjectId, user.isLoggedIn])
  useEffect(()=>{
    getSubjectContext();
  }, [getSubjectContext]);

  const hasLoaded = useMemo(()=>{
    return isObjectSubjectRecordFormat(subjectContext)
  },[subjectContext])

  const hasError = useMemo(()=>{
    return isObjectSubjectRecordFormat(subjectContext) && subjectContext.error;
  },[subjectContext])

  const subjectContextForSubject = useContext(SubjectContextContext)
  if(!isStaff){
    return subjectContextForSubject;
  }

  return {subjectContext, hasLoaded, hasError, helpers}
};

const SubjectContextProvider = ({children}) => {
  const [subjectContext, setSubjectContext] = useState(null);
  const user = useContext(UserContext);

  const getSubjectContext = useCallback(async ()=> {
    if(!user?.isLoggedIn || user.accountType === "staff"){
      setSubjectContext(DEFAULT_SUBJECT_CONTEXT)
      return;
    }

    const response = await SubjectService.getSubjectContext();
    if(isObjectSubjectRecordFormat(response)) {
      setSubjectContext(response);
    } else {
      setSubjectContext(DEFAULT_SUBJECT_CONTEXT)
    }
  }, [user.accountType, user.isLoggedIn])
  useEffect(()=>{
    getSubjectContext();
  }, [getSubjectContext]);

  const hasLoaded = useMemo(()=>{
    return isObjectSubjectRecordFormat(subjectContext)
  },[subjectContext])

  const hasError = useMemo(()=>{
    return isObjectSubjectRecordFormat(subjectContext) && subjectContext.error;
  },[subjectContext])

  return <SubjectContextContext.Provider value={{subjectContext, hasLoaded, hasError, helpers}} >
    {children}
  </SubjectContextContext.Provider>
}

export default useSubjectContext;
export {SubjectContextProvider}

