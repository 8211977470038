import React, { useState, useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-moment";
import { Line, Scatter, Bar } from "react-chartjs-2";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Button, Dropdown } from "semantic-ui-react";
import SubjectService from "../../../../../SubjectService";
import ChartService from "../../../../../services/ChartService";


ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Tooltip,
  Legend
);
const CHART_TYPES = {
  LINE: "LINE",
  SCATTER: "SCATTER",
  BAR: "BAR",
};

const StackedBarChart = (props) => {
  const { t, title, yAxis, subjectId, dataList } = props;
  let [chartType, setChartType] = useState(CHART_TYPES.BAR);
  let chartRef = useRef(null);

  let subjectData;
  SubjectService.getSubjectData(subjectId).then((data) => {
    subjectData = data;
  });

  let dataSets = [];
  dataList.map(data => {
    let dataSet = {
      label: data.title ||title || "",
      borderColor: data.color,
      backgroundColor: data.color,
      fill: "none",
      pointRadius: 4,
      pointHoverRadius: 6,
      data: data.values.map((v, index) => {
        return {
          x: v.x,
          y: v.y,
        };
      }),
    };
    dataSets.push(dataSet);
    return true
  } )


  const chartOptions = [
    {
      key: CHART_TYPES.BAR,
      text: t("CHART_BAR"),
      value: CHART_TYPES.BAR,
    },
    {
      key: CHART_TYPES.LINE,
      text: t("CHART_LINE"),
      value: CHART_TYPES.LINE,
    },
    {
      key: CHART_TYPES.SCATTER,
      text: t("CHART_SCATTER"),
      value: CHART_TYPES.SCATTER,
    },
  ];
  const exportFunction = () => {
    ChartService.subjectChartExport(
      document,
      chartRef.current,
      subjectData,
      "stacked-"+title
    );
  };

  let options = {
    scales: {
      x: {
        stacked: true,
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            second: "LTS",
            minute: "LT",
            hour: "LT",
            day: "ll",
            week: "ll",
            month: "ll",
            quarter: "ll",
            year: "YYYY",
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: false,
        },
        title: {
          display: true,
          text: yAxis || '',
        },
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const date = moment(tooltipItem.xLabel).format("LLLL");
          return "[" + date + "]:  " + tooltipItem.yLabel;
        },
      },
    },
  };

  const data = {
    datasets: dataSets,
  };

  return (
    <>
      {chartType === CHART_TYPES.LINE && (
        <Line data={data} options={options} ref={chartRef} />
      )}
      {chartType === CHART_TYPES.SCATTER && (
        <Scatter data={data} options={options} ref={chartRef} />
      )}
      {chartType === CHART_TYPES.BAR && (
        <Bar data={data} options={options} ref={chartRef} />
      )}
      <div
        style={{
          padding: "12px 0",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Dropdown
            placeholder={t("CHART_BAR")}
            selection
            onChange={(e, obj) => {
              setChartType(obj.value);
            }}
            options={chartOptions}
        />
        <div>
          <Button
            primary
            onClick={() => {
              exportFunction();
            }}
          >
            {t("CHART_EXPORT")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(StackedBarChart);
