import InternationalisationService from "../InternationalisationService";
import RequestHelper from "../RequestHelper";

export default class SignatureService {
  static signatureRequestDiagnostics() {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/digitalsignature/diagnostics",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }
  static async createSignatureRequest(
    questionnaireOrQuestionnaireDefinitionId,
    questionDefinitionId,
    subjectId,
    name,
    emailAddress
  ) {
    let useGetRequest = false;
    try {
      return await SignatureService._createSignatureRequestViaPost(
        questionnaireOrQuestionnaireDefinitionId,
        questionDefinitionId,
        subjectId,
        name,
        emailAddress
      );
    } catch (error) {
      console.log(
        "[SignatureService][createSignatureRequest] error for POST",
        error
      );
      useGetRequest = true;
    }
    if (useGetRequest) {
      console.log(
        "[SignatureService][createSignatureRequest] using legacy GET API endpoint"
      );
      return await SignatureService._createSignatureRequestViaGet(
        questionnaireOrQuestionnaireDefinitionId,
        questionDefinitionId,
        subjectId
      );
    }
  }

  /**
   * @private
   * @deprecated in favour of using a POST. This remains for systems which have not had a code pull to ensure backwards compatability.
   */
  static _createSignatureRequestViaGet(
    questionnaireOrQuestionnaireDefinitionId,
    questionDefinitionId,
    subjectId
  ) {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/digitalsignatures/createsignaturerequest/" +
        subjectId +
        "/" +
        questionnaireOrQuestionnaireDefinitionId +
        "/" +
        questionDefinitionId,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }
  /**
   * @private
   */
  static _createSignatureRequestViaPost(
    questionnaireOrQuestionnaireDefinitionId,
    questionDefinitionId,
    subjectId,
    name,
    emailAddress
  ) {
    const requestBody = {
      questionnaireOrQuestionnaireDefinitionId,
      questionDefinitionId,
      name,
      emailAddress,
    };
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/digitalsignatures/createsignaturerequest/" +
        subjectId,
      { "Accept-Language": InternationalisationService.getLanguage() },
      "POST",
      null,
      requestBody
    );
  }

  static processSignatureResponse(thirdPartyStatus, envelopeId, subjectId) {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/digitalsignatures/processsignatureresponse/" +
        subjectId +
        "/" +
        thirdPartyStatus +
        "/" +
        envelopeId,
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }
}
