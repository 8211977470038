import React, { memo, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PermissionsService from '../../../services/PermissionsService';
import GroupPermission from '../../../GroupPermission';
import { Button, Dropdown, Form, Message, Modal, ModalActions, ModalContent, ModalHeader } from 'semantic-ui-react';
import DateTimeService from '../../../services/DateTimeService';
import ConfirmButtonWithFeedback from '../../../components/dashboard/ConfirmButtonWithFeedback';
import { QUESTION_TYPES } from 'atom5-branching-questionnaire';
import SubjectAnswerManagementService from '../../../services/SubjectAnswerManagementService';
import GeneralHelpers from '../../../helpers/GeneralHelpers';

const AnswerManagementAddEditAnswerModal = (props) => {
  const { t,
    subject,
    questionnaire,
    questionnaireDefinition,
    questionnaireAnswer,
    questionDefinition: passedQuestionDefinition,
    questionnaireAnswers,
    onClose,
    open
  } = props;
  const [hasPermission, setHasPermission] = useState();
  const [answer, setAnswer] = useState(questionnaireAnswer?.answer);
  const [isTextArea, setIsTextArea] = useState(false);
  const [questionDefinition, setQuestionDefinition] = useState(passedQuestionDefinition);

  const [questionDefinitions, setQuestionDefinitions] = useState([]);

  const isNew = passedQuestionDefinition == null;
  const [isSaveEnabled, setIsSaveEnabled] = useState(!isNew);

  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (!open) {
      return;
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, questionDefinition, questionnaireDefinition]);

  useEffect(() => {
    if (!open) {
      return;
    }
    setIsSaveEnabled(questionDefinition != null);
    setIsTextArea(questionDefinition != null ? [QUESTION_TYPES.DIARY, QUESTION_TYPES.TEXTAREA].includes(questionDefinition.type) : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionDefinition]);

  if (!open) {
    return null;
  }

  const checkPermisisons = async () => {
    const hasCorrectPermission =
      await PermissionsService.hasPermissionForSubject(
        subject?.groups,
        GroupPermission.EDIT_SUBJECT_QUESTIONNAIRES
      );
    setHasPermission(hasCorrectPermission);
    return hasCorrectPermission;
  };

  const init = async () => {
    await checkPermisisons();
    await populateQuestionDefinitons();
  };

  const populateQuestionDefinitons = async () => {
    const defs = Object.values(questionnaireDefinition.questions)
      .filter((qd) => {
        // Multi select fixed values store multiple rows in the answer table for the questionnaire/questiontype combo
        if (qd.type === QUESTION_TYPES.FIXED_VALUE) {
          return true;
        }
        const existingAnswerObj = questionnaireAnswers.find((qa) => qa.question.code === qd.code);
        return existingAnswerObj == null || Array.isArray(existingAnswerObj.answer); // Failsafe array check to detect fixed values
      })
      .map((qd) => {
        return {
          key: qd.code,
          text: `${qd.label} (${qd.code})`,
          value: qd.code,
        };
      });
    setQuestionDefinitions(defs);
  };

  const closeModal = () => {
    if (onClose != null) {
      onClose();
    };
  }

  if (hasPermission === false) {
    closeModal();
    return null;
  }

  const saveAnswer = async (feedbackReason) => {
    const value = answer != null ? answer.toString().trim() : undefined;
    const valueToSave = value !== '' ? value : undefined;

    try {
      await SubjectAnswerManagementService.addEditQuestionnaireAnswerById(
        feedbackReason,
        subject.id,
        questionnaire.id,
        questionnaireAnswer?.id,
        questionDefinition.id,
        valueToSave);
      closeModal();
    } catch (error) {
      const message = GeneralHelpers.errorToString(error);
      setErrorMessage(message);
    }
  };

  const handleValueChange = (e) => {
    setAnswer(e.target.value);
  };

  return (
    <Modal
      open={open}
      closeIcon={false}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <ModalHeader>
        {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRE_ANSWER', 'Add/Edit answer')}
      </ModalHeader>
      <ModalContent>
        <table>
          <tbody>
            <tr>
              <td>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_SUBJECT_CODE', 'Subject code')}
              </td>
              <td>
                {subject?.subjectCode}
              </td>
            </tr>
            <tr>
              <td>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_QUESTIONNAIRE_LABEL', 'Questionnaire')}
              </td>
              <td>
                {questionnaireDefinition?.label} <span style={{ color: '#707070', fontStyle: 'italic' }}>({questionnaireDefinition?.code})</span>
              </td>
            </tr>
            <tr>
              <td>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_QUESTIONNAIRE_ID', 'Questionnaire ID')}
              </td>
              <td>
                {questionnaire.id}
              </td>
            </tr>
            <tr>
              <td>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_QUESTIONNAIRE_COMPLETIONDATE', 'Completed date')}
              </td>
              <td>
                {DateTimeService.build.asDisplayDateTime(questionnaire?.completionDate)}
              </td>
            </tr>
            <tr>
              <td>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_QUESTIONNAIRE_QUESTION', 'Question')}
              </td>
              <td>
                {!isNew && (
                  <>
                    {questionDefinition?.label} <span style={{ color: '#707070', fontStyle: 'italic' }}>({questionDefinition?.code})</span>
                  </>
                )}
                {isNew && questionDefinitions?.length > 0 && (
                  <Dropdown
                    fluid
                    selection
                    options={questionDefinitions}
                    value={questionDefinition?.code}
                    name={'type'}
                    onChange={(_e, data) => {
                      const def = questionnaireDefinition.questions.find(qd => qd.code === data.value);
                      setQuestionDefinition(def);
                    }}
                  />
                )}
                {isNew && questionDefinitions?.length === 0 && (
                  <Message warning>No questions can be added to this questionnaire</Message>
                )}
              </td>
            </tr>
            <tr>
              <td valign='top'>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_QUESTIONNAIRE_QUESTION_TYPE', 'Question type')}
              </td>
              <td >
                {questionDefinition?.type}
                {questionDefinition?.type === QUESTION_TYPES.FIXED_VALUE && questionDefinition.answers.length > 0 && (
                  <div style={{ marginTop: 10 }}>
                    {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_QUESTIONNAIRE_QUESTION_ANSWERS', 'Possible Answers')}
                    <ul>
                      {questionDefinition.answers.map(a => <li key={a.id}>{a.id} - {a.label} ({a.code})</li>)}
                    </ul>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {isTextArea && (
          <Form.TextArea
            autoFocus={true}
            defaultValue={answer}
            onChange={handleValueChange}
            style={{ width: '100%', minHeight: 240, resize: 'none' }}
          />
        )}
        {!isTextArea && (
          <Form.Input
            style={{ width: '100%' }}
            type={'text'}
            autoFocus={true}
            defaultValue={answer}
            onChange={handleValueChange}
          />
        )}
        {errorMessage && (
          <Message
            error
            header={t('GLOBAL_ERROR_TITLE', 'Error')}
            content={
              `There has been a error - this could be due to duplicate questions in the questionnaire, but if it is not that, and the error persists please contact support (Error message: ${errorMessage})`
            }
          />
        )}
        <Message info>
          Leave the answer completely empty to be treated as 'null'.<br /><br />
          Be careful with data which expects to be formatted in certain ways.<br />
          Some examples:
          <ul>
            <li>FIXED_VALUE - the 'answer' is the numeric ID of the answer</li>
            <li>DATETIME - 2023-10-04T00:00:00</li>
            <li>DATE - 2023-08-17</li>
            <li>TIME - 14:55</li>
            <li>MONTHYEAR - 2023-08</li>
            <li>DIARY valid JSON object or null</li>
          </ul><br />
          If in doubt - ask!
        </Message>
      </ModalContent>
      <ModalActions>
        <ConfirmButtonWithFeedback
          color='orange'
          disabled={!isSaveEnabled}
          buttonText={t('GLOBAL_SAVE', 'Save')}
          headerText={t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRE_ANSWER_SAVE_PROMPT_TITLE', 'Save answer')}
          contentText={t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRE_ANSWER_SAVE_PROMPT_TITLE', 'Please enter a reason')}
          confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
          cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
          onConfirm={saveAnswer}
          placeholderText={t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRE_ANSWER_SAVE_PROMPT_TITLE', 'Save answer')}
          mandatoryValidationText={t('GLOBAL_REASON_PROMPT', 'Please enter a reason')}
        />
        <Button onClick={closeModal}>{t(['GLOBAL_BUTTON_CLOSE'], 'Close')}</Button>
      </ModalActions>
    </Modal>
  );
};

export default withTranslation()(memo(AnswerManagementAddEditAnswerModal));
