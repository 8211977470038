import firebase from "firebase/app";
import "firebase/messaging";
import AuthService from "./AuthService";
import BrowserService from "./BrowserService";
import ConfigService from "./ConfigService";
import _ from "lodash";
import {
  setMessagingId,
  setNotificationPreference,
  removeAllNotifications,
  addNotification,
  removeNotification,
  clearMessagingId,
  resetState,
} from "../redux/notificationsSlice";
import { v4 as uuid_v4 } from "uuid";
import store from "../redux/store";
import RequestHelper, {CONTENT_TYPE} from "../RequestHelper";

export const NOTIFICATION_PREFERENCE = {
  NONE: "NONE",
  ALLOWED: "ALLOWED",
  DECLINED: "DECLINED",
  BLOCKED: "BLOCKED",
};

export default class NotificationService {
  static isSupported() {
    try {
      return firebase?.messaging?.isSupported() || false;
    } catch (error) {
      console.error("NotificationService - Messaging is not supported", error);
      return false;
    }
  }
  static initialise(dispatch) {
    if (!NotificationService.isSupported()) {
      return;
    }
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_THIRD_PARTY_FIREBASE_FCM_API_KEY,
      projectId: process.env.REACT_APP_THIRD_PARTY_FIREBASE_FCM_PROJECT_ID,
      messagingSenderId:
        process.env.REACT_APP_THIRD_PARTY_FIREBASE_FCM_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_THIRD_PARTY_FIREBASE_FCM_APP_ID,
    };
    if (firebase?.apps?.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }
    const messaging = firebase.messaging();
    messaging.onMessage((payload) =>
      NotificationService.handleReceivedMessage(dispatch, payload)
    );
  }
  static async getToken(dispatch) {
    if (!NotificationService.isSupported()) {
      return null;
    }
    try {
      NotificationService.initialise(dispatch);
      const messaging = firebase.messaging();
      const token = await messaging.getToken();
      return token;
    } catch (error) {
      console.error(
        "NotificationService - Expected Error getting token (Part expected)",
        error
      );
      return null;
    }
  }
  static async deleteToken(dispatch) {
    if (!NotificationService.isSupported()) {
      return null;
    }
    try {
      NotificationService.initialise(dispatch);
      const messaging = firebase.messaging();
      const success = await messaging.deleteToken();
      return success;
    } catch (error) {
      console.error(
        "NotificationService - Error Deleting token (Part expected)",
        error
      );
      return false;
    }
  }

  static showNotificationControls() {
    if (!ConfigService.isNotificationEnabled()) {
      return false;
    }
    if (!(AuthService.isSubject() && AuthService.isLoggedIn())) {
      return false;
    }
    return true;
  }

  static async enableBrowserNotifications(dispatch) {
    const messagingId = await NotificationService.getToken(dispatch);
    if (!messagingId) {
      // Error - probably incognito mode / blocked by site settings. No graceful way to pick this up which is reliable
      dispatch(setNotificationPreference(NOTIFICATION_PREFERENCE.BLOCKED));
      return;
    }

    dispatch(setMessagingId(messagingId));

    const browserDetails = BrowserService.getBrowserDetails();
    const browserId = await BrowserService.getBrowserId();
    const browserSession = { ...browserDetails, browserId, messagingId };

    BrowserService.updateSubjectBrowserSession(browserSession);
    dispatch(setNotificationPreference(NOTIFICATION_PREFERENCE.ALLOWED));
  }

  static getMessagingIdFromState() {
    const state = store?.getState();
    return state?.notifications?.messagingId;
  }

  static async disableBrowserNotifications(dispatch) {
    const messagingId = NotificationService.getMessagingIdFromState();
    if (AuthService.isLoggedIn()) {
      try {
        const browserId = await BrowserService.getBrowserId();
        const browserSessionDelete = { browserId, messagingId };
        BrowserService.deleteSubjectBrowserSession(browserSessionDelete);
      } catch (error) {
        console.error(
          "NotificationService - Error deleting browser session from disable notification stage (Part expected)",
          error
        );
        // May give a 401 to the service call, as the isLoggedIn is basic as it stands,
        //  and as it may contain an expired or invalid token.
        // As such we don't mind if this errors, we just need to mae sure it doesn't block execution,
        //  so the catch block is intentionally empty.
      }
    }

    try {
      NotificationService.deleteToken(dispatch);
    } catch (error) {
      console.error(
        "NotificationService - Error deleting token from disable notification stage (Part expected)",
        error
      );
      // May give a 401 to the service call, as Firebase may have cleared the token already or give
      //  a random error anyway - loads of reports online about this
      // As such we don't mind if this errors, we just need to mae sure it doesn't block execution,
      //  so the catch block is intentionally empty.
    }

    dispatch(clearMessagingId());
    dispatch(removeAllNotifications());
    dispatch(setNotificationPreference(NOTIFICATION_PREFERENCE.DECLINED));
  }

  static async handleReceivedMessage(dispatch, payload) {
    const notification =
      NotificationService.transformReceivedMessagingPayload(payload);
    dispatch(addNotification(notification));
  }

  static transformReceivedMessagingPayload = (payload) => {
    // NOTE: Any changes to this method, should probably be reflected in the firebase-messaging-sw.js's transformReceivedMessagingPayload function
    if (!payload) {
      console.error("firebase-messaging-sw.js: No Payload in Received Message");
      return;
    }
    if (!payload.data?.notification) {
      console.error(
        "firebase-messaging-sw.js: No Notification Data in Received Message"
      );
      return;
    }
    const data = payload.data;
    let dataNotification = null;
    if (typeof data?.notification === "string") {
      dataNotification = JSON.parse(data?.notification);
    }
    const notification = {
      id: uuid_v4(),
      title: dataNotification?.title,
      body: dataNotification?.body,
      questionnaire: {
        id: data?.questionnaireId,
        type: data?.type,
      },
      baseUrl: data?.baseUrl,
    };
    return notification;
  };

  static async deleteAllReceivedNotifications(dispatch) {
    dispatch(removeAllNotifications());
  }
  static async deleteReceivedNotification(dispatch, id) {
    dispatch(removeNotification(id));
  }

  static processBackgroundMessage(dispatch, data) {
    if (!data) {
      return undefined;
    }
    const notificationJson = atob(data);
    if (!notificationJson) {
      return undefined;
    }
    const notification = JSON.parse(notificationJson);
    if (!notification) {
      return undefined;
    }

    dispatch(addNotification(notification));

    return NotificationService.navigateToQuestionnaire(notification);
  }

  static navigateToQuestionnaire(notification) {
    // FUTURE: This can be called from NotificationIcon items which have questionnaire type and id, or also ones passed in from the background service which also have that data.
    // const questionnaire = notification.questionnaire;
    // if (questionnaire.id && questionnaire.type) {
    //   // Future: URL to PRO directly with id and type - invoke the modal somehow
    //   // const url = "TODO";
    //   // return url;
    // }
    return undefined;
  }

  static resetNotifications(dispatch) {
    try {
      dispatch(resetState());
    } catch (error) {
      console.error("NotificationService Error resetting notification state");
    }
  }

  /**
   * FOR SUPER ADMIN ONLY
   * returns all notifications, not filtering for roles/groups
   */
  static searchNotificationActivityAudit(pageNo,from,to,notificationType,subjectCode, email, groupId, download){
    const queryParams = {
      from:from,
      to:to,
      notificationType:notificationType,
      subjectCode: !subjectCode || subjectCode==='' ? undefined : subjectCode,
      email: !email || email==='' ? undefined : email,
      groupId: groupId,
    }
    const queryParamsCleaned = _.omitBy(queryParams, _.overSome([_.isNil, _.isNaN]))

    if(download){
      return RequestHelper.send(
          process.env.REACT_APP_SERVER_ADDRESS +
          "/auditactivity/notifications/search/csv-export",
          {Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM},
          "GET",
          queryParamsCleaned
      );

    }else{
      return RequestHelper.send(
          process.env.REACT_APP_SERVER_ADDRESS +
          "/auditactivity/notifications/search/",
          {},
          "GET",
          {...queryParamsCleaned,
            pageNumber:pageNo,
            pageSize:20,
          }
      );
    }

  }
  static getEmailNotificationActivityAudit(pageNo,startDate,endDate,searchTerm){
    const queryParams = {
      pageNumber:pageNo,
      startDate:startDate,
      endDate:endDate,
      searchTerm: searchTerm
    }
      return RequestHelper.send(
          process.env.REACT_APP_SERVER_ADDRESS +
          "/auditactivity/list/email",
          {},
          "GET",
          queryParams
      );
    }
}
