import moment from "moment";
import Sugar from "sugar";

const INTERNATIONAL_DATE = "YYYY-MM-DD",
    FRIENDLY_DATE = "DD/MMM/YYYY",
    DATE_TIME = "YYYY-MM-DDTHH:mm:ss",
    DATE = "YYYY-MM-DD",
    FRIENDLY_MONTHYEAR = "MMM/YYYY",
    FRIENDLY_DATETIME = "DD/MMM/YYYY HH:mm:ss",
    FRIENDLY_DATETIME_TZ = "DD/MMM/YYYY HH:mm:ssZ";

export const DATE_FORMATS = {
  INTERNATIONAL_DATE,
  FRIENDLY_DATE,
  DATE_TIME,
  DATE,
  FRIENDLY_MONTHYEAR,
  FRIENDLY_DATETIME,
};


function getOffset() {
  return moment().format("ZZ");
}

function asDate(textDate) {
  let sugarDate = Sugar.Date.create(textDate);
  return Sugar.Date.format(sugarDate, "%F");
}

function asTime(textDate) {
  let sugarDate = Sugar.Date.create(textDate);
  return Sugar.Date.format(sugarDate, "%R");
}

function asDateTime(textDate) {
  let sugarDate = Sugar.Date.create(textDate);
  if (sugarDate) {
    return Sugar.Date.format(sugarDate, "{yyyy}-{MM}-{dd}T{HH}:{mm}");
  } else {
    return textDate;
  }
}

function inFuture(date) {
  return moment(date).isValid() && moment(date).isAfter(moment());
}

function getMedicationKeyForToday() {
  return internationalDateToday();
}

function getMedicationKeyForYesterday() {
  return internationalDateYesterday();
}

function getMedicationKeyFromDate(date) {
  return internationalDateFromDate(date);
}

function nowAsString() {
  return moment().format(DATE_TIME);
}

function nowAsMs() {
  return moment().valueOf();
}

function generateAsDate(fromNowAmount, fromNowUnits) {
  return moment().add(fromNowAmount, fromNowUnits).toDate();
}

function getCurrentHourAsInt() {
  return parseInt(moment().format("H"), 10);
}

function medicationNotificationAsDate(date, hour) {
  return moment(date).hour(hour).toDate();
}

function medicationNotificationId(date, slotNumber) {
  return parseInt(moment(date).format("YYYYMMDD") + slotNumber);
}

function internationalDateToday() {
  return moment().format(INTERNATIONAL_DATE);
}

function internationalDateYesterday() {
  return moment().subtract(1, "days").format(INTERNATIONAL_DATE);
}

function internationalDateFromDate(date) {
  return moment(date).format(INTERNATIONAL_DATE);
}

function yesterdayAtMidnightAsString() {
  return moment().add(-1, "day").startOf("day").format(DATE_TIME);
}

function fromEpochMillis(epochMillis){
  return moment(epochMillis)
}

function buildDateAsString(date, hour, minute, second, milliseconds) {
  if (date === undefined) {
    date = 0;
  }

  if (hour === undefined) {
    hour = 0;
  }

  if (minute === undefined) {
    minute = 0;
  }

  return moment(date)
    .hour(hour)
    .minute(minute)
    .format(DATE);
}

function buildDisplayDateAsString(date) {
  return moment(date).format(FRIENDLY_DATE);
}

function buildDisplayDateTimeAsString(date) {
  return moment(date).format(FRIENDLY_DATETIME);
}

function buildDisplayMonthYearAsString(date) {
  return moment(date).format(FRIENDLY_MONTHYEAR);
}
export default {
  getOffset: getOffset,
  parse: {
    asDate: asDate,
    asTime: asTime,
    asDateTime: asDateTime,
  },
  inFuture: inFuture,
  medicationKey: {
    today: getMedicationKeyForToday,
    yesterday: getMedicationKeyForYesterday,
    fromDate: getMedicationKeyFromDate,
  },
  now: {
    asString: nowAsString,
    asMs: nowAsMs,
    currentHour: getCurrentHourAsInt,
  },
  yesterday: {
    atMidnight: yesterdayAtMidnightAsString,
  },
  fromNow: {
    asDate: generateAsDate,
  },
  medicationNotification: {
    asId: medicationNotificationId,
    asDate: medicationNotificationAsDate,
  },
  internationalDate: {
    today: internationalDateToday,
    yesterday: internationalDateYesterday,
    fromDate: internationalDateFromDate,
  },
  fromEpochMillis: fromEpochMillis,
  build: {
    asString: buildDateAsString,
    asDisplayDate: buildDisplayDateAsString,
    asDisplayDateTime: buildDisplayDateTimeAsString,
    asDisplayMonthYear: buildDisplayMonthYearAsString,
    asDisplayDateTimeWithTz: (date)=>moment(date).format(FRIENDLY_DATETIME_TZ),
    asDate: buildDateAsString,
  },
  FRIENDLY: {
    DATETIME: FRIENDLY_DATETIME,
    DATE: FRIENDLY_DATE,
  },
};
