import React, {useCallback, useMemo, useState} from 'react';
import {Button, Form, FormField, Input, Label} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import LookupService from "../../../services/LookupService";

const LookupEditCard = ({lookup, language, onSubmit}) => {
  const {t} = useTranslation()

  const [code, setCode] = useState(lookup?.code || "")
  const [name, setName] = useState(lookup?.name || "")

  const isSaveAllowed = useMemo(()=>{
    return code && code?.length > 0 && name && name?.length > 0;
  }, [code, name])
  const handleSave = useCallback(async () => {
    await LookupService.editLookupByLanguageAndLookupCode(language, code, name);
    onSubmit && onSubmit();
  }, [code, onSubmit, language, name])


  return <div style={{display: "flex", padding: '16px'}}>
    <Form style={{width: '100%'}}>
      <Label style={{marginBottom: "16px"}}>{language}</Label>
      <FormField>
        <label>{t("LOOKUP_CODE", "Code")}</label>
        <Input fluid disabled value={code} onChange={(e, v)=>{setCode(v.value)}} />
      </FormField>
      <FormField>
        <label>{t("LOOKUP_NAME", "Name")}</label>
        <Input fluid value={name} onChange={(e, v)=>{setName(v.value)}} />
      </FormField>
      <Button
          disabled={!isSaveAllowed}
          primary={isSaveAllowed}
          onClick={handleSave}
      >{
        t("GLOBAL_SAVE", "Save")
      }</Button>
    </Form>
  </div>
}

export default LookupEditCard;