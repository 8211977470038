import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class AccountTypeSelector extends React.Component {
  handleClick = (e) => {
    const type = e.target.name;
    this.props.handleClick(type);
  };

  render() {
    const { t } = this.props;
    return (
      <Segment>
        <Form size="large">
          <Form.Field>
            <Button
              primary
              name="staff"
              onClick={this.handleClick}
              fluid
              size="large"
            >
              {t("LOGIN_AS_STAFF_BUTTON", "Log in as a clinician")}
            </Button>
          </Form.Field>
          <Form.Field>
            <Button
              primary
              name="subject"
              onClick={this.handleClick}
              fluid
              size="large"
            >
              {t("LOGIN_AS_SUBJECT_BUTTON", "Log in as a patient")}
            </Button>
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

export default withTranslation()(AccountTypeSelector);
