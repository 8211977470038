import { DateTime } from "luxon";

export default {
  isComplete: (questionnaire) => {
    return questionnaire.completionDate !== null;
  },
  isInDate: (questionnaire, subjectTimezone) => {
    const nowUtc = DateTime.utc();
    return (
      DateTime.fromISO(questionnaire.activationFromDate) < nowUtc &&
      (questionnaire.activationToDate === null ||
        DateTime.fromISO(questionnaire.activationToDate) > nowUtc ||
        DateTime.fromISO(questionnaire.closingDate, {
          zone: subjectTimezone,
          setZone: true,
        }) > DateTime.now())
    );
  },
  otherQuestionnaireWithSameDefinitionAndNewerActivationToDate: (
    questionnaire,
    allQuestionnaires
  ) => {
    for (var i = allQuestionnaires.length - 1; i >= 0; i--) {
      if (
        allQuestionnaires[i].completionDate === null &&
        questionnaire.id !== allQuestionnaires[i].id &&
        questionnaire.definitionId === allQuestionnaires[i].definitionId &&
        DateTime.fromISO(questionnaire.activationToDate)
          .diff(DateTime.fromISO(allQuestionnaires[i].activationToDate))
          .toObject().milliseconds < 0
      ) {
        return true;
      }
    }
    return false;
  },
};
