import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Button, Checkbox,
  Form,
  FormField,
  Grid,
  Input,
  Label,
  Loader,
  Message,
  TextArea
} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import LookupService from "../../../services/LookupService";

const LookupItemCreateCard = ({onSubmit, lookup}) => {
  const {t} = useTranslation()

  const [error, setError] = useState(false);
  const [lookupItemDataArray, setLookupItemDataArray] = useState(null)

  // lookups are retrieved instead of languages so we know which are present
  const [lookups, setLookups] = useState([])
  const getLookups = useCallback(async ()=>{
    const lookups = await LookupService.getLookupListByCode(lookup.code);
    setLookups(lookups);
  }, [lookup])
  useEffect(() => {
    getLookups();
  }, [getLookups]);
  useEffect(()=>{
    setLookupItemDataArray(lookups.map(l=>{
      // The lookup could be sent up itself, but the idea of the route
      // is to create items for a lookup code, not a number of different lookups.
      return {
        language: l.language,
        ref: "",
        value: "",
        data: ""
      }
    }));
  }, [lookups]);
  const hasLoaded = useMemo(()=>{
    return Array.isArray(lookups) && lookups?.length > 0
  }, [lookups])

  // changes
  const [isSpecifyingRef, setIsSpecifyingRef] = useState(false);
  useEffect(()=>{
    if(!isSpecifyingRef){
      setLookupItemDataArray(lIDA => {
        return lIDA.map(lID => {
          return {...lID, ref: ""}
        })
      })
    }
  }, [isSpecifyingRef])
  const handleNameChange = (index, key, value) => {
    setLookupItemDataArray(oldLookupData => {
      const newData = [...oldLookupData];
      newData[index][key] = value;
      return newData
    })
  }


  // submission
  const isSubmitAllowed = useMemo(()=>{
    if(!hasLoaded) return false;
    // unlike lookup, which is assumed to exist for every language,
    // the lookup item is allowed to be missing for some languages
    return lookupItemDataArray &&
        Array.isArray(lookupItemDataArray) &&
        lookupItemDataArray.some(lookupData => lookupData?.value?.length > 0 && lookupData?.data.length > 0 );
  }, [hasLoaded, lookupItemDataArray])
  const handleSave = useCallback(async () => {
    if(!isSubmitAllowed) return
    const lookupItemDataArrayToSubmit = lookupItemDataArray.filter(lID => lID?.value?.length > 0);
    try {
      await LookupService.createLookupItemsByLookupCode(lookup.code, lookupItemDataArrayToSubmit);
      if(error) setError(false);
      onSubmit && onSubmit();
    } catch (error) {
      console.error("[LookupCreateCard] Failed to create Lookup", error)
      setError(true)
    }
  }, [error, isSubmitAllowed, lookup.code, lookupItemDataArray, onSubmit])


  return <div style={{display: "flex", padding: '16px'}}>
    <Form style={{width: '100%'}}>
      {!hasLoaded && <Loader isActive /> }
      {hasLoaded && <FormField>
        <Label>{lookup.code}</Label>
        <Checkbox label={t("LOOKUP_ITEM_REF_LABEL", "Use Specific Ref?")} style={{paddingLeft: '1rem'}} checked={isSpecifyingRef} onChange={(e,v)=>setIsSpecifyingRef(v.checked)} />
        <Message>{t(
            "LOOKUP_ITEM_CREATE_WARNING",
            "Lookup Items with blank values will not be created. If related lookups are required create them all at this time. Adding more to this relation is not yet supported through the UI.")}
        </Message>
        <h4>{t("LOOKUP_NAME", "Lookup Items")}</h4>
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}></Grid.Column>
            {isSpecifyingRef && <Grid.Column width={4}>
              <label>{t("LOOKUP_ITEM_REF", "Ref")}</label>
            </Grid.Column>}
            <Grid.Column width={isSpecifyingRef ? 4 : 6}>
              <label>{t("LOOKUP_ITEM_VALUE", "Value")}</label>
            </Grid.Column>
            <Grid.Column width={isSpecifyingRef ? 6 : 8}>
              <label>{t("LOOKUP_ITEM_DATA", "Data")}</label>
            </Grid.Column>
          </Grid.Row>
          {lookupItemDataArray.map((lookupData, index) =>
              <Grid.Row key={"lookup-"+index}>
                <Grid.Column width={2}><label>{lookupData.language}</label></Grid.Column>
                {isSpecifyingRef && <Grid.Column width={4}>
                  <Input
                      fluid
                      value={lookupData.ref}
                      onChange={(e, v) => handleNameChange(index, "ref", v.value)}/>
                </Grid.Column>}
                <Grid.Column width={isSpecifyingRef ? 4 : 6}>
                  <Input
                    fluid
                    value={lookupData.value}
                    onChange={(e, v) => handleNameChange(index, "value", v.value)}/>
                </Grid.Column>
                <Grid.Column width={isSpecifyingRef ? 6 : 8}>
                  <TextArea
                    fluid
                    rows={1}
                    value={lookupData.data}
                    onChange={(e, v) => handleNameChange(index, "data", v.value)}/>
                </Grid.Column>
              </Grid.Row>
          )}
        </Grid>
      </FormField>}
      {error && <Message color={"red"} >{t("LOOKUP_CREATE_ERROR_MESSAGE", "There was an error creating the lookups, please contact suppport.")}</Message> }
      <Button
          disabled={!isSubmitAllowed}
          primary={isSubmitAllowed}
          onClick={handleSave}
      >{
        t("GLOBAL_SAVE", "Save")
      }</Button>
    </Form>
  </div>
}

export default LookupItemCreateCard;