import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import Page from "../../../components/page/Page";
import { getProDefinitions } from "../../../redux/questionnaires/questionnaireDefinitionsSlice";
import {
  getCurrentPros,
  getCompletedPros,
  getStatus,
} from "../../../redux/questionnaires/subjectQuestionnairesSlice";
import SubjectAppStateService from "../../../services/SubjectAppStateService";
import SubjectQuestionnaireList from "../../../components/lists/SubjectQuestionnaireList";
import {
  byDecreasingCompletionDate, bySequenceAndById
} from "../../../helpers/sortFunctions";

const ProListPage = (props) => {
  const { t, pros, completed, proDefinitions } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    SubjectAppStateService.getSubjectQuestionnairesFromServer(dispatch);
  }, [dispatch]);

  const nonModuleFilter = (definition) => {
    const hasModule =
      definition.config?.modules && definition.config.modules.length > 0;
    return !hasModule;
  };

  const nonModuleDefinitions = proDefinitions.filter(nonModuleFilter);

  const nonModulePros = pros.filter((pro) => {
    const definition = nonModuleDefinitions.find(
      (def) => def.id === pro.definitionId
    );
    return !!definition;
  });
  const nonModuleCompleted = completed.filter((pro) => {
    const definition = nonModuleDefinitions.find(
      (def) => def.id === pro.definitionId
    );
    return !!definition;
  });

  return (
    <Page
      name="PRO_LIST_PAGE"
      header={t("PRO_LIST_PAGE_HEADER")}
      maxWidth="800px"
    >
      <SubjectQuestionnaireList
        questionnaireList={nonModulePros}
        definitionList={nonModuleDefinitions}
        sort={bySequenceAndById}
        showEmpty
      />
      {completed.length > 0 && (
        <SubjectQuestionnaireList
          title={t("GLOBAL_COMPLETED")}
          questionnaireList={nonModuleCompleted}
          definitionList={nonModuleDefinitions}
          sort={byDecreasingCompletionDate}
        />
      )}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    proStatus: getStatus(state),
    pros: getCurrentPros(state),
    completed: getCompletedPros(state),
    proDefinitions: getProDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(ProListPage);
