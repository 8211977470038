import React, {Fragment, useEffect, useState, useContext, useCallback, useMemo} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import SubjectService from "../../SubjectService";
import {Grid, Placeholder} from "semantic-ui-react";
import SubjectQuestionnaireMenu from "./SubjectQuestionnaireMenu";
import AuthService from "../../services/AuthService";
import ConfigContext from "../../context/ConfigContext";
import subjectMenuItemsHelper from "../../helpers/subjectMenuItemsHelper";
import SubjectDataTable from "./SubjectDataTable";
import SubjectDataWorkflowTables from "./SubjectDataWorkflowTables";
import SecondaryButton from "../../components/generic/SecondaryButton";

const SubjectViewQuestionnaireTab = (props) => {
  const [questionnaireDefinitions, setQuestionnaireDefinitions] = useState([]);
  const [isSubjectDataTableLoading, setIsSubjectDataTableLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const {t} = useTranslation();
  
  const config = useContext(ConfigContext);
  const subjectId = props.match.params.subjectId;
  const questionnaireType = props.match.params.questionnaireType;

  const setParentLoadingState = props.loadingState;

  useEffect(() => {
    setParentLoadingState(true); //sets parent component loading state
    const init = async () => {
      try {
        setLoading(true);

        const definitions = await SubjectService.getQuestionnaireDefinitions(questionnaireType);
        if (questionnaireType === props.match.params.questionnaireType) {
          const definitionsInfo = await Promise.all(
            definitions.map(({ code }) => {
              return SubjectService.getSubjectQuestionnairesCountAll(
                subjectId,
                code
              ).then((response) => {
                return { code, count: response.count };
              });
            })
          );
          let newQuestionnaireCountObject = {};
          definitionsInfo.forEach(({ code, count }) => {
            newQuestionnaireCountObject[code] = count;
          });

          const pathwayAnswer = await SubjectService.getSubjectPathway(subjectId);
          const isSuperAdmin = await AuthService.getIsSuperAdmin();

          setQuestionnaireDefinitions(
            definitions.filter((def) => {
              return subjectMenuItemsHelper.shouldShowDefinition(
                def,
                pathwayAnswer ? pathwayAnswer : [],
                newQuestionnaireCountObject,
                isSuperAdmin,
                config,
                undefined
              );
            })
          );
        }
        setLoading(false);
        setParentLoadingState(false);
      } catch (error) {
        errorHandler(error);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, questionnaireType, subjectId, props.match.params.questionnaireType, setParentLoadingState]);

  const errorHandler = (error) => {
    console.error(error);
    setLoading(false);
    setParentLoadingState(false);
  };

  const handleSubjectDataTableLoadingChanged = useCallback((isLoading) => {
    if(isLoading !== undefined) {
      setIsSubjectDataTableLoading(isLoading);
    }
  },[]);

  let selectedDefinitionCode = null;
  if (props.match.params.definitionCode) {
    selectedDefinitionCode = props.match.params.definitionCode;
  } else if (questionnaireDefinitions.length > 0) {
    selectedDefinitionCode = questionnaireDefinitions[0].code;
  }

  const selectedDefinition = useMemo(() => {
    return questionnaireDefinitions.find(qD => qD.code === selectedDefinitionCode)
  }, [questionnaireDefinitions, selectedDefinitionCode]);
  const hasAssociatedWorkflow = selectedDefinition?.questionnaireWorkflowDefinition !== undefined;
  useEffect(()=>setIsInWorkflowMode(hasAssociatedWorkflow),[hasAssociatedWorkflow])

  const [isInWorkflowMode, setIsInWorkflowMode] = useState(hasAssociatedWorkflow);
  const toggleWorkflowDisplay = () => setIsInWorkflowMode(!isInWorkflowMode);

  const noQuestionnaires = questionnaireDefinitions.length === 0 && !loading;

  return (
    <Fragment>
      {loading && (
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      )}

      {noQuestionnaires && (<p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY")}</p>)}

      {!loading && !noQuestionnaires && (
        <Grid>
          <Grid.Column width={3}>
            <SubjectQuestionnaireMenu
              selectedDefinitionCode={selectedDefinitionCode}
              subjectId={subjectId}
              questionnaireType={questionnaireType}
              definitions={questionnaireDefinitions}
              loading={loading}
              disabled={isSubjectDataTableLoading}
            />
          </Grid.Column>
          <Grid.Column width={13}>
            {hasAssociatedWorkflow && <SecondaryButton style={{marginBottom: "1rem"}} width={'100%'} onClick={toggleWorkflowDisplay}>
              {isInWorkflowMode && t("WORKFLOW_DISPLAY_TOGGLE_BUTTON_ON", "Workflow Display")}
              {!isInWorkflowMode && t("WORKFLOW_DISPLAY_TOGGLE_BUTTON_OFF", "Default Display")}
            </SecondaryButton>}
            {(!hasAssociatedWorkflow || !isInWorkflowMode) && <SubjectDataTable
                subjectId={subjectId}
                definitions={questionnaireDefinitions}
                questionnaireType={questionnaireType}
                questionnaireDefinitions={questionnaireDefinitions}
                definitionCode={selectedDefinitionCode}
                history={props.history}
                match={props.match}
                componentStillLoading={handleSubjectDataTableLoadingChanged}
                hideAnswers={false}
            />}
            {(hasAssociatedWorkflow && isInWorkflowMode) && <SubjectDataWorkflowTables
                subjectId={subjectId}
                definitions={questionnaireDefinitions}
                questionnaireType={questionnaireType}
                questionnaireDefinitions={questionnaireDefinitions}
                definitionCode={selectedDefinitionCode}
                history={props.history}
                match={props.match}
                componentStillLoading={handleSubjectDataTableLoadingChanged}
            />}
          </Grid.Column>
        </Grid>
      )}
    </Fragment>
  );
};

export default withTranslation()(SubjectViewQuestionnaireTab);
