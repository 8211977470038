import React, {Fragment, useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
import {Placeholder, Segment} from "semantic-ui-react";
import SensorDataService from "../../../services/sensordata/SensorDataService";
import {addPeriod, ensureXAxisShows} from "../../../services/helpers/graphHelper";
import moment from "moment";
import LineChart from "./chartcomponents/generic/LineChart";
import PeriodSelection from "./PeriodSelection";

const SubjectSpo2Graph  = ({subjectId, t}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [dataPoints, setDataPoints] = useState([]);
  const [fromDate, setFromDate] = useState(moment().clone().add(-1, 'week').startOf('day'));
  const [period, setPeriod] = useState('week');

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [subjectId, fromDate, period])

  const getData = async () => {

    try {
      setLoading(true)
      const toDate=addPeriod(fromDate, period)
      const dataStandard = await SensorDataService.getSpo2(subjectId, fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'none', null, 'none');
      let timeSeriesData = [];
      if (dataStandard.timeSeries) {
        dataStandard.timeSeries.forEach(({timestamp, value}) => {
          timeSeriesData.push({x: timestamp, y: value});
        });
      }
      timeSeriesData = ensureXAxisShows(timeSeriesData, fromDate, toDate)
      setDataPoints(timeSeriesData)

      setLoading(false)
    } catch (e) {
      console.error('Error loading SPO2 data', e);
      setError(true)
      setLoading(false)
    }
  };


    return (
      <Fragment>
        {loading && (
          <Segment>
            <Placeholder fluid>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        )}

        {!loading && (
          <>
            <PeriodSelection setFromDate={setFromDate} fromDate={fromDate} period={period} setPeriod={setPeriod} />

            {error && <p>{t("SENSOR_DATA_ERROR", "Error while fetching data, if the problem persists contact support")}</p>}

            {(!dataPoints || dataPoints.length === 0) && (
                <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY", "There is currently no data to display for selected period")}</p>
            )}

            {dataPoints && dataPoints.length > 0 && (
              <LineChart
              subjectId={subjectId}
              dataPoints={dataPoints}
              label={t("SUBJECT_GRAPH_SPO2", "SPO2")}
              yLabel={t("GRAPH_SPO2_Y_LABEL", "")}
              color={'steelblue'}
              />
            )}
          </>
        )}
      </Fragment>
    );

}

export default withTranslation()(SubjectSpo2Graph);
