import React, {Fragment, useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
import {Placeholder, Segment} from "semantic-ui-react";
import ActivityChart from "./chartcomponents/ActivityChart";
import moment from "moment";
import {addPeriod, ensureXAxisShows} from "../../../services/helpers/graphHelper";
import StackedBarChart from "./chartcomponents/generic/StackedBarChart";
import SensorDataService from "../../../services/sensordata/SensorDataService";
import PeriodSelection from "./PeriodSelection";

const SubjectActivityGraph = ({subjectId, t}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [steps, setSteps] = useState([])
  const [distance, setDistance] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [fromDate, setFromDate] = useState(moment().clone().add(-1, 'week').startOf('day'));
  const [period, setPeriod] = useState('week');

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [subjectId, fromDate, period])

  const getData = async () => {
    setLoading(true)
    try{
      const stepsData = await SensorDataService.getActivity(subjectId, 'steps', fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'day_sum', null, 'none')
      let steps = [];
      stepsData.timeSeries.forEach(({timestamp, value}) => {
        steps.push({ x: timestamp, y: value });

      });
      const toDate=addPeriod(fromDate, period)
      steps = ensureXAxisShows(steps, fromDate, toDate)
      setSteps(steps)
      const distanceData = await SensorDataService.getActivity(subjectId, 'distance', fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'day_sum', null, 'none')
      let distance = distanceData.timeSeries.map(({timestamp, value}) => {
        return { x: timestamp, y: value/1000 }
      })
      distance = ensureXAxisShows(distance, fromDate, toDate)

      setDistance(distance)


      const walkingData = await SensorDataService.getActivityTypes(subjectId, 'WALKING', fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'day_sum', null, 'none')
      let walking = walkingData.timeSeries.map(({timestamp, value}) => {
        return { x: timestamp, y: value }
      })
      walking = ensureXAxisShows(walking, fromDate, toDate)

      const sedentaryData = await SensorDataService.getActivityTypes(subjectId, 'SEDENTARY', fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'day_sum', null, 'none')
      let sedentary = sedentaryData.timeSeries.map(({timestamp, value}) => {
        return { x: timestamp, y: value }
      })
      sedentary = ensureXAxisShows(sedentary, fromDate, toDate)

      const genericData = await SensorDataService.getActivityTypes(subjectId, 'GENERIC', fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'day_sum', null, 'none')
      let generic = genericData.timeSeries.map(({timestamp, value}) => {
        return { x: timestamp, y: value }
      })
      generic = ensureXAxisShows(generic, fromDate, toDate)

      setActivityTypes([
        {
          values:sedentary,
          color:'steelblue',
          title:t('ACTIVITY_TYPE_SEDENTARY', 'Sedentary')
        },
        {
          values:walking,
          color:'orange',
          title:t('ACTIVITY_TYPE_WALKING', 'Walking')
        },
        {
          values:generic,
          color:'gray',
          title:t('ACTIVITY_TYPE_GENERIC', 'Generic')
        }])
      setLoading(false)
    }catch (e) {
      errorHandler(e)
    }

  };

  const errorHandler = (error) => {
    console.error(error);
    setLoading(false)
    setError(true)
  };




    return (
      <Fragment>
        {loading && (
          <Segment>
            <Placeholder fluid>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        )}

        {!loading && (
          <>
            <PeriodSelection setFromDate={setFromDate} fromDate={fromDate} period={period} setPeriod={setPeriod} />

            {error && <p>{t("SENSOR_DATA_ERROR", "Error while fetching data, if the problem persists contact support")}</p>}
            {!steps && (
                <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY")}</p>
            )}

            {steps && (
              <ActivityChart
                title={t("GRAPH_ACTIVITY_STEPS_TITLE", 'Steps per Day')}
                yAxis={t("GRAPH_ACTIVITY_STEPS_Y_LABEL", 'Steps Count')}
                subjectId={subjectId}
                activityData={steps}
                color={'palegreen'}
              />
            )}

            {!distance && (
                <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY")}</p>
            )}
            {distance && (
                <ActivityChart
                    title={t("GRAPH_ACTIVITY_DISTANCE_TITLE", 'Distance per Day')}
                    yAxis={t("GRAPH_ACTIVITY_DISTANCE_Y_LABEL", 'km')}
                    subjectId={subjectId}
                    activityData={distance}
                    color={'palegreen'}
                />
            )}

            {activityTypes && (
            <StackedBarChart title={t("GRAPH_ACTIVITY_ACTIVITY_TYPES_TITLE", 'Activity types per day')}
                             yAxis={t("GRAPH_ACTIVITY_ACTIVITY_TYPES_Y_LABEL", 'minutes')}
                             subjectId={subjectId}
                             dataList={activityTypes}
            />
            )}
          </>
        )}
      </Fragment>
    );

}

export default withTranslation()(SubjectActivityGraph);
