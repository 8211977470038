import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export default function GenericRadarChart({ data, options }) {
  const baseOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        color: "#444",
        min: 0,
        width: 2,
        pointLabels: {
          color: "#444",
          font: {
            size: 14,
            family: "Poppins",
          },
        },
        angleLines: {
          lineWidth: 2,
        },
        grid: {
          borderWidth: 3,
          lineWidth: 2,
        },
        ticks: {
          backdropPadding: { top: 4, left: 4, bottom: 2, right: 4 },
          backdropColor: "rgba(255,255,255,0.5)",
          textStrokeWidth: 40,
          font: {
            size: 14,
            family: "Poppins",
          },
        },
      },
    },
    elements: {
      line: {
        spanGaps: false,
      },
    },
  };
  return <Radar data={data} options={baseOptions} />;
}
