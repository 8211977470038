import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import BatchAttachmentService from '../../../../services/BatchAttachmentService';
import { Dropzone, FileMosaic } from '@files-ui/react';
import AuthService from '../../../../services/AuthService';
import { Loader, Segment } from 'semantic-ui-react';

const MultiFileSelector = (props) => {
  const { t } = props;

  const [uploadConfiguration, setUploadConfiguration] = useState();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [requestHeaders, setRequestHeaders] = useState(null);

  useEffect(() => {
    populateConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);

  const populateConfig = async () => {
    const cfg = await BatchAttachmentService.getUploadConfiguration();
    cfg.allowedFilePattern = cfg.allowedFilePattern != null ? cfg.allowedFilePattern : 'image/*, video/*';
    cfg.maxNumberOfFiles = cfg.maxNumberOfFiles != null ? cfg.maxNumberOfFiles : 15;
    cfg.maxFileSize = cfg.maxFileSize != null ? cfg.maxFileSize : (180 * 1024 * 1024); // pre-existing yml file value
    setUploadConfiguration(cfg);
  };

  useEffect(() => {
    // Force dropzone to use latest headers and token as wasn't redrawing to refresh by passing in AuthService.getAuthToken() directly
    setRequestHeaders({
      Authorization: 'Bearer ' + AuthService.getAuthToken()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [AuthService.getAuthToken()]);

  const validateFile = async (file) => {
    // Ideally this would be done by specifying validator={validateFile} on the dropzone - but it does not allow us to use async methods for that.
    // So we have to watch for the upload starting/stopping/failing via onChange, and use those values to determine if freshly dropped files.
    // As change is fired on drop, and on upload and on each item's upload

    // Check to see if we already have the same filename
    const doesFileExist = await BatchAttachmentService.doesFileExistOnServer(file.name);
    if (doesFileExist) {
      return {
        valid: false,
        errors: [t('MULTIFILESELECTOR_ERRORS_DUPLICTEFILENAME', 'Duplicate filename')]
      };
    }
    return { valid: true };
  }

  const handleSelectedFilesChange = async (files) => {
    for (const file of files) {
      // valid is a bool set by files-ui based on type, size etc. So we can ignore those that are not valid, and those that are uploaded.
      if (file.valid && file.uploadStatus !== 'success') {
        const validationResult = await validateFile(file);
        file.valid = validationResult.valid;
        if (!file.valid) {
          if (!Array.isArray(file.errors)) {
            file.errors = [];
          }
          file.errors.push(validationResult.errors);
        }
      }
    }
    setSelectedFiles(files)
  };

  if (uploadConfiguration == null) {
    return <Loader />;
  }

  return (
    <>
      <Segment>
        {t('MULTIFILESELECTOR_INTRO', 'You can drag multiple files into the area below, or you click and it will show you a file selector window. Any files you select will be validated based on file type, file size, duplicate files. Any which are not valid will be automatically removed from the selection.')}
      </Segment>
      <Dropzone
        onChange={handleSelectedFilesChange}
        minHeight='200px'
        value={selectedFiles}
        accept={uploadConfiguration.allowedFilePattern}
        maxFiles={uploadConfiguration.maxNumberOfFiles}
        maxFileSize={uploadConfiguration.maxFileSize}
        label={t('MULTIFILESELECTOR_LABEL', 'Drag files here, or click to see a file selector window')}
        uploadConfig={{
          autoUpload: false,
          cleanOnUpload: true,
          url: BatchAttachmentService.getUploadFilesUrl(),
          headers: requestHeaders,
        }}
        actionButtons={{
          position: 'after',
          abortButton: { resetStyles: true, className: 'ui button secondary' },
          deleteButton: { resetStyles: true, className: 'ui button prsecondaryimary' },
          uploadButton: { resetStyles: true, className: 'ui button primary' },
        }}
      >
        {selectedFiles.map((file) => (
          <FileMosaic
            {...file}
            key={file.id}
            resultOnTooltip
            info
            onDelete={(fileId) => {
              const idx = selectedFiles.findIndex(i => i.id === fileId);
              if (idx > -1) {
                const updatedFiles = [...selectedFiles];
                updatedFiles.splice(idx, 1);
                setSelectedFiles(updatedFiles);
              }
            }}
          />
        ))}
      </Dropzone>
    </>
  );
};

export default withTranslation()(MultiFileSelector);
