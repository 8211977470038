const getMatchFromCode = (regex, searchString, groupIndex) => {
  const matches = searchString.match(regex)
  if(!matches) return null

  if(matches.length > groupIndex && matches[groupIndex].length > 1){
    return matches[groupIndex]
  }

  return null;
}

export default getMatchFromCode;