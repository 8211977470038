import React from "react";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import ClientsOptions from "./ClientsOptions";

const ClientsDropdown = (props) => {

  const { t, disabled, multiple, selectedDropdown, onlyDefaultClients, selected } = props;

  const dropdownChangeHandler = (data) => {
    props.onChangeHandler(data);
  }

  const options = ClientsOptions.getAllValues(onlyDefaultClients);

  return (
      <Dropdown
        disabled={disabled}
        transparent={disabled}
        placeholder={t("ADMIN_CONFIG_DROPDOWN_PLACEHOLDER", "Select client")}    
        fluid
        selection
        multiple={multiple}
        value={multiple ? selectedDropdown : selected}
        onChange={(_e, data) => dropdownChangeHandler(data)}    
        options={options} />
  );
}

export default withTranslation()(ClientsDropdown);