import React, { useEffect, useState, useContext } from "react";
import {withTranslation} from "react-i18next";
import {Grid, Icon, Loader, Message, Popup, Table} from "semantic-ui-react";
import Page from "../../components/page/Page";
import UserContext from "../../context/UserContext";
import Pagination from "semantic-ui-react/dist/commonjs/addons/Pagination";

import RemoteJobQueueService from "../../services/RemoteJobQueueService";

function AdminRemoteJobProcessingQueueListPage({t}){
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [jobQueueEntries, setJobQueueEntries] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(0);


    const user = useContext(UserContext);
    const isSuperAdmin = user.profile.superAdmin;
    const pageSize = 50;


    useEffect( () => {
        async function init() {
            try {
                setLoading(true);
                setJobQueueEntries( await RemoteJobQueueService.getJobQueues( pageNo - 1, pageSize));
                setTotalPages( Math.ceil( await RemoteJobQueueService.getJobQueueCount() / pageSize));
            } catch (err) {
                setError(true)
            } finally {
                setLoading(false);
            }
        }
        init();
    }, [pageNo]);


    const handlePaginationChange = (e, { activePage }) => {
        setPageNo(activePage)
    }

    const reprocessJob = async ( jobId) => {
        await RemoteJobQueueService.reprocessJob(jobId);
        setJobQueueEntries( await RemoteJobQueueService.getJobQueues( pageNo - 1, pageSize));
    }

    const manultriggerJob = async (jobId) => {
        await RemoteJobQueueService.manuallyTriggerJob(jobId);
        setJobQueueEntries( await RemoteJobQueueService.getJobQueues( pageNo - 1, pageSize));
    }

    const createTable = () => {

        const headers = [
            {title: ["ADMIN_JOBQUEUE_LIST_ID", "id"], config: {width: 1}},
            {title: ["ADMIN_JOBQUEUE_LIST_QUEUENAME", "Queue Name"], config: {width: 1}},
            {title: ["ADMIN_JOBQUEUE_LIST_LASTUPDATED", "Last Updated"], config: {width: 1}},
            {title: ["ADMIN_JOBQUEUE_LIST_CREATEDATE", "Create Date"], config: {width: 1}},
            {title: ["ADMIN_JOBQUEUE_LIST_STATE", "State"], config: {width: 1}},
            {title: ["ADMIN_JOBQUEUE_LIST_NEXTTRIGGERTIME", "Next Trigger Time"], config: {width: 1}},
            {title: ["ADMIN_JOBQUEUE_LIST_STATUSMSG", "Status Message"], config: {width: 2}},
            {title: ["ADMIN_JOBQUEUE_LIST_ATTACHMENT_COUNT", "Associated Attachments"], config: {width: 1}},
            {title: ["ADMIN_JOBQUEUE_LIST_ACTION", "Action"], config: {width: 1}}
        ];

        const headerRow = (
            <Table.Header>
                <Table.Row>
                    {headers.map((header,index) => (
                        <Table.HeaderCell key={index} {...header?.config}>
                            {t(header.title)}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
        );

        const configListRows = jobQueueEntries?.map((jobQueueItem) => {
            return (
                <Table.Row
                    key={jobQueueItem.id}>
                    <Table.Cell >
                        {jobQueueItem.id}
                    </Table.Cell>
                    <Table.Cell >
                        {jobQueueItem.queueName}
                    </Table.Cell>
                    <Table.Cell >
                        { new Date(jobQueueItem.lastmodifieddate).toUTCString()}
                    </Table.Cell>
                    <Table.Cell >
                        { new Date(jobQueueItem.createddate).toUTCString()}
                    </Table.Cell>
                    <Table.Cell >
                        {jobQueueItem.state}
                    </Table.Cell>
                    <Table.Cell >
                        { new Date(jobQueueItem.nexttriggertime).toUTCString()}
                    </Table.Cell>
                    <Table.Cell >
                        { jobQueueItem.statusmessage}
                    </Table.Cell>
                    <Table.Cell >
                        {jobQueueItem.attachmentcount}
                    </Table.Cell>
                    <Table.Cell>
                        { (jobQueueItem.state !== 'QUEUING' && jobQueueItem.state !== 'REPROCESS' && jobQueueItem.state !== 'EXECUTING'
                                && jobQueueItem.state !== 'PREPARING_JOB')  &&
                            <Popup trigger={<Icon style={{cursor: "pointer"}} color="orange" onClick={(e) => {
                                e.stopPropagation(); reprocessJob(jobQueueItem.id)
                            }} name='redo'/>} position="top center">
                                {t("ADMIN_JOBQUEUE_LIST_REPROCES_QUEUE", "Reprocess the Job")}
                            </Popup>
                        }
                        {jobQueueItem.state === 'QUEUING' && jobQueueItem.attachmentcount > 0  && jobQueueItem.manualtrigger !== true &&
                            (<Popup trigger={<Icon style={{cursor: "pointer"}} color="orange" onClick={(e) => {
                                e.stopPropagation(); manultriggerJob(jobQueueItem.id)
                            }} name='play'/>} position="top center">
                                {t("ADMIN_JOBQUEUE_LIST_MANTRIGGER_QUEUE", "Manually trigger job")}
                            </Popup>)}
                    </Table.Cell>

                </Table.Row>

            )}
        );

        return (
            <>
                {headerRow}
                <Table.Body>
                    {configListRows}
                </Table.Body>
            </>
        );
    }

    return (
        <Page
            name="Remote Job Processing Queues"
            header={t("ADMIN_JOBQUEUE_LIST_PAGETITLE", "Remote Job Processing Queues")}>
            {error && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={"Error, if it persist contact support:" + error.message}
                />
            )}
            {loading && (
                <Loader active={loading} />
            )}
            {!isSuperAdmin && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
                />
            )}

            <Grid>
                <Grid.Row>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={4}>
                        { jobQueueEntries && (
                        <Pagination
                            activePage={pageNo}
                            onPageChange={handlePaginationChange}
                            totalPages={totalPages}
                            disabled={typeof jobQueueEntries === 'undefined' || jobQueueEntries.length === 0}
                        />)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table fixed id={"adminRemoteJobProcessingQueueList"}>
                {createTable()}
            </Table>

        </Page>)

}

export default withTranslation()(AdminRemoteJobProcessingQueueListPage);
