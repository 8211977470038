import React from "react";
import DOMPurify from "dompurify";
import withContainer from "./hocs/withContainer";

const Heading = ({ question }) => {
  if (question?.config?.useMarkup) {
    return (
      <h2
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(question.label),
        }}
      />
    );
  }
  return <h2 className="questionHolder">{question.label}</h2>;
};

export default withContainer(Heading);
