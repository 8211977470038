import React from "react";
import { withTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";

const AttachmentPreviewUnsupportedFormatError = ({ t }) => {
  return (
    <div>
      <Message info visible>
        {t("MEDIA_PREVIEW_UNSUPPORTED_ERROR_TEXT", "This file type cannot be displayed in the browser")}
      </Message>
    </div>
  );
};

export default withTranslation()(AttachmentPreviewUnsupportedFormatError);
