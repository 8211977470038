import RequestHelper from "../RequestHelper";
import InternationalisationService from "../InternationalisationService";

export default class ModuleService {
  static getModules() {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + "/modules",
        {
          "Accept-Language": InternationalisationService.getLanguage(),
        },
        "GET",
        null
    );
  }
}