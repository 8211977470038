import React from 'react';
import { withTranslation } from 'react-i18next';

const AdditionalContent = ({content, configStyle}) => (
  <>
    {typeof content === 'string' &&
      <div style={{ marginTop: '3px', padding: '4px' }}>
        <div style={configStyle}>{content}</div>        
      </div>}
  </>
);

export default withTranslation()(AdditionalContent);