import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../../../components/page/Page';
import GroupPermission from '../../../GroupPermission';
import PermissionsService from '../../../services/PermissionsService';
import { Link, Redirect } from 'react-router-dom';
import MultiFileSelector from './components/MultiFileSelector';
import { Button } from 'semantic-ui-react';

const BatchUploadFileSelector = (props) => {
  const { t } = props;
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    populatePermissions();
  }, []);

  const populatePermissions = async () => {
    const requiredPermissionKeys = [
      GroupPermission.BATCH_ATTACHMENTS_VIEW,
      GroupPermission.BATCH_ATTACHMENTS_UPLOAD_FILES,
    ];

    const result = {};
    for (const permissionKey of requiredPermissionKeys) {
      const hasPermission = await PermissionsService.hasPermissionInAnyGroup(permissionKey);
      result[permissionKey] = hasPermission;
    }

    setPermissions(result);
  };

  if (permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_VIEW] !== true) {
    return <Redirect to='/' />;
  }

  return (
    <Page
      name='BATCHUPLOADFILES'
      header={t('BATCHUPLOADFILES_TITLE', 'Batch upload - upload attachment files')}
    >
      {permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_UPLOAD_FILES] === true && (
        <MultiFileSelector />
      )}
      <div style={{ marginTop: 50 }}>
        <Button
          as={Link}
          to={'/app/attachments/batchupload'}
        >
          {t('GLOBAL_BUTTON_BACK', 'Back')}
        </Button>
      </div>
    </Page>
  );
};

export default withTranslation()(BatchUploadFileSelector);
