export default {
  getAuditDisplayForStaff(staff, config) {
    let auditLastUpdatedBy = "";
    if (staff && staff.auditLastUpdatedBy) {
      if (config?.audit?.staffAuditIdentifier) {
        switch (config?.audit?.staffAuditIdentifier) {
          case "EMAIL":
            auditLastUpdatedBy = staff.auditLastUpdatedByEmail;
            break;
          case "FULL_NAME":
            auditLastUpdatedBy =
              staff.auditLastUpdatedByFirstName +
              " " +
              staff.auditLastUpdatedByLastName;
            break;

          default:
            auditLastUpdatedBy = staff.auditLastUpdatedBy;
            break;
        }
      }
      if (!auditLastUpdatedBy || auditLastUpdatedBy.length === 0) {
        auditLastUpdatedBy = staff.auditLastUpdatedBy;
      }
    }
    return auditLastUpdatedBy;
  },
  getAuditDisplayForSubjectData(subjectData, config) {
    let auditLastUpdatedBy = "";
    if (subjectData && subjectData.audit_lastupdatedby) {
      if (config?.audit?.staffAuditIdentifier) {
        switch (config?.audit?.staffAuditIdentifier) {
          case "EMAIL":
            auditLastUpdatedBy = subjectData.audit_lastupdatedbyemail;
            break;
          case "FULL_NAME":
            auditLastUpdatedBy =
              subjectData.audit_lastupdatedbyfirstname +
              " " +
              subjectData.audit_lastupdatedbylastname;
            break;

          default:
            auditLastUpdatedBy = subjectData.audit_lastupdatedby;
            break;
        }
      }
      if (!auditLastUpdatedBy || auditLastUpdatedBy.length === 0) {
        auditLastUpdatedBy = subjectData.audit_lastupdatedby;
      }
    }
    return auditLastUpdatedBy;
  },
};
