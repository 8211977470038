import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Grid, Header, Image, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import BrandingService from "../../services/BrandingService";
import { compose } from "redux";
import ConfigContext from "../../context/ConfigContext";
import SUBJECT_AUTHENTICATION_STRATEGY from "../../constants/SUBJECT_AUTHENTICATION_STRATEGY";
import TrialService from "../../TrialService";
import InternationalisationService from "../../InternationalisationService";

const ForgottenPasswordModalInsert = (props) => {
  const { t, handleForgottenPassword } = props;

  const [username, setUsername] = useState("");
  const accountType = AuthService.getAccountType();
  const [requestPending, setRequestPending] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const config = useContext(ConfigContext);

  const [subjectAuthenticationStrategy, setSubjectAuthenticationStrategy] =
    useState(TrialService.getDefaultSubjectAuthenticationStrategy());

  const fetchTrialAuthenticationSettings = async () => {
    setSubjectAuthenticationStrategy(
      await TrialService.getSubjectAuthenticationStrategy()
    );
  };

  useEffect(() => {
    fetchTrialAuthenticationSettings();
  }, []);

  let brandImageUrl;
  if (config?.ui?.onboardingBrandImage) {
    brandImageUrl = BrandingService.getBrandImageForOnBoarding();
  }

  useEffect(() => {
    AuthService.setAccountType(accountType);
  }, [accountType]);

  const validateForm = () => {
    return AuthService.EMAIL_REGEX.test(username);
  };

  const resetPassword = async (event) => {
    event.preventDefault();
    setRequestPending(true);
    try {
      await AuthService.sendForgotPassword(username, accountType);
      setRequestPending(false);
      setMessage(
        t(
          "RESET_PASSWORD_SENT",
          "If an account exists for that email address, an email will be sent to allow your password to be reset."
        )
      );
    } catch {
      requestPending(false);
      setErrorMessage(t("LOGIN_FAILED_UNKNOWN", "An unknown error occured"));
    }
  };

  const showForgottenPasswordForm =
    AuthService.getAccountType() === "staff" ||
    (AuthService.getAccountType() === "subject" &&
      subjectAuthenticationStrategy ===
        SUBJECT_AUTHENTICATION_STRATEGY.EMAIL_PWD);

  return (
    <Form style={styles.container} onSubmit={resetPassword}>
      <Grid centered padded>
        <Grid.Row>
          <Grid.Column>
            <Image
              style={{ height: "25px" }}
              src={
                "./images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
              }
              alt={"Aparito Logo"}
            />
          </Grid.Column>
        </Grid.Row>
        {brandImageUrl && (
          <Grid.Row
            centered
            rows={3}
            style={{
              backgroundImage: `url(${brandImageUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
              height: "100px",
            }}
          />
        )}
        <Grid.Row columns={3}>
          <Grid.Column
            width={10}
            textAlign={
              InternationalisationService.isRTL() ? "right" : undefined
            }
          >
            <Header as="h3" textAlign="center">
              {t("RESET_PASSWORD_HEADER_TITLE", "Reset password")}
            </Header>
            {showForgottenPasswordForm && (
              <>
                {message.length > 0 && <Message positive>{message}</Message>}
                {errorMessage.length > 0 && (
                  <Message negative>{errorMessage}</Message>
                )}
                <Form.Input
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder={t("GLOBAL_LABEL_EMAIL_ADDRESS", "Email address")}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  disabled={requestPending}
                />
                <Button
                  type="submit"
                  primary
                  fluid
                  size="large"
                  disabled={!validateForm() || requestPending}
                >
                  {t("RESET_PASSWORD_BUTTON_SUBMIT", "Reset password")}
                </Button>
              </>
            )}
            {!showForgottenPasswordForm && (
              <p>{t("RESET_PASSWORD_INFO_TEXT")}</p>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid centered padded>
        <Grid.Row>
          <Button
            className="borderless"
            basic
            color={"orange"}
            size="small"
            disabled={requestPending}
            onClick={handleForgottenPassword}
          >
            {t("RETURN_TO_LOGIN", "Return to Login")}
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const styles = {
  container: {
    minHeight: "420px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  accountTypeGroup: {
    display: "flex",
    justifyContent: "space-around",
    padding: "32px 0px",
  },
};

const enhance = compose(withTranslation());

export default enhance(ForgottenPasswordModalInsert);
