import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import withError from "./hocs/withError";
import withContainer from "./hocs/withContainer"
import withLabel from "./hocs/withLabel";
import Lookup from "../../components/questionnaire/Lookup.js";
import LookupService from "../../services/LookupService";

const Text = ({
  question,
  value,
  changeValue,
  changeAnswerMapValue,
  isReadOnly,
  label,
  showLabel = true,
}) => {
  const useLookup = question.config?.lookup?.isEnabled || false;
  const [isLookupActive, setIsLookupActive] = useState(false);

  let inputType = "text";
  if (question.config != null) {
    if (question.config.isEmail) {
      inputType = "email";
    } else if (question.config.isTel) {
      inputType = "tel";
    }
  }

  const onChange = ({ target: { value } }) => {
    if (isLookupActive) {
      return;
    }
    changeValue(value);
  };

  const handleLookupSelect = (selectedValue) => {
    changeValue(selectedValue.value);
    setIsLookupActive(false);
    const otherQuestionValues = question.config?.lookup?.otherQuestionValues;
    LookupService.populateRelatedQuestionValues(
      selectedValue,
      otherQuestionValues,
      changeAnswerMapValue
    );
  };

  return (
    <>
      {useLookup && isLookupActive && (
        <Lookup
          question={question}
          onSelect={handleLookupSelect}
          onCancel={() => {
            setIsLookupActive(false);
          }}
        />
      )}
      <Form.Input
        data-question-answer-value={value}
        autoComplete={useLookup ? "off" : null}
        type={inputType}
        name={question.code}
        id={question.code}
        onChange={onChange}
        onFocus={() => {
          setIsLookupActive(useLookup);
        }}
        value={value != null ? value : ""}
        disabled={isReadOnly}
        style={question?.config?.style?.input}
      />
    </>
  );
};

export default withContainer(withLabel(withError(Text)));
