import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import ConfigContext from "../../context/ConfigContext";
import GeneralHelpers from "../../helpers/GeneralHelpers";
import AuthService from "../../services/AuthService";

const VideoPlayer = (props) => {
  const { onReady, onError, onPlayingComplete } = props;
  const { t, 
    width, 
    height, 
    mediaUrl, 
    controls = true,
    showFrameByFrameControls = false, 
    isMonitoredVideo = false,
    hasAllowSubjectMediaAudioPermission = false
  } = props;
  
  const config = useContext(ConfigContext);

  const defaultSkipStepSize = 
    config?.ui?.components?.videoPlayer?.skip?.defaultStepSize != null
    ? parseInt(config?.ui?.components?.videoPlayer?.skip?.defaultStepSize)
    : 1;

  const availableSkipStepSizes =
    config?.ui?.components?.videoPlayer?.skip?.availableStepSizes || [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 5, 10, 20, 30, 60];

  const playerRef = useRef();
  const [isReady, setIsReady] = useState(false);

  const [selectedSkipStepSize, setSelectedSkipStepSize] = useState(defaultSkipStepSize);

  const [viewingProgress, _setViewingProgress] = useState({
    currentTime: 0,
    latestViewedTime: 0,
    isManualSeeking: false,
    latestViewedTimeAtManualSeekStart: 0,
    seekTo: null
  });
  const viewingProgressRef = useRef(viewingProgress);
  const setViewingProgress = (obj) => {
    viewingProgressRef.current = obj;
    _setViewingProgress(obj);
  };

  const [allowMuteChange, _setAllowMuteChange] = useState(hasAllowSubjectMediaAudioPermission);
  const allowMuteChangeRef = useRef(allowMuteChange);
  const setAllowMuteChange = (val) => {
    allowMuteChangeRef.current = val;
    _setAllowMuteChange(val);
  };

  useEffect(() => {
    setAllowMuteChange(hasAllowSubjectMediaAudioPermission);
  }, [hasAllowSubjectMediaAudioPermission]);

  useEffect(() => {
    if (isReady === false) {
      return;
    }
    const internalPlayer = getInternalPlayer();
    if (internalPlayer == null) {
      console.error('Error: internalPlayer is NULL');
      return;
    }

    const listeners = [
      {object: internalPlayer, event: 'volumechange', handler: handleVideoVolumeChange, isMonitoredVideoOnly: false},
      {object: internalPlayer, event: 'timeupdate', handler: handleVideoTimeUpdate, isMonitoredVideoOnly: true},
      {object: internalPlayer, event: 'seeking', handler: handleVideoSeeking, isMonitoredVideoOnly: true},
      {object: internalPlayer, event: 'seeked', handler: handleVideoSeeked, isMonitoredVideoOnly: true},
    ]

    for (const listener of Object.values(listeners)) {
      if (!isMonitoredVideo && listener.isMonitoredVideoOnly === true) {
        continue;
      }
      listener.object.addEventListener(listener.event, listener.handler);
    }

    return () => {
      if (isMonitoredVideo === false) {
        return;
      }
      for (const listener of Object.values(listeners)) {
        listener.object.removeEventListener(listener.event, listener.handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const handleMediaLoadError = (e) => {
    if (onError != null) {
      console.error('handleMediaLoadError', e)
      onError(e);
    }
  };

  const handleMediaReady = async () => {
    setIsReady(true);
    if (onReady != null) {
      onReady();
    }
  };

  const getPlayer = () => {
    const player = playerRef?.current;
    return player;
  };

  const getInternalPlayer = () => {
    const player = getPlayer();
    if (player != null) {
      return player.getInternalPlayer();
    }
    return null;
  };

  const getCurrentTime = () => {
    const player = getPlayer();
    if (player != null) {
      return player.getCurrentTime();
    }
    return null;
  };

  const setCurrentTime = (time) => {
    const player = getPlayer();
    if (player == null) {
      console.error('Error: player is NULL');
      return;
    }

    const isDecimal = time % 1 !== 0;
    const seekType = isDecimal ? 'fragment' : 'seconds';
    player.seekTo(time, seekType);
  };

  const skip = (val) => {
    const currentTime = getCurrentTime();
    if (currentTime == null) {
      console.error('Error: currentTime is NULL');
      return;
    }

    const moveTo = currentTime + (val * selectedSkipStepSize);
    setCurrentTime(moveTo);
  };

  const handlePrevClick = (e) => {
    GeneralHelpers.stopEvent(e);
    skip(-1);
  };

  const handleNextClick = (e) => {
    GeneralHelpers.stopEvent(e);
    skip(1);
  };

  const buildSkipStepSizeOptions = () => {
    return availableSkipStepSizes.map(v => {
      return {
        text: v,
        key: v,
        value: v,
      }
    })
  }
  
  const handleEnded = () => {
    if (onPlayingComplete != null) {
      onPlayingComplete();
    }
  };

  const handleVideoVolumeChange = (e) => {
    /*
    It's not straight forward to disable or hide the mute/volume button
    So instead we detect the volumechange event, and if the user is not permitted ot hear the audio
    we simply force it to be muted.
    Not the most elegant method, but we are phasing out this player, and it is handled in the new one
    */
    if (allowMuteChangeRef.current === true) {
      // Has permission - don't do anything else
      return;
    }
    const internalPlayer = getInternalPlayer();
    if (internalPlayer == null) {
      console.error('Error: internalPlayer is NULL');
      return;
    }
    internalPlayer.muted = true;
  };

  const handleVideoTimeUpdate = () => {
    if (isMonitoredVideo === false) {
      return;
    }
    
    const vp = {...viewingProgressRef.current};
    if (vp.currentTime > vp.latestViewedTime) {
      vp.latestViewedTime = vp.currentTime;
    }

    vp.currentTime = getCurrentTime();
    setViewingProgress(vp);
  };

  const handleVideoSeeking = () => {
    if (isMonitoredVideo === false) {
      return;
    }

    const vp = {...viewingProgressRef.current};
    if (vp.isManualSeeking === false) {
      vp.isManualSeeking = true;
      vp.seekTo = getCurrentTime();
      vp.latestViewedTimeAtManualSeekStart = vp.latestViewedTime;
    }

    setViewingProgress(vp);
  };

  const handleVideoSeeked = () => {
    if (isMonitoredVideo === false) {
      return;
    }
    
    const vp = {...viewingProgressRef.current};
    if (vp.isManualSeeking) {
      if (vp.seekTo > vp.latestViewedTimeAtManualSeekStart) {
        setCurrentTime(vp.latestViewedTimeAtManualSeekStart);
      }
    }

    vp.latestViewedTime = vp.latestViewedTimeAtManualSeekStart;
    vp.isManualSeeking = false;
    vp.seekTo = null;

    setViewingProgress(vp);
  };

  const skipStepSizeOptions = useMemo(buildSkipStepSizeOptions, [availableSkipStepSizes]);

  const shouldShowFrameByFrameControls = AuthService.isStaff() && showFrameByFrameControls && isReady;

  return (
    <>
      <div>
        <ReactPlayer
          ref={playerRef}
          muted={true}
          width={width}
          height={height}
          url={mediaUrl}
          controls={controls}
          onReady={handleMediaReady}
          onError={handleMediaLoadError}
          onEnded={handleEnded}
          preload={'none'}
          config={{
            file: {
              attributes: {
                onContextMenu: e => e.preventDefault(),
                controlsList: 'nodownload'
              }
            }
          }}
        />
        {shouldShowFrameByFrameControls && (
          <div style={{marginTop: 4, backgroundColor: props.backgroundColor != null ? props.backgroundColor : '#ffffff'}}>
            {t('COMPONENT_VIDEOPLAYER_SKIP_STEP_LABEL', 'Skip by')}{' '}
            <Dropdown
              compact
              selection
              options={skipStepSizeOptions}
              style={{width: 100}}
              onChange={(_e, data) => {
                setSelectedSkipStepSize(data.value);
              }}
              value={selectedSkipStepSize}
              
            />
            {' '}{t('COMPONENT_VIDEOPLAYER_SKIP_STEP_SECONDS_LABEL', 'seconds')}{' '}
            <Button 
              primary
              icon
              labelPosition="left"
              onClick={handlePrevClick} 
              title={t('COMPONENT_VIDEOPLAYER_SKIP_STEP_BACKWARDS', 'Skip backwards')}>
              <Icon name="step backward" /> {t('COMPONENT_VIDEOPLAYER_SKIP_STEP_BACKWARDS', 'Skip backwards')}
            </Button>
            <Button 
              primary
              icon
              labelPosition="left"
              onClick={handleNextClick} 
              title={t('COMPONENT_VIDEOPLAYER_SKIP_STEP_FORWARDS', 'Skip forwards')}>
            {t('COMPONENT_VIDEOPLAYER_SKIP_STEP_FORWARDS', 'Skip forwards')}<Icon name="step forward" />
            </Button>
          </div>
        )}
      </div>
    </>
  );

};

export default withTranslation()(VideoPlayer);
