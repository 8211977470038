import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Image, Loader } from "semantic-ui-react";
import SubjectService from "../../../SubjectService";

export const KEY = "QR_CODE_DISPLAY";

function QRCodeDisplay({ user, t }) {
  const [loading, setLoading] = useState(true);
  const [device, setDevice] = useState(null);
  const [QRCodeImageUrl, setQRCodeImageUrl] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const pollingTimeout = useRef();

  const generateNewDevice = async () => {
    setIsGenerating(true);
    await SubjectService.subjectCreateDevice(user.profile.Id);
  };

  const pollForChanges = async () => {
    let devices = await SubjectService.getSubjectDevices(user.profile.Id);
    try {
      devices = devices.filter((device) => device.state === "CREATED");
    } catch {
      console.error("[QRCodeDisplay] Error filtering devices");
    }

    if (devices.length > 0) {
      if (device !== devices[0]) {
        await setDevice(devices[0]);
        getQRCode(devices[0]);
      }
    } else {
      setQRCodeImageUrl(null);
      setLoading(false);
    }

    pollingTimeout.current = setTimeout(pollForChanges, 2500);
  };

  const getQRCode = async (device) => {
    const QRCodeImage = await SubjectService.getQrCode(device.id);
    await setQRCodeImageUrl(URL.createObjectURL(QRCodeImage));
    setIsGenerating(false);
    setLoading(false);
  };

  useEffect(() => {
    pollForChanges();
    return () => {
      clearTimeout(pollingTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      {(loading || isGenerating) && <Loader active inline={"centered"} />}
      {!loading && QRCodeImageUrl && <Image src={QRCodeImageUrl} />}
      {!loading && QRCodeImageUrl && device && <h2>{device.onboardingCode}</h2>}

      {!isGenerating && !QRCodeImageUrl && (
        <Button onClick={generateNewDevice} primary fluid>
          {("QR_CODE_DISPLAY_NEW", "Get New QR Code")}
        </Button>
      )}
    </Card>
  );
}

export default QRCodeDisplay;
