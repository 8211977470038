import React, { useContext, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Loader, Message } from "semantic-ui-react";
import ConfigContext from "../../context/ConfigContext";
import UserContext from "../../context/UserContext";
import {
  getEventDefinitions,
  getProDefinitions,
} from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import { getPros } from "../../redux/questionnaires/subjectQuestionnairesSlice";
import { compose } from "redux";
import { connect } from "react-redux";
import RequestHelper from "../../RequestHelper";
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";
import Questionnaire from "../../questionnaires/Questionnaire";
import DashboardContext from "../../context/DashboardContext";

const SubjectStudyConsentPage = (props) => {
  const { t, eventDefinitions, proDefinitions, thePros } = props;

  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const [error, setError] = useState(false);
  const [studyConsentQuestionnaire, setStudyConsentQuestionnaire] =
    useState(null);
  const [answerMap, setAnswerMap] = useState(null);

  const dashboardContext = useContext(DashboardContext);
  const handlePageChange = () => {
    dashboardContext.scrollToTop();
  };

  let foundConsentQuestionnaire;

  //first check the event questionnaires
  foundConsentQuestionnaire =
    Array.isArray(eventDefinitions) &&
    eventDefinitions.find((pro) => {
      return pro.id === studyConsentQuestionnaire?.id;
    });

  //if no consent questionnaire found then check the pros
  if (!foundConsentQuestionnaire) {
    foundConsentQuestionnaire =
      Array.isArray(thePros) &&
      thePros.find((pro) => {
        return pro.definitionId === studyConsentQuestionnaire?.id;
      });
  }

  if (!foundConsentQuestionnaire) {
    console.log("Consent form not found in the list of events or pros");
  }

  const setupConsentQuestionnaire = async () => {
    const foundStudyConsentQuestionnaire = [
      ...eventDefinitions,
      ...proDefinitions,
    ].find((def) => {
      return def.code === config.ui.studyConsentQuestionnaire;
    });

    const subjectRecords =
      await SubjectQuestionnaireService.getSubjectRecords();

    const answerMapReducer = (acc, curr) => {
      return { ...acc, ...curr.answers };
    };
    const answerMap = subjectRecords.reduce(answerMapReducer, {});

    await setAnswerMap(answerMap);
    setStudyConsentQuestionnaire(foundStudyConsentQuestionnaire);
  };

  useEffect(() => {
    setupConsentQuestionnaire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConsented = async (questionnaireAnswerMap) => {
    try {
      let foundPro;
      let isPro = studyConsentQuestionnaire.type === "PRO";

      if (isPro) {
        foundPro = thePros.find((pro) => {
          return pro.definitionId === studyConsentQuestionnaire.id;
        });

        if (!foundPro) {
          console.error(
            "No PRO found found for id " + studyConsentQuestionnaire.id
          );
          setError(true);
          return;
        }
      }

      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      await RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + "/subject/consent",
        {},
        "PUT",
        { subjectTimezone: timeZone }
      );

      await SubjectQuestionnaireService.submitQuestionnaire(
        studyConsentQuestionnaire,
        foundPro,
        questionnaireAnswerMap
      );

      user.refreshAuthState();
    } catch (anError) {
      setError(true);
    }
  };

  if (user.profile.consentDate !== null) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ maxWidth: 850, padding: "20px" }}>
      {error && <Message error>{t("CONSENT_ERROR_MESSAGE")}</Message>}
      {!error && !foundConsentQuestionnaire && <Loader active={true} />}
      {!error && studyConsentQuestionnaire && foundConsentQuestionnaire && (
        <div>
          <Questionnaire
            key={thePros.length}
            questionnaire={{
              id: studyConsentQuestionnaire.id,
              answers: answerMap,
            }}
            definition={studyConsentQuestionnaire}
            onPageChange={handlePageChange}
            onSubmit={onConsented}
            shouldGroupAnswersByQuestionnaire={true}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    eventDefinitions: getEventDefinitions(state),
    proDefinitions: getProDefinitions(state),
    thePros: getPros(state),
  };
};

const enhance = compose(connect(mapStateToProps), withTranslation());

export default enhance(SubjectStudyConsentPage);
