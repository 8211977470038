/* eslint no-unused-vars: 0 */

import React, { Component } from "react";
import { Message } from "semantic-ui-react";

import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import DrugForm from "../../components/drug/DrugForm";
import DrugService from "../../DrugService";

class DrugEditPage extends Component {
  state = {
    loading: true,
  };

  constructor(props, context) {
    super(props, context);
    const { match } = props;
    const drugId = match.params.id;
    DrugService.getDrug(drugId)
      .then((drug) => this.setState({ drug, drugExist: true }))
      .catch((_error) => this.setState({ drugExist: false }))
      .finally(() => this.setState({loading: false}));
  }

  onCancel = () => {
    window.location.href = window.location.origin + "/app/drugs";
  };

  onSubmit = (drug, headers) => {
    return DrugService.updateDrug(
      this.state.drug.id,
      drug.name,
      this.state.drug.active,
      headers
    )
      .then(() => {
        window.location.href = window.location.origin + "/app/drugs";
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  render() {
    const { t } = this.props;
    const errorMessage = this.state.error
      ? t("DRUG_NOT_FOUND_ERROR_MESSAGE")
      : undefined;

    return (
      <Page
        name="DRUG_EDIT"
        header={t("DRUG_EDIT_HEADER")}
        subheader={t("DRUG_EDIT_SUBHEADER")}
        loading={this.state.loading}
      >
        {this.state.drugExist && (
          <DrugForm
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            drug={this.state.drug}
            error={errorMessage}
          />
        )}

        {!this.state.drugExist && (
          <Message error>
            <Message.Header>{t("DRUG_FORM_ERROR_TITLE")}</Message.Header>
            {t("DRUG_NOT_FOUND_ERROR_MESSAGE")}
          </Message>
        )}
      </Page>
    );
  }
}

export default withTranslation()(DrugEditPage);
