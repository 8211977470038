import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import Page from "../../components/page/Page";
import AuthService from "../../services/AuthService";
import AdminAttachmentService from "../../services/admin/AdminAttachmentService";
import _ from "lodash";
function AdminAttachmentMonitoringPage(props) {
  const [loading, setLoading] = useState(true);
  const [lastDaysFilter, setLastDaysFilter] = useState("30");
  const [results, setResults] = useState([]);
  const [expandedRow, setExpandedRow] = useState();
  const [error, setError] = useState({});
  const [profile, setProfile] = useState({});

  const { t } = props;

  function parseError(e, fatal) {
    if (e.message) {
      setError({ message: e.message, fatal: fatal });
    } else if (typeof e == "string") {
      setError({ message: e, fatal: fatal });
    } else {
      setError({ message: JSON.stringify(e), fatal: fatal });
    }
  }

  const doSearch = async () => {
    try {
      const data = await AdminAttachmentService.getAttachmentsNotReceived(lastDaysFilter);

      const processed = _.groupBy(data, (a) => a.subjectCode);
      const results = _.map(_.keys(processed), (subjectCode) => {
        const x = processed[subjectCode];
        return {
          subjectCode: subjectCode,
          attachments: _.orderBy(
            x,
            ["questionnaireDefinition", "questionDefinition", "attachmentId"],
            ["asc", "asc", "desc"]
          ),
        };
      });
      setResults(results);
    } catch (e) {
      console.log("Error fetching late videos", e);
      parseError(e, true);
    }
  };

  const onItemClick = (e, line) => {
    if (expandedRow === line.subjectCode) {
      setExpandedRow(undefined);
    } else {
      setExpandedRow(line.subjectCode);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const profile = await AuthService.getMyProfile();
        setProfile(profile);

        setLoading(false);
      } catch (e) {
        parseError(e, true);
      } finally {
      }
    };
    return init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table = () => {
    return (
      <Table selectable columns={12}>
        <Table.Header>
          <Table.Row key={"header"}>
            <Table.HeaderCell
              // style={{ minHeight: "40px", width: "200px" }}
              width={1}
              key={"subject_header"}
            >
              {t("ADMIN_SUBJECT_CODE", "Subject")}
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ minHeight: "40px" }}
              key={"vides_cnt_header"}
              width={11}
            >
              {t("ADMIN_VIDEOS_AFFECTED", "Videos affected")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {results.map((line) => {
            return (
              <Table.Row key={line.subjectCode}>
                <Table.Cell
                  key={line.subjectCode + "_code"}
                  width={1}
                  onClick={(e) => onItemClick(e, line)}
                >
                  {line.subjectCode}
                </Table.Cell>
                <Table.Cell key={line.subjectCode + "_cnt"} width={11}>
                  <div onClick={(e) => onItemClick(e, line)}>
                    {line.attachments.length}
                  </div>

                  {expandedRow === line.subjectCode && (
                    <Table selectable columns={4}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell style={{ minHeight: "40px" }}>
                            {t("ATTACHMENT_ID", "AttachmentId")}
                          </Table.HeaderCell>
                          <Table.HeaderCell style={{ minHeight: "40px" }}>
                            {t("ATTACHMENT_DATE", "Date")}
                          </Table.HeaderCell>
                          <Table.HeaderCell style={{ minHeight: "40px" }}>
                            {t("ATTACHMENT_STATUS", "Status")}
                          </Table.HeaderCell>
                          <Table.HeaderCell style={{ minHeight: "40px" }}>
                            {t("QUESTIONNAIRE_DEFINITION", "Questionnaire")}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {line.attachments.map((att) => {
                          return (
                            <Table.Row key={att.attachmentId}>
                              <Table.Cell key={att.attachmentId + "_id"}>
                                {att.attachmentId}
                                <div
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "grey",
                                  }}
                                >
                                  {att.attachmentReference}
                                </div>
                              </Table.Cell>
                              <Table.Cell key={att.attachmentId + "_date"}>
                                {att.questionnaireCompletionDate}{" "}
                                {att.questionnaireCompletionDateTimezone}
                              </Table.Cell>
                              <Table.Cell id={att.attachmentId + "_status"}>
                                {att.attachmentState}
                              </Table.Cell>
                              <Table.Cell
                                id={att.attachmentId + "_questionnaire"}
                              >
                                {att.questionnaireDefinition} (
                                {att.questionType}-{att.questionDefinition})
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Page
      name="Admin Attachment Monitoring"
      header={t("ADMIN_ATTACHMENT_MONITORING", "Admin Attachment Monitoring")}
    >
      {error && error.fatal && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={
            "Fatal error, if it persist contact support:" + error.message
          }
        />
      )}
      {loading && <Loader active={loading} />}
      {!loading && !profile.superAdmin && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
        />
      )}

      {!loading && profile.superAdmin && (
        <>
          <Grid columns={"equal"}>
            <Grid.Column>
              <Form onSubmit={doSearch}>
                <Form.Field>
                  <label>
                    {t("LAST_DAYS_FILTER", "Retrieve data for last X days")}
                  </label>
                  <Dropdown
                    name="lastDaysFilter"
                    selection
                    options={[
                      { key: "7", value: "7", text: "7" },
                      { key: "14", value: "14", text: "14" },
                      { key: "30", value: "30", text: "30" },
                      { key: "365", value: "365", text: "365" },
                    ]}
                    value={lastDaysFilter}
                    onChange={(e, data) => {
                      e.preventDefault();
                      setLastDaysFilter(data.value);
                    }}
                  />
                </Form.Field>

                <Button type="submit" primary>
                  {t("GLOBAL_BUTTON_SEARCH", "Search")}
                </Button>
              </Form>
            </Grid.Column>
          </Grid>

          <Segment style={{ overflow: "auto" }}>
            <Header as="h4">{t("VIDE_RESULTS", "Video Results")}</Header>
            {table()}
          </Segment>
        </>
      )}
    </Page>
  );
}

export default withTranslation()(AdminAttachmentMonitoringPage);
