const buildQuestionnaireModuleFilter = (moduleCode) => (questionnaire) => {
  if(!questionnaire.modules){
    return true;
  }

  if(!Array.isArray(questionnaire.modules)){
    return true;
  }

  if(questionnaire.modules.length === 0){
    return true;
  }

  return questionnaire.modules.some(m=>m.code === moduleCode)
}

export default buildQuestionnaireModuleFilter;