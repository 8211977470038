import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Form, Input, Modal, TextArea} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const TranslationEditModal = ({translationObject, isOpen, onClose, onConfirmCallback}) => {
  const {t} = useTranslation();

  const jsonifiedValue = useMemo(()=>{
    if(translationObject.code.slice(-6) !== "config"){
      return translationObject.translation
    }

    try{
      return JSON.stringify(JSON.parse(translationObject.translation), null, 2);
    } catch (e) {
      return translationObject.translation;
    }
  }, [translationObject.code, translationObject.translation])
  const [translation, setTranslation] = useState(jsonifiedValue)
  useEffect(()=>{
    setTranslation(jsonifiedValue)
  }, [jsonifiedValue])

  const handleChange = (e, v) => {
    setTranslation(v.value)
  }

  const handleSave = useCallback(() => {
    onConfirmCallback({...translationObject, translation});
    onClose();
  }, [onClose, onConfirmCallback, translation, translationObject])

  const handleCancel = useCallback(() => {
    setTranslation(translationObject.translation);
    onClose();
  }, [onClose, translationObject.translation])


  return (
      <Modal
          open={isOpen}
          onClose={onClose}
      >
        <Modal.Content>
          <h4 style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            marginBottom: '0.5rem'
          }}>{translationObject.code}
          </h4>
          <p style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}>{translationObject.language}
          </p>
          <Form>
            <Form.Field inline>
              {translationObject.code.slice(-6) !== "config" &&  <Input fluid value={translation} onChange={handleChange}/>}
              {translationObject.code.slice(-6) === "config" &&  <TextArea fluid rows={16} value={translation} onChange={handleChange}/>}
            </Form.Field>
            <Form.Field inline>
              <Button primary onClick={handleSave}>{t("TRANSLATION_EDIT_SAVE", "Save")}</Button>
              <Button basic primary onClick={handleCancel}>{t("TRANSLATION_EDIT_CANCEL", "Cancel")}</Button>
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
  )
}

export default TranslationEditModal;