import React, { useState, useEffect, useContext } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import {
  Button,
  Grid,
  Header,
  Icon, Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import MultiQuestionnaire from "../../questionnaires/MultiQuestionnaire";
import { useHistory, useParams } from "react-router-dom";
import DashboardContext from "../../context/DashboardContext";

const SubjectEditPage = (props) => {
  const { t } = props;

  const [subjectData, setSubjectData] = useState({
    subjectCode: "",
    groups: [],
    subjectRecords: [],
  });
  const [loading, setLoading] = useState(true);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [error, setError] = useState(false);

  let { tabName, subjectId } = useParams();
  const history = useHistory();

  const dashboardContext = useContext(DashboardContext);
  const handlePageChange = () => {
    dashboardContext.scrollToTop();
  };

  const initialise = async () => {
    let [subjectData, questionnaires] = await Promise.all([
      SubjectService.getSubjectData(subjectId),
      SubjectService.getSubjectRecordQuestionnaireDefinitions(),
    ]);

    if (!subjectData || !questionnaires) {
      setError(true);
      setLoading(false);
    }

    questionnaires = questionnaires.filter(
      (questionnaire) =>
        (questionnaire.config.tab && questionnaire.config.tab === tabName) ||
        (!questionnaire.config.tab && tabName === "details")
    );

    questionnaires.forEach((questionnaire) => {
      questionnaire.answers = getAnswers(questionnaire.code, subjectData);
    });

    setQuestionnaires(questionnaires);
    setSubjectData(subjectData);
  };

  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subjectData && questionnaires) {
      setLoading(false);
    }
  }, [subjectData, questionnaires]);

  const onSubmit = async (answerMap) => {
    setLoading(true);
    await SubjectService.updateSubjectRecords(subjectId, answerMap);

    history.push(
      "/app/subject/" + subjectId + "/tabs/subject-record/" + tabName
    );
  };

  const getAnswers = (questionnaireCode, subjectData) => {
    let subjectRecord = subjectData.subjectRecords.find(
      (record) => record.definition.code === questionnaireCode
    );
    let answerMap = {};

    if (subjectRecord) {
      Object.entries(subjectRecord.answers).forEach((entry) => {
        const questionCode = entry[0];
        const answerValue = entry[1];
        const questionDefinition = subjectRecord.definition.questions.find(
          (question) => question.code === questionCode
        );

        if (questionDefinition) {
          // todo validate if this is required, removed to support conditions on calculated values && !questionDefinition.calculated
          answerMap[questionCode] = answerValue;
        }
      });
    } else {
      console.log(
        `[adebug] No subject record found for code ${questionnaireCode} and subject records ${JSON.stringify(
          subjectData
        )}`
      );
    }
    return answerMap;
  };

  return (
    <Page
      header={() => (
        <Header as="h3">
          <Button
            color="orange"
            style={{ padding: "0.25rem 1rem " }}
            onClick={() =>
              history.push(
                "/app/subject/" + subjectId + "/tabs/subject-record/" + tabName
              )
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Icon name="arrow left" />
              <h3 style={{ margin: "0rem", marginLeft: "0.5rem" }}>
                {subjectData.subjectCode}
              </h3>
            </div>
          </Button>
          {" " + t("SUBJECT_EDIT_HEADER")}
        </Header>
      )}
      loading={false}
    >
      {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE")}
          content={t("GLOBAL_ERROR_GENERIC_MESSAGE")}
        />
      )}
      <Grid columns={"equal"}>
        <Grid.Column>
          <Segment>
            <MultiQuestionnaire
              questionnaires={questionnaires}
              onPageChange={handlePageChange}
              onSubmit={onSubmit}
              subjectId={subjectId}
            />
          </Segment>
          {loading && <Loader active={loading} />}

        </Grid.Column>
      </Grid>
    </Page>
  );
};

export default withTranslation()(SubjectEditPage);
