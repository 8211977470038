import { DateTime } from "luxon";
import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import moment from "moment";
import LocalDataService from "../LocalDataService";

//note: moment and luxon use different case for date formats e.g. dd/MM/yyyy (luxon) vs DD/MM/YYYY (moment)
const SUBMIT_MONTH_YEAR_FORMAT_MOMENT = "YYYY-MM";
const SUBMIT_DATE_FORMAT_MOMENT = "YYYY-MM-DD";
const SUBMIT_DATETIME_FORMAT_MOMENT = "YYYY-MM-DDTHH:mm:ss";
const SUBMIT_TIME_FORMAT_MOMENT = "HH:mm";

const questionnaireHelper = {
  generateUriPrefixByType: (type) => {
    return "questionnaire://" + type.toLowerCase() + "/";
  },

  formatAnswerMap: (answerMap, questionnaireDefinition) => {
    const formattedAnswerMap = {};
    const lang = LocalDataService.getLanguage() ? LocalDataService.getLanguage() : 'en_GB'
    for (const [key] of Object.entries(answerMap)) {
      let question = questionnaireDefinition.questions.find(
        (question) => question.code === key
      );

      // This is to remove completionDate from the answermap.
      // maybe we should remove it.
      if (!question) {
        continue;
      }

      if (
        answerMap[key] ===
        questionnaireHelper.getEmptyAnswerOfType(question.type)
      ) {
        continue;
      }

      formattedAnswerMap[key] = questionnaireHelper.formatAnswer(question.type, answerMap[key], lang);
    }
    return formattedAnswerMap;
  },

  getEmptyAnswerOfType: (type) => {
    switch (type) {
      case QUESTION_TYPES.TEXT:
        return "";
      case QUESTION_TYPES.TEXTAREA:
        return "";
      case QUESTION_TYPES.INTEGER:
      case QUESTION_TYPES.DECIMAL:
      case QUESTION_TYPES.DATE:
      case QUESTION_TYPES.TIME:
      case QUESTION_TYPES.DATETIME:
      case QUESTION_TYPES.FIXED_VALUE:
      case QUESTION_TYPES.SLIDER:
      default:
        return null;
    }
  },

// would be nice to do this in luxon but luxon supports islamic calendar for output only:
//   https://github.com/moment/luxon/blob/master/docs/calendars.md
  formatAnswer: (type, rawValue, lang) => {
    switch (type) {
      case QUESTION_TYPES.DATE:
        return moment(
            rawValue,
              SUBMIT_DATE_FORMAT_MOMENT,
              lang
        ).locale('en_GB').format(SUBMIT_DATE_FORMAT_MOMENT)
      case QUESTION_TYPES.TIME:
        return moment(
            "1970-01-01T" + rawValue
        ).locale('en_GB').format(SUBMIT_TIME_FORMAT_MOMENT)
      case QUESTION_TYPES.DATETIME:
        return moment(
            rawValue,
            SUBMIT_DATETIME_FORMAT_MOMENT,
            lang
        ).locale('en_GB').format(SUBMIT_DATETIME_FORMAT_MOMENT)
      case QUESTION_TYPES.MONTHYEAR:
        return moment(
            rawValue,
            SUBMIT_MONTH_YEAR_FORMAT_MOMENT,
            lang
        ).locale('en_GB').format(SUBMIT_MONTH_YEAR_FORMAT_MOMENT)

      default:
        return rawValue;
    }
  },

  otherQuestionnaireWithSameDefinitionAndNewerActivationToDate: (
    questionnaire,
    allQuestionnaires
  ) => {
    for (var i = allQuestionnaires.length - 1; i >= 0; i--) {
      if (
        allQuestionnaires[i].completionDate === null &&
        questionnaire.id !== allQuestionnaires[i].id &&
        questionnaire.definitionId === allQuestionnaires[i].definitionId &&
        DateTime.fromISO(questionnaire.activationToDate)
          .diff(DateTime.fromISO(allQuestionnaires[i].activationToDate))
          .toObject().milliseconds < 0
      ) {
        return true;
      }
    }
    return false;
  },
};

export default questionnaireHelper;
