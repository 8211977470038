import {QUESTION_TYPES} from "atom5-branching-questionnaire";
import QuestionHelper from "../../helpers/QuestionHelper";
import ScoringService from "../../services/ScoringService";
import {Table} from "semantic-ui-react";
import DisplayQuestion from "../../questionnaires/display/DisplayQuestion";
import ConfigService from "../../services/ConfigService";
import React, {useMemo} from "react";
import shouldDisplayInDataTable from "./utility/shouldDisplayInDataTable";
import TriggerInfoDisplay from "./TriggerInfoDisplay";
import SubjectDataTableQuestionHeaderLabel from "./SubjectDataTableQuestionHeaderLabel";
import determineColumnVisibility from "./utility/determineColumnVisibility";
import QUESTIONNAIRE_COLUMN_KEYS from "../../constants/QUESTIONNAIRE_COLUMN_KEYS";
import getSubjectQuestionnaireTableCell from "./utility/getSubjectQuestionnaireTableCell";
import workflowColumnFilter from "./utility/workflowColumnFilter";
import {
  formatColumn,
  moduleColumnFieldOrExpression,
  moduleColumnKey,
  moduleHasColumnsForCompletedConfigured, parseColumnExpression
} from "../../services/helpers/moduleHelper";

const DEFAULT_COLUMN_ORDER = [
  QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_INFO_BUTTON,
  QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_SUBMIT_BUTTON,
  QUESTIONNAIRE_COLUMN_KEYS.WORKFLOW_TASK_START_DATE,
  QUESTIONNAIRE_COLUMN_KEYS.CREATION_TRIGGER,
  QUESTIONNAIRE_COLUMN_KEYS.TOTAL_SCORE,
  QUESTIONNAIRE_COLUMN_KEYS.CUSTOM_QUESTIONNAIRE_COLUMNS,
  QUESTIONNAIRE_COLUMN_KEYS.QUESTIONS
];

const SubjectDataTableWorkflowTaskDisplay = ({
    t,
    definition,
    workflow,
    task,
    questionnaires,
    history,
    permissions,
    subjectId,
    hideAnswers,
    definitionCode,
    config,
    questionnaireType,
    questionnairesRequireSignoff,
    isGraphOpen,
    uiTabConfig
}) => {
  const questions = definition?.questions || [];

  const table = {
    headers: [],
    body: [],
  }

  // Questions where all answers are null may be removed
  // this tracks the columns that may need to be removed
  let isNull = [];

  const hasCustomColumns = moduleHasColumnsForCompletedConfigured(uiTabConfig);
  const columnOrderBasedOnDefault = [...DEFAULT_COLUMN_ORDER].filter(cT => workflowColumnFilter(cT, task, hasCustomColumns))

  const columnOrder = ConfigService.getQuestionnaireTableColumnOrder(definition, workflow, task) || columnOrderBasedOnDefault;
  const columnVisibility = {};
  columnOrder.forEach(cO=> columnVisibility[cO] = determineColumnVisibility(cO, definition, permissions));

  const visibleQuestionLength = useMemo(() => {
    return questions
        .filter((q) => shouldDisplayInDataTable(task, q, hideAnswers))
        .length
  }, [questions, task, hideAnswers]);
  const customColumnLength = uiTabConfig?.columnsCompleted?.length || 0;

  const generateQuestionsHeaders = (headersRow) => {
    questions
        .filter((q) => shouldDisplayInDataTable(task, q, hideAnswers))
        .forEach((question, index) => {
          headersRow.push(
              <Table.HeaderCell key={question.code}>
                <SubjectDataTableQuestionHeaderLabel question={question}/>
              </Table.HeaderCell>
          );
        })
  }

  const generateQuestionsDisplay = (questionnaire, questionnaireIndex, tableRow, columnIndex) => {
    questions
        .filter((q) => shouldDisplayInDataTable(task, q, hideAnswers))
        .forEach((question, index) => {
          let answer = questionnaire[`${definitionCode}_${question.code}`];

          if (question.type === QUESTION_TYPES.PARAGRAPH) {
            const renderAs = QuestionHelper.getConfigValue(
                question,
                "renderAs",
                "label"
            );
            if (renderAs === "label") {
              answer = question.label;
            }
          }

          let questionScore = null;

          // Try the colour config for a calculation first
          if (question?.config.coloring && answer !== "") {
            const scoringInformation = ScoringService.getScoringInformation(
                answer,
                question?.config.coloring,
                questionnaire,
                definitionCode
            );
            if (scoringInformation && scoringInformation.score) {
              questionScore = scoringInformation.score;
            }
          }


          // No scoring information found via the color config
          if (questionScore === null) {
            if (question.type === QUESTION_TYPES.FIXED_VALUE) {
              const answers =
                  questionnaires[questionnaireIndex][`${definitionCode}_${question.code}`];
              let questionScoreSum = null;
              question.answers.forEach((answer) => {
                if (
                    typeof answers === "object" &&
                    answers.includes(answer.code)
                ) {
                  if (
                      "score" in answer &&
                      answer.score !== undefined &&
                      !isNaN(answer.score)
                  ) {
                    questionScoreSum =
                        (questionScoreSum !== null ? questionScoreSum : 0) +
                        answer.score;
                  }
                }
              });

              if (questionScoreSum !== null) {
                questionScore = ScoringService.calculateAnswerScore(
                    question,
                    questionScoreSum
                );
              }
            }
          }

          if (
              answer === undefined &&
              isNull[columnIndex + index] !== false &&
              isNull[columnIndex + index] !== true
          ) {
            isNull[columnIndex + index] = true;
          } else if (answer !== undefined) {
            isNull[columnIndex + index] = false;
          }

          let backgroundColor = null;
          if (question?.config.coloring && answer !== "") {
            const scoringInformation = ScoringService.getScoringInformation(
                answer,
                question.config.coloring,
                questionnaire,
                definitionCode
            );
            if (scoringInformation) {
              backgroundColor = scoringInformation.color;
            }
          }
          tableRow.push(
              <Table.Cell style={{backgroundColor}} key={index}>
                <DisplayQuestion
                    definition={definition}
                    question={question}
                    answer={answer}
                    showLabel={false}
                    permissions={permissions}
                    subjectId={subjectId}
                    containerType="table"
                />
              </Table.Cell>
          );
        });
  };

  const generateCustomHeaders = (headersRow) => {
    uiTabConfig.columnsCompleted.forEach(column => {
      headersRow.push(
          <Table.HeaderCell key={`${moduleColumnKey(column)}_header`}>
            {column.labelKey ? t(column.labelKey, (column.label ? column.label : column.labelKey)) : (column.label ? column.label : moduleColumnFieldOrExpression(column))}
          </Table.HeaderCell>

      );
    })
  }

  const generateCustomBody = (questionnaire, questionnaireIndex, tableRow, columnIndex) => {
    uiTabConfig.columnsCompleted.forEach((column, index) => {

      if(column.field && column.field==='triggerActivityAudit'){
        tableRow.push(
            <Table.Cell key={`${moduleColumnKey(column)}_val_${questionnaire.id}`}>
              <TriggerInfoDisplay t={t} questionnaire={questionnaire}/>
            </Table.Cell>
        )
      }else{
        const rawValue = parseColumnExpression(column, {
          q: questionnaire,
          nullSafe: (val, nullValue) => val && val !== 'null' && val !== nullValue ? val : ''
        })
        tableRow.push(
            <Table.Cell key={`${moduleColumnKey(column)}_val_${questionnaire.id}`}>
              {formatColumn(column, rawValue)}
            </Table.Cell>
        )
      }

      isNull[columnIndex + index] = false;
    })
  }

  const generateHeaders = () => {
    const headerRow = [];
    columnOrder.forEach((cT, i) => {
      let cell = null;
      if (!columnVisibility[cT]) return headerRow.push(cell);

      if(QUESTIONNAIRE_COLUMN_KEYS.QUESTIONS === cT){
        generateQuestionsHeaders(headerRow);
      } else if(QUESTIONNAIRE_COLUMN_KEYS.CUSTOM_QUESTIONNAIRE_COLUMNS === cT){
        generateCustomHeaders(headerRow);
      } else {
        cell = getSubjectQuestionnaireTableCell.forHeader(cT, t)
      }

      headerRow.push(cell)
    });

    return headerRow;
  }

  const generateTableBodyRow = (questionnaire, index) => {
    const row = [];
    let haveQuestionsBeenAdded = false;
    let haveCustomColumnsBeenAdded = false;

    columnOrder.forEach((cT, i) => {
      let cell = null;
      if (!columnVisibility[cT]) return row.push(cell);

      // if this muticolumn approach goes past 2 then create and increment and offset
      if(QUESTIONNAIRE_COLUMN_KEYS.QUESTIONS === cT){
        generateQuestionsDisplay(questionnaire, index, row, haveCustomColumnsBeenAdded ? i + customColumnLength : i );
        haveQuestionsBeenAdded = true;
      } else if(QUESTIONNAIRE_COLUMN_KEYS.CUSTOM_QUESTIONNAIRE_COLUMNS === cT) {
        generateCustomBody(questionnaire, index, row, haveQuestionsBeenAdded ? i + visibleQuestionLength : i );
        haveCustomColumnsBeenAdded = true
      } else {
        cell = getSubjectQuestionnaireTableCell.forBody(cT, questionnaire, definition, t, history)
      }

      if (cell) {
        const columnIndexQuestionAddition = haveQuestionsBeenAdded ? visibleQuestionLength : 0;
        const columnIndexCustomColumnAddition = haveCustomColumnsBeenAdded ? customColumnLength : 0;
        const columnIndexConsideringCustomColumnsAndQuestions = i + columnIndexQuestionAddition + columnIndexCustomColumnAddition;
        isNull[columnIndexConsideringCustomColumnsAndQuestions] = false;
      }

        row.push(cell)
    })

    return row;
  }

  const cleanQuestionnaireNulls = (table, isNull) => {
    if (config?.ui?.shouldDisplayNulls ){
      return
    }

    table.headers.forEach((header, headerIndex) => {
      if(isNull[headerIndex]) table.headers[headerIndex] = null;
    })
    table.body.forEach((row, rowIndex) => {
      row.forEach((_column, columnIndex)=>{
        if(isNull[columnIndex]) table.body[rowIndex][columnIndex] = null
      })
    })
  }

  table.headers = generateHeaders();
  questionnaires.forEach((questionnaire, index) => {
    table.body.push(generateTableBodyRow(questionnaire, index))
  });
  cleanQuestionnaireNulls(table, isNull);

  return <>
    <h4>
      {t("SUBJECT_TAB_DATA_TABLE_HEADER_"+task.code.toUpperCase())}
    </h4>
    {table.body.length > 0 && (
        <Table id={"subjectDataTable"} selectable celled>
          <Table.Header>
            <Table.Row>{table.headers}</Table.Row>
          </Table.Header>
          <Table.Body>
            {table.body.map((cells, index) => {
              let questionnaireId = questionnaires[index].id;
              if (cells.length === 0 && definition) {
                cells.push(
                    <Table.Cell key={1} colSpan={table.headers.length}>
                      {t("SUBJECT_TAB_DATA_TABLE_NO_ANSWERS")}{" "}
                      {definition.label}
                    </Table.Cell>
                );
              }
              return (
                  <Table.Row
                      style={hideAnswers ? {} : {cursor: "pointer"}}
                      onClick={() => {
                        if (hideAnswers) {
                          return;
                        }
                        history.push(
                            "/app/subject/" +
                            subjectId +
                            "/questionnaire-type/" +
                            questionnaireType +
                            "/" +
                            definitionCode +
                            "/view/" +
                            questionnaireId
                        );
                      }}
                      key={index}
                  >
                    {cells}
                  </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
    )}
    {table.body.length === 0 && <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY")}</p>}
  </>

}

export default SubjectDataTableWorkflowTaskDisplay;
