import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon, Popup } from "semantic-ui-react";

class UnauthorisedMedia extends Component {
  render() {
    const { t } = this.props;
    return (
      <Popup
        trigger={
          <Button color="orange" icon>
            <Icon name="lock" size="big" />
          </Button>
        }
      >
        <Popup.Content>{t("MEDIA_CONTENT_UNAUTHORISED")}</Popup.Content>
      </Popup>
    );
  }
}

export default withTranslation()(UnauthorisedMedia);
