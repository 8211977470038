import { createSlice } from "@reduxjs/toolkit";
import LocalDataService from "../services/LocalDataService";

// Future: Refactor using subscribers on the store (if possible to only subscribe to portions of state, via slices)
const updateLocalStorage = (state) => {
  LocalDataService.setNotificationData(state);
};
const clearLocalStorage = (state) => {
  LocalDataService.clearNotificationData();
};
const getFromLocalStorage = () => {
  const data = LocalDataService.getNotificationData();
  if (!data.notifications) {
    data.notifications = [];
    data.preference = null;
    data.messagingId = null;
  }
  return data;
};
// Future: Refactor END

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: getFromLocalStorage(),
  reducers: {
    resetState: (state, action) => {
      state.notifications = [];
      state.preference = null;
      state.messagingId = null;
      clearLocalStorage();
    },

    // MessagingId
    setMessagingId: (state, action) => {
      state.messagingId = action.payload;
      updateLocalStorage(state);
    },
    clearMessagingId: (state, action) => {
      state.messagingId = null;
      updateLocalStorage(state);
    },

    // Preference
    setNotificationPreference: (state, action) => {
      state.preference = action.payload;
      updateLocalStorage(state);
    },
    clearNotificationPreference: (state, action) => {
      state.preference = null;
      updateLocalStorage(state);
    },

    // Notifications
    removeAllNotifications: (state, action) => {
      state.notifications = [];
      updateLocalStorage(state);
    },
    addNotification: (state, action) => {
      const id = action.payload.id;
      const index = state.notifications.findIndex((item) => {
        return item.id === id;
      });
      if (index === undefined || index === -1) {
        state.notifications.push(action.payload);
        updateLocalStorage(state);
      }
    },
    removeNotification: (state, action) => {
      const id = action.payload;
      const index = state.notifications.findIndex((item) => {
        return item.id === id;
      });
      if (index !== undefined && index > -1) {
        const newValue = [...state.notifications];
        newValue.splice(index, 1);
        state.notifications = newValue;
      }
      updateLocalStorage(state);
    },
  },
});

// MessagingId
const getMessagingId = (store) => {
  return store?.notifications?.messagingId;
};

// Preference
const getNotificationPreference = (store) => {
  return store.notifications.preference;
};

// Notifications
const getNotifications = (store) => {
  return store.notifications.notifications;
};

export { getMessagingId, getNotificationPreference, getNotifications };

export const {
  resetState,
  setMessagingId,
  clearMessagingId,
  setNotificationPreference,
  clearNotificationPreference,
  removeAllNotifications,
  addNotification,
  removeNotification,
} = notificationsSlice.actions;

export default notificationsSlice;
