import React from "react";
import { useTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";

const withError = (WrappedComponent) => {
  return (props) => {
    const { error } = props;
    const { t } = useTranslation();

    return (
      <>
        <WrappedComponent {...props} />
        {error && (
          <Message
            visible
            error
            header={t("STAFF_FORM_ERROR_TITLE")}
            content={error}
          />
        )}
      </>
    );
  };
};

export default withError;
