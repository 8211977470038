import React, { useState, useEffect } from "react";
import { Form, Input } from "semantic-ui-react";

import { withTranslation } from "react-i18next";

const NameAndEmailSearch = (props) => {

  const { t, 
    staff,
    handleFilterChanged,
    staffDropdownFilter,
    showAdmin
  } = props;

  const [search, setSearch] = useState("");

  useEffect(() => {
    refreshFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff, search, showAdmin, staffDropdownFilter]);
  
  const refreshFilters = () => {
    const searchStrings = search.split(",");
    let searchList, concatenatedSearchResults = [];
    searchStrings.forEach(searchString => {
      if (searchStrings.length > 1 && searchString === '') {
        return null;
      }
      searchList = staff
      //filter on the status dropdown box
      .filter(staffMember => {
        if (staffDropdownFilter === 'ALL') {
          return true;
        }
        return (staffMember.state === staffDropdownFilter);
      })
      //search for admin staff if showAdmin toggle is enabled
      .filter(staffMember => {
        return showAdmin ? true : staffMember.superAdmin === false;
      })
      //search firstname, lastname, email address and ID
      .filter(s => {
        return s.firstName.toLowerCase().includes(searchString.trim().toLowerCase()) 
          || s.lastName.toLowerCase().includes(searchString.trim().toLowerCase())
          || s.email.toLowerCase().includes(searchString.trim().toLowerCase())
          || s.id.toString().includes(searchString.trim().toLowerCase());
      });

      searchList.forEach(staffMember => {
        if (concatenatedSearchResults.indexOf(staffMember) === -1) {
          concatenatedSearchResults.push(staffMember);
        }
      });
    });
    handleFilterChanged(concatenatedSearchResults);
  };

  return (
    <Form onSubmit={(() => setSearch(""))}>
      <Input
      onChange={(value) => setSearch(value.target.value)}
      style={{ marginLeft: 20, paddingRight: "120px" }}
      value={search}
      placeholder={t("STAFF_NAME_AND_EMAIL_FILTER", "Name, ID and email filter")}
      action={t("STAFF_CLEAR_SEARCH_BUTTON", "Clear")} />
    </Form>
  )
}

export default withTranslation()(NameAndEmailSearch);