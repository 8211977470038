import React, { useMemo } from "react";
import DefaultAuthorise from "./Authorise/DefaultAuthorise";
import ModalAuthorise from "./Authorise/ModalAuthorise";

import { AuthoriseHelpers } from "atom5-branching-questionnaire";

const Authorise = (props) => {
  const {containerType, question} = props;

  const renderComponent = useMemo(() => {
    // In the Subject Data Table, we just need to show whatever Authorise does by default
    if (containerType === "table") {
      return <DefaultAuthorise {...props} />;
    }
    const type = question?.config?.type;
    switch (type) {
      case AuthoriseHelpers.ModalAuthorise.NAME:
        return <ModalAuthorise {...props} />;
      default:
        return <DefaultAuthorise {...props} />
    }
  }, [question, containerType, props]);

  return renderComponent;
};

export default Authorise;
