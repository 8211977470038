import React from "react";
import * as PropTypes from "prop-types";

export function VTUGTable({vtugResults}) {

    const stages = [
        { name: "Stand",  field: "_01_stand"} ,
        { name: "Walk Front", field: "_03_walk"},
        { name: "Turn", field: "_05_turn180"},
        { name: "Walk Back", field: "_07_walkBack"},
        { name: "Turn & Sit", field: "_09_turnSit"},
        { name: "Sit", field: "_10_sit"}

    ]

    const tableStyle = {
        maxHeight: '200px', // Adjust the desired maximum height
        overflowY: 'auto',
    };

    const titleStyle = {
        textAlign: 'center',
        color: 'black',
    };

    const boldTextStyle = {
        fontWeight: 'bold',
    };

    const containerStyle = {
        display: 'flex', // Use flexbox to arrange items horizontally
    };


    return (
        <div style={containerStyle}>
            {vtugResults.map((vtug, index) => (
                <div key={index + "table" } >
                    <h2 style={titleStyle}>vTUG {index + 1 }</h2>
                    <table style={tableStyle}>
                        <thead>
                        <tr>
                            <th>Action</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Steps</th>
                            <th>Duration</th>
                        </tr>
                        </thead>
                        <tbody>
                        {stages.map((stage, stage_index) => (
                            <tr key={index + "." + stage_index}>
                                <td style={{ border: '1px solid #ccc' }}>{stage.name}</td>
                                <td style={{ border: '1px solid #ccc', textAlign: 'right' }}>{(vtug[stage.field].start / 1000).toFixed(2)} s</td>
                                <td style={{ border: '1px solid #ccc', textAlign: 'right' }}>{(vtug[stage.field].end / 1000).toFixed(2)} s</td>
                                <td style={{ border: '1px solid #ccc', textAlign: 'right' }}>{vtug[stage.field].steps}</td>
                                <td style={{ border: '1px solid #ccc', textAlign: 'right' }}>{(vtug[stage.field].duration / 1000).toFixed(2)} s</td>
                            </tr>
                        ))}
                        <tr key={index + "total" } >
                            <td style={{ border: '1px solid #ccc', ...boldTextStyle }}>Total Time</td>
                            <td style={{ border: '1px solid #ccc', textAlign: 'right',  ...boldTextStyle }}>{(vtug.start / 1000).toFixed(2)} s</td>
                            <td style={{ border: '1px solid #ccc', textAlign: 'right',  ...boldTextStyle }}>{(vtug.end / 1000).toFixed(2)} s</td>
                            <td style={{ border: '1px solid #ccc', textAlign: 'right',  ...boldTextStyle }}>{vtug.totalSteps}</td>
                            <td style={{ border: '1px solid #ccc', textAlign: 'right',  ...boldTextStyle }}>{(vtug.duration / 1000).toFixed(2)} s</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
}

VTUGTable.propTypes = {data: PropTypes.any};