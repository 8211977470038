import React, { useEffect, useRef } from 'react';

const VideoCallTrack = (props) => {

  const { track, setIsMuted } = props;

  const ref = useRef();

  useEffect(() => {
    if (track.attach) {
      const child = track.attach();
      child.setAttribute('style', 'max-width: 100%')
      ref.current.appendChild(child);

      if (track && track.kind === 'audio') {
        track.on('disabled', () => setIsMuted(true));
        track.on('enabled', () => setIsMuted(false));
      }
    }
  }, [track, setIsMuted]);

  return <div ref={ref}></div>;
}

export default VideoCallTrack;