import React, { useContext, useEffect, useRef, useState } from "react";
import GroupsContext from "../../context/GroupsContext";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import { Form, Message } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { compose } from "redux";
import { getDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import TrialContext from "../../context/TrialContext";
import { connect } from "react-redux";
import branchingQuestionnaireHelper from "../../helpers/branchingQuestionnaireHelper";
import useForceRerender from "../../hooks/useForceRerender";
import { EVENTS } from "atom5-branching-questionnaire";
import GroupService from "../../services/GroupService";
import i18next from "i18next";

const GroupsUpdateMetadataPage = ({ t, definitions }) => {
  const groups = useContext(GroupsContext);
  const trial = useContext(TrialContext);
  const params = useParams();
  const forceRerender = useForceRerender();
  const history = useHistory();

  const _questionsForCreation = useRef();

  const editingGroup = groups.find((group) => group.id === parseInt(params.id));

  const [loading, setLoading] = useState(true);
  const [questionnaireDefinition, setQuestionnaireDefinition] = useState(null);
  const [metadata, setMetadata] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const getMetadata = async () => {
      setMetadata(await GroupService.getMetadata(editingGroup));
      const groupMetadataQuestionnaire = definitions.find((def) => def.code === trial.groupMetadataQuestionnaire);
      if (groupMetadataQuestionnaire == null) {
        let newError = 'Group Metadata Questionnaire "{}" does not exist';
        if (i18next.exists("GROUPS_UPDATE_METADATA_NO_QUESTIONNAIRE")) {
          newError = t("GROUPS_UPDATE_METADATA_NO_QUESTIONNAIRE")
        }
        newError = newError.replace('{}', trial.groupMetadataQuestionnaire);
        setError(newError);
        return;
      }
      setQuestionnaireDefinition(groupMetadataQuestionnaire);
      setLoading(false);
    };
    getMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definitions, editingGroup]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const builder = branchingQuestionnaireHelper.getBuilderWithRenderers()
      .withQuestions(questionnaireDefinition.questions)
      .on(EVENTS.RERENDER, forceRerender)
      .on(EVENTS.SUBMIT_SUCCESS, handleSubmit)
      .withDefaultValues(metadata)

    _questionsForCreation.current = builder.build().render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const handleSubmit = async (values) => {
    await GroupService.updateMetadata(editingGroup, values);
    history.goBack();
  };

  const getQuestionForm = () => {
    if (!_questionsForCreation.current) {
      return <></>;
    }
  
    const nonNullQuestionsForCreation = Object.values(
      _questionsForCreation.current
    ).filter((renderFunction) => !!renderFunction);

    return nonNullQuestionsForCreation.map(([component, props], index) => (
      <Form.Field style={{ padding: "0.5rem 0" }}>
        {React.createElement(component, {
          ...props,
          key: questionnaireDefinition.code + "_" + props?.question?.code,
        })}
      </Form.Field>
    ))
  }
  

  return (
    <Page
      name="GROUPS_UPDATE_METADATA"
      header={t("GROUPS_UPDATE_METADATA_HEADER", "Update Group Metadata")}
      subheader={t("GROUPS_UPDATE_METADATA_SUBHEADER", "Update the metadata for a group")}
      loading={loading}
    >
      <h3>{editingGroup.label}</h3>
      {getQuestionForm()}
      {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={error}
        />
      )}
    </Page>
  );
}

const mapStateToProps = (state) => {
  return {
    definitions: getDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(GroupsUpdateMetadataPage);
