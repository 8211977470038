import { createSlice } from "@reduxjs/toolkit";

import { cloneDeep } from "lodash";

const currentQuestionnaireSlice = createSlice({
  name: "currentQuestionnaire",
  initialState: {
    id: null,
    state: null,
    subjectId: null,
    questionnaire: {},
    answerMap: {},
    cursor: 0,
    cursorHistoryStack: [],
    isCompleted: false,
    questionnaireUserHistory: [],
  },
  reducers: {
    resetState: (state, action) => {
      state.id = null;
      state.state = null;
      state.subjectId = null;
      state.questionnaire = {};
      state.answerMap = {};
      state.cursor = 0;
      state.cursorHistoryStack = [];
      state.isCompleted = false;
      state.questionnaireUserHistory = [];
    },
    resetCursorHistory: (state, action) => {
      state.cursorHistoryStack = [];
    },
    updateCurrentQuestionnaire: (state, action) => {
      const {
        payload: { questionnaire, subjectId },
      } = action;
      const { id, answerMap, cursorHistoryStack, cursor } = state;

      if (!questionnaire.id) {
        questionnaire.id = questionnaire?.definition?.id;
      }

      // if a new questionnaire is being loaded
      // or if a new subject is being submitted for
      if (state.id !== questionnaire.id || state.subjectId !== subjectId) {
        if (state.id !== null) {
          state.questionnaireUserHistory.push({
            id,
            subjectId: state.subjectId,
            answerMap,
            cursor,
            cursorHistoryStack,
          });
        }

        state.id = questionnaire.id;
        state.subjectId = subjectId;
        state.questionnaire = questionnaire;
        state.isCompleted = false;
        state.questionnaireLength = questionnaire.definition.questions.length;

        // includes costly clone as editing is not possible
        if ("customLabels" in questionnaire) {
          const definition = cloneDeep(questionnaire.definition);
          questionnaire.customLabels.forEach((customLabel) => {
            let questionWithLabel = definition.questions.find(
              (question) => question.code === customLabel.code
            );
            questionWithLabel.label = customLabel.label;
          });
          state.questionnaire.definition = definition;
        }

        // Search for user data from history
        const foundUserDetailsIndex = state.questionnaireUserHistory.findIndex(
          (questionnaireUserDetails) => {
            return (
              questionnaireUserDetails.id === questionnaire.id &&
              questionnaireUserDetails.subjectId === subjectId
            );
          }
        );

        if (foundUserDetailsIndex !== -1) {
          const foundUserDetails =
            state.questionnaireUserHistory[foundUserDetailsIndex];
          // TODO: Should check that calculated values are
          // present and unchanged.

          // add user data to current questionnaire
          state.answerMap = foundUserDetails.answerMap;
          state.cursorHistoryStack = foundUserDetails.cursorHistoryStack;
          state.cursor = foundUserDetails.cursor;

          // remove old data
          state.questionnaireUserHistory.splice(foundUserDetailsIndex, 1);
        } else {
          state.answerMap = questionnaire.answers ? questionnaire.answers : {};
          state.cursorHistoryStack = [];
          state.cursor = 0;
        }
      }
    },
    replaceCursorHistoryStack: (state, action) => {
      const { cursorHistoryStack, cursor } = action.payload;
      if (Array.isArray(cursorHistoryStack)) {
        state.cursorHistoryStack = cursorHistoryStack;
        state.cursor = cursor;
      }
    },
    replaceAnswerMap: (state, action) => {
      state.answerMap = action.payload;
    },
    setCurrentQuestionnaireAsCompleted: (state) => {
      state.id = null;
      state.state = null;
      state.subjectId = null;
      state.questionnaire = {};
      state.answerMap = {};
      state.cursorHistoryStack = [];
      state.cursor = 0;
    },
  },
});

export const {
  resetState,
  resetCursorHistory,
  updateCurrentQuestionnaire,
  replaceCursorHistoryStack,
  replaceAnswerMap,
  setCurrentQuestionnaireAsCompleted,
} = currentQuestionnaireSlice.actions;

const getCurrentQuestionnaire = (state) =>
  state.currentQuestionnaire.questionnaire;
const getCurrentCompletedState = (state) =>
  state.currentQuestionnaire.isCompleted;

export { getCurrentQuestionnaire, getCurrentCompletedState };

export default currentQuestionnaireSlice;
