import React, { useMemo } from "react";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import RenderVideo from "./RenderVideo";
import AuthService from "../../services/AuthService";
import { typeHelper } from "atom5-branching-questionnaire";

const Video = (props) => {

  // START: See AT-2056 for the reason for this, and AT-1855 for the follow up
  const {questionnaire,  question} = props;

  const shouldShowRenderVideo = useMemo(() => {
    const showInCompletedQuestionnaire = typeHelper.parseBool(question?.config?.showInCompletedQuestionnaire);
    return showInCompletedQuestionnaire && AuthService.isSubject() === true && questionnaire?.completionDate != null;
  }, [questionnaire, question]);

  const renderVideoProps = useMemo(() => {
    if (!shouldShowRenderVideo) {
      return;
    }
    const renderVideoConfig = {...question?.config};
    renderVideoConfig.renderAs = 'value';

    const renderVideoQustion = {...question};
    renderVideoQustion.config = renderVideoConfig;

    const renderVideoProps = {...props};
    renderVideoProps.question = renderVideoQustion;
    return renderVideoProps;
  }, [props, question, shouldShowRenderVideo]);

  if (shouldShowRenderVideo) {
    return <RenderVideo {...renderVideoProps} />;
  }
  // END: See AT-2056 for the reason for this, and AT-1855 for the follow up

  console.warn("VIDEO questions are not currently supported.");
  return null;
};

export default withLabel(withError(Video));
