import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { useLocation } from "react-router";
import SubjectService from "../../SubjectService";
import ConfigContext from "../../context/ConfigContext";
import { defaultNavigationConfig } from "../../constants/SUBJECT_NAVIGATION";
import {
  getCompletedDatas,
  getCompletedEvents,
  getCompletedPros,
  getCurrentContents,
  getCurrentDatas,
  getCurrentPros,
} from "../../redux/questionnaires/subjectQuestionnairesSlice";
import { connect } from "react-redux";
import MenuItem from "./MenuItem";
import {
  getContentDefinitions,
  getDataDefinitions,
  getProDefinitions,
} from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import questionnaireFilters from "../../helpers/filters/questionnaireFilters";
import { filterCompletedQuestionnaires } from "../../helpers/completedQuestionnairesHelper";

const SubjectMenu = (props) => {
  const { t, badgeDetails, user, definitions, completedQuestionnaires } = props;

  const location = useLocation();
  const config = useContext(ConfigContext);

  const questionnaires = [
    ...badgeDetails?.contents,
    ...badgeDetails?.completedData,
    ...badgeDetails?.pros,
  ];

  let filteredCompletedQuestionnaires = completedQuestionnaires;
  if (
    completedQuestionnaires !== null &&
    completedQuestionnaires.length !== undefined
  ) {
    filteredCompletedQuestionnaires = filterCompletedQuestionnaires(
      completedQuestionnaires ? completedQuestionnaires : [],
      definitions,
      config.data
    );
  }

  let [showScreeningQuestionnaires, setShowScreeningQuestionnaires] =
    useState(false);
  let [loading, setLoading] = useState(true);
  let [hasConsented, setHasConsented] = useState(
    user.profile?.consentDate !== null
  );

  useEffect(() => {
    determinScreeningStatus();
    setHasConsented(user.profile?.consentDate !== null);
  }, [user]);

  const determinScreeningStatus = async () => {
    let hasScreeningQuestionnaires = false;

    const questionnaireDefinitions =
      await SubjectService.getQuestionnaireDefinitions("EVENT");
    if (questionnaireDefinitions) {
      const screeningQuestionnaires = SubjectService.getScreeningQuestionnaires(
        questionnaireDefinitions,
        Window.configuration.ui.selfOnboarding
      );
      hasScreeningQuestionnaires =
        screeningQuestionnaires && screeningQuestionnaires.length > 0;
    }

    if (hasScreeningQuestionnaires) {
      const doesSubjectNeedToCompleteScreeningQuestionnaires =
        await SubjectService.doesSubjectNeedToCompleteScreeningQuestionnaires();
      setShowScreeningQuestionnaires(
        doesSubjectNeedToCompleteScreeningQuestionnaires
      );
    }
    setLoading(false);
  };

  const getLinkFromScreen = (screen, payload) => {
    switch (screen) {
      case "pros":
        return "/app/subject/pro";
      case "events":
        return "/app/subject/event";
      case "contents":
      case "library":
        return "/app/subject/content";
      case "completeddata":
        return "/app/subject/data";
      case "settings":
        return "/app/settings";
      case "module":
        return "/app/subject/module/" + payload;
      default:
        return null;
    }
  };

  const getBadgeValueFromScreen = (screen, payload) => {
    const isQuietFilter = (questionnaire) => {
      const definition = definitions.find(
        (def) => def.id === questionnaire.definitionId
      );
      return !definition?.config?.quiet;
    };

    switch (screen) {
      case "pros":
        return badgeDetails.pros
          .filter((q) => {
            return questionnaireFilters.nonModuleFilter(definitions, q);
          })
          .filter(isQuietFilter).length;
      case "contents":
      case "library":
        return badgeDetails.contents
          .filter((q) => {
            return questionnaireFilters.nonModuleFilter(definitions, q);
          })
          .filter(isQuietFilter).length;
      case "completeddata":
        return badgeDetails.completedData
          .filter((q) => {
            return questionnaireFilters.nonModuleFilter(definitions, q);
          })
          .filter(isQuietFilter).length;
      case "module":
        return (
          badgeDetails.completedData
            .filter((q) => {
              return questionnaireFilters.moduleFilter(definitions, q, payload);
            })
            .filter(isQuietFilter).length +
          badgeDetails.contents
            .filter((q) => {
              return questionnaireFilters.moduleFilter(definitions, q, payload);
            })
            .filter(isQuietFilter).length +
          badgeDetails.pros
            .filter((q) => {
              return questionnaireFilters.moduleFilter(definitions, q, payload);
            })
            .filter(isQuietFilter).length
        );

      default:
        return null;
    }
  };

  const getModuleItemCount = (moduleCode = null) => {
    const availableItems = questionnaires
      .filter((q) => {
        return questionnaireFilters.moduleFilter(definitions, q, moduleCode);
      })
      .filter(questionnaireFilters.notCompletedFilter);

    const completeItems = filteredCompletedQuestionnaires.filter((q) => {
      return questionnaireFilters.moduleFilter(definitions, q, moduleCode);
    });

    return availableItems.length + completeItems.length;
  };

  const filterModules = (navItems, config) => {
    if (!config?.modules) {
      // No module config found - return nav items as-is
      return navItems;
    }
    const filteredModules = navItems.filter((navItem) => {
      if (!navItem?.screen?.startsWith("module")) {
        // Not a module - show by default
        return true;
      }
      const [screenName, moduleCode] = navItem.screen.split("/");
      if (screenName !== "module" || moduleCode == null) {
        // Invalid module config - show by default
        return true;
      }
      const moduleConfig = config?.modules[moduleCode]?.config;
      if (!moduleConfig) {
        // No module config found - show by default
        return true;
      }
      if (
        moduleConfig.subject?.hideIfEmpty === undefined ||
        moduleConfig.subject?.hideIfEmpty === false
      ) {
        // Not set to hideIfEmpty - show by default
        return true;
      }
      const moduleItemCount = getModuleItemCount(moduleCode);
      if (moduleItemCount === 0) {
        // Count of items is 0 - hide
        return false;
      }
      // Fallback - show by default
      return true;
    });
    return filteredModules;
  };

  const buildNavigation = (navigationArray) => {
    return navigationArray
      .map((nav) => {
        const screen = nav.screen.split("/")[0];
        const link = getLinkFromScreen(screen, nav.screen.split("/")[1]);
        const badgeValue = getBadgeValueFromScreen(
          screen,
          nav.screen.split("/")[1]
        );
        if (!link) {
          return null;
        }
        return (
          <MenuItem
            to={link}
            active={location.pathname === link}
            badgeValue={badgeValue}
            text={t(nav.webText)}
            icon={nav.icon}
            isButtonStyle={true}
          />
        );
      })
      .filter((n) => !!n);
  };

  const filteredMenuItems = filterModules(
    config.home?.navigation || defaultNavigationConfig,
    config
  );
  const configuredNavigation = buildNavigation(filteredMenuItems);

  return (
    <>
      {showScreeningQuestionnaires && (
        <MenuItem
          to={"/app/subject/screening"}
          active={location.pathname === "/app/subject/screening"}
          badgeValue={true}
          icon={"tasks"}
          text={t("MENU_SCREENING")}
          isButtonStyle={true}
        />
      )}
      {!loading && !showScreeningQuestionnaires && !hasConsented && (
        <MenuItem
          to={"/app/subject/study-consent"}
          active={location.pathname === "/app/subject/study-consent"}
          badgeValue={true}
          icon={"tasks"}
          text={t("MENU_STUDY_CONSENT")}
          isButtonStyle={true}
        />
      )}
      {!loading && !showScreeningQuestionnaires && hasConsented && (
        <>{configuredNavigation}</>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    badgeDetails: {
      contents: getCurrentContents(state),
      pros: getCurrentPros(state),
      completedData: getCurrentDatas(state),
    },
    completedQuestionnaires: [
      ...getCompletedPros(state),
      ...getCompletedEvents(state),
      ...getCompletedDatas(state),
    ],
    definitions: [
      ...getContentDefinitions(state),
      ...getDataDefinitions(state),
      ...getProDefinitions(state),
    ],
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(SubjectMenu);
