import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import DATA_QUERY_TABLE_ACTIONS from '../../constants/dataquery/DATA_QUERY_TABLE_ACTIONS';
import DATA_QUERY_TABLE_COLUMNS from '../../constants/dataquery/DATA_QUERY_TABLE_COLUMNS';
import DataQueryModalStyles from './DataQueryModalStyles';
import DataQueryTable from './DataQueryTable';

const ViewDataQueriesModal = ({t, open, onClose, queries, showAnswerButton, onAnswerPress, showCloseButton, onClosePress, renderedQuestion}) => {

  const columns = [
    DATA_QUERY_TABLE_COLUMNS.STATUS,
    DATA_QUERY_TABLE_COLUMNS.RAISED_BY,
    DATA_QUERY_TABLE_COLUMNS.RAISED_AT,
    DATA_QUERY_TABLE_COLUMNS.ANSWERED_BY,
    DATA_QUERY_TABLE_COLUMNS.ANSWERED_AT,
    DATA_QUERY_TABLE_COLUMNS.CLOSED_BY,
    DATA_QUERY_TABLE_COLUMNS.CLOSED_AT,
  ];

  let actionsToShow = [
    showAnswerButton && DATA_QUERY_TABLE_ACTIONS.ANSWER, 
    showCloseButton && DATA_QUERY_TABLE_ACTIONS.CLOSE,
  ].filter(a => a);

  let on = {};
  on[DATA_QUERY_TABLE_ACTIONS.ANSWER] = onAnswerPress;
  on[DATA_QUERY_TABLE_ACTIONS.CLOSE] = onClosePress;

  const tableProps = {
    queries,
    columns,
    actionsToShow,
    on,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{padding: 15, width: '90%'}}
    >
      <h2>{t("DATA_QUERY_VIEW_HEADER")}</h2>
      <p>{t("DATA_QUERY_VIEW_DESCRIPTION")}</p>

      <Form>
        <div style={DataQueryModalStyles.important}>
          {renderedQuestion}
        </div>
      </Form>

      <DataQueryTable {...tableProps} />
    </Modal>
  )
};

export default withTranslation()(ViewDataQueriesModal);