import React, { Component, Fragment } from "react";
import SubjectService from "../../../SubjectService";
import { Placeholder } from "semantic-ui-react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
import moment from "moment";

class MedicationComplianceGraph extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.initialise();
    this.mounted = true;
  }

  initialise = () => {
    SubjectService.getMedicationCompliance(
      this.props.subjectId,
      moment(this.props.startDate).format("YYYY-MM-DD"),
      moment(this.props.endDate).format("YYYY-MM-DD")
    )
      .then((complianceData) => {
        if (this.mounted) {
          this.setState({ complianceData, loading: false });
        }
      })
      .catch(this.errorHandler);

    return {
      loading: true,
      complianceData: [],
      error: false,
    };
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ error: true });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.subjectId !== this.props.subjectId ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.setState(this.initialise());
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render() {
    const { t } = this.props;

    let complianceSeries = {
      name: t("MEDICATION_CHART_COMPLIANCE"),
      data: [],
    };
    let baselineSeries = {
      name: t("MEDICATION_CHART_BASELINE_COMPLIANCE"),
      data: [],
    };
    let categories = [];

    this.state.complianceData.forEach((medication) => {
      complianceSeries.data.push(medication.compliance);
      baselineSeries.data.push(medication.baselineCompliance);
      categories.push(medication.name);
    });

    const chartData = {
      series: [complianceSeries, baselineSeries],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          min: 0,
          max: 100,
          title: {
            text: t("MEDICATION_CHART_COMPLIANCE"),
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    };

    return (
      <Fragment>
        {this.state.loading && (
          <Placeholder fluid style={{ height: 350 }}>
            <Placeholder.Image />
          </Placeholder>
        )}

        {!this.state.loading && (
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(MedicationComplianceGraph);
