import React from "react";

const CheckSelector = (props) => {
  const { isSelected, isUnselectable, disabled } = props;

  if (isUnselectable) {
    return (
      <img
        src="/images/selectors/checkDisabled.svg"
        alt="check disabled"
        height="20px"
        width="20px"
      />
    );
  }

  return (
    <div>
      {!disabled && isSelected && (
        <img
          src="/images/selectors/checkSelected.svg"
          alt="check selector selected"
          height="20px"
          width="20px"
        />
      )}
      {!disabled && !isSelected && (
        <img
          src="/images/selectors/checkUnselected.svg"
          alt="check selector unselected"
          height="20px"
          width="20px"
        />
      )}
      {disabled && isSelected && (
        <img
          src="/images/selectors/checkSelectedDisabled.svg"
          alt="check selector selected disabled"
          height="20px"
          width="20px"
        />
      )}
      {disabled && !isSelected && (
        <img
          src="/images/selectors/checkUnselectedDisabled.svg"
          alt="check selector unselected disabled"
          height="20px"
          width="20px"
        />
      )}
    </div>
  );
};

export default CheckSelector;
