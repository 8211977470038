import React, { Component } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import SignatureService from "./SignatureService";
import RequestHelper from "../RequestHelper";

class SignatureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      password: "",
      error: null,
      requestPending: false,
      visible: this.props.show,
    };
    this.successCallback = this.props.onSignatureCreated;

    if (this.props.isGlobalInstance) {
      RequestHelper.signatureModalInstance = this;
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.show !== this.props.show) {
      this.setState({ visible: this.props.show });
    }
  };

  activate = (successCallback) => {
    this.successCallback = successCallback;
    this.setState({ visible: true });
  };

  validateForm() {
    return this.state.password.length > 0 && this.state.reason.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { password, reason } = this.state;
    this.setState({ error: null, requestPending: true }, () => {
      SignatureService.createSignatureToken(password, reason)
        .then((signatureToken) => {
          this.setState(
            {
              visible: false,
              reason: "",
              password: "",
              error: null,
              requestPending: false,
            },
            () => {
              this.successCallback(signatureToken);
            }
          );
        })
        .catch((errors) => {
          const errorMessage = errors[0].message;
          let errorMessageKey = "ERROR_UNKNOWN";
          if (errorMessage.indexOf("Password does not match") !== -1) {
            errorMessageKey = "ERROR_PASSWORD_DOES_NOT_MATCH";
          }
          this.setState({
            password: "",
            error: errorMessageKey,
            requestPending: false,
          });
        });
    });
  };

  close = () => {
    this.setState({ visible: false });
  };

  render() {
    const { t } = this.props;

    return (
      <Modal
        open={this.state.visible}
        onClose={this.props.onClose || this.close}
        closeIcon
      >
        <Modal.Header>{t("SIGNATURE_MODAL_HEADER_TITLE")}</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={this.onSubmit}
            error={this.state.showErrors}
            noValidate
          >
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              name="password"
              placeholder={t("GLOBAL_LABEL_PASSWORD", "Password")}
              type="password"
              value={this.state.password}
              onChange={this.handleChange}
              disabled={this.state.requestPending}
              autoFocus
            />

            <Form.TextArea
              name="reason"
              label={t("SIGNATURE_DIALOG_REASON")}
              rows="5"
              maxLength="4000"
              onChange={this.handleChange}
              value={this.state.reason}
              disabled={this.state.requestPending}
            />

            <Button
              type="submit"
              primary
              disabled={!this.validateForm() || this.state.requestPending}
            >
              {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
            </Button>

            {this.state.error && (
              <div className="ui negative message">{t(this.state.error)}</div>
            )}
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation()(SignatureModal);
