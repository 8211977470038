import React, { memo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ControlButton from './ControlButton';
import AnnotationAddEditForm from './AnnotationAddEditForm';
import { Button, Header, Modal } from 'semantic-ui-react';
import GeneralHelpers from '../../../../helpers/GeneralHelpers';

const AnnotationAddIcon = ({
  t,
  viewingProgress,
  controlState,
  annotationOptions,
  onSave
}) => {
  console.log('AnnotationAddIcon LOAD');

  const handleClick = (_action, e) => {
    GeneralHelpers.stopEvent(e);
    setIsModalOpen(true);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = (annotation) => {
    if (onSave) {
      onSave(annotation);
    }
    handleModalClose();
  }

  return (
    <>
      <ControlButton
        buttonText={t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_ANNOTATIONS_ADD', 'Add annotation')}
        iconName={'edit'}
        onClick={handleClick}
      />
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        closeIcon={true}
        closeOnDimmerClick={true}
        closeOnDocumentClick={true}
      >
        <Modal.Content scrolling>
          <Header as='h2'>{t('TODO: ', 'TODO: ADD or EDIT? Annotation')}</Header>
          <AnnotationAddEditForm
            controlState={controlState}
            annotationOptions={annotationOptions}
            newAnnotationStartTime={viewingProgress.currentTimeMilliseconds}
            onSave={handleSave}
          />
          <Button onClick={handleModalClose}>
            {t(["TODO: ", "GLOBAL_BUTTON_CLOSE"], "Close")}
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default memo(withTranslation()(AnnotationAddIcon));
