import React, { useContext, useEffect, useState, useCallback } from "react";
import { Button, Icon, Message, Progress, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AttachmentService, {
  MEDIA_STATE,
} from "../../services/AttachmentService";
import { QUESTION_TYPES, typeHelper } from "atom5-branching-questionnaire";
import AttachmentPreview from "../../components/attachments/AttachmentPreview";
import VideoPlayer from "../../components/media/VideoPlayer";
import EnhancedVideoPlayer from "../../components/media/EnhancedVideoPlayer/EnhancedVideoPlayer";
import UserContext from "../../context/UserContext";
import AttachmentDownload from "./AttachmentDownload";
import { getSkeletonAnalysis, MediapipeSkeleton, ChannelList} from "atom5-data-analysis"
import {constructVTugBarGraphDataChartjs,extractAnnotations} from "atom5-data-analysis/ui/web"

import SkeletonView from "../../data-analysis/ui/skeleton/SkeletonView";
import ChannelListScatterChart from "../../data-analysis/ui/graphs/ChannelListScatterChart";
import StackedColumnChart from "../../data-analysis/ui/graphs/StackedColumnChart";
import {VTUGTable} from "../../data-analysis/ui/VTUGTable";
import {SliderInput} from "./SliderInput";
import Switch from "react-switch";
import {EventsTable} from "../../data-analysis/ui/EventsTable";


const MEDIA_SIZE = {
  SMALL: {
    width: 500,
  },
  NORMAL: {
    width: 750, 
  },
  LARGE: {
    width: 1000,
  },
};


const AttachmentInlineWithAnalysisVtug = ({ question, reference, subjectId, t, printable, blurredOnly , hasAllowSubjectMediaAudioPermission}) => {
  const forceUseEnhancedPlayer = true //typeHelper.parseBool(question.config?.useEnhancedPlayer);
  const [useEnhancedPlayer, setUseEnhancedPlayer] = useState(forceUseEnhancedPlayer);
  const user = useContext(UserContext);


  const trackingEnabled = typeHelper.parseBool(question.config?.trackingConfig?.enabled)

  const [maxTimeSeconds, setMaxTimeSeconds] = useState(0)
  const [annotations, setAnnotations] = useState([])
  const [skeletonAnalysis]= useState(getSkeletonAnalysis("vtug"))


  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0); // Initial slider value
  const [trackingOverlay, ] = useState(trackingEnabled)
  const [vtugChartData, setVTugChartData] = useState({
    labels: [],
    datasets: [ ],
  })
  const [vtugResults, setVtugResults] = useState([]);
  const [skeleton, setSkeleton] = useState(new MediapipeSkeleton())
  const [analysisParameters, setAnalysisParameters] = useState(skeletonAnalysis.analysisParameters);
  const [events,setEvents] = useState([]);


  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaState, setMediaState] = useState(null);
  const [mediaSize, setMediaSize] = useState(MEDIA_SIZE.SMALL);
  const [attachment, setAttachment] = useState(null);
  const [blobData, setBlobData] = useState(undefined);


  const loadContent = async () => {
    const attachmentResponse = await AttachmentService.getAttachment(
      subjectId,
      reference,
      blurredOnly
    );

    if(attachmentResponse.groupReference !== ""){
      const attachmentGroupResponse = await AttachmentService.getAttachmentGroup(subjectId,attachmentResponse.groupReference,blurredOnly)
      /// Check if in attachmentGroup there are skeleton files
      const skeletonAttachment = attachmentGroupResponse.find(map => map["mediaType"].includes("skeleton.mlkit"));

      if(skeletonAttachment && AttachmentService.getMediaStateFromString(skeletonAttachment.state) === MEDIA_STATE.COMPLETED){

        AttachmentService.getAttachmentInline(
            subjectId,
            skeletonAttachment.reference,
            blurredOnly,
            (progress) => {
              //console.log(progress.percentage);
            },
            (skeletonDataUrl, blob) => {
              skeletonAnalysis.loadAttachment(skeletonDataUrl,displayResults)

            },
            (err) => {
              console.error('Error getAttachmentInline', err)
            }
        )
      }
  }

    setAttachment(attachmentResponse);
    if (attachmentResponse && attachmentResponse.state) {
      const state = AttachmentService.getMediaStateFromString(
        attachmentResponse.state
      );
      setMediaState(state);
    }
  };


  useEffect(() => {
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [channelListTop, ] = useState(new ChannelList(20,100))
  const [channelListBottom, ] = useState(new ChannelList(20,100))

  const updateCharts = useCallback(() => {
    channelListTop.emitDataChangedEvent()
    channelListBottom.emitDataChangedEvent()

    setAnnotations(extractAnnotations(skeletonAnalysis.vTugs))
    setVTugChartData(constructVTugBarGraphDataChartjs(skeletonAnalysis.vTugs,0.001))

    setVtugResults(skeletonAnalysis.vTugs)
    setEvents(skeletonAnalysis.events)
    console.log(skeletonAnalysis.events)
  }, [skeletonAnalysis.events, channelListTop,channelListBottom,skeletonAnalysis.vTugs]);



  const displayResults = useCallback(() => {
    setAnalysisParameters(skeletonAnalysis.analysisParameters)
    setMaxTimeSeconds(skeletonAnalysis.sktChannel.getDuration() / 1000)
    updateCharts();

  }, [updateCharts, skeletonAnalysis.analysisParameters,skeletonAnalysis.sktChannel]);


  const filterActiveChanged =  (checked) => {

    const filterSettings = { activeFiltering:checked };
    const newParameters= {...analysisParameters,...filterSettings }
    setAnalysisParameters(newParameters);
    skeletonAnalysis.doAnalysis(newParameters);
    updateCharts();
  }



  useEffect(() => {
    channelListTop.addDataChannel(skeletonAnalysis.shouldersChan,"red",2,"y");
    channelListTop.addDataChannel(skeletonAnalysis.shouldersDifChan, "brown", 1, "y1");

    channelListTop.addDataChannel(skeletonAnalysis.hipsChan,"green",2,"y");
    channelListTop.addDataChannel(skeletonAnalysis.visibilityChan,"blue",1,"y1");
    channelListTop.addDataChannel(skeletonAnalysis.turnStateChan,"black",2,"y1");


    channelListBottom.addDataChannel(skeletonAnalysis.anklesChan,"purple",2,"y");
    channelListBottom.addDataChannel(skeletonAnalysis.heightChan,"blue",2,"y");
    channelListBottom.addDataChannel(skeletonAnalysis.percentLegStretchChan,"green","2","y1");
    channelListBottom.addDataChannel(skeletonAnalysis.stepsChan,"black",2,"y1");
    channelListBottom.addDataChannel(skeletonAnalysis.sitStandStateChan,"grey",2,"y1");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skeletonAnalysis]);

  const legStretchThresholdChanged = (value) => {
    const threshold_stretch = { threshold_stretch: value };
    const newParameters = { ...analysisParameters, ...threshold_stretch };
    setAnalysisParameters(newParameters);
    skeletonAnalysis.doAnalysis(newParameters);
    updateCharts();
  };

  const turnThresholdChanged = (value) => {
    let newValue = value;

    if( analysisParameters.threshold_turn === newValue)
      return;
    const threshold_turn = { threshold_turn: newValue};
    const newParameters = { ...analysisParameters, ...threshold_turn };
    setAnalysisParameters(newParameters);
    skeletonAnalysis.doAnalysis(newParameters);
    updateCharts();
  };


  useEffect(() => {
    if (
      mediaState !== MEDIA_STATE.COMPLETED &&
      mediaState !== MEDIA_STATE.LEGACY
    ) {
      return;
    }
    AttachmentService.getAttachmentInline(
      subjectId,
      reference,
      blurredOnly,
      (progress) => {
        setLoadingPercentage(progress.percentage);
      },
      (mediaDataUrl, blob) => {
        setMediaUrl(mediaDataUrl);
        setBlobData(blob);
        setIsLoaded(true);
      },
      (err) => {
        console.error('Error getAttachmentInline', err)
        setError(err);
        setIsLoaded(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState]);

  const onMediaLoadError = (err) => {
    console.error('onMediaLoadError', err)

    setError(err);
  };

  const onTimeUpdate = (timeMS) => {
    setSkeleton(skeletonAnalysis.sktChannel.getDataTime(timeMS))
    setCurrentTimeSeconds(timeMS / 1000)
  }

  const getEnhancedVideoPlayer = () => {
    const enhancedVideoPlayerProps = {
      videoData: {
        url: mediaUrl,
      },
      options: {
        allowMuteChange: hasAllowSubjectMediaAudioPermission
      },
      onError: onMediaLoadError
    };
    return <EnhancedVideoPlayer {...enhancedVideoPlayerProps} />;
  }


  const getEnhancedVideoPlayerWithTrackingOverlay = () => {
    const enhancedVideoPlayerProps = {
      videoData: {
        url: mediaUrl,
      },
      options: {
        allowMuteChange: hasAllowSubjectMediaAudioPermission
      },
      onError: onMediaLoadError,
      onTimeUpdate: onTimeUpdate, 
      expandedView: false

    };
    return (
        <div style={parentStyle}>
          <div>
          <EnhancedVideoPlayer style={videoPlayerStyle} {...enhancedVideoPlayerProps} />
          </div>
          <div style={overlayContainerStyle}>
            <div style={overlayStyle}> 
            <SkeletonView width={600} heigh={800} skeleton={skeleton} scale={{x: 0.0014, y: 0.0008}} visible={true}
                            lineColor="white" invertY={false}/>
            </div>
          </div>
        </div>
    );
  };

  const getPlayerToggle = () => {
    if (user?.profile?.superAdmin !== true) {
      return null;
    }
    return (
      <div style={{ textAlign: 'left' }}>
        <Button
          onClick={() => setUseEnhancedPlayer(!useEnhancedPlayer)}
          size='tiny'
          color='grey'
          content={useEnhancedPlayer ? 'Use Standard Player' : 'Use Experimental Player'}
        />
      </div>
    );
  };

  const getPlayer = () => {
    if (useEnhancedPlayer) {
      if(trackingOverlay){
        return getEnhancedVideoPlayerWithTrackingOverlay();
      }else {
        return getEnhancedVideoPlayer();
      }
    }

    return (<VideoPlayer
      width={mediaSize.width + "px"}
      height="100%"
      mediaUrl={mediaUrl}
      showFrameByFrameControls
      controls
      onError={onMediaLoadError}
      hasAllowSubjectMediaAudioPermission={hasAllowSubjectMediaAudioPermission}      
    />);

  }


  const videoPlayerStyle = {
    position: 'relative',
    width: "100%",
    heith: "100%",
    border: '1px solid #ccc', // Example border
  };

  const parentStyle = {
    position: 'relative',
    width: "100%",
    heith: "800",
    border: '1px solid #ccc', // Example border
  };
  
  const overlayContainerStyle = {
    //backgroundColor: 'green',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  
  const overlayStyle = {
    //backgroundColor: 'green',
    //borderRadius: '5px',
    zIndex: 11,
    //width: '200',
    height: '800',
  };





  return (
    <>
      {!isLoaded &&
        !error &&
        (mediaState === MEDIA_STATE.COMPLETED ||
          mediaState === MEDIA_STATE.LEGACY) && (
          <Progress percent={loadingPercentage} indicating />
        )}
      {error &&
        (mediaState === MEDIA_STATE.COMPLETED ||
          mediaState === MEDIA_STATE.LEGACY) && (
          // Error
          <Message negative>{t("ERROR_LOADING_VIDEO")}</Message>
        )}
      {mediaState !== null &&
        mediaState !== MEDIA_STATE.COMPLETED &&
        mediaState !== MEDIA_STATE.LEGACY && (
          // Have state, Not Completed, Not Legacy - show info
          <>
            <Message
              icon={mediaState?.displayProperties?.icon}
              info={mediaState?.displayProperties?.isInfo}
              content={t(mediaState?.translationKey, mediaState?.fallbackText)}
              style={{ width: "600px" }}
            />
          </>
        )}
      {(mediaState === MEDIA_STATE.COMPLETED ||
        mediaState === MEDIA_STATE.LEGACY) && (
          // Completed or Legacy - Display It
          <>
            {!useEnhancedPlayer && question.type !== QUESTION_TYPES.FILE && !printable && (
              <Segment compact>
                <Icon
                  name={"desktop"}
                  size={"small"}
                  style={{ marginRight: "10px" }}
                  className={"mouse-pointer"}
                  onClick={() => {
                    setMediaSize(MEDIA_SIZE.SMALL);
                  }}
                />
                <Icon
                  name={"desktop"}
                  size={"large"}
                  style={{ marginRight: "10px" }}
                  className={"mouse-pointer"}
                  onClick={() => {
                    setMediaSize(MEDIA_SIZE.NORMAL);
                  }}
                />
                <Icon
                  name={"desktop"}
                  size={"big"}
                  className={"mouse-pointer"}
                  onClick={() => {
                    setMediaSize(MEDIA_SIZE.LARGE);
                  }}
                />
              </Segment>
            )}
            {(question.type === QUESTION_TYPES.IMAGE ||
              question.type === QUESTION_TYPES.SIGNATURE) && (
                <>
                  {isLoaded && !error && (
                    <>
                      <img
                        width={mediaSize.width}
                        src={mediaUrl}
                        alt={reference}
                        data-value={reference}
                      />
                    </>
                  )}
                </>
              )}
            {question.type === QUESTION_TYPES.VIDEO && (
              <>
              <div style={containerStyle}>
                <>
                  <div style = {trackingEnabled?columnStretchStyle:{}} >

                  <div>
                  {(isLoaded && !forceUseEnhancedPlayer) && getPlayerToggle()}
                  {isLoaded && getPlayer()}
                  </div>
                </div>
                </>

                {trackingEnabled && (
                <div style = {columnStretchStyle} >
                  <ChannelListScatterChart channelList = {channelListTop} selectedPosition = {currentTimeSeconds}  minX = {0} maxX = {Math.round(maxTimeSeconds)} minLeft = {-1}  maxLeft = {+1} minRight = {0}  maxRight = {100} />
                  <ChannelListScatterChart channelList = {channelListBottom} selectedPosition = {currentTimeSeconds}  minX = {0} maxX = {Math.round(maxTimeSeconds)} minLeft = {-1}  maxLeft = {+1} minRight = {0}  maxRight = {100}  />
                  <ChannelListScatterChart selectedPosition = {currentTimeSeconds}  minX = {0} maxX = {Math.round(maxTimeSeconds)} minLeft = {0}  maxLeft = {10} externalAnnotations = {annotations}/>
                  {analysisParameters && (
                      <div>
                        <SliderInput defaultValue={analysisParameters.threshold_turn} name={"Threshold Turn"} max={1.0} step={0.01} onValueChange={turnThresholdChanged} />
                        <SliderInput defaultValue={analysisParameters.threshold_stretch} name={"Threshold Stretch"} max={1.0} step={0.01} onValueChange={legStretchThresholdChanged} />
                        <div>{analysisParameters.activeFiltering ? 'Filter Active' : 'Filter Inactive'}</div>
                        <Switch  checked={analysisParameters.activeFiltering} onChange={filterActiveChanged}/>
                      </div>
                )}
                </div> )}
               </div>
               {trackingEnabled && (
                   <div style = {graphContainer}>
                     <EventsTable events = {events} />
                      <StackedColumnChart  title= {"vTUG timings"} data={vtugChartData} />
                     <VTUGTable vtugResults={vtugResults} />

                   </div>
               )}
               
                
               </>
            )}
            {question.type === QUESTION_TYPES.FILE && (
              <>
                {isLoaded && !error && (
                  <AttachmentPreview
                    question={question}
                    attachment={attachment}
                    url={mediaUrl}
                    blob={blobData}
                  />
                )}
              </>
            )}

            <AttachmentDownload
              subjectId={subjectId}
              reference={reference}
              blurredOnly={blurredOnly}
              mediaState={mediaState}
            />
          </>
        )}
    </>
  );
};

 

const graphContainer = {
  display: 'flex',
  height: '100vh',          // Optional: Set a height to center vertically in the viewport
  padding: '50px',
  justifyContent: 'center', // Horizontally center
};

const containerStyle = {
  display: 'flex',
};


const columnStretchStyle = {
  flex: 1,
  margin: '0 10px',
  border: '1px solid #ccc',
  padding: '10px',
};

export default withTranslation()(AttachmentInlineWithAnalysisVtug);
/*

 */