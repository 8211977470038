/* eslint no-unused-vars: 0 */

import React, { Fragment, useContext, useEffect, useState } from "react";
import { Message, Divider, Form } from "semantic-ui-react";

import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import StaffService from "../../StaffService";
import StaffForm from "../../components/staff/StaffForm";
import DateTimeService from "../../services/DateTimeService";
import ConfigContext from "../../context/ConfigContext";
import auditDisplayHelper from "../../helpers/auditDisplayHelper";

const StaffCreateAndEditPage = ({ t, match }) => {
  const config = useContext(ConfigContext);

  const staffId = match.params.id;
  const isNew = staffId === undefined;

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const [staff, setStaff] = useState(null);
  const [staffExists, setStaffExists] = useState(true);

  useEffect(() => {
    if (!isNew) {
      const runSetup = async () => {
        try {
          const staff = await StaffService.getStaff(staffId);
          await Promise.all([setStaff(staff), setStaffExists(true)]);
          if (!staff) {
            setErrorMessage(t("STAFF_EMAIL_EXISTS_ERROR_MESSAGE"));
          }
        } catch {
          setStaffExists(false);
        } finally {
          setLoading(false);
        }
      };
      runSetup();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, staffId]);

  const onSubmit = (staff) => {
    setErrorMessage(null);

    let createOrUpdateFunction = StaffService.createStaff;

    const args = [
      staff.email,
      staff.firstName,
      staff.lastName,
      staff.drugManager,
      staff.groupRoles,
      staff.superAdmin
    ];

    if (!isNew) {
      createOrUpdateFunction = StaffService.updateStaff;
      args.unshift(staffId);
    }

    return createOrUpdateFunction(...args)
      .then(() => {
        window.location.href = window.location.origin + "/app/staff";
      })
      .catch(([e]) => {
        handleError(e);
      });
  };

  const handleError = (e) => {
    let messageText;
    if (e?.message?.indexOf("Email exists") >= 0) {
      messageText = t("STAFF_EMAIL_EXISTS_ERROR_MESSAGE");
    }
    if (e?.message?.indexOf("group role") >= 0) {
      messageText = t("STAFF_NO_GROUP_ROLES_ERROR_MESSAGE");
    }
    if (!messageText) {
      messageText = t(
        "GLOBAL_ERROR_GENERIC_FORM_MESSAGE",
        "Please fill in all the required fields"
      );
    }
    setErrorMessage(messageText);
  };

  const auditLastUpdated = () => {
    if (!isNew) {
      if (staff && staff.auditLastUpdated) {
        return DateTimeService.build.asDisplayDateTime(staff.auditLastUpdated);
      }
    }
    return "";
  };

  const auditLastUpdatedBy = () => {
    return auditDisplayHelper.getAuditDisplayForStaff(staff, config);
  };

  const pageName = isNew ? "STAFF_CREATE" : "STAFF_EDIT";
  const pageHeader = isNew ? "STAFF_CREATE_HEADER" : t("STAFF_EDIT_HEADER");
  const pageSubHeader = isNew
    ? "STAFF_CREATE_SUBHEADER"
    : "STAFF_EDIT_SUBHEADER";

  return (
    <Page
      name={pageName}
      header={t(pageHeader)}
      subheader={t(pageSubHeader)}
      loading={loading}
    >
      {staffExists && (
        <Fragment>
          <StaffForm onSubmit={onSubmit} staff={staff} error={errorMessage} />
          {!isNew && (
            <>
              <Divider />
              <Form noValidate>
                <Form.Input
                  label={t("AUDIT_LAST_UPDATED_DATE")}
                  name="auditLastUpdated"
                  id="auditLastUpdated"
                  value={auditLastUpdated()}
                  disabled
                />
                <Form.Input
                  label={t("AUDIT_LAST_UPDATED_BY")}
                  name="auditLastUpdatedBy"
                  id="auditLastUpdatedBy"
                  value={auditLastUpdatedBy()}
                  disabled
                />
              </Form>
            </>
          )}
        </Fragment>
      )}

      {!staffExists && (
        <Message error>
          <Message.Header>{t("STAFF_FORM_ERROR_TITLE")}</Message.Header>
          {t("STAFF_NOT_FOUND_ERROR_MESSAGE")}
        </Message>
      )}
    </Page>
  );
};

export default withTranslation()(StaffCreateAndEditPage);
