import React, { useState } from 'react';

const withPseudoStyleHints = (WrappedComponent) => {
  return (props) => {
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
      setIsHover(true);
    };

    const handleMouseLeave = () => {
      setIsHover(false);
    };

    return (
      <WrappedComponent
        pseudoStyleEvents={{
          onMouseEnter: () => handleMouseEnter(),
          onMouseLeave: () => handleMouseLeave()
        }}
        pseudoStyleHints={{
          hover: isHover
        }}
        {...props}
      />
    );
  };
};

export default withPseudoStyleHints;
