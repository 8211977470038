import React from "react";

const TriggerInfoDisplay = ({t, questionnaire}) => {
  if (typeof (questionnaire.triggerActivityAudit) != "undefined" && questionnaire.triggerActivityAudit != null) {
    const invocation = questionnaire.triggerActivityAudit?.triggerInvocation === 'AUTOMATIC'? t("SUBJECT_TAB_DATA_TABLE_TRIGGER_AUTO","Auto"):t("SUBJECT_TAB_DATA_TABLE_TRIGGER_MANUAL","Man")
    return (
        <div className='tooltip'> {questionnaire.triggerActivityAudit?.triggerName} ({invocation})
          <span className='tooltiptext'>
              {t("SUBJECT_TAB_DATA_TABLE_TRIGGER_OCCURRENCE", "Occurrence: ") + questionnaire.triggerActivityAudit?.occurrenceCount}
            </span>
        </div>
    )
  } else {
    return (
        <div className='tooltip'>{t("SUBJECT_TAB_DATA_TABLE_TRIGGER_NOTRECORDED", "Not Recorded")}
          <span className='tooltiptext'>
                  {t("SUBJECT_TAB_DATA_TABLE_TRIGGER_NOTRECORDED_TOOLTIP", "Trigger not recorded with this questionnaire")}
            </span>
        </div>
    )
  }
}

export default TriggerInfoDisplay;