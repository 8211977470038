import React, { useContext, useEffect, useState } from "react";
import GroupsContext from "../../context/GroupsContext";
import GroupService from "../../services/GroupService";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import { Loader } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import GroupEditComponent from "../../components/groups/GroupEditComponent";
import InternationalisationService from "../../InternationalisationService";
import StaffService from "../../StaffService";

function GroupsEditPage({ t }) {
  const groups = useContext(GroupsContext);
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState(true);
  const [staff, setStaff] = useState(true);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    const getLanguages = async () => {
      const results = await Promise.all([
        InternationalisationService.getLanguages(),
        StaffService.listStaff(),
      ]);
      await setLanguages(results[0]);
      await setStaff(results[1]);
      setLoading(false);
    };
    getLanguages();
  }, []);

  const editingGroup = groups.find((group) => group.id === parseInt(params.id));

  const handleSubmit = async (newGroupData, feedback) => {
    await GroupService.editGroup(
      {
        ...newGroupData,
        labelLanguage: InternationalisationService.getLanguage(),
      },
      feedback
    ).then(()=>{

      // While normally a refresh is unwanted, in this
      // case it will refetch new translations
      window.location.href = "/app/groups";
    }).catch((e)=>{
      console.error("Error saving group", e)
      setError("Could not save group, if the problem persists contact support")
    });;
  };

  return (
    <Page
      name="GROUPS_EDIT"
      header={t("GROUPS_EDIT_HEADER", "Edit Group")}
      subheader={t("GROUPS_EDIT_SUBHEADER", "Edit Group and Alert Information")}
      loading={false}
    >
      {loading && <Loader />}
      {!loading && (
        <GroupEditComponent
          onSubmit={handleSubmit}
          group={editingGroup}
          groups={groups}
          staff={staff}
          languages={languages}
          error={error}
        />
      )}
    </Page>
  );
}

export default withTranslation()(GroupsEditPage);
