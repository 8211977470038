import React, {useMemo, useCallback, useState} from "react";
import {debounce} from 'lodash';
import { useTranslation } from "react-i18next";
import {Button, Checkbox, Dropdown, Form, Grid, Input, Message, Modal, TextArea} from "semantic-ui-react";
import {ACTION_TYPES} from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";
import AccordianReveal from "../../generic/AccordianReveal";
import {QUESTION_DEFINITION_CALCULATED_WHEN, QUESTION_TYPES} from "atom5-branching-questionnaire";
import AceEditor from "react-ace";
import AdminQuestionAnswerComponent from "../AdminQuestionAnswerComponent";

function QuestionEditorRevealedCardComponent({
    questionDefinition,
    isReadOnly = false,
    dispatch,
    translations
}) {
  const { t } = useTranslation();
  const isQuestionType = ([_key, value]) => typeof value === "string";
  const questionTypeOptions = Object.entries(QUESTION_TYPES)
      .filter(isQuestionType)
      .map(([_key, qT]) => {
        return {
          key: qT,
          text: qT,
          value: qT,
        };
      });

  const calculatedWhenOptions = Object.entries(QUESTION_DEFINITION_CALCULATED_WHEN)
    .map(([_key, item]) => {
      return {
        key: item.value,
        text: t(item.translationKey, item.fallbackText),
        value: item.value,
      };
    });

  const hasAnswers = useMemo(()=>{
    return [
      QUESTION_TYPES.FIXED_VALUE,
      QUESTION_TYPES.SINGLE_FIXED_VALUE
    ].includes(questionDefinition.type);
  }, [questionDefinition.type]);
  const hasValidation = useMemo(()=>{
    return [
      QUESTION_TYPES.INTEGER,
      QUESTION_TYPES.DECIMAL,
      QUESTION_TYPES.TEXT,
      QUESTION_TYPES.FIXED_VALUE,
      QUESTION_TYPES.SLIDER,
      QUESTION_TYPES.DATE,
      QUESTION_TYPES.TIME,
      QUESTION_TYPES.DATETIME,
      QUESTION_TYPES.MONTHYEAR
    ].includes(questionDefinition.type);
  }, [questionDefinition.type]);
  const hasStep = useMemo(()=>{
    return [
      QUESTION_TYPES.SLIDER
    ].includes(questionDefinition.type);
  }, [questionDefinition.type]);

  const onChange = (_e, v) => {
    let composedResponse;
    if (Object.keys(v).includes("checked")) {
      composedResponse = {...questionDefinition, [v.name]: v.checked};
    } else {
      composedResponse = {...questionDefinition, [v.name]: v.value};
    }
    dispatch({
      type: ACTION_TYPES.EDIT_QUESTION_DEFINITION,
      payload: composedResponse,
    });
  }

  const [jsonParseError, setJsonParseError] = useState(false)
  const [tempValue, setTempValue] = useState(JSON.stringify(questionDefinition.conditions, null, 2));
  const onConditionsChange = async (v) => {
    setTempValue(v);
    try {
      const value = JSON.parse(v);
      console.log(Date.now())
      updateQuestionnaireObject(value)
    } catch (e) {
      setJsonParseError(true)
    }
  };
  const updateQuestionnaireObject = useCallback(debounce((value) => {
    try {
      const composedResponse = {...questionDefinition, "conditions": value};
      setJsonParseError(false)
      dispatch({
        type: ACTION_TYPES.EDIT_QUESTION_DEFINITION,
        payload: composedResponse,
      });
    } catch (e) {
      setJsonParseError(true)
    }
  }, 500), [questionDefinition, dispatch]);

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () =>{
    setIsModalOpen(!isModalOpen)
  }

  const onChangeAnswer = (answerArray) => {
    dispatch({
      type: ACTION_TYPES.EDIT_QUESTION_DEFINITION,
      payload: {...questionDefinition, answers:answerArray},
    });
  }

  return (
      <Form>
        <Form.Field>
          <label>{t("QUESTIONNAIRE_DEFINITION_TYPE", "Type")}</label>
          <Dropdown
              fluid
              selection
              options={questionTypeOptions}
              value={questionDefinition.type}
              name={"type"}
              onChange={onChange}
              disabled={isReadOnly}
          />
        </Form.Field>
        <Grid>
          <Grid.Column width={5}>
            <Form.Field>
              <label>
                {t("QUESTION_DEFINITION_HIDDEN", "is Hidden?")}
              </label>
              <Checkbox
                  checked={questionDefinition.hidden}
                  name={"hidden"}
                  onChange={onChange}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Field>
              <label>
                {t("QUESTION_DEFINITION_REQUIRED", "is Required?")}
              </label>
              <Checkbox
                  checked={questionDefinition.required}
                  name={"required"}
                  onChange={onChange}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={5}>
            <Form.Field>
              <label>
                {t("QUESTION_DEFINITION_HIDDEN", "is Calculated?")}
              </label>
              <Checkbox
                  checked={questionDefinition.calculated}
                  name={"calculated"}
                  onChange={onChange}
              />
            </Form.Field>
          </Grid.Column>
        </Grid>
        {questionDefinition.calculated && (
          <>
            <Form.Field>
              <label>{t("QUESTION_DEFINITION_CALCULATEDWHEN", "Calculated when?")}</label>
              <Dropdown
                fluid
                selection
                options={calculatedWhenOptions}
                value={questionDefinition.calculatedWhen}
                name={"calculatedWhen"}
                onChange={onChange}
                disabled={isReadOnly}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("QUESTION_DEFINITION_VALUE", "Calculated Value")}</label>
              <TextArea
                value={questionDefinition.value}
                name={"value"}
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
              />
            </Form.Field>
          </>
        )}
        {hasAnswers && <AccordianReveal displayText={t("QUESTION_DEFINITION_ANSWERS", "Answers")} noPadding>
          <Button primary onClick={toggleModal}>{t("QUESTION_DEFINITION_ANSWERS_MODAL","Open Answer Editor")}</Button>
        </AccordianReveal>}
        {hasValidation && <AccordianReveal displayText={t("QUESTION_DEFINITION_ANSWERS", "Min, Max, Step")} noPadding>
          <Grid>
            <Grid.Column width={8}>
              <Form.Field>
                <label>{t("QUESTION_DEFINITION_MIN", "Min")}</label>
                <Input
                    value={questionDefinition.min}
                    name={"min"}
                    fluid
                    transparent={isReadOnly}
                    disabled={isReadOnly}
                    onChange={onChange}
                />
              </Form.Field></Grid.Column>
            <Grid.Column width={8}><Form.Field>
              <label>{t("QUESTION_DEFINITION_MAX", "Max")}</label>
              <Input
                  value={questionDefinition.max}
                  name={"max"}
                  fluid
                  transparent={isReadOnly}
                  disabled={isReadOnly}
                  onChange={onChange}
              />
            </Form.Field>
            </Grid.Column>
            {hasStep && <Form.Field>
              <label>{t("QUESTION_DEFINITION_STEP", "Step")}</label>
              <Input
                  value={questionDefinition.step}
                  name={"step"}
                  fluid
                  transparent={isReadOnly}
                  disabled={isReadOnly}
                  onChange={onChange}
              />
            </Form.Field>}
          </Grid>
        </AccordianReveal>}
        <AccordianReveal displayText={t("QUESTION_DEFINITION_NAVIGATION", "Navigation")} noPadding>
          <AceEditor
              readOnly={isReadOnly}
              mode="json"
              height="100%"
              theme="github"
              onChange={(v) => !isReadOnly && onConditionsChange(v)}
              name="conditionsEditor"
              editorProps={{ $blockScrolling: true }}
              value={tempValue}
              style={{ minHeight: "200px" }}
          />
          {jsonParseError && <Message error>{t("QUESTION_DEFINITION_PARSE_ERROR", "Current Json is not correct")}</Message> }
        </AccordianReveal>
        <AccordianReveal displayText={"Export"} noPadding>
          <Form.Field>
            <label>{t("QUESTION_DEFINITION_EXPORT_NAME", "Export Name Override")}</label>
            <Input
                value={questionDefinition.questionnaireExportHeadingOverride}
                name={"questionnaireExportHeadingOverride"}
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
            />
          </Form.Field>
        </AccordianReveal>
        <AccordianReveal displayText={"Scoring"} noPadding>
          <Form.Field>
            <label>
              {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Score Offset")}
            </label>
            <Input
                value={questionDefinition?.scoreOffset}
                name={"scoreOffset"}
                type="number"
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
            />
          </Form.Field>
          <Form.Field>
            <label>
              {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Score Multiplier")}
            </label>
            <Input
                value={questionDefinition?.scoreMultiplier}
                name={"scoreMultiplier"}
                type="number"
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
            />
          </Form.Field>
        </AccordianReveal>
        <Modal open={isModalOpen} onClose={toggleModal}>
          <div style={{padding: "1rem"}}>
            <AdminQuestionAnswerComponent
                questionDefinition={questionDefinition}
                serverTranslations={translations}
                onChange={onChangeAnswer} />
          </div>
        </Modal>
      </Form>
  );
}

export default QuestionEditorRevealedCardComponent;