import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, Message, Modal } from "semantic-ui-react";

const ERROR_CODES = {
  ANSWER_INCLUDES_SPACE: "The answer code includes a space.",
  ANSWER_INCLUDES_UNDERSCORE: "The answer code includes an underscore.",
  ANSWER_NOT_UNIQUE: "The answer code is not unique.",
};

function AdminNewQuestionAnswerModal({
  t,
  isOpen,
  setIsOpen,
  onConfirm,
  answers,
}) {
  const [code, setCode] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);

  const handleSubmit = () => {
    const errors = isValid(code);
    if (errors.length < 1) {
      onConfirm(code);
      setCode("");
      setIsOpen(false);
    }
    setValidationErrors(errors);
  };

  const isValid = (code) => {
    const errors = [];
    if (code.includes(" ")) {
      errors.push("ANSWER_INCLUDES_SPACE");
    }

    if (code.includes("_")) {
      errors.push("ANSWER_INCLUDES_UNDERSCORE");
    }

    if (!answers.every((a) => a.code !== code)) {
      errors.push("ANSWER_NOT_UNIQUE");
    }

    return errors;
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Content scrolling>
        <Modal.Header>
          <h3>{t("ADMIN_QUESTION_ANSWER_NEW_MODAL", "Create New Answer")}</h3>
        </Modal.Header>
        <Modal.Content>
          <p>
            {t(
              "ADMIN_QUESTION_ANSWER_NEW_CODE",
              "Please note that the cannot be changed after creation"
            )}
          </p>
          {validationErrors.map((vE) => (
            <Message warning>
              {t("ANSWER_CODE_ERROR_" + vE, ERROR_CODES[vE])}
            </Message>
          ))}
          <Input
            value={code}
            name={"code"}
            fluid
            onChange={(_e, data) => setCode(data.value)}
          />
        </Modal.Content>
        <Modal.Actions style={{ paddingTop: "1rem" }}>
          <Button onClick={() => setIsOpen(false)}>
            {t("ADMIN_QUESTION_ANSWER_NEW_CODE_CANCEL", "Cancel")}
          </Button>
          <Button primary onClick={handleSubmit}>
            {t("ADMIN_QUESTION_ANSWER_NEW_CODE_SUBMIT", "Submit")}
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
}

export default withTranslation()(AdminNewQuestionAnswerModal);
