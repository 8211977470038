import React, {useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
import {Modal, Grid, Button} from "semantic-ui-react";
import DateTimeField from "../../components/DateTimeField";
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";
import moment from "moment";

function QuestionnaireActivationDateUpdateModal(
  { t, isOpen, handleModalClosure, questionnaire }) {

  const [from, setFrom] = useState(questionnaire?.activationFromDate);
  const [to, setTo] = useState(questionnaire?.activationToDate);
  const [closing, setClosing] = useState(questionnaire?.closingDate);

  useEffect(()=>{
    setFrom(questionnaire?.activationFromDate);
    setTo(questionnaire?.activationToDate);
    let closingDate = questionnaire?.closingDate
        ? questionnaire?.closingDate
        : questionnaire?.activationToDate;
    setClosing(closingDate);
  }, [questionnaire]);

  const submitDates = async () => {
    const data = {activationFrom: from, activationTo: to, closing: closing};
    await SubjectQuestionnaireService.updateActivationDates(data, questionnaire?.subjectId, questionnaire?.id);
    handleModalClosure && handleModalClosure(true);
  };

  return (
    <Modal open={isOpen}
      closeIcon
      size="large"
      onClose={() => handleModalClosure && handleModalClosure(false)}>
      <Modal.Content key={questionnaire?.id} >
        <Modal.Description>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <DateTimeField
                name="from"
                label={t("SUBJECT_TAB_DATA_TABLE_MODIFY_ACTIVATION_WINDOW_FROM_DATE",
                   "Activation from date")}
                onChange={d => setFrom(d.toISOString())}
                value={moment(from)}/>
            </Grid.Column>
            <Grid.Column width={5}>
              <DateTimeField
                name="to"
                label={t("SUBJECT_TAB_DATA_TABLE_MODIFY_ACTIVATION_WINDOW_TO_DATE", "Activation to date")}
                onChange={d => setTo(d.toISOString())}
                value={moment(to)}/>
            </Grid.Column>
            <Grid.Column width={5}>
              <DateTimeField
                label={t("SUBJECT_TAB_DATA_TABLE_MODIFY_ACTIVATION_WINDOW_CLOSING_DATE", "Closing date")}
                onChange={d => setClosing(d.toISOString())}
                value={moment(closing)}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
                <Button primary floated="right"
                  disabled={from === null && to === null && closing === null}
                  onClick={submitDates}>
                  {t("SUBJECT_TAB_DATA_TABLE_UPDATE_ACTIVATION_WINDOW", "Update")}
                </Button>
                <Button floated="right" onClick={() => handleModalClosure(false)}>
                  {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                </Button>
              </Grid.Column>
          </Grid.Row>
        </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default withTranslation()(QuestionnaireActivationDateUpdateModal);

