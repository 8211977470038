import React, { Component } from "react";
import { Header, Loader } from "semantic-ui-react";
import PageManager from "../../pages/PageManager";
import { withTranslation } from "react-i18next";

class Page extends Component {
  constructor(props, context) {
    super(props, context);
    PageManager.setCurrentPage(props.name);
  }

  render() {
    const { header, subheader, loading, maxWidth, rightColumn } = this.props;
    let colWidth = rightColumn ? 50 : 100;
    return (
      <>
        <div style={{ padding: "2rem" }}>
          {header && typeof header !== "function" && header.length && (
            <Header
              as={"h3"}
              content={header}
              subheader={subheader}
              style={{ paddingBottom: "2rem" }}
            />
          )}
          {header && typeof header === "function" && header()}
          <Loader active={loading} />
          {!loading && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: `${colWidth}%`, maxWidth: maxWidth }}>
                {this.props.children}
              </div>
              <div style={{ width: `${100 - colWidth}%`, margin: `${colWidth === 100 ? 0 : 2}rem` }}>
                {this.props.rightColumn}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(Page);
