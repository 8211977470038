import React, { useContext } from "react";
import UserContext from "../context/UserContext";
import { Redirect, Route, useLocation } from "react-router-dom";

function DashboardForceChangePasswordRoute({ children, ...rest }) {
  const user = useContext(UserContext);
  const location = useLocation();
  const isExclusion =
    /app\/utils|app\/profile|app\/subject\/study-consent/.test(
      location.pathname
    );
  const isSubject = user.accountType === "subject";
  const shouldForceChange =
    isSubject &&
    !isExclusion &&
    (user?.profile?.forcePasswordChangeAtLogin || false);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !shouldForceChange ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/app/profile",
              search: "?changePassword",
              state: { from: location },
            }}
            changePassword={true}
          />
        )
      }
    />
  );
}

export default DashboardForceChangePasswordRoute;
