import { QUESTION_TYPES } from 'atom5-branching-questionnaire';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Confirm, Form, Modal } from 'semantic-ui-react';
import QUESTION_RENDERERS from '../../questionnaires/question-renderers/QuestionnaireRenderers';
import DataQueryService from '../../services/DataQueryService';
import DataQueryModalStyles from './DataQueryModalStyles';

const CloseDataQueryModal = ({t, open, onClose, query, question, renderedQuestion, subjectId}) => {
  const [comment, setComment] = useState("");

  const [acceptConfirmOpen, setAcceptConfirmOpen] = useState(false);
  const [rejectConfirmOpen, setRejectConfirmOpen] = useState(false);

  const hasNewValue = query.newValue != null;
  
  let newValueQuestion = null;
  if (hasNewValue) {
    let newValue = query.newValue;
    if (question.type === QUESTION_TYPES.FIXED_VALUE) {
      newValue = newValue.substring(1, query.newValue.length - 1).split(",");
    }
    const renderer = QUESTION_RENDERERS[question.type];
    newValueQuestion = React.createElement(renderer, {
      question: question,
      value: newValue,
      isReadOnly: true,
    });
  }

  const openConfirm = (acceptNewValue) => {
    if (comment.length === 0) {
      return;
    }
    if (acceptNewValue) {
      setAcceptConfirmOpen(true);
    } else {
      setRejectConfirmOpen(true);
    }
  };

  const onSubmit = async (acceptNewValue) => {
    await DataQueryService.closeQuery(query.id, subjectId, comment, acceptNewValue);
    setComment("");
    onClose();
  };
  
  const valueInfo = hasNewValue ? (
    <>
      <p>{t("DATA_QUERY_CLOSE_OLD_VALUE")}</p>
      <div style={DataQueryModalStyles.important}>
        {renderedQuestion}
      </div>

      <p>{t("DATA_QUERY_CLOSE_NEW_VALUE")}</p>
      <div style={DataQueryModalStyles.important}>
        {newValueQuestion}
      </div>
    </>
  ) : (
    <>
      <p>{t("DATA_QUERY_CLOSE_CURRENT_VALUE")}</p>
      <div style={DataQueryModalStyles.important}>
        {renderedQuestion}
      </div>

      <div style={DataQueryModalStyles.important}>
        <p>{t("DATA_QUERY_CLOSE_VALUE_CORRECT")}</p>
      </div>
    </>
  );

  const confirmProps = {
    cancelButton: t("GLOBAL_BUTTON_CANCEL"),
    confirmButton: t("GLOBAL_BUTTON_CONFIRM"),
    size: "tiny",
  };
  
  return (
    <Modal
      size={"large"}
      open={open}
      onClose={onClose}
      style={{padding: 15}}
    >
      <Form>
        <h2>{t("DATA_QUERY_CLOSE_HEADER")}</h2>
        
        {valueInfo}

        <p>{t("DATA_QUERY_CLOSE_ANSWERER_COMMENT").replace('{}', query.answeredBy)}</p>
        <p style={DataQueryModalStyles.important}>{query.answererComment}</p>

        <Form.Input
          label={t("DATA_QUERY_CLOSE_COMMENT")}
          type="text"
          name="comment"
          id="comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          required={true}
        />

        <Button primary onClick={() => openConfirm(true)}>{t("DATA_QUERY_CLOSE_SUBMIT_ACCEPTED")}</Button>
        <Button color="red" onClick={() => openConfirm(false)}>{t("DATA_QUERY_CLOSE_SUBMIT_REJECTED")}</Button>

        <Confirm {...confirmProps} open={acceptConfirmOpen} content={t("DATA_QUERY_CLOSE_CONFIRM_ACCEPT_CONTENT")} onCancel={() => setAcceptConfirmOpen(false)} onConfirm={() => onSubmit(true)} />
        <Confirm {...confirmProps} open={rejectConfirmOpen} content={t("DATA_QUERY_CLOSE_CONFIRM_REJECT_CONTENT")} onCancel={() => setRejectConfirmOpen(false)} onConfirm={() => onSubmit(false)} />
      </Form>
    </Modal>
  );
};

export default withTranslation()(CloseDataQueryModal);