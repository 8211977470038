import React, { useContext, useEffect, useState } from "react";
import { Button, Icon, Message, Progress, Segment } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AttachmentService, {
  MEDIA_STATE,
} from "../../services/AttachmentService";
import { QUESTION_TYPES, typeHelper } from "atom5-branching-questionnaire";
import AttachmentPreview from "../../components/attachments/AttachmentPreview";
import VideoPlayer from "../../components/media/VideoPlayer";
import EnhancedVideoPlayer from "../../components/media/EnhancedVideoPlayer/EnhancedVideoPlayer";
import UserContext from "../../context/UserContext";
import AttachmentDownload from "./AttachmentDownload";
import ConfigContext from "../../context/ConfigContext";

const MEDIA_SIZE = {
  SMALL: {
    width: 500,
  },
  NORMAL: {
    width: 750,
  },
  LARGE: {
    width: 1000,
  },
};

const AttachmentInline = ({ question, reference, subjectId, t, printable, blurredOnly, hasAllowSubjectMediaAudioPermission }) => {
  const forceUseEnhancedPlayer = typeHelper.parseBool(question.config?.useEnhancedPlayer);
  const [useEnhancedPlayer, setUseEnhancedPlayer] = useState(forceUseEnhancedPlayer);
  const user = useContext(UserContext);

  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaState, setMediaState] = useState(null);
  const [mediaSize, setMediaSize] = useState(MEDIA_SIZE.SMALL);
  const [attachment, setAttachment] = useState(null);
  const [blobData, setBlobData] = useState(undefined);
  const config = useContext(ConfigContext);

  const loadContent = async () => {
    const attachmentResponse = await AttachmentService.getAttachment(
      subjectId,
      reference,
      blurredOnly
    );
    setAttachment(attachmentResponse);
    if (attachmentResponse && attachmentResponse.state) {
      const state = AttachmentService.getMediaStateFromString(
        attachmentResponse.state
      );
      setMediaState(state);
    }
  };

  useEffect(() => {
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    if (
      mediaState !== MEDIA_STATE.COMPLETED &&
      mediaState !== MEDIA_STATE.LEGACY
    ) {
      return;
    }
    AttachmentService.getAttachmentInline(
      subjectId,
      reference,
      blurredOnly,
      (progress) => {
        setLoadingPercentage(progress.percentage);
      },
      (mediaDataUrl, blob) => {
        setMediaUrl(mediaDataUrl);
        setBlobData(blob);
        setIsLoaded(true);
      },
      (err) => {
        console.error('Error getAttachmentInline', err)
        setError(err);
        setIsLoaded(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState]);

  const onMediaLoadError = (err) => {
    console.error('onMediaLoadError', err)

    setError(err);
  };

  const getEnhancedVideoPlayer = () => {
    const frameSkipSizes = config?.ui?.components?.videoPlayer?.skip?.availableStepSizes;
    const defaultSkipSize = config?.ui?.components?.videoPlayer?.skip?.defaultStepSize;
    const enhancedVideoPlayerProps = {
      videoData: {
        url: mediaUrl,
      },
      options: {
        allowMuteChange: hasAllowSubjectMediaAudioPermission
      },
      timeSkipOptions: {
        sizes: frameSkipSizes,
        defaultSkipSize: defaultSkipSize ? parseInt(defaultSkipSize):null
      },
      onError: onMediaLoadError
    };
    return <EnhancedVideoPlayer {...enhancedVideoPlayerProps} />;
  }

  const getPlayerToggle = () => {
    if (user?.profile?.superAdmin !== true) {
      return null;
    }
    return (
      <div style={{ textAlign: 'left' }}>
        <Button
          onClick={() => setUseEnhancedPlayer(!useEnhancedPlayer)}
          size='tiny'
          color='grey'
          content={useEnhancedPlayer ? 'Use Standard Player' : 'Use Experimental Player'}
        />
      </div>
    );
  };

  const getPlayer = () => {
    if (useEnhancedPlayer) {
      return getEnhancedVideoPlayer();
    }

    return (<VideoPlayer
      width={mediaSize.width + "px"}
      height="100%"
      mediaUrl={mediaUrl}
      showFrameByFrameControls
      controls
      onError={onMediaLoadError}
      hasAllowSubjectMediaAudioPermission={hasAllowSubjectMediaAudioPermission}
    />);

  }

  return (
    <>
      {!isLoaded &&
        !error &&
        (mediaState === MEDIA_STATE.COMPLETED ||
          mediaState === MEDIA_STATE.LEGACY) && (
          <Progress percent={loadingPercentage} indicating />
        )}
      {error &&
        (mediaState === MEDIA_STATE.COMPLETED ||
          mediaState === MEDIA_STATE.LEGACY) && (
          // Error
          <Message negative>{t("ERROR_LOADING_VIDEO")}</Message>
        )}
      {mediaState !== null &&
        mediaState !== MEDIA_STATE.COMPLETED &&
        mediaState !== MEDIA_STATE.LEGACY && (
          // Have state, Not Completed, Not Legacy - show info
          <>
            <Message
              icon={mediaState?.displayProperties?.icon}
              info={mediaState?.displayProperties?.isInfo}
              content={t(mediaState?.translationKey, mediaState?.fallbackText)}
              style={{ width: "600px" }}
            />
          </>
        )}
      {(mediaState === MEDIA_STATE.COMPLETED ||
        mediaState === MEDIA_STATE.LEGACY) && (
          // Completed or Legacy - Display It
          <>
            {!useEnhancedPlayer && question.type !== QUESTION_TYPES.FILE && !printable && (
              <Segment compact>
                <Icon
                  name={"desktop"}
                  size={"small"}
                  style={{ marginRight: "10px" }}
                  className={"mouse-pointer"}
                  onClick={() => {
                    setMediaSize(MEDIA_SIZE.SMALL);
                  }}
                />
                <Icon
                  name={"desktop"}
                  size={"large"}
                  style={{ marginRight: "10px" }}
                  className={"mouse-pointer"}
                  onClick={() => {
                    setMediaSize(MEDIA_SIZE.NORMAL);
                  }}
                />
                <Icon
                  name={"desktop"}
                  size={"big"}
                  className={"mouse-pointer"}
                  onClick={() => {
                    setMediaSize(MEDIA_SIZE.LARGE);
                  }}
                />
              </Segment>
            )}
            {(question.type === QUESTION_TYPES.IMAGE ||
              question.type === QUESTION_TYPES.SIGNATURE) && (
                <>
                  {isLoaded && !error && (
                    <>
                      <img
                        width={mediaSize.width}
                        src={mediaUrl}
                        alt={reference}
                        data-value={reference}
                      />
                    </>
                  )}
                </>
              )}
            {question.type === QUESTION_TYPES.VIDEO && (
              <>
                {(isLoaded && !forceUseEnhancedPlayer) && getPlayerToggle()}
                {isLoaded && getPlayer()}
              </>
            )}
            {question.type === QUESTION_TYPES.FILE && (
              <>
                {isLoaded && !error && (
                  <AttachmentPreview
                    question={question}
                    attachment={attachment}
                    url={mediaUrl}
                    blob={blobData}
                  />
                )}
              </>
            )}

            <AttachmentDownload
              subjectId={subjectId}
              reference={reference}
              blurredOnly={blurredOnly}
              mediaState={mediaState}
            />
          </>
        )}
    </>
  );
};

export default withTranslation()(AttachmentInline);
