/* eslint no-unused-vars: 0 */
import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class DrugForm extends Component {
  state = {
    name: "",

    canSubmit: false,
    submitting: false,
  };

  constructor(props, context) {
    super(props, context);

    if (props.drug) {
      this.state.name = props.drug.name;
      this.state.originalName = props.drug.name;
    }

    this.onSubmitCallback = props.onSubmit;
  }

  nameChanged = (event) => {
    this.setState({
      name: event.target.value,
      canSubmit: event.target.value.trim().length > 0,
    });
  };

  handleSubmit = (event, headers) => {
    event.preventDefault();

    const drug = {
      name: this.state.name.trim(),
    };

    const promise = this.onSubmitCallback(drug, headers);

    // If we have a promise, lets use that to determine when to show indication of submission
    if (promise) {
      this.setState({ submitting: true });

      promise.finally(() => {
        this.setState({ submitting: false });
      });
    }
  };

  isModified = () => {
    return this.state.name !== this.state.originalName;
  };

  render() {
    const { t, error } = this.props;

    return (
      <Form onSubmit={(e) => this.handleSubmit(e, {})} error={error}>
        <Message error header={t("DRUG_FORM_ERROR_TITLE")} content={error} />

        <Grid columns={"equal"}>
          <Grid.Column>
            <Segment>
              <Header>{t("DRUG_FORM_DETAILS_HEADER")}</Header>
              <Form.Field>
                <label>{t("DRUG_FORM_NAME_LABEL")}</label>
                <input
                  autoFocus
                  name={"name"}
                  required
                  value={this.state.name}
                  onChange={this.nameChanged}
                />
              </Form.Field>
            </Segment>
          </Grid.Column>
          <Grid.Row>
            <Grid.Column>
              <Button type="submit" loading={this.state.submitting} primary={this.state.canSubmit} disabled={!this.state.canSubmit}>
                {t("GLOBAL_BUTTON_SAVE")}
              </Button>
              {this.props.onCancel && (
                <Button type="button" onClick={this.props.onCancel} color="red">
                  {t("GLOBAL_BUTTON_CANCEL")}
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default withTranslation()(DrugForm);
