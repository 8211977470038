import React, {useState} from 'react';
import {Button, Modal, Table} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import LookupItemEditCard from "./LookupItemEditCard";

const LookupItemDisplay = ({lookup, lookupItemArray, language, refreshLookups}) => {
  const {t} = useTranslation()

  const [selectedItem, setSelectedItem] = useState(null);

  if(!lookupItemArray || !Array.isArray(lookupItemArray)) return null;

  return <div style={{paddingTop: '20px'}}>
    <Table>
      <Table.Header>
        <Table.HeaderCell>{"ref"}</Table.HeaderCell>
        <Table.HeaderCell>{"value"}</Table.HeaderCell>
        <Table.HeaderCell>{"data"}</Table.HeaderCell>
        <Table.HeaderCell>{"source"}</Table.HeaderCell>
        <Table.HeaderCell>{"priority"}</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Header>
      {
        lookupItemArray.map(lookupItem=>
          <Table.Row key={lookupItem.ref}>
            <Table.Cell>{lookupItem.ref}</Table.Cell>
            <Table.Cell>{lookupItem.value}</Table.Cell>
            <Table.Cell>{lookupItem.data}</Table.Cell>
            <Table.Cell>{lookupItem.source}</Table.Cell>
            <Table.Cell>{lookupItem.priority}</Table.Cell>
            <Table.Cell>
              <Button primary onClick={()=>setSelectedItem(lookupItem)}>{t("LOOKUP_ITEM_EDIT", "Edit")}</Button>
            </Table.Cell>
          </Table.Row>
        )
      }
    </Table>
    <Modal open={selectedItem !== null} onClose={()=>setSelectedItem(null)}>
      <LookupItemEditCard
          language={language}
          lookup={lookup}
          lookupItem={selectedItem}
          onSubmit={() => {
            setSelectedItem(null)
            refreshLookups && refreshLookups()
          }}
      />
    </Modal>
  </div>
}

export default LookupItemDisplay;