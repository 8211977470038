const DATA_QUERY_TABLE_COLUMNS = {
  STATUS: "STATUS",
  RAISED_BY: "RAISED_BY",
  RAISED_AT: "RAISED_AT",
  RAISER_COMMENT: "RAISER_COMMENT",
  ANSWERED_BY: "ANSWERED_BY",
  ANSWERED_AT: "ANSWERED_AT",
  ANSWERER_COMMENT: "ANSWERER_COMMENT",
  CLOSED_BY: "CLOSED_BY",
  CLOSED_AT: "CLOSED_AT",
  CLOSER_COMMENT: "CLOSER_COMMENT",
  SUBJECT_CODE: "SUBJECT_CODE",
  SUBJECT_ID: "SUBJECT_ID",
  QUESTIONNAIRE_CODE: "QUESTIONNAIRE_CODE",
  QUESTION_CODE: "QUESTION_CODE",

  isDateTimeColumn: (column) => {
    const DATE_TIME_COLUMNS = [
      DATA_QUERY_TABLE_COLUMNS.RAISED_AT,
      DATA_QUERY_TABLE_COLUMNS.ANSWERED_AT,
      DATA_QUERY_TABLE_COLUMNS.CLOSED_AT,
    ];
    return DATE_TIME_COLUMNS.indexOf(column) >= 0;
  },

  asPropertyString: (column) => {
    return column.split("_").map((c, index) => {
      if (index === 0) {
        return c.toLowerCase();
      }
      return c.slice(0, 1) + c.slice(1).toLowerCase();
    }).join("");
  }
};

export default DATA_QUERY_TABLE_COLUMNS;