import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import ConfigContext from '../context/ConfigContext';
import InternationalisationService from '../InternationalisationService';
import Package from "../../package.json";

const AboutModalContent = ({t}) => {

  const config = useContext(ConfigContext);
  const aboutConfig = config.about;

  return (
    <Modal.Content scrolling>
      <h2>{t('ABOUT_MANUFACTURER')}</h2>
      <p>
        {aboutConfig.name}
        <br />
        {aboutConfig.address.line1}
        <br />
        {aboutConfig.address.line2}
        <br />
        {aboutConfig.address.line3}
        <br />
        {aboutConfig.address.townCity}
        <br />
        {aboutConfig.address.postcode}
        <br />
        {aboutConfig.address.country}
      </p>

      <h2>{t('ABOUT_MANUFACTURER_CONTACT')}</h2>
      <p>
        {t('ABOUT_CALL')}:
        <a href={'tel:' + aboutConfig.support.telNumber} target="_blank" rel="noopener noreferrer">
          {' ' + aboutConfig.support.telLabel}
        </a>
      </p>
      <p>
        {t('ABOUT_EMAIL')}: 
        <a href={'mailto:' + aboutConfig.support.email} target="_blank" rel="noopener noreferrer">
          {' ' + aboutConfig.support.email}
        </a>
      </p>

      <h2>{t('ABOUT_BUILD')}</h2>
      <p>Nucleus: {config.nucleusVersion}</p>
      <p>Clinician-Web: {Package.version}</p>

      {'privacy' in aboutConfig && (
        <>
          <h2>{t('ABOUT_PRIVACY')}</h2>
          <a href={'https://' + aboutConfig.privacy?.url?.[InternationalisationService.getLanguage()] || 'www.aparito.com/privacy-policy/'} target="_blank" rel="noopener noreferrer">
            {t('ABOUT_PRIVACY_GOTO')}
          </a>
        </>
      )}
    </Modal.Content>
  );
};

export default withTranslation()(AboutModalContent);