import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Checkbox,
  Dropdown,
  Form,
  FormField,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";

function AlertMembersEditComponent({
  t,
  index,
  alertEmail,
  alertLanguage,
  languages,
  staff,
  onChange,
  onDelete,
}) {
  const staffMember = staff.find((sM) => sM.email === alertEmail);
  const [isExistingStaff, setIsExistingStaff] = useState(
    staffMember || alertEmail.length === 0 ? true : false
  );

  const languageDropdownOptions = languages.map((language) => {
    return {
      key: language.code,
      text: language.name,
      value: language.code,
    };
  });
  const staffDropdownOptions = staff.map((staff) => {
    return {
      key: staff.email,
      text: staff.firstName + " " + staff.lastName,
      value: staff.email,
    };
  });

  const handleChange = (e, { value, name }) => {
    let returnValue = {
      alertEmail: alertEmail,
      alertLanguage: alertLanguage,
    };
    returnValue[name] = value;
    onChange(returnValue);
  };

  const handleCheckboxChange = () => {
    setIsExistingStaff((oldValue) => !oldValue);
  };

  return (
    <Table.Row>
      <Table.Cell>
        <FormField>
          <Checkbox
            checked={isExistingStaff}
            onChange={handleCheckboxChange}
            label={t("GROUP_EDIT_ALERT_IS_REGISTERED", "Registered?")}
          />
        </FormField>
      </Table.Cell>
      <Table.Cell>
        <Form.Field>
          {isExistingStaff ? (
            <Dropdown
              selection
              name="alertEmail"
              value={alertEmail}
              options={staffDropdownOptions}
              onChange={handleChange}
              error={alertEmail.length === 0}
            />
          ) : (
            <Input
              name="alertEmail"
              value={alertEmail}
              onChange={handleChange}
              error={alertEmail.length === 0}
            />
          )}
        </Form.Field>
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          selection
          name="alertLanguage"
          value={alertLanguage}
          options={languageDropdownOptions}
          onChange={handleChange}
        />
      </Table.Cell>
      <Table.Cell>
        <Icon
          color="red"
          size="large"
          name="times circle"
          onClick={onDelete}
          style={{ cursor: "pointer" }}
        />
      </Table.Cell>
    </Table.Row>
  );
}

export default withTranslation()(AlertMembersEditComponent);
