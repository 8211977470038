import RequestHelper, {CONTENT_TYPE} from "../../RequestHelper";
import InternationalisationService from "../../InternationalisationService";


export default class SensorDataService {

  static getYumenData(hubId, type, maxReturnCount) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        `/external/yumen/data?hubId=${hubId}&type=${type}&maxReturnCount=${maxReturnCount}&apiKey=A8DaFdJfMhQmSpU`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getYumenGraphData(subjectId, type, from, to, maxDelaySeconds) {
    if (to !== null) {
      return RequestHelper.send(
          process.env.REACT_APP_SERVER_ADDRESS +
          "/subjects/" +
          subjectId +
          `/data/yumenWearableGraphDataPeriod?type=${type}&from=${from}&to=${to}&maxDelaySeconds=${maxDelaySeconds}`,
          { "Accept-Language": InternationalisationService.getLanguage() }
      );
    } else {
      return RequestHelper.send(
          process.env.REACT_APP_SERVER_ADDRESS +
          "/subjects/" +
          subjectId +
          `/data/yumenWearableGraphDataDay?type=${type}&from=${from}&maxDelaySeconds=${maxDelaySeconds}`,
          { "Accept-Language": InternationalisationService.getLanguage() }
      );
    }
  }

  static getYumenExportData(hubId, type) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        `/external/yumen/data/export/csv?hubId=${hubId}&limb=${type}&apiKey=A8DaFdJfMhQmSpU`,
        { "Accept-Language": InternationalisationService.getLanguage(), "Accept": "text/plain" }
    );
  }

  static getYumenExportDataCount(subjectId, type) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" + subjectId + `/data/count?type=${type}`
    );
  }

  static getActivity(subjectId, type, from, to, groupBy, movingAverageMinutes, fillMissingStrategy) {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/activity?type=${type}&from=${from ? from : ''}&to=${to ? to : ''}&groupBy=${groupBy ? groupBy : ''}&movingAverageMinutes=${movingAverageMinutes ? movingAverageMinutes : ''}&fillMissingStrategy=${fillMissingStrategy ? fillMissingStrategy : ''}`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getActivityTypes(subjectId, type, from, to, groupBy, movingAverageMinutes, fillMissingStrategy) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/activity_types?type=${type}&from=${from}&to=${to}&groupBy=${groupBy ? groupBy : ''}&movingAverageMinutes=${movingAverageMinutes ? movingAverageMinutes : ''}&fillMissingStrategy=${fillMissingStrategy ? fillMissingStrategy : ''}`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getHeartRateInfo(subjectId, type, from, to, groupBy, movingAverageMinutes, fillMissingStrategy) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/heartrate?type=${type}&from=${from}&to=${to}&groupBy=${groupBy ? groupBy : ''}&movingAverageMinutes=${movingAverageMinutes ? movingAverageMinutes : ''}&fillMissingStrategy=${fillMissingStrategy ? fillMissingStrategy : ''}`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getStepData(subjectId, type, from, to, groupBy, movingAverageMinutes, fillMissingStrategy) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/steps?type=${type}&from=${from}&to=${to}&groupBy=${groupBy ? groupBy : ''}&movingAverageMinutes=${movingAverageMinutes ? movingAverageMinutes : ''}&fillMissingStrategy=${fillMissingStrategy ? fillMissingStrategy : ''}`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getSpo2(subjectId, from, to, groupBy, movingAverageMinutes, fillMissingStrategy) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/spo2?from=${from}&to=${to}&groupBy=${groupBy ? groupBy : ''}&movingAverageMinutes=${movingAverageMinutes ? movingAverageMinutes : ''}&fillMissingStrategy=${fillMissingStrategy ? fillMissingStrategy : ''}`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getRespirationData(subjectId, from, to, groupBy, movingAverageMinutes, fillMissingStrategy) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/respirationData?from=${from}&to=${to}&groupBy=${groupBy ? groupBy : ''}&movingAverageMinutes=${movingAverageMinutes ? movingAverageMinutes : ''}&fillMissingStrategy=${fillMissingStrategy ? fillMissingStrategy : ''}`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }
  static getActivityDistanceData(subjectId, from, to) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/activityDistanceData?from=${from}&to=${to}`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getBbi(subjectId, from, to, groupBy, movingAverageMinutes, fillMissingStrategy) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/bbi?from=${from}&to=${to}&groupBy=${groupBy ? groupBy : ''}&movingAverageMinutes=${movingAverageMinutes ? movingAverageMinutes : ''}&fillMissingStrategy=${fillMissingStrategy ? fillMissingStrategy : ''}`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }


  static getSleep(subjectId) {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        "/data/sleep",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getGarminSleep(subjectId) {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        "/data/garminSleep",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getStats(subjectId, from, to) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/stats?from=${from}&to=${to}`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }
  static getDeviceLogs(subjectId, from, to, query) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/logs?from=${from}&to=${to}&query=${query}&lines=500`,
        { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static downloadDeviceLogs(subjectId, from, to, query) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/subjects/" +
        subjectId +
        `/data/logs/download?from=${from}&to=${to}&query=${query}&lines=20000`,
        {
          Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
          "Keep-Alive": "Keep-Alive: timeout=600, max=1000"
        },
        "GET"
    );
  }

}
