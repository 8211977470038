import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";
import Page from "../../components/page/Page";
import StaffService from "../../StaffService";

function RolesListPage({ t }) {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pstaffRoles = StaffService.getRolesForSuperAdminStaff();
    pstaffRoles.then(staffRoles => {
      setRoles(
        staffRoles.map(role => {
          return {
            key: role.id,
            label: role.label,
            code: role.code,
            override: role.creatableRolesOverride !== null ? role.creatableRolesOverride.toString() : ''
          }
        })
      );
      setLoading(false);
    })
  }, []);

  return (<Page
    name="ROLES_LIST"
    header={t("ROLES_LIST_HEADER", "List Roles")}
    subheader={t("ROLES_LIST_SUBHEADER", "Roles Management")}
    loading={loading}
  >
    <Table columns={4}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {t("ROLES_LIST_ROLES_LABEL", "Label")}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("ROLES_LIST_ROLES_CODE", "Code")}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t("ROLES_LIST_OVERRIDE", "Override Roles")}
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
      {roles.map(role => {
        return (<Table.Row key={role.key}>
          <Table.Cell>{role.label}</Table.Cell>
          <Table.Cell>{role.code}</Table.Cell>
          <Table.Cell style={{ wordBreak: "break-word" }}>{role.override}</Table.Cell>
          <Table.Cell>
            <Button as={Link} to={`/app/roles/edit/${role.key}`} primary>
              {t("ROLES_LIST_EDIT", "Edit")}
            </Button>
          </Table.Cell>
        </Table.Row>);
      })}
      </Table.Body>
    </Table>
    <Button as={Link} primary to={"/app/roles/new"}>
      {t("ROLES_LIST_NEW", "New")}
    </Button>
  </Page>
  );
}

export default withTranslation()(RolesListPage);