import RequestHelper, {CONTENT_TYPE} from "../RequestHelper";

export default {
    async getDashboardReportList() {
        let response = await RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/dashboard-reports",
            {},
            "GET",
        );
        return response;
    },

    async getDashboardReportTabList(code) {
        let response = await RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/dashboard-reports/"+code + "/tabs",
            {},
            "GET",
        );
        return response;
    },

    async getDashboardReport(code, from, to, limit = 100) {
        let response = await RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/dashboard-reports/" + code,
            {},
            "GET",
            {from, to, limit}
        );
        return response;
    },

    async getDashboardReportOfTab(code, from, to, limit = 300, tab) {
        let response = await RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/dashboard-reports/" + code,
            {},
            "GET",
            {from, to, limit, tab}
        );
        return response;
    },

    async getDashboardReportExportByFilters(code, from, to, sites, subjectCodes, freeText) {
        let response = await RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/dashboard-reports/" + code + "/excel-export",
            {Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM},
            "GET",
            {from, to, sites, subjectCodes, freeText}
        );
        return response;
    },
    async getDashboardReportExport(code) {
        let response = await RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/dashboard-reports/" + code + "/excel-export",
            {Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM},
            "GET",
        );
        return response;
    },

}
