import DOMPurify from "dompurify";
import React from "react";

const SubjectDataTableQuestionHeaderLabel = ({question}) => {
  let columnSizeOverride = 100;
  if(question.label.length > 10){
    columnSizeOverride = 200
  }
  if(question.label.length > 50){
    columnSizeOverride = 300
  }
  if(question.label.length > 100){
    columnSizeOverride = 400
  }
  if(question.label.length > 200){
    columnSizeOverride = 500
  }

  if (question?.config?.useMarkupInDataTable) {
    const cleanLabel = DOMPurify.sanitize(question.label);
    return <p dangerouslySetInnerHTML={{ __html: cleanLabel }} />;
  }
  return <p style={{
      minWidth: `${columnSizeOverride}px`,
  }}>{question.label
      .replaceAll(/<.*?>/g, " ") // remove all tags, replace with spaces
      .replaceAll(/ +/g, " ") // replace all instances of multiple spaces with a single space
      .trim()}</p>
};

export default SubjectDataTableQuestionHeaderLabel;