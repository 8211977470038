import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import i18next from 'i18next';
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";

const SubjectQuestionnaireMenu = ({
  selectedDefinitionCode,
  subjectId,
  questionnaireType,
  definitions,
  disabled,
  loading,
}) => {
  const menuItems = definitions
    .map((definition, i) => {
      const isActive =
        selectedDefinitionCode === definition.code ||
        (i === 0 && selectedDefinitionCode === null);
      const questionnaireLabel =
        SubjectQuestionnaireService.getQuestionnaireLabel(definition);
      const isNew = new URLSearchParams(window.location.search).get("new");

      const to =
        `/app/subject/${subjectId}/tabs/${questionnaireType}/${definition.code}` +
        (isNew ? "?new=true" : "");

      return (
        <Link
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            }
          }}
          to={to}
          className={"item " + (isActive ? "active " : "")}
          key={definition.code}
          style={{
            borderWidth: 0,
            color: isActive ? "#f8991d" : null,
            lineHeight: 1.4,
          }}
        >
          {questionnaireLabel}
        </Link>
      );
    })
    .filter((mi) => mi !== null);

  if (loading) {
    return <p>...</p>;
  }
  if (menuItems.length === 0) {
    return (
      <p>
        {i18next.t(
          "SUBJECT_QUESTIONNAIRE_MENU_NO_QUESTIONNAIRES",
          "Nothing Scheduled"
        )}
      </p>
    );
  }

  return (
    <Menu fluid vertical tabular style={{ borderWidth: 0 }}>
      {menuItems}
    </Menu>
  );
};

export default SubjectQuestionnaireMenu;