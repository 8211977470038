import _ from "lodash";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import ConfigService from "./ConfigService";

const CURRENT_THEME_KEY = 'CURRENT_THEME';

const THEMES = [
  {
    key: 'DEFAULT',
    style: {},
  },
  {
    key: 'DULLER_BACKGROUND',
    style: {
      backgroundColor: '#d3d3d3'
    },
  },
  /* Commenting these out as they don't work, but we may want to do these in future
  {
    key: 'DARK_THEME',
    style: {
      backgroundColor: '#444444',
      color: '#dddddd',
    },
  },
  {
    key: 'BLUE_ACCENT',
    style: {
      accentColor: '#1da1f2',
    },
  },
  */
];

const ThemeService = {
  getTheme: () => {
    const localStorageTheme = LocalStorageHelper.getJsonObject(CURRENT_THEME_KEY);
    if (!_.isEmpty(localStorageTheme)) {
      return localStorageTheme;
    }

    const configDefaultThemeKey = ConfigService.getDefaultThemeKey();
    const configDefaultTheme = THEMES.find(theme => theme.key === configDefaultThemeKey);

    if (configDefaultTheme) {
      return configDefaultTheme;
    }

    return THEMES[0];
  },
  setTheme: (theme) => {
    LocalStorageHelper.setJson(CURRENT_THEME_KEY, theme);
  },
  getThemes: () => {
    const configDefaultThemeKey = ConfigService.getDefaultThemeKey();

    return THEMES.sort((theme1, _theme2) => {
      if (theme1.key === configDefaultThemeKey) {
        return -1;
      }
      return 0;
    });
  },
  getStyle: () => {
    return ThemeService.getTheme().style;
  }
};

export default ThemeService;
