import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Icon, Popup, PopupContent } from "semantic-ui-react";

const CronExpressionBuilderValuesPopup = (props) => {
  const { t, possibleValues, onSelect, buttonText, buttonIcon } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  if (!possibleValues) {
    return null;
  }

  const onOpen = () => {
    setIsOpen(true);
  };

  const onSaveClick = (e) => {
    let selectedValue = "";
    for (const value of selectedValues) {
      if (selectedValue.length > 0) {
        selectedValue += ",";
      }
      selectedValue += value;
    }
    onSelect && onSelect(e, selectedValue);
    closePopup();
  };

  const onChange = (e, data) => {
    const values = data?.value;
    setSelectedValues(values);
  };

  const onCancelClick = () => {
    closePopup();
  };

  const closePopup = () => {
    setSelectedValues([]);
    setIsOpen(false);
  };

  const getDropdownOptions = () => {
    return possibleValues.map((possibleValue) => {
      return { key: possibleValue, text: possibleValue, value: possibleValue };
    });
  };

  const popupTrigger = buttonIcon ? (
    <Icon
      name={buttonIcon}
      size={"large"}
      title={buttonText}
      className={"mouse-pointer"}
    />
  ) : (
    <Button>{buttonText}</Button>
  );

  return (
    <Popup
      trigger={popupTrigger}
      on="click"
      pinned
      open={isOpen}
      onOpen={onOpen}
    >
      <PopupContent>
        <Dropdown
          fluid
          multiple
          selection
          options={getDropdownOptions()}
          onChange={onChange}
        />
        <div>
          <Button
            primary
            onClick={(e) => {
              onSaveClick(e);
            }}
          >
            {t("GLOBAL_BUTTON_SAVE", "Save")}
          </Button>
          <Button onClick={onCancelClick}>
            {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
          </Button>
        </div>
      </PopupContent>
    </Popup>
  );
};

export default withTranslation()(CronExpressionBuilderValuesPopup);
