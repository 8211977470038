import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import Page from "../../../components/page/Page";
import { getContentDefinitions } from "../../../redux/questionnaires/questionnaireDefinitionsSlice";
import { getContents } from "../../../redux/questionnaires/subjectQuestionnairesSlice";
import SubjectAppStateService from "../../../services/SubjectAppStateService";
import SubjectQuestionnaireList from "../../../components/lists/SubjectQuestionnaireList";

const ContentListPage = (props) => {
  const { t, contents, contentDefinitions } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    SubjectAppStateService.getSubjectQuestionnairesFromServer(dispatch);
  }, [dispatch]);

  const nonModuleDefinitions = contentDefinitions.filter((definition) => {
    const hasModule = definition.config?.modules && definition.config.modules.length > 0;
    return !hasModule;
  });

  const nonModuleContents = contents.filter((content) => {
    const definition = nonModuleDefinitions.find((def) => def.id === content.definitionId);
    return !!definition;
  });

  return (
    <Page
      name="CONTENT_LIST_PAGE"
      header={t("CONTENT_LIST_PAGE_HEADER")}
      maxWidth="800px"
    >
      <SubjectQuestionnaireList
        questionnaireList={nonModuleContents}
        definitionList={nonModuleDefinitions}
        showEmpty
      />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    contents: getContents(state),
    contentDefinitions: getContentDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(ContentListPage);
