import React, { useState } from "react";
import { Route } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import StaffListPage from "../pages/staff/StaffListPage";
import StaffCreateAndEditPage from "../pages/staff/StaffCreateAndEditPage";
import SubjectCreatePage from "../pages/subjects/SubjectCreatePage";
import SubjectListPage from "../pages/subjects/SubjectListPage";
import DrugListPage from "../pages/drugs/DrugListPage";
import DrugCreatePage from "../pages/drugs/DrugCreatePage";
import DrugEditPage from "../pages/drugs/DrugEditPage";
import GroupsListPage from "../pages/groups/GroupsListPage";
import GroupsNewPage from "../pages/groups/GroupsNewPage";
import GroupsEditPage from "../pages/groups/GroupsEditPage";
import Profile from "../pages/profile/Profile";
import AdminUtilsPage from "../pages/utils/AdminUtilsPage";
import AdminUtilsDiagnosticsPage from "../pages/utils/AdminUtilsDiagnosticsPage";
import ExportListPage from "../pages/export/ExportListPage";
import ProListPage from "../pages/subjectaccount/questionnaireLists/ProListPage";
import ContentListPage from "../pages/subjectaccount/questionnaireLists/ContentListPage";
import EventListPage from "../pages/subjectaccount/questionnaireLists/EventListPage";
import SubjectStudyConsentPage from "../pages/account-activation/SubjectStudyConsentPage";
import DataListPage from "../pages/subjectaccount/questionnaireLists/DataListPage";
import QuestionnairePage from "../pages/subjectaccount/QuestionnairePage";
import SettingsPage from "../pages/settings/SettingsPage";
import DashboardForceChangePasswordRoute from "./DashboardForceChangePasswordRoute";
import ModuleListPage from "../pages/subjectaccount/questionnaireLists/ModuleListPage";
import QuestionnaireContext from "../context/QuestionnaireContext";
import ManageRewardsPage from "../pages/rewards/ManageRewardsPage";
import AdminTranslationsPage from "../pages/utils/AdminTranslationsPage";
import DataQueryListPage from "../pages/dataqueries/DataQueryListPage";
import SubjectPage from "../pages/subjects/SubjectPage";
import AdminTriggerListPage from "../pages/utils/AdminTriggerListPage";
import AdminLookupTool from "../pages/utils/AdminLookupTool";
import AdminAttachmentMonitoringPage from "../pages/utils/AdminAttachmentMonitoringPage";
import AdminAttachmentRandomisationPage from "../pages/utils/AdminAttachmentRandomisationPage";
import AdminCacheManagementPage from "../pages/utils/AdminCacheManagementPage";
import GroupsUpdateMetadataPage from "../pages/groups/GroupsUpdateMetadataPage";
import AdminTriggerCreateEditPage from "../pages/utils/AdminTriggerCreateEditPage";
import RolesListPage from "../pages/roles/RolesListPage";
import RolesNewPage from "../pages/roles/RolesNewPage";
import RolesEditPage from "../pages/roles/RolesEditPage";
import AdminQuestionnaireDefinitionListPage from "../pages/utils/AdminQuestionnaireDefinitionListPage";
import AdminQuestionnaireDefinitionCreatePage from "../pages/utils/AdminQuestionnaireDefinitionCreatePage";
import AdminQuestionnaireDefinitionCreateEditPage from "../pages/utils/AdminQuestionnaireDefinitionCreateEditPage";
import AdminQuestionDefinitionCreateEditPage from "../pages/utils/AdminQuestionDefinitionCreateEditPage";
import AdminConfigToolsListPage from "../pages/utils/AdminConfigToolsListPage";
import AdminConfigToolsCreateOrEditPage from "../pages/utils/AdminConfigToolsCreateOrEditPage";
import AttachmentSearch from "../pages/attachments/AttachmentSearch";
import BatchUploadIndex from "../pages/attachments/BatchUpload/BatchUploadIndex";
import AdminNotificationListPage from "../pages/utils/AdminNotificationListPage";
import NotificationListPage from "../pages/notifications/NotificationListPage";
import DashboardReportPage from "../pages/reports/DashboardReportPage";
import AdminTechSupportPage from "../pages/utils/AdminTechSupportPage";
import AuditSearch from "../pages/audit/AuditSearch";
import ScreenshotsListPage from "../pages/screenshots/ScreenshotsListPage";
import AdminColumnOverridesPage from "../pages/utils/AdminColumnOverridesPage";
import AdminAttachmentBlurringPage from "../pages/utils/AdminAttachmentBlurringPage";
import RolesPermissionsPage from "../pages/staff/RolesPermissionsPage";
import AdminDigestAlertListPage from "../pages/utils/AdminDigestAlertListPage";
import AdminQuestionnaireDefinitionFullEditPage from "../pages/utils/AdminQuestionnaireDefinitionFullEditPage";
import AdminRemoteJobProcessingQueueListPage from "../pages/utils/AdminRemoteJobProcessingQueueListPage";
import BatchUploadFileSelector from "../pages/attachments/BatchUpload/BatchUploadFileSelector";
import BatchUploadProcessData from "../pages/attachments/BatchUpload/BatchUploadProcessData";
import AdminQuestionnaireWorkflowPage from "../pages/utils/AdminQuestionnaireWorkflowPage";
import AdminRecalculateAnswersPage from "../pages/utils/answers/AdminRecalculateAnswersPage";

const DashboardRoutes = (props) => {
  const [questionnaireReturnTo, setQuestionnaireReturnTo] = useState(null);

  return (
    <>
      <DashboardForceChangePasswordRoute>
        <Route path={"/app/home"} component={HomePage} />
        <Route path={"/app/profile"} component={Profile} />
        <Route path={"/app/dashboard-report/:code"} component={DashboardReportPage} />
        <Route exact path={"/app/rewards"} component={ManageRewardsPage} />
        <Route exact path={"/app/staff"} component={StaffListPage} />
        <Route
          exact
          path={"/app/staff/new"}
          component={StaffCreateAndEditPage}
        />
        <Route
          exact
          path={"/app/staff/edit/:id"}
          component={StaffCreateAndEditPage}
        />
        <Route
          exact
          path={"/app/subject/study-consent"}
          component={SubjectStudyConsentPage}
        />
        <QuestionnaireContext.Provider
          value={{
            returnTo: questionnaireReturnTo,
            setReturnTo: setQuestionnaireReturnTo,
          }}
        >
          <Route exact path={"/app/subject/pro"} component={ProListPage} />
          <Route
            exact
            path={"/app/subject/content"}
            component={ContentListPage}
          />
          <Route exact path={"/app/subject/event"} component={EventListPage} />
          <Route exact path={"/app/subject/data"} component={DataListPage} />
          <Route
            exact
            path={"/app/subject/module/:moduleCode"}
            component={ModuleListPage}
          />
          <Route
            exact
            path={"/app/subject/questionnaire/:questionnaireId"}
            component={QuestionnairePage}
          />
        </QuestionnaireContext.Provider>
        <Route
          exact
          path={"/app/subjects/:groupCode"}
          component={SubjectListPage}
        />
        <Route
          exact
          path={"/app/subjects/:groupCode/new"}
          component={SubjectCreatePage}
        />
        <Route path={"/app/subject/:subjectId"} component={SubjectPage} />
        <Route exact path={"/app/drugs"} component={DrugListPage} />
        <Route exact path={"/app/drugs/new"} component={DrugCreatePage} />
        <Route exact path={"/app/drugs/edit/:id"} component={DrugEditPage} />
        <Route exact path={"/app/groups"} component={GroupsListPage} />
        <Route exact path={"/app/groups/new"} component={GroupsNewPage} />
        <Route exact path={"/app/groups/edit/:id"} component={GroupsEditPage} />
        <Route
          exact
          path={"/app/groups/updatemetadata/:id"}
          component={GroupsUpdateMetadataPage}
        />
        <Route exact path={"/app/roles"} component={RolesListPage} />
        <Route exact path={"/app/roles/new"} component={RolesNewPage} />
        <Route exact path={"/app/roles/edit/:id"} component={RolesEditPage} />
        <Route exact path={"/app/utils"} component={AdminUtilsPage} />
        <Route
          exact
          path={"/app/utils/translations"}
          component={AdminTranslationsPage}
        />
        <Route
          exact
          path={"/app/utils/attachment-monitoring"}
          component={AdminAttachmentMonitoringPage}
        />
        <Route
          exact
          path={"/app/utils/attachment-randomisation"}
          component={AdminAttachmentRandomisationPage}
        />
        <Route
          exact
          path={"/app/utils/attachment-blurring"}
          component={AdminAttachmentBlurringPage}
        />
        <Route
          exact
          path={"/app/utils/cache-management"}
          component={AdminCacheManagementPage}
        />
        <Route
          exact
          path={"/app/utils/tech-support"}
          component={AdminTechSupportPage}
        />

        <Route
            exact
            path={"/app/utils/notification-auditactivity"}
            component={AdminNotificationListPage}
        />
        <Route
            exact
            path={"/app/utils/remotejob-processing-queues"}
            component={AdminRemoteJobProcessingQueueListPage}
        />
        <Route
            exact
            path={"/app/utils/config-tool"}
            component={AdminConfigToolsListPage}
        />
        <Route
            exact
            path={"/app/utils/column-overrides"}
            component={AdminColumnOverridesPage}
        />
        <Route
            exact
            path={"/app/utils/questionnaire-workflow"}
            component={AdminQuestionnaireWorkflowPage}
        />
        <Route
          exact
          path={"/app/utils/config-tool/new"}
          component={AdminConfigToolsCreateOrEditPage}
        />
        <Route
          exact
          path={"/app/utils/diagnostics"}
          component={AdminUtilsDiagnosticsPage}
        />
        <Route
          exact
          path={"/app/utils/triggers/new"}
          component={AdminTriggerCreateEditPage}
        />
        <Route
          exact
          path={"/app/utils/triggers/edit/:triggerId"}
          component={AdminTriggerCreateEditPage}
        />
        <Route
            exact
            path={"/app/utils/triggers"}
            component={AdminTriggerListPage}
        />
        <Route
            exact
            path={"/app/utils/digest-alerts"}
            component={AdminDigestAlertListPage}
        />
        <Route
          exact
          path={"/app/utils/lookup"}
          component={AdminLookupTool}
        />
        <Route
          exact
          path={"/app/utils/questionnaire-definitions/new"}
          component={AdminQuestionnaireDefinitionCreatePage}
        />
        <Route
          exact
          path={
            "/app/utils/questionnaire-definitions/edit/:questionnaireDefinitionId"
          }
          component={AdminQuestionnaireDefinitionCreateEditPage}
        />
        <Route
            exact
            path={"/app/utils/questionnaire-definitions/full-edit/:questionnaireDefinitionId"}
            component={AdminQuestionnaireDefinitionFullEditPage}
        />
        <Route
          exact
          path={
            "/app/utils/questionnaire-definitions/edit/:questionnaireDefinitionId/new-question"
          }
          component={AdminQuestionDefinitionCreateEditPage}
        />
        <Route
          exact
          path={"/app/utils/questionnaire-definitions"}
          component={AdminQuestionnaireDefinitionListPage}
        />
        <Route
          exact
          path={"/app/utils/question-definitions/edit/:questionDefinitionId"}
          component={AdminQuestionDefinitionCreateEditPage}
        />
        <Route
            exact
            path={"/app/utils/answers/recalculate"}
            component={AdminRecalculateAnswersPage}
        />

        <Route exact path={"/app/exports"} component={ExportListPage} />
        <Route exact path={"/app/settings"} component={SettingsPage} />
        <Route exact path={"/app/queries"} component={DataQueryListPage} />
        <Route exact path={"/app/notifications"} component={NotificationListPage} />
        <Route
          exact
          path={"/app/search/attachments"}
          component={AttachmentSearch}
        />
        <Route
          exact
          path={"/app/attachments/batchupload"}
          component={BatchUploadIndex}
        />
        <Route
          exact
          path={"/app/attachments/batchupload/uploadfiles"}
          component={BatchUploadFileSelector}
        />
        <Route
          exact
          path={"/app/attachments/batchupload/processdata"}
          component={BatchUploadProcessData}
        />
        <Route
          exact
          path={"/app/search/audit"}
          component={AuditSearch}
        />
        <Route exact path={"/app/screenshots"} component={ScreenshotsListPage} />
        <Route exact path={"/app/permissions"} component={RolesPermissionsPage} />
      </DashboardForceChangePasswordRoute>
    </>
  );
};

export default DashboardRoutes;
