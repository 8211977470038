import RequestHelper from "../RequestHelper"

export default class ScheduledItemService {

  static listScheduledItems(type, subjectId) {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/scheduleditem/${type}/${subjectId}`,
      {},
      "GET")
  }

  static deleteScheduledItem(itemId, subjectId) {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/scheduleditem/${itemId}/${subjectId}`,
      {},
      "DELETE"
    );
  }

  static createScheduledItem(subjectId, item) {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/scheduleditem/${subjectId}`,
      {},
      "POST",
      null,
      item
    );
  }
}