import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Placeholder, Segment } from "semantic-ui-react";
import RSVP from "rsvp";
import SleepTypesChart from "./chartcomponents/SleepTypesChart";
import SensorDataService from "../../../services/sensordata/SensorDataService";

class SubjectGarminSleepGraph extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.initialise();
  }

  initialise = () => {
    RSVP.Promise.all([
      SensorDataService.getGarminSleep(this.props.subjectId).then((data) => {
        let light = [], deep = [], rem = [], awake = [];
        data.days.forEach((day) => {
          light.push({x: day.when, y: day.light});
          deep.push({x: day.when, y: day.deep});
          rem.push({x: day.when, y: day.rem});
          awake.push({x: day.when, y: day.awake});
        });
        this.setState({ light, deep, rem, awake, loading: false });
      }),
    ]).catch(this.errorHandler);

    return {
      error: false,
      loading: true,
      definition: {},
      questionnaires: [],
      permissions: [],
    };
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.subjectId !== this.props.subjectId) {
      this.setState(this.initialise());
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        {this.state.loading && (
          <Segment>
            <Placeholder fluid>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        )}

        {!this.state.loading && (
          <>
            {(!this.state.deep.length === 0 && this.state.light.length === 0 && this.state.rem.length === 0 && this.state.awake.length === 0) && (
                <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY")}</p>
            )}

            {(this.state.deep.length > 0 || this.state.light.length > 0 || this.state.rem.length > 0 || this.state.awake.length > 0) && (
              <SleepTypesChart
                titleLight={t("GRAPH_SLEEP_TITLE_LIGHT", "Light Sleep")}
                titleDeep={t("GRAPH_SLEEP_TITLE_DEEP", "Deep Sleep")}
                titleRem={t("GRAPH_SLEEP_TITLE_REM", "REM Sleep")}
                titleAwake={t("GRAPH_SLEEP_TITLE_AWAKE", "Awake")}
                yAxis={t("GRAPH_SLEEP_Y_LABEL")}
                subjectId={this.props.subjectId}
                sleepDataLight={this.state.light}
                sleepDataDeep={this.state.deep}
                sleepDataRem={this.state.rem}
                sleepDataAwake={this.state.awake}
              />
            )}
          </>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(SubjectGarminSleepGraph);
