export default class PageManager {
  static CURRENT_PAGE = "";

  static PAGES = {
    HOME: {
      path: "/app/home",
    },
    PROFILE: {
      parent: "HOME",
      path: "/app/profile",
    },
    SUBJECT_LIST: {
      parent: "HOME",
      path: "/app/subjects",
    },
    SUBJECT_VIEW: {
      parent: "SUBJECT_LIST",
      path: "/app/subject/:id",
    },
    SUBJECT_VIEW_DETAILS: {
      parent: "SUBJECT_LIST",
      path: "/app/subject/:id/details",
    },
    SUBJECT_VIEW_DATA: {
      parent: "SUBJECT_LIST",
      path: "/app/subject/:id/data/:data",
    },
    SUBJECT_CREATE: {
      parent: "SUBJECT_LIST",
      path: "/app/subjects/new",
    },
    STAFF_LIST: {
      parent: "HOME",
      path: "/app/staff",
    },
    STAFF_CREATE: {
      parent: "STAFF_LIST",
      path: "/app/staff/new",
    },
    STAFF_EDIT: {
      parent: "STAFF_LIST",
      path: "/app/staff/edit/:id",
    },
    DRUG_LIST: {
      parent: "HOME",
      path: "/app/drugs",
    },
    DRUG_CREATE: {
      parent: "DRUG_LIST",
      path: "/app/drugs/new",
    },
    DRUG_EDIT: {
      parent: "DRUG_LIST",
      path: "/app/drugs/edit/:id",
    },
    EXPORT_LIST: {
      parent: "HOME",
      path: "/app/exports",
    },
  };

  static setCurrentPage(page) {
    PageManager.CURRENT_PAGE = page;
  }

  static getCurrentPage() {
    const pages = [];
    let parent = PageManager.CURRENT_PAGE;

    while (parent) {
      const page = PageManager.PAGES[parent];

      if (page) {
        pages.push(Object.assign({ name: parent }, page));
        parent = page.parent;
      } else {
        parent = undefined;
      }
    }

    return pages.reverse();
  }
}
