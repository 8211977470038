import React, {useEffect, useMemo} from 'react'
import QUESTION_RENDERERS from "../../questionnaires/question-renderers/QuestionnaireRenderers";
import {QUESTION_TYPES} from "atom5-branching-questionnaire";
import useCollapseNavInfo from "../../hooks/useCollapseNavInfo";
import MonitoredVideo from "../../questionnaires/question-renderers/MonitoredVideo";

const VideoDisplay = ({definition, questionnaire}) => {
  const [ , setIsExpanded] = useCollapseNavInfo()
  const videoQuestions = useMemo(()=>{
    return definition?.questions.filter(q => {
      return q.type === QUESTION_TYPES.RENDER_VIDEO || q.type === QUESTION_TYPES.MONITORED_VIDEO
    }).filter(q => q?.config?.showToSide)
  }, [definition]);

  useEffect(()=>{
    if(videoQuestions.length === 0) return
    setIsExpanded(false);
  }, [setIsExpanded, videoQuestions])

  if(videoQuestions.length === 0) return null;

  const VideoComponent = QUESTION_RENDERERS[QUESTION_TYPES.RENDER_VIDEO]

  return  <div style={{position: "sticky", top: "80px"}}>
    {videoQuestions.map(q => {
      if (q.type === QUESTION_TYPES.RENDER_VIDEO) {
        return <VideoComponent question={q} value={questionnaire?.answers[q.code]} isInSideView={true}/>
      }
      if (q.type === QUESTION_TYPES.MONITORED_VIDEO) {
        return <MonitoredVideo question={q} value={questionnaire?.answers[q.code]} isInSideView={true}/>
      }
      console.warn("[VideoDisplay] Unsupported question type passed to VideoDisplay")
      return null;
    })}
  </div>
}

export default VideoDisplay;