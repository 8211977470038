import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button} from 'semantic-ui-react';

const withShowHide = (WrappedComponent) => {
  return (props) => {
    const {t} = useTranslation();

    if (!props.question) {
      return <WrappedComponent {...props} />;
    }

    const canCollapse = props.question.config?.canCollapse;

    const [isCollapsed, setIsCollapsed] = useState(canCollapse);

    const toggleIsCollapsed = () => {
      setIsCollapsed(!isCollapsed);
    }

    const buttonText = props.question.config?.buttonText || t("SHOW_CONTENT");

    return (
      <>
        {isCollapsed && <div style={{ padding: "8px", width: "100%" }}>
          <Button onClick={toggleIsCollapsed}>{buttonText}</Button>
        </div>}
        {!isCollapsed && <WrappedComponent {...props} />}
      </>
    );
  };
}

export default withShowHide;