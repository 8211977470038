import React, {useCallback, useMemo, useState} from 'react';
import {Button, Form, FormField, Input, Label, TextArea} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import LookupService from "../../../services/LookupService";


const LookupItemEditCard = ({lookup, lookupItem, language, onSubmit}) => {
  const {t} = useTranslation()

  const [ref, setRef] = useState(lookupItem?.ref || "")
  const [value, setValue] = useState(lookupItem?.value || "")
  const [data, setData] = useState(lookupItem?.data || "")
  const [source, setSource] = useState(lookupItem?.source || "")
  const [priority, setPriority] = useState(lookupItem?.priority || "0")

  const isSaveAllowed = useMemo(()=>{
    return ref && ref?.length > 0
        && value && value?.length > 0
        && data && data?.length > 0;
  }, [data, ref, value])
  const handleSave = useCallback(async () => {
    await LookupService.editLookupItemByLookupItemId(lookupItem.id, ref, value, data, priority, source);
    onSubmit && onSubmit();
  }, [data, lookupItem.id, onSubmit, ref, value, priority, source])


  return <div style={{display: "flex", padding: '16px'}}>
    <Form style={{width: '100%'}}>
      <Label style={{marginBottom: "16px"}}>{language}</Label>
      <FormField>
        <label>{t("LOOKUP_ITEM_REF", "Ref")}</label>
        <Input fluid value={ref} onChange={(e, v)=>setRef(v.value)} />
      </FormField>
      <FormField>
        <label>{t("LOOKUP_ITEM_VALUE", "Value")}</label>
        <Input fluid value={value} onChange={(e, v)=>setValue(v.value)} />
      </FormField>
      <FormField>
        <label>{t("LOOKUP_ITEM_DATA", "Data")}</label>
        <TextArea fluid value={data} onChange={(e, v)=>setData(v.value)} />
      </FormField>
      <FormField>
        <label>{t("LOOKUP_ITEM_PRIORITY", "Priority (lower value appears higher in the list)")}</label>
        <TextArea fluid value={priority} onChange={(e, v)=>setPriority(v.value)} />
      </FormField>
      <FormField>
        <label>{t("LOOKUP_ITEM_SOURCE", "Source")}</label>
        <TextArea fluid value={source} onChange={(e, v)=>setSource(v.value)} />
      </FormField>
      <Button
          disabled={!isSaveAllowed}
          primary={isSaveAllowed}
          onClick={handleSave}
      >{
        t("GLOBAL_SAVE", "Save")
      }</Button>
    </Form>
  </div>
}

export default LookupItemEditCard;