import InternationalisationService from "../InternationalisationService";
import RequestHelper from "../RequestHelper";
import StaffService from "../StaffService";

export default class GroupService {
  static getGroupsWithPermission(requiredPermission) {
    return StaffService.getMyProfile().then((profile) => {
      let groups = {};
      profile.groupMappings.forEach((groupMapping) => {
        groupMapping.roles.forEach((role) => {
          if (role.fullPermissions.includes(requiredPermission)) {
            groups[groupMapping.group.code] = groupMapping.group;
          }
        });
      });
      return Object.values(groups);
    });
  }

  static getGroupsWithRole(requiredRole) {
    return StaffService.getMyProfile().then((profile) => {
      let groups = {};
      profile.groupMappings.forEach((groupMapping) => {
        groupMapping.roles.forEach((role) => {
          if (role.code === requiredRole) {
            groups[groupMapping.group.code] = groupMapping.group;
          }
        });
      });
      return Object.values(groups);
    });
  }

  static getGroupsWithPermissionSynchronous(profile, requiredPermission) {
    let groups = {};
    profile.groupMappings.forEach((groupMapping) => {
      groupMapping.roles.forEach((role) => {
        if (role.fullPermissions.includes(requiredPermission)) {
          groups[groupMapping.group.code] = groupMapping.group;
        }
      });
    });
    return Object.values(groups);
  }

  static getGroupsWithRoleSynchronous(profile, requiredRole) {
    let groups = {};
    profile.groupMappings.forEach((groupMapping) => {
      groupMapping.roles.forEach((role) => {
        if (role.code === requiredRole) {
          groups[groupMapping.group.code] = groupMapping.group;
        }
      });
    });
    return Object.values(groups);
  }

  static getGroupsWithPermissionFromProfile(profile, requiredPermission) {
    let groups = {};
    profile.groupMappings.forEach((groupMapping) => {
      groupMapping.roles.forEach((role) => {
        if (role.fullPermissions.includes(requiredPermission)) {
          groups[groupMapping.group.code] = groupMapping.group;
        }
      });
    });
    return Object.values(groups);
  }

  static newGroup(group, feedback) {
    let feedbackHeader = feedback
      ? { "x-feedback-available": "Adding new Group" }
      : {};
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/group/new",
      {
        "Accept-Language": InternationalisationService.getLanguage(),
        ...feedbackHeader,
      },
      "POST",
      null,
      { ...group, feedback }
    );
  }

  static editGroup(group, feedback) {
    let feedbackHeader = feedback
      ? { "x-feedback-available": "Editing group " + group.code }
      : {};
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/group/" + group.id + "/edit",
      {
        "Accept-Language": InternationalisationService.getLanguage(),
        ...feedbackHeader,
      },
      "POST",
      null,
      { ...group, feedback }
    );
  }

  static getMetadata(group) {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/group/" + group.id + "/metadata",
      {},
      "GET"
    );
  }

  static updateMetadata(group, values) {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/group/" + group.id + "/metadata",
      {},
      "POST",
      null,
      values
    );
  }
}
