import React, {useContext, useEffect, useMemo, useState} from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import GroupService from "../../services/GroupService";
import GroupPermission from "../../GroupPermission";
import ExportService from "../../services/ExportService";
import { useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import ConfigContext from "../../context/ConfigContext";
import TypeHelper from "../../helpers/TypeHelper";
import { Icon } from "semantic-ui-react";
import DashboardReportService from "../../services/DashboardReportService";

const StaffMenu = (props) => {
  const { user, groups, t } = props;
  const { profile } = user;

  const config = useContext(ConfigContext);
  const location = useLocation();

  let [exportTriggered, setExportTriggered] = useState(false);
  let [isGroupsExpanded, setIsGroupsExpanded] = useState(true);
  const toggleIsGroupsExpanded = () => {
    setIsGroupsExpanded((oldValue) => !oldValue);
  };

  let [dashboardReportList, setDashboardReportList] = useState();
  const groupsWithDashboardReportPermission = useMemo(
      () =>
          GroupService.getGroupsWithPermissionFromProfile(
              profile,
              GroupPermission.VIEW_SITE_DASHBOARD_REPORTS
          ),
      [profile]
  );
  const canViewDashboardReports = profile.superAdmin || groupsWithDashboardReportPermission.length > 0;

  const groupsWithManageExportsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_EXPORTS
      ),
    [profile]
  );
  const canManageExports =
    profile.superAdmin || groupsWithManageExportsPermission.length > 0;

  const groupsWithCreateStaffPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.CREATE_STAFF
      ),
    [profile]
  );
  const canCreateStaff =
    profile.superAdmin || groupsWithCreateStaffPermission.length > 0;

  const groupsWithManageRewardsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_REWARDS
      ),
    [profile]
  );
  const canManageRewards =
    profile.superAdmin || groupsWithManageRewardsPermission.length > 0;

  const groupsWithManageGroupsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_GROUPS
      ),
    [profile]
  );
  const canManageGroups =
    profile.superAdmin || groupsWithManageGroupsPermission.length > 0;

  const canManageRoles = profile.superAdmin;

  const showHomeMenuItem = config?.ui?.showHomeMenuItem
    ? TypeHelper.parseBool(config?.ui?.showHomeMenuItem)
    : true;

  const groupsWithViewPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.VIEW_SUBJECT
      ),
    [profile]
  );

  const groupsWithViewDataQueryPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.VIEW_DATA_QUERIES
      ),
    [profile]
  );
  const canViewDataQueries =
    profile.superAdmin || groupsWithViewDataQueryPermission.length > 0;

  const groupsWithReceiveAlertsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.VIEW_NOTIFICATIONS
      ),
    [profile]
  );
  const canViewNotifications =
    profile.superAdmin || groupsWithReceiveAlertsPermission.length > 0;

  const groupsWithManageScreenshotsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_SCREENSHOTS
      ),
    [profile]
  );
  const canManageScreenshots =
    profile.superAdmin || groupsWithManageScreenshotsPermission.length > 0;

  const groupsWithBatchAttachmentsViewPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.BATCH_ATTACHMENTS_VIEW
      ),
    [profile]
  );
  const canViewBatchUploadAttachments = groupsWithBatchAttachmentsViewPermission.length > 0;

  const viewableGroups = groups
    .filter((group) =>
      groupsWithViewPermission.some(
        (viewableGroup) => group.code === viewableGroup.code
      )
    )
    .sort((a, b) => a?.label?.localeCompare(b.label))
    .map((group) => (
      <MenuItem
        className="indent-1"
        active={location.pathname === "/app/subjects/" + group.code}
        to={"/app/subjects/" + group.code}
        key={group.code}
        text={group.label}
      />
    ));

  // Search
  const searchMenuComponents = [];

  const groupsWithSearchAttachmentsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.SEARCH_ATTACHMENTS
      ),
    [profile]
  );
  const canSearchAttachments = groupsWithSearchAttachmentsPermission.length > 0;
  if (canSearchAttachments) {
    searchMenuComponents.push(
      <MenuItem
        className="indent-1"
        active={location.pathname === "/app/search/attachments"}
        to={"/app/search/attachments"}
        icon={"file outline"}
        text={t("MENU_SEARCH_ATTACHMENTS", "Search Attachments")}
      />
    );
  }

  const groupsWithSearchAuditPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.SEARCH_AUDIT
      ),
    [profile]
  );
  const canSearchAudit = groupsWithSearchAuditPermission.length > 0;
  if (canSearchAudit) {
    searchMenuComponents.push(
      <MenuItem
        className="indent-1"
        active={location.pathname === "/app/search/audit"}
        to={"/app/search/audit"}
        icon={"database icon"}
        text={t("MENU_SEARCH_AUDIT", "Search Audit")}
      />
    );
  }

  const triggerExport = () => {
    ExportService.triggerFullExport();
    setExportTriggered(true);
  };


  useEffect(()=>{
    const getDashboardReportList = async () => {
      const dashboardReports = await DashboardReportService.getDashboardReportList();
      setDashboardReportList(dashboardReports)
    }
    canViewDashboardReports && getDashboardReportList();
  }, [canViewDashboardReports])

  return (
    <>
      {showHomeMenuItem && (
        <MenuItem
          active={location.pathname === "/app/home"}
          to={"/app/home"}
          icon={"desktop"}
          text={t("MENU_HOME")}
        />
      )}
      {canViewDashboardReports && dashboardReportList?.reports?.length > 0 && (
          dashboardReportList.reports.map((dR)=>
            <MenuItem
                to={"/app/dashboard-report/" + dR.code}
                text={<strong>{t(dR.label)}</strong>}
            />
          )
      )}
      {viewableGroups.length > 0 && (
        <>
          <div
            onClick={toggleIsGroupsExpanded}
            aria-label={t("MENU_SHOW_HIDE_SUBJECT_GROUPS")}
          >
            <div style={styles.iconRightStyle}>
              <Icon name={isGroupsExpanded ? "minus" : "plus"} />
            </div>
            <MenuItem
              to="#"
              icon={"address book"}
              text={<strong>{t("MENU_SUBJECTS")}</strong>}
            />
          </div>
          <div
            style={isGroupsExpanded ? styles.groupsOpen : styles.groupsClosed}
          >
            {viewableGroups}
          </div>
        </>
      )}
      {canManageExports && (
        <>
          <MenuItem to="#" text={<strong>{t("MENU_EXPORT")}</strong>} />

          <MenuItem
            className="indent-1"
            active={location.pathname === "/app/exports"}
            to={"/app/exports"}
            icon={"md folder"}
            text={t("MENU_EXPORT_LIST")}
          />

          <div onClick={triggerExport}>
            <MenuItem
              to="#"
              className="indent-1"
              icon={exportTriggered ? "check" : "play"}
              text={
                exportTriggered
                  ? t("MENU_EXPORT_TRIGGERED")
                  : t("MENU_TRIGGER_EXPORT")
              }
            />
          </div>
        </>
      )}

      {searchMenuComponents.length > 0 && (
        <>
          <MenuItem
            to="#"
            text={<strong>{t("MENU_SEARCH", "Search")}</strong>}
          />
          {searchMenuComponents}
        </>
      )}

      {(canCreateStaff || canManageRewards || profile.drugManager) && (
        <>
          <MenuItem to="#" text={<strong>{t("MENU_ADMINISTRATION")}</strong>} />
          {canCreateStaff && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/staff"}
              to={"/app/staff"}
              icon={"md user"}
              text={t("MENU_STAFF")}
            />
          )}
          {canManageRewards && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/rewards"}
              to={"/app/rewards"}
              icon={"md gift"}
              text={t("MENU_REWARDS")}
            />
          )}
          {canViewDataQueries && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/queries"}
              to={"/app/queries"}
              icon={"md search"}
              text={t("MENU_DATA_QUERIES")}
            />
          )}
          {profile.drugManager && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/drugs"}
              to={"/app/drugs"}
              icon={"syringe"}
              text={t("MENU_DRUG_MANAGEMENT")}
            />
          )}
          {canManageGroups && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/groups"}
              to={"/app/groups"}
              icon={"address book"}
              text={t("MENU_GROUP_MANAGEMENT")}
            />
          )}
          {canManageRoles && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/roles"}
              to={"/app/roles"}
              icon={"key"}
              text={t("MENU_ROLES_MANAGEMENT", "Manage Roles")}
            />
          )}
          {canViewNotifications && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/notifications"}
              to={"/app/notifications"}
              icon={"bell"}
              text={t("MENU_VIEW_NOTIFICATIONS","Notifications")}
            />
          )}
          {canManageScreenshots && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/screenshots"}
              to={"/app/screenshots"}
              icon={"mobile alternate"}
              text={t("MENU_SCREENSHOT_MANAGEMENT","Manage Screenshots")}
            />
          )}
          {canViewBatchUploadAttachments && (
            <MenuItem
              className="indent-1"
              active={location.pathname === "/app/attachments/batchupload"}
              to={"/app/attachments/batchupload"}
              icon={"upload"}
              text={t("MENU_BATCHUPLOAD_ATTACHMENTS","Batch upload Attachments")}
            />
          )}
        </>
      )}
    </>
  );
};

const styles = {
  iconRightStyle: {
    float: "right",
    marginTop: "14px",
    width: 30,
  },
  groupsOpen: {},
  groupsClosed: {
    height: 0,
    overflow: "hidden",
  },
};

const enhance = compose(withTranslation());

export default enhance(StaffMenu);
