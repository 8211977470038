import React, {useMemo} from "react";
import { Form } from "semantic-ui-react";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";

const TextArea = ({
  question,
  value,
  changeValue,
  isReadOnly,
  printable = false,
}) => {
  const onChange = ({ target: { value } }) => {
    changeValue(value);
  };

  const isLong = useMemo(()=>{
    return value?.length > 800 || value?.split("\n").length > 5;
  }, [value])

  return (
    <>
      {!isReadOnly ? (
        <div className={"field"}>
          <textarea
            id={question.code}
            className={["ui", "form", "textarea"]}
            onChange={onChange}
            rows={question.rows || 5}
            defaultValue={value}
          />
        </div>
      ) : !printable ? (
        <Form.TextArea
          data-question-answer-value={value}
          name={question.code}
          id={question.code}
          defaultValue={value}
          disabled={true}
          style={{...{minWidth: 300, minHeight: isLong ? 240 : 160, resize: "none"}, ...question?.config?.style?.input}}
        />
      ) : (
        <div style={{ marginBottom: 30 }}>
          {value.split("\n").map((p) => (
            <p style={{ color: "#AAA", marginLeft: 14, marginTop: 17 }}>{p}</p>
          ))}
        </div>
      )}
    </>
  );
};

export default withContainer(withLabel(withError(TextArea)));
