import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { updateLanguage } from "../../services/admin/AdminTranslationService"

import {
    Button, Checkbox,
    Form,
    Grid, Image, Message, Modal
} from "semantic-ui-react";

const EditLanguageModal = ({open, onClose, languageCode, languageName, createNew, t}) => {

    const [newName, setNewName] = useState(languageCode)
    const [newCode, setNewCode] = useState(languageName)
    const [error, setError] = useState("")
    const [info, setInfo] = useState("")
    const [purposes, setPurposes] = useState(["MobileApp"])


    useEffect(() => {
        setError("")
        setInfo("")
        setNewName(languageName)
        setNewCode(languageCode)
        setPurposes(["MobileApp"])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageCode, languageName, createNew, open, onclose])


    const addPurpose = (purpose) => {

        if(!purposes.includes(purpose)){
            let newPurposes = [...purposes, purpose];
            setPurposes(newPurposes)
        }
    }

    const removePurpose = (purpose) => {

        if(purposes.includes(purpose)){
            let newPurposes = purposes.filter(p => p !== purpose)
            setPurposes(newPurposes)
        }
    }
    const doClose = ()=> {
        setError("")
        setInfo("")
        setNewName(languageName)
        setNewCode(languageCode)
        setPurposes(["MobileApp"])
        onClose()
    }
    const doSave = async () => {
        try{
            if(!newName || newName.trim().length===0){
                setError("Name is required")
                return;
            }
            if(!newCode || newCode.trim().length===0){
                setError("Code is required")
                return;
            }
        if (createNew) {
            await updateLanguage(newCode, newName, true, purposes)
            setError("")
            setInfo("Import file submitted. It can take a few minutes, check the jobs list for completion ")
            setTimeout(() => {
                doClose()
            }, 3000)
        } else {
            await updateLanguage(languageCode, newName, false, purposes)
            doClose()
        }

        }catch (e) {
            console.error("Error creating language",e)
            setError(e)
        }
    }


    return (
    <Modal
        size={"large"}
        open={open}
        onClose={doClose}
    >
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <Image
                            style={{ height: "25px" }}
                            src={
                                "../../images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal.Content scrolling>
                {error && error.length>0 &&  <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={error}
                />}
                {info && info.length>0 &&  <Message
                    info
                    header={t("GLOBAL_INFO_TITLE", "Info")}
                    content={info}
                />}

                <Form>
                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION_LANGUAGE_CODE", "Language Code")}</label>
                        <input
                            name="languageCode"
                            value={newCode}
                            readOnly={!createNew}
                            onChange={(e) => {
                                e.preventDefault()
                                setNewCode(e.target.value)
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION_LANGUAGE_NAME", "Language Name")}</label>
                        <input
                            name="languageName"
                            value={newName}
                            readOnly={false}
                            onChange={(e) => {
                                e.preventDefault()
                                setNewName(e.target.value)
                            }}
                        />
                    </Form.Field>

                    {createNew &&
                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION_PURPOSES", "Language purpose")}</label>
                        <Checkbox
                            name="mobileapp"
                            value={true}
                            checked={purposes.includes("MobileApp")}
                            onChange={(e, data) => {
                                e.preventDefault()
                                if(data?.checked){
                                    addPurpose("MobileApp")
                                }else{
                                    removePurpose("MobileApp")
                                }
                            }}
                            label={"Mobile App"}
                        />
                        <Checkbox
                            name="clinicianweb"
                            value={true}
                            checked={purposes.includes("ClinicianWeb")}
                            onChange={(e, data) => {
                                e.preventDefault()
                                if(data?.checked){
                                    addPurpose("ClinicianWeb")
                                }else{
                                    removePurpose("ClinicianWeb")
                                }
                            }}
                            label={"Clinician Web"}
                        />
                        <Checkbox
                            name="nucleus"
                            value={true}
                            checked={purposes.includes("Nucleus")}
                            onChange={(e, data) => {
                                e.preventDefault()
                                if(data?.checked){
                                    addPurpose("Nucleus")
                                }else{
                                    removePurpose("Nucleus")
                                }
                            }}
                            label={"Nucleus"}
                        />

                    </Form.Field>

                    }



                </Form>
            </Modal.Content>
            <Grid centered padded>
                <Grid.Row>

                    <Button
                        color={"orange"}
                        size="small"
                        onClick={() => doSave()}
                    >
                        {t("GLOBAL_BUTTON_SAVE", "Save")}
                    </Button>

                    <Button
                        basic
                        color={"orange"}
                        size="small"
                        onClick={onClose}
                    >
                        {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                    </Button>
                </Grid.Row>
            </Grid>

    </Modal>
    )
}

export default withTranslation()(EditLanguageModal);
