export default {
  CREATION_TRIGGER:"CREATION_TRIGGER",
  QUESTIONNAIRE_ID:"QUESTIONNAIRE_ID",
  CREATED_DATE:"CREATED_DATE",
  QUESTIONNAIRE_ID_ENCODED:"QUESTIONNAIRE_ID_ENCODED",
  DELIVERED_DATE:"DELIVERED_DATE",
  COMPLETED_LOCATION:"COMPLETED_LOCATION",
  COMPLETED_BY:"COMPLETED_BY",
  COMPLETED_DATE:"COMPLETED_DATE",
  TOTAL_SCORE:"TOTAL_SCORE",
  QUESTIONS:"QUESTIONS",
  QUESTIONNAIRE_SUBMIT_BUTTON: "QUESTIONNAIRE_SUBMIT_BUTTON",
  QUESTIONNAIRE_INFO_BUTTON: "QUESTIONNAIRE_INFO_BUTTON",
  WORKFLOW_TASK_START_DATE: "WORKFLOW_TASK_START_DATE",
  CUSTOM_QUESTIONNAIRE_COLUMNS: "CUSTOM_QUESTIONNAIRE_COLUMNS"
}