import React, { useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router";
import SubjectInfoContext from "../../context/SubjectInfoContext";
import SubjectService from "../../SubjectService";
import SubjectCreateQuestionnairePage from "./SubjectCreateQuestionnairePage";
import SubjectDataQuestionnairePage from "./SubjectDataQuestionnairePage";
import SubjectDeviceCreatePage from "./SubjectDeviceCreatePage";
import SubjectEditEmailPage from "./SubjectEditEmailPage";
import SubjectEditGroupsPage from "./SubjectEditGroupsPage";
import SubjectEditLanguagePage from "./SubjectEditLanguagePage";
import SubjectEditPage from "./SubjectEditPage";
import SubjectSubmitQuestionnairePage from "./SubjectSubmitQuestionnairePage";
import SubjectViewPage from "./SubjectViewPage";
import SubjectViewQuestionnairePage from "./SubjectViewQuestionnairePage";
import SubjectViewPDFQuestionnairePage from "./SubjectViewPDFQuestionnairePage";
import { Loader } from "semantic-ui-react";
import SubjectEditSubjectCodePage from "./SubjectEditSubjectCodePage";
import StaffSignOffQuestionnairePage from "./StaffSignOffQuestionnairePage";
import AnswerManagementListQuestionnaires from "./questionnaireAnswerManagement/AnswerManagementListQuestionnaires";

const SubjectPage = () => {
  const [subjectData, setSubjectData] = useState({
    groups: [],
    subjectCode: "",
    loaded: false
  });

  const { subjectId } = useParams();

  useEffect(() => {
    const getSubjectData = async () => {
      const isSubjectIdValid = !isNaN(subjectId);
      if (isSubjectIdValid) {
        const subjectData = await SubjectService.getSubjectData(subjectId);
        setSubjectData({ ...subjectData, loaded: true });
      }
    };
    getSubjectData();
  }, [subjectId]);

  return (
    <SubjectInfoContext.Provider value={{ subjectData }}>
      <Switch>
        <Route
          path={"/app/subject/:subjectId/tabs/"}
          render={(props) => {
            if (!subjectData.loaded) {
              return <Loader active />;
            } else { 
              return <SubjectViewPage {...props} subjectData={subjectData} />;
            }
          }}
        />
        <Route
          exact
          path={"/app/subject/:subjectId/edit/:tabName"}
          component={SubjectEditPage}
        />
        <Route
          exact
          path={"/app/subject/:subjectId/groups"}
          component={SubjectEditGroupsPage}
        />
        <Route
          exact
          path={"/app/subject/:subjectId/email"}
          component={SubjectEditEmailPage}
        />
        <Route
          exact
          path={"/app/subject/:subjectId/questionnaireAnswerManagement/questionnaires"}
          component={AnswerManagementListQuestionnaires}
        />
        <Route
          exact
          path={"/app/subject/:subjectId/language"}
          component={SubjectEditLanguagePage}
        />
        <Route
          exact
          path={"/app/subject/:subjectId/subjectCode"}
          component={SubjectEditSubjectCodePage}
        />
        <Route
          exact
          path={"/app/subject/:subjectId/devices/new"}
          component={SubjectDeviceCreatePage}
        />
        <Route
          exact
          path={"/app/subject/:subjectId/data/:definitionCode"}
          component={SubjectDataQuestionnairePage}
        />
        <Route
          exact
          path={
            "/app/subject/:subjectId/questionnaire-type/:questionnaireType/:definitionCode/create"
          }
          component={SubjectCreateQuestionnairePage}
        />
        <Route
          exact
          path={
            "/app/subject/:subjectId/questionnaire-type/:questionnaireType/:definitionCode/pdfview"
          }
          component={SubjectViewPDFQuestionnairePage}
        />
        <Route
          exact
          path={
            "/app/subject/:subjectId/questionnaire-type/:questionnaireType/:definitionCode/view/:questionnaireId"
          }
          component={SubjectViewQuestionnairePage}
        />
        <Route
          exact
          path={
            "/app/subject/:subjectId/questionnaire-type/:questionnaireType/:definitionCode/submit/:questionnaireId"
          }
          component={SubjectSubmitQuestionnairePage}
        />
        <Route
          exact
          path={
            "/app/subject/:subjectId/questionnaire-type/:questionnaireType/:definitionCode/sign-off/:questionnaireId"
          }
          component={StaffSignOffQuestionnairePage}
        />
      </Switch>
    </SubjectInfoContext.Provider>
  );
};

export default SubjectPage;
