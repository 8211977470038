import moment from "moment";
import React from "react";
import DateTimeField from "../../components/DateTimeField";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";

const Date = ({ question, value, changeValue, isReadOnly }) => (
  <DateTimeField
    value={moment(value)}
    onChange={(v) => {
      if (v.isValid()) {
        changeValue(v.format("YYYY-MM-DD"));
      }
    }}
    mode={"date"}
    readOnly={isReadOnly}
    nowOnly={question?.config?.type === "NOW"}
  />
);

export default withContainer(withLabel(withError(Date)));
