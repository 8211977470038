import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Icon, Popup, PopupContent } from "semantic-ui-react";

const CronExpressionBuilderExamplesPopup = (props) => {
  const { t, examples, onSelect, buttonText, buttonIcon } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  if (!examples) {
    return null;
  }

  const onOpen = () => {
    setIsOpen(true);
  };

  const onSaveClick = (e) => {
    onSelect && onSelect(e, selectedValue);
    closePopup();
  };

  const onChange = (e, data) => {
    const value = data?.value;
    setSelectedValue(value);
  };

  const onCancelClick = () => {
    closePopup();
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const getDropdownOptions = () => {
    return Object.entries(examples).map((example) => {
      const exampleText = example[0];
      const exampleValue = example[1];
      return {
        key: exampleValue,
        text: exampleText,
        value: exampleValue,
      };
    });
  };

  const popupTrigger = buttonIcon ? (
    <Icon
      name={buttonIcon}
      size={"large"}
      title={buttonText}
      className={"mouse-pointer"}
    />
  ) : (
    <Button>{buttonText}</Button>
  );

  return (
    <Popup
      trigger={popupTrigger}
      on="click"
      pinned
      open={isOpen}
      onOpen={onOpen}
    >
      <PopupContent>
        <Dropdown
          fluid
          selection
          options={getDropdownOptions()}
          onChange={onChange}
        />
        <div>
          <Button
            primary
            onClick={(e) => {
              onSaveClick(e);
            }}
          >
            {t("GLOBAL_BUTTON_SAVE", "Save")}
          </Button>
          <Button onClick={onCancelClick}>
            {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
          </Button>
        </div>
      </PopupContent>
    </Popup>
  );
};

export default withTranslation()(CronExpressionBuilderExamplesPopup);
