import RequestHelper from "../RequestHelper";

export default {
  getUnsentDigestAlerts: () => {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/digest-alerts/unsent",
        {},
        "GET",
        null
    );
  },
  forceSendAllDigestAlerts: () => {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS +
        "/digest-alerts/forceSendAllUnsent",
        {},
        "POST",
        null
    );
  }
};
