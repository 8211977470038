import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import NotificationService from "../../services/NotificationService";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

const GenericReturnPage = () => {
  const DEFAULT_REDIRECT = "/app/home";
  const dispatch = useDispatch();
  const [redirectUrl, setRedirectUrl] = useState(undefined);

  useEffect(() => {
    const windowUrl = window.location.href;
    const parsedUrl = new URL(windowUrl);
    const type = (parsedUrl.searchParams.get("t") || "").toUpperCase();
    const data = parsedUrl.searchParams.get("d");

    if (!type || !data) {
      setRedirectUrl(DEFAULT_REDIRECT);
    }

    switch (type) {
      case "NOTIFICATION":
        const redirectUrl = NotificationService.processBackgroundMessage(
          dispatch,
          data
        );
        if (redirectUrl === undefined) {
          setRedirectUrl(DEFAULT_REDIRECT);
        } else {
          setRedirectUrl(redirectUrl);
        }
        break;
      default:
        setRedirectUrl(DEFAULT_REDIRECT);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  } else {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader active={true} inline={true} size={"massive"} />
      </div>
    );
  }
};

export default withTranslation()(GenericReturnPage);
