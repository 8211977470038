import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";

class SubjectMedicationEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.initialise();
  }

  initialise = () => {
    SubjectService.getMedication(
      this.props.match.params.subjectId,
      this.props.match.params.medicationId
    )
      .then((medication) => {
        this.setState({
          medication,
          ongoing: !medication.endDate,
          endDate: medication.endDate,
          baselineCompliance: medication.baselineCompliance,
          loading: false,
        });
      })
      .catch(this.errorHandler);

    return {
      loading: true,
      error: false,

      medication: {
        drug: {},
      },

      ongoing: true,
      endDate: undefined, // date
      baselineCompliance: 0, // double
    };
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.match.params.subjectId !== this.props.match.params.subjectId ||
      prevProps.match.params.medicationId !==
        this.props.match.params.medicationId
    ) {
      this.setState(this.initialise());
    }
  };

  selectChanged = (event, option) => {
    this.setState({ [option.name]: option.value });
  };

  checkboxChanged = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event, headers) => {
    const payload = {
      endDate: this.state.endDate,
      baselineCompliance: this.state.baselineCompliance,
    };
    SubjectService.editMedication(
      this.props.match.params.subjectId,
      this.props.match.params.medicationId,
      payload
    )
      .then(this.onCancel)
      .catch((error) => {
        console.error(error);
        this.setState({ error: true });
      });
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  onCancel = () => {
    window.location.href =
      window.location.origin +
      "/app/subject/" +
      this.props.match.params.subjectId +
      "/tabs/medication";
  };

  render() {
    const { t } = this.props;
    const errorMessage = this.state.error
      ? t("GLOBAL_ERROR_GENERIC_MESSAGE")
      : undefined;

    const slotCheckboxes = ["1", "2", "3", "4"].map((slotNum) => {
      return (
        <Form.Field key={slotNum}>
          <label>{t("MEDICATION_CREATE_SLOT_" + slotNum)}</label>
          <Form.Group inline>
            <Form.Input
              type="checkbox"
              id={"slot" + slotNum}
              name={"slot" + slotNum}
              onChange={this.checkboxChanged}
              value={this.state.medication["slot" + slotNum]}
              checked={this.state.medication["slot" + slotNum]}
              disabled="true"
            />
          </Form.Group>
        </Form.Field>
      );
    });

    return (
      <Page
        name="MEDICATION_EDIT"
        header={t("MEDICATION_EDIT_HEADER")}
        subheader={t("MEDICATION_EDIT_SUBHEADER")}
        loading={this.state.loading}
      >
        <Form
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          error={this.state.error}
        >
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE")}
            content={errorMessage}
          />

          <Grid columns={"equal"}>
            <Grid.Column>
              <Segment>
                <Header>{t("MEDICATION_CREATE_DRUG_SECTION_HEADER")}</Header>

                <Form.Field>
                  <Form.Input
                    label={t("MEDICATION_CREATE_DRUG_LABEL")}
                    type="text"
                    name="drug"
                    id="drug"
                    value={this.state.medication.drug.name}
                    disabled="true"
                  />
                </Form.Field>
              </Segment>
              <Segment>
                <Header>
                  {t("MEDICATION_CREATE_TIME_PERIOD_SECTION_HEADER")}
                </Header>

                <Form.Input
                  label={t("MEDICATION_CREATE_START_DATE_LABEL")}
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={this.state.medication.startDate}
                  disabled="true"
                />

                <Form.Field>
                  <label>{t("MEDICATION_CREATE_ONGOING_LABEL")}</label>
                  <Form.Group inline>
                    <Form.Input
                      type="checkbox"
                      id="ongoing"
                      name="ongoing"
                      onChange={this.checkboxChanged}
                      value={this.state.ongoing}
                      checked={this.state.ongoing}
                    />
                  </Form.Group>
                </Form.Field>

                {!this.state.ongoing && (
                  <Form.Input
                    label={t("MEDICATION_CREATE_END_DATE_LABEL")}
                    type="date"
                    name="endDate"
                    id="endDate"
                    onChange={this.inputChanged}
                    value={this.state.endDate}
                  />
                )}
              </Segment>

              <Segment>
                <Header>{t("MEDICATION_CREATE_DOSAGE_SECTION_HEADER")}</Header>

                <Form.Input
                  label={t("MEDICATION_DOSAGE_AMOUNT")}
                  type="number"
                  step="0.01"
                  min="0.01"
                  max="1000"
                  name="dosageAmount"
                  id="dosageAmount"
                  value={this.state.medication.dosageAmount}
                  disabled="true"
                />

                <Form.Input
                  label={t("MEDICATION_DOSAGE_UNIT")}
                  type="text"
                  name="dosageUnit"
                  id="dosageUnit"
                  value={
                    this.state.medication.dosageUnit
                      ? t(
                          "MEDICATION_DOSAGE_UNIT_" +
                            this.state.medication.dosageUnit
                        )
                      : ""
                  }
                  disabled="true"
                />

                <Form.Input
                  label={t("MEDICATION_DOSAGE_ROUTE")}
                  type="text"
                  name="dosageRoute"
                  id="dosageRoute"
                  value={
                    this.state.medication.dosageRoute
                      ? t(
                          "MEDICATION_DOSAGE_ROUTE_" +
                            this.state.medication.dosageRoute
                        )
                      : ""
                  }
                  disabled="true"
                />

                <Form.Input
                  label={t("MEDICATION_DOSAGE_FREQUENCY")}
                  type="text"
                  name="dosageFrequency"
                  id="dosageFrequency"
                  value={
                    this.state.medication.dosageFrequency
                      ? t(
                          "MEDICATION_DOSAGE_FREQUENCY_" +
                            this.state.medication.dosageFrequency
                        )
                      : ""
                  }
                  disabled="true"
                />

                {this.state.medication.dosageFrequency === "REGULAR" &&
                  slotCheckboxes}
              </Segment>

              {this.state.dosageFrequency === "REGULAR" && (
                <Segment>
                  <Header>{t("MEDICATION_CREATE_CYCLE_SECTION_HEADER")}</Header>

                  <Form.Input
                    label={t("MEDICATION_CREATE_DAYS_ON")}
                    type="number"
                    step="1"
                    min="1"
                    max="365"
                    name="daysOn"
                    id="daysOn"
                    value={this.state.medication.daysOn}
                    disabled="true"
                  />

                  <Form.Input
                    label={t("MEDICATION_CREATE_DAYS_OFF")}
                    type="number"
                    step="1"
                    min="0"
                    max="365"
                    name="daysOff"
                    id="daysOff"
                    value={this.state.medication.daysOff}
                    disabled="true"
                  />
                </Segment>
              )}

              <Segment>
                <Header>
                  {t("MEDICATION_CREATE_COMPLIANCE_SECTION_HEADER")}
                </Header>

                <Form.Input
                  label={t("MEDICATION_COMPLIANCE")}
                  type="number"
                  step="0.01"
                  min="0"
                  max="100"
                  name="baselineCompliance"
                  id="baselineCompliance"
                  onChange={this.inputChanged}
                  value={this.state.baselineCompliance}
                />
              </Segment>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column>
                <Button type="submit" loading={this.state.submitting} primary>
                  {t("GLOBAL_BUTTON_SAVE")}
                </Button>
                {this.props.onCancel && (
                  <Button primary basic type="button" onClick={this.onCancel}>
                    {t("GLOBAL_BUTTON_CANCEL")}
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Page>
    );
  }
}

export default withTranslation()(SubjectMedicationEditPage);
