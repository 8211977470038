import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Loader, Modal, Table } from "semantic-ui-react";
import TriggerService from "../../services/admin/TriggerService";
import AdminTriggerEditComponent from "./AdminTriggerEditComponent";

function AdminTriggerViewModal({ t, isOpen, setIsOpen, triggerId }) {
  const [triggerData, setTriggerData] = useState(null);

  const getTriggerData = async () => {
    const triggerData = await TriggerService.getTriggerData(triggerId);
    setTriggerData(triggerData);
  };

  useEffect(() => {
    if (triggerId) {
      getTriggerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerId]);

  const { trigger, triggerOccurrence } = triggerData || {};
  const hasTriggerOccured =
    Array.isArray(triggerOccurrence) && triggerOccurrence.length > 0;

  const createTable = (triggerOccurrence) => {
    if (!triggerOccurrence) {
      return;
    }

    const headers = [
      {
        title: ["ADMIN_TRIGGER_OCCURRENCE_SUBJECT", "Subject"],
      },
      {
        title: ["ADMIN_TRIGGER_OCCURENCE_DATE", "Date of Latest Trigger"],
      },
      {
        title: ["ADMIN_TRIGGER_OCCURENCE_NUMBER", "Times fired"],
      },
    ];

    const headerRow = (
      <Table.Header>
        {headers.map((header) => (
          <Table.HeaderCell>{t(header.title)}</Table.HeaderCell>
        ))}
      </Table.Header>
    );
    const triggerRows = triggerOccurrence.map((tO) => (
      <Table.Row>
        <Table.Cell>{tO.subject.subjectCode} ({tO.subject.Id})</Table.Cell>
        <Table.Cell>{tO.triggerOccurrence?.lastModified}</Table.Cell>
        <Table.Cell>{tO.triggerOccurrence?.occurrences}</Table.Cell>
      </Table.Row>
    ));
    return (
      <>
        {headerRow}
        {triggerRows}
      </>
    );
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Content scrolling>
        <Modal.Description>
          {!triggerData && <Loader />}
          {triggerData && (
            <AdminTriggerEditComponent trigger={trigger} isReadOnly={true} />
          )}
          {hasTriggerOccured && <Table>{createTable(triggerOccurrence)}</Table>}
          {!hasTriggerOccured && (
            <p>
              {t("TRIGGER_OCCURRENCE_EMPTY", "This trigger has not fired.")}
            </p>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default withTranslation()(AdminTriggerViewModal);
