import { Form } from "semantic-ui-react";
import * as PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class PasswordSetterInput extends Component {
  constructor(props) {
    super(props);
    this.state = { password1: "", password2: "" };
    this.rules = Window.configuration.passwords;
  }

  handleChange = (event) => {
    const name = event.target.name;
    this.setState(
      {
        [name]: event.target.value,
      },
      () => {
        this.validateForm(name);
      }
    );
  };

  validateForm = (field) => {
    const { password1, password2 } = this.state;
    if (password1.length < this.rules.minLength) {
      this.props.onError({
        field,
        error: "PASSWORD_CHANGE_NEW_PASSWORD_TOO_SHORT",
      });
    } else if (!this.checkRules(password1)) {
      this.props.onError({
        field,
        error: "PASSWORD_CHANGE_NEW_PASSWORD_RULE_MISMATCH",
      });
    } else if (password1 !== password2) {
      this.props.onError({
        field,
        error: "PASSWORD_CHANGE_NEW_PASSWORD_MUST_MATCH",
      });
    } else {
      this.props.onError({ error: undefined });
      this.props.onValid(password1);
    }
  };

  checkRules = (password) => {
    let lower = 0,
      upper = 0,
      numbers = 0,
      symbols = 0,
      count = 0;
    for (let i = 0; i < password.length; i++) {
      const c = password.charAt(i);
      if (c.match(/[a-z]/g)) {
        if (lower++ === 0) {
          count++;
        }
      } else if (c.match(/[A-Z]/g)) {
        if (upper++ === 0) {
          count++;
        }
      } else if (c.match(/[0-9]/g)) {
        if (numbers++ === 0) {
          count++;
        }
      } else {
        if (symbols++ === 0) {
          count++;
        }
      }
    }
    return (
      password.length >= this.rules.minLength &&
      count >= this.rules.minCharacterTypes
    );
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Form.Input
          value={this.state.password1}
          fluid
          icon="lock"
          iconPosition="left"
          name="password1"
          placeholder={t("CHANGE_PASSWORD_NEW")}
          type="password"
          onChange={this.handleChange}
          // disabled={this.props.disabled}
        />
        <Form.Input
          value={this.state.password2}
          fluid
          icon="lock"
          iconPosition="left"
          name="password2"
          placeholder={t("CHANGE_PASSWORD_REPEAT")}
          type="password"
          onChange={this.handleChange}
          disabled={this.props.disabled}
        />
      </>
    );
  }
}

PasswordSetterInput.propTypes = {
  onValid: PropTypes.func,
  onError: PropTypes.func,
  t: PropTypes.func,
};

PasswordSetterInput.defaultProps = {
  onValid: (v) => v,
  onError: (v) => v,
  t: (v) => v,
};

export default withTranslation()(PasswordSetterInput);
