import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  List,
  ListItem,
  Message,
  Popup,
  PopupContent,
  Segment,
  Table,
} from "semantic-ui-react";
import Page from "../../components/page/Page";
import AuditService from "../../services/AuditService";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import { LOCALDATA_KEYS } from "../../services/LocalDataService";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import moment from "moment";
import DateRangeControls from "../../components/DateRangeControls";
import DateTimeService, { DATE_FORMATS } from "../../services/DateTimeService";
import ConfigContext from "../../context/ConfigContext";
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";
import DataFormatService from "../../services/DataFormatService";
import { usePapaParse } from "react-papaparse";
import { saveAs } from "file-saver";

const AuditSearch = (props) => {
  const { t } = props;
  
  const config = useContext(ConfigContext);

  const defaultSearchCriteriaNumberOfDays =
      config?.audit?.search?.defaultSearchCriteriaNumberOfDays || 7;

  const [hasPermission, setHasPermission] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const { jsonToCSV } = usePapaParse();

  // Search Inputs/Filters
  const [
    searchCriteriaAuditedEntities,
    setSearchCriteriaAuditedEntities,
  ] = useState();
  const defaultSearchCriteria = {
    dateFrom: moment().subtract(defaultSearchCriteriaNumberOfDays, "days").format(DATE_FORMATS.DATE),
    dateTo: moment().subtract().format(DATE_FORMATS.DATE),
    showDetailInResults: false
  };
  const [searchCriteria, setSearchCriteria] = useState(defaultSearchCriteria);

  // Search Results
  const [searchResults, setSearchResults] = useState();
  const [searchResultsHasExceededLimit, setSearchResultsHasExceededLimit] =
    useState(false);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPermisisons = async () => {
    const hasCorrectPermission =
      await PermissionsService.hasPermissionInAnyGroup(
        GroupPermission.SEARCH_AUDIT
      );
    setHasPermission(hasCorrectPermission);
  };

  const setupSearchCriteriaInputs = async () => {
    await setupSearchCriteriaAuditEntityFilter();
  };

  const setupSearchCriteriaAuditEntityFilter = async () => {
    const auditedEntities = await AuditService.getAuditedEntities();
    const searchableAuditedEntities = auditedEntities
      .map((entityName) => {
        const text = t(
          `AUDIT_SEARCH_INPUT_AUDIT_ENTITY_${entityName.toUpperCase()}`,
          entityName
        );
        return {
          key: entityName,
          text: text,
          value: entityName,
        };
      }).sort((a, b) => a.text.localeCompare(b.text))
    setSearchCriteriaAuditedEntities(searchableAuditedEntities);
  };

  const init = async () => {
    await checkPermisisons();
    await setupSearchCriteriaInputs();
    const persistedCriteria = LocalStorageHelper.getJsonObject(
      LOCALDATA_KEYS.AUDIT_SEARCH_CRITERIA
    );
    if (!_.isEmpty(persistedCriteria)) {
      setSearchCriteria(persistedCriteria);
    }
    setIsLoading(false);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    performSearch();
  };

  const onResetSearchCriteria = (e) => {
    e.preventDefault();
    setSearchCriteria(defaultSearchCriteria);
    setErrorMessage();
    setSearchResults();
    setSearchResultsHasExceededLimit(false);
    LocalStorageHelper.setJson(
      LOCALDATA_KEYS.AUDIT_SEARCH_CRITERIA,
      searchCriteria
    );
  };

  const initSearchState = async () => {
    setIsLoading(true);
    setErrorMessage();
    setSearchResultsHasExceededLimit(false);
    setSearchResults();
  };

  const performSearch = async () => {
    await initSearchState();
    try {
      LocalStorageHelper.setJson(
        LOCALDATA_KEYS.AUDIT_SEARCH_CRITERIA,
        searchCriteria
      );
      const rawResults = await AuditService.getBySearchCriteria(
        searchCriteria
      );
      setSearchResultsHasExceededLimit(rawResults?.hasExceededLimit);
      const transFormedResults = rawResults.auditEntries;
      setSearchResults(transFormedResults);
    } catch (error) {
      setErrorMessage(error?.message ? error.message : error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAuditCount = () => {
    const count = searchResults?.length;
    return count;
  };

  const buildSearchCriteriaFilterInputs = () => {
    return (
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column width={6}>
            <label>
              {t(
                "AUDIT_SEARCH_INPUT_AUDIT_ENTITY",
                "Entity"
              )}
            </label>
            <Dropdown
              placeholder={t(
                [
                  "AUDIT_SEARCH_INPUT_AUDIT_ENTITY_PLACEHOLDER",
                  "AUDIT_SEARCH_INPUT_AUDIT_ENTITY",
                ],
                "Entity"
              )}
              fluid
              selection
              options={searchCriteriaAuditedEntities}
              multiple={true}
              onChange={(_e, data) => {
                const newSearchCriteria = { ...searchCriteria };
                newSearchCriteria.entityTypes = data.value;
                setSearchCriteria(newSearchCriteria);
              }}
              value={searchCriteria?.entityTypes || []}
            />
          </Grid.Column>
          
          <Grid.Column width={6}>
            <label>
              {t("AUDIT_SEARCH_INPUT_AUDIT_DATERANGE", "Date Range")}
            </label>
            <DateRangeControls
              disableFormSubmit={true}
              notifyParentOnLoad={false}
              onChange={(startDate, endDate) => {
                const newSearchCriteria = { ...searchCriteria };
                newSearchCriteria.dateFrom = startDate;
                newSearchCriteria.dateTo = endDate;
                setSearchCriteria(newSearchCriteria);
              }}
              start={moment(searchCriteria.dateFrom)}
              end={moment(searchCriteria.dateTo)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form onSubmit={onFormSubmit} className={null}>
              <Button primary disabled={isLoading} type="submit">
                {t("AUDIT_SEARCH_BUTTON", "Search")}
              </Button>
              <Button secondary disabled={isLoading} onClick={onResetSearchCriteria}>
                {t("AUDIT_RESET_BUTTON", "Reset search")}
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const buildSearchResultsTable = () => {
    return (
      <div style={{overflowX: "auto", paddingTop: 10}}>
        <Table id={"auditDataTable"} selectable columns={12}>
          <Table.Header>
            <Table.Row key={"header"}>
              <Table.HeaderCell width={1} key={"header_entity"}>
                {t("AUDIT_SEARCH_RESULTS_TABLE_HEADER_ENTITY", "Entity")}
              </Table.HeaderCell>
              <Table.HeaderCell width={1} key={"header_entityId"}>
                {t("AUDIT_SEARCH_RESULTS_TABLE_HEADER_ENTITY_ID", "Entity ID")}
              </Table.HeaderCell>
              <Table.HeaderCell width={1} key={"header_when"}>
                {t("AUDIT_SEARCH_RESULTS_TABLE_HEADER_WHEN", "When")}
              </Table.HeaderCell>
              <Table.HeaderCell width={1} key={"header_user"}>
                {t("AUDIT_SEARCH_RESULTS_TABLE_HEADER_USER", "User")}
              </Table.HeaderCell>
              <Table.HeaderCell width={1} key={"header_action"}>
                {t("AUDIT_SEARCH_RESULTS_TABLE_HEADER_ACTION", "Action")}
              </Table.HeaderCell>
              <Table.HeaderCell width={2} key={"header_summary"}>
                {t("AUDIT_SEARCH_RESULTS_TABLE_HEADER_SUMMARY", "Summary")}
              </Table.HeaderCell>
              <Table.HeaderCell width={1} key={"header_subjectCode"}>
                {t("AUDIT_SEARCH_RESULTS_TABLE_HEADER_SUBJECT_CODE", "Subject Code")}
              </Table.HeaderCell>
              <Table.HeaderCell width={2} key={"header_data"}>
                {t("AUDIT_SEARCH_RESULTS_TABLE_HEADER_DATA", "Data")}
              </Table.HeaderCell>
              <Table.HeaderCell width={2} key={"header_reason"}>
                {t("AUDIT_SEARCH_RESULTS_TABLE_HEADER_REASON", "Reason")}
              </Table.HeaderCell>            
            </Table.Row>
          </Table.Header>
          <Table.Body
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {searchResults &&
              searchResults.map((auditEntry, index) =>
                buildSearchResultsTableDataRow(auditEntry, index)
              )}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const isJson = (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch {
      return false;
    }
  };

  const buildDataColumnValue = (auditEntry) => {
    let dataColumnValue = auditEntry.reason;
    if (auditEntry.data != null && auditEntry.data !== "" && auditEntry.data !== "NA") {
      let outputElement;

      const isValidJson = isJson(auditEntry.data);
      if (isValidJson) {
        const dataObject = JSON.parse(auditEntry.data);
        const dataItemList = Object.keys(dataObject).map((key) => {
          const val = dataObject[key] || "NA";
          const text = `${key}: ${val}`;
          return <ListItem key={key}>{text}</ListItem>;
        });
        outputElement = <List>{dataItemList}</List>;
      } else {
        outputElement = auditEntry.data;
      }

      dataColumnValue = (
        <Popup
          on="click"
          pinned
          trigger={
            <Button size={"tiny"}>{t("AUDIT_SEARCH_RESULTS_TABLE_DATA_VIEW", "View data")}</Button>
          }
        >
          <PopupContent>{outputElement}</PopupContent>
        </Popup>
      );
    }
    return dataColumnValue;
  }

  const parseFeedbackValue = (rawValue) => {
    let parsedFeedback = rawValue;
    // This arrives with us in a format where it is escaped for quotes and wrapped in quotes,
    //  this is how it is in the DB, so we will parse it here!
    // This relates to AT-1099 and the escaping is quite extensive. So this is not infallible
    parsedFeedback = parsedFeedback.replaceAll("\"\"", "\"");
    if (parsedFeedback.startsWith("\"")) {
      parsedFeedback = parsedFeedback.substr(1);
    }
    if (parsedFeedback.endsWith("\"")) {
      parsedFeedback = parsedFeedback.substr(0, parsedFeedback.length - 1);
    }
    return parsedFeedback;
  }

  const onDownloadAsCsvClick = async () => {
    const rawTableData = DataFormatService.getTableData("auditDataTable");
    const results = jsonToCSV(rawTableData);
    const csvBlobData = DataFormatService.buildCsvBlob(results);

    const dateTimeWithMillis = DateTimeService.now.asString();
    const filename = `audit-search-results-${dateTimeWithMillis}.csv`;
    saveAs(csvBlobData, filename);
  };

  const onDownloadAsExcelClick = async () => {
    const blobData = await AuditService.getBySearchCriteria(
      searchCriteria,
      "xls"
    );

    const dateTimeWithMillis = DateTimeService.now.asString();
    const filename = `audit-search-results-${dateTimeWithMillis}.xls`;
    saveAs(blobData, filename);
  };

  const buildReasonColumnValue = (auditEntry) => {
    let reasonColumnValue = auditEntry.reason;
    if (auditEntry.reason != null && auditEntry.reason !== "" && auditEntry.reason !== "NA") { 
      const parsedReason = parseFeedbackValue(auditEntry.reason);
      
      let popupContent;
      
      try {
        const reasonObject = JSON.parse(parsedReason);
        popupContent = Object.keys(reasonObject).map((key) => {
          const val = reasonObject[key] || "NA";
          const text = `${key}: ${val}`;
          return <ListItem key={key}>{text}</ListItem>;
        });
      } catch (error) {
        // The feedback is overly escaped and causes issues. This is a quick work around to show something and not crash the screen - we need to look at the escaping of this when it's inserted.
        console.log('buildReasonColumnValue parsing reason Error', error);
        if (parsedReason != null && typeof parsedReason === 'string') {
          popupContent = `Raw Value: ${parsedReason}`;
        }        
      }

      if (popupContent != null) {
        reasonColumnValue = (
          <Popup
            on="click"
            pinned
            trigger={
              <Button size={"mini"}>{t("AUDIT_SEARCH_RESULTS_TABLE_REASON_VIEW", "View reason")}</Button>
            }
          >
            <PopupContent><List>{popupContent}</List></PopupContent>
          </Popup>
        );
      }
    }
    return reasonColumnValue;
  }

  const buildSearchResultsTableDataRow = (auditEntry, index) => {
    const dataColumnValue = buildDataColumnValue(auditEntry);
    const reasonColumnValue = buildReasonColumnValue(auditEntry);

    return (
      <Table.Row key={index}>
        <Table.Cell width={1} key={index + "_entity"}>
          {auditEntry.entityType}
        </Table.Cell>
        <Table.Cell width={1} key={index + "_entityId"}>
          {auditEntry.entityId} 
        </Table.Cell>
        <Table.Cell width={1} key={index + "_when"}>
          {DateTimeService.build.asDisplayDateTime(
            auditEntry.when
          )}
        </Table.Cell>
        <Table.Cell width={1} key={index + "_user"}>
          {auditEntry.user}
        </Table.Cell>
        <Table.Cell width={1} key={index + "_action"}>
          {auditEntry.auditAction}
        </Table.Cell>
        <Table.Cell width={2} key={index + "_summary"}>
          {auditEntry.summary}
        </Table.Cell>
        <Table.Cell width={1} key={index + "_subjectCode"}>
          {auditEntry.subjectCode}
        </Table.Cell>
        <Table.Cell width={2} key={index + "_data"} data-primitive-value={auditEntry.data}>
          {searchCriteria.showDetailInResults && auditEntry.data}
          {!searchCriteria.showDetailInResults && dataColumnValue}
        </Table.Cell>
        <Table.Cell width={2} key={index + "_reason"} data-primitive-value={auditEntry.reason}>
          {searchCriteria.showDetailInResults && auditEntry.reason}
          {!searchCriteria.showDetailInResults && reasonColumnValue}
        </Table.Cell>
      </Table.Row>
    );
  };

  if (hasPermission === false) {
    return <Redirect to="/" />;
  }

  const shouldShowExportControls = (getAuditCount() != null && getAuditCount() > 0);

  return (
    <Page
      name="AUDIT_SEARCH_PAGE"
      header={t("AUDIT_SEARCH_TITLE", "Audit Search")}
    >
      {errorMessage && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={
            "Fatal error, if it persists contact support: " + errorMessage
          }
        />
      )}
      {buildSearchCriteriaFilterInputs()}
      {searchResultsHasExceededLimit && (
        <Message
          warning
          header={t("GLOBAL_INFO_TITLE", "Warning")}
          content={t(
            "AUDIT_SEARCH_RESULTS_EXCEEDED_LIMIT",
            "Your search returned a lot of results, it is advisable to add more filters to reduce the results."
          )}
        />
      )}
      {getAuditCount() !== undefined && (
        <Segment>
          {t("AUDIT_SEARCH_RESULTS_ITEM_COUNT", "Count")}:{" "}
          {getAuditCount()}
        </Segment>
      )}
      {buildSearchResultsTable()}

      <div style={{marginTop: 20}}>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column width={4}>
              <AparitoSwitch
                checked={searchCriteria.showDetailInResults}
                onChange={() => {
                  const newSearchCriteria = { ...searchCriteria };
                  newSearchCriteria.showDetailInResults = !searchCriteria.showDetailInResults;
                  setSearchCriteria(newSearchCriteria);
                }}
                label={t("AUDIT_SEARCH_RESULTS_SHOW_DETAILS_IN_TABLE", "Show details inline in table?")}
              />
            </Grid.Column>
            {shouldShowExportControls && (
              <Grid.Column width={8}>  
                <Button
                  primary
                  icon
                  labelPosition="left"
                  onClick={onDownloadAsCsvClick}
                >
                  {t("EXPORT_TABLE", "Export Table as CSV")}
                  <Icon name="table" />
                </Button>
                <Button
                  primary
                  icon
                  labelPosition="left"
                  onClick={onDownloadAsExcelClick}
                >
                  {t("EXPORT_TABLE_XLS", "Export Table as Excel")}
                  <Icon name="table" />
                </Button>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    </Page>
  );
};

const enhance = compose(withTranslation());

export default enhance(AuditSearch);
