import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import {Button, Icon} from "semantic-ui-react";

import VideoCallParticipant from './VideoCallParticipant';

const VideoCallRoom = (props) => {
  const { 
    room, 
    leaveRoom,
    t
  } = props;

  const defaultParticipants = room.participants.values();

  const [participants, setParticipants] = useState(Array.from(defaultParticipants));

  const [isCameraHidden, setIsCameraHidden] = useState(false);
  const [isLocalParticipantMuted, setisLocalParticipantMuted] = useState(false);
  
  useEffect(() => {
    room.on('participantConnected', (connectedParticipant) => {
      setParticipants([...participants, connectedParticipant]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCamera = () => {
    if (isCameraHidden) {
      room.localParticipant.videoTracks.forEach(publication => {
        publication.track.enable();
      });
      setIsCameraHidden(false);
    } else {
      room.localParticipant.videoTracks.forEach(publication => {
        publication.track.disable();
      });
      setIsCameraHidden(true);
    }
  };

  const toggleMute = () => {
    if (isLocalParticipantMuted) {
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.enable();
      });
      setisLocalParticipantMuted(false);
    } else {
      room.localParticipant.audioTracks.forEach(publication => {
        publication.track.disable();
      });
      setisLocalParticipantMuted(true);
    }
  };

  const disconnect = () => {
    room.disconnect();
    leaveRoom();
  };

  const localParticipant = <div style={styles.participant}>
    <VideoCallParticipant 
      key={room.localParticipant.identity} 
      participant={room.localParticipant} 
      isLocalParticipant={true} 
      isCameraHidden={isCameraHidden} 
      isLocalParticipantMuted={isLocalParticipantMuted} />

    <div style={styles.localParticipantButtonsContainer}>
      <Button 
        color={isCameraHidden && "red"}
        primary={!isCameraHidden}
        icon
        labelPosition="left"
        onClick={toggleCamera}
      >
        {isCameraHidden ? t('VIDEO_CALL_SHOW_VIDEO') : t('VIDEO_CALL_HIDE_VIDEO')}
        <Icon name="camera" />
      </Button>

      <Button 
        color={isLocalParticipantMuted && "red"} 
        primary={!isLocalParticipantMuted}
        icon
        labelPosition="left"
        onClick={toggleMute}
      >
        {isLocalParticipantMuted ? t('VIDEO_CALL_UNMUTE_AUDIO') : t('VIDEO_CALL_MUTE_AUDIO')}
        <Icon name="microphone" />
      </Button>

      <Button 
        color="red" 
        icon
        labelPosition="left"
        onClick={disconnect}
      >
        {t('VIDEO_CALL_LEAVE_ROOM')}
        <Icon name="x" />
      </Button>
    </div>
  </div>;

  const remoteParticipants = participants.filter(p => p.state !== 'disconnected').map(p => {
    return <div style={styles.participant}>
      <VideoCallParticipant key={p.sid} participant={p} isLocalParticipant={false} />
    </div>;
  });

  return <div style={styles.participantsContainer}>
    {localParticipant}
    {remoteParticipants}
  </div>;

};

const styles = {
  participantsContainer: {
    display: 'flex',
    flexDirection: 'row', 
    flexShrink: 1,
  },
  participant: {
    display: 'flex', 
    flexDirection: 'column',
    padding: 15,
    maxWidth: '50%',
    alignItems: 'stretch',
  },
  localParticipantButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 1,
  },
};

export default withTranslation()(VideoCallRoom);