import React from "react";

import Slider from "./Slider";
import EQ5D from "./EQ5D";
import NRS from "./NRS";

const SliderSwitch = (props) => {
  const { question } = props;

  if (props.displaySliderValueOnly === true) {
    return <div>{props.value}</div>;
  }

  // returning view will allow insection of props
  if (!question?.config?.type) {
    return <Slider {...props} />;
  }

  let component;
  switch (question.config.type) {
    case "NRS":
      component = <NRS {...props} />;
      break;
    case "EQ5D":
      component = <EQ5D {...props} />;
      break;
    default:
      component = <Slider {...props} />;
      break;
  }

  return component;
};

export default SliderSwitch;
