import React, { useState, useContext } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import Page from "../../components/page/Page";
import DashboardContext from "../../context/DashboardContext";
import ThemeService from "../../services/ThemeService";

const SettingsPage = (props) => {
  const { t } = props;

  const {rerenderDashboard} = useContext(DashboardContext);

  const [selectedTheme, setSelectedTheme] = useState(ThemeService.getTheme().key);

  const doSetTheme = (newTheme) => {
    ThemeService.setTheme(newTheme);
    setSelectedTheme(newTheme.key);
    rerenderDashboard();
  }

  const themeOptions = ThemeService.getThemes().map((theme) => {
      const selected = theme.key === selectedTheme;
      const themeSquareStyle = {...styles.themeSquare, ...(selected ? styles.themeSquareSelected : {}), backgroundColor: theme.style.backgroundColor || '#f9f9f9'};
      return (
        <div style={themeSquareStyle} onClick={() => doSetTheme(theme)}>
          <div style={{...styles.themeSquareText, backgroundColor: theme.style.color || '#444444', width: '70%'}} />
          <div style={{...styles.themeSquareText, backgroundColor: theme.style.color || '#444444', width: '50%'}} />
          <div style={{...styles.themeSquareText, backgroundColor: theme.style.color || '#444444', width: '100%'}} />
          <div style={{...styles.themeSquareText, backgroundColor: theme.style.accentColor || '#f8991d', width: '30%'}} />
        </div>
      );
    });

  return (
    <Page
      name="SETTINGS"
      header={t("SETTINGS_HEADER")}
      subheader={t("SETTINGS_SUBHEADER")}
    >
      <Form>
        <Form.Field>
          <label>{t('SETTINGS_THEME_LABEL')}</label>
          <div style={styles.themesContainer}>
            {themeOptions}
          </div>
        </Form.Field>
      </Form>
    </Page>
  );
};

const styles = {
  themeSquare: {
    border: '1px solid darkgrey',
    borderRadius: 5,
    cursor: 'pointer',

    height: 100,
    width: 100,
    margin: 20,
    padding: 15,

    flexDirection: 'column',
  },
  themeSquareSelected: {
    border: '5px solid #F8991D',
    padding: 11,
  },
  themeSquareText: {
    height: 10,
    marginBottom: 10,
  },
  themesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,

    backgroundColor: '#ffffff',
    borderRadius: 25,
    marginTop: 15,
    width: 'min-content',
  }
}

export default withTranslation()(SettingsPage);
