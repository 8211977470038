import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";

import VideoCallTrack from './VideoCallTrack';

const VideoCallParticipant = (props) => {
  const { 
    participant, 
    isLocalParticipant, 
    //isCameraHidden, // need to do something with this, ideally to replace the video feed with an image
    isLocalParticipantMuted,
    t
  } = props;

  const [shouldUpdate, setShouldUpdate] = useState(true);

  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    if (shouldUpdate) {
      const existingPublications = Array.from(participant.tracks.values());
      const existingTracks = existingPublications.map(publication => publication.track);
      const nonNullTracks = existingTracks.filter(track => track !== null);
  
      setTracks(nonNullTracks);
      setShouldUpdate(false);
    }    
  }, [shouldUpdate, participant.tracks]);

  const [isRemoteParticipantMuted, setIsRemoteParticipantMuted] = useState(false);

  useEffect(() => {
    participant.on('trackSubscribed', () => setShouldUpdate(true));
    participant.on('trackUnsubscribed', () => setShouldUpdate(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const participantTitle = (() => {
    let title = participant.identity;
    let isMuted;

    if (isLocalParticipant) {
      title += ` (${t('VIDEO_CALL_YOU')})`;
      isMuted = isLocalParticipantMuted;
    } else {
      isMuted = isRemoteParticipantMuted;
    }

    if (isMuted) {
      title += ` - (${t('VIDEO_CALL_MUTED')})`;
    }

    return <p>{title}</p>
  })();

  return <div>
    {participantTitle}
    {tracks.map(track => {
      const key = track.id ? track.id : track.sid;
      return <VideoCallTrack key={key} track={track} setIsMuted={setIsRemoteParticipantMuted} />
    })}
  </div>;
}

export default withTranslation()(VideoCallParticipant);