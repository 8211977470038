import React, { useState, useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Scatter } from "react-chartjs-2";
import "chartjs-adapter-moment";

import moment from "moment";
import { withTranslation } from "react-i18next";
import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import { Button, Dropdown } from "semantic-ui-react";
import SubjectService from "../../../../SubjectService";
import ScoringService from "../../../../services/ScoringService";
import ChartService from "../../../../services/ChartService";

const POINTER_TYPES = [
  "circle",
  "triangle",
  "cross",
  "crossRot",
  "rect",
  "rectRounded",
  "rectRot",
  "star",
];

const CHART_TYPES = {
  LINE: "LINE",
  SCATTER: "SCATTER",
};

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const QuestionChart = (props) => {
  const { t, questionnaires, definition, isNull, isUsingTotal, subjectId } =
    props;
  let [chartType, setChartType] = useState(CHART_TYPES.LINE);
  let [RGBArray, setRGBArray] = useState([]);
  let chartRef = useRef(null);

  let subjectData;
  SubjectService.getSubjectData(subjectId).then((data) => {
    subjectData = data;
  });

  const dates = questionnaires.map((questionnaire) => {
    return moment(questionnaire.completionDate).valueOf();
  });

  const rangeMin = moment(Math.min(...dates));
  const rangeMax = moment(Math.max(...dates));

  let diff = rangeMax.diff(rangeMin, "day");
  let unit = "hour";
  if (diff === 0) {
    diff = rangeMax.diff(rangeMin, "hour");
    if (diff < 2) {
      unit = "minute";
    }
  }
  if (diff > 2) {
    unit = "day";
  }
  if (diff > 30) {
    unit = "week";
  }
  if (diff > 60) {
    unit = "month";
  }

  const totalScore = questionnaires.map((questionnaire) => {
    return questionnaire.totalScore;
  });

  const shouldDisplay = (question) => {
    if ( typeof (question?.config.showOnDashboard) != "undefined") {
      return question?.config.showOnDashboard;
    }

    if (question.hidden) {
      return false;
    }

    switch (question.type) {
      case QUESTION_TYPES.HEADING:
      case QUESTION_TYPES.PARAGRAPH:
      case QUESTION_TYPES.MARKUP:
      case QUESTION_TYPES.RENDER_IMAGE:
      case QUESTION_TYPES.RENDER_VIDEO:
        return false;
      default:
        return true;
    }
  };

  let dataSets = [];
  if (definition.questions) {
    definition.questions.filter(shouldDisplay).forEach((question, index) => {
      if (isNull[index]) {
        return;
      }

      if (
        question.type !== QUESTION_TYPES.INTEGER &&
        question.type !== QUESTION_TYPES.DECIMAL &&
        question.type !== QUESTION_TYPES.FIXED_VALUE &&
        question.type !== QUESTION_TYPES.SLIDER
      ) {
        return;
      }

      let r, g, b;

      if (RGBArray[index]) {
        r = RGBArray[index].r;
        g = RGBArray[index].g;
        b = RGBArray[index].b;
      } else {
        setRGBArray((PrevRGBArray) => {
          let nextRGBArray = [...PrevRGBArray];
          nextRGBArray[index] = {
            r: Math.trunc(Math.random() * 255),
            g: Math.trunc(Math.random() * 255),
            b: Math.trunc(Math.random() * 255),
          };
          return nextRGBArray;
        });
      }

      let dataSet = {
        label: question.label,
        pointStyle: POINTER_TYPES[index % POINTER_TYPES.length],
        borderColor: "rgb(" + r + "," + g + "," + b + ")",
        backgroundColor: "rgb(" + r + "," + g + "," + b + ")",
        fill: "none",
        pointRadius: 4,
        pointHoverRadius: 6,
        data: dates.map((date, index) => {
          if (question.type === QUESTION_TYPES.FIXED_VALUE) {
            const answers = questionnaires[index][question.code];
            let score = null;
            question.answers.forEach((answer) => {
              if (
                typeof answers === "object" &&
                answers.includes(answer.code)
              ) {
                if ("score" in answer) {
                  score = score === null ? answer.score : score + answer.score;
                }
              }
            });

            return {
              x: date,
              y: ScoringService.calculateAnswerScore(question, score),
            };
          } else {
            return {
              x: date,
              y: ScoringService.calculateAnswerScore(
                question,
                questionnaires[index][question.code]
              ),
            };
          }
        }),
      };
      dataSets.push(dataSet);
    });
  }

  if (isUsingTotal) {
    const totalScoreDataSet = {
      label: t("TOTAL_SCORE"),
      backgroundColor: "#F8991D",
      borderColor: "#F8991D",
      pointRadius: 4,
      pointHoverRadius: 6,
      fill: "none",
      borderWidth: 2,
      data: dates.map((date, index) => {
        return { x: moment(date), y: totalScore[index] };
      }),
    };

    dataSets.push(totalScoreDataSet);
  }

  let completionDateLabel = t("SUBJECT_QUESTIONNAIRE_META_COMPLETED");
  if (completionDateLabel.charAt(completionDateLabel.length - 1) === ":") {
    completionDateLabel = completionDateLabel.substr(
      0,
      completionDateLabel.length - 1
    );
  }

  const exportFunction = () => {
    const fileName =
      subjectData.subjectCode +
      "-" +
      definition.label +
      "-[" +
      rangeMin.format("L") +
      "-" +
      rangeMax.format("L") +
      "].png";
    ChartService.questionChartExport(document, chartRef.current, fileName);
  };

  const chartOptions = [
    {
      key: CHART_TYPES.LINE,
      text: t("CHART_LINE"),
      value: CHART_TYPES.LINE,
    },
    {
      key: CHART_TYPES.SCATTER,
      text: t("CHART_SCATTER"),
      value: CHART_TYPES.SCATTER,
    },
  ];

  let options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: unit,
          displayFormats: {
            second: "LTS",
            minute: "LT",
            hour: "LT",
            day: "ll",
            week: "ll",
            month: "ll",
            quarter: "ll",
            year: "YYYY",
          },
        },

        scaleLabel: {
          display: true,
          labelString: completionDateLabel,
        },
        ticks: {
          suggestedMin: rangeMin,
          suggestedMax: rangeMax,
        },
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const date = moment(tooltipItem.xLabel).format("LLLL");
          return "[" + date + "]:  " + tooltipItem.yLabel;
        },
      },
    },
  };

  const data = {
    datasets: dataSets,
  };

  const onLegendItemToggle = (shouldBeHidden, index) => {
    const chartInstance = chartRef.current.chartInstance;
    chartInstance.getDatasetMeta(index).hidden = shouldBeHidden;
    chartInstance.update();
  };

  const legendItems = chartRef.current?.chartInstance?.legend?.legendItems.map(
    (item, index) => (
      <LegendItem
        index={index}
        text={item.text}
        fillStyle={item.fillStyle}
        onToggle={onLegendItemToggle}
      />
    )
  );

  const legend = (
    <div style={{ flexDirection: "column", alignItems: "flex-start" }}>
      {legendItems}
    </div>
  );

  return (
    <>
      <div style={{ width: "100%" }}>
        {chartType === CHART_TYPES.LINE && (
          <Line data={data} options={options} ref={chartRef} />
        )}
        {chartType === CHART_TYPES.SCATTER && (
          <Scatter data={data} options={options} ref={chartRef} />
        )}
        {legend}
      </div>
      <div
        style={{
          padding: "12px 0",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Dropdown
          placeholder={t("CHART_LINE")}
          selection
          onChange={(e, obj) => {
            setChartType(obj.value);
          }}
          options={chartOptions}
        />
        <div>
          <Button
            primary
            onClick={() => {
              exportFunction();
            }}
          >
            {t("CHART_EXPORT")}
          </Button>
        </div>
      </div>
    </>
  );
};

const LegendItem = ({ index, text, fillStyle, onToggle }) => {
  const [hidden, setHidden] = useState(false);

  const toggleHidden = () => {
    onToggle(!hidden, index);
    setHidden(!hidden);
  };

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          minHeight: 30,
          alignItems: "center",
          cursor: "pointer",
          opacity: hidden ? "0.5" : 1,
        }}
        onClick={toggleHidden}
      >
        <div
          style={{
            display: "inline-block",
            minHeight: 30,
            minWidth: 30,
            backgroundColor: fillStyle,
          }}
        />
        <div
          style={{ marginLeft: 10, fontStyle: hidden ? "italic" : "normal" }}
        >
          {text}
        </div>
      </div>
      <hr />
    </>
  );
};

export default withTranslation()(QuestionChart);
