import React, {useState} from "react";
import { withTranslation } from "react-i18next";
import { downloadForTranslationNoDuplicatesSingleFile } from "../../services/admin/AdminTranslationService"
import { saveAs } from 'file-saver'
import {
    Button,
    Form,
    Grid, Image, Message, Modal
} from "semantic-ui-react";

const DownloadTranslationsFiltersModal = ({open, onClose, t, language, trial}) => {
    const [filters, setFilters] = useState("")
    const [error, setError] = useState("")

    const doDownload = async () => {

        console.log(`Filters selected: ${filters}`)
        try{
            const blob = await downloadForTranslationNoDuplicatesSingleFile(language, filters)
            saveAs(blob, trial.name + "-atom5-to-translate-no-dups-" + language + "-" + new Date().toISOString().slice(0, 10) + ".csv");

        }catch (e) {
            console.error("Error downloading file",e)
            setError(e)
        }
        setTimeout(() => {
            onClose()
        }, 3000)
    }



    return (
    <Modal
        size={"large"}
        open={open}
        onClose={onClose}
    >
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <Image
                            style={{ height: "25px" }}
                            src={
                                "../../images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal.Content scrolling>
                {error && error.length>0 &&  <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={error}
                />}
                <Form>

                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION_DOWNLOAD_FILTERS", "Enter comma-separated list of transation prefixes")}</label>
                        <input
                            type="text"
                            onChange={(e) => {
                                e.preventDefault()
                                setFilters(e.target.value)
                            }}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Grid centered padded>
                <Grid.Row>

                    <Button
                        color={"orange"}
                        size="small"
                        onClick={() => doDownload()}
                    >
                        {t("GLOBAL_BUTTON_DOWNLOAD", "Download")}
                    </Button>

                    <Button
                        basic
                        color={"orange"}
                        size="small"
                        onClick={onClose}
                    >
                        {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                    </Button>
                </Grid.Row>
            </Grid>

    </Modal>)
}

export default withTranslation()(DownloadTranslationsFiltersModal);
