import React from "react";
import { Loader } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class DigitalSignatureReturnPage extends React.Component {
  render() {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader active={true} inline={true} size={"massive"} />
      </div>
    );
  }
}

export default withTranslation()(DigitalSignatureReturnPage);
