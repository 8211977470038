const DataFormatService = {
  getTableData: (tableId) => {
    const table = document.getElementById(tableId);
    if (table) {
      const data = [...table.rows].map((row) =>
        [...row.cells].map((col) => {

          // Find if the column itself has a data attribute 
          //  data-primitive-value={"Hello World"}
          //  which apears as primitiveValue in the element's dataset
          if (("primitiveValue" in col.dataset)) {
            return col.dataset['primitiveValue'];
          }

          // If not, look at elements inside the column, for existing data-question-answer-valu usage
          const dataQuestionAnswerValueElements = col.querySelectorAll(
            "[data-question-answer-value]"
          );
          if (
            dataQuestionAnswerValueElements &&
            dataQuestionAnswerValueElements.length > 0 &&
            dataQuestionAnswerValueElements[0]?.dataset?.questionAnswerValue
          ) {
            return dataQuestionAnswerValueElements[0]?.dataset
              ?.questionAnswerValue;
          }
          return col.innerText;
        })
      );
      return data;
    }
  },
  buildCsvBlob: (csvData) => {
    const csvBlobData = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });
    return csvBlobData;
  },
};

export default DataFormatService;
