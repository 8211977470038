// Update in RolesPermissionsPage when adding to list
const GroupPermission = {
  VIEW_SUBJECT_QUESTIONNAIRES: "VIEW_SUBJECT_QUESTIONNAIRES",
  VIEW_SUBJECT: "VIEW_SUBJECT",
  VIEW_SUBJECT_MEDIA: "VIEW_SUBJECT_MEDIA",
  VIEW_SUBJECT_DATA: "VIEW_SUBJECT_DATA",
  UPDATE_SUBJECT_EMAIL: "UPDATE_SUBJECT_EMAIL",
  UPDATE_SUBJECT_LANGUAGE: "UPDATE_SUBJECT_LANGUAGE",
  UPDATE_SUBJECT_CODE: "UPDATE_SUBJECT_CODE",
  RESET_SUBJECT_PASSWORD: "RESET_SUBJECT_PASSWORD",
  UPDATE_SUBJECT_RECORDS: "UPDATE_SUBJECT_RECORDS",
  UPDATE_SUBJECT_ACTIVE: "UPDATE_SUBJECT_ACTIVE",
  MANAGE_SUBJECT_ALLERGIES: "MANAGE_SUBJECT_ALLERGIES",
  MANAGE_SUBJECT_DEVICES: "MANAGE_SUBJECT_DEVICES",
  MANAGE_SUBJECT_MEDICATION: "MANAGE_SUBJECT_MEDICATION",
  MANAGE_SUBJECT_SCHEDULED_ITEMS: "MANAGE_SUBJECT_SCHEDULED_ITEMS",
  CREATE_SUBJECT: "CREATE_SUBJECT",
  // UPDATE_SUBJECT: "UPDATE_SUBJECT", // This permission exists, but it's just a group of the above
  MODIFY_ACTIVATION_WINDOW: "MODIFY_ACTIVATION_WINDOW",
  MANUAL_QUESTIONNAIRE_DELIVERY: "MANUAL_QUESTIONNAIRE_DELIVERY",
  CREATE_SUBJECT_QUESTIONNAIRES: "CREATE_SUBJECT_QUESTIONNAIRES",
  SUBMIT_SUBJECT_QUESTIONNAIRES: "SUBMIT_SUBJECT_QUESTIONNAIRES",
  MANAGE_SUBJECT_GROUPS: "MANAGE_SUBJECT_GROUPS",
  RECEIVE_ALERTS: "RECEIVE_ALERTS",
  MANAGE_SITE_EXPORTS: "MANAGE_SITE_EXPORTS",
  MANAGE_EXPORTS: "MANAGE_EXPORTS",
  MANAGE_REWARDS: "MANAGE_REWARDS",
  CREATE_STAFF: "CREATE_STAFF",
  CREATE_STAFF_CREATORS: "CREATE_STAFF_CREATORS",
  VIEW_DATA_QUERIES: "VIEW_DATA_QUERIES",
  RAISE_DATA_QUERIES: "RAISE_DATA_QUERIES",
  ANSWER_DATA_QUERIES: "ANSWER_DATA_QUERIES",
  CLOSE_DATA_QUERIES: "CLOSE_DATA_QUERIES",
  MANAGE_GROUPS: "MANAGE_GROUPS",
  UPDATE_GROUP_METADATA: "UPDATE_GROUP_METADATA",
  RANDOMISE_SUBJECT: "RANDOMISE_SUBJECT",
  SEARCH_ATTACHMENTS: "SEARCH_ATTACHMENTS",
  VIEW_NOTIFICATIONS: "VIEW_NOTIFICATIONS",
  VIEW_SITE_DASHBOARD_REPORTS: "VIEW_SITE_DASHBOARD_REPORTS",
  UPDATE_ATTACHMENT_WORKFLOW: "UPDATE_ATTACHMENT_WORKFLOW",
  SEARCH_AUDIT: "SEARCH_AUDIT",
  MANAGE_SCREENSHOTS: "MANAGE_SCREENSHOTS",
  VIEW_BLURRED_MEDIA: "VIEW_BLURRED_MEDIA",
  SUBMIT_BLINDED_QUESTIONNAIRE: "SUBMIT_BLINDED_QUESTIONNAIRE",
  EXPORT_QUESTIONNAIRE_PDF: "EXPORT_QUESTIONNAIRE_PDF",
  BATCH_ATTACHMENTS_VIEW: "BATCH_ATTACHMENTS_VIEW",
	BATCH_ATTACHMENTS_UPLOAD_FILES: "BATCH_ATTACHMENTS_UPLOAD_FILES",
	BATCH_ATTACHMENTS_PROCESS_DATA: "BATCH_ATTACHMENTS_PROCESS_DATA",
  BATCH_ATTACHMENTS_DELETE_FILES: "BATCH_ATTACHMENTS_DELETE_FILES",
  DOWNLOAD_SUBJECT_MEDIA: "DOWNLOAD_SUBJECT_MEDIA",
  ALLOW_SUBJECT_MEDIA_AUDIO: "ALLOW_SUBJECT_MEDIA_AUDIO",
  EDIT_SUBJECT_QUESTIONNAIRES: "EDIT_SUBJECT_QUESTIONNAIRES"
};


export default GroupPermission;
