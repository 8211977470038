import React, { memo } from 'react';
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

const ProgressSlider = ({
  durationMilliseconds,
  currentTimeMilliseconds,
  onProgressChange,
}) => {
  return (
    <>
      <RCSlider
        onChange={onProgressChange}
        min={0}
        max={durationMilliseconds}
        step={1}
        value={currentTimeMilliseconds}
        handleStyle={{
          backgroundColor: '#fff',
          border: '0px solid #fff',
          outline: '0px solid #fff',
          margin: '-4px'
        }}
        trackStyle={{
          backgroundColor: '#f8991d',
          height: '5px',
          cursor: 'pointer'
        }}
        railStyle={{
          backgroundColor: '#adadad',
          height: '5px',
          cursor: 'pointer'
        }}
        dotStyle={{ backgroundColor: '#fff', border: '0' }}
        activeDotStyle={{
          backgroundColor: '#fff',
          border: '0',
        }}
        style={{
          marginLeft: 20,
          marginTop: 2,
          maxWidth: 'calc(100% - 30px)',
          backgroundColor: null,
        }}
      />
    </>
  );
};

export default memo(ProgressSlider);
