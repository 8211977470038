import React, { useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  TextArea,
  Message,
  Dropdown,
} from "semantic-ui-react";
import ConfirmButtonWithFeedback from "../dashboard/ConfirmButtonWithFeedback";
import { QUESTION_DEFINITION_CALCULATED_WHEN, QUESTION_TYPES } from "atom5-branching-questionnaire";
import AceEditor from "react-ace";
import AdminQuestionAnswerComponent from "./AdminQuestionAnswerComponent";

require(`ace-builds/src-noconflict/mode-json`);
require(`ace-builds/src-noconflict/theme-github`);

const TRANSLATION_MAP = {
  INCORRECT_FORMAT: "Incorrect Format",
  NOT_FOUND: "Questionnaire Definition not found",
  PARSE_FAILURE: "JSON failed to parse",
  CODE_ALREADY_USED: "Code already used",
  CODE_EMPTY: "Code required already used",
  INVALID_CHARACTERS: "Code should not contain spaces or underscores.",
};


function AdminQuestionDefinitionEditComponent({
  t,
  questionDefinition,
  serverTranslations,
  isReadOnly,
  isCreating,
  onChange,
  onSubmit,
  isSubmitting,
  validationErrors = [],
}) {
  const getQuestionnaireDefinitionChangeButton = (
    onComplete,
    buttonText,
    isSubmitting,
    isPositive = true
  ) => {
    return (
      <ConfirmButtonWithFeedback
        // onCLick={handleLoad}
        fluid
        buttonText={t(buttonText)}
        headerText={t(buttonText)}
        contentText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PROMPT",
          "Please give a reason why this is being changed and confirm."
        )}
        confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
        cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
        onConfirm={onComplete}
        placeholderText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_PLACEHOLDER_TEXT",
          "Reason"
        )}
        mandatoryValidationText={t(
          "ADMIN_QUESTIONNAIRE_DEFINITION_EDIT_REASON_VALIDATION_TEXT",
          "Please supply a reason for the change."
        )}
        color={!isPositive ? "negative" : "primary"}
        isSubmitting={isSubmitting}
      />
    );
  };



  const isQuestionType = ([key, value]) => typeof value === "string";

  const questionTypeOptions = Object.entries(QUESTION_TYPES)
    .filter(isQuestionType)
    .map(([_key, qT]) => {
      return {
        key: qT,
        text: qT,
        value: qT,
      };
    });

  // condition state may not be needed, it doesnt seem to be used by library
  const [conditions, setConditions] = useState(questionDefinition.conditions);
  const updateConditions = (value) => {
    try {
      let parsedValue = JSON.parse(value);
      setConditions(parsedValue);
      onChange(null, { name: "conditions", value: parsedValue });
    } catch (e) {
      // handle failure
      console.error("failed to parse:", value);
    }
  };

  const createValidationMessageDisplay = (key) => (validationError) => {
    if (validationError.key !== key) {
      return null;
    }

    return (
      <Message negative>
        {t(
          validationError.errorCode,
          TRANSLATION_MAP[validationError.errorCode]
        )}
      </Message>
    );
  };

  const updateAnswerOrder = (reorderedList) => {
    onChange(null, { name: "answers", value: reorderedList });
  };

  const isRenderType =
    questionDefinition.type.includes("RENDER") ||
    questionDefinition.type.includes("MONITORED_VIDEO");

  const getTranslation = (searchString) =>
    serverTranslations.find((sT) => sT.code.includes(searchString));

  const labelTranslation = useMemo(
    () => getTranslation(`questions_${questionDefinition.code}_label`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serverTranslations, questionDefinition.code]
  );

  const urlTranslation = useMemo(
    () => getTranslation(`questions_${questionDefinition.code}_url`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serverTranslations, questionDefinition.code]
  );

  const configTranslation = useMemo(
    () => getTranslation(`questions_${questionDefinition.code}_config`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serverTranslations, questionDefinition.code]
  );

  const minMaxQuestions = [
    QUESTION_TYPES.DATE,
    QUESTION_TYPES.DATETIME,
    QUESTION_TYPES.DECIMAL,
    QUESTION_TYPES.FIXED_VALUE,
    QUESTION_TYPES.INTEGER,
    QUESTION_TYPES.TEXT,
    QUESTION_TYPES.TEXTAREA,
    QUESTION_TYPES.TIME,
    QUESTION_TYPES.SLIDER,
  ];

  const calculatedWhenOptions = Object.entries(QUESTION_DEFINITION_CALCULATED_WHEN)
    .map(([_key, item]) => {
      return {
        key: item.value,
        text: t(item.translationKey, item.fallbackText),
        value: item.value,
      };
    });

  return (
    <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {validationErrors.map(
              createValidationMessageDisplay("questionDefinition")
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <Form.Field>
              <label>{t("QUESTIONNAIRE_DEFINITION_CODE", "Code")}</label>
              <Input
                value={questionDefinition.code}
                name={"code"}
                fluid
                onChange={onChange}
                transparent={!isCreating}
                disabled={!isCreating}
              />
              {validationErrors.map(createValidationMessageDisplay("code"))}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={6}>
            {!isRenderType && (
              <Form.Field>
                <label>{t("QUESTIONNAIRE_DEFINITION_LABEL", "Label")}</label>
                <Input
                  value={labelTranslation?.translation}
                  name={"label"}
                  fluid
                  transparent={true}
                  disabled={true}
                />
              </Form.Field>
            )}
            {isRenderType && (
              <Form.Field>
                <label>{t("QUESTIONNAIRE_DEFINITION_URL", "URL")}</label>
                <Input
                  value={urlTranslation?.translation}
                  name={"url"}
                  fluid
                  transparent={true}
                  disabled={true}
                />
              </Form.Field>
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field>
              <label>{t("QUESTIONNAIRE_DEFINITION_TYPE", "Type")}</label>
              <Dropdown
                fluid
                selection
                options={questionTypeOptions}
                value={questionDefinition.type}
                name={"type"}
                onChange={onChange}
                disabled={isReadOnly}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Field>
              <label>
                {t("QUESTIONNAIRE_DEFINITION_SEQUENCE", "Sequence")}
              </label>
              <Input
                value={questionDefinition.sequence}
                name={"sequence"}
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
              />
              {validationErrors.map(createValidationMessageDisplay("sequence"))}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            {!isReadOnly && 
              getQuestionnaireDefinitionChangeButton(
                onSubmit,
                t("ADMIN_TRIGGER_SAVE", "Save"),
                isSubmitting
              )}
            {isReadOnly && (
              <Button
                primary
                fluid
                size="tiny"
                as={Link}
                to={
                  "/app/utils/question-definitions/edit/" +
                  questionDefinition.id
                }
              >
                {t("ADMIN_TRIGGER_EDIT", "Edit")}
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Field>
              <label>
                {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Hidden?")}
              </label>
              <Checkbox
                checked={questionDefinition?.hidden}
                name={"hidden"}
                fluid
                disabled={isReadOnly}
                onChange={onChange}
              />
              {validationErrors.map(createValidationMessageDisplay("hidden"))}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Field>
              <label>
                {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Calculated?")}
              </label>
              <Checkbox
                checked={questionDefinition?.calculated}
                name={"calculated"}
                fluid
                disabled={isReadOnly}
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("calculated")
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Field>
              <label>
                {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Required?")}
              </label>
              <Checkbox
                checked={questionDefinition?.required}
                name={"required"}
                fluid
                disabled={isReadOnly}
                onChange={onChange}
              />
              {validationErrors.map(createValidationMessageDisplay("required"))}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Field>
              <label>
                {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Score Offset")}
              </label>
              <Input
                value={questionDefinition?.scoreOffset}
                name={"scoreOffset"}
                type="number"
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("scoreOffset")
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field>
              <label>
                {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Score Multiplier")}
              </label>
              <Input
                value={questionDefinition?.scoreMultiplier}
                name={"scoreMultiplier"}
                type="number"
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("scoreMultiplier")
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} />
        </Grid.Row>
        {(questionDefinition.type === QUESTION_TYPES.SINGLE_FIXED_VALUE ||
          questionDefinition.calculated) && (
            <>
              <Grid.Row>
                <Grid.Column width={4} />
                <Grid.Column width={12}>
                  <label>{t("QUESTIONNAIRE_DEFINITION_VALUE", "Value")}</label>
                  <Input
                    value={questionDefinition?.value}
                    name={"value"}
                    fluid
                    transparent={isReadOnly}
                    disabled={isReadOnly}
                    onChange={onChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4} />
                <Grid.Column width={6}>
                  <label>
                    {t("QUESTIONNAIRE_DEFINITION_CALCULATED_WHEN", "Calculated when?")}
                  </label>
                  <Dropdown
                    fluid
                    selection
                    options={calculatedWhenOptions}
                    value={questionDefinition?.calculatedWhen != null ? questionDefinition?.calculatedWhen : QUESTION_DEFINITION_CALCULATED_WHEN.QUESTIONNAIRE_CREATION_AND_SUBMISSION.value}
                    name={"calculatedWhen"}
                    onChange={onChange}
                    disabled={isReadOnly}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        {minMaxQuestions.includes(questionDefinition.type) && (
          <>
            <Grid.Row>
              <Grid.Column width={4} />
              <Grid.Column width={6}>
                <label>{t("QUESTIONNAIRE_DEFINITION_MIN", "Min")}</label>
                <Input
                  value={questionDefinition?.min}
                  name={"min"}
                  type="number"
                  fluid
                  transparent={isReadOnly}
                  disabled={isReadOnly}
                  onChange={onChange}
                />
                {validationErrors.map(createValidationMessageDisplay("min"))}
              </Grid.Column>
              <Grid.Column width={6}>
                <label>{t("QUESTIONNAIRE_DEFINITION_MAX", "Max")}</label>
                <Input
                  value={questionDefinition?.max}
                  name={"max"}
                  type="number"
                  fluid
                  transparent={isReadOnly}
                  disabled={isReadOnly}
                  onChange={onChange}
                />
                {validationErrors.map(createValidationMessageDisplay("max"))}
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {questionDefinition.type === QUESTION_TYPES.FIXED_VALUE && (
          <Grid.Row>
            <Grid.Column width={4} />
            <Grid.Column width={12}>
              <AdminQuestionAnswerComponent
                onChange={updateAnswerOrder}
                questionDefinition={questionDefinition}
                serverTranslations={serverTranslations}
                isReadOnly={isReadOnly}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={12}>
            <h4>{t("QUESTION_DEFINITION_EXPORT_NAME", "Export Column Name")}</h4>
            <Input
                value={questionDefinition?.questionnaireExportHeadingOverride}
                name={"questionnaireExportHeadingOverride"}
                type="text"
                fluid
                transparent={isReadOnly}
                disabled={isReadOnly}
                onChange={onChange}
            />
            {validationErrors.map(createValidationMessageDisplay("questionnaireExportHeadingOverride"))}
          </Grid.Column>
        </Grid.Row>
        {validationErrors.map(createValidationMessageDisplay("answers"))}
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={12}>
            <Form.Field>
              <label>{t("QUESTIONNAIRE_DEFINITION_CONFIG", "Config")}</label>
              <TextArea
                style={{
                  border: 0,
                  padding: 0,
                  wordWrap: "word-break",
                  caretColor: "transparent",
                  backgroundColor: "transparent",
                }}
                value={configTranslation?.translation}
                name={"config"}
                fluid
                transparent={true}
                disabled={false}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={12}>
            <h4>{t("QUESTION_DEFINITION_CONDITIONS", "Conditions")}</h4>
            <AceEditor
              readOnly={isReadOnly}
              mode="json"
              height="100%"
              theme="github"
              onChange={(v) => !isReadOnly && updateConditions(v)}
              name="conditionsEditor"
              editorProps={{ $blockScrolling: true }}
              value={JSON.stringify(conditions, null, "  ")}
              style={{ minHeight: "200px" }}
            />
            {validationErrors.map(createValidationMessageDisplay("conditions"))}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>
    </Form>
  );
}

export default withTranslation()(AdminQuestionDefinitionEditComponent);
