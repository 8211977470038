/* eslint no-unused-vars: 0 */

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DrugService from "../../DrugService";
import Page from "../../components/page/Page";
import DrugForm from "../../components/drug/DrugForm";

class DrugCreatePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      error: null,
    };
  }

  onSubmit = (drug, headers) => {
    return DrugService.createDrug(drug.name, headers)
      .then(() => {
        window.location.href = window.location.origin + "/app/drugs";
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  onCancel = () => {
    window.location.href = window.location.origin + "/app/drugs";
  };

  render() {
    const { t } = this.props;
    const errorMessage = this.state.error
      ? t("DRUG_NAME_EXISTS_ERROR_MESSAGE")
      : undefined;

    return (
      <Page
        name="DRUG_CREATE"
        header={t("DRUG_CREATE_HEADER")}
        subheader={t("DRUG_CREATE_SUBHEADER")}
      >
        <DrugForm
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          drug={this.state.drug}
          error={errorMessage}
        />
      </Page>
    );
  }
}

export default withTranslation()(DrugCreatePage);
