import React from "react";
import { Form } from "semantic-ui-react";

const withContainer = (WrappedComponent) => {
  return (props) => {
    const { question } = props;
    const defintionStyles = question?.config?.style ? question.config.style : {};

    return (
        <Form.Field style={{...{width: "100%", padding: '0.5rem', display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: 'stretch'}, ...defintionStyles.container}}>
          <WrappedComponent {...props}/>
        </Form.Field>
    );
  };
};

export default withContainer;