import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";
import InternationalisationService from "./InternationalisationService";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en_GB",
    debug: false,
    useSuspense: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "{{lng}}",
      parse: function (data) {
        const translations = {};

        // eslint-disable-next-line
        for (let item of data) {
          translations[item.code] = item.translation;
        }

        return translations;
      },
      ajax: function (language, options, callback) {
        InternationalisationService.getTranslations(language).then((data) => {
          callback(data, { status: 200 });
        });
      },
    },
  });

const selectedLanguage = InternationalisationService.getLanguage();
if (selectedLanguage) {
  i18n.changeLanguage(selectedLanguage);
} else {
  const browserLanguage = InternationalisationService.getBrowserLanguage();
  if (browserLanguage) {
    InternationalisationService.changeLanguage(browserLanguage);
  } else {
    InternationalisationService.getLanguages().then((data) => {
      if (data.length > 0) {
        InternationalisationService.changeLanguage(data[0].code);
      }
    });
  }
}

export default i18n;
