const questionnaireFilters = {
  notCompletedFilter: (questionnaire) => {
    return questionnaire?.completionDate === null;
  },
  nonModuleFilter: (definitions, questionnaire) => {
    const definition = definitions.find(
      (def) => def.id === questionnaire.definitionId
    );
    const hasModule =
      definition.config?.modules && definition.config.modules.length > 0;
    return !hasModule;
  },
  moduleFilter: (definitions, questionnaire, moduleCode) => {
    if (questionnaire?.modules?.length > 0) {
      return questionnaire.modules.some(module => module.code === moduleCode);
    }
    const definition = definitions.find(
      (def) => def.id === questionnaire.definitionId
    );
    return definition?.config?.modules?.indexOf(moduleCode) >= 0;
  },
};

export default questionnaireFilters;
