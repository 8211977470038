import React, { useEffect } from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import {
  Header,
  Icon,
  List,
  Message,
  Modal,
  Progress,
} from "semantic-ui-react";
import DateTimeService from "../../services/DateTimeService";
import { SUBJECT_QUESTIONNAIRE_SERVICE_CONSTANTS } from "../../services/SubjectQuestionnaireService";

const DataSubmissionStatusModal = (props) => {
  const { t, dataUploadProgress } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [openTimeMs, setOpenTimeMs] = useState(null);
  const isComplete =
    dataUploadProgress?.total > 0 &&
    dataUploadProgress?.current === dataUploadProgress?.total;
  const failed =
      dataUploadProgress && dataUploadProgress.items &&
      Object.values(dataUploadProgress.items).filter(x=>x.failed).length>0
  useEffect(() => {
    const hasData =
      dataUploadProgress !== null &&
      Object.entries(dataUploadProgress.items).length > 1;
    setIsOpen(hasData);

    if (hasData) {
      setOpenTimeMs(DateTimeService.now.asMs());
    } else {
      setOpenTimeMs(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUploadProgress]);

  useEffect(() => {
    if (isComplete) {
      // This stops a jarring flash on and off of the modal, and makes it remain for minDisplayMs at least
      const minDisplayMs = 1250;
      const actualDisplayMs = DateTimeService.now.asMs() - openTimeMs;
      const timeout =
        actualDisplayMs < minDisplayMs ? minDisplayMs - actualDisplayMs : 0;
      setTimeout(() => {
        setIsOpen(false);
      }, timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplete]);

  if (!isOpen) {
    return null;
  }

  const listItems = Object.entries(dataUploadProgress.items).map((item) => {
    const questionCode = item[0];
    const data = item[1];
    const listItemText =
      questionCode ===
      SUBJECT_QUESTIONNAIRE_SERVICE_CONSTANTS.DATA_UPLOAD_QUESTIONNAIRE_ITEM_KEY
        ? t("DATA_UPLOAD_QUESTIONNAIRE_DATA_TEXT")
        : data.name;
    const listItemStatusIcon = data.uploaded ? "check circle" : "wait outline";
    return (
      <List.Item key={questionCode}>
        <List.Content floated="right"></List.Content>
        <List.Icon
          name={listItemStatusIcon}
          color={failed ? "red" : "orange"}
          size={"big"}
          verticalAlign="middle"
          aria-label={listItemText}
        />
        <List.Content verticalAlign="middle">{listItemText}</List.Content>
      </List.Item>
    );
  });

  return (
    <Modal
      size={"small"}
      open={isOpen}
      closeIcon={failed}
      closeOnDimmerClick={failed}
      closeOnDocumentClick={failed}
      onClose={()=>{
        setIsOpen(false)
      }}
    >
      <Modal.Header style={styles.titleContainer}>
        <Icon
          white
          name={"upload"}
          size={"large"}
          aria-label={t("DATA_UPLOAD_STATUS_HEADING")}
          style={styles.titleIcon}
        />
        <Header as="h1" style={styles.title}>
          {t("DATA_UPLOAD_STATUS_HEADING")}
        </Header>
      </Modal.Header>
      <Modal.Content scrolling>
        {failed && (
            <Message error>
              {t("DATA_UPLOAD_FAILED", "Attachment Upload Failed - check the file type or scan it for viruses")}
            </Message>
        )}
        {!failed && !isComplete && (
          <Message warning>
            {t("DATA_UPLOAD_STATUS_DO_NOT_CLOSE_WINDOW_WARNING")}
          </Message>
        )}
        <List divided relaxed style={styles.list}>
          {listItems}
        </List>
        <Header as="h3">{t("DATA_UPLOAD_STATUS_PROGRESS")}</Header>
        <Progress
          style={styles.progress}
          success={isComplete}
          value={dataUploadProgress?.current}
          total={dataUploadProgress?.total}
        />
      </Modal.Content>
    </Modal>
  );
};

const styles = {
  titleContainer: {
    backgroundColor: "#f8991d",
  },
  title: {
    color: "#ffffff",
    display: "inline",
  },
  titleIcon: {
    float: "right",
    color: "#ffffff",
  },
  list: {
    marginTop: 25,
  },
};

const enhance = compose(withTranslation());

export default enhance(DataSubmissionStatusModal);
