import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import questionnaireDefinitions from "./questionnaires/questionnaireDefinitionsSlice";
import subjectQuestionnaires from "./questionnaires/subjectQuestionnairesSlice";
import currentQuestionnaireSlice from "./questionnaires/currentQuestionnaireSlice";
import notifications from "./notificationsSlice";

const store = configureStore({
  reducer: combineReducers({
    questionnaireDefinitions: questionnaireDefinitions.reducer,
    currentQuestionnaire: currentQuestionnaireSlice.reducer,
    subjectQuestionnaires: subjectQuestionnaires.reducer,
    notifications: notifications.reducer,
  }),
});

export default store;
