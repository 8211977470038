import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import {FixedValueWithoutWrapping} from "./FixedValue";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";
import withError from "./hocs/withError";

const SingleFixedValue = ({
  question,
  subtype,
  value,
  changeValue,
  error,
  label,
  isReadOnly,
}) => {
  const fixedValueQuestion = {
    ...question,
    answers: [
      {
        code: question.value,
        label: label,
      },
    ],
  };

  const { t } = useTranslation();

  return (
    <>
      {!isReadOnly ? (
        <FixedValueWithoutWrapping
          question={fixedValueQuestion}
          subtype={subtype}
          value={value ? [value] : []}
          changeValue={(valueArray) => changeValue(valueArray[0])}
          error={error}
          isReadOnly={isReadOnly}
          showLabel={false}
        />
      ) : (
        <Form.Input
          data-question-answer-value={
            value ? t("GENERIC_YES") : t("GENERIC_NO")
          }
          name={question.code}
          id={question.code}
          value={value ? t("GENERIC_YES") : t("GENERIC_NO")}
          disabled={isReadOnly}
        />
      )}
    </>
  );
};

export default withContainer(withLabel(withError(SingleFixedValue)));
