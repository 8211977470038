import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../../../components/page/Page';
import BatchUploadedFileList from './BatchUploadedFileList';
import GroupPermission from '../../../GroupPermission';
import PermissionsService from '../../../services/PermissionsService';
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const BatchUploadIndex = (props) => {
  const { t } = props;
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    populatePermissions();
  }, []);

  const populatePermissions = async () => {
    const requiredPermissionKeys = [
      GroupPermission.BATCH_ATTACHMENTS_VIEW,
      GroupPermission.BATCH_ATTACHMENTS_UPLOAD_FILES,
      GroupPermission.BATCH_ATTACHMENTS_PROCESS_DATA
    ];

    const result = {};
    for (const permissionKey of requiredPermissionKeys) {
      const hasPermission = await PermissionsService.hasPermissionInAnyGroup(permissionKey);
      result[permissionKey] = hasPermission;
    }

    setPermissions(result);
  };

  if (permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_VIEW] !== true) {
    return <Redirect to='/' />;
  }

  return (
    <Page
      name='BATCHUPLOADINDEX'
      header={t('BATCHUPLOADINDEX_TITLE', 'Attachment Batch Upload')}
    >
      {permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_UPLOAD_FILES] === true && (
        <Button
          as={Link}
          to={'/app/attachments/batchupload/uploadfiles'}
        >
          {t('BATCHUPLOADINDEX_UPLOAD_FILES', 'Upload attachment files')}
        </Button>
      )}
      {permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_PROCESS_DATA] === true && (
        <Button
          as={Link}
          to={'/app/attachments/batchupload/processdata'}
        >
          {t('BATCHUPLOADINDEX_UPLOAD_DATA', 'Import data')}
        </Button>
      )}
      <BatchUploadedFileList />
    </Page>
  );
};

export default withTranslation()(BatchUploadIndex);
