import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import { Button, Header, Icon, Segment, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";

class SubjectViewAllergiesTab extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      hasManageAllergiesPermission: false,
      allergies: [],
    };

    this.reload(false);
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.match.params.subjectId !== this.props.match.params.subjectId
    ) {
      this.reload();
    }
  };

  reload = (pageInitialised = true) => {
    if (pageInitialised) {
      this.setState({ loading: true });
    }

    SubjectService.getAllergyList(this.props.match.params.subjectId)
      .then((allergies) => {
        this.setState({ allergies, loading: false });
      })
      .catch(this.errorHandler);

    PermissionsService.hasPermissionForSubject(this.props.subjectData.groups, GroupPermission.MANAGE_SUBJECT_ALLERGIES)
      .then((hasManageAllergiesPermission) => {
        this.setState({hasManageAllergiesPermission});
      });
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  edit = (allergy) => {
    if (this.state.hasManageAllergiesPermission) {
      this.props.history.push(
        "/app/subject/" +
          this.props.match.params.subjectId +
          "/tabs/allergies/edit/" +
          allergy.id
      );
    }
  };

  delete = (allergy) => {
    SubjectService.deleteAllergy(this.props.match.params.subjectId, allergy.id)
      .then(() => {
        SubjectService.getAllergyList(this.props.match.params.subjectId)
          .then((allergies) => {
            this.setState({ allergies, loading: false });
          })
          .catch(this.errorHandler);
      })
      .catch(this.errorHandler);
  };

  render() {
    const { t } = this.props;

    const allergyRows = this.state.allergies.map((allergy) => (
      <Table.Row key={allergy.id} style={{ cursor: "pointer" }}>
        <Table.Cell textAlign={"left"} onClick={() => this.edit(allergy)}>
          {allergy.name}
        </Table.Cell>
        <Table.Cell textAlign={"right"}>
          {this.state.hasUpdatePermission && (
            <Button
              type="button"
              floated="right"
              size="tiny"
              onClick={() => this.delete(allergy)}
              color="red"
            >
              {t("GLOBAL_BUTTON_REMOVE")}
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <>
        <Segment style={{ overflow: "auto" }}>
          <Header as="h4">{t("KNOWN_ALLERGIES")}</Header>

          <Table selectable columns={8} compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("ALLERGIES_TABLE_HEADER_NAME")}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"}>
                  {this.state.hasManageAllergiesPermission && (
                    <Button
                      floated="right"
                      primary
                      icon
                      as={Link}
                      labelPosition="left"
                      to={
                        "/app/subject/" +
                        this.props.match.params.subjectId +
                        "/tabs/allergies/new"
                      }
                    >
                      {t(["SUBJECT_TAB_ADD_NEW_ALLERGY", "GLOBAL_BUTTON_ADD_NEW"])}
                      <Icon name="syringe" />
                    </Button>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{allergyRows}</Table.Body>
          </Table>
        </Segment>
      </>
    );
  }
}

export default withTranslation()(SubjectViewAllergiesTab);
