import React, { Component } from "react";
import DateTime from "react-datetime";
import moment from "moment";
import InternationalisationService from "../InternationalisationService";
import { Button, Form } from "semantic-ui-react";
import "../react-datetime.css";
import DateTimeService from "../services/DateTimeService";
import { withTranslation } from "react-i18next";

//note: moment and luxon use different case for date formats e.g. dd/MM/yyyy (luxon) vs DD/MM/YYYY (moment)
const MONTH_YEAR_FORMAT_MOMENT = "MM/YYYY";
const DATE_FORMAT_MOMENT = "DD/MM/YYYY";
const TIME_FORMAT_MOMENT = "HH:mm";

class DateTimeField extends Component {
  constructor(props, context) {
    super(props, context);

    const mode = this.props.mode || "datetime";

    const valueFormat =
      (mode !== "time"
        ? ""
        : mode === "monthyear"
        ? MONTH_YEAR_FORMAT_MOMENT
        : DATE_FORMAT_MOMENT) +
      (mode === "datetime" ? " " : "") +
      (mode !== "date" || mode === "monthyear" ? "" : TIME_FORMAT_MOMENT);

    let value;
    if (!this.props.value) {
      value = "";
    }

    if (moment.isMoment(this.props.value)) {
      value = this.props.value;
    }

    if (typeof value === "undefined") {
      value = moment(this.props.value).format(valueFormat);
    }

    this.state = {
      value: value,
      lang: InternationalisationService.getLanguage(),
      showTime: true,
      mode: mode,
      readOnly: this.props.readOnly
    };
  }

  componentDidMount() {
    this.initialiseValue(); 
  }

  initialiseValue() {
    const isDeviceTimestamp = this.props?.config?.type === "DEVICE_TIMESTAMP";
    if (isDeviceTimestamp) {
      const isReadOnly = this.state.readOnly; // Use readOnly to see if questionnaire is completed or not.
      this.setState({readOnly: true});
      if (!isReadOnly) {
        this.handleNow();
      }      
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.value !== this.props.value){
      this.setState({value: this.props.value});
    }
  }

  isValid = (currentDate, selectedDate) => {
    let result = true;
    if (this.props.min) {
      result = moment(currentDate)
        .add(1, "day") // add a day so the min is inclusive
        .isSameOrAfter(moment(this.props.min));
    }
    if (this.props.max) {
      result = moment(currentDate)
        .add(1, "day") // add a day so the max is inclusive (TODO: verify this)
        .isSameOrBefore(moment(this.props.max));
    }
    return result;
  };

  onOpen = () => {
    if (this.state.mode === "datetime") {
      this.setState({ showTime: false });
    }
  };

  onChange = (value) => {
    if (this.state.mode === "datetime") {
      this.setState({ showTime: true });
    }

    if (moment.isMoment(value)) {
      this.setState({ value: value });
      this.props.onChange(value);
    } else {
      // The component is supposedly controlable with state, however its
      // value is actually its own internal state that can update. So, to
      // force re-render the value is nulled and reset when a moment is not
      // used as the value.
      let oldValue = this.state.value;
      this.setState({ value: null }, () => {
        this.setState({ value: oldValue });
      });
      this.props.onChange(oldValue);
    }
  };

  getFormattedValue = () => {
    if (this.state.mode === "date") {
      return DateTimeService.build.asDisplayDate(this.state.value);
    } else if (this.state.mode === "datetime") {
      return DateTimeService.build.asDisplayDateTime(this.state.value);
    } else if (this.state.mode === "monthyear") {
      return DateTimeService.build.asDisplayMonthYear(this.state.value);
    } else if (this.state.mode === "time") {
      return this.state.value.format(TIME_FORMAT_MOMENT);
    }
  };

  handleNow = () => {
    const value = moment();
    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    let translationKey = "PICK_DATETIME";
    switch (this.state.mode) {
      case "date":
        translationKey = "PICK_DATE";
        break;
      case "time":
        translationKey = "PICK_TIME";
        break;
      default:
        break;
    }

    return (
      <>
        {!this.state.readOnly && this.props.nowOnly && (
          <Button onClick={this.handleNow} primary>
            <p>
              {this.state.value.isValid()
                ? this.getFormattedValue()
                : this.props.t(translationKey, "Click here")}
            </p>
          </Button>
        )}
        {!this.state.readOnly && !this.props.nowOnly && (
          <Form.Field>
            <label>{this.props.label}</label>
            <DateTime
              value={this.state.value}
              onChange={this.onChange}
              onOpen={this.onOpen}
              strictParsing={false}
              dateFormat={
                this.state.mode === "time"
                  ? false
                  : this.state.mode === "monthyear"
                  ? MONTH_YEAR_FORMAT_MOMENT
                  : DATE_FORMAT_MOMENT
              }
              timeFormat={
                this.state.mode !== "date" &&
                this.state.mode !== "monthyear" &&
                this.state.showTime &&
                TIME_FORMAT_MOMENT
              }
              isValidDate={this.isValid}
              locale={this.state.lang}
            />
          </Form.Field>
        )}
        {this.state.readOnly && (
          <Form.Input
            data-question-answer-value={this.getFormattedValue()}
            label={this.props.label}
            type="text"
            value={this.getFormattedValue()}
            disabled={true}
            style={this.props?.style?.input}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(DateTimeField);
