import BranchingQuestionnaireBuilder from "atom5-branching-questionnaire"
import QUESTION_RENDERERS from "../questionnaires/question-renderers/QuestionnaireRenderers";

const branchingQuestionnaireHelper = {
  getBuilderWithRenderers: () => {
    const builder = new BranchingQuestionnaireBuilder();
    Object.entries(QUESTION_RENDERERS).forEach(([type, renderer]) => {
      builder.with(type, renderer);
    });
    return builder;
  },
};

export default branchingQuestionnaireHelper;