import React from "react";

const AttachmentImagePreview = (props) => {
  const { url, reference } = props;
  return <img style={styles.image} src={url} alt={reference} />;
};

const styles = {
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
};

export default AttachmentImagePreview;
