import React from "react";
import {Dropdown, Grid} from "semantic-ui-react";
import DateTimeField from "../../../components/DateTimeField";
import {withTranslation} from "react-i18next";

const PeriodSelection = ({t, setFromDate, fromDate, period, setPeriod}) => {

    return (
        <Grid columns={'2'}>
            <Grid.Row>
                <Grid.Column>
                    <DateTimeField
                        sty
                        label={t("GRAPH_FROM_DATE", "Data from")}
                        onChange={d => setFromDate(d)}
                        value={fromDate}/>
                </Grid.Column>
                <Grid.Column>
                    <Dropdown
                        value={period}
                        placeholder={t("GRAPH_PERIOD", "Period")}
                        selection

                        onChange={(e, obj) => {
                            setPeriod(obj.value);
                        }}
                        options={[
                            {
                                key: 'day',
                                text: t('day', 'Day'),
                                value: 'day',
                            },
                            {
                                key: 'week',
                                text: t('week', 'Week'),
                                value: 'week',
                            },
                            {
                                key: 'fortnight',
                                text: t('fortnight', 'Fortnight'),
                                value: 'fortnight',
                            },
                            {
                                key: 'month',
                                text: t('month', 'Month'),
                                value: 'month',
                            },
                        ]}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default withTranslation()(PeriodSelection);
