import React from "react";

export function EventsTable({events}) {

    const tableStyle = {
        maxHeight: '200px', // Adjust the desired maximum height
        overflowY: 'auto',
    };

    const containerStyle = {
        display: 'flex', // Use flexbox to arrange items horizontally
    };


    return (
        <div style={containerStyle}>
                    <table style={tableStyle}>
                        <thead>
                        <tr>
                            <th>Index</th>
                            <th>Type</th>
                        </tr>
                        </thead>
                        <tbody>
                        {events.map((event, event_index) => (
                            <tr key={event_index}>
                                <td style={{ border: '1px solid #ccc' }}>{event_index + 1}</td>
                                <td style={{ border: '1px solid #ccc', textAlign: 'right' }}>{event.eventType}</td>
                                <td style={{ border: '1px solid #ccc', textAlign: 'right' }}>{(event.time / 1000).toFixed(2)}</td>
                            </tr>
                        ))}
                           </tbody>
                    </table>
        </div>)
}
