import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Header, Image, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import { compose } from "redux";
import InternationalisationService from "../../InternationalisationService";
import { logevent } from "../../services/FirebaseAnalytics";

const LoginModal = (props) => {
  const { t, subjectAccountCreationData } = props;

  const [email, setEmail] = useState("");
  const [requestPending, setRequestPending] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateForm = () => {
    return AuthService.EMAIL_REGEX.test(email);
  };

  useEffect(() => {
    registerScreenView();
  },[])

  let [showSubmitButton, setShowSubmitButton] = useState(true);

  const handleSubmit = async (event) => {
    const { token, id } = subjectAccountCreationData;
    event.preventDefault();
    setRequestPending(true);
    setMessage("");
    setErrorMessage("");

    try {
      await AuthService.signup(id, token, email, "subject");
      AuthService.setAccountType("subject");
      setMessage(t("SELFONBOARD_CONFIRMATION_EMAIL_SENT"));
      setRequestPending(false);
      setShowSubmitButton(false);
    } catch (_error) {
      setErrorMessage(t("SELF_ONBOARD_FAILED"));
      setRequestPending(false);
    }
  };

  const registerScreenView = () => {
    logevent(
    'screen_view', {
      firebase_screen: 'register_email', 
      firebase_screen_class: 'RegisterEmailModalInsert'
    })
  }
  return (
    <Form style={styles.container} onSubmit={handleSubmit}>
      <Grid centered padded>
        <Grid.Row>
          <Grid.Column>
            <Image
              style={{ height: "25px" }}
              src={
                "./images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column
            width={10}
            textAlign={
              InternationalisationService.isRTL() ? "right" : undefined
            }
          >
            <Header as="h3" textAlign="center">
              {t("ACCOUNT_ACTIVATION_TITLE")}
            </Header>
            {message.length > 0 && <Message positive>{message}</Message>}
            {errorMessage.length > 0 && (
              <Message negative>{errorMessage}</Message>
            )}

            {showSubmitButton && (
              <>
                <Form.Input
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder={t("GLOBAL_LABEL_EMAIL_ADDRESS", "Email address")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={requestPending}
                />

                <Button
                  type="submit"
                  primary
                  fluid
                  size="large"
                  disabled={!validateForm() || requestPending}
                >
                  {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                </Button>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid centered padded>
        <Grid.Row>
          <Button
            className="borderless"
            basic
            color={"orange"}
            size="small"
            as={"a"}
            href="https://www.aparito.com/privacy-policy/"
            target="_blank"
          >
            {t("PRIVACY_POLICY")}
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  accountTypeGroup: {
    display: "flex",
    justifyContent: "space-around",
    padding: "32px 0px",
  },
};

const enhance = compose(withTranslation());

export default enhance(LoginModal);
