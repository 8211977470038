export const getQuestionnaireFilterModules = (profile, modulesConfig) => {
  let questionnaireFilterModules = modulesConfig

  if(!questionnaireFilterModules || questionnaireFilterModules.length===0){
    const tabsConfig = Window.configuration.ui.tabs || [];
    const allowedTabs = []
    tabsConfig.forEach((tab) => {
      if(typeof tab === "string"){
        allowedTabs.push(tab)
        return;
      }
      if(tab.name){
        if(!tab.roles || tab.roles.length===0){
          allowedTabs.push(tab)
          return;
        }
        profile.groupMappings.forEach(gm=>{
          gm.roles.forEach(r=>{
            if(tab.roles.includes(r.code)){
              allowedTabs.push(tab.name.replace('module/', ''))
              return;
            }
          })
        })
      }

    });
    //use all allowed modules for staff
    questionnaireFilterModules = allowedTabs;
  }
  return questionnaireFilterModules;
}

export const createQuestionnaireDefinitionModuleFilter =
    (moduleArray) => (definition) => {
      const definitionModules = definition?.config?.modules || [];
      if (definitionModules.length === 0) {
        return false;
      }
      var sharedModules = moduleArray.filter((m) => {
        return definitionModules.indexOf(m) > -1;
      });
      return sharedModules.length > 0;
    };


//https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
export const getRolesForModules = (modules, staffProfile) => {
  if(!modules || modules.length===0){
    if(staffProfile){
      const roles = []
      staffProfile.groupMappings.forEach(g => {
        g.roles.forEach(r=>{
          if(!roles.includes(r.code)){
            roles.push(r.code)
          }
        })
      })
      return roles;
    }
  }
  const tabsConfig = Window.configuration.ui.tabs || [];
  let allRoles = [];

  tabsConfig.forEach((tC) => {
    const name = typeof tC === "string" ? tC : tC.name;

    if (modules.includes(name)||modules.includes(name.replace('module/',''))) {
      const roles = typeof tC === "string" ? [] : tC.roles;
      allRoles = [...new Set([...allRoles, ...roles])];
    }
  });

  return allRoles;
};

export default {getQuestionnaireFilterModules, getRolesForModules, createQuestionnaireDefinitionModuleFilter}