import RequestHelper from "./RequestHelper";
import InternationalisationService from "./InternationalisationService";
import SUBJECT_AUTHENTICATION_STRATEGY from "./constants/SUBJECT_AUTHENTICATION_STRATEGY";
import _ from "lodash";
export default class TrialService {
  static getCurrentTrial() {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/trial/current",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static getGroups() {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/trial/groups",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  }

  static async getConfiguration() {
    const webConfig = await RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/trial/configuration/electron-clinician-web",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
    const commonConfig = await RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/trial/configuration/electron-subject-common",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
    return _.merge(commonConfig, webConfig);
  }

  static getDefaultSubjectAuthenticationStrategy() {
    return SUBJECT_AUTHENTICATION_STRATEGY.EMAIL_PWD;
  }

  static async getSubjectAuthenticationStrategy() {
    let trial;
    try {
      trial = await TrialService.getCurrentTrial();
    } catch (err) {
      console.error("Error getting trial");
    }
    const subjectAuthenticationStrategy =
      trial.subjectAuthenticationStrategy ||
      TrialService.getDefaultSubjectAuthenticationStrategy();
    return subjectAuthenticationStrategy;
  }
}
