import React from "react";
import { Form } from "semantic-ui-react";

const FeedbackForm = (props) => {
    const { placeholder, addFeedback } = props;

    const handleChange = (evt) => {
        addFeedback(evt.target.value);
    }

     return (
        <Form.TextArea
            id="feedback"
            name="feedback"
            rows="5"
            maxLength="4000"
            onChange={handleChange}
            placeholder={placeholder}
            style={{    
                textalign: "center", width: "100%", 
                paddingTop: "20px", padding: "10px", 
                minWidth: 600, resize: "none",  
            }}
        />
    );
    
}

export default FeedbackForm;