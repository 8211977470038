import RequestHelper from "./RequestHelper";

export default class DrugService {
  static LIST_DRUGS_QUERY = `
        query($pageNum: Int!, $size: Int!, $beginsWith: String!) {
            query: listDrugs(pageNum: $pageNum, size: $size, beginsWith: $beginsWith) {
            totalPages
            content {
                id
                name
                active
                }
            }
        }
    `;

  static GET_DRUG_QUERY = `
        query($id: Long!) {
            query: getDrug(id: $id) {
                id
                name
                active
            }
        }
    `;

  static CREATE_DRUG_MUTATION = `
        mutation query($name: String!) {
            createDrug(name: $name) {
                id
                name
                active
            }
        }
    `;

  static UPDATE_DRUG_MUTATION = `
        mutation query($id: Long!, $name: String!, $active: Boolean!) {
            updateDrug(id: $id, name: $name, active: $active) {
                id
                name
                active
            }
        }
    `;

  static createDrug(name, headers) {
    const variables = { name };
    return RequestHelper.graphQl(DrugService.CREATE_DRUG_MUTATION, variables);
  }

  static updateDrug(id, name, active, headers) {
    const variables = { id, name, active };
    return RequestHelper.graphQl(DrugService.UPDATE_DRUG_MUTATION, variables);
  }

  static listDrugs(pageNum, size, beginsWith) {
    const variables = { pageNum, size, beginsWith };
    return RequestHelper.graphQl(DrugService.LIST_DRUGS_QUERY, variables);
  }

  static getDrug(id) {
    let drug = RequestHelper.graphQl(DrugService.GET_DRUG_QUERY, { id });
    return drug;
  }
}
