import React from "react";
import DOMPurify from "dompurify";
import { withTranslation } from "react-i18next";
import InternationalisationService from "../../InternationalisationService";
import SubjectService from "../../SubjectService";
import {
  Button,
  Checkbox,
  Form,
  Message,
  Placeholder,
} from "semantic-ui-react";

class SubjectNonConsentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      language: InternationalisationService.getLanguage(),
      loading: true,
      error: false,
      isChecked: {},
    };

    SubjectService.getQuestionnaireDefinitions("CONTENT").then(
      (questionnaireDefinitions) => {
        const nonConsentQuestionnaireCode = Window.configuration.ui
          .selfOnboarding
          ? Window.configuration.ui.selfOnboarding.nonConsentQuestionnaire
          : "study-non-consent";
        const studyNonConsentQuestionnaire = questionnaireDefinitions.find(
          (qDef) => {
            return qDef.code === nonConsentQuestionnaireCode;
          }
        );
        this.setState({
          studyConsentQuestionnaire: studyNonConsentQuestionnaire,
          loading: false,
        });
      }
    );
  }

  checkFn = (checked, code) => {
    this.setState({
      isChecked: { ...this.state.isChecked, [code]: checked },
    });
  };

  render() {
    const { t } = this.props;
    let studyConsentMsg;
    let groups = {};

    let headerDisplay;
    let partsDisplay;
    let linkDisplay;

    if (this.state.studyConsentQuestionnaire) {
      studyConsentMsg = this.state.studyConsentQuestionnaire.questions;
      studyConsentMsg.forEach((Obj) => {
        const codes = Obj.code.split("-");
        groups[codes[0]] = { ...groups[codes[0]], [codes[1]]: Obj };
      });

      headerDisplay = Object.keys(groups).map((key) =>
        key.includes("header") ? (
          <HeaderDisplay key={key} group={groups[key]} />
        ) : null
      );

      linkDisplay = Object.keys(groups).map((key) =>
        key.includes("link") ? (
          <LinkDisplay key={key} group={groups[key]} />
        ) : null
      );

      partsDisplay = Object.keys(groups).map((key) => {
        return key.includes("part") ? (
          <PartDisplay key={key} group={groups[key]} checkFn={this.checkFn} />
        ) : null;
      });
    }

    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: 850, padding: "20px" }}>
          {this.state.error && (
            <Message error>{t("CONSENT_ERROR_MESSAGE")}</Message>
          )}
          {this.state.loading && !this.state.error && (
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          )}
          {!this.state.loading &&
            !this.state.error &&
            this.state.studyConsentQuestionnaire && (
              <>
                {headerDisplay}
                {partsDisplay}
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {linkDisplay}
                </div>
              </>
            )}
        </div>
      </div>
    );
  }
}

const HeaderDisplay = (props) => {
  const { group } = props;

  if (group.overwriteMarkup) {
    return MarkupDisplay(group.overwriteMarkup);
  }

  return (
    <div style={{ padding: "20px 0px" }}>
      {group.mainheader && <h1 key={group.code}>{group.mainheader.label}</h1>}
      {group.subheader && <h3>{group.subheader.label}</h3>}
      {group.markup && <MarkupDisplay markupObject={group.markup} />}
    </div>
  );
};

const PartDisplay = (props) => {
  const { group, checkFn } = props;

  if (group.overwriteMarkup) {
    return MarkupDisplay(group.overwriteMarkup);
  }
  return (
    <div id="step-container" style={{ padding: "20px 0px", textAlign: "left" }}>
      {group.header && <h4 key={group.header.code}>{group.header.label}</h4>}
      {group.text && (
        <p key={group.text.code} style={{ width: "100%" }}>
          {group.text.label}
        </p>
      )}
      {group.markup && (
        <MarkupDisplay key={group.markup.code} markupObject={group.markup} />
      )}
      {group.checkbox && (
        <Form.Field key={group.checkbox.code} style={{ padding: "20px 0" }}>
          <Checkbox
            label={group.checkbox.label}
            onChange={(e, data) => checkFn(data.checked, group.checkbox.code)}
          />
        </Form.Field>
      )}
    </div>
  );
};

const LinkDisplay = (props) => {
  const { group } = props;

  if (group.overwriteMarkup) {
    return MarkupDisplay(group.overwriteMarkup);
  }

  return (
    <>
      {group.button && (
        <a href={group.button.config.to}>
          <Button primary basic>
            {group.button.label}
          </Button>
        </a>
      )}
    </>
  );
};

const MarkupDisplay = (props) => {
  const { markupObject } = props;
  let cleanHTML = DOMPurify.sanitize(markupObject.label, {
    ADD_ATTR: ["target"],
  });
  return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
};

export default withTranslation()(SubjectNonConsentPage);
