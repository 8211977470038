import React, { useContext, useEffect, useState } from "react";
import { Form, Icon, Loader, Message } from "semantic-ui-react";
import SubjectService from "../../SubjectService";
import withError from "./hocs/withError";
import UserContext from "../../context/UserContext";
import { compose } from "redux";
import { useTranslation } from "react-i18next";
import { Link as NavLink } from "react-router-dom";
import { getDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import { connect } from "react-redux";
import withContainer from "./hocs/withContainer";
import {typeHelper} from "atom5-branching-questionnaire";

const Link = ({
  question,
  value,
  changeValue,
  subjectId,
  definitions,
  isReadOnly,
  containerType,
}) => {
  const [internalValue, setInternalValue] = useState(
    value != null ? value : false
  );
  const [to, setTo] = useState(null);

  const { t } = useTranslation();

  const user = useContext(UserContext);

  let displayIfVisited = "DEFAULT";
  if (question?.config?.displayIfVisited) {
    displayIfVisited = question?.config?.displayIfVisited;
  }

  const isUrl = to && to.startsWith("http") ? true : false;
  const hasBeenVisited = value;

  const onChange = () => {
    if (!internalValue) {
      setInternalValue(true);
      changeValue(true);
    }
  };

  useEffect(() => {
    const init = async () => {
      let to;
      let toArray;

      if (isReadOnly && !typeHelper.parseBool(question.config?.showOnDashboard)) {
        return;
      }

      if (!Array.isArray(question.config.to)) {
        toArray = [question.config.to];
      } else {
        toArray = question.config.to;
      }

      for (let i = 0; i < toArray.length; i++) {
        let toUri = null;
        let shouldFetchCompleted = false;
        if (typeof toArray[i] === "object") {
          toUri = toArray[i].uri;
          shouldFetchCompleted = toArray[i].completed;
        } else {
          toUri = toArray[i];
        }

        if (toUri.indexOf("://") === -1) {
          to = toUri;
          console.warn(
            "[LINK] failed to parse to uri. Not in form <type>://<code> ",
            to
          );
          continue;
        }

        if(toUri && toUri?.startsWith("http")){
          to = toUri;
          continue;
        }

        const [namespace, code] = toUri.split("://");
        let targetQuestionnaire = undefined;
        try {
          if(shouldFetchCompleted){
            const completed = await SubjectService.getSubjectQuestionnairesCompletedOnly(
              subjectId,
              code,
              undefined,
              undefined,
              1
            );
            targetQuestionnaire = completed && completed.result && completed.result.length > 0 ? completed.result[0] : undefined;
          }else{
            const all = await SubjectService.getSubjectQuestionnaires(
              subjectId,
              code,
              undefined,
              undefined, 
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              1,
              undefined
            )
            targetQuestionnaire = all && all.result && all.result.length > 0 ? all.result[0] : undefined
          
          }
        } catch (e) {
          console.warn(
            `Tried and failed to fetch questionnaires for subject ${subjectId} and questionnaire code ${code}`
          );
        }

        if (
          targetQuestionnaire === undefined ||
          (namespace === "event" && !shouldFetchCompleted)
        ) {
          continue;
        }

        const isSubject = user.accountType === "subject";
        const hasFoundQuestionnaire = targetQuestionnaire !== undefined;

        switch (namespace) {
          case "content":
            if (isSubject) {
              to = `/app/subject/questionnaire/${targetQuestionnaire.id}`;
            } else {
              to = false;
            }
            break;
          case "pro":
            if (user.accountType === "subject") {
              to = `/app/subject/questionnaire/${targetQuestionnaire.id}`;
            } else if (shouldFetchCompleted) {
              to = `/app/subject/${subjectId}/questionnaire-type/pro/${code}/view/${targetQuestionnaire.id}`;
            } else {
              to = `/app/subject/${subjectId}/questionnaire-type/pro/${code}/submit/${targetQuestionnaire.id}`;
            }
            break;
          case "event":
            let definition = definitions.find((d) => d.code === code);
            if (isSubject && definition) {
              if (hasFoundQuestionnaire) {
                to = `/app/subject/questionnaire/${targetQuestionnaire.id}`;
              } else {
                to = `/app/subject/questionnaire/${definition.id}`;
              }
            } else if (!isSubject && hasFoundQuestionnaire) {
              to = `/app/subject/${subjectId}/questionnaire-type/event/${code}/view/${targetQuestionnaire.id}`;
            } else {
              to = false;
            }
            break;
          default:
            to = false;
        }
        if (to) {
          break;
        }
      }
      if (!to) {
        to = false;
      }
      setTo(to);
    };
    init();
  }, [definitions, question, subjectId, user.accountType, isReadOnly]);

  if (isReadOnly && typeHelper.parseBool(question.config?.showOnDashboard)) {
    if (value) {
      return (
        <div style={{ display: "flex" }}>
          <Icon name="check" data-question-answer-value={t("GENERIC_YES")} />
          {containerType !== "table" && <LinkDisplay
              t={t}
              onChange={onChange}
              question={question}
              to={to}
              hasBeenVisited={hasBeenVisited}
              isUrl={isUrl}
              isReadOnly={isReadOnly}
              value={value}
              displayIfVisited={displayIfVisited} />}
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex" }}>
          <Icon name="times" data-question-answer-value={t("GENERIC_NO")} />{" "}
          {containerType !== "table" && <LinkDisplay
              t={t}
              onChange={onChange}
              question={question}
              to={to}
              hasBeenVisited={hasBeenVisited}
              isUrl={isUrl}
              isReadOnly={isReadOnly}
              value={value}
              displayIfVisited={displayIfVisited} />}
        </div>
      );
    }
  }


  if (to === null) {
    if(isReadOnly){
      return null;
    } else {
      return <Loader style={{ marginTop: 50 }} active />;
    }

  }

  return <LinkDisplay
      t={t}
      onChange={onChange}
      question={question}
      to={to}
      hasBeenVisited={hasBeenVisited}
      isUrl={isUrl}
      isReadOnly={isReadOnly}
      displayIfVisited={displayIfVisited} />
};

const LinkDisplay = ({t, to, internalValue, hasBeenVisited, isUrl, question, displayIfVisited, isReadOnly, onChange}) => {
  if (!to && !internalValue) {
    console.warn("[LINK] Link failed to find questionnaire");
    return (
        <Message>
          {t("LINK_NOT_FOUND_ERROR_MESSAGE", "The link is missing, please check config or contact support.")}
        </Message>
    );
  }

  if (!hasBeenVisited) {
    if (isUrl) {
      return (
          <Form.Field>
            <a
                onClick={onChange}
                href={to}
                target="_blank"
                rel="noopener noreferrer"
            >
              {question.label}
            </a>
          </Form.Field>
      );
    } else {
      return (
          <Form.Field>
            <NavLink onClick={onChange} to={to}>
              {question.label}
            </NavLink>
          </Form.Field>
      );
    }
  }

  if (hasBeenVisited) {
    switch (displayIfVisited) {
      case "NONE":
        return <div />;
      case "MESSAGE":
        return (
            <Form.Field>
              <Message>
                {t([
                  "QUESTIONNAIRE_ALREADY_SEEN",
                  "Questionnaire has already been seen",
                ])}
              </Message>
            </Form.Field>
        );
      default:
        if (isUrl) {
          return (
              <Form.Field>
                <a
                    onClick={onChange}
                    href={to}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  {question.label}
                </a>
                {displayIfVisited === "DEFAULT_WITH_MESSAGE" && (
                    <p>
                      {t([
                        "QUESTIONNAIRE_ALREADY_SEEN",
                        "Questionnaire has already been seen",
                      ])}
                    </p>
                )}
              </Form.Field>
          );
        } else {
          return (
              <Form.Field>
                <NavLink onClick={onChange} to={to}>
                  {question.label}
                </NavLink>
                {displayIfVisited === "DEFAULT_WITH_MESSAGE" && (
                    <p>
                      {t([
                        "QUESTIONNAIRE_ALREADY_SEEN",
                        "Questionnaire has already been seen",
                      ])}
                    </p>
                )}
              </Form.Field>
          );
        }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    definitions: getDefinitions(state),
  };
};

const enhance = compose(withContainer, connect(mapStateToProps), withError);

export default enhance(Link);
