import { useEffect, useState } from "react";
import SubjectService from "../SubjectService";

/**
 * Returns the parent questionnaire with populated answer map
 * @param {*} questionnaire
 * @param {*} subjectId
 * @param {*} allDefinitions
 * @returns [isLoading, [{parentQuestionnaire, parentDefinition}], parentQuestionnaireAnswers]
 */
export default function useParentQuestionnaireInformation(
  questionnaire,
  subjectId,
  allDefinitions
) {
  const [isLoading, setIsLoading] = useState(true);
  const [parentQuestionnaireArray, setParentQuestionnaireArray] =
    useState(null);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const populateParentQuestionnaireInformation = async () => {
      let permissions = await SubjectService.getSubjectPermission(subjectId);
      const parentQuestionnaireArray = await getParentQuestionnaire(
        questionnaire
      );

      await Promise.all([
        setParentQuestionnaireArray(parentQuestionnaireArray),
        setPermissions(permissions),
      ]);
      setIsLoading(false);
    };

    const getParentQuestionnaire = async (questionnaire) => {
      const definition = allDefinitions.find((d) => {
        if (questionnaire) {
          return d.id === questionnaire.definitionId;
        }
        return false;
      });
      if (questionnaire?.parentQuestionnaire && definition?.config?.parent?.show) {
        
        const parentQuestionnaire = await SubjectService.getSubjectQuestionnaireById(subjectId, questionnaire.parentQuestionnaire)
        let parentDefinition;
        if (parentQuestionnaire) {
          parentDefinition = allDefinitions.find(
            (d) => d.id === parseInt(parentQuestionnaire.definitionId)
          );
        }
        if (parentDefinition) {
          return [
            { parentQuestionnaire, parentDefinition },
            ...(await getParentQuestionnaire(parentQuestionnaire)),
          ];
        }
      }
      return [];
    };

    populateParentQuestionnaireInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire, subjectId, allDefinitions.length]);

  return [isLoading, parentQuestionnaireArray, permissions];
}
