/* eslint no-unused-vars: 0 */
import React, {Component} from "react";
import {Button, Dropdown, Form, Grid, Header, Icon, Label, List, Message, Segment} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import StaffService from "../../StaffService";
import GroupService from "../../services/GroupService";
import GroupPermission from "../../GroupPermission";
import TrialService from "../../TrialService";
import AuthService from "../../services/AuthService";
import ConfigContext from "../../context/ConfigContext";
import GeneralHelpers from "../../helpers/GeneralHelpers";

class StaffForm extends Component {
  state = {
    superAdmin: false,
    isPrimarySuperAdmin: false,
    firstName: "",
    lastName: "",
    email: "",
    drugManager: false,
    availableRoles: [],
    groupRoles: [],
    groups: [],
    roles: [],
    groupLabels: {},
    roleLabels: {},

    submitting: false,

    showSuperAdmin: false,
    showDrugManager: true,
    warning: null,
  };

  constructor(props, context) {
    super(props, context);

    this.state.showDrugManager = this.context.ui?.showDrugManager !== "false";

    if (props.staff) {
      this.state.firstName = props.staff.firstName;
      this.state.lastName = props.staff.lastName;
      this.state.email = props.staff.email;
      this.state.drugManager = props.staff.drugManager;
      this.state.superAdmin = props.staff.superAdmin;
      this.state.isPrimarySuperAdmin = StaffService.isPrimarySuperAdmin(props.staff);

      this.state.groupRoles = [];

      for (const groupRole of props.staff.groupMappings) {
        this.state.groupRoles.push({
          group: groupRole.group.code,
          roles: groupRole.roles.map((role) => role.code),
        });
        this.addRoleLabelsForGroup(groupRole.group.code);
      }
    }

    AuthService.getIsSuperAdmin().then((isSuperAdmin) => {
      this.setState({showSuperAdmin: isSuperAdmin});
      if (isSuperAdmin) {        
        TrialService.getGroups().then((allGroups) => {
          const newGroupLabels = {};
          for (const group of allGroups) {
            newGroupLabels[group.code] = group.label;
          }
          this.setState({
            availableGroups: allGroups,
            groupLabels: newGroupLabels,
          });
        });
      } else {
        GroupService.getGroupsWithPermission(GroupPermission.CREATE_STAFF).then(
          (groups) => {
            TrialService.getGroups().then((allGroups) => {
              const newGroupLabels = {};
              for (const group of groups) {
                const trialGroup = allGroups.find((g) => g.code === group.code);
                newGroupLabels[group.code] = trialGroup.label;
              }
              this.setState({
                availableGroups: groups,
                groupLabels: newGroupLabels,
              });
            });
          }
        );
      }
    });

    this.onSubmitCallback = props.onSubmit;
  }
  addGroupRole = (event) => {
    event.preventDefault();
    const groupRoles = this.state.groupRoles;
    for(const group of this.state.groups) {
      groupRoles.push({group: group, roles: this.state.roles});
    }

    this.setState({ groupRoles, groups: [], roles: [] });
  };

  removeGroupRole = (group) => {
    const groupRoles = this.state.groupRoles;

    for (let i = 0; i < groupRoles.length; i++) {
      if (groupRoles[i].group === group) {
        groupRoles.splice(i, 1);
        break;
      }
    }

    this.setState({ groupRoles });
  };

  groupSelected = async (_event, item) => {
    this.setState({
      loading: true,
    });
    let { t } = this.props;
    let groupValues;
    if (item.value.includes('select_all')) {
      //add all the groups into the dropdown
      const nonSelectedGroups = this.getNonSelectedGroups();
      groupValues = nonSelectedGroups
          .filter(group => group.value !== 'select_all')
          .map(group => group.value)
    }else{
      groupValues = item.value;
    }
    //Get list of roles common to all the selected groups
    const commonAvailableRoles = await this.getAvailableRolesForStaffCreation(groupValues);
    //gets a list of the common roles that have already been selected
    const commonSelectedRoles = this.getCommonSelectedRoles(commonAvailableRoles);
    //If they have commonSelected roles that are now different from the current selected roles warn the user
    const noChanges =  GeneralHelpers.arraysEqual(commonSelectedRoles,this.state.roles);
    if (!noChanges){
      this.setState({warning : t("STAFF_FORM_ROLES_CHANGED","Current selected roles have changed")});
    } else{
      this.setState({warning : null});
    }
    this.setState({availableRoles:commonAvailableRoles, roles:commonSelectedRoles, groups:groupValues,  loading: false });

    this.addRoleLabelsForGroup(item.value);

  };

  getCommonSelectedRoles(commonAvailableRoles) {
    return this.state.roles.filter(role => {
      return commonAvailableRoles.filter(commonRole => {
        return commonRole.code === role;
      }).length > 0;
    });
  }

//list all available roles given list of groups
  //user is assigning to a user. Available role will only be included in the list
  //if the user is allowed to grant the role for all the groups passed into this function.
  getAvailableRolesForStaffCreation =  async ( groups ) => {
    let commonAvailableRoles = [];
    for (const group of groups) {
      let availableRoles = await StaffService.getRolesForStaffCreation(group);
      if (commonAvailableRoles.length === 0){
        commonAvailableRoles = availableRoles; //if the list is empty add all the roles
      }else{
        let currentAvailableRoles = commonAvailableRoles;
        commonAvailableRoles = availableRoles.filter( availableRole => {
          return (currentAvailableRoles.filter(currentRole => currentRole.id === availableRole.id).length > 0);
        })
      }
    }
    return commonAvailableRoles;
  }

  addRoleLabelsForGroup = (groupCode) => {
    const roleLabels = this.state.roleLabels;
    StaffService.getRolesForStaffCreation(groupCode).then((availableRoles) => {
      for (const role of availableRoles) {
        roleLabels[role.code] = role.label;
      }
      this.setState({ roleLabels: roleLabels });
    });
  };

  roleSelected = (_event, item) => {
    this.setState({
      roles: item.value,
    });
  };

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  checkBoxChanged = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const staff = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      drugManager: this.state.drugManager,
      superAdmin: this.state.superAdmin,
      email: this.state.email,
      groupRoles: this.state.groupRoles,
    };

    const promise = this.onSubmitCallback(staff);

    // If we have a promise, lets use that to determine when to show indication of submission
    if (promise) {
      this.setState({ submitting: true });

      promise.finally(() => {
        this.setState({ submitting: false });
      });
    }
  };

  getNonSelectedGroups() {
    const { t } = this.props;
    const groups = [];
    if (this.state.availableGroups) {
      groups.push({
        key: 'selectall',
        text: t("STAFF_FORM_SELECT_ALL","Select All"),
        value: 'select_all',
      });
      for (const group of this.state.availableGroups) {
        let groupAlreadyAssigned = false;

        for (const groupRole of this.state.groupRoles) {
          if (group.code === groupRole.group) {
            groupAlreadyAssigned = true;
            break;
          }
        }

        if (!groupAlreadyAssigned) {
          groups.push({
            key: group.code,
            text: this.state.groupLabels[group.code],
            value: group.code,
          });
        }
      }
    }
    return groups;
  }

  render() {
    const { t, error } = this.props;

    const groups = this.getNonSelectedGroups();

    for (const groupRole of this.state.groupRoles) {
      const index = groups.indexOf(groupRole.group);

      if (index >= 0) {
        groups.splice(index, 1);
      }
    }

    const roles = [];
    for (const role of this.state.availableRoles) {
      roles.push({ key: role.code, text: role.label, value: role.code });
    }

    const canSelectGroup = groups.length > 0 && !this.state.submitting;
    const canAddGroupRole =
      this.state.groups.length && this.state.roles.length > 0 && !this.state.submitting;
    const canSubmit = !this.state.submitting;

    return (
      <Form onSubmit={this.handleSubmit} error={error}>
        <Message error header={t("STAFF_FORM_ERROR_TITLE")} content={error} />
        <Message warning visible={this.state.warning !== null} header={t("STAFF_FORM_WARNING_TITLE","Warning")} content={this.state.warning} />
        <Grid columns={"equal"}>
          <Grid.Column>
            <Segment>
              <Header>{t("STAFF_FORM_DETAILS_HEADER")}</Header>
              {this.state.isPrimarySuperAdmin && (
                <Message info visible>
                  <Message.Header>
                    <Icon name={"warning sign"} /> WARNING
                  </Message.Header>
                  {t("PRIMARY_SUPERADMIN_EDIT_WARNING", "This is the Primary Super Admin, and some details cannot be edited")}
                </Message>
              )}
              {this.state.showSuperAdmin && (
                <Form.Field>
                  <label>{t("STAFF_FORM_IS_SUPER_ADMIN_LABEL", "Is Super Admin?")}</label>
                  <input
                    type="checkbox"
                    name={"superAdmin"}
                    checked={this.state.superAdmin}
                    value="superAdmin"
                    onChange={this.checkBoxChanged}
                    disabled={this.state.isPrimarySuperAdmin}
                  />
                </Form.Field>
              )}
              <Form.Field>
                <label>{t("STAFF_FORM_FIRSTNAME_LABEL")}</label>
                <input
                  autoFocus
                  name={"firstName"}
                  required
                  value={this.state.firstName}
                  onChange={this.inputChanged}
                  disabled={this.state.isPrimarySuperAdmin}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("STAFF_FORM_LASTNAME_LABEL")}</label>
                <input
                  name={"lastName"}
                  required
                  value={this.state.lastName}
                  onChange={this.inputChanged}
                  disabled={this.state.isPrimarySuperAdmin}
                />
              </Form.Field>
              {this.state.showDrugManager && (
                <Form.Field>
                  <label>{t("STAFF_FORM_DRUG_MANAGER_LABEL")}</label>
                  <input
                    type="checkbox"
                    name={"drugManager"}
                    checked={this.state.drugManager}
                    value="drugManager"
                    onChange={this.checkBoxChanged}
                  />
                </Form.Field>
              )}
              <Form.Field>
                <label>{t("STAFF_FORM_EMAIL_LABEL")}</label>
                <input
                  name={"email"}
                  required
                  value={this.state.email}
                  onChange={this.inputChanged}
                  disabled={this.state.isPrimarySuperAdmin}
                />
              </Form.Field>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Header>{t("STAFF_FORM_GROUP_ROLES_HEADER")}</Header>
              <Form.Group>
                <Form.Field width={6}>
                  <label>{t("STAFF_FORM_GROUP_DROPDOWN_LABEL")}</label>
                  <Dropdown
                    placeholder={t("STAFF_FORM_GROUP_DROPDOWN_PLACEHOLDER")}
                    fluid
                    multiple
                    selection
                    options={groups}
                    onChange={this.groupSelected}
                    value={this.state.groups}
                    disabled={!canSelectGroup}
                  />
                </Form.Field>
                <Form.Field width={6}>
                  <label>{t("STAFF_FORM_ROLES_DROPDOWN_LABEL")}</label>
                  <Dropdown
                    placeholder={t("STAFF_FORM_ROLES_DROPDOWN_PLACEHOLDER")}
                    fluid
                    multiple
                    selection
                    options={roles}
                    onChange={this.roleSelected}
                    value={this.state.roles}
                    disabled={!canSelectGroup}
                  />
                </Form.Field>
                <Form.Field width={4}>
                  <label>&nbsp;</label>
                  <Button
                    style={{ height: 35 }}
                    primary
                    onClick={this.addGroupRole}
                    disabled={!canAddGroupRole}
                  >
                    {t("GLOBAL_BUTTON_ADD")}
                  </Button>
                </Form.Field>
              </Form.Group>

              <List divided selection>
                {this.state.groupRoles.map((groupRole) => (
                  <List.Item key={groupRole.group}>
                    <List.Content>
                      <List.Header>
                        {this.state.groupLabels[groupRole.group]}{" "}
                        <a
                          href={"#noop"}
                          onClick={(event) => {
                            event.preventDefault();
                            this.removeGroupRole(groupRole.group);
                          }}
                          style={{ float: "right" }}
                        >
                          {t("GLOBAL_BUTTON_REMOVE")}
                        </a>
                      </List.Header>
                      <List.Description style={{ paddingTop: "0.2em" }}>
                        {groupRole.roles.map((role) => (
                          <Label key={role}>
                            {this.state.roleLabels[role]}
                          </Label>
                        ))}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Row>
            <Grid.Column>
              <Button
                type="submit"
                loading={this.state.submitting}
                primary
                disabled={!canSubmit}
              >
                {t("GLOBAL_BUTTON_SAVE")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

StaffForm.contextType = ConfigContext;

export default withTranslation()(StaffForm);
