const workflowHelper = {
  hasQuestionnaireWorkflow: (questionnaire) => !!workflowHelper.getInstanceFromQuestionnaire(questionnaire),
  getInstanceFromQuestionnaire: (questionnaire) => {
    return questionnaire?.questionnaireWorkflowInstance
  },
  getTaskInstanceFromQuestionnaire: (questionnaire) => {
    return workflowHelper.getInstanceFromQuestionnaire(questionnaire)?.atTask;
  },
  getTaskFromQuestionnaire: (questionnaire) => {
    return workflowHelper.getTaskInstanceFromQuestionnaire(questionnaire)?.questionnaireWorkflowTask;
  },
}

export default workflowHelper;