import React, { useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import {Button, Form, Input, Message, Table} from "semantic-ui-react";
import AlertMembersEditComponent from "./AlertMembersEditComponent";
import ConfirmButtonWithFeedback from "../dashboard/ConfirmButtonWithFeedback";
import ConfirmButton from "../dashboard/ConfirmButton";
import ConfigContext from "../../context/ConfigContext";

const NEW_GROUP_INFORMATION = {
  id: null,
  code: "",
  label: "",
  alertEmail: "",
  alertLanguage: "",
};

function GroupEditComponent({
  t,
  group,
  groups,
  staff,
  languages,
  onSubmit,
  error,
}) {
  const config = useContext(ConfigContext);
  let showFeedbackOnSubmit = config.audit?.captureGroupAlternationFeedback;

  const [internalGroup, setInternalGroup] = useState(
    group ? group : NEW_GROUP_INFORMATION
  );

  const handleSubmit = async (feedback) => {
    const newGroup = {
      ...internalGroup,
      code: internalGroup.code.trim(),
      label: internalGroup.label.trim(),
      alertEmail: internalGroup.alertEmail,
      alertLanguage: internalGroup.alertLanguage,
    };
    await onSubmit(newGroup, feedback)

  };

  const onChange = (e) => {
    e.preventDefault();
    setInternalGroup((oldGroup) => {
      return { ...oldGroup, [e.target.name]: e.target.value };
    });
  };

  let isEmailNull = internalGroup.alertEmail === null;
  let isEmailEmpty = isEmailNull || internalGroup.alertEmail.length === 0;
  let isLanguageNull = internalGroup.alertLanguage === null;
  let isLanguageEmpty =
    isLanguageNull || internalGroup.alertLanguage.length === 0;

  let alertEmails;
  if (isEmailNull) {
    alertEmails = [];
  } else if (isEmailEmpty) {
    alertEmails = [""];
  } else {
    alertEmails = internalGroup.alertEmail.split(",");
  }

  let alertLanguages;
  if (isLanguageNull) {
    alertLanguages = [];
  } else if (isLanguageEmpty) {
    alertLanguages = [""];
  } else {
    alertLanguages = internalGroup.alertLanguage.split(",");
  }

  const addNewAlertEmail = () => {
    setInternalGroup((oldGroup) => {
      return {
        ...oldGroup,
        alertEmail:
          oldGroup.alertEmail === null ? "" : oldGroup.alertEmail.trim() + ",",
        alertLanguage:
          oldGroup.alertLanguage === null
            ? languages[0].code
            : oldGroup.alertLanguage + "," + languages[0].code,
      };
    });
  };

  const onChangeAlert = ({ alertEmail, alertLanguage }, i) => {
    alertEmails[i] = alertEmail.trim();
    alertLanguages[i] = alertLanguage;

    setInternalGroup((oldGroup) => {
      return {
        ...oldGroup,
        alertEmail: alertEmails.join(","),
        alertLanguage: alertLanguages.join(","),
      };
    });
  };

  const deleteAlert = (index) => {
    alertEmails.splice(index, 1);
    alertLanguages.splice(index, 1);
    setInternalGroup((oldGroup) => {
      return {
        ...oldGroup,
        alertEmail: alertEmails.length === 0 ? null : alertEmails.join(),
        alertLanguage:
          alertLanguages.length === 0 ? null : alertLanguages.join(),
      };
    });
  };

  const isGroupCodeUsed = (groupPresent, groupNew) => {
    return (
      groupPresent.code === groupNew.code.trim() && groupPresent.id !== groupNew.id
    );
  };
  const isGroupCodeValid = useMemo(
    () => (internalGroup) => {
      const group = groups.find((group) =>
        isGroupCodeUsed(group, internalGroup)
      );
      if (group) {
        return false;
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internalGroup, groups]
  );
  const isValid = () => {
    if (internalGroup.code.trim().length === 0) {
      return false;
    }
    if (!isGroupCodeValid(internalGroup)) {
      return false;
    }
    if (internalGroup.label.trim().length === 0) {
      return false;
    }
    if (
      internalGroup.alertEmail === null &&
      internalGroup.alertLanguage === null
    ) {
      return true;
    }
    if (!internalGroup.alertEmail.split(",").every((v) => v.trim().length !== 0)) {
      return false;
    }
    if (!internalGroup.alertLanguage.split(",").every((v) => v.trim().length !== 0)) {
      return false;
    }
    return true;
  };

  return (
    <Form error={error}>
      <Message error header={t("GROUP_FORM_ERROR_TITLE", "Error")} content={error} />
      <Form.Field>
        <label>{t("GROUP_EDIT_CODE", "Code")}</label>
        <Input
          name="code"
          value={internalGroup.code}
          onChange={onChange}
          error={!isGroupCodeValid(internalGroup)}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("GROUP_EDIT_LABEL", "Label")}</label>
        <input name="label" value={internalGroup.label} onChange={onChange} />
      </Form.Field>
      <label>{t("GROUP_EDIT_ALERT_MEMBERS", "Alert Members")}</label>
      <Table>
        {alertEmails.map((email, i) => {
          return (
            <AlertMembersEditComponent
              alertEmail={email}
              alertLanguage={alertLanguages[i]}
              languages={languages}
              staff={staff}
              onChange={(value) => onChangeAlert(value, i)}
              onDelete={() => deleteAlert(i)}
            />
          );
        })}
      </Table>
      <Form.Field>
        <Button primary onClick={addNewAlertEmail}>
          {t("GROUP_EDIT_NEW_ALERT_EMAIL", "Add New Alert Member")}
        </Button>
      </Form.Field>
      <Form.Field>
        {showFeedbackOnSubmit ? (
          <ConfirmButtonWithFeedback
            buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
            headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
            contentText={t(
              "GROUP_EDIT_DETAILED",
              "Please give a reason why this is being changed and confirm."
            )}
            confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
            cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
            onConfirm={handleSubmit}
            placeholderText={t("GROUP_EDIT_PLACEHOLDER_TEXT", "Reason")}
            mandatoryValidationText={t(
              "GROUP_EDIT_VALIDATION_TEXT",
              "Please supply a reason for the change."
            )}
            disabled={!isValid()}
            color={isValid() ? "orange" : "grey"}
          />
        ) : (
          <ConfirmButton
            buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
            headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
            contentText={t(
              "GROUP_EDIT_DETAILED_NO_FEEDBACK",
              "Please confirm that you want to make this change"
            )}
            confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
            cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
            onConfirm={handleSubmit}
            disabled={!isValid()}
            color={isValid() ? "orange" : "grey"}
          />
        )}
      </Form.Field>
    </Form>
  );
}

export default withTranslation()(GroupEditComponent);
