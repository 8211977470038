import { DateTime } from "luxon";
import { v4 as uuid_v4 } from "uuid";
import AuthService from "./AuthService";
import * as _ from "lodash";
import LZString from "lz-string";
import RequestHelper from "../RequestHelper";
import Package from "../../package.json";

const buildSubmissionDataEnvelope = () => {
  const token = AuthService.getAuthToken();
  console.log("[submissionEnvelopeBuilder] token ", token);

  return {
    id: uuid_v4(),
    subject: "token://" + token,
    data: [],
    analytics: {},
    webVersion: Package.version
  };
};

const buildDataItem = async (type, source, payload) => {
  console.log("[submissionEnvelopeBuilder] building data item");

  return {
    acquired: {
      localDateTime: DateTime.local().toFormat("kkkk-MM-dd'T'HH:mm:ss"),
      offset: DateTime.local().toFormat("ZZZ"),
    },
    type: type,
    source: source,
    payload: payload,
  };
};

const generateSubmissionItem = async (type, source, payload) => {
  console.log("[submissionEnvelopeBuilder] enveloping the payload: ", payload);
  let submissionEnvelope = buildSubmissionDataEnvelope();
  submissionEnvelope.data.push(await buildDataItem(type, source, payload));

  return submissionEnvelope;
};

const packageAndSend = async (
  type,
  source,
  passedPayload,
  subjectId = null,
  isSignOff
) => {
  console.log("[SubmissionQueueService] adding to app feed queue");
  let payload = _.cloneDeep(passedPayload);

  if (typeof payload === "object") {
    payload = JSON.stringify(payload);
  }

  if (typeof payload === "string") {
    payload = "lz-base64://" + LZString.compressToBase64(payload);
    if (payload.length % 16) {
      var c,
        additional = 16 - (payload.length % 16);
      for (c = 0; c < additional; c++) {
        payload = payload + " ";
      }
    }
  }

  const submissionQueueItem = await generateSubmissionItem(
    type,
    source,
    payload
  );
  return sendData(submissionQueueItem, subjectId, isSignOff);
};

const sendData = async (payload, subjectId, isSignOff = false) => {
  console.log("[SubmissionQueueService] attempting to send data to backend");
  const baseUrl = process.env.REACT_APP_SERVER_ADDRESS;

  let submissionRoute = "/data"
  if(subjectId){
    submissionRoute = submissionRoute + "/" + subjectId;
  }
  if(isSignOff){
    submissionRoute = submissionRoute + "/sign-off";
  }

  return RequestHelper.send(baseUrl + submissionRoute, {}, "POST", {}, payload);
};

const sendAttachment = async (payload, headers) => {
  const baseUrl = process.env.REACT_APP_SERVER_ADDRESS;
  const submissionRoute = "/attachments/uploadmultipartfile";
  return RequestHelper.send(
    baseUrl + submissionRoute,
    headers,
    "POST",
    {},
    payload
  );
};

export default { generateSubmissionItem, packageAndSend, sendAttachment };
