import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Icon, Message } from "semantic-ui-react";

const ChildQuestionnaire = (props) => {
  const { t } = props;
  const { questionnaire, label, isCompleted, completedStatusText, ariaLabelText, questionnaireLink } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Message
      key={questionnaire.id}
      negative={!isCompleted}
      positive={isCompleted}
      style={{ marginBottom: 10, padding: 8 }}
      aria-label={ariaLabelText}
    >
      {!isExpanded && <Icon name="plus square outline" onClick={handleExpandToggle} className={"mouse-pointer"} aria-label={t('GENERIC_EXPAND', 'Open')} />}
      {isExpanded && <Icon name="minus square outline" onClick={handleExpandToggle} className={"mouse-pointer"} aria-label={t('GENERIC_SHRINK', 'Close')} />}

      {label}

      {isExpanded && (
        <div>
          {completedStatusText}
          <br />
          {questionnaireLink}
          <br />
          {questionnaire.id}
        </div>
      )}
    </Message>
  )
};

export default withTranslation()(ChildQuestionnaire);
