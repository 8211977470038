import { typeHelper } from "atom5-branching-questionnaire";

export default class ConfigService {
  static canStaffViewAndEditSubjectEmail() {
    const value = Window.configuration?.ui?.viewAndEditSubjectEmail
      ? typeHelper.parseBool(Window.configuration?.ui?.viewAndEditSubjectEmail)
      : false;
    return value;
  }
  static canSubjectViewAndEditOwnEmail() {
    const value = Window.configuration?.ui?.canSubjectViewAndEditOwnEmail
      ? typeHelper.parseBool(
          Window.configuration?.ui?.canSubjectViewAndEditOwnEmail
        )
      : true;
    return value;
  }
  static canStaffEditOwnDetails() {
    const value = Window.configuration?.ui?.canStaffEditOwnDetails
      ? typeHelper.parseBool(
          Window.configuration?.ui?.canStaffEditOwnDetails
        )
      : true;
    return value;
  }
  static isNotificationEnabled() {
    const value = Window.configuration?.ui?.notifications?.isEnabled
      ? typeHelper.parseBool(Window.configuration?.ui?.notifications?.isEnabled)
      : true;
    return value;
  }
  static showPreferredNotificationTime() {
    const value = Window.configuration?.ui?.notifications
      ?.showPreferredNotificationTime
      ? typeHelper.parseBool(
          Window.configuration?.ui?.notifications?.showPreferredNotificationTime
        )
      : true;
    return value;
  }
  static shouldShowQuestionnairesCreatedDateColumn() {
    const value = Window.configuration?.ui?.questionnaires
        ?.showCreatedDateColumn
        ? typeHelper.parseBool(
            Window.configuration?.ui?.questionnaires?.showCreatedDateColumn
        )
        : false;
    return value;
  }

  static shouldShowQuestionnaireIdColumn() {
    return typeHelper.parseBool(Window.configuration?.ui?.questionnaires?.showIdColumn);
  }
  
  static shouldShowQuestionnaireDeliveredDateColumn() {
    return typeHelper.parseBool(Window.configuration?.ui?.questionnaires?.showDeliveredDateColumn);
  }

  static shouldShowQuestionnaireCompletedByColumn() {
    return typeHelper.parseBool(Window.configuration?.ui?.questionnaires?.showCompletedByColumn);
  }

  static shouldShowQuestionnaireLocationColumn() {
    const value = Window.configuration?.ui?.questionnaires?.showLocationColumn
        ? typeHelper.parseBool(
            Window.configuration?.ui?.questionnaires?.showLocationColumn
        )
        : false;
    return value;
  }

  static shouldShowCardActivationFromDateColumn() {
    //defaults to true
    return Window.configuration?.ui?.dashboard?.cards?.showActivationFromDate
        ? typeHelper.parseBool(
            Window.configuration?.ui?.dashboard?.cards?.showActivationFromDate
        )
        : true;
  }
  static shouldShowCardSubjectCodeColumn() {
    //defaults to true
    return Window.configuration?.ui?.dashboard?.cards?.showSubjectCode
        ? typeHelper.parseBool(
            Window.configuration?.ui?.dashboard?.cards?.showSubjectCode
        )
        : true;
  }
  static shouldShowCardEncodedQuestionnaireIdColumn() {
    //defaults to true
    return Window.configuration?.ui?.dashboard?.cards?.showEncodedQuestionnaireId
        ? typeHelper.parseBool(
            Window.configuration?.ui?.dashboard?.cards?.showEncodedQuestionnaireId
        )
        : true;
  }
  static shouldCardRowsBeShuffled() {
    return Window.configuration?.ui?.dashboard?.cards?.shuffleRows
        ? typeHelper.parseBool(
            Window.configuration?.ui?.dashboard?.cards?.shuffleRows
        )
        : false;
  }
  static enforceTriggerAuditLogQuestionnaireConstraint() {
    const value = Window.configuration?.ui?.questionnaires
        ?.enforceTriggerAuditLogConstraint
        ? typeHelper.parseBool(
            Window.configuration?.ui?.questionnaires?.enforceTriggerAuditLogConstraint
        )
        : true;
    return value;
  }
  static shouldShowQuestionnairesTriggerColumn() {
    return typeHelper.parseBool(Window.configuration?.ui?.questionnaires?.showTriggerColumn);
  }

  static shouldAllowStaffSubmissionDefault() {
    const value = Window.configuration?.ui?.questionnaires
        ?.allowStaffSubmissionDefault
        ? typeHelper.parseBool(
            Window.configuration?.ui?.questionnaires?.allowStaffSubmissionDefault
        )
        : false;
    return value;
  }
  static subjectQuestionnaireOpeningMode() {
    return Window.configuration?.ui?.subjectQuestionnaireOpeningMode || "LINK";
  }
  static getDefaultThemeKey() {
    return Window.configuration?.ui?.theme?.defaultTheme;
  }
  static getQuestionnaireTableColumnOrder(definition, workflow, task) {
    const taskSpecificOrder = workflow !== undefined && task !== undefined && Window.configuration?.ui?.questionnaires?.workflows?.[workflow.code]?.tasks?.[task.code]?.questionnaireTableColumnOrder;
    if(taskSpecificOrder) return taskSpecificOrder;

    const workflowSpecificOrder = workflow !== undefined && Window.configuration?.ui?.questionnaires?.workflows?.[workflow.code]?.questionnaireTableColumnOrder;
    if(workflowSpecificOrder) return workflowSpecificOrder;

    const workflowOrder = workflow !== undefined && Window.configuration?.ui?.questionnaires?.workflows?.questionnaireTableColumnOrder;
    if(workflowOrder) return workflowOrder;

    const definitionSpecificOrder = Window.configuration?.ui?.questionnaires?.[definition.code]?.questionnaireTableColumnOrder;
    if(definitionSpecificOrder) return definitionSpecificOrder;

    return Window.configuration?.ui?.questionnaires?.questionnaireTableColumnOrder;
  }
  static shoulDisablePasswordAutoComplete() {
    const value = Window.configuration?.passwords
      ?.shoulDisablePasswordAutoComplete
      ? typeHelper.parseBool(
          Window.configuration?.passwords?.shoulDisablePasswordAutoComplete
        )
      : true;
    return value;
  }
}
