import React, { useContext } from "react";
import i18next from "i18next";
import { DateTime } from "luxon";
import API_QUESTIONNAIRE_DEFINITION_TYPES from "../../constants/API_QUESTIONNAIRE_DEFINITION_TYPES";
import ConfigService from "../../services/ConfigService";
import { Card, Icon } from "semantic-ui-react";
import DynamicTextService from "../../services/DynamicTextService";
import { useHistory, useLocation } from "react-router-dom";
import QuestionnaireContext from "../../context/QuestionnaireContext";

const SingleQuestionnaireDisplay = (props) => {
  const { questionnaire, definition, action, readOnly } = props;
  const isCompleted = questionnaire?.completionDate !== null;
  const isQuiet = definition?.config?.quiet;
  const isAlternateDisplay = readOnly || isQuiet || isCompleted;

  const history = useHistory();
  const location = useLocation();

  const setReturnTo = useContext(QuestionnaireContext).setReturnTo;

  const linkId = questionnaire ? questionnaire.id : definition.id;

  const onCompletedText =
    definition.type === API_QUESTIONNAIRE_DEFINITION_TYPES.CONTENT
      ? "GLOBAL_READ_DATE"
      : "SUBJECT_TAB_DATA_TABLE_HEADER_COMPLETED_QUESTIONNAIRES";

  const configLinkOrModal = ConfigService.subjectQuestionnaireOpeningMode();

  const onClick = (() => {
    if (readOnly) {
      return null;
    }
    if (configLinkOrModal === "MODAL") {
      return () => action(questionnaire, definition);
    }
    if (configLinkOrModal !== "LINK") {
      console.warn(
        `${configLinkOrModal} is not a valid value for configLinkOrModal.`
      );
    }
    return () => {
      setReturnTo(location.pathname);
      history.push("/app/subject/questionnaire/" + linkId);
    };
  })();

  let label = definition.label;
  if (definition?.config?.titleDateFormat) {
    let activationFromDate = questionnaire.activationFromDate;
    let titleDateFormat = definition.config?.titleDateFormat;

    let dynamicDate = DynamicTextService.getDateInFormat(
      activationFromDate,
      titleDateFormat
    );
    label += " " + dynamicDate;
  }

  return (
    <Card
      fluid
      style={{
        ...styles.card,
        ...{
          backgroundColor: isAlternateDisplay ? "white" : "#f8991d",
          color: isAlternateDisplay ? "f8991d" : "white",
        },
      }}
      onClick={onClick}
    >
      <div style={styles.cardContentContainer}>
        <div style={styles.cardContent}>
          <Card.Header
            style={{
              ...styles.cardHeader,
              color: isAlternateDisplay ? "#444" : "white",
            }}
          >
            {label}
          </Card.Header>
          {questionnaire && !isCompleted && questionnaire.activationToDate && (
            <Card.Meta
              style={{ color: isAlternateDisplay ? "#444" : "#eeeeee" }}
            >
              {i18next.t("SUBJECT_TAB_DATA_TABLE_AVAILABILITY_HEADER") +
                ": " +
                DateTime.fromISO(questionnaire.activationToDate).toLocaleString(
                  DateTime.DATE_SHORT
                )}
            </Card.Meta>
          )}
          {questionnaire && isCompleted && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Icon name={"check"} color={"orange"}></Icon>
              <Card.Meta
                style={{ color: isAlternateDisplay ? "#444" : "white" }}
              >
                {i18next.t(onCompletedText) +
                  ": " +
                  DateTime.fromISO(questionnaire.completionDate).toLocaleString(
                    DateTime.DATE_SHORT
                  )}
              </Card.Meta>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

const styles = {
  card: {
    padding: "1.5rem",
    borderRadius: 20,
    boxShadow: "0px 4px 16px rgba(0,0,0,0.1)",
  },
  cardContentContainer: {
    display: "flex",
    justifyContent: "center",
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  cardHeader: {
    fontSize: 20,
    paddingBottom: 5,
  },
};

export default SingleQuestionnaireDisplay;
