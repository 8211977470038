import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardGroup, Grid, Label, Loader } from "semantic-ui-react";
import ConfigContext from "../../../context/ConfigContext";
import DashboardService from "../../../services/DashboardService";

const ComplianceCardGroup = ({ t }) => {
  const config = useContext(ConfigContext);
  const complianceConfig = config.ui.dashboard.cards.compliance;

  const [loading, setLoading] = useState(true);
  const [compliance, setCompliance] = useState(true);

  const getCompliance = async () => {
    const compliance = await DashboardService.getCompliance({
      pro: config.pro?.lowThreshold,
      medication: config.medication?.lowThreshold,
      wearable: config.wearable?.lowThreshold,
    });
    setCompliance(compliance);
    setLoading(false);
  };

  useEffect(() => {
    getCompliance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proComplianceSort = (a, b) =>
    a.proCompliance < b.proCompliance ? -1 : 1;
  const medicationComplianceSort = (a, b) =>
    a.proCompliance < b.proCompliance ? -1 : 1;
  const wearableComplianceSort = (a, b) =>
    a.proCompliance < b.proCompliance ? -1 : 1;

  return (
    <CardGroup itemsPerRow={3}>
      {complianceConfig.pro && (
        <ComplianceInfoCard
          t={t}
          translationSuffix="PRO"
          loading={loading}
          sort={proComplianceSort}
          min={compliance.proComplianceMin}
          max={compliance.proComplianceMax}
          mean={compliance.proComplianceMean}
          low={compliance.proComplianceLow}
          complianceKey="proCompliance"
        />
      )}
      {complianceConfig.medication && (
        <ComplianceInfoCard
          t={t}
          translationSuffix="MEDICATION"
          loading={loading}
          sort={medicationComplianceSort}
          min={compliance.medicationComplianceMin}
          max={compliance.medicationComplianceMax}
          mean={compliance.medicationComplianceMean}
          low={compliance.medicationComplianceLow}
          complianceKey="medicationCompliance"
        />
      )}
      {complianceConfig.wearable && (
        <ComplianceInfoCard
          t={t}
          translationSuffix="WEARABLE"
          loading={loading}
          sort={wearableComplianceSort}
          min={compliance.wearableComplianceMin}
          max={compliance.wearableComplianceMax}
          mean={compliance.wearableComplianceMean}
          low={compliance.wearableComplianceLow}
          complianceKey="wearableCompliance"
        />
      )}
    </CardGroup>
  );
};

const ComplianceInfoCard = ({
  t,
  translationSuffix,
  loading,
  sort,
  min,
  max,
  mean,
  low,
  complianceKey,
}) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header style={{ paddingBottom: "1rem" }}>
          {t("HOME_CARD_COMPLIANCE_" + translationSuffix)}
        </Card.Header>
        {loading && <Loader />}
        {!loading && (
          <Grid>
            <Grid.Row centered>
              <Grid.Column width={5}>
                <p style={{ textAlign: "center" }}>
                  {t("HOME_CARD_COMPLIANCE_MIN")}
                </p>
              </Grid.Column>
              <Grid.Column width={5}>
                <p style={{ textAlign: "center" }}>
                  {t("HOME_CARD_COMPLIANCE_MEAN")}
                </p>
              </Grid.Column>
              <Grid.Column width={5}>
                <p style={{ textAlign: "center" }}>
                  {t("HOME_CARD_COMPLIANCE_MAX")}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered style={{ padding: "0 0 0.5rem 0" }}>
              <Grid.Column
                width={5}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Label size={"large"}>{Math.round(min) + " %"}</Label>
              </Grid.Column>
              <Grid.Column
                width={5}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Label size={"large"}>{Math.round(mean) + " %"}</Label>
              </Grid.Column>
              <Grid.Column
                width={5}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Label size={"large"}>{Math.round(max) + " %"}</Label>
              </Grid.Column>
            </Grid.Row>
            {low.length !== 0 && <></>}
            <>
              <Grid.Row>
                <Grid.Column
                  style={{ display: "flex", alignItems: "center" }}
                  width={16}
                >
                  <p style={{ paddingRight: "1rem" }}>
                    {t("HOME_CARD_COMPLIANCE_LOW")}
                  </p>
                  <Label>{low.length}</Label>
                </Grid.Column>
              </Grid.Row>
              {low
                .sort(sort)
                .slice(0, 3)
                .map((subjectCompliance) => (
                  <Grid.Row style={{ padding: "0.25rem" }}>
                    <Grid.Column width={6}>
                      <Link
                        to={`/app/subject/${subjectCompliance.subjectId}/tabs/subject-record/details`}
                      >
                        <Label color={"orange"}>
                          {subjectCompliance.subjectCode}
                        </Label>
                      </Link>
                    </Grid.Column>
                    <Grid.Column
                      width={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <p>
                        {isNaN(Math.round(subjectCompliance[complianceKey]))
                          ? "0 %"
                          : Math.round(subjectCompliance[complianceKey]) + " %"}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              <Grid.Row style={{ padding: "0.5rem" }} />
            </>
          </Grid>
        )}
      </Card.Content>
    </Card>
  );
};

export default withTranslation()(ComplianceCardGroup);
