import RequestHelper from "../../RequestHelper";


export const  updateLogLevel = async (logName, logLevel) => {
    const data = {}
    data[logName] = logLevel;
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/trial/configuration/logging",
      {},
      "PUT",
      null,
      data
    );
  }