import React, { useContext, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Grid, Icon, Loader, Message, Table } from "semantic-ui-react";
import Page from "../../components/page/Page";
import UserContext from "../../context/UserContext";
import QuestionnaireDefinitionService from "../../services/admin/QuestionnaireDefinitionService";
import AdminQuestionnaireDefinitionViewModal from "../../components/admin/AdminQuestionnaireDefinitionViewModal";
import { Link } from "react-router-dom";
import FileSaver from 'file-saver';
import * as _ from "lodash";
import AdminQuestionnaireDefinitionDuplicateModal from "../../components/admin/AdminQuestionnaireDefinitionDuplicateModal";

const AdminQuestionnaireDefinitionListPage = ({ t }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [questionnaireDefinitions, setQuestionnaireDefinitions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [monitoredSearchTerm, setMonitoredSearchTerm] = useState("");
  const [selected, setSelected] = useState(null);
  const [toClone,setToClone] = useState(null);
  const [jsonExporting,setJsonExporting] = useState(false);

  const user = useContext(UserContext);
  const isSuperAdmin = user?.profile?.superAdmin;

  const getQuestionnaireDefinitionList = async () => {
    try {
      const questionnaireDefinitionList =
        await QuestionnaireDefinitionService.getQuestionnaireDefinitions(
          monitoredSearchTerm
        );
      if (questionnaireDefinitionList) {
        setQuestionnaireDefinitions(questionnaireDefinitionList);
        setError(false);
      } else {
        setError(true);
      }

      setLoading(false);
    } catch (e) {
      console.error("Error fetching triggers: ", e);
      setError(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    getQuestionnaireDefinitionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoredSearchTerm]);

  const createTable = (questionnaireDefinitions) => {
    if (!questionnaireDefinitions) {
      return;
    }

    const headers = [
      {
        title: ["ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_CODE", "Code"],
        config: { width: 4 },
      },
      {
        title: ["ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_TYPE", "Type"],
        config: { width: 3 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_SEQUENCE",
          "Sequence",
        ],
        config: { width: 2 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_RESTRICTTO",
          "Restrict to",
        ],
        config: { width: 2 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_NONSCORING",
          "Non Scoring",
        ],
        config: { width: 2 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_SCORECALCULATION",
          "Score Calculation",
        ],
        config: { width: 4 },
      },
      {
        title: [
          "ADMIN_QUESTIONNAIRE_DEFINITION_TABLE_HEADER_ACTION",
          "Action",
        ],
        config: { width: 2 },
      },
    ];

    const cellStyle = { textOverflow: "elipses", whiteSpace: "nowrap" };

    const headerRow = (
      <Table.Header>
        {headers.map((header) => (
          <Table.HeaderCell {...header?.config}>
            {t(header.title)}
          </Table.HeaderCell>
        ))}
      </Table.Header>
    );
    const questionnaireDefinitionRows = questionnaireDefinitions.map(
      (questionnaireDefinition, index) => (
        <Table.Row
          key={index}
          active={questionnaireDefinition.id === selected}
          style={loading ? { backgroundColor: "#eee", color: "#999" } : {}}
          onClick={
            loading ? () => {} : () => setSelected(questionnaireDefinition.id)
          }
        >
          <Table.Cell>{questionnaireDefinition.code}</Table.Cell>
          <Table.Cell style={cellStyle}>
            {questionnaireDefinition.type}
          </Table.Cell>
          <Table.Cell style={cellStyle}>
            {questionnaireDefinition.sequence}
          </Table.Cell>
          <Table.Cell style={cellStyle}>
            {questionnaireDefinition.restrictTo != null ? questionnaireDefinition.restrictTo : 'ANY'}
          </Table.Cell>
          <Table.Cell style={cellStyle}>
            {questionnaireDefinition.nonscoring}
          </Table.Cell>
          <Table.Cell>{questionnaireDefinition.scoreCalculation}</Table.Cell>
          <Table.Cell>
              <Icon style={{cursor: "pointer"}} color="orange" onClick={(e) => {e.stopPropagation(); setToClone(questionnaireDefinition.code) }} name='copy' />
              <Icon style={{cursor: "pointer"}} color={jsonExporting ? "grey" : "orange" } inverted={jsonExporting} disabled={jsonExporting} onClick={(e) => {e.stopPropagation(); exportToJson(questionnaireDefinition.code)}} name='download' />
              <Button
                  primary
                  fluid
                  size="tiny"
                  as={Link}
                  to={
                    "/app/utils/questionnaire-definitions/full-edit/" +
                    questionnaireDefinition.id
                  }
              >
                {t("ADMIN_QUESTIONNAIERDEFINITION_Edit", "Edit")}
              </Button>
          </Table.Cell>
        </Table.Row>
      )
    );
    return (
      <>
        {headerRow}
        {questionnaireDefinitionRows}
      </>
    );
  };

  const exportToJson = async (code) => {
    setJsonExporting(true);
    try{
      const jsonContent = await QuestionnaireDefinitionService.getQuestionnaireDefinitionJsonExport(code)
      const blob = new Blob([JSON.stringify(jsonContent,null, 2)], {
        type: "text/plain;charset=utf-8"
      });
      let jsDate = new Date();
      //jsDate.setTime( jsDate.getTime() + jsDate.getTimezoneOffset() * 60 * 1000 );
      FileSaver.saveAs(blob, "qd-export-" + jsDate.toISOString().slice(0, 19) + "-" + code + ".json");
      setJsonExporting(false);
    }catch(response){
      setJsonExporting(false);
      console.log("Error exporting JSON:" + response);
    }
  }

  const updateMonitoredSearchTerm = function (value) {
    setMonitoredSearchTerm(value);
  };

  const debouncedSeachTermUpdate = useRef(
    _.debounce(updateMonitoredSearchTerm, 500)
  );

  const setAutoSearchValue = (value) => {
    setSearchTerm(value);
    updateMonitoredSearchTerm(value);
  }

  return (
    <Page
      name="Admin Questionnaire Definition Utilities"
      header={t(
        "ADMIN_QUESTIONNAIRE_DEFINITIONS_HEADER",
        "Admin Questionnaire Definitions"
      )}
    >
      {error && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={"Error, if it persist contact support:" + error.message}
        />
      )}
      {loading && questionnaireDefinitions.length === 0 && (
        <Loader active={loading} />
      )}
      {!isSuperAdmin && (
        <Message
          error
          header={t("GLOBAL_ERROR_TITLE", "Error")}
          content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
        />
      )}
      {isSuperAdmin && Array.isArray(questionnaireDefinitions) && (
        <>
          <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column width={11}>
              <Form.Field>
                <Form.Input
                  icon={<Icon name="search" />}
                  iconPosition={"left"}
                  value={searchTerm}
                  onChange={(e, v) => {
                    setLoading(true);
                    setSearchTerm(v.value);
                    debouncedSeachTermUpdate.current(v.value);
                  }}
                />
              </Form.Field>
            </Grid.Column>
              <Grid.Column width={5}>
                <Button
                  as={Link}
                  to="/app/utils/questionnaire-definitions/new"
                  fluid
                  primary
                >
                {t("ADMIN_QUESTIONNAIERDEFINITION_NEW", "Create New")}
                </Button>
              </Grid.Column>
            </Grid.Row>
            </Grid>
          </Form>
              
                  
          <Table fixed id={"questionnaireDefinitionsList"}>
            {createTable(questionnaireDefinitions)}
          </Table>
          <AdminQuestionnaireDefinitionViewModal
            questionnaireDefinitionId={selected}
            isOpen={selected !== null}
            setIsOpen={() => setSelected(null)}
          />
          <AdminQuestionnaireDefinitionDuplicateModal
            questionnaireDefinitionCode={toClone}
            isOpen={toClone !== null}
            setIsOpen={() => {setToClone(null)}}
            setSearchTermCallback={setAutoSearchValue}

          />
        </>
      )}
    </Page>
  );
};

export default withTranslation()(AdminQuestionnaireDefinitionListPage);
