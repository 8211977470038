export default class LocalStorageHelper {
  static setString(key, value) {
    let stringValue;
    if (value !== null && value !== undefined) {
      try {
        stringValue = value.toString();
        localStorage.setItem(key, stringValue);
      } catch (error) {
        console.error(
          "[LocalStorageHelper][setString] Error",
          key,
          stringValue,
          error
        );
      }
    } else {
      LocalStorageHelper.deleteValue(key);
    }
  }

  static getString(key) {
    return localStorage.getItem(key);
  }

  static setJson(key, value) {
    const jsonValue = JSON.stringify(value);
    try {
      localStorage.setItem(key, jsonValue);
    } catch (error) {
      console.error(
        "[LocalStorageHelper][setJson] Error",
        key,
        jsonValue,
        error
      );
    }
  }

  static getJsonArray(key) {
    const stringValue = localStorage.getItem(key);
    if (stringValue) {
      return JSON.parse(stringValue);
    }
    return [];
  }

  static getJsonObject(key) {
    const stringValue = localStorage.getItem(key);
    if (stringValue) {
      return JSON.parse(stringValue);
    }
    return {};
  }

  static setBoolean(key, value) {
    let stringValue;
    if (value !== null && value !== undefined) {
      stringValue = value.toString();
      try {
        localStorage.setItem(key, stringValue);
      } catch (error) {
        console.error(
          "[LocalStorageHelper][setBoolean] Error",
          key,
          stringValue,
          error
        );
      }
    } else {
      LocalStorageHelper.deleteValue(key);
    }
  }

  static getBoolean(key, defaultValue = false) {
    const storedValue = localStorage.getItem(key);
    const isEmpty = storedValue === undefined || storedValue === null;
    const value = isEmpty
      ? defaultValue
      : storedValue === true.toString()
      ? true
      : false;
    return value;
  }

  static deleteValue(key) {
    localStorage.removeItem(key);
  }

  static clear() {
    localStorage.clear();
  }
}
