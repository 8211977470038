import React, { Component } from "react";
import Chart from "react-apexcharts";
import * as PropTypes from "prop-types";

export default class ZoomableGraph extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  // componentDidUpdate = (prevProps) => {
  //     if (prevProps.show !== this.props.show) {
  //         this.setState({visible: this.props.show});
  //     }
  // };

  componentDidMount() {
    this.drawGraph();
  }

  componentWillReceiveProps(nextProps) {
    this.drawGraph();
  }

  drawGraph() {
    let series = [];
    if (Array.isArray(this.props.data)) {
      series.push({
        name: this.props.title || "",
        data: this.props.data,
      });
    } else {
      let fieldName;
      for (fieldName in this.props.data) {
        if (Object.prototype.hasOwnProperty.call(this.props.data, fieldName)) {
          series.push({
            name: fieldName,
            data: this.props.data[fieldName],
          });
        }
      }
    }
    let yScale = this.props.yScale || 1000;
    this.setState({
      series,
      options: {
        chart: {
          type: "area",
          stacked: true,
          height: 350,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: false,
          },
          toolbar: {
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: this.props.title || "",
          align: "left",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return (val / yScale).toFixed(0) * yScale;
            },
          },
          title: {
            text: this.props.yAxis || "",
          },
        },
        xaxis: {
          type: this.props.xAxisType || "datetime",
          title: {
            text: this.props.xAxis || "",
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
      },
      loading: false,
    });
  }

  render() {
    return (
      <div id="chart">
        {!this.state.loading && (
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="area"
            height={350}
          />
        )}
      </div>
    );
  }
}

ZoomableGraph.propTypes = {
  title: PropTypes.string,
  yAxis: PropTypes.string,
  yScale: PropTypes.number,
  data: PropTypes.object.isRequired,
};

ZoomableGraph.defaultProps = {
  t: (v) => v,
};
