import React, {useContext, useEffect, useState} from "react";
import { Grid } from "semantic-ui-react";
import QuestionnaireTableCard from "../../components/dashboard/cards/QuestionnaireTableCard";
import UserContext from "../../context/UserContext";
import ConfigContext from "../../context/ConfigContext";

export default function CardDisplay({ cardArray }) {
  const [cardDisplay, setCardDisplay] = useState([]);
  const user = useContext(UserContext);
  const config = useContext(ConfigContext);

  const moduleVisible = (card) => {
    //if card doesn't have modules configured, show it
    if(!card.config.modules || card.config.modules.length===0){
        return true;
    }
    const roles = config.ui.tabs.filter(t=>{
      let moduleName;
      if(typeof t === "string"){
        moduleName = t.replaceAll('module/', '')
      } else {
        moduleName = t.name.replaceAll('module/', '')
      }
      return card.config.modules.includes(moduleName)
    }).flatMap(m => m.roles)
    //if module doesn't have roles configured, show it
    if(!roles || roles.length ===0){
      return true
    }
    const userRoles = user.profile.groupMappings.flatMap(g=>g.roles)
    const matchingRoles = userRoles.filter(ur=>{
      if(roles.includes(ur.code)){
        return true
      }else{
        return false;
      }
    })
    return matchingRoles && matchingRoles.length>0;
  }
  const buildCardDisplay = (questionnaireAggregateCards) => {
    return questionnaireAggregateCards?.map((card) => {
      if(!moduleVisible(card)) return null;
      switch (card.type) {
        case "GENERIC_QUESTIONNIARE_LIST":
          return <QuestionnaireTableCard card={card} allQuestionnaireDefinitions={[]}/>;
        default:
          return null;
      }
    });
  };

  useEffect(() => {
    setCardDisplay(buildCardDisplay(cardArray ? cardArray : []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Grid style={{ padding: "1rem" }}>{cardDisplay}</Grid>;
}
