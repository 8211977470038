import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import BatchAttachmentHelpers from '../BatchAttachmentHelpers';
import { withTranslation } from 'react-i18next';
import { VALIDATION_MESSAGES } from '../constants/VALIDATION_MESSAGES';

const GridCell = (props) => {
  const { t } = props;
  const cellData = props.data[BatchAttachmentHelpers.getHeaderRef(props.colDef?.field)];

  const isRowStatusCell = cellData.type === '__row_status';

  const hasWarnings = (Array.isArray(cellData?.warnings) && cellData?.warnings.length > 0) || cellData.rowHasWarnings;
  const hasErrors = (Array.isArray(cellData?.errors) && cellData?.errors.length > 0) || cellData.rowHasErrors;
  const hasPopup = !isRowStatusCell && (hasWarnings || hasErrors);

  const style = {
    color: undefined,
    backgroundColor: undefined
  };
  let icon;
  let cellText = props.value;

  if (hasWarnings) {
    style.color = 'white';
    style.backgroundColor = 'orange';
    icon = (<Icon name={'info circle'} />);
    if (isRowStatusCell) {
      cellText = t('BATCHUPLOADEDFILELIST_GRID_ROW_HAS_WARNINGS', 'Row has Warnings');
    }
  }

  if (hasErrors) {
    style.color = 'white';
    style.backgroundColor = 'red';
    icon = (<Icon name={'warning sign'} />);
    if (isRowStatusCell) {
      cellText = t('BATCHUPLOADEDFILELIST_GRID_ROW_HAS_ERRORS', 'Row has Errors');
    }
  }

  if (isRowStatusCell) {
    if (!hasWarnings && !hasErrors) {
      style.color = 'white';
      style.backgroundColor = 'green';
      icon = (<Icon name={'check circle'} />);
      cellText = t('BATCHUPLOADEDFILELIST_GRID_ROW_IS_VALID', 'Row is Valid');
    }
  }

  const cellContents = (
    <div style={style}>
      {icon}
      {cellText}
    </div>
  );

  const buildCellPopupContent = () => {
    const allMessages = [...cellData.errors, ...cellData.warnings];
    const listItems = allMessages.map((error, index) => {
      let message = error;
      if (Object.keys(VALIDATION_MESSAGES).includes(error)) {
        const validationMessage = VALIDATION_MESSAGES[error];
        message = t(validationMessage.translationKey, validationMessage.fallbackText);
      }
      return <div key={index}>{message}</div>;
    });
    return listItems;
  };

  return (
    <>
      {!hasPopup && cellContents}
      {hasPopup && (
        <Popup trigger={cellContents}>
          {buildCellPopupContent()}
        </Popup>
      )}
    </>
  );
};

export default withTranslation()(GridCell);
