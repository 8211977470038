import { createSelector, createSlice } from "@reduxjs/toolkit";

const questionnaireDefinitionsSlice = createSlice({
  name: "questionnaireDefinitions",
  initialState: {
    status: "initial",
    proDefinitions: [],
    contentDefinitions: [],
    eventDefinitions: [],
    dataDefinitions: [],
  },
  reducers: {
    resetState: (state, action) => {
      state.status = "initial";
      state.proDefinitions = [];
      state.contentDefinitions = [];
      state.eventDefinitions = [];
      state.dataDefinitions = [];
    },
    setDefinitions: (state, action) => {
      const { payload } = action;
      const {
        proDefinitions,
        contentDefinitions,
        eventDefinitions,
        dataDefinitions,
      } = payload;

      if (proDefinitions) {
        state.proDefinitions = proDefinitions;
      }

      if (contentDefinitions) {
        state.contentDefinitions = contentDefinitions;
      }

      if (eventDefinitions) {
        state.eventDefinitions = eventDefinitions;
      }

      if (dataDefinitions) {
        state.dataDefinitions = dataDefinitions;
      }
    },
  },
});

const getDefinitions = (store) => [
  ...store.questionnaireDefinitions.proDefinitions,
  ...store.questionnaireDefinitions.contentDefinitions,
  ...store.questionnaireDefinitions.eventDefinitions,
  ...store.questionnaireDefinitions.dataDefinitions,
];
const getProDefinitions = (store) =>
  store.questionnaireDefinitions.proDefinitions;
const getContentDefinitions = (store) =>
  store.questionnaireDefinitions.contentDefinitions;
const getEventDefinitions = (store) =>
  store.questionnaireDefinitions.eventDefinitions;
const getDataDefinitions = (store) =>
  store.questionnaireDefinitions.dataDefinitions;

// https://github.com/reduxjs/reselect/blob/master/README.md#q-how-do-i-create-a-selector-that-takes-an-argument
const getDefinitionWithCodeCreator = createSelector(
  [getDefinitions],
  (definitions) => {
    return (code) => {
      let foundDefinition = definitions.find((def) => def.code === code);
      if (foundDefinition) {
        return foundDefinition;
      }
      // TODO: error dispatching
      return false;
    };
  }
);

export {
  getDefinitions,
  getProDefinitions,
  getContentDefinitions,
  getEventDefinitions,
  getDataDefinitions,
  getDefinitionWithCodeCreator,
};

export const { resetState, setDefinitions } =
  questionnaireDefinitionsSlice.actions;

export default questionnaireDefinitionsSlice;
