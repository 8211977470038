import React, {useMemo} from 'react';
import useFullPageLayoutPortal from "../../hooks/useFullPageLayoutPortal";
import HelpSectionDisplay from "./HelpSectionDisplay";
import {connect} from "react-redux";
import {getContentDefinitions} from "../../redux/questionnaires/questionnaireDefinitionsSlice";

const HelpSection = ({contentDefinitions}) => {
  const [createPortal, FixedPosition] = useFullPageLayoutPortal();

  const helpDefinitions = useMemo(()=>{
    return contentDefinitions
        .filter(definition => {return definition?.config?.modules?.includes("reserved-module-help")})
        .sort((a,b)=> a.sequence - b.sequence)

  }, [contentDefinitions]);

  if(helpDefinitions?.length === 0) return null;

  return createPortal(
      <FixedPosition right={24} bottom={12}>
        <HelpSectionDisplay definitions={helpDefinitions} />
      </FixedPosition>);
}

const mapStateToProps = (state) => {
  return {
    contentDefinitions: getContentDefinitions(state),
  };
};

export default connect(mapStateToProps)(HelpSection);