const QuestionHelper = {
  getConfigValue: (question, key, defaultValue) => {
    const config = question?.config;
    if (!config) {
      return defaultValue;
    }
    const renderAs = config[key] !== undefined ? config[key] : defaultValue;
    return renderAs;
  },
  shuffleArray: (array) => {
    let shuffled = array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    return shuffled;
  },
};

export default QuestionHelper;
