import React, { memo, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Label, Menu, Popup } from 'semantic-ui-react';
import GeneralHelpers from '../../../../helpers/GeneralHelpers';
import LayoutHelpers from '../../../../helpers/LayoutHelpers';
import { useFullScreen } from '../../../../context/FullScreenContext';

const PlaybackRateControls = ({
  t,
  controlState,
  playbackRateOptions,
  onChange,
  popupSize = 'tiny'
}) => {
  const styles = {
    popup: {
      borderRadius: '0.28rem',
      opacity: 0.8,
    }
  }

  const fullScreenContext = useFullScreen();
  const [menuPopupPosition, setMenuPopupPosition] = useState(false);

  const selectedRate = controlState.playbackRate || 1;

  const availablePlaybackRates = playbackRateOptions?.rates || [0.1, 0.2, 0.5, 0.75, 1, 1.25, 1.5, 2, 5];
  if (!availablePlaybackRates.includes(selectedRate)) {
    availablePlaybackRates.push(selectedRate);
  }

  const formatValueForOutput = (value) => {
    return `${value}x`;
  }

  const buildPlaybackRateList = () => {
    return availablePlaybackRates.map(v => {
      return {
        key: v,
        value: v, // key is always undefined on onItemClick, so adding this to pick it up there. key is used for react rendering only
        content: formatValueForOutput(v),
        active: (v === selectedRate)
      }
    })
  }

  const playbackRateList = useMemo(buildPlaybackRateList, [availablePlaybackRates, selectedRate]);

  const handleChange = (e, rate) => {
    GeneralHelpers.stopEvent(e);
    setIsPopupOpen(false);
    if (onChange != null) {
      onChange(rate);
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const buildLabelComponent = () => {
    return (<Label
      size='medium'
      style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}
      className='mouse-pointer'
      onClick={handleMenuPopupClick}
    >
      {formatValueForOutput(selectedRate)}
    </Label>);
  }

  const handleMenuPopupClick = (e) => {
    GeneralHelpers.stopEvent(e);
    setIsPopupOpen(true)
    setMenuPopupPosition(LayoutHelpers.createRectFromEvent(e));
  };

  const labelComponent = buildLabelComponent();

  const popupMountNode = fullScreenContext?.containerRef?.current || document.body;

  return (
    <>
      <Popup
        mountNode={popupMountNode}
        trigger={labelComponent}
        on='hover'
        content={t('ENHANCED_VIDEO_PLAYER_CONTROLS_PLAYBACKRATE_ICON_TOOLTIP', 'Select playback rate')}
        style={styles.popup}
        inverted
        size={popupSize}
      />
      <Popup
        mountNode={popupMountNode}
        context={{
          getBoundingClientRect: () => menuPopupPosition
        }}
        on='click'
        offset={'[0, 5]'}
        styles={styles.popup}
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        inverted
      >
        <Menu
          inverted
          items={playbackRateList}
          onItemClick={(e, data) => {
            handleChange(e, data.value);
          }}
          secondary
          vertical
        />
      </Popup>
    </>
  );
};

export default memo(withTranslation()(PlaybackRateControls));
