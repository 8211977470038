import moment from "moment";
import React from "react";
import DateTimeField from "../../components/DateTimeField";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";

const DateTime = ({ question, value, changeValue, isReadOnly }) => (
  <DateTimeField
    config={question?.config}
    value={moment(value)}
    onChange={(v) => {
      if (v.isValid()) {
        changeValue(v.format("YYYY-MM-DDTHH:mm:ss"));
      }
    }}
    mode={"datetime"}
    readOnly={isReadOnly}
    nowOnly={question?.config?.type === "NOW"}
    style={question?.config?.style}
/>
);
export default withContainer(withLabel(withError(DateTime)));
