import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Placeholder, Segment } from "semantic-ui-react";
import RSVP from "rsvp";
import SleepChart from "./chartcomponents/SleepChart";
import SensorDataService from "../../../services/sensordata/SensorDataService";

class SubjectSleepGraph extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.initialise();
  }

  initialise = () => {
    RSVP.Promise.all([
      SensorDataService.getSleep(this.props.subjectId).then((data) => {
        let sleepYesNo = [];
        data.days.forEach((day) => {
          sleepYesNo.push({x: day.when, y: day.light + day.deep});
        });
        this.setState({ sleepYesNo, loading: false });
      }),
    ]).catch(this.errorHandler);

    return {
      error: false,
      loading: true,
      definition: {},
      questionnaires: [],
      permissions: [],
    };
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.subjectId !== this.props.subjectId) {
      this.setState(this.initialise());
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        {this.state.loading && (
          <Segment>
            <Placeholder fluid>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </Segment>
        )}

        {!this.state.loading && (
          <>
            {(!this.state.sleepYesNo || this.state.sleepYesNo.length === 0) && (
                <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY")}</p>
            )}

            {this.state.sleepYesNo && this.state.sleepYesNo.length > 0 && (
              <SleepChart
                title={t("GRAPH_SLEEP_TITLE", "Sleep")}
                yAxis={t("GRAPH_SLEEP_Y_LABEL")}
                subjectId={this.props.subjectId}
                sleepData={this.state.sleepYesNo}
              />
            )}
          </>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(SubjectSleepGraph);
