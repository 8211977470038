import React from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

const StaffListFilter = (props) => {
  const { t } = props;

  const dropdownOptions = [
    {
      text: t('STAFF_ALL_STAFF', 'All Staff'),
      value: 'ALL',
      key: 'ALL'
    },
    {
      text: t('STAFF_STATE_ACTIVE', 'Active'),
      value: 'ACTIVE',
      key: 'ACTIVE'
    },
    {
      text: t('STAFF_STATE_SUSPENDED', 'Suspended'),
      value: 'SUSPENDED',
      key: 'SUSPENDED',
      disabled: props.suspendedCount === 0
    },
    {
      text: t('GENERIC_TERM_STAFF_AWAITING_ACTIVATION', 'Awaiting Activation'),
      value: 'AWAITING_ACTIVATION',
      key: 'AWAITING_ACTIVATION',
      disabled: props.awaitingActivationCount === 0
    }
  ];

  return (
    <div className='staff-filter'>
      <div className='staff-filter-control'>
        <Dropdown
          selection
          options={dropdownOptions}
          onChange={(_e, data) => {
            props.onChangeFilter(data.value);
          }}
          value={props.selected}
        />
      </div>
    </div>
  )
}

export default withTranslation()(StaffListFilter);