import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import CronExpressionBuilderExamplesPopup from "./CronExpressionBuilderExamplesPopup";
import CronExpressionBuilderValuesPopup from "./CronExpressionBuilderValuesPopup";

const CronExpressionBuilderField = (props) => {
  const { t, field, onChange, value } = props;

  const [isValidValue, setIsValidValue] = useState(true);

  const onInputChanged = (e) => {
    const value = e?.target?.value;
    const valid = isValidInput(value);
    setIsValidValue(valid);
    onChange && onChange(e, value);
  };

  const onExampleSelect = (e, value) => {
    const valid = isValidInput(value);
    setIsValidValue(valid);
    onChange && onChange(e, value);
  };

  const onValueSelect = (e, value) => {
    const valid = isValidInput(value);
    setIsValidValue(valid);
    onChange && onChange(e, value);
  };

  const isValidInput = (value) => {
    if (value == null || value.trim().length === 0) {
      return false;
    }
    return true;
  };

  const icon = isValidValue
    ? null
    : { name: "exclamation triangle", color: "red" };

  return (
    <>
      <Form.Field style={{ width: 100 }}>
        <div
          style={{
            height: 45,
            position: "relative",
          }}
        >
          <label
            style={{ position: "absolute", bottom: 0, fontWeight: "bold" }}
          >
            {t(field.label)}
          </label>
        </div>
        <Form.Input
          type={"text"}
          onChange={onInputChanged}
          value={value}
          icon={icon}
        />
        <div>
          <CronExpressionBuilderValuesPopup
            possibleValues={field.possibleValues}
            buttonIcon={"list alternate outline"}
            buttonText={t("ADMIN_UTILS_CRONEXPRESSIONINPUT_VALUES", "Values")}
            onSelect={onValueSelect}
          />
          <CronExpressionBuilderExamplesPopup
            examples={field.examples}
            buttonIcon={"question circle outline"}
            buttonText={t(
              "ADMIN_UTILS_CRONEXPRESSIONINPUT_EXAMPLES",
              "Examples"
            )}
            onSelect={onExampleSelect}
          />
        </div>
      </Form.Field>
    </>
  );
};

export default withTranslation()(CronExpressionBuilderField);
