export default {
  PRO_LIST_PAGE: "PRO_LIST_PAGE",
  CONTENT_LIST_PAGE: "CONTENT_LIST_PAGE",
  EVENT_LIST_PAGE: "EVENT_LIST_PAGE",
  DATA_LIST_PAGE: "DATA_LIST_PAGE",
};

export const defaultNavigationConfig = [
  {
    webText: "MENU_PRO_LIST",
    screen: "pros",
    icon: "phone",
  },
  {
    webText: "MENU_EVENT_LIST",
    screen: "events",
    icon: "phone",
  },
  {
    webText: "MENU_CONTENT_LIST",
    screen: "contents",
    icon: "phone",
  },
  {
    webText: "MENU_SETTINGS",
    icon: "cogs",
    screen: "settings",
  }
];
