import React, { Component, Fragment } from "react";
import { Placeholder, Segment, Table } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import DateTimeService from "../../services/DateTimeService";
import moment from "moment";

class AsNeededMedicationTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.initialise();
    this.mounted = true;
  }

  initialise = () => {
    SubjectService.getAdhocMedication(
      this.props.subjectId,
      moment(this.props.startDate).format("YYYY-MM-DD"),
      moment(this.props.endDate).format("YYYY-MM-DD")
    )
      .then((medicationData) => {
        if (this.mounted) {
          this.setState({ medicationData, loading: false });
        }
      })
      .catch(this.errorHandler);

    return {
      loading: true,
      medicationData: [],
      error: false,
    };
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ error: true });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.subjectId !== this.props.subjectId ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.setState(this.initialise());
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render() {
    const { t } = this.props;

    const tableRows = this.state.medicationData.map((medicationApplication) => (
      <Table.Row key={medicationApplication.id}>
        <Table.Cell textAlign={"left"}>
          {medicationApplication.applicationDate
            ? DateTimeService.build.asDisplayDateTime(
                medicationApplication.applicationDate
              )
            : ""}
        </Table.Cell>
        <Table.Cell textAlign={"left"}>
          {medicationApplication.drugName}
        </Table.Cell>
        <Table.Cell textAlign={"left"}>
          {medicationApplication.dosageAmount}
        </Table.Cell>
        <Table.Cell textAlign={"left"}>
          {medicationApplication.dosageUnit
            ? t("MEDICATION_DOSAGE_UNIT_" + medicationApplication.dosageUnit)
            : ""}
        </Table.Cell>
        <Table.Cell textAlign={"left"}>
          {medicationApplication.dosageRoute
            ? t("MEDICATION_DOSAGE_ROUTE_" + medicationApplication.dosageRoute)
            : ""}
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <Fragment>
        {this.state.loading && (
          <Placeholder fluid>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        )}

        {!this.state.loading && (
          <Segment style={{ overflow: "auto" }}>
            <Table columns={5} compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_DATETIME")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_DRUG")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_DOSAGE_AMOUNT")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_DOSAGE_UNIT")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_DOSAGE_ROUTE")}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{tableRows}</Table.Body>
            </Table>
          </Segment>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(AsNeededMedicationTable);
