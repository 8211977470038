import InternationalisationService from "../../InternationalisationService";
import RequestHelper from "../../RequestHelper";

const QuestionnaireDefinitionService = {
  getQuestionnaireDefinitions: (searchTerm) => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-definitions/list",
      {},
      "GET",
      { searchQuery: searchTerm }
    );
  },
  getQuestionnaireDefinitionData: (id) => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/questionnaire-definitions/" +
        id +
        "/data",
      {},
      "GET"
    );
  },
  getQuestionnaireDefinitionJsonExport: (code) => {
      return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-definitions/exportFile",
        {},
        "GET",
        {code: code}
      );
    },
  editQuestionnaireDefinition: (questionnaireDefinition, feedbackReason) => {
    const feedbackHeaderValue = feedbackReason
      ? `Editing questionnaire definition [${questionnaireDefinition.id}]`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const requestBody = { ...initialRequestBody, questionnaireDefinition };

    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/questionnaire-definitions/" +
        questionnaireDefinition.id,
      headers,
      "PUT",
      null,
      requestBody
    );
  },

  editQuestionOrderingForQuestionnaire: (
    questionnaireDefinitionId,
    questionDefinitions,
    previousQuestionDefinitions,
    feedbackReason
  ) => {
    const feedbackHeaderValue = feedbackReason
      ? `Editing questionnaire definition [${questionnaireDefinitionId}] New: ${JSON.stringify(
          questionDefinitions.map((q) => {
            return { id: q.id, sequence: q.sequence };
          })
        )} Previous: ${JSON.stringify(
          previousQuestionDefinitions.map((q) => {
            return { id: q.id, sequence: q.sequence };
          })
        )}`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    const requestBody = { ...initialRequestBody, questionDefinitions };

    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/questionnaire-definitions/" +
        questionnaireDefinitionId +
        "/question-definitions/sequence",
      headers,
      "PUT",
      null,
      requestBody
    );
  },

  cloneQuestionnaireDefinition: (
    questionnaireDefinitionCloneJson,
    feedback
  ) => {
    let feedbackHeader = feedback
      ? { "x-feedback-available": "Cloning QuestionnaireDefinition" }
      : {};
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-definitions/clone",
      {
        "Accept-Language": InternationalisationService.getLanguage(),
        ...feedbackHeader,
      },
      "POST",
      null,
      { ...questionnaireDefinitionCloneJson, feedback }
    );
  },

  validateQuestionnaireDefinitionJson: (questionnaireDefinitionJson) => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-definitions/validate",
      {
        "Accept-Language": InternationalisationService.getLanguage(),
      },
      "POST",
      null,
      { ...questionnaireDefinitionJson }
    );
  },

  createQuestionnaireDefinition: (questionnaireDefinitionJson, feedback) => {
    let feedbackHeader = feedback
      ? { "x-feedback-available": "Adding new QuestionnaireDefinition" }
      : {};
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-definitions/create",
      {
        "Accept-Language": InternationalisationService.getLanguage(),
        ...feedbackHeader,
      },
      "POST",
      null,
      { ...questionnaireDefinitionJson, feedback }
    );
  },

  submitQuestionnaireDefinitionAndTranslationChanges: async (questionnaireDefinitionChangeObject, feedbackReason) => {
    const feedbackHeaderValue = feedbackReason
      ? `Editing questionnaire definition hierarchy [${questionnaireDefinitionChangeObject?.questionnaireDefinition?.code}]`
      : undefined;

    const { initialRequestBody, initialHeaders: headers } = RequestHelper.createInitialRequestObjectsWithFeedback(
      feedbackReason,
      feedbackHeaderValue
    );
    headers["Accept-Language"] = InternationalisationService.getLanguage();
    
    // Translations
    const translationResponse = await RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + "/translations/mgmt/data/bulk-update",
        headers,
        "POST",
        null,
        questionnaireDefinitionChangeObject.translationArray
    );

    // Questionnaire Definition
    const requestBody = { 
      ...initialRequestBody, 
      questionnaireDefinition: questionnaireDefinitionChangeObject.questionnaireDefinition
    };
    const questionnaireResponse = await RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/questionnaire-definitions/questionnaireDefinitionHierarchy",
      headers,
      "PUT",
      null,
      requestBody
    );

    return {
      errors: {...translationResponse.errors, ...questionnaireResponse.errors},
      warnings: {...translationResponse.warnings, ...questionnaireResponse.warnings}
    }
  }
};

export default QuestionnaireDefinitionService;
