import React from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Container, Form, Image, Message, Progress } from "semantic-ui-react";
import useUserMedia from "../../hooks/useUserMedia";
import withContainer from "./hocs/withContainer";

const RenderImage = ({ question, value, t }) => {
  const renderAs =
    question?.config?.renderAs !== undefined
      ? question?.config?.renderAs
      : "url";

  const { subjectId } = useParams();

  const mediaObject = useUserMedia(
    renderAs === "value" ? value : null,
    subjectId
  );

  return (
    <Form.Field>
      <Container style={{}}>
        {mediaObject.isLoading && (
          <Progress percent={mediaObject.downloadProgress} indicating />
        )}
        {mediaObject.isError && (
          <Message negative>{t("ERROR_LOADING_IMAGE")}</Message>
        )}
        {((mediaObject.userMediaUri && renderAs === "value") ||
          (question.url && renderAs === "url")) && (
          <Image
            src={
              mediaObject.userMediaUri ? mediaObject.userMediaUri : question.url
            }
            style={{ display: "block", margin: "auto" }}
            size="large"
          />
        )}
      </Container>
    </Form.Field>
  );
};

export default withContainer(withTranslation()(RenderImage));
