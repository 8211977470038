import React, {memo} from "react";
import RCSlider from "rc-slider";
import {useFullScreen} from "../../../../context/FullScreenContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Popup} from "semantic-ui-react";
import withPseudoStyleHints from "../../../hocs/withPseudoStyleHints";

const VolumeControl = ({
                           controlState,
                           volumeChange,
                           min,
                           max,
                           step,
                           buttonText,
                           iconName,
                           iconSize = '1x',
                           action,
                           onClick,
                           pseudoStyleEvents,
                           pseudoStyleHints,
                           popupSize = 'tiny'
                       }) => {
    const fullScreenContext = useFullScreen();
    const styles = {
        icon: {
            color: pseudoStyleHints?.hover ? 'white' : 'lightGrey',
            marginLeft: 10,
            marginRight: 10,
        },
        popup: {
            borderRadius: '0.28rem',
            opacity: 0.8
        }
    }

    const handleClick = (e) => {
        if (onClick) {
            onClick(action, e);
        }
    };

    const popupMountNode = fullScreenContext?.containerRef?.current || document.body;
    return (
        <Popup
            mountNode={popupMountNode}
            trigger={<span>
                      <FontAwesomeIcon
                          {...pseudoStyleEvents}
                          style={styles.icon}
                          aria-label={buttonText}
                          className='mouse-pointer'
                          onClick={handleClick}
                          size={iconSize}
                          icon={['fas', iconName]}
                      />
                    </span>
            }
            on={['focus', 'hover']}
            size={popupSize}
            hoverable
            basic
            style={styles.popup}
            inverted>
            {!controlState.isMuted && (<><RCSlider
                horizontal
                onChange={volumeChange}
                min={min}
                max={max}
                step={step}
                defaultValue={controlState.volume}
                handleStyle={{
                    backgroundColor: '#fff',
                    border: '0px solid #fff',
                    outline: '0px solid #fff',
                }}
                trackStyle={{
                    backgroundColor: '#f8991d',
                    height: '4px',
                    cursor: 'pointer'
                }}
                railStyle={{
                    backgroundColor: '#adadad',
                    height: '4px',
                    cursor: 'pointer'
                }}
                style={{
                    marginLeft: '1px',
                    marginRight: '1px',
                    width: '100px',
                    backgroundColor: null,
                }}
            /><span>{buttonText}: {controlState.volume * 10}</span></>)}
            {controlState.isMuted && (buttonText)}
        </Popup>
    );
}
export default memo(withPseudoStyleHints(VolumeControl));