export const substituteSubjectContext = (string, subjectContext) => {
  const isString = string != null && typeof(string) === 'string';
  if (!isString) {
    return string;
  }

  let returnString = string;

  // replace subject[key]
  returnString = returnString.replace(/\{subject\['([^']+)'\]\}/g, (match, prop) => {
    return subjectContext?.[prop] || "";
  });

  // replace subject.data[key]
  returnString = returnString.replace(/\{subject.data\['([^']+)'\]\}/g, (match, prop) => {
    return subjectContext?.data?.[prop]  || "";
  });

  return returnString;
}

export const hasSubjectDataReference = (string) => {
  const isString = string != null && typeof(string) === 'string';
  if (!isString) {
    return false;
  }

  if(/\{subject\['([^']+)'\]\}/g.test(string)) return true;
  return /\{subject.data\['([^']+)'\]\}/g.test(string)
}

export const getSubjectMatches = (string) => {
  const pattern = /\{subject\['([^']+)'\]\}/g;
  return [...string.matchAll(pattern)].map(match => match[1]);
}

export const getSubjectDataMatches = (string) => {
  const pattern = /\{subject\.data\['([^']+)'\]\}/g;
  return [...string.matchAll(pattern)].map(match => match[1]);
}

// has the capacity to overwrite, but key clashes are unlikely
export const buildSubjectDataPathArray = (string) => {
  if(!hasSubjectDataReference(string)) return []
  const keyArray = [];
  getSubjectMatches(string).forEach(key=>{keyArray.push(`${key}`)})
  getSubjectDataMatches(string).forEach(key=>{keyArray.push(`data.${key}`)})
  return keyArray;
}

export default {substituteSubjectContext, hasSubjectDataReference, buildSubjectDataPathArray}