export default class ScoringService {
  static getScoringInformation(answerValue, colors, questionnaire, definitionCode) {
    if (Array.isArray(answerValue)) {
      return answerValue
        .map((a) =>
          ScoringService.getScoringInformation(a, colors, questionnaire, definitionCode)
        )
        .find((a) => a !== null);
    }

    const matched = colors.find((colorConfig) => {
      if (colorConfig.questionCode) {
        answerValue = definitionCode && !colorConfig.questionCode.includes(`${definitionCode}_`)  ?
              questionnaire[`${definitionCode}_${colorConfig.questionCode}`] : questionnaire[`${colorConfig.questionCode}`];
      }
      if (colorConfig.min !== undefined && answerValue < colorConfig.min) {
        return false;
      } else if (
        colorConfig.max !== undefined &&
        answerValue > colorConfig.max
      ) {
        return false;
      } else if (
        colorConfig.value !== undefined &&
        answerValue !== colorConfig.value
      ) {
        return false;
      } else if (
        colorConfig.values !== undefined &&
        !colorConfig.values.includes(answerValue)
      ) {
        return false;
      } else {
        return true;
      }
    });

    let scoringInformation = null;
    if (matched) {
      scoringInformation = {
        color: matched.color,
        score: answerValue,
      };
    }
    return scoringInformation;
  }

  static calculateAnswerScore = (questionDefinition, score) => {
    let scoreOffset = 0;
    let scoreMultiplier = 1;

    if (questionDefinition) {
      if (questionDefinition.scoreOffset !== undefined) {
        scoreOffset = questionDefinition.scoreOffset;
      }
      if (questionDefinition.scoreMultiplier !== undefined) {
        scoreMultiplier = questionDefinition.scoreMultiplier;
      }
    }

    const calculatedScore = scoreOffset + scoreMultiplier * score;
    return calculatedScore;
  };
}
