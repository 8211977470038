import React, { useEffect, useMemo, useState } from "react";
import { AuthoriseHelpers } from "atom5-branching-questionnaire";
import Questionnaire from "../../Questionnaire";
import { Button, Message, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import ReadOnlyQuestionnaire from "../../ReadOnlyQuestionnaire";
import SubjectService from "../../../SubjectService";
import AuthService from "../../../services/AuthService";
import GroupPermission from "../../../GroupPermission";

const ModalAuthorise = (props) => {
  const {
    t,
    question,
    changeValue,
    changeAnswerMapValue,
    subjectId,
    questionnaireId,
    definition,
    isReadOnly,
    currentQuestionnaire,
    error
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [staffPermissions, setStaffPermissions] = useState();
  const [readonlyQuestionnaire, setReadonlyQuestionnaire] = useState();
  const [subjectCode, setSubjectCode] = useState();

  const {
    clickToSignButtonTranslationKey,
    clickToViewButtonTranslationKey,
    clickToGiveAuthorisationButtonTranslationKey
  } = AuthoriseHelpers.ModalAuthorise.getTranslationKeys(question);

  const includeQuestionCodes = AuthoriseHelpers.ModalAuthorise.getIncludeQuestionCodes(question);

  const authoriseQuestions = useMemo(() => {
    return AuthoriseHelpers.ModalAuthorise.getAuthoriseModalQuestions(definition, includeQuestionCodes, question.code);
  }, [definition, includeQuestionCodes, question.code]);

  // Build the "questionnaire definition" for the modal
  const authoriseModalQuestionnaireDefinition = useMemo(() => {
    return AuthoriseHelpers.ModalAuthorise.getAuthoriseModalQuestionnaireDefinition(definition, authoriseQuestions);
  }, [definition, authoriseQuestions]);

  useEffect(() => {
    if (AuthService.isStaff() === false) {
      return;
    }

    const getStaffPermissions = async () => {
      let permissions;

      // Permissions
      try {
        permissions = await SubjectService.getSubjectPermission(
          subjectId
        );
        setStaffPermissions(permissions);
      } catch (error) {
        console.error('[ModalAuthorise] Error Getting Staff Permissions', error);
      }

      // Subject Code
      try {
        const hasViewSubjectPermission = permissions.includes(GroupPermission.VIEW_SUBJECT);
        if (hasViewSubjectPermission) {
          const subjectData = await SubjectService.getSubjectData(subjectId);
          setSubjectCode(subjectData.subjectCode)
        }
      } catch (error) {
        console.error('[ModalAuthorise] Error Getting Subject Code', error);
      }
    }

    const getQuestionnaireForStaffView = async () => {
      try {
        const questionnaire = await SubjectService.getSubjectQuestionnaireByCodeAndId(
          subjectId,
          definition.code,
          questionnaireId
        );

        const allQuestionnaireAnswers = {};
        definition.questions.filter((question) => {
          return Object.keys(questionnaire).includes(`${definition.code}_${question.code}`);
        }).forEach((question) => {
          const answer = questionnaire[`${definition.code}_${question.code}`];
          allQuestionnaireAnswers[question.code] = answer;
        });

        setReadonlyQuestionnaire({
          ...questionnaire, 
          answers: allQuestionnaireAnswers,
          answerMap: allQuestionnaireAnswers
        });
      } catch (error) {
        console.error('[ModalAuthorise] Error Getting Questionnaire', error);
      }
    }

    getStaffPermissions();
    getQuestionnaireForStaffView();
  },[subjectId, definition, questionnaireId]);

  const handleQuestionnaireSubmit = (authoriseQuestionAnswers) => {
    if (isReadOnly !== true) {
      // Update the main answer map from the modal values
      Object.keys(authoriseQuestionAnswers).forEach(authoriseQuestionAnswerCode => {
        changeAnswerMapValue(
          authoriseQuestionAnswerCode,
          authoriseQuestionAnswers[authoriseQuestionAnswerCode]
        );
      });
      changeValue(authoriseQuestionAnswers[question.code]);
    }
    closeModal();
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modalInitButtonText = isReadOnly === true
    ? t(clickToViewButtonTranslationKey, "View authorisation")
    : t(clickToSignButtonTranslationKey, "Click here to authorise");

  const submitButtonText = isReadOnly === true
    ? t(["AUTHORISE_CLOSE", "GLOBAL_BUTTON_CLOSE"], "Close")
    : t(clickToGiveAuthorisationButtonTranslationKey, "I give authorisation");

  const showReadOnlyQuestionnaire = isReadOnly === true && AuthService.isStaff();
  const showQuestionnaire = !showReadOnlyQuestionnaire;
  
  const showModalCloseButton = showReadOnlyQuestionnaire 
    || (showQuestionnaire && isReadOnly !== true);
  
  return (
    <>
      <Button primary onClick={() => setIsModalOpen(true)}>{modalInitButtonText}</Button>
      {error && (
        <Message
          style={styles.error}
          visible
          error
          header={t("STAFF_FORM_ERROR_TITLE")}
          content={error}
        />
      )}
      <Modal
        size={"fullscreen"}
        open={isModalOpen}
        onClose={closeModal}
        closeIcon={true}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Content>
          {showReadOnlyQuestionnaire && (
            <ReadOnlyQuestionnaire
              definition={authoriseModalQuestionnaireDefinition}
              questionnaire={readonlyQuestionnaire}
              subjectId={subjectId}
              subjectCode={subjectCode}
              permissions={staffPermissions}
              isStaff={true}
              shouldShowTitle={false}
              shouldShowQuestionnaireMeta={false}
            />
          )}
          {showQuestionnaire && (
            <Questionnaire
              definition={authoriseModalQuestionnaireDefinition}
              questionnaire={currentQuestionnaire?.questionnaire}
              subjectId={AuthService.isStaff() ? subjectId : undefined}
              isReadOnly={isReadOnly}
              onSubmit={handleQuestionnaireSubmit} 
              shouldShowTitle={false}
              showSubmitAs={submitButtonText}
              shouldDispatchToStore={false}
            />
          )}
          {showModalCloseButton && (
            <div style={styles.closeButton}>
              <Button onClick={closeModal}>
                {t(["AUTHORISE_CLOSE", "GLOBAL_BUTTON_CLOSE"], "Close")}
              </Button>
            </div>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

const styles = {
  closeButton: {
    marginTop: 20,
    marginBottom: 10,
  },
  error: {
    width: '100%'
  }
};

const mapStateToProps = (state) => {
  return {
    currentQuestionnaire: state.currentQuestionnaire,
  };
};

const enhance = compose(connect(mapStateToProps), withTranslation());

export default enhance(ModalAuthorise);
