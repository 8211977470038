import React, { useContext, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Container, Form, Message, Progress } from "semantic-ui-react";
import VideoPlayer from "../../components/media/VideoPlayer";
import useUserMedia from "../../hooks/useUserMedia";
import withContainer from "./hocs/withContainer";
import EnhancedVideoPlayer from "../../components/media/EnhancedVideoPlayer/EnhancedVideoPlayer";
import UserContext from "../../context/UserContext";
import { typeHelper } from "atom5-branching-questionnaire";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";
import SubjectService from "../../SubjectService";
import AttachmentDownload from "../display/AttachmentDownload";
import ConfigContext from "../../context/ConfigContext";

const RenderVideo = ({ question, value, t, isInSideView }) => {
  const forceUseEnhancedPlayer = typeHelper.parseBool(question.config?.useEnhancedPlayer);
  const [useEnhancedPlayer, setUseEnhancedPlayer] = useState(forceUseEnhancedPlayer);
  const user = useContext(UserContext);
  const [hasAllowSubjectMediaAudioPermission, setHasAllowSubjectMediaAudioPermission] = useState();

  const renderAs =
    question?.config?.renderAs !== undefined
      ? question?.config?.renderAs
      : "url";

  const showFrameByFrameControls =
    question?.config?.showFrameByFrameControls !== undefined
      ? question?.config?.showFrameByFrameControls
      : false;
  const config = useContext(ConfigContext);

  let subjectId = null;
  const params = useParams();
  if(user.accountType === "staff"){
    subjectId = params.subjectId
  } else if (user.accountType === "subject"){
    subjectId = user.profile.Id
  }

  const mediaObject = useUserMedia(
    renderAs === "value" ? value : null,
    subjectId
  );

  const mediaUrl = useMemo(() => mediaObject.userMediaUri ? mediaObject.userMediaUri : question.videoUrl, [mediaObject.userMediaUri, question.videoUrl]);

  useEffect(() => {
    populateHasAllowSubjectMediaAudioPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateHasAllowSubjectMediaAudioPermission = async () => {
    if (user?.accountType === "subject") {
      setHasAllowSubjectMediaAudioPermission(true);
      return;
    }

    const subjectGroups = await SubjectService.getSubjectGroups(subjectId);
    const hasPerm = await PermissionsService.hasPermissionForSubject(subjectGroups, GroupPermission.ALLOW_SUBJECT_MEDIA_AUDIO);
    setHasAllowSubjectMediaAudioPermission(hasPerm);
  };

  if (question?.config?.showToSide && !isInSideView) {
    return null;
  }

  const getEnhancedVideoPlayer = () => {
    const frameSkipSizes = config?.ui?.components?.videoPlayer?.skip?.availableStepSizes;
    const defaultSkipSize = config?.ui?.components?.videoPlayer?.skip?.defaultStepSize;
    const enhancedVideoPlayerProps = {
      videoData: {
        url: mediaUrl,
      },
      options: {
        allowMuteChange: hasAllowSubjectMediaAudioPermission
      },
      timeSkipOptions: {
        sizes: frameSkipSizes,
        defaultSkipSize: defaultSkipSize ? parseInt(defaultSkipSize):null
      }
    };
    return <EnhancedVideoPlayer {...enhancedVideoPlayerProps} />;
  }

  const getPlayerToggle = () => {
    if (user?.profile?.superAdmin !== true) {
      return null;
    }
    return (
      <div style={{ textAlign: 'left' }}>
        <Button
          onClick={() => setUseEnhancedPlayer(!useEnhancedPlayer)}
          size='tiny'
          color='grey'
          content={useEnhancedPlayer ? 'Use Standard Player' : 'Use Experimental Player'}
        />
      </div>
    );
  };

  const getPlayer = () => {
    if (useEnhancedPlayer) {
      return getEnhancedVideoPlayer();
    }

    return (<VideoPlayer
      width={question.width || 640}
      height={question.height || 480}
      style={{ display: "block", margin: "auto" }}
      mediaUrl={mediaUrl}
      showFrameByFrameControls={showFrameByFrameControls}
      controls
      backgroundColor={'transparent'}
      hasAllowSubjectMediaAudioPermission={hasAllowSubjectMediaAudioPermission}
    />);

  }

  const shouldRenderPlayer = (mediaObject.userMediaUri && renderAs === "value") || (question.videoUrl && renderAs === "url");

  return (
    <Form.Field>
      <Container style={{}}>
        {mediaObject.isLoading && (
          <Progress percent={mediaObject.downloadProgress} indicating />
        )}
        {mediaObject.isError && (
          <Message negative>{t(mediaObject.errorMsg, "Error loading video, please refresh page to try again") + (mediaObject.errorCode ? ':' + mediaObject.errorCode : '')}</Message>
        )}
        {(shouldRenderPlayer && !forceUseEnhancedPlayer) && getPlayerToggle()}
        {shouldRenderPlayer && getPlayer()}

        <AttachmentDownload
          subjectId={subjectId}
          reference={value}
        />
      </Container>
    </Form.Field>
  );
};

export default withContainer(withTranslation()(RenderVideo));
