const availableOptions = [
  { key: 'electron-clinician-web', text: 'Electron Clinician Web', value: 'electron-clinician-web', displayDefault: true },
  { key: 'electron-subject-common', text: 'Electron Subject Common', value: 'electron-subject-common', displayDefault: true },
  { key: 'electron-subject-mobile', text: 'Electron Subject Mobile', value: 'electron-subject-mobile', displayDefault: true },
  { key: 'nucleus', text: 'Nucleus', value: 'nucleus', displayDefault: true },
  { key: 'system', text: 'System', value: 'System', displayDefault: false }
];

const ClientsOptions = {
  getDefaultDisplayLabels: () => {
    return availableOptions.filter(f => f.displayDefault === true ).map(option => option.key);
  },
  getAllValues: (onlyDefaultClients) => {
    if (onlyDefaultClients) {
      return availableOptions.filter(f => f.displayDefault === true );
    } else {
      return availableOptions;
    }
  }
}

export default ClientsOptions;