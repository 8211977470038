import React, { useContext, useLayoutEffect, useMemo, useRef, useState } from "react";

const FullScreenContext = React.createContext(null);

const FullScreenProvider = ({
  children
}) => {
  const containerRef = useRef(null);

  const getBrowserFullScreenElementProp = () => {
    if (typeof document.fullscreenElement !== "undefined") {
      return "fullscreenElement";
    } else if (typeof document.mozFullScreenElement !== "undefined") {
      return "mozFullScreenElement";
    } else if (typeof document.msFullscreenElement !== "undefined") {
      return "msFullscreenElement";
    } else if (typeof document.webkitFullscreenElement !== "undefined") {
      return "webkitFullscreenElement";
    } else {
      return undefined;
    }
  };

  const elementProp = useMemo(getBrowserFullScreenElementProp, []);
  const isFullScreenSupported = elementProp != null;

  const [isFullScreen, setIsFullScreen] = useState(document[elementProp] != null);

  const setFullScreen = (targeFullScreenContainerRef, value) => {
    if (targeFullScreenContainerRef.current == null) {
      console.error('[FullScreenProvider][setFullScreen] targeFullScreenContainerRef is undefined');
      return;
    }
    if (value === isFullScreen) {
      return;
    }
    if (value === true && containerRef.current != null) {
      console.error('[FullScreenProvider][setFullScreen] There is already another full screen element. Close it first.');
      return;
    }
    containerRef.current = targeFullScreenContainerRef.current;
    try {
      if (value === true) {
        containerRef.current.requestFullscreen();
        setIsFullScreen(document[elementProp] != null);
      } else {
        containerRef.current = undefined;
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    } catch (error) {
      console.error('[FullScreenProvider][setFullScreen] Error', error)
      setIsFullScreen(false);
    }
  };

  useLayoutEffect(() => {
    document.onfullscreenchange = () => {
      const isFull = document[elementProp] != null;
      setIsFullScreen(isFull);
      if (isFull === false) {
        containerRef.current = undefined;
      }
    };
    return () => (document.onfullscreenchange = undefined);
  });

  const buildContext = () => {
    return {
      isFullScreenSupported: isFullScreenSupported,
      isFullScreen: isFullScreen,
      setIsFullScreen: setFullScreen,
      containerRef: containerRef
    };
  };

  const context = useMemo(buildContext, [isFullScreenSupported, isFullScreen, containerRef]);

  return (
    <FullScreenContext.Provider value={context}>
      {children}
    </FullScreenContext.Provider>
  );

};

function useFullScreen() {
  return useContext(FullScreenContext);
}

export { FullScreenProvider, useFullScreen };
