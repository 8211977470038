import i18next from 'i18next';
import React from 'react';

const DEFAULT_REQUIRED_INDICATOR = '*';

const withQuestionRequiredText = (WrappedComponent) => {
  return (props) => {
    const requiredText = i18next.exists('QUESTION_REQUIRED') ? i18next.t('QUESTION_REQUIRED') : DEFAULT_REQUIRED_INDICATOR;

    return (
      <WrappedComponent {...props} questionRequiredText={requiredText} />
    );
  };
}

export default withQuestionRequiredText;