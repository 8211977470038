import RCSlider from 'rc-slider';
import React, {useState} from 'react';

export const SliderInput = (props) => {

    const labelStyle = {
        marginBottom: '10px',
        color: '#333', // Text color
    };

    const [sliderValue, setSliderValue] = useState(props.defaultValue);

    const handleSliderChange = (value) => {
        setSliderValue(value);
        if(props.onValueChange){
            props.onValueChange(value);
        }
    };

  return (
      <div>
          <div style={labelStyle}>{props.name}: {sliderValue}</div>
    <RCSlider

      {...props}
      //defaultValue={1}
      onChange={handleSliderChange}
      handleStyle={{
        backgroundColor: '#fff',
        border: '2px solid #f8991d',
        outline: '2px solid #f8991d'
      }}
      trackStyle={{ backgroundColor: '#f8991d' }}
      railStyle={{ backgroundColor: '#444' }}
      dotStyle={{ backgroundColor: '#444', border: '0' }}
      activeDotStyle={{
        backgroundColor: '#444',
        border: '0'
      }}
      style={{
        marginLeft: 10,
        marginBottom: '20px',
        maxWidth: 'calc(100% - 20px)',
        minWidth: '110px',
        backgroundColor: null
      }}
    />
      </div>
  );
};

export default SliderInput;
