import React, { Component, Fragment } from "react";
import { Placeholder, Segment, Table } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import DateTimeService from "../../services/DateTimeService";
import moment from "moment";

class MedicationNotTakenTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.initialise();
    this.mounted = true;
  }

  initialise = () => {
    SubjectService.getMedicationNotTaken(
      this.props.subjectId,
      moment(this.props.startDate).format("YYYY-MM-DD"),
      moment(this.props.endDate).format("YYYY-MM-DD")
    )
      .then((notTakenData) => {
        if (this.mounted) {
          this.setState({ notTakenData, loading: false });
        }
      })
      .catch(this.errorHandler);

    return {
      loading: true,
      notTakenData: [],
      error: false,
    };
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ error: true });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.subjectId !== this.props.subjectId ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.setState(this.initialise());
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render() {
    const { t } = this.props;

    const tableRows = this.state.notTakenData.map((medicationNotTaken) => (
      <Table.Row
        key={medicationNotTaken.drug + medicationNotTaken.scheduledDate}
      >
        <Table.Cell textAlign={"left"}>
          {medicationNotTaken.scheduledDate
            ? DateTimeService.build.asDisplayDate(
                medicationNotTaken.scheduledDate
              )
            : ""}
        </Table.Cell>
        <Table.Cell textAlign={"left"}>{medicationNotTaken.drug}</Table.Cell>
        <Table.Cell textAlign={"left"}>
          {t("MEDICATION_CREATE_SLOT_" + medicationNotTaken.slot)}
        </Table.Cell>
        <Table.Cell textAlign={"left"}>
          {t("MEDICATION_NOT_TAKEN_REASON_" + medicationNotTaken.reason)}
        </Table.Cell>
        <Table.Cell textAlign={"left"}>{medicationNotTaken.comment}</Table.Cell>
      </Table.Row>
    ));

    return (
      <Fragment>
        {this.state.loading && (
          <Placeholder fluid>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        )}

        {!this.state.loading && (
          <Segment style={{ overflow: "auto" }}>
            <Table columns={4} compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_SCHEDULED_DATE")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_DRUG")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_SLOT")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_REASON")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("MEDICATION_TABLE_HEADER_COMMENT")}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{tableRows}</Table.Body>
            </Table>
          </Segment>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(MedicationNotTakenTable);
