import InternationalisationService from '../InternationalisationService';
import RequestHelper, { HTTP_METHOD } from '../RequestHelper';

const SubjectAnswerManagementService = {
  getSubjectBaseUrl: (subjectId) => {
    return `${process.env.REACT_APP_SERVER_ADDRESS}/subjects/${subjectId}/questionnaireAnswerManagement`;
  },

  getQuestionnairesBySearchCriteria: async (subjectId, searchCriteria) => {
    const url = `${SubjectAnswerManagementService.getSubjectBaseUrl(subjectId)}/searchQuestionnaires`;

    const requestBody = {
      questionnaireDefinitionCodes:
        searchCriteria?.questionnaireDefinitionCodes != null
          ? searchCriteria.questionnaireDefinitionCodes
          : [],
      dateFrom: searchCriteria.dateFrom,
      dateTo: searchCriteria.dateTo,
      questionnaireId: searchCriteria.questionnaireId
    };

    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() },
      HTTP_METHOD.POST,
      null,
      requestBody
    );
    return response;
  },

  getQuestionnaireAnswersByQuestionnaireId: async (subjectId, questionnaireId) => {
    const url = `${SubjectAnswerManagementService.getSubjectBaseUrl(subjectId)}/questionnaireAnswers/${questionnaireId}`;
    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() },
    );
    return response;
  },

  deleteQuestionnaireAnswerById: async (feedbackReason, subjectId, questionnaireId, questionnaireAnswerId) => {
    const feedbackHeaderValue = feedbackReason
      ? 'Questionnaire Management - Delete an Answer to a Questionnaire'
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const url = `${SubjectAnswerManagementService.getSubjectBaseUrl(subjectId)}/questionnaireAnswers/${questionnaireId}/answer/${questionnaireAnswerId}`;

    const response = await RequestHelper.send(
      url,
      headers,
      HTTP_METHOD.DELETE,
      null,
      initialRequestBody
    );
    return response;
  },

  addEditQuestionnaireAnswerById: async (feedbackReason, subjectId, questionnaireId, questionnaireAnswerId, questionDefinitionId, answer) => {
    const feedbackHeaderValue = feedbackReason
      ? 'Questionnaire Management - Add or Edit an Answer to a Questionnaire'
      : undefined;
    const { initialRequestBody, initialHeaders: headers } =
      RequestHelper.createInitialRequestObjectsWithFeedback(
        feedbackReason,
        feedbackHeaderValue
      );

    headers['Accept-Language'] = InternationalisationService.getLanguage();

    const url = `${SubjectAnswerManagementService.getSubjectBaseUrl(subjectId)}/questionnaireAnswers/${questionnaireId}/answer`;

    const requestBody = {
      ...initialRequestBody,
      questionnaireAnswerId,
      questionDefinitionId,
      answer
    };

    const response = await RequestHelper.send(
      url,
      headers,
      HTTP_METHOD.POST,
      null,
      requestBody
    );
    return response;
  },

};

export default SubjectAnswerManagementService;
