import GroupService from "./GroupService";
import { flatten } from "lodash";
import GroupPermission from "../GroupPermission";
import RequestHelper from "../RequestHelper";
import InternationalisationService from "../InternationalisationService";

const PERMISSION_REQUIRED_FOR_TAB = {
  allergies: GroupPermission.VIEW_SUBJECT,
  data: GroupPermission.VIEW_SUBJECT, 
  devices: GroupPermission.VIEW_SUBJECT,
  event: GroupPermission.VIEW_SUBJECT, 
  graphs: GroupPermission.VIEW_SUBJECT_DATA, 
  medication: GroupPermission.VIEW_SUBJECT,
  module: GroupPermission.VIEW_SUBJECT,
  pro: GroupPermission.VIEW_SUBJECT,
  subjectrecord: GroupPermission.VIEW_SUBJECT,
  videocalls: GroupPermission.VIEW_SUBJECT,
  visit: GroupPermission.VIEW_SUBJECT,
  vitalsigns: GroupPermission.VIEW_SUBJECT_DATA, 
};

const PermissionsService = {
  listAllPermissions: () => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/permissions/list",
      { "Accept-Language": InternationalisationService.getLanguage() }
    );
  },

  hasPermissionInAnyGroup: async (permission) => {
    const groupsWithPermission = await GroupService.getGroupsWithPermission(permission);
    return groupsWithPermission.length >= 1;
  },

  hasPermissionInAnyGroupSynchronous: (profile, permission) => {
    if(!profile) return false;

    const groupsWithPermission = GroupService.getGroupsWithPermissionSynchronous(profile, permission);
    return groupsWithPermission.length >= 1;
  },

  hasPermissionForSubject: async (subjectGroups, permission) => {
    const groupsWithPermission = await GroupService.getGroupsWithPermission(permission);
    return subjectGroups.some((subjectGroup) =>
      groupsWithPermission.some(
        (editableGroup) => editableGroup.code === subjectGroup.code
      )
    );
  },

  hasPermissionForSubjectSynchronous: ( profile, subjectGroups, permission) => {
    if(!profile) return false;

    const groupsWithPermission = GroupService.getGroupsWithPermissionSynchronous(profile, permission);
    return subjectGroups.some((subjectGroup) =>
      groupsWithPermission.some(
        (editableGroup) => editableGroup.code === subjectGroup.code
      )
    );
  },

  hasPermissionInGroup: async (groupCode, permission) => {
    const groupsWithPermission = await GroupService.getGroupsWithPermission(permission);
    return groupsWithPermission.some((group) => group.code === groupCode);
  },

  hasPermissionInGroupSynchronous: (profile, groupCode, permission) => {
    const groupsWithPermission = GroupService.getGroupsWithPermissionSynchronous(profile, permission);
    return groupsWithPermission.some((group) => group.code === groupCode);
  },

  canStaffViewSubjectTab: async (subjectGroups, tabConfig, staffGroupMappings, isSubjectRecordTab = false) => {
    const tabName = typeof tabConfig === "string" ? tabConfig : tabConfig.name;

    const tabType = tabName.split('/')[0];

    let requiredPermissionKey = isSubjectRecordTab ? 'subjectrecord' : tabType;

    const isPermittedByPermission = await PermissionsService.hasPermissionForSubject(subjectGroups, PERMISSION_REQUIRED_FOR_TAB[requiredPermissionKey]);

    if (!isPermittedByPermission) {
      return false;
    }

    return PermissionsService.canStaffViewByConfig(subjectGroups, tabConfig, staffGroupMappings);
  },

  canStaffViewSubjectTabSynchronous: (profile, subjectGroups, tabConfig, isSubjectRecordTab = false) => {
    const tabName = typeof tabConfig === "string" ? tabConfig : tabConfig.name;

    const tabType = tabName.split('/')[0];

    let requiredPermissionKey = isSubjectRecordTab ? 'subjectrecord' : tabType;

    const isPermittedByPermission = PermissionsService.hasPermissionForSubjectSynchronous(profile, subjectGroups, PERMISSION_REQUIRED_FOR_TAB[requiredPermissionKey]);

    if (!isPermittedByPermission) {
      return false;
    }

    return PermissionsService.canStaffViewByConfig(subjectGroups, tabConfig, profile.groupMappings);
  },



  canStaffViewByConfig: (subjectGroups, tabConfig, staffGroupMappings) => {
    if (typeof tabConfig === 'string') {
      return true;
    }

    const staffRolesForSubject =
      PermissionsService.getStaffRolesForSubject(
        staffGroupMappings,
        subjectGroups
      );

    if (staffRolesForSubject.length === 0) {
      return false;
    }

    if (Array.isArray(tabConfig.roles)) {
      return tabConfig.roles.some((value) => {
        return staffRolesForSubject.includes(value);
      });
    }

    return Object.entries(tabConfig.roles).some(([key, _value]) =>
      staffRolesForSubject.includes(key)
    );
  },

  getStaffRolesForSubject: (staffGroupMappings, subjectGroups) => {
    const staffGroupMappingsForSubject = staffGroupMappings.filter((gm) => {
      return subjectGroups.some(
        (subjectGroup) => subjectGroup.code === gm.group.code
      );
    });

    let roleArray = staffGroupMappingsForSubject.map((gm) =>
      gm.roles.map((role) => role.code)
    );

    return flatten(roleArray);
  },
};

export default PermissionsService;