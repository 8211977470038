export default class LayoutHelpers {
  static createRectFromEvent(e) {
    // Based on https://github.com/Semantic-Org/Semantic-UI-React/issues/3896
    const rect = e.target?.getBoundingClientRect();
    const top = rect?.top || e.clientY;
    const left = rect?.left || e.clientX;
    const right = left + 1;
    const bottom = top + 1;
    return {
      left,
      top,
      right,
      bottom,
      height: 0,
      width: 0
    };
  }
}
