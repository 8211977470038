import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getTranslationForLanguageAndDefinition} from "../../../services/admin/AdminTranslationService";
import TranslationGroupDisplay from "./TranslationGroupDisplay";
import {Button, Form, Input, Modal, Table, TableRow, TextArea} from 'semantic-ui-react'
import LanguageSelectionGeneric from "../../languageselection/LanguageSelectionGeneric";
import {ACTION_TYPES} from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";
import getMatchFromCode from "./helpers/getMatchFromCode";
import {useTranslation} from "react-i18next";

const getSortByQuestionOrder = (questions) => (a, b) => {
  const indexOfA = questions.findIndex(q => q.code === a.code);
  const indexOfB = questions.findIndex(q => q.code === b.code);
  return indexOfA - indexOfB;
}

const TYPES = ["label", "config", "notificationTitle", "notificationMessage", "answers", "url", "videoUrl", "videoPoster", "height", "width", "validation-required", "validation-format", "validation-min", "validation-max"]
const createGroupedTranslationsByQuestionCode = (translations, focusedQuestions) => {
  const returnValue = []
  const translationQuestionCodeRegex = /questionnaire_(.+)_questions_(.+)_(.+)/

  const shouldOnlySortFocusedQuestions = focusedQuestions && Array.isArray(focusedQuestions) && focusedQuestions.length > 0;
  if (shouldOnlySortFocusedQuestions) {
    focusedQuestions.forEach(fQ => returnValue.push({code: fQ, translations: []}))
  }

  translations.forEach(t => {
    let questionCode = getMatchFromCode(translationQuestionCodeRegex, t.code, 2)?.split("_")[0]
    if (!questionCode) questionCode = "QUESTIONNAIRE";

    let index = returnValue.findIndex(rV => rV?.code === questionCode);
    if (index === -1) {
      if (!shouldOnlySortFocusedQuestions) {
        returnValue.push({code: questionCode, translations: [t]})
      }
    } else {
      returnValue[index].translations.push(t)
    }
  })

  return returnValue;
}

function QuestionnaireTranslationCardComponent({
   selectedLanguage,
   changedTranslationArray,
   questionnaireDefinition,
   dispatch,
   focusedQuestions,
   setSelectedLanguageCallback
}) {
  const {t} = useTranslation();

  const [searchedTranslations, setSearchedTranslations] = useState(null);

  // Translation searching should be done when the language or search changes so to get this to happen
  // automatically searchTerm has been split into a fast and slow updating variable.
  const [searchTermStore, setSearchTermStore] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const handleSearchChange = (_e, v) => {
    setSearchTermStore(v.value)
  }
  const handleSearchRequest = () => {
    setSearchTerm(searchTermStore)
  }

  const fetchTranslations = useCallback(async () => {
    const translations = await getTranslationForLanguageAndDefinition(
        selectedLanguage,
        questionnaireDefinition.code,
        searchTerm
    );

    setSearchedTranslations(translations);
  }, [selectedLanguage, questionnaireDefinition.code, searchTerm]);

  const refreshTranslations = useCallback(async () => {
    await setSearchedTranslations(null);
    fetchTranslations();
  }, [fetchTranslations]);
  useEffect(() => {
    refreshTranslations();
  }, [refreshTranslations]);

  const translationsWithLocalUpdates = useMemo(() => {
    if (!searchedTranslations) return [];
    const newSearchedTranslations = [...searchedTranslations];
    changedTranslationArray.forEach(cT => {
      if (cT.language !== selectedLanguage) {
        return;
      }

      const foundTranslationIndex = newSearchedTranslations.findIndex(t => t.code === cT.code && t.language === cT.language)
      if (foundTranslationIndex !== -1) {
        newSearchedTranslations.splice(foundTranslationIndex, 1, cT);
        return;
      }

      if (searchTerm.length === 0) {
        newSearchedTranslations.push(cT)
        return;
      }

      if (cT.translation.includes(searchTerm)) {
        newSearchedTranslations.push(cT)
      }
    })
    return newSearchedTranslations;
  }, [searchedTranslations, changedTranslationArray, selectedLanguage, searchTerm])


  // Sorting could be done in place or by creating a new array and setting.
  // Make sure that any sorting, past the initial in place sort, sets a new variable
  // in order to prompt useEffect to trigger.
  const groupedTranslations = useMemo(() => {
    if (!translationsWithLocalUpdates) return [];
    let groupedTranslations = createGroupedTranslationsByQuestionCode(translationsWithLocalUpdates, focusedQuestions)
    groupedTranslations.sort(getSortByQuestionOrder(questionnaireDefinition.questions))
    return groupedTranslations;
  }, [translationsWithLocalUpdates, focusedQuestions, questionnaireDefinition.questions])

  const [isFullTranslationEditorOpen, setIsFullTranslationEditorOpen] = useState(false);
  const toggleModalOpen = () => {
    setIsFullTranslationEditorOpen(!isFullTranslationEditorOpen)
  }


  const updateTranslation = (translationObject) => {
    dispatch({
      type: ACTION_TYPES.ADD_TRANSLATION_EDIT,
      payload: translationObject
    })
  }

  const hasLoaded = useMemo(() => {
    return searchedTranslations !== null;
  }, [searchedTranslations]);

  return (

      <div>
        <div style={{display: "flex", paddingBottom: "1rem"}}>
          <Input value={searchTermStore} onChange={handleSearchChange} style={{marginRight: "0.5rem", flexGrow: 1}}/>
          <Button primary onClick={handleSearchRequest}>{t("TRANSLATION_EDIT_SEARCH", "Search")}</Button>
          <Button primary onClick={toggleModalOpen}>{t("TRANSLATION_EDIT_FULL", "Full Editor")}</Button>
        </div>
        {hasLoaded && groupedTranslations.map((group) => <TranslationGroupDisplay
            key={'translation-group-' + group.code} translationGroupObject={group} isSearching={searchTerm.length > 0}
            dispatch={dispatch}/>)}
        <Modal open={isFullTranslationEditorOpen} size={"large"} onClose={toggleModalOpen}>
          <Modal.Content scrolling>
            <div style={{display: "flex", paddingBottom: "1rem"}}>
              <LanguageSelectionGeneric
                  inline
                  language={selectedLanguage}
                  callback={setSelectedLanguageCallback}
              />
              <Input fluid value={searchTermStore} onChange={handleSearchChange}
                     style={{marginRight: "0.5rem", flexGrow: 1}}/>
              <Button primary onClick={handleSearchRequest}>{t("TRANSLATION_EDIT_SEARCH", "Search")}</Button>
            </div>
            <Form>
              <Table fixed singleLine>
                <Table.Header>
                  <Table.HeaderCell width={6}>code</Table.HeaderCell>
                  <Table.HeaderCell width={10}>translation</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {hasLoaded && groupedTranslations.map(gT => gT.translations.sort((a, b) => {
                    let regex = /questionnaire_(.+)_questions_(.+)_(.+)/
                    let groupIndex = 3
                    if (gT.code === "QUESTIONNAIRE") {
                      regex = /questionnaire_(.+)_(.+)/
                      groupIndex = 2
                    }
                    const typeA = getMatchFromCode(regex, a.code, groupIndex);
                    const typeB = getMatchFromCode(regex, b.code, groupIndex);

                    return TYPES.findIndex(type => type === typeA) - TYPES.findIndex(type => type === typeB)
                  }).map(t => <TableRow key={"translation-"+t.code}>
                    <Table.Cell
                        width={6}>{t.code.slice(`questionnaire_${questionnaireDefinition.code}_`.length)}</Table.Cell>
                    <Table.Cell width={10}><TextArea rows={1} fluid value={t.translation} onChange={(_e, v) => {
                      updateTranslation({...t, translation: v.value})
                    }}/></Table.Cell>
                  </TableRow>))}
                </Table.Body>
              </Table>
            </Form>
          </Modal.Content>
        </Modal>
      </div>
  );
}

export default QuestionnaireTranslationCardComponent;
