import React, { memo, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PermissionsService from '../../../services/PermissionsService';
import GroupPermission from '../../../GroupPermission';
import SubjectService from '../../../SubjectService';
import { Button, Form, Modal, ModalActions, ModalContent, ModalHeader, Table } from 'semantic-ui-react';
import SubjectAnswerManagementService from '../../../services/SubjectAnswerManagementService';
import DateTimeService from '../../../services/DateTimeService';
import ConfirmButtonWithFeedback from '../../../components/dashboard/ConfirmButtonWithFeedback';
import AnswerManagementAddEditAnswerModal from './AnswerManagementAddEditAnswerModal';
import { QUESTION_TYPES, typeHelper } from 'atom5-branching-questionnaire';

const AnswerManagementEditQuestionnairesModal = ({ t, subjectId, questionnaireId, questionnaireDefinition, onClose, open }) => {
  const [subjectData, setSubjectData] = useState();
  const [hasPermission, setHasPermission] = useState();

  const [questionnaireData, setQuestionnaireData] = useState();
  const [questionnaireAnswerData, setQuestionnaireAnswerData] = useState();

  const [selectedQuestionnaireAnswer, setSelectedQuestionnaireAnswer] = useState();

  useEffect(() => {
    if (!open) {
      return;
    }
    populateSubjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (subjectData == null) {
      return;
    }
    checkPermisisons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectData]);

  useEffect(() => {
    if (hasPermission !== true) {
      return;
    }
    populateQuestionnaireData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermission]);

  if (!open) {
    return null;
  }

  const populateSubjectData = async () => {
    const data = await SubjectService.getSubjectData(subjectId);
    setSubjectData(data);
  };

  const checkPermisisons = async () => {
    const hasCorrectPermission =
      await PermissionsService.hasPermissionForSubject(
        subjectData?.groups,
        GroupPermission.EDIT_SUBJECT_QUESTIONNAIRES
      );
    setHasPermission(hasCorrectPermission);
    return hasCorrectPermission;
  };

  const populateQuestionnaireData = async () => {
    if (questionnaireId == null) {
      setQuestionnaireData();
      setQuestionnaireAnswerData();
      return;
    }

    const data = await SubjectService.getSubjectQuestionnaireById(subjectId, questionnaireId);
    setQuestionnaireData(data);

    const answerData = await SubjectAnswerManagementService.getQuestionnaireAnswersByQuestionnaireId(subjectId, questionnaireId);
    setQuestionnaireAnswerData(answerData);
  }

  const closeModal = () => {
    if (onClose != null) {
      onClose();
    };
  }

  if (hasPermission === false) {
    closeModal();
    return null;
  }

  const isLongText = (answer) => {
    try {
      const value = answer.toString();
      // Logic matches TextArea renderer
      return value?.length > 800 || value?.split('\n').length > 5;
    } catch {
      return false;
    }
  };

  const selectQuestionnaireAnswerToEdit = (obj) => {
    const fullObj = { ...obj, subject: subjectData, questionnaire: questionnaireData, questionnaireDefinition };
    setSelectedQuestionnaireAnswer(fullObj);
  };

  const addNewAnswer = () => {
    const obj = { subject: subjectData, questionnaire: questionnaireData, questionnaireDefinition };
    setSelectedQuestionnaireAnswer(obj);
  }

  const deleteQuestionnaireAnswer = async (feedbackReason, questionnaireAnswer) => {
    await SubjectAnswerManagementService.deleteQuestionnaireAnswerById(
      feedbackReason,
      subjectId,
      questionnaireId,
      questionnaireAnswer.id);
    await populateQuestionnaireData();
  };

  const buildAnswerTableDataRow = (questionnaireAnswer) => {
    const questionDefinition = questionnaireDefinition.questions.find(qd => qd.code === questionnaireAnswer.question.code);
    const isLong = isLongText(questionnaireAnswer.answer);

    let answerForDisplay = questionnaireAnswer.answer;
    if (questionDefinition.type === QUESTION_TYPES.FIXED_VALUE && typeHelper.isNumber(questionnaireAnswer.answer)) {
      const answerDefiniton = questionDefinition.answers.find(a => a.id === typeHelper.parseNumber(questionnaireAnswer.answer));
      if (answerDefiniton != null) {
        answerForDisplay += ` (Code: ${answerDefiniton.code} Label: ${answerDefiniton.label})`;
      }
    }

    return (
      <Table.Row key={questionnaireAnswer.id}>
        <Table.Cell key={questionnaireAnswer.id + '_label'} verticalAlign='top'>
          {questionDefinition?.label}
          <div style={{ color: '#707070', fontStyle: 'italic', fontSize: 11 }}>
            {questionDefinition?.code}
          </div>
        </Table.Cell>
        <Table.Cell key={questionnaireAnswer.id + '_answer'} verticalAlign='top'>
          {!isLong && answerForDisplay}
          {isLong && (
            <Form.TextArea
              defaultValue={answerForDisplay}
              disabled={true}
              style={{ width: '100%', minHeight: 240, resize: 'none' }}
            />
          )}
        </Table.Cell>
        <Table.Cell key={questionnaireAnswer.id + '_answer_id'} verticalAlign='top'>
          {questionnaireAnswer.id}
        </Table.Cell>
        <Table.Cell key={questionnaireAnswer.id + '_control'} verticalAlign='top' textAlign={'right'}>
          <Button
            onClick={() => selectQuestionnaireAnswerToEdit({ questionDefinition, questionnaireAnswer })}
          >
            {t('GLOBAL_BUTTON_EDIT', 'Edit')}
          </Button>
          <ConfirmButtonWithFeedback
            color='red'
            buttonText={t('GLOBAL_BUTTON_DELETE', 'Delete')}
            headerText={t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_PROMPT_TITLE', 'Delete answer')}
            contentText={t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_PROMPT_TITLE', 'Please enter a reason')}
            confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
            cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
            onConfirm={(feedbackReason) => deleteQuestionnaireAnswer(feedbackReason, questionnaireAnswer)}
            placeholderText={t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_PROMPT_TITLE', 'Delete answer')}
            mandatoryValidationText={t('GLOBAL_REASON_PROMPT', 'Please enter a reason')}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  const buildAnswerTable = () => {
    return (
      <Table selectable columns={12} compact>
        <Table.Header>
          <Table.Row key='header'>
            <Table.HeaderCell width={3} key='header_question_label'>
              {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_RESULTS_TABLE_HEADER_QUESTIONNAIRE_QUESTION', 'Question')}
            </Table.HeaderCell>
            <Table.HeaderCell width={5} key='header_answer'>
              {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_RESULTS_TABLE_HEADER_QUESTIONNAIRE_ANSWER', 'Answer')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} key='header_questionnaire_answer_id'>
              {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_RESULTS_TABLE_HEADER_QUESTIONNAIRE_ANSWER_ID', 'Questionnaire Answer ID')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} key='header_questionnaire_answer_control'>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {questionnaireAnswerData &&
            questionnaireAnswerData.map((questionnaireAnswerRecord) => buildAnswerTableDataRow(questionnaireAnswerRecord)
            )}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Modal
      open={open}
      size={'fullscreen'}
      closeIcon={false}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <ModalHeader>
        {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES', 'Edit questionnaire')}
      </ModalHeader>
      <ModalContent scrolling>
        {selectedQuestionnaireAnswer != null && (
          <AnswerManagementAddEditAnswerModal
            open={selectedQuestionnaireAnswer != null}
            subject={selectedQuestionnaireAnswer?.subject}
            questionnaire={selectedQuestionnaireAnswer?.questionnaire}
            questionnaireDefinition={selectedQuestionnaireAnswer?.questionnaireDefinition}
            questionnaireAnswer={selectedQuestionnaireAnswer?.questionnaireAnswer}
            questionDefinition={selectedQuestionnaireAnswer?.questionDefinition}
            questionnaireAnswers={questionnaireAnswerData}
            onClose={async () => {
              await populateQuestionnaireData();
              setSelectedQuestionnaireAnswer();
            }}
          />
        )}
        <table>
          <tbody>
            <tr>
              <td>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_SUBJECT_CODE', 'Subject code')}
              </td>
              <td>
                {subjectData?.subjectCode}
              </td>
            </tr>
            <tr>
              <td>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_QUESTIONNAIRE_LABEL', 'Questionnaire')}
              </td>
              <td>
                {questionnaireDefinition?.label} <span style={{ color: '#707070', fontStyle: 'italic' }}>({questionnaireDefinition?.code})</span>
              </td>
            </tr>
            <tr>
              <td>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_QUESTIONNAIRE_ID', 'Questionnaire ID')}
              </td>
              <td>
                {questionnaireId}
              </td>
            </tr>
            <tr>
              <td>
                {t('SUBJECT_ANSWERMANAGEMENT_EDIT_QUESTIONNAIRES_INFO_QUESTIONNAIRE_COMPLETIONDATE', 'Completed date')}
              </td>
              <td>
                {DateTimeService.build.asDisplayDateTime(questionnaireData?.completionDate)}
              </td>
            </tr>
          </tbody>
        </table>
        <Button onClick={addNewAnswer}>{t(['GLOBAL_BUTTON_ADD'], 'Add')}</Button>
        {buildAnswerTable()}
      </ModalContent>
      <ModalActions>
        <Button onClick={closeModal}>{t(['GLOBAL_BUTTON_CLOSE'], 'Close')}</Button>
      </ModalActions>
    </Modal>
  );
};

export default withTranslation()(memo(AnswerManagementEditQuestionnairesModal));
