import React, { Component } from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import GroupService from "../../services/GroupService";
import GroupPermission from "../../GroupPermission";
import TrialService from "../../TrialService";
import PermissionsService from "../../services/PermissionsService";

class SubjectGroupSelect extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedGroupCodes: this.props.initialGroupsSelected,
      availableGroups: [],
      hasManageSubjectGroups: false,
      allowMultipleValues: true,   //default to allow multiple values
    };

    //overide the default value of allowMultiple values if we have a property passed in.
    if (this.props.allowMultiple !== undefined){
      this.state.allowMultipleValues = this.props.allowMultiple;
    } 

    TrialService.getGroups().then((allGroups) => {
      const requiredPermission =
        this.props.requiredPermission || GroupPermission.MANAGE_SUBJECT_GROUPS;
      GroupService.getGroupsWithPermission(requiredPermission).then(
        (groupsWithPermission) => {
          let availableGroups = [];
          allGroups.forEach((group) => {
            groupsWithPermission.forEach((groupWithPermission) => {
              if (group.code === groupWithPermission.code) {
                availableGroups.push(group);
              }
            });
          });
          availableGroups.sort((a, b) => a.label.localeCompare(b.label));
          this.setState({ availableGroups });
        }
      );
    });
    this.checkForPermission();
  }

  componentDidUpdate = (prevProps) => {
    let checkPermission = true; //Default to check permission
    if (this.state.allowMultipleValues) {
      //If this dropdown is using multple values check 1st element of array
      if (prevProps.initialGroupsSelected[0] === this.props.initialGroupsSelected[0]) {
        checkPermission = false;
      }
    } else {
      //else use String value to compare previous vs new state
      if (prevProps.initialGroupsSelected === this.props.initialGroupsSelected) {
        checkPermission = false;
      }
    }
    if (checkPermission){
      this.checkForPermission();
    }
  };

  checkForPermission = async () => {
    const hasManageSubjectGroups = await PermissionsService.hasPermissionInGroup(this.state.allowMultipleValues ? this.state.selectedGroupCodes[0] : this.state.selectedGroupCodes , GroupPermission.MANAGE_SUBJECT_GROUPS);
    this.setState({ hasManageSubjectGroups });
  };

  groupSelected = (event, dropdown) => {
    this.setState({ selectedGroupCodes: dropdown.value }, () => {
      this.props.onGroupSelected(this.state.selectedGroupCodes);
    });
  };

  render() {
    const { t } = this.props;
   
    const groups = [];
    this.state.availableGroups.forEach((group) => {
      groups.push({ key: group.code, text: group.label, value: group.code });
    });

    return (
      <>
      {this.state.hasManageSubjectGroups 
        && (<Form.Field>        
          <label>{t("SUBJECT_LIST_TABLE_HEADER_GROUPS")}</label>
          <Dropdown
            placeholder={t("SUBJECT_CREATE_GROUPS_PLACEHOLDER")}
            fluid
            multiple={this.state.allowMultipleValues}
            selection
            options={groups}
            onChange={this.groupSelected}
            value={this.state.selectedGroupCodes}
          />
        </Form.Field>)}
      </>
    );
  }
}

export default withTranslation()(SubjectGroupSelect);
