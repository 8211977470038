import React, { useContext, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import AttachmentService, { MEDIA_STATE } from "../../services/AttachmentService";
import { Button, Icon, Popup } from "semantic-ui-react";
import { saveAs } from 'file-saver'
import GroupPermission from "../../GroupPermission";
import PermissionsService from "../../services/PermissionsService"
import SubjectService from "../../SubjectService";
import UserContext from "../../context/UserContext";

const AttachmentDownload = ({ t, subjectId, reference, blurredOnly = false, mediaState = MEDIA_STATE.COMPLETED }) => {
  const user = useContext(UserContext);
  const { isLoggedIn, accountType } = user;

  const disabledTimeoutRef = useRef();
  const [hasPermission, setHasPermission] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    populatePermissions();

    disabledTimeoutRef.current = setTimeout(() => {
      setIsButtonDisabled(false);
    }, 30 * 1000); // Disable the download button for 30s after clicking. Helps prevent users multi clicking, and resultant load on server
    return () => clearInterval(disabledTimeoutRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const populatePermissions = async () => {
    if (!isLoggedIn) {
      setHasPermission(false);
      return;
    }
    if (accountType !== 'staff') {
      setHasPermission(false);
      return;
    }
    
    const subjectData = await SubjectService.getSubjectData(subjectId);
    const hasPerm = await PermissionsService.hasPermissionForSubject(
      subjectData.groups,
      GroupPermission.DOWNLOAD_SUBJECT_MEDIA);
    setHasPermission(hasPerm);
  };

  const downloadAttachment = () => {
    setIsButtonDisabled(true);

    AttachmentService.getAttachmentInline(
      subjectId,
      reference,
      blurredOnly,
      (_progress) => {
        // noop - this arg is needed for the method, but we won't se it here for now AT-2930
      },
      (_mediaDataUrl, blob, filename) => {
        saveAs(blob, filename);
      },
      (err) => {
        console.error('[AttachmentDialog][Download] Error', err);
      }
    );
  }

  if (hasPermission == null || hasPermission !== true) {
    return null;
  }

  if (mediaState !== MEDIA_STATE.COMPLETED && mediaState !== MEDIA_STATE.LEGACY) {
    return null;
  }

  if (!reference) {
    // Don't render if the reference is falsy in any way - false, null, '', etc
    return null;
  }

  return (
    <div style={{ marginTop: 10 }}>
      <Popup
        on='click'
        trigger={
          <Button
            icon
            size="small"
            onClick={downloadAttachment}
            disabled={isButtonDisabled}
          >
            <Icon name="download icon" />
            {t("GLOBAL_BUTTON_DOWNLOAD", "Download")}
          </Button>
        }
      >
        <Popup.Content>
          {t("GLOBAL_BUTTON_DOWNLOAD_MESSAGE", "Your download will begin automatically - check your usual download folder. It will take longer to download if the file is large.")}
        </Popup.Content>
      </Popup>
    </div>
  );
};

export default withTranslation()(AttachmentDownload);
