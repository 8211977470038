import React, { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import { Popup } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

const ParagraphTooltip = (props) => {

    const [contentArray, setContentArray] = useState([]);
    const [isMarkup] = useState(props.isMarkup);

    const init = async () => {
        await extractTooltipMarkup(props.content);
    }

    useEffect(() => {
      init();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.content]);

    const generatePopupFromMarkup = (theMarkup, key) => {
        //an example of markup would be [text to link: 'This is the popup text header']
        //    text to link - is the text to appear on the page with a hyperlink
        //    'This is the popup text header' - to appear in the popup on mouse over
        //if there is no semi-colon in theMarkup var then return as we cant parse
        if (theMarkup.indexOf(":") === -1) {
            return;
        }

        theMarkup = tidyUpMarkup(theMarkup);
        let trigger = <span><u>{getLink(theMarkup)}</u></span>;

        let bodyAndProps = extractBodyAndDynamicProps(theMarkup);
        let body = (<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(bodyAndProps[0])}}/>);
        let dynamicProps = bodyAndProps[1];
        
        return <Popup key={key} trigger={trigger} content={body} {...dynamicProps}/>;
    }

    const getLink = (theMarkup) => {
        return theMarkup.substring(0, theMarkup.indexOf(":"));
    }

    const extractBodyAndDynamicProps = (theMarkup) => {
        const propertiesMarker = "||";
        let body, options = [], dynamicProps = {};
        if (theMarkup.indexOf(propertiesMarker) === -1) {
            body = theMarkup.substring(theMarkup.indexOf(":") + 1, theMarkup.length);
        } else {
            body = theMarkup.substring(theMarkup.indexOf(":") + 1, theMarkup.lastIndexOf(propertiesMarker) - 1);
            options = theMarkup.substring(theMarkup.lastIndexOf(propertiesMarker) + propertiesMarker.length, theMarkup.length).split(",");
            options.map(item => {
                if (item.indexOf("=") > 0) {
                    let popProp = item.split("=");
                    dynamicProps[popProp[0]] = popProp[1].replaceAll("'", "");
                } else {
                    dynamicProps[item.trim()] = item.trim();
                }
                return "";
            });
        }
        return [body, dynamicProps];
    }

    const extractTooltipMarkup = async (theContent) => {

        //regexp to grab all matches for markup
        const regexpFindMatches = /\[(.*?)\]/; 

        //if there is nothing to replace then just output the original text
        if (!regexpFindMatches.test(theContent)) {
            if (isMarkup) {
                theContent = (<span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(theContent)}}/>)
            }
            setContentArray([theContent]);
            return;
        }

        let match, startPos = 0, output = []; //temporary storage as populating state array is buggy

        //to prevent an infinate loop
        let failSafe = 0; 

        do {
            match = regexpFindMatches.exec(theContent);
            if (match) {
                if (isMarkup) {
                    output = [...output, 
                        (<span dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(theContent.substring(startPos, match.index))}}/>
                        )
                    ];
                } else {
                    output = [...output, theContent.substring(startPos, match.index)];
                }
                startPos = match.index + match[0].length;

                const popupComponent = generatePopupFromMarkup(match[0], failSafe);
                output = [...output, popupComponent === undefined ? match[0] : popupComponent];
                theContent = replaceMatchWithAsterix(theContent, match[0]);

                //in testing some invalid html can cause an ininite loop, followin if statement is to prevent that happening
                //there is never likey to be over 200 items on a page, if this limit is hit then there is a problem
                if (failSafe++ > 200) {
                    break;
                }
            }
        } while (match);
        output = [...output, theContent.substring(startPos, theContent.length)];
        setContentArray(output); 
    }

    const tidyUpMarkup = (theMarkup) => {
        theMarkup = theMarkup
            .replace("[", "").replace("']", "")
            .replace(" :", ":").replace(": '", ":")
            .replace(":'", ":").replace("' :", ":").trim();
            
        if (theMarkup.lastIndexOf(']') === theMarkup.length - 1) {
            theMarkup = theMarkup.substring(0, theMarkup.length - 1);
        }
        return theMarkup;
    }

    const replaceMatchWithAsterix = (theContent, match) => {
        //a crude string replacement but the regexp version would not work when the content contained html
        let start = theContent.indexOf("[");
        let end = theContent.indexOf("]");
        let len = end - start;
        let padding = new Array(len + 2).join( "*" )
        return theContent.substring(0, start) + padding + theContent.substring(end+1, theContent.length);
    }
    
    let paragraph = contentArray.map((text) => text);

    return (
        <p>{paragraph}</p>
    )
}

export default withTranslation()(ParagraphTooltip);