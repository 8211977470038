import {withTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {Button,  Form, Grid, GridColumn, GridRow, Icon, Loader, Message, Table} from "semantic-ui-react";
import Page from "../../components/page/Page";
import VanillaJsonEditor from "./components/jsoneditor/VanillaJsonEditor";
import WorkflowService from "../../services/admin/WorkflowService";
import UserContext from "../../context/UserContext";
import FileSaver from 'file-saver';

const AdminQuestionnaireWorkflowPage = (props) => {

    const { t } = props;

    const user = useContext(UserContext);
    const isSuperAdmin = user.profile.superAdmin;

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [jsonValue, setJsonValue] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [showJsonEditor, setShowJsonEditor] = useState(false);
    const [workflowDefinitions, setWorkflowDefinitions] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        const fetchAllWorkflows = async () => {
            setIsLoading(true);
            const workflowDefinitionsList = await WorkflowService.fetchAllWorkflowDefinitions();
            setWorkflowDefinitions(workflowDefinitionsList);
            setIsLoading(false);
        }

        fetchAllWorkflows().catch(error => {
            setIsLoading(false);
            setError(error.message);
            setMessage("");
        });
    };

    const addNewWorkflowDefinitionHandler = async () => {
        setMessage("");
        setError("");
        const response = await WorkflowService.addNewQuestionnaireWorkflowDefinition(jsonValue);
        if (response !== 'Success') {
            setError(response);
        } else {
            setMessage(t("ADMIN_IMPORT_WORKFLOW_SUCCESS", "JSON Imported successfully!"));
            setJsonValue("");
            setShowJsonEditor(false);
            loadData();
        }
    }

    const filterDataBySearchTerm = () => {
        let newList = workflowDefinitions;
        if (searchTerm.length > 0) {
            newList = workflowDefinitions.filter((item) => item.code.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0
                || item.version.toLowerCase().indexOf(searchTerm.trim().toLowerCase()) >= 0);
        }
        return newList;
    };

    const exportToJson = async (id) => {
        setIsLoading(true);
        try{
            const jsonContent = await WorkflowService.getQuestionnaireWorkflowDefinitionJsonExport(id);
            const blob = new Blob([JSON.stringify(jsonContent,null, 2)], {
                type: "text/plain;charset=utf-8"
            });
            const code = jsonContent?.code === undefined ? "code" : jsonContent.code;
            let jsDate = new Date();
            FileSaver.saveAs(blob, "questionnaire-workflow-definition-" + code + "-" + jsDate.toISOString().slice(0, 19) + "-" + id + ".json");
        } catch (response) {
            setError("Error exporting JSON: " + JSON.stringify(response.message) + ": code(" + id + ")");
        } finally {
            setIsLoading(false);
        }
    };


    const createTable = () => {
        let itemList = filterDataBySearchTerm();
        if (!itemList) {
            return;
        }

        const headers = [
            {title: ["ADMIN_QUESTIONNAIRE_WORKFLOW_HEADER_CODE", "Code"],},
            {title: ["ADMIN_QUESTIONNAIRE_WORKFLOW_HEADER_VERSION", "Version"]},
            {title: ["ADMIN_QUESTIONNAIRE_WORKFLOW_HEADER_ACTION", "Actions"]}
        ];

        const headerRow = (
            <Table.Header>
                <Table.Row>
                    {headers.map((header) => (
                        <Table.HeaderCell {...header?.config}>
                            {t(header.title)}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
        );

        const workflowDefinitionsRows = itemList.map((definition, index) => (
            <Table.Row key={index}>
                <Table.Cell>{definition.code}</Table.Cell>
                <Table.Cell>{definition.version}</Table.Cell>
                <Table.Cell>
                    <Icon style={{cursor: "pointer"}} color="orange" onClick={(e) => {e.stopPropagation(); exportToJson(definition.id)}} name='download' />
                </Table.Cell>
            </Table.Row>
        ));

        return (
            <>
                {headerRow}
                {workflowDefinitionsRows}
            </>);
    }

    return (
        <Page
            name="Admin Questionnaire Workflow"
            header={t("ADMIN_QUESTIONNAIRE_WORKFLOW_HEADER", "Questionnaire Workflow Definitions")}
        >
            {!isSuperAdmin && (
                <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={t("ADMIN_UTILS_NOT_LOGGED_IN_AS_ADMIN", "NOT AUTHORIZED")}
                />
            )}
            {isLoading && <Loader active={isLoading} />}
            {isSuperAdmin && !isLoading &&
                <>
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={11}>
                                    <Form.Field>
                                        <Form.Input
                                            icon={<Icon name="search" />}
                                            iconPosition={"left"}
                                            value={searchTerm}
                                            placeholder={t("ADMIN_CODE_FILTER", "Code or Version Search")}
                                            onChange={(e, v) => {
                                                setSearchTerm(v.value);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Button
                                        fluid
                                        primary
                                        onClick={() => setShowJsonEditor(!showJsonEditor)}
                                    >
                                        {t("ADMIN_IMPORT_WORKFLOW_JSON", "Import workflow Json")}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>

                    <Grid>
                        <GridRow>
                            <GridColumn>
                                {error.trim().length > 0 && (
                                    <Message error style={{ padding: "2rem" }}>
                                        {t("ADMIN_IMPORT_WORKFLOW_ERROR", "Error")}: {error}
                                    </Message>
                                )}

                                {message.trim().length > 0 && (
                                    <Message positive style={{ padding: "2rem" }}>
                                        {t("ADMIN_IMPORT_WORKFLOW_SUCCESS", "Success, entry added")}:
                                    </Message>
                                )}

                                {!showJsonEditor && (
                                    <>
                                        <Table fixed id={"wdList"}>
                                            {createTable(workflowDefinitions)}
                                        </Table>
                                    </>
                                )}

                                {showJsonEditor && (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                            <Button
                                                style={{ margin: 10, width: "400px", align: "center" }}
                                                type="submit"
                                                icon
                                                labelPosition="left"
                                                onClick={() => setShowJsonEditor(false)}
                                            >
                                                {t("ADMIN_IMPORT_WORKFLOW_CANCEL_BUTTON", "Cancel")} <Icon name="cancel" />
                                            </Button>
                                            <Button
                                                style={{ margin: 10, width: "400px", align: "center" }}
                                                type="submit"
                                                icon
                                                primary
                                                labelPosition="left"
                                                onClick={addNewWorkflowDefinitionHandler}
                                            >
                                                {t("ADMIN_IMPORT_WORKFLOW_SUBMIT_BUTTON", "Submit")} <Icon name="upload" />
                                            </Button>
                                        </div>

                                        <div className="my-editor">
                                            <VanillaJsonEditor
                                                fluid
                                                content={{text:jsonValue}}
                                                readOnly={false}
                                                onChange={updated=>{if(updated.json) {setJsonValue(JSON.stringify(updated.json))} else {setJsonValue(updated.text)}}}
                                            />
                                        </div>
                                    </>)
                                }
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </>
            }
        </Page>
    )
}

export default withTranslation()(AdminQuestionnaireWorkflowPage);
