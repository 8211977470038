import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Loader, Modal } from "semantic-ui-react";
import QuestionDefinitionService from "../../services/admin/QuestionDefinitionService";
import AdminQuestionDefinitionEditComponent from "./AdminQuestionDefinitionEditComponent";

function AdminQuestionDefinitionViewModal({
  t,
  isOpen,
  setIsOpen,
  questionDefinitionId,
}) {
  const [questionDefinitionData, setQuestionDefinitionData] = useState(null);

  const getQuestionDefinitionData = async () => {
    setQuestionDefinitionData(null);
    const questionDefinitionData =
      await QuestionDefinitionService.getQuestionDefinitionData(
        questionDefinitionId
      );
    setQuestionDefinitionData(questionDefinitionData);
  };

  useEffect(() => {
    if (questionDefinitionId) {
      getQuestionDefinitionData(questionDefinitionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionDefinitionId]);

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Content scrolling>
        <Modal.Description>
          {!questionDefinitionData && <Loader />}
          {questionDefinitionData &&
            questionDefinitionData.questionDefinition && (
              <AdminQuestionDefinitionEditComponent
                questionDefinition={questionDefinitionData.questionDefinition}
                serverTranslations={questionDefinitionData.serverTranslations}
                isReadOnly={true}
              />
            )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default withTranslation()(AdminQuestionDefinitionViewModal);
