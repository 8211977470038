import React from "react";
import CheckSelector from "./CheckSelector";
import RadioSelector from "./RadioSelector";

export default function SelectorDisplay({
  type,
  id,
  onChange,
  value,
  isCheckbox,
  checked,
  disabled,
  label,
}) {
  return (
    <div tabIndex="0"
      style={{
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => {
        if (!disabled) {
          onChange(value);
        }
      }}
      onKeyDown={(e) => {
        if (!disabled) {
          if (e.key === ' ') {
            onChange(value);
            e.preventDefault()
          }
        }
      }}
    >
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isCheckbox ? (
          <CheckSelector disabled={disabled} isSelected={checked} />
        ) : (
          <RadioSelector disabled={disabled} isSelected={checked} />
        )}
      </div>
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          padding: "0.25rem 1rem 0.25rem 1rem",
        }}
      >
        {label}
      </div>
    </div>
  );
}
