import React, { useEffect, useState } from 'react';
import {isSafari} from 'react-device-detect';

import VideoCallService from '../../services/VideoCallService';
import VideoCallRoom from './VideoCallRoom';

const { connect } = require('twilio-video');

const VideoCall = (props) => {

  const {
    identity,
    subjectId, 
    onLeave
  } = props;

  const [room, setRoom] = useState(null);

  const leaveRoom = () => {
    setRoom(null);
    onLeave();
  };

  useEffect(() => {
    // Specfically for Safari<->Android communication, we need to force Safari to use the VP8 Codec as that is the only one shared between the two.
    // Every other browser supports VP8, VP9 and H.264, so we can let the browser decide
    const preferredVideoCodecs = isSafari ? ['VP8'] : undefined;
    const fetchNewRoom = async () => {
      const accessData = await VideoCallService.getAccessToken(identity, subjectId);
      const newRoom = await connect(accessData.token, {
        name: accessData.roomName,
        audio: true,
        video: true,
        preferredVideoCodecs: preferredVideoCodecs
      });
      setRoom(newRoom);
    }
    fetchNewRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return room && <VideoCallRoom room={room} leaveRoom={leaveRoom} />;
}

export default VideoCall;