import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'semantic-ui-react';
import DataQueryService from '../../services/DataQueryService';

const RaiseDataQueryModal = ({t, open, onClose, questionCode, questionnaireId, renderedQuestion, subjectId}) => {
  const [comment, setComment] = useState("");

  const onSubmit = async () => {
    if (comment.length === 0) {
      return;
    }
    await DataQueryService.raiseQuery(questionCode, questionnaireId, subjectId, comment);
    setComment("");
    onClose();
  };

  return (
    <Modal size={"large"} open={open} onClose={onClose} style={{ padding: 15 }}>
      <Form>
        <h2>{t("DATA_QUERY_RAISE_HEADER")}</h2>
        <p>{t("DATA_QUERY_RAISE_DESCRIPTION")}</p>

        <div
          style={{
            paddingLeft: 20,
            marginBottom: 10,
            borderLeft: "3px solid lightgrey",
          }}
        >
          {renderedQuestion}
        </div>

        <Form.Input
          label={t("DATA_QUERY_RAISE_COMMENT")}
          type="text"
          name="comment"
          id="comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          required={true}
        />

        <Button onClick={onSubmit}>
          {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
        </Button>
      </Form>
    </Modal>
  );
};

export default withTranslation()(RaiseDataQueryModal);