import {cloneDeep} from 'lodash';

const performStaffSignOffQuestionModification = (user, definition, questionnaire, question, isReadOnly) => {
  const doesQuestionnaireRequireStaffSignOff = definition?.config?.requiresStaffSignOff || false;
  const doesQuestionRequireShuffling = question?.config?.answerDisplayOrder?.mode === "RANDOM" &&
      user.accountType === "subject" &&
      !isReadOnly;

  if(!doesQuestionnaireRequireStaffSignOff && !doesQuestionRequireShuffling){
    return question;
  }

  const modifiedQuestion = cloneDeep(question);
  const isQuestionnaireViewedBySubject = user.accountType === "subject";
  const isQuestionnaireComplete = questionnaire.completionDate !== null;
  const isQuestionForStaffSignOff = question?.config?.isStaffSignOffQuestion;
  if(!isReadOnly && isQuestionForStaffSignOff && (isQuestionnaireViewedBySubject || !isQuestionnaireComplete)){
    modifiedQuestion.required = false;
    modifiedQuestion.hidden = true;
  }

  // The isReadOnly attribute will be passed to render props in BranchingQuestionnaire
  if(isQuestionnaireComplete && !isQuestionForStaffSignOff){
    modifiedQuestion.isReadOnly = true;
  }

  return modifiedQuestion;
};

export default performStaffSignOffQuestionModification;