import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Card,
  Label,
  Loader,
  Pagination,
  Table,
  TableHeaderCell,
} from "semantic-ui-react";
import ConfigContext from "../../../context/ConfigContext";
import GroupsContext from "../../../context/GroupsContext";
import InternationalisationService from "../../../InternationalisationService";
import DashboardService from "../../../services/DashboardService";
import DateTimeService from "../../../services/DateTimeService";

function BoundedComplianceCard({ t }) {
  const [compliance, setCompliance] = useState({});
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [selectedLanguage, setSelectedLanguage] = useState(true);

  const config = useContext(ConfigContext);
  const groups = useContext(GroupsContext);

  const cardConfig = config.ui.dashboard.cards.boundedCompliance;

  const questions = cardConfig.bounds.map((q) => q.questionCode);

  const getCompliance = async () => {
    const compliance = await DashboardService.getBoundedCompliance(
      cardConfig.questionnaire,
      questions,
      activePage - 1,
      null,
      null,
      null
    );
    let selectedLanguage = await InternationalisationService.getLanguage();

    await Promise.all([
      setCompliance(compliance),
      setSelectedLanguage(selectedLanguage),
    ]);

    setLoading(false);
  };

  useEffect(() => {
    getCompliance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  const headers = [];
  if (compliance.definition) {
    headers.push(
      <Table.HeaderCell>
        {t("BOUNDED_COMPLIANCE_GROUP_HEADER")}
      </Table.HeaderCell>
    );
    headers.push(
      <Table.HeaderCell>
        {t("BOUNDED_COMPLIANCE_SUBJECT_HEADER")}
      </Table.HeaderCell>
    );
    for (let i = 0; i < questions.length; i++) {
      let question = compliance.definition.questions.find((q) => {
        return q.code === questions[i];
      });

      if ("show" in cardConfig.bounds[i] ? cardConfig.bounds[i].show : true) {
        headers.push(
          <TableHeaderCell>
            {cardConfig.bounds[i].dateLabel
              ? cardConfig.bounds[i].dateLabel[selectedLanguage]
              : question.label}
          </TableHeaderCell>
        );
      }

      // Last in the bounds array, so has no range to calculate compliance
      if (i !== questions.length - 1) {
        headers.push(
          <TableHeaderCell
            style={{ justifyContent: "center", display: "flex" }}
          >
            {cardConfig.bounds[i].complianceLabel
              ? cardConfig.bounds[i].complianceLabel[selectedLanguage]
              : question.label +
                " " +
                t("BOUNDED_COMPLIANCE_COMPLIANCE_SUFFIX")}
          </TableHeaderCell>
        );
      }
    }
  }

  const handlePageChange = (value, a) => setActivePage(a.activePage);

  const hasData =
    compliance &&
    compliance.complianceArray &&
    compliance.complianceArray.length !== 0;

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>{t("BOUNDED_COMPLIANCE_HEADER")}</Card.Header>
        {loading && <Loader />}
        {!loading && hasData && (
          <>
            <Table>
              <Table.Header>{headers}</Table.Header>
              <Table.Body>
                {compliance.complianceArray?.map((compliance) => {
                  const foundGroup = groups.find(
                    (group) => group.code === compliance.group
                  );
                  let groupLabel = "";
                  if (foundGroup) {
                    groupLabel = foundGroup.label;
                  }

                  return (
                    <Table.Row>
                      <Table.Cell>
                        <p>{groupLabel}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <Link
                          to={`/app/subject/${compliance.id}/tabs/subject-record/details`}
                        >
                          <Label size="large" color={"orange"}>
                            {compliance.subjectCode}
                          </Label>
                        </Link>
                      </Table.Cell>
                      {compliance.compliance?.map((boundedCompliance, i) => {
                        let cells = [];

                        if (i === 0) {
                          cells.push(
                            <Table.Cell>
                              {DateTimeService.build.asDisplayDate(
                                boundedCompliance.lower
                              )}
                            </Table.Cell>
                          );
                        }

                        cells.push(
                          <Table.Cell>
                            <p
                              style={{
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              {Math.round(boundedCompliance.compliance) + " %"}
                            </p>
                          </Table.Cell>
                        );
                        if (
                          "show" in cardConfig.bounds[i + 1]
                            ? cardConfig.bounds[i + 1].show
                            : true
                        ) {
                          cells.push(
                            <Table.Cell>
                              {DateTimeService.build.asDisplayDate(
                                boundedCompliance.upper
                              )}
                            </Table.Cell>
                          );
                        }

                        return cells;
                      })}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {compliance.numberOfPages > 1 && (
              <Pagination
                defaultActivePage={1}
                totalPages={compliance.numberOfPages}
                onPageChange={handlePageChange}
              />
            )}
          </>
        )}
        {!loading && !hasData && <p>{t("BOUNDED_COMPLIANCE_EMPTY")}</p>}
      </Card.Content>
    </Card>
  );
}

export default withTranslation()(BoundedComplianceCard);
