import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  List,
  Message,
  Modal,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import LookupService from "../../services/LookupService";

const Lookup = (props) => {
  const { t, question, onCancel, onSelect } = props;

  const useLookup = question.config?.lookup?.isEnabled || false;
  const minInputCharacters = question.config?.lookup?.minInputCharacters || 2;
  const maxNumberToShow = question.config?.lookup?.maxNumberToShow || 10;
  const allowFreeText = question.config?.lookup?.allowFreeText || false;

  const [lookupCode, setLookupCode] = useState(null);
  const [query, setQuery] = useState("");
  const [lookupResult, setLookupResult] = useState({});

  const setupLookup = async () => {
    if (!useLookup) {
      return;
    }
    setLookupCode(question.config?.lookup?.code);
  };

  useEffect(() => {
    setupLookup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQueryChange = async (e) => {
    const value = e.target.value;
    setQuery(value);
    const result = await LookupService.performLookup(
      minInputCharacters,
      lookupCode,
      value,
      maxNumberToShow
    );
    setLookupResult(result);
  };

  const handleItemSelect = (item) => {
    if (onSelect) {
      onSelect(item);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleSelectFreeText = () => {
    const item = LookupService.buildFreeTextItem(query?.trim());
    handleItemSelect(item);
  };

  const lookupListItems = lookupResult?.items?.map((item) => {
    return (
      <List.Item
        key={item.value}
        onClick={() => handleItemSelect(item)}
        style={styles.listItem}
        className={"mouse-pointer"}
      >
        {item.value}
      </List.Item>
    );
  });

  return (
    <Modal
      size={"small"}
      open={true}
      closeIcon={false}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Header>{question.label}</Modal.Header>
      <Modal.Content>
        <Form.Input
          type={"text"}
          autoFocus={true}
          defaultValue={query}
          onChange={handleQueryChange}
        />

        {lookupResult == null && (
          <Message positive>{t("LOOKUP_ERROR")}</Message>
        )}

        {lookupResult?.hasMoreItems && (
          <Message positive>{t("LOOKUP_TOOMANYRESULTS")}</Message>
        )}

        {lookupResult?.isValidQuery && !lookupResult?.hasItems && (
          <Message negative>{t("LOOKUP_NORESULTS")}</Message>
        )}

        {lookupResult?.isValidQuery &&
          !lookupResult?.hasItems &&
          allowFreeText && (
            <Message positive>
              {t("LOOKUP_ENTERFREETEXT")}
              <div>
                <Button icon primary tiny onClick={handleSelectFreeText}>
                  {t("LOOKUP_USEFREETEXT")}
                </Button>
              </div>
            </Message>
          )}

        {lookupResult?.hasItems && (
          <Container style={styles.listContainer}>
            <List celled size={"big"} style={styles.list}>
              {lookupListItems}
            </List>
          </Container>
        )}

        <div style={styles.cancelButton}>
          <Button onClick={handleCancel}>{t("GLOBAL_BUTTON_CANCEL")}</Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

const styles = {
  listContainer: {
    maxHeight: 200,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  list: {
    margin: 5,
    borderWidth: 1,
  },
  listItem: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  cancelButton: {
    marginTop: 20,
    marginBottom: 10,
  },
};

const enhance = compose(withTranslation());

export default enhance(Lookup);
