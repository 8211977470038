import moment from "moment";
import React from "react";
import DateTimeField from "../../components/DateTimeField";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";

const Time = ({ question, value, changeValue, isReadOnly }) => (
  <DateTimeField
    value={moment("1970-01-01T" + value)}
    onChange={(v) => {
      if (v.isValid()) {
        changeValue(v.format("HH:mm"));
      }
    }}
    mode={"time"}
    readOnly={isReadOnly}
    nowOnly={question?.config?.type === "NOW"}
  />
);

export default withContainer(withLabel(withError(Time)));
