import React, { useRef, useState } from "react";
import {
  EVENTS,
} from "atom5-branching-questionnaire";
import { Header } from "semantic-ui-react";
import branchingQuestionnaireHelper from "../helpers/branchingQuestionnaireHelper";

const DefinitionOnlyQuestionnaireDisplay = ({
  definition,
  onPageChange,
  shouldShowTitle = true,
}) => {
  const _questionsForCreation = useRef();

  const [hasQuestionnaireBuilt, setHasQuestionnaireBuilt] = useState(false);

  const onQuestionPageChange = (page_cursor) => {
      onPageChange && onPageChange();
  }

  if(!hasQuestionnaireBuilt){
    const builder = branchingQuestionnaireHelper.getBuilderWithRenderers();

    const builtQuestionnaire = builder
        .on(EVENTS.CHANGE_PAGE, onQuestionPageChange)
      .withAdditionalProps({
        definition,
        shouldShowSubmit: false,
      })
      .withDefaultValues(
          {},
        0,
        []
      )
      .withQuestions(definition.questions)
      .build();

    _questionsForCreation.current = builtQuestionnaire.render();
    setHasQuestionnaireBuilt(true)
  }

  if(!_questionsForCreation.current) {
    return <></>;
  }

  const nonNullQuestionsForCreation = Object.values(
    _questionsForCreation.current
  ).filter((renderFunction) => !!renderFunction);

  return (
    <>
        {shouldShowTitle === true && definition.label && (
          <Header as={"h2"}>{definition.label}</Header>
        )}
        <div style={{ maxWidth: "800px", display: "flex", flexDirection: "row", flexWrap: 'wrap', margin:'-0.5rem', fontSize: '1rem', lineHeight: '120%'  }}>
          {nonNullQuestionsForCreation.map(([component, props], index) => {
            return (
              React.createElement(component, {
                ...props,
                key: definition.code + "_" + props?.question?.code,
              })
          )})}
        </div>

    </>
  );
};



export default DefinitionOnlyQuestionnaireDisplay;
