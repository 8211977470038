import React, { useState, useEffect, useContext, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon, Label, Table } from "semantic-ui-react";
import StaffService from "../../StaffService";
import TrialService from "../../TrialService";
import { Link } from "react-router-dom";
import Page from "../../components/page/Page";
import ConfigContext from "../../context/ConfigContext";
import TypeHelper from "../../helpers/TypeHelper";
import StaffListFilter from "./StaffListFilter";
import StaffCard from "./StaffCard";
import { usePapaParse } from "react-papaparse";
import { saveAs } from "file-saver";
import AuthService from "../../services/AuthService";
import DateTimeService from "../../services/DateTimeService";
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";
import NameAndEmailSearch from "./NameAndEmailSearch";
import GroupAndRoleSearch from "./GroupAndRoleSearch";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";

const StaffListPage = (props) => {
  const { t, history } = props;

  const config = useContext(ConfigContext);

  const [isLoading, setIsLoading] = useState(true);
  const [staff, setStaff] = useState([]);
  const [hasEmailResent, setHasEmailResent] = useState([]);

  const [staffDropdownFilter, setstaffDropdownFilter] = useState('ACTIVE');
  const [expandedStaffRows, setExpandedStaffRows] = useState([]);
  const [allExpanded, setAllExpanded] = useState(false);
  const [groupLabels, setGroupLabels] = useState();
  const [roleLabels, setRoleLabels] = useState([]);
  const [showAdmin, setShowAdmin] = useState(false);

  const [myProfile, setMyProfile] = useState()

  const [filteredStaffList, setFilteredStaffList] = useState([]);
  const [initialFilterForGroups, setInitialFilterForGroups] = useState([]);

  const [showIsSuperAdmin, setShowIsSuperAdmin] = useState(false);
  
  const showDrugManager = config?.ui?.showDrugManager
    ? TypeHelper.parseBool(config?.ui?.showDrugManager)
    : true;

  const { jsonToCSV } = usePapaParse();

  const TABLE_COL_FIXED_COUNT = 8; // Without optional ones(e.g. Super Admin / Drug Manager)

  const initialise = async () => {
    try {
      setIsLoading(true);

      const isSuperAdmin = await AuthService.getIsSuperAdmin();
      setShowIsSuperAdmin(isSuperAdmin);

      let staffData = await StaffService.listStaff();
      let newHasEmailResent = Array(staffData.length).fill(false);
      const profile = await AuthService.getMyProfile();
      setMyProfile(profile)
      let filteredStaffList = filterStaffListGroupsBasedOnLoggedInUsersGroups(staffData, profile);
      if (!profile.superAdmin) {
        filteredStaffList = removeUsersWithNoMatchingGroups(filteredStaffList);
      }
      setStaff(filteredStaffList);
      const initialList = filteredStaffList.filter(s => {
        return s.state === staffDropdownFilter
          && (showAdmin ? true : s.superAdmin === false);
      });
      setInitialFilterForGroups(initialList);
      setHasEmailResent(newHasEmailResent);
      populateLabels();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateLabels = () => {
    TrialService.getGroups().then((allGroups) => {
        const newGroupLabels = {};
        for (const group of allGroups) {
          newGroupLabels[group.code] = group.label;
          addRoleLabelsForGroup(group.code);
        }
        setGroupLabels(newGroupLabels);
    });
  };

  const addRoleLabelsForGroup = (groupCode) => {
    const newRoleLabels = roleLabels;
    StaffService.getRolesForStaffCreation(groupCode).then((availableRoles) => {
      for (const role of availableRoles) {
          newRoleLabels[role.code] = role.label;
      }
      if (Object.keys(newRoleLabels).length !== 0) {
        setRoleLabels(newRoleLabels);
      }
    });
  };

  const removeUsersWithNoMatchingGroups = (staff) => {
    let staffToRemove = [];
    staff.forEach(s => {
      if (s.groupMappings.length === 0) {
        staffToRemove.push(s);
      }
    });
    let newStaffList = staff.filter(s => {
      return !staffToRemove.includes(s);
    });
    return newStaffList
  };


  const filterStaffListGroupsBasedOnLoggedInUsersGroups = (staff, myProfile) => {
    if (!myProfile.superAdmin && (!myProfile.groupMappings || myProfile.groupMappings.length === 0)) { return []; }

    let groupCodes = [];
    myProfile.groupMappings.forEach(f => {
      groupCodes.push(f.group.code);
    })

    let groupsToRemove = [];
    let returnStaffList = [];
    staff.forEach(s => {
      if (myProfile.superAdmin) {
        returnStaffList.push(s);
        return;
      }

      if (!s.groupMappings) {
        return;
      }
      s.groupMappings.forEach(gm => {
        if (!groupCodes.includes(gm.group.code)) {
          groupsToRemove.push(gm.group.code);
        }
      });

      let newList = s.groupMappings.filter(g => {
        return !groupsToRemove.includes(g.group.code);
      });

      s.groupMappings = newList;
      returnStaffList.push(s);
    });
    return returnStaffList;
  };

  const buildStaffStateLabel = (s, title) => {
    let colour = "green";
    if (s.state === "AWAITING_ACTIVATION") {
      colour = "orange";
    }
    if (s.state === "SUSPENDED") {
      colour = "red";
    }
    return (
      <Label size={"tiny"} color={colour}>
        {title}
      </Label>
    );
  };

  const sendActivationEmail = (staff, index) => {
    StaffService.sendActivationEmail(staff.email);
    setHasEmailResent((prevState) => {
      let nextState = [...prevState];
      nextState[index] = true;
      return nextState;
    });
  };

  const toggleStaffSuspension = (feedbackReason, staff) => {
    StaffService.toggleStaffSuspension(feedbackReason, staff).then(() => {
      initialise();
    });
  };

  const toggleExpandAll = (triggeredFromExportButton) => {
    let allIds = [];
    staff.map((s) => {
      return allIds.push(s.id);
    });
    if (!allExpanded && !triggeredFromExportButton) {
      setExpandedStaffRows(allIds);
      setAllExpanded(true);
    } else if (allExpanded && !triggeredFromExportButton) {
      setExpandedStaffRows([0]);
      setAllExpanded(false);
    }
    //if triggered from export button then expand all staff
    else if (triggeredFromExportButton) {
      setExpandedStaffRows(allIds);
    }
  }

  const filterChangeHandler = selectedstaffDropdownFilter => {
    setstaffDropdownFilter(selectedstaffDropdownFilter);
  }

  const itemCount = (state) => {
    return staff.filter(staffMember => {
      return staffMember.state === state;
    }).length;
  }

  const staffIdNotInExpandedList = (id) => {
    return expandedStaffRows.indexOf(id) === -1;
  }

  const handleToggleExpandStaff = (staffMemberId) => {
    if (staffIdNotInExpandedList(staffMemberId)) {
      setExpandedStaffRows((prevExpandedItems) => [staffMemberId, ...prevExpandedItems]);
    } else {
      setExpandedStaffRows(prevState => prevState.filter((id) => id !== staffMemberId));
    }
  };

  const generateExport = async () => {
    const exportData = []
    filteredStaffList.forEach(s => {
        s.groupMappings.forEach(gm => {
          gm.roles.forEach(r => {
            let roleLabel = roleLabels[r.code]
              ? roleLabels[r.code]
              : r.code.charAt(0).toUpperCase() + r.code.slice(1);

            const exportRow={};
            exportRow[t("STAFF_LIST_TABLE_HEADER_STAFF_ID", "ID")] = s.id;
            exportRow[t("STAFF_LIST_TABLE_HEADER_FIRST_NAME", "First name")] = s.firstName;
            exportRow[t("STAFF_LIST_TABLE_HEADER_LAST_NAME", "Last name")] = s.lastName;
            exportRow[t("STAFF_LIST_TABLE_HEADER_EMAIL", "Email")] = s.email;
            if (showIsSuperAdmin) {
              exportRow[t("STAFF_LIST_TABLE_HEADER_IS_SUPER_ADMIN", "Is Super Admin?")] = s.superAdmin ? t("GENERIC_YES") : t("GENERIC_NO");
            }
            if (showDrugManager) {
             exportRow[t("STAFF_LIST_TABLE_HEADER_DRUG_MANAGER", "Drug Manager?")] = s.drugManager ? t("GENERIC_YES") : t("GENERIC_NO");
            }
            exportRow[t("STAFF_CARD_CREATION_DATE", "Created date")] = s.createdDate;
            exportRow[t("STAFF_CARD_END_DATE", "Suspended date")] = s.endDate;
            exportRow[t("STAFF_LIST_TABLE_HEADER_STATUS", "Status")] =  s.state;
            exportRow[t("GROUPS_LIST_GROUP_NAME", "Group")] = groupLabels[gm.group.code];
            exportRow[t("STAFF_GROUP_HEADER", "Role")] = roleLabel;
            
            exportData.push(exportRow)
          })
        })
    })
    let results = jsonToCSV(exportData);
    const csvBlobData = new Blob([results], {
      type: "text/csv;charset=utf-8;",
    });

    const theDate = DateTimeService.build.asDisplayDate(new Date());
    const filename = `StaffDetailsExport-${theDate}.csv`;
    saveAs(csvBlobData, filename);

  }

  const suspendedCount = itemCount('SUSPENDED');
  const awaitingActivationCount = itemCount('AWAITING_ACTIVATION');

  const handleShowAdmin = () => {
    setShowAdmin(!showAdmin);
  }
  const handleFilteredStaffList = (list) => {
    setFilteredStaffList(list);
  };

  const handleFilterChanged = (concatenatedSearchResults) => {
    setFilteredStaffList(concatenatedSearchResults);
    setInitialFilterForGroups(concatenatedSearchResults);
  };

  const getTableColumnCount = () => {
    let count = TABLE_COL_FIXED_COUNT;
    if (showIsSuperAdmin) {
      count++;
    }
    if (showDrugManager) {
      count++;
    }
    return count;
  }

  const tableColumnCount = useMemo(getTableColumnCount, [showIsSuperAdmin, showDrugManager]);

  let styles = {
    marginRight: '20px',
    width: '250px',
    height: '250px',
    backgroundColor: 'yellow',
  };

  return (
    <>
    <Page
      name="STAFF_LIST"
      header={t("STAFF_LIST_HEADER")}
      subheader={t("STAFF_LIST_SUBHEADER")}
      loading={isLoading}>
      <div style={{
        padding:10,
        display: 'flex',
        height: 50,
        alignItems: 'center' }}>
        <StaffListFilter
          suspendedCount={suspendedCount}
          awaitingActivationCount={awaitingActivationCount}
          selected={staffDropdownFilter}
          onChangeFilter={filterChangeHandler}/>
          {myProfile?.superAdmin && (<div style={{marginLeft: 20, marginBottom: 10}}>
            <AparitoSwitch style={styles}
              id="aparitoSwitch"
              checked={showAdmin}
              onChange={handleShowAdmin}
              label={t("STAFF_INCLUDE_SUPER_ADMIN", "Include Super Admin")} />
          </div>)}

          <NameAndEmailSearch
            staff={staff}
            showAdmin={showAdmin}
            roleLabels={roleLabels}
            groupLabels={groupLabels}
            staffDropdownFilter={staffDropdownFilter}
            initialFilterForGroups={initialFilterForGroups}
            handleFilterChanged={handleFilterChanged}
            />

          <GroupAndRoleSearch
            initialFilterForGroups={initialFilterForGroups}
            handleFilteredStaffList={handleFilteredStaffList}
            groupLabels={groupLabels}
            roleLabels={roleLabels}/>
      </div>

      <Table selectable columns={tableColumnCount} id={"staffTable"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell id="expand" onClick={() => toggleExpandAll(false)} style={{ cursor: "pointer" }}>
              {!allExpanded && <Icon name="caret right" />}
              {allExpanded && <Icon name="caret down" />}
              {" " + t("STAFF_LIST_TABLE_HEADER_EXPAND", "Expand")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("STAFF_LIST_TABLE_HEADER_STAFF_ID", "ID")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("STAFF_LIST_TABLE_HEADER_FIRST_NAME", "First name")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("STAFF_LIST_TABLE_HEADER_LAST_NAME", "Last name")}
            </Table.HeaderCell>
            {showIsSuperAdmin && (
              <Table.HeaderCell>
                {t("STAFF_LIST_TABLE_HEADER_IS_SUPER_ADMIN", "Is Super Admin?")}
              </Table.HeaderCell>
            )}
            {showDrugManager && (
              <Table.HeaderCell>
                {t("STAFF_LIST_TABLE_HEADER_DRUG_MANAGER", "Drug Manager?")}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>
              {t("STAFF_LIST_TABLE_HEADER_EMAIL", "Email")}
            </Table.HeaderCell>
            <Table.HeaderCell collapsing>
              {t("STAFF_LIST_TABLE_HEADER_CREATED_DATE", "Created Date")}
            </Table.HeaderCell>
            <Table.HeaderCell collapsing>
              {t("STAFF_LIST_TABLE_HEADER_STATUS", "Status")}
            </Table.HeaderCell>
            <Table.HeaderCell id="actions">
              {t("STAFF_LIST_TABLE_HEADER_ACTIONS", "Actions")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredStaffList.map((staffEntity, index) => {
            const disabled = !staffEntity.canBeEdited;
            const goToEdit =
              disabled ||
              (() => {
                history.push("/app/staff/edit/" + staffEntity.id);
              });

            return (
              <>
                <Table.Row
                  key={staffEntity.id}
                  warning={staffEntity.state !== "ACTIVE"}
                  style={disabled ? { opacity: 0.5 } : { cursor: "pointer" }}
                >
                  <Table.Cell id="expand" onClick={() => handleToggleExpandStaff(staffEntity.id)}>
                   {staffIdNotInExpandedList(staffEntity.id) && <Icon name="caret right" /> }
                   {!staffIdNotInExpandedList(staffEntity.id) && <Icon name="caret down" /> }
                  </Table.Cell>
                  <Table.Cell onClick={goToEdit}>{staffEntity.id}</Table.Cell>
                  <Table.Cell onClick={goToEdit}>{staffEntity.firstName}</Table.Cell>
                  <Table.Cell onClick={goToEdit}>{staffEntity.lastName}</Table.Cell>
                  {showIsSuperAdmin && (
                    <Table.Cell onClick={goToEdit}>
                      {staffEntity.superAdmin ? t("GENERIC_YES") : t("GENERIC_NO")}
                    </Table.Cell>
                  )}
                  {showDrugManager && (
                    <Table.Cell onClick={goToEdit}>
                      {staffEntity.drugManager ? t("GENERIC_YES") : t("GENERIC_NO")}
                    </Table.Cell>
                  )}
                  <Table.Cell onClick={goToEdit}>{staffEntity.email}</Table.Cell>
                  <Table.Cell onClick={goToEdit}>
                    {staffEntity.createdDate ? `${DateTimeService.build.asDisplayDateTime(staffEntity.createdDate)}` : ''}
                  </Table.Cell>
                  <Table.Cell onClick={goToEdit}>
                    {buildStaffStateLabel(staffEntity, t("STAFF_STATE_" + staffEntity.state))}
                  </Table.Cell>
                  <Table.Cell id="actions" textAlign={"right"}>
                    {staffEntity.state === "AWAITING_ACTIVATION" &&
                      !hasEmailResent[index] && (
                        <Button
                          primary
                          size={"tiny"}
                          labelPosition="left"
                          icon
                          disabled={disabled}
                          onClick={() => sendActivationEmail(staffEntity, index)}>
                          {t("STAFF_LIST_SEND_ACTIVATION_EMAIL_BUTTON")}{" "}
                          <Icon name="mail" />
                        </Button>
                      )}

                    {staffEntity.state === "AWAITING_ACTIVATION" &&
                      hasEmailResent[index] && (
                        <Button
                          color="yellow"
                          disabled
                          size={"tiny"}
                          labelPosition="left"
                          icon
                        >
                          {t("SENT")} <Icon name="paper plane" />
                        </Button>
                      )}

                    {staffEntity.state !== "SUSPENDED" && !StaffService.isPrimarySuperAdmin(staffEntity) && !disabled && (
                      <ConfirmButtonWithFeedback
                        buttonText={t("STAFF_LIST_SUSPEND_BUTTON", "Suspend")}
                        headerText={t("STAFF_ACCOUNT_SUSPEND_HEADER", "Suspend Staff Account")}
                        contentText={t("STAFF_ACCOUNT_SUSPEND_DETAIL", "Please confirm that you want to suspend this staff account")}
                        color={"red"}
                        confirmButtonText={t("STAFF_LIST_SUSPEND_BUTTON", "Suspend")}
                        cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                        onConfirm={(feedback) => toggleStaffSuspension(feedback, staffEntity)}
                        placeholderText={t("STAFF_LIST_SUSPEND_BUTTON_REASON", "Reason")}
                        mandatoryValidationText={t("STAFF_LIST_SUSPEND_BUTTON_REASON_ERROR", "Please enter a valid reason")}
                      />
                    )}

                    {staffEntity.state === "SUSPENDED" && !StaffService.isPrimarySuperAdmin(staffEntity) && (
                      <ConfirmButtonWithFeedback
                        buttonText={t("STAFF_LIST_ACTIVATE_BUTTON", "Activate")}
                        headerText={t("STAFF_ACCOUNT_ACTIVATE_HEADER", "Activate Staff Account")}
                        contentText={t("STAFF_ACCOUNT_ACTIVATE_DETAIL", "Please confirm that you want to activate this staff account")}
                        color={"green"}
                        confirmButtonText={t("STAFF_LIST_ACTIVATE_BUTTON", "Activate")}
                        cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                        onConfirm={(feedback) => toggleStaffSuspension(feedback, staffEntity)}
                        disabled={disabled}
                        placeholderText={t("STAFF_LIST_ACTIVATE_BUTTON_REASON", "Subject code")}
                        mandatoryValidationText={t("STAFF_LIST_ACTIVATE_BUTTON_REASON_ERROR", "Please enter a valid reason")}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
                {!staffIdNotInExpandedList(staffEntity.id) && (
                <Table.Row key={staffEntity.id + "-row"}>
                  <Table.Cell id="expandedData" colSpan={tableColumnCount}>
                    <StaffCard
                      staffEntity={staffEntity}
                      groupLabels={groupLabels}
                      roleLabels={roleLabels}/>
                  </Table.Cell>
                </Table.Row>
                )}
              </>
            );
          })}
        </Table.Body>
      </Table>

      {filteredStaffList.length === 0 && (
        <div style={{ marginBottom:20 }}>{t("STAFF_NO_STAFF_IN_LIST", "No staff listed, try adjusting the filters above.")}</div>
      )}

      <Button primary icon as={Link} labelPosition="left" to={"/app/staff/new"}>
        {t(["STAFF_LIST_ADD_NEW", "GLOBAL_BUTTON_ADD_NEW"])}{" "}
        <Icon name="user md" />
      </Button>

      <Button
        primary
        icon
        disabled={filteredStaffList.length === 0}
        labelPosition="left"
        onClick={generateExport}>
        {t("EXPORT_TABLE", "Export Table as CSV")}
        <Icon name="table" />
      </Button>
    </Page>
    </>
  );
};

export default withTranslation()(StaffListPage);
