import RequestHelper from "../RequestHelper"

export default class RewardService {

  static getAvailableRewardCount(type = "AMAZON_VOUCHER") {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/reward/available/${type}`,
      {},
      "GET",
    );
  }
  
  static getUnfulfilledRewardCount(type = "AMAZON_VOUCHER") {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/reward/unfulfilled/${type}`,
      {},
      "GET",
    );
  }

  static uploadRewardCodes(codes, type = "AMAZON_VOUCHER") {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/reward/upload/${type}`,
      {},
      "POST",
      null,
      codes
    );
  }
}