import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import * as _ from "lodash";
import SubjectService from "../../SubjectService";
import ConfigService from "../../services/ConfigService";

const NotificationTime = (props) => {
  const { style } = props;
  const showPreferredNotificationTime =
    ConfigService.showPreferredNotificationTime();

  const preferredNotificationTimeHours = _.range(0, 24);
  const preferredNotificationTimeOptions = preferredNotificationTimeHours.map(
    (hour) => {
      const itemText = hour.toString().padStart(2, "0") + ":00";
      return {
        text: itemText,
        key: hour,
        value: hour,
      };
    }
  );

  const [
    selectedPreferredNotificationTime,
    setSelectedPreferredNotificationTime,
  ] = useState(0);

  const fetchSelectedPreferredNotificationTime = async () => {
    if (!showPreferredNotificationTime) {
      // If it's disabled, let's reset the value
      SubjectService.updatePreferredNotificationTime(null);
      return;
    }

    const preferredTimeFromService =
      await SubjectService.getPreferredNotificationTime();
    if (preferredTimeFromService) {
      setSelectedPreferredNotificationTime(preferredTimeFromService);
    } else {
      setSelectedPreferredNotificationTime(0);
    }
  };

  useEffect(() => {
    fetchSelectedPreferredNotificationTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPreferredNotificationTimeChange = (e, data) => {
    SubjectService.updatePreferredNotificationTime(data.value);
    setSelectedPreferredNotificationTime(data.value);
  };

  return (
    <>
      <Dropdown
        style={style}
        fluid
        selection
        options={preferredNotificationTimeOptions}
        value={selectedPreferredNotificationTime}
        onChange={onPreferredNotificationTimeChange}
      />
    </>
  );
};

export default withTranslation()(NotificationTime);
