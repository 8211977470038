import SubjectService from "../../../../SubjectService";

const YumenHelper = {
    findYumenHubId: async (subjectId) => {
        if (subjectId === undefined) {
            return;
        }

        const subjectData = await SubjectService.getSubjectData(subjectId);
        let theHubId = undefined;
        if (subjectData.subjectRecords) {
            subjectData.subjectRecords.map(r => {
                if (r?.answers?.yumenwearablehubid) {
                    theHubId = r?.answers?.yumenwearablehubid;
                }
                return null;
            });
        }
        return theHubId;
    }
};

export default YumenHelper;
