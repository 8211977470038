import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    BarElement
  } from 'chart.js';

ChartJS.register(BarElement, CategoryScale);

const StackedColumnChart = (props) => {

    const {title,data} = props

    const options = {
      animation: {
        duration: 0, // Set animation duration to 0 milliseconds
      },
      scales: {
        x: {
          stacked: true, // Enable stacking for the x-axis (categories)
        },
        y: {
          stacked: true, // Enable stacking for the y-axis (values)
          beginAtZero: true,
          title: {
            display: true,
            text: 'Duration (seconds)',
          },
        },
      },
    };

  return (
    <div >
      <h2 style={titleStyle}>{title}</h2>
      <Bar data={data} options={options} style={charStyle}/>
    </div>
  );
};

const titleStyle = {
  textAlign: 'center',
  color: 'black',
};

const charStyle = {
  width: 600,
  height: 400,
}

export default StackedColumnChart;
