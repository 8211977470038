import React from "react";
import { Icon } from "semantic-ui-react";

const CollapseButton = (props) => {
  const { isExpanded, fn } = props;
  return (
    <div style={styles.container}>
      <div style={styles.button} onClick={fn}>
        <div style={styles.angleRight}>
          {isExpanded && <Icon name="angle left" color="orange" size="large" />}
          {!isExpanded && (
            <Icon name="angle right" color="orange" size="large" />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: "0px",
    width: "0px",
  },
  button: {
    height: "30px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    boxShadow: "2px 4px 16px 0px rgba(0,0,0,0.2) ",
    borderRadius: "100%",
  },
  angleRight: {
    paddingLeft: "2px",
  },
};

export default CollapseButton;
