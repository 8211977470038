/**
 * access object property using string . notation
 * e.g. getProperty(questionnaire, 'triggerActivityAudit.triggerName')
 */
export const getDescendantProperty = (obj, desc) => {
    const arr = desc.split(".");
    const result = arr.reduce((a, b) => a?.[b], obj);
    return result;
}

export const parseExpression = (expression, context) => {
    let res = ''
    try {
        // eslint-disable-next-line
        const c = context//used in eval
        // eslint-disable-next-line
        res =  eval('`' + expression + '`')
    } catch (error) {
        console.warn("[objectUtils][parseExpression] failed to parse expression ", expression, context)
    }
    return res !== undefined && res !== null && res !== 'null' ? res : '';
}