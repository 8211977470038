import QUESTIONNAIRE_COLUMN_KEYS from "../../../constants/QUESTIONNAIRE_COLUMN_KEYS";
import {Button, Table} from "semantic-ui-react";
import DateTimeService from "../../../services/DateTimeService";
import TriggerInfoDisplay from "../TriggerInfoDisplay";
import React from "react";
import QuestionnaireInfoModalButton from "../QuestionnaireInfoModalButton";
import workflowHelper from "../../../helpers/questionnaireWorkflowHelper";


export default {
  forHeader: (columnType, t) => {
    switch (columnType) {
      case QUESTIONNAIRE_COLUMN_KEYS.TOTAL_SCORE:
        return (
            <Table.HeaderCell key={"totalScore"}>
              {t("TOTAL_SCORE")}
            </Table.HeaderCell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_BY:
        return (
            <Table.HeaderCell key={"completedBy"}>
              {t("SUBJECT_TAB_DATA_TABLE_COMPLETED_BY_HEADER", "Completed by")}
            </Table.HeaderCell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_DATE:
        let completionDateHeaderText = t("SUBJECT_QUESTIONNAIRE_META_COMPLETED");
        if (
            completionDateHeaderText.charAt(completionDateHeaderText.length - 1) ===
            ":"
        ) {
          completionDateHeaderText = completionDateHeaderText.substr(
              0,
              completionDateHeaderText.length - 1
          );
        }
        return (
            <Table.HeaderCell key={"completiondate"}>
              {completionDateHeaderText}
            </Table.HeaderCell>
        );
      case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_LOCATION:
        return (
            <Table.HeaderCell key={"locationData"}>
              {t("SUBJECT_TAB_DATA_TABLE_LOCATION_HEADER", "Location")}
            </Table.HeaderCell>
        );
      case QUESTIONNAIRE_COLUMN_KEYS.DELIVERED_DATE:
        return (
            <Table.HeaderCell key={"deliveryDate"}>
              {t("SUBJECT_TAB_DATA_TABLE_DELIVERED_DATE_HEADER", "Delivered")}
            </Table.HeaderCell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_ID_ENCODED:
        return (
            <Table.HeaderCell key={"encodedid"}>
              {t("SUBJECT_TAB_DATA_TABLE_ENCODED_ID_HEADER")}
            </Table.HeaderCell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_ID:
        return (
            <Table.HeaderCell key={"questionnaireid"}>
              {t("SUBJECT_TAB_DATA_TABLE_ID_HEADER")}
            </Table.HeaderCell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.CREATION_TRIGGER:
        return (
            <Table.HeaderCell key={"triggerInfo"}>
              {t("SUBJECT_TAB_DATA_TABLE_TRIGGER_CREATION_SOURCE", "Trigger")}
            </Table.HeaderCell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.CREATED_DATE:
        return (
            <Table.HeaderCell key={"createddate"}>
              {t("SUBJECT_TAB_DATA_TABLE_CREATION_DATE_HEADER")}
            </Table.HeaderCell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_SUBMIT_BUTTON:
        return (
            <Table.HeaderCell>
              {t("SUBJECT_TAB_DATA_TABLE_SUBMIT_QUESTIONNAIRE")}
            </Table.HeaderCell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.WORKFLOW_TASK_START_DATE:
        return (
            <Table.HeaderCell>
              {t("SUBJECT_TAB_DATA_TABLE_WORKFLOW_TASK_START_DATE")}
            </Table.HeaderCell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_INFO_BUTTON:
        return (
            <Table.HeaderCell />
        )
      default:
        return null;
    }
  },

  forBody: (columnType, questionnaire, definition, t, history) => {
    switch (columnType) {
      case QUESTIONNAIRE_COLUMN_KEYS.TOTAL_SCORE:
        return <Table.Cell key={"totalscore"}>{questionnaire.totalScore}</Table.Cell>
      case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_BY:
        return <Table.Cell key={"completedBy"}>
          {questionnaire.questionnaireAuthReference != null ? questionnaire.questionnaireAuthReference : ""}
        </Table.Cell>
      case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_DATE:
        return (<Table.Cell key={"completionDate"}>
          {questionnaire.completionDate != null
              ? DateTimeService.build.asDisplayDateTime(
                  questionnaire.completionDate
              )
              : t("SUBJECT_QUESTIONNAIRE_NOT_YET_COMPLETED")}
        </Table.Cell>)
      case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_LOCATION:
        const location = (questionnaire.latitude == null || questionnaire.longitude == null)
            ? "-"
            : questionnaire.latitude + ', ' + questionnaire.longitude;
        return (
            <Table.Cell key={"locationData"}>
              {location}
            </Table.Cell>
        );
      case QUESTIONNAIRE_COLUMN_KEYS.DELIVERED_DATE:
        return (<Table.Cell key={"deliveryDate"}>
          {questionnaire.deliveryDate != null
              ? DateTimeService.build.asDisplayDateTime(
                  questionnaire.deliveryDate
              )
              : t("SUBJECT_QUESTIONNAIRE_NOT_YET_RECEIVED")}
        </Table.Cell>)
      case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_ID_ENCODED:
        return (
            <Table.Cell key={"encodedId"}>
              {btoa(questionnaire.id)}
            </Table.Cell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_ID:
        return (
            <Table.Cell key={"questionnaireId"}>
              {questionnaire.id}
            </Table.Cell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.CREATION_TRIGGER:
        return (
            <Table.Cell key={"triggerInfo"}>
              <TriggerInfoDisplay t={t} questionnaire={questionnaire}/>
            </Table.Cell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.CREATED_DATE:
        return (<Table.Cell key={"deliveryDate"}>
          {DateTimeService.build.asDisplayDateTime(
              questionnaire.createdDate
          )}
        </Table.Cell>)
      case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_SUBMIT_BUTTON:
        return (
            <Table.Cell onClick={e=>e.preventDefault()}>
              <Button primary onClick={(e) => {
                e.stopPropagation()
                history.push(
                    "/app/subject/" +
                    questionnaire.subjectId +
                    "/questionnaire-type/" +
                    definition?.type +
                    "/" +
                    definition.code +
                    "/submit/" +
                    questionnaire.id
                );
              }} >{t("SUBJECT_TAB_DATA_TABLE_SUBMIT_BUTTON", "Submit")}</Button>
            </Table.Cell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_INFO_BUTTON:
        return (
            <Table.Cell>
              <QuestionnaireInfoModalButton
                  questionnaire={questionnaire}
                  definition={definition}
                  t={t}
                  history={history} />
            </Table.Cell>
        )
      case QUESTIONNAIRE_COLUMN_KEYS.WORKFLOW_TASK_START_DATE:
        return (
            <Table.Cell>
              {DateTimeService.build.asDisplayDateTime(
                  workflowHelper.getTaskInstanceFromQuestionnaire(questionnaire)?.startDate
              )}
            </Table.Cell>
        )
      default:
        return null;
    }
  }
}
