import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Dropdown, Form, Input} from "semantic-ui-react";
import { ACTION_TYPES } from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";
import { QUESTIONNAIRE_DEFINITION_RESTRICT_TO } from "../../../constants/QUESTIONNAIRE_DEFINITION_RESTRICT_TO";

function QuestionnaireEditorCardComponent({
  questionnaireDefinition,
  isReadOnly = false,
  dispatch,
}) {
  const { t } = useTranslation();

  const restrictToOptions = Object.entries(QUESTIONNAIRE_DEFINITION_RESTRICT_TO)
    .map(([_key, value]) => {
      return {
        key: value,
        text: value,
        value: value,
      };
    });

  const onChange = (_e, v) => {
    const composedResponse = { ...questionnaireDefinition, [v.name]: v.value };
    dispatch({
      type: ACTION_TYPES.EDIT_QUESTIONNAIRE_DEFINITION,
      payload: composedResponse,
    });
  };

  return (
    <Form>
      <Form.Field>
        <label>{t("QUESTIONNAIRE_DEFINITION_TYPE", "Type")}</label>
        <Input
          value={questionnaireDefinition.type}
          name={"type"}
          fluid
          transparent={isReadOnly}
          disabled={isReadOnly}
          onChange={onChange}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("QUESTIONNAIRE_DEFINITION_SEQUENCE", "Sequence")}</label>
        <Input
          value={questionnaireDefinition.sequence}
          name={"sequence"}
          fluid
          transparent={isReadOnly}
          disabled={isReadOnly}
          onChange={onChange}
        />
      </Form.Field>
      <Form.Field>
        <label>
          {t("QUESTIONNAIRE_DEFINITION_NONSCORING", "Non Scoring?")}
        </label>
        <Checkbox
          checked={questionnaireDefinition.nonScoring}
          name={"nonScoring"}
          onChange={onChange}
        />
      </Form.Field>
      <Form.Field>
        <label>
          {t("QUESTIONNAIRE_DEFINITION_SCORECALCULATION", "Score Calculation")}
        </label>
        <Input
          value={questionnaireDefinition.scoreCalculation}
          name={"scoreCalculation"}
          fluid
          transparent={isReadOnly}
          disabled={isReadOnly}
          onChange={onChange}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("QUESTIONNAIRE_DEFINITION_RESTRICTTO", "Restrict to")}</label>
        <Dropdown
          fluid
          selection
          options={restrictToOptions}
          value={questionnaireDefinition.restrictTo}
          name={"restrictTo"}
          onChange={onChange}
          disabled={isReadOnly}
        />
      </Form.Field>
    </Form>
  );
}

export default QuestionnaireEditorCardComponent;
