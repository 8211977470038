import React, {useMemo} from "react";
import { compose } from "redux";
import { Form } from "semantic-ui-react";
import DOMPurify from "dompurify";
import withQuestionRequiredText from "./withQuestionRequiredText";
import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import useSubjectContext from "../../../hooks/useSubjectContext";

const withLabel = (WrappedComponent) => {
  return (props) => {
    const { question, questionRequiredText, isReadOnly, subjectId } = props;

    let label = question.label;
    let showLabel = true;
    if ("showLabel" in props) {
      showLabel = props.showLabel;
    }

    const {subjectContext, helpers} = useSubjectContext(subjectId, label);
    let displayLabel = useMemo(() => {
      if(Array.isArray(label)) return label
      return helpers.textSubstitution(label, subjectContext)
    }, [helpers, label, subjectContext])

    if (!isReadOnly && question.type === QUESTION_TYPES.SINGLE_FIXED_VALUE) {
      showLabel = false;
    }

    let useMarkup = false;
    if (question?.config?.useMarkup) {
      useMarkup = question?.config?.useMarkup;
    }

    if (question.required) {
      displayLabel += " " + questionRequiredText;
    }

    const useInlineLabel = question.config?.useInlineLabel;
    const displayLabelRight = question.config?.displayLabelRight;

    const labelStyles = {
      marginBottom: useInlineLabel ? '0rem' : '0.75rem',
      marginLeft: useInlineLabel && displayLabelRight ? '0.75rem' : '0rem',
      marginRight: useInlineLabel && !displayLabelRight ? '0.75rem' : '0rem',
      flexGrow: useInlineLabel && displayLabelRight ? 1 : undefined
    }

    return (
      <Form.Field style={useInlineLabel ? {display: "flex", alignItems: "center", flexDirection: displayLabelRight ? 'row-reverse' : 'row' } : {}}>
        {showLabel && !useMarkup ? <label style={{...labelStyles, display: 'flex', alignSelf: 'center'}}>{displayLabel}</label> : null}
        {showLabel && useMarkup ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(question.label),
            }}
            style={labelStyles}
          />
        ) : null}
        <WrappedComponent {...props} label={displayLabel} />
      </Form.Field>
    );
  };
};

const enhance = compose(withQuestionRequiredText, withLabel);

export default enhance;
