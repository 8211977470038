import React, {Fragment, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Placeholder, Segment} from "semantic-ui-react";
import moment from "moment";
import {addPeriod, ensureXAxisShows} from "../../../services/helpers/graphHelper";
import SensorDataService from "../../../services/sensordata/SensorDataService";
import {Bar} from "react-chartjs-2";
import PeriodSelection from "./PeriodSelection";

const SubjectStepsLoggedGraph = ({subjectId, t}) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [stepsLogged, setStepsLogged] = useState([]);
  const [fromDate, setFromDate] = useState(moment().clone().add(-1, 'week').startOf('day'));
  const [period, setPeriod] = useState('week');

  useEffect(() => {
    getData().catch(console.error);
    // eslint-disable-next-line
  }, [subjectId, fromDate, period])

  const getData = async () => {
    try {
      setLoading(true)
      const toDate= addPeriod(fromDate, period);

      const data = await SensorDataService.getStepData(subjectId, 'logged_data', fromDate.format('YYYY-MM-DD'), addPeriod(fromDate, period).format('YYYY-MM-DD'), 'day_sum', null, 'none');
      let loggedData = [];
      if (data.timeSeries) {
        data.timeSeries.forEach(({timestamp, value}) => {
          loggedData.push({x: timestamp, y: value});
        });
      }
      loggedData = ensureXAxisShows(loggedData, fromDate, toDate);

      let dataSets = [];
      if (loggedData) {
        let dataSet = {
          label: t("GRAPH_STEPS_LOGGED", "Steps (logged)"),
          borderColor: "orange",
          backgroundColor: "orange",
          fill: "none",
          pointRadius: 4,
          pointHoverRadius: 6,
          data: loggedData.map((stepObj, index) => {
            return {
              x: stepObj.x,
              y: stepObj.y,
            };
          }),
        };
        dataSets.push(dataSet);
      }
      setStepsLogged(dataSets);

      setLoading(false);
    } catch (e) {
      console.error('Error loading logged step data', e);
      setError(true);
      setLoading(false);
    }
  };

  let options = {
    scales: {
      y: {
        title: {
          display: true,
          text: t("GRAPH_STEPS_LOGGED_Y_AXIS", "Steps"),
        },
      },
      x: {
        title: {
          display: true,
          text: t("GRAPH_STEPS_LOGGED_X_AXIS_HOURS", "Date"),
        },
      },
    }
  };

  const chartData = {
    datasets: stepsLogged,
  };

  return (
      <Fragment>
        {loading && (
            <Segment>
              <Placeholder fluid>
                <Placeholder.Header image>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                </Placeholder.Paragraph>
              </Placeholder>
            </Segment>
        )}

        {!loading && (
            <>
              <PeriodSelection setFromDate={setFromDate} fromDate={fromDate} period={period} setPeriod={setPeriod} />

              {error && <p>{t("SENSOR_DATA_ERROR", "Error while fetching data, if the problem persists contact support")}</p>}

              {!stepsLogged && (
                  <p>{t("SUBJECT_TAB_DATA_TABLE_EMPTY", "There is currently no logged data to display for selected period")}</p>
              )}
              {stepsLogged && (
                  <Bar data={chartData} options={options}/>
              )}
            </>
        )}
      </Fragment>
  );

}

export default withTranslation()(SubjectStepsLoggedGraph);
