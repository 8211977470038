import React, {Component} from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import SubjectViewDevicesTab from "./SubjectViewDevicesTab";
import { Link, Route, Switch } from "react-router-dom";
import SubjectViewQuestionnaireTab from "./SubjectViewQuestionnaireTab";
import SubjectViewGraphsTab from "./SubjectViewGraphsTab";
import SubjectDeviceCreatePage from "./SubjectDeviceCreatePage";
import SubjectViewMedicationTab from "./SubjectViewMedicationTab";
import SubjectMedicationCreatePage from "./SubjectMedicationCreatePage";
import SubjectMedicationEditPage from "./SubjectMedicationEditPage";
import SubjectViewAllergiesTab from "./SubjectViewAllergiesTab";
import SubjectAllergyCreatePage from "./SubjectAllergyCreatePage";
import SubjectAllergyEditPage from "./SubjectAllergyEditPage";
import SubjectRecordTab from "./SubjectRecordTab";
import SubjectVitalSignsTab from "./SubjectVitalSignsTab";
import SubjectViewVideoCallsTab from "./SubjectViewVideoCallsTab";
import SubjectVideoCallCreatePage from "./SubjectVideoCallCreatePage";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import { Button, Icon } from "semantic-ui-react";
import StaffService from "../../StaffService";
import PermissionsService from "../../services/PermissionsService";
import './subjectStyles.css';
import ExportModal from "./ExportModal";
import GroupPermission from "../../GroupPermission";
import SubjectViewModuleQuestionnaireTab from "./SubjectViewModuleQuestionnaireTab";

class SubjectViewPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      tabs: [],
      groupMappings: [],
      loading: true,
      subjectViewLoading: props.subjectViewLoading ? props.subjectViewLoading : false,
      showExportModal: false,
      hasManageSiteExport:false,
      subjectIds:[],
      totalSubjectCount: 0,
      pageSizeInt: 25 //matches nucleus defaultPageSizeInt

    };
    this.checkForPermission();
  }

  componentDidMount() {
    this.reload(false);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.subjectData.subjectCode !== prevProps.subjectData.subjectCode || this.props.match.params.subjectId !== prevProps.match.params.subjectId) {
      this.reload(false);
    }
  };

  checkForPermission = async () => {
    const hasManageSiteExport = await PermissionsService.hasPermissionInGroup(this.props.subjectData.groups[0]?.code, GroupPermission.MANAGE_SITE_EXPORTS);
    this.setState({ hasManageSiteExport });
  };

  handleExportModalClose = () => {
    this.setState({showExportModal:false})
  };

  openExportModal = () => {
    this.setState({showExportModal:true})
  }

  getSubjectIdsPage = async (subjectFilterParams, pageIncrement) => {
    const subjectData = await SubjectService.listSubjectIds(
        subjectFilterParams.groupCode,
        subjectFilterParams.pageNum + pageIncrement,
        subjectFilterParams.searchTerm,
        subjectFilterParams.sortBy,
        subjectFilterParams.sortDirection,
        subjectFilterParams.pageLimit,
        subjectFilterParams.enabledFilters,
        subjectFilterParams.subjectStates,
        subjectFilterParams.includeStopped)
    return subjectData;
  }

  reload = async (pageInitialised = true) => {
    if (pageInitialised) {
      this.setState({ loading: true });
    }
    //Load page of subjectIds data for navigating previous\Next
    const subjectListFilterParams = LocalStorageHelper.getJsonObject("subjectListFilterParams");
    const subjectsData = await this.getSubjectIdsPage(subjectListFilterParams,0);
    const { allSubjectIds } = subjectsData;
    this.setState({subjectIds:allSubjectIds})


    if (this.props.subjectData.groups.length === 0) {
      return;
    }

    let groupMappings, tabs;
    try {
      groupMappings = (await StaffService.getMyProfile()).groupMappings;
      tabs = await this.getTabsInfo(this.props.subjectData.groups, groupMappings);
      tabs = tabs.filter((tab) => tab != null);
    } catch (e) {
      console.log(e);
    }

    this.setState({
      groupMappings,
      tabs,
      loading: false,
    });
  };

  getTabsInfo = async (subjectGroups, groupMappings) => {
    const subjectRecordTabNames = (
      await SubjectService.getSubjectRecordQuestionnaireDefinitions()
    )
      .map((definition) =>
        definition.config.tab ? definition.config.tab : "details"
      )
      // The following line reduces the list to unique values
      .filter((value, index, self) => self.indexOf(value) === index);

    const defaultTabsConfig = [
      "devices",
      "pro",
      "event",
      "visit",
      "data",
      "medication",
      "allergies",
      "graphs",
    ];
    const tabsConfig = Window.configuration.ui.tabs || defaultTabsConfig;
    const allTabsConfig = [...subjectRecordTabNames, ...tabsConfig];

    return Promise.all(
      allTabsConfig.map(async (tab) => {
        const isSubjectRecord = subjectRecordTabNames.includes(tab);

        const hasPermission = await PermissionsService.canStaffViewSubjectTab(
          subjectGroups,
          tab,
          groupMappings,
          isSubjectRecord
        );

        if (!hasPermission) {
          return;
        }

        return {
          tab,
          isSubjectRecord,
        };
      })
    );
  };

  activeLink = (tabName) => {
    const detailsTabs = [
      "extra",
      "details",
      "day0",
      "day1record",
      "day1",
      "uat",
      "UAT tests",
    ];
    if (detailsTabs.includes(tabName)) {
      tabName = "subject-record/" + tabName;
    }

    const isActiveRegex = new RegExp('.*/tabs/' + tabName + '([/?].*)?$');
    return this.props.location.pathname.match(isActiveRegex);
  };

  //Tracks the loading state of the subjectViewModuleTab component so as the main questionnaire
  //module tabs can be disabled while loading. This is to avoid stale data being displayed to the user.
  setSubjectViewModuleLoadingState = (pageLoadingState) => {
    this.setState({ subjectViewLoading: pageLoadingState });
  }

  render() {
    const { t } = this.props;
    let paginationLimit = Window.configuration?.ui?.subjectListConfig?.defaultPageSize
          ? Window.configuration?.ui?.subjectListConfig?.defaultPageSize   // if defined get defaultpageing size
          : Window.configuration?.ui?.subjectPaginationLimit
          ? Window.configuration?.ui?.subjectPaginationLimit  //if not found,  fall back to legacy
          : 25;   //default to 25 failing both

    const navigationEnabled = Window.configuration?.ui?.subjectListConfig?.navigationEnabled
      ? Window.configuration?.ui?.subjectListConfig?.navigationEnabled === "true"
        : false;

    if (paginationLimit > 50 ){
      paginationLimit = 50;
    }

    const isNew = new URLSearchParams(window.location.search).get("new");
    const tabs = this.state.tabs.map(({ tab, isSubjectRecord }) => {
      let tabName;
      if (typeof tab === "object") {
        tabName = tab.name;
      } else {
        // the tab value is a string for the tab name
        tabName = tab;
      }

      const linkToPrefix =
        "/app/subject/" +
        this.props.match.params.subjectId +
        "/tabs" +
        (isSubjectRecord ? "/subject-record" : "") +
        "/";

      return (
        <Link
          to={linkToPrefix + tabName + (isNew ? "?new=true" : "")}
          className={(this.state.subjectViewLoading ? "disabled-link " : "") + "item " + (this.activeLink(tabName) ? "active" : "")}
          key={tabName+Math.floor(Math.random() * 1000)}
          style={{
            border: 0,
            color: this.activeLink(tabName) ? "#f8991d" : null,
          }}
        >
          {t("SUBJECT_TAB_" + tabName.toUpperCase().replace("/", "_"))}
        </Link>
      );
    });

    const subjectNavigation = (() => {
      if (isNew) {
        return null;
      }

      const subjectListIds = this.state.subjectIds;
      const subjectIndexInList = subjectListIds.indexOf(
          parseFloat(this.props.match.params.subjectId)
      );

      const subjectListFilterParams = LocalStorageHelper.getJsonObject("subjectListFilterParams");
      const goToNextSubject = async () => {
        this.setState({loading :true})
        const subjectFilterParams = LocalStorageHelper.getJsonObject("subjectListFilterParams");
        if (subjectIndexInList === subjectListIds.length - 1 ) { //check to see if we are at the end of the batch of subject ids
          const subjectsData = await this.getSubjectIdsPage(subjectFilterParams,+1)
          const { allSubjectIds } = subjectsData;
          subjectFilterParams.pageNum = subjectFilterParams.pageNum + 1;
          LocalStorageHelper.setJson("subjectListFilterParams", subjectFilterParams);
          navigateToNextSubject(allSubjectIds[0]) // navigate to the first id from the page of data

        } else {
          const nextSubjectId =
              subjectIndexInList !== -1
                  ? subjectListIds[subjectIndexInList + 1]
                  : null;
          if (nextSubjectId !== null){
            navigateToNextSubject(nextSubjectId)
          }
        }
      };

      const goToPreviousSubject = async () => {
        this.setSubjectViewModuleLoadingState(true)
        if (subjectIndexInList === 0) { //check to see if we are at the start of the page(batch) of subject ids
          //Go and get the previous page of data
          const subjectFilterParams = LocalStorageHelper.getJsonObject("subjectListFilterParams");
          const subjectsData = await this.getSubjectIdsPage(subjectFilterParams,-1)
          const { allSubjectIds} = subjectsData;
          subjectListFilterParams.pageNum--;
          LocalStorageHelper.setJson("subjectListFilterParams", subjectListFilterParams);
          navigateToNextSubject(allSubjectIds[allSubjectIds.length - 1]) //navigate to the last id in the page of data

        } else {
          const previousSubjectId =
              subjectIndexInList !== -1
                  ? subjectListIds[subjectIndexInList - 1]
                  : null;
          if (previousSubjectId !== null) {
            navigateToNextSubject(previousSubjectId)
          }
        }
      }

      const navigateToNextSubject = (subjectId) => {
        this.props.history.push(
            window.location.pathname.replace(
                this.props.match.params.subjectId,
                subjectId
            )
        );
      }

      return (
        <div
          style={{
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Button
            primary={!((subjectListFilterParams.pageNum * paginationLimit + subjectIndexInList) === 0) && navigationEnabled}
            icon
            labelPosition="left"
            onClick={() => goToPreviousSubject()}
            disabled={((subjectListFilterParams.pageNum * paginationLimit + subjectIndexInList) === 0) ||
                this.state.subjectViewLoading || this.state.loading || !navigationEnabled
            }
          >
            {t("PREVIOUS_SUBJECT")}
            <Icon name="arrow left" />
          </Button>
          {this.state.hasManageSiteExport &&  (<Button
              primary
              onClick={this.openExportModal}
          >
            {t("SUBJECT_VIEW_EXPORT_BUTTON", "Export Data")}
          </Button>)}
          <Button
            primary={!((subjectListFilterParams.pageNum * paginationLimit + subjectIndexInList) >= subjectListFilterParams.totalSubjectCount - 1) && navigationEnabled }
            icon
            labelPosition="right"
            onClick={goToNextSubject}
            disabled={((subjectListFilterParams.pageNum * paginationLimit + subjectIndexInList) >= subjectListFilterParams.totalSubjectCount - 1) ||
                this.state.subjectViewLoading || this.state.loading || !navigationEnabled}
          >
            {t("NEXT_SUBJECT")}
            <Icon name="arrow right" />
          </Button>
        </div>
      );
    })();

    return (
      <Page
        name="SUBJECT_VIEW"
        header={
          t("SUBJECT_VIEW_HEADER") + " " + this.props.subjectData.subjectCode
        }
        loading={this.state.loading}
      >
        {subjectNavigation}
        <div
          className="ui attached tabular menu"
          style={{
            overflowX: "auto",
            overflowY: "hidden",
            border: 0,
            paddingBottom: "24px",
            paddingLeft: "12px",
          }}
        >
          {tabs}
        </div>
        <div
          className="ui bottom attached segment active tab"
          style={{ border: 0 }}
        >
          <Switch>
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/subject-record/:tabName"}
              render={(props) => (
                  <SubjectRecordTab
                      {...props}
                      loadingState={this.setSubjectViewModuleLoadingState}
                  />
              )}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/medication"}
              render={(props) => (
                <SubjectViewMedicationTab
                  {...props}
                  subjectData={this.props.subjectData}
                />
              )}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/medication/new"}
              component={SubjectMedicationCreatePage}
            />
            <Route
              exact
              path={
                "/app/subject/:subjectId/tabs/medication/edit/:medicationId"
              }
              component={SubjectMedicationEditPage}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/allergies"}
              render={(props) => (
                <SubjectViewAllergiesTab
                  {...props}
                  subjectData={this.props.subjectData}
                />
              )}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/allergies/new"}
              component={SubjectAllergyCreatePage}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/allergies/edit/:allergyId"}
              component={SubjectAllergyEditPage}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/devices"}
              render={(props) => (
                <SubjectViewDevicesTab
                  {...props}
                  subjectData={this.props.subjectData}
                  loadingState={this.setSubjectViewModuleLoadingState}
                />
              )}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/devices/new"}
              component={SubjectDeviceCreatePage}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/vitalsigns"}
              render={(props) => (
                    <SubjectVitalSignsTab
                    {...props}
                    loadingState={this.setSubjectViewModuleLoadingState}
                  />
                  )}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/videocalls"}
              render={(props) => (
                <SubjectViewVideoCallsTab
                  {...props}
                  subjectData={this.props.subjectData}
                />
              )}
            />
            <Route
              exact
              path={"/app/subject/:subjectId/tabs/videocalls/new"}
              component={SubjectVideoCallCreatePage}
            />
            <Route
              path={
                "/app/subject/:subjectId/tabs/graphs/:graph?/:code?/:metric?/:range?"
              }
              render={(props) => (
                  <SubjectViewGraphsTab
                      {...props}
                      loadingState={this.setSubjectViewModuleLoadingState}
                  />
              )}
            />
            <Route
              path={
                "/app/subject/:subjectId/tabs/module/:moduleCode/:definitionCode?"
              }
              render={(props) => (
                <SubjectViewModuleQuestionnaireTab
                  {...props}
                  groupMappings={this.state.groupMappings}
                  subjectData={this.props.subjectData}
                  loadingState={this.setSubjectViewModuleLoadingState}
                />
              )}
            />
            <Route
              path={
                "/app/subject/:subjectId/tabs/:questionnaireType/:definitionCode?"
              }
              render={(props) => (
                  <SubjectViewQuestionnaireTab
                      {...props}
                      loadingState={this.setSubjectViewModuleLoadingState}
                  />
              )}
            />
          </Switch>
        </div>
        <ExportModal
            show={this.state.showExportModal}
            onClose={this.handleExportModalClose}
            selectedSubjects={[this.props.match.params.subjectId]}
            groupCode={null}
            t={t}
            modalTitle={t('SUBJECT_VIEW_EXPORT_DATA_SUBJECT_MODAL_TITLE',"Exporting data for Patient: ") + this.props.subjectData.subjectCode}
            closeIcon
        />
      </Page>
    );
  }
}

export default withTranslation()(SubjectViewPage);
