import React, { Component } from "react";
import { withTranslation } from "react-i18next";

export const DIARY_TIME_INPUT_TYPE = {
  FROM_TO: "FROM_TO",
  DURATION_NUMERIC: "DURATION_NUMERIC",
  DURATION_TEXT: "DURATION_TEXT",
  TIME: "TIME",
};
class DiaryTable extends Component {
  getTimeInputType = (diaryItem) => {
    if (diaryItem) {
      if (diaryItem.time) {
        return DIARY_TIME_INPUT_TYPE.TIME;
      } else if (diaryItem.durationNumeric) {
        return DIARY_TIME_INPUT_TYPE.DURATION_NUMERIC;
      } else if (diaryItem.durationText) {
        return DIARY_TIME_INPUT_TYPE.DURATION_TEXT;
      } else if (diaryItem.from || diaryItem.to) {
        return DIARY_TIME_INPUT_TYPE.FROM_TO;
      } else {
        return DIARY_TIME_INPUT_TYPE.NONE;
      }
    }
    return DIARY_TIME_INPUT_TYPE.FROM_TO;
  };

  render() {
    let data;
    if (this.props.answer) {
      data = JSON.parse(this.props.answer);
    }
    data = data !== undefined && Array.isArray(data) ? data : [];

    const diaryItems = data.map((diaryItem) => {
      const timeInputType = this.getTimeInputType(diaryItem);
      let timeRender = null;
      switch (timeInputType) {
        case DIARY_TIME_INPUT_TYPE.FROM_TO:
          timeRender = diaryItem.from + " - " + diaryItem.to;
          break;
        case DIARY_TIME_INPUT_TYPE.TIME:
          timeRender = diaryItem.time;
          break;
        case DIARY_TIME_INPUT_TYPE.DURATION_NUMERIC:
          timeRender = diaryItem.durationNumeric;
          if (
            diaryItem.durationNumericUnitText &&
            diaryItem.durationNumericUnitText.length > 0
          ) {
            timeRender += " " + diaryItem.durationNumericUnitText;
          }
          break;
        case DIARY_TIME_INPUT_TYPE.DURATION_TEXT:
          timeRender = diaryItem.durationText;
          break;
        case DIARY_TIME_INPUT_TYPE.NONE:
          timeRender = null;
          break;
        default:
          console.error("Invalid timeInputType: ", timeInputType);
      }

      return (
        <>
          {timeRender && (
            <p>
              <u>{timeRender}</u>
            </p>
          )}
          <p>{diaryItem.detail}</p>
        </>
      );
    });

    return (
      <>
        {diaryItems && (
          <>
            <p>
              <strong>{this.props.question.label}</strong>
            </p>
            {diaryItems}
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(DiaryTable);
