import React, {useMemo, useState} from "react";
import {Button, Dropdown, Form, Input} from "semantic-ui-react";
import {QUESTION_TYPES} from "atom5-branching-questionnaire";
import SecondaryButton from "../../generic/SecondaryButton";
import {ACTION_TYPES} from "../../../hooks/useCompleteQuestionnaireDefinitionInformation";
import validate from './helpers/questionCodeValidator'
import {useTranslation} from "react-i18next";

const DEFAULT_QUESTION = {
  required: false,
  calculated: false,
  hidden: false,
}

function NewQuestionCardComponent({
  isReadOnly = false,
  dispatch,
  questionnaireDefinition,
  toggleOpen
}) {
  const {t} = useTranslation();

  const isQuestionType = ([_key, value]) => typeof value === "string";
  const questionTypeOptions = Object.entries(QUESTION_TYPES)
      .filter(isQuestionType)
      .map(([_key, qT]) => {
        return {
          key: qT,
          text: qT,
          value: qT,
        };
      });

  const [code, setCode] = useState("");
  const [type, setType] = useState(QUESTION_TYPES.PARAGRAPH);
  const isCodeValid = useMemo(()=>{
    return validate(code, questionnaireDefinition.questions)
  }, [code, questionnaireDefinition.questions])

  const handleSave = () => {
    const composedResponse = { ...DEFAULT_QUESTION, code, type, sequence:questionnaireDefinition.questions.length };
    dispatch({
      type: ACTION_TYPES.ADD_QUESTION_DEFINITION,
      payload: composedResponse
    })
    handleReset()
  }
  const handleReset = () => {
    setCode("")
    setType(QUESTION_TYPES.PARAGRAPH)
    toggleOpen && typeof toggleOpen === 'function' && toggleOpen()
  }

  return <div style={{width: "100%", padding: "1rem"}}>
    <Form>
      <Form.Field>
        <label>{t("NEW_QUESTION_DEFINITION_CODE", "Question Code")}</label>
        <Input
            value={code}
            name={"code"}
            error={!isCodeValid && code.length}
            fluid
            onChange={(e,v)=>setCode(v.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>{t("NEW_QUESTION_DEFINITION_TYOE", "Type")}</label>
        <Dropdown
            fluid
            selection
            options={questionTypeOptions}
            value={type}
            name={"type"}
            onChange={(e,v)=>setType(v.value)}
            disabled={isReadOnly}
        />
      </Form.Field>
      <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
        <Button primary={isCodeValid} onClick={handleSave} disabled={!isCodeValid}>{t("NEW_QUESTION_DEFINITION_SAVE", "Save")}</Button>
        <SecondaryButton onClick={handleReset}>{t("NEW_QUESTION_DEFINITION_CANCEL", "Cancel")}</SecondaryButton>
      </div>
    </Form>
  </div>
}

export default NewQuestionCardComponent;
