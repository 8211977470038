import RequestHelper from "../../RequestHelper";

const baseApiUrl = process.env.REACT_APP_SERVER_ADDRESS

const AdminAttachmentService = {

  getAttachmentsNotReceived: async (lastDays) => {
    const result = RequestHelper.send(
        `${baseApiUrl}/attachments/notreceived/${lastDays}`
    );
    return result;
  },

  updateAttachmentsBlurredFlag: (ids, blurred) => {
    const requestBody = { ids, blurred };
    return RequestHelper.send(
      `${baseApiUrl}/attachments/set-blurred`,
      {},
      "POST",
      null,
      requestBody,
    );
  },

  getSubmittedAttachmentsConfiguredForRandomising: () => {
    const result = RequestHelper.send(
        `${baseApiUrl}/attachments/configured-for-randomising`
    );
    return result;
  },
}

export default AdminAttachmentService;
