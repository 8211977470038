export const VALIDATION_MESSAGES = {
  ERROR_BLANK_VALUE: {
    translationKey: 'ERROR_BLANK_VALUE',
    fallbackText: 'Blank value not allowed',
  },
  ERROR_INVALID_VALUE: {
    translationKey: 'ERROR_INVALID_VALUE',
    fallbackText: 'The value is invalid',
  },
  ERROR_DOES_NOT_EXIST: {
    translationKey: 'ERROR_DOES_NOT_EXIST',
    fallbackText: 'The item does not exist',
  },
  ERROR_COPYING_FILE: {
    translationKey: 'ERROR_COPYING_FILE',
    fallbackText: 'Error copying file',
  },
  ERROR_SUBJECT_WITH_CODE_DOES_NOT_EXIST: {
    translationKey: 'ERROR_SUBJECT_WITH_CODE_DOES_NOT_EXIST',
    fallbackText: 'This subject does not exist',
  },
  ERROR_SUBJECT_WITH_CODE_ALREADY_EXISTS: {
    translationKey: 'ERROR_SUBJECT_WITH_CODE_ALREADY_EXISTS',
    fallbackText: 'A subject with this code already exists',
  },
  ERROR_INVALID_VALUE_FOR_SUBJECT_QUESTIONNAIRES: {
    translationKey: 'ERROR_INVALID_VALUE_FOR_SUBJECT_QUESTIONNAIRES',
    fallbackText: 'Invalid value for the subject questionnaire',
  },
  ERROR_INVALID_VALUE_FOR_TARGET_QUESTIONNAIRES: {
    translationKey: 'ERROR_INVALID_VALUE_FOR_TARGET_QUESTIONNAIRES',
    fallbackText: 'Invalid value for the target questionnaire',
  },
  WARNING_SUBJECT_GROUPS_IGNORED_FOR_USEEXISTING: {
    translationKey: 'WARNING_SUBJECT_GROUPS_IGNORED_FOR_USEEXISTING',
    fallbackText: 'Groups will be ignored when using existing subject',
  },
  WARNING_VALUE_IGNORED_FOR_USEEXISTING: {
    translationKey: 'WARNING_VALUE_IGNORED_FOR_USEEXISTING',
    fallbackText: 'Value will be ignored when using existing subject',
  },
  WARNING_SUBJECT_QUESTIONNAIRES_IGNORED_FOR_USEEXISTING: {
    translationKey: 'WARNING_SUBJECT_QUESTIONNAIRES_IGNORED_FOR_USEEXISTING',
    fallbackText: 'Subject questionnaires and answers will be ignored when using existing subject',
  },
  ERROR_USEEXISTINGQUESTIONNAIRE_NEEDS_EXISTINGSUBJECT: {
    translationKey: 'ERROR_USEEXISTINGQUESTIONNAIRE_NEEDS_EXISTINGSUBJECT',
    fallbackText: 'When using an existing questionnaire, you need to have an existing subject.',
  },
  ERROR_QUESTIONNAIRE_DOESNOT_EXIST_FOR_DEFINITION_AND_SUBJECT: {
    translationKey: 'ERROR_QUESTIONNAIRE_DOESNOT_EXIST_FOR_DEFINITION_AND_SUBJECT',
    fallbackText: 'Questionnaire does not exist for this questionnaire definition and subject combination',
  },
  ERROR_INVALID_QUESTIONNAIRE_DEFINITION_CODE: {
    translationKey: 'ERROR_INVALID_QUESTIONNAIRE_DEFINITION_CODE',
    fallbackText: 'Invalid Questionnaire Definition Code',
  },
  ERROR_INVALID_QUESTION_DEFINITION_CODE: {
    translationKey: 'ERROR_INVALID_QUESTION_DEFINITION_CODE',
    fallbackText: 'Invalid Question Definition Code',
  },
  ERROR_DUPLICATE_QUESTION_DEFINITION_CODE_IN_QUESTIONNAIRE: {
    translationKey: 'ERROR_DUPLICATE_QUESTION_DEFINITION_CODE_IN_QUESTIONNAIRE',
    fallbackText: 'Duplicate question definition code in questionnaire',
  },
  ERROR_EMPTY_FILE: {
    translationKey: 'ERROR_EMPTY_FILE',
    fallbackText: 'Empty file',
  },
  ERROR_INVALID_FILE_HEADERS: {
    translationKey: 'ERROR_INVALID_FILE_HEADERS',
    fallbackText: 'Invalid file headers',
  },
  ERROR_FIRST_COLUMN_NOT_FILENAME: {
    translationKey: 'ERROR_FIRST_COLUMN_NOT_FILENAME',
    fallbackText: 'First column must be filename',
  },
  ERROR_DUPLICATE_HEADERS: {
    translationKey: 'ERROR_DUPLICATE_HEADERS',
    fallbackText: 'Duplicate headers',
  },
  ERROR_NO_DATA_ROWS: {
    translationKey: 'ERROR_NO_DATA_ROWS',
    fallbackText: 'No data',
  },
  ERROR_INVALID_PERMISSIONS_TO_GROUP: {
    translationKey: 'ERROR_INVALID_PERMISSIONS_TO_GROUP',
    fallbackText: 'You do not have sufficient permissions for the specified group(s)',
  },
  ERROR_DUPLICATE_ATTACHMENT_REFERENCE: {
    translationKey: 'ERROR_DUPLICATE_QUESTION_DEFINITION_CODE_IN_QUESTIONNAIRE',
    fallbackText: 'Duplicate attachment reference',
  }
};
