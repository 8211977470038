import SubjectService from "../../SubjectService";
import {
  Button,
  Grid,
  Header,
  Icon,
  Placeholder,
  Segment,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import RSVP from "rsvp";
import ReadOnlyQuestionnaire from "../../questionnaires/ReadOnlyQuestionnaire";
import Page from "../../components/page/Page";
import SubjectDataQuestionnairePage from "./SubjectDataQuestionnairePage";
import DashboardContext from "../../context/DashboardContext";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";
import { compose } from "redux";
import { connect } from "react-redux";
import { getDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import ParentQuestionnaireDisplay from "../../questionnaires/ParentQuestionnaireDisplay";
import { Link } from "react-router-dom";
import {cloneDeep} from "lodash";

class SubjectViewQuestionnairePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.initialise();
  }

  initialise = () => {
    RSVP.Promise.all([
      SubjectService.getSubjectPermission(
        this.props.match.params.subjectId
      ).then((permissions) => {
        this.setState({ permissions });
      }),
      SubjectService.getSubjectData(this.props.match.params.subjectId).then(
        (subjectData) => {
          this.setState({ subjectData });

          PermissionsService.hasPermissionForSubject(
            subjectData.groups,
            GroupPermission.CREATE_SUBJECT_QUESTIONNAIRES
          ).then((hasCreateSubjectQuestionnairePermission) => {
            this.setState({ hasCreateSubjectQuestionnairePermission });
          });
        }
      ),
      SubjectService.getSubjectQuestionnaireByCodeAndId(
        this.props.match.params.subjectId,
        this.props.match.params.definitionCode,
        this.props.match.params.questionnaireId
      ).then((questonnaire) => {
        this.questionnaireRecord = questonnaire
      }),
    ]).then(()=>{
        RSVP.Promise.all([
          SubjectService.getSubjectQuestionnaireChildren(this.props.match.params.subjectId, this.props.match.params.questionnaireId)      
            .then(response => {
                this.setState({ childQuestionnaires: response.result });
            })
        ])

    })
    .then(() => {
      const answers = {};

      const correctRecord = this.questionnaireRecord

      const definitions = this.props.allDefinitions;
   
      let definition = definitions.find(
        (definition) =>
          definition.code === this.props.match.params.definitionCode
      );
      let linkedQuestionnaireDefinition = null;
      if (definition.config && definition.config.linkedDataQuestionnaire) {
        linkedQuestionnaireDefinition = definitions
          .filter((def) => def.type === "DATA")
          .find(
            (def) => def.code === definition.config.linkedDataQuestionnaire
          );
      }

      if (definition) {
        definition.questions.forEach((question, index) => {
          let recordQuestionKey = definition.code + "_" + question.code;
          answers[question.code] = correctRecord[recordQuestionKey];

          if (
            correctRecord.customLabels &&
            correctRecord.customLabels.length !== 0
          ) {
            let customLabel = correctRecord.customLabels.find((label) => {
              return label.code === question.code;
            });

            if (customLabel) {
              this.setState(() => {
                const newDefinition = cloneDeep(definition);
                newDefinition.questions[index].label = customLabel.label;
                return { definition: newDefinition };
              });
            }
          }
        });
      }

      const config = {
        definition,
        answers: answers,
        questionnaire: correctRecord,
      };
      this.setState({
        config,
        definitions,
        linkedQuestionnaireDefinition,
        loading: false,
      });
    });

    return {
      config: {
        definition: {
          config: {},
        },
      },
      subjectData: {},
      permissions: [],
      error: false,
      loading: true,
      shouldUseAltDisplay: false,
      showLinkedQuestionnaire: false,
      linkedQuestionnaireDefinition: null,
      childQuestionnaires: [],
      definitions: [],
    };
  };

  componentDidUpdate(prevProps) {
    const hasQuestionnaireChanged =
      prevProps.match.params.questionnaireId !==
      this.props.match.params.questionnaireId;
    const hasSubjectChanged =
      prevProps.match.params.subjectId !== this.props.match.params.subjectId;
    if (hasQuestionnaireChanged || hasSubjectChanged) {
      this.initialise();
    }
  }

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  showLinkedQuestionnaire = () => {
    this.setState({ showLinkedQuestionnaire: true });
  };

  setShouldUseAltDisplay = () => {
    this.setState((oldState) => {
      return {
        ...oldState,
        shouldUseAltDisplay: !oldState.shouldUseAltDisplay,
      };
    });
  };

  handlePageChange = () => {
    this.context.scrollToTop();
  };

  onBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { t } = this.props;
    let linkedDataQuestionnaire = null;
    if (this.state.hasCreateSubjectQuestionnairePermission) {
      if (
        this.state.config &&
        this.state.config.definition &&
        this.state.config.definition.config
      ) {
        linkedDataQuestionnaire =
          this.state.config.definition.config.linkedDataQuestionnaire;
      }
    }

    const linkedQuestionnaireParams = {
      params: {
        definitionCode: linkedDataQuestionnaire,
        subjectId: this.props.match.params.subjectId,
      },
    };

    const buttons = (
      <>
        <Button onClick={this.onBack}>{t("GLOBAL_BACK")}</Button>
        {linkedDataQuestionnaire &&
          this.state.linkedQuestionnaireDefinition &&
          !this.state.showLinkedQuestionnaire && (
            <Button
              primary
              icon
              labelPosition="left"
              onClick={this.showLinkedQuestionnaire}
            >
              {this.state.linkedQuestionnaireDefinition.label}{" "}
              <Icon name="plus" />
            </Button>
          )}

        {this.state.childQuestionnaires.map((childQuestionnaire) => {
          let definition = this.state.definitions.find(
            (d) => childQuestionnaire.definitionId === d.id
          );
          if (!definition) return null;
          let type = definition.type.toLowerCase();
          let viewMode =
            childQuestionnaire.completionDate !== null ? "view" : "submit";
          return (
            <Button
              primary
              as={Link}
              to={`/app/subject/${this.props.match.params.subjectId}/questionnaire-type/${type}/${definition.code}/${viewMode}/${childQuestionnaire.id}`}
            >
              {definition.label}
            </Button>
          );
        })}
      </>
    );

    return (
      <Page
        name="SUBJECT_VIEW_QUESTIONNAIRE"
        header={() => (
          <Header as="h3">
            <Button
              color="orange"
              style={{ padding: "0.25rem 1rem " }}
              onClick={() => this.props.history.goBack()}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon name="arrow left" />
                <h3 style={{ margin: "0rem", marginLeft: "0.5rem" }}>
                  {this.state.subjectData.subjectCode}
                </h3>
              </div>
            </Button>
          </Header>
        )}
        loading={this.state.loading}
      >
        {this.state.loading && (
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        )}

        {!this.state.loading && (
          <Grid columns="equal">
            <Grid.Column>
              <Segment>
                <div
                  style={{
                    display: "flex",
                    flexDirection: this.state.shouldUseAltDisplay
                      ? "column-reverse"
                      : "row",
                  }}
                >
                  <div style={{ flexGrow: 1, paddingRight: "2rem" }}>
                    <ReadOnlyQuestionnaire
                      key={this.state.config.questionnaire.id}
                      definition={this.state.config.definition}
                      questionnaire={{
                        ...this.state.config.questionnaire,
                        answers: this.state.config.answers,
                      }}
                      subjectId={this.props.match.params.subjectId}
                      subjectCode={this.state.subjectData.subjectCode}
                      permissions={this.state.permissions}
                      onPageChange={this.handlePageChange}
                      isStaff={true}
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "2rem",
                    }}
                  >
                    <ParentQuestionnaireDisplay
                      questionnaire={this.state.config.questionnaire}
                      subjectId={this.props.match.params.subjectId}
                      allDefinitions={this.props.allDefinitions}
                      shouldUseAltDisplay={this.state.shouldUseAltDisplay}
                      setShouldUseAltDisplay={this.setShouldUseAltDisplay}
                      shouldShow={true}
                    />
                  </div>
                </div>
              </Segment>
              <Segment>{buttons}</Segment>
            </Grid.Column>

            {linkedDataQuestionnaire && this.state.showLinkedQuestionnaire && (
              <Grid.Column>
                <SubjectDataQuestionnairePage
                  match={linkedQuestionnaireParams}
                  history={this.props.history}
                />
              </Grid.Column>
            )}
          </Grid>
        )}
      </Page>
    );
  }
}

SubjectViewQuestionnairePage.contextType = DashboardContext;

const mapStateToProps = (store) => {
  return { allDefinitions: getDefinitions(store) };
};

const enhance = compose(connect(mapStateToProps), withTranslation());

export default enhance(SubjectViewQuestionnairePage);
