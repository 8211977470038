import firebase from "firebase/app";
import "firebase/analytics";

/**
 * Initialise firebase configuration, only needs to be called once on initialisation of the app.
 * Configuration is pulled from the config section inside the file electron-clinician-web.json
 * And will look something like:
 * "firebaseAnalytics": {
 *     "isEnabled" : true,
 *     "firebaseConfig" : {
 *       "apiKey": "AZZZZZZZZZZ2XXzMEMOe-C0cyybSc",
 *       "authDomain": "aparito-analytics-test.firebaseapp.com",
 *       "projectId": "aparito-analytics-test",
 *       "storageBucket": "aparito-analytics-test.appspot.com",
 *       "messagingSenderId": "21405234242420",
 *       "appId": "1:214058690500:web:c0482599554c77a35b2691",
 *       "measurementId": "G-ZZZZZZZZ"
 *     }
 *   }
 *  The firebaseConfig section is generated when creating a web project inside a Firebaseproject
 */
const initFirebase = () => {
    if (Window.configuration.firebaseAnalytics?.isEnabled){
        const firebaseConfig = Window.configuration.firebaseAnalytics.firebaseConfig
        firebase.initializeApp(firebaseConfig);
    }
}

/**
 * Logs an event to firebase analytics see https://developers.google.com/tag-platform/gtagjs/reference/events
 * for supported predefined events.
 * @param  {...any} event 
 *  e.g. logevent('event_name_here',{
 *  eventParam1:value,
 *  anotherParam:value2
 * })
 * Event name is the name of an event defined in the URL above, each event may have parameters passed to it to add
 * further information to the event.
 * The event name could be a custom event, a name which you make up, you may also pass extra parameters to that custom 
 * event just as in the example above, these can be any name and value.
 */
const logevent = (...event) => {
    if (Window.configuration.firebaseAnalytics?.isEnabled){
        const firebaseAnalytics = firebase.analytics();
        if (event.length === 1){
            firebaseAnalytics.logEvent(event[0]);
        }
        if (event.length === 2){
            firebaseAnalytics.logEvent(event[0],event[1]);
        }
        if (event.length === 0 || event.length > 2 ){
            throw Error ("Error: invalid number of parameters passed to FirebaseAnalytics:logEvent()");
        }
    }
}

/**
 * Enables or disables Firebase analytical tracking. For this to take effect, first, firebase analytic tracking needs to be enabled in the 
 * study config. Analytics is enabled by default at the firebase level if configured in the study, so no need to explicitly call 
 * enableDisableAnalytics(true) to enable Analytics tracking
 * @param {*} isEnabled 
 *  
 */
const enableDisableAnalytics = (isEnabled) => {
    if (Window.configuration.firebaseAnalytics?.isEnabled){ //check atom5 configuration analytics isEnabled level first
        const firebaseAnalytics = firebase.analytics();
        firebaseAnalytics.setAnalyticsCollectionEnabled(isEnabled);
    }
}

/**
 * Associates userId with the analytical session being tracked.
 * @param {*} userId
 *  The user Id of the user.
 */
const setUserId = (userId) => {
    if (Window.configuration.firebaseAnalytics?.isEnabled){
        const firebaseAnalytics = firebase.analytics();
        firebaseAnalytics.setUserId(userId);

    }
}

export {initFirebase,logevent,enableDisableAnalytics,setUserId};