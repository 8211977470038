import RequestHelper from "../RequestHelper";

export default class SignatureService {
  static CREATE_SIGNATURE = `
        mutation query($password: String!, $reason: String!) {
            createSignatureToken(password: $password, reason: $reason)
        }
    `;

  static createSignatureToken(password, reason) {
    const variables = { password, reason };
    return RequestHelper.graphQl(
      SignatureService.CREATE_SIGNATURE,
      variables
    ).then((jsonResponse) => {
      return jsonResponse.createSignatureToken;
    });
  }
}
