import React, {useMemo} from 'react';
import { Form } from 'semantic-ui-react';
import withShowHide from './hocs/withShowHide';
import ParagraphTooltip from './ParagraphTooltip';
import withContainer from "./hocs/withContainer";
import useSubjectContext from "../../hooks/useSubjectContext";

const Paragraph = ({ question, value, subjectId }) => {
  const isMarkup = question?.config?.useMarkup;

  const renderAs =
    question?.config?.renderAs !== undefined
      ? question?.config?.renderAs
      : "label";

  let questionLabelOverride = question.label;
  if (renderAs === "value") {
    const isString = value != null && typeof(value) === 'string';
    if (isString && value.indexOf("|") >= 0) {
      questionLabelOverride = value.split("|");
    } else {
      questionLabelOverride = value;
    }
  }

  const {subjectContext, helpers} = useSubjectContext(subjectId, question.label);
  const displayLabel = useMemo(() => {
    if(Array.isArray(questionLabelOverride)) return questionLabelOverride
    return helpers.textSubstitution(questionLabelOverride, subjectContext)
  }, [helpers, questionLabelOverride, subjectContext])

  return (
    <Form.Field>
      {
        Array.isArray(displayLabel) ? (
          displayLabel.map(label => <ParagraphTooltip content={label} isMarkup={isMarkup} />)
        ) : (
          <ParagraphTooltip content={displayLabel} isMarkup={isMarkup} />
        )
      }
    </Form.Field>
  );
};

export default withContainer(withShowHide(Paragraph));