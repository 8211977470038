import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Radio,
  Message,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import SubjectService from "../../SubjectService";
import BrandingService from "../../services/BrandingService";
import { compose } from "redux";
import ConfigContext from "../../context/ConfigContext";
import { connect } from "react-redux";
import { getEventDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import SUBJECT_AUTHENTICATION_STRATEGY from "../../constants/SUBJECT_AUTHENTICATION_STRATEGY";
import TrialService from "../../TrialService";
import InternationalisationService from "../../InternationalisationService";
import ConfigService from "../../services/ConfigService";
import { logevent } from "../../services/FirebaseAnalytics";

const LoginModal = (props) => {
  const { t, handleForgottenPassword, eventDefinitions } = props;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [accountType, setAccountType] = useState(AuthService.getAccountType());
  const [requestPending, setRequestPending] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const config = useContext(ConfigContext);
  const [isTestEnvironment, setIsTestEnvironment] = useState(false);

  let brandImageUrl;
  if (config?.ui?.onboardingBrandImage) {
    brandImageUrl = BrandingService.getBrandImageForOnBoarding();
  }

  const [subjectAuthenticationStrategy, setSubjectAuthenticationStrategy] =
    useState(TrialService.getDefaultSubjectAuthenticationStrategy());
  const [validateUsernameAsEmailAddress, setValidateUsernameAsEmailAddress] =
    useState(true);

  const fetchTrialAuthenticationSettings = async () => {
    setSubjectAuthenticationStrategy(
      await TrialService.getSubjectAuthenticationStrategy()
    );
    setValidateUsernameAsEmailAddress(
      await AuthService.validateUsernameAsEmailAddress()
    );
  };

  const fetchTrialSettings = async () => {
    const trial = await TrialService.getCurrentTrial();
    setIsTestEnvironment(trial.isTest);
  };

  useEffect(() => {
    fetchTrialSettings();
    fetchTrialAuthenticationSettings();
  }, []);

  useEffect(() => {
    fetchTrialAuthenticationSettings();
    AuthService.setAccountType(accountType);
  }, [accountType]);

  const validateForm = () => {
    const isValidUsername =
      (!validateUsernameAsEmailAddress && username.length > 0) ||
      (validateUsernameAsEmailAddress &&
        AuthService.EMAIL_REGEX.test(username));
    return isValidUsername && password.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorType("");
    setErrorMessage("");
    setRequestPending(true);

    try {
      await AuthService.login(username, password, accountType);
      setRequestPending(false);

      let showScreeningQuestionnaires = false;

      if (AuthService.getAccountType() === "subject") {
        await SubjectService.autoSelectSubjectLanguage();

        let hasScreeningQuestionnaires = false;

        if (eventDefinitions) {
          const screeningQuestionnaires =
            SubjectService.getScreeningQuestionnaires(
              eventDefinitions,
              Window.configuration.ui.selfOnboarding
            );
          hasScreeningQuestionnaires =
            screeningQuestionnaires && screeningQuestionnaires.length > 0;
        }

        if (hasScreeningQuestionnaires) {
          showScreeningQuestionnaires =
            await SubjectService.doesSubjectNeedToCompleteScreeningQuestionnaires();
        }
      }
      logevent('login',{method:'web_' + subjectAuthenticationStrategy});
      AuthService.redirectAfterLogin(showScreeningQuestionnaires);
    } catch (error) {
      setPassword("");
      setRequestPending(false);
      setErrorType(error);
      switch (error) {
        case "bad credentials":
          setErrorMessage(
            t(
              "LOGIN_FAILED_CREDENTIALS",
              "The email address or password entered was incorrect."
            )
          );
          logevent('exception',{description:'LOGIN_FAILED_CREDENTIALS'});
          break;
        case "account locked":
          setErrorMessage(
            t(
              "LOGIN_FAILED_LOCKED",
              "Your account has been locked, please reset your password to regain access to your account."
            )
          );
          logevent('exception',{description:'LOGIN_FAILED_LOCKED'});
          break;
        case "password expired":
          setErrorMessage(
            t(
              "LOGIN_FAILED_EXPIRED",
              "Your account's password has expired, please reset your password to regain access to your account."
            )
          );
          logevent('exception',{description:'LOGIN_FAILED_EXPIRED'});
          break;
        default:
          setErrorMessage(
            t("LOGIN_FAILED_UNKNOWN", "An unknown error occured")
          );
          logevent('exception',{description:'LOGIN_FAILED_UNKNOWN'});
      }
    }
  };

  const handleAccountTypeChange = (_e, radio) => {
    setAccountType(radio.value);
  };

  const shoulDisablePasswordAutoComplete =
    !isTestEnvironment && ConfigService.shoulDisablePasswordAutoComplete();

  return (
    <Form style={styles.container} onSubmit={handleSubmit}>
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <Image
              style={{ height: "25px" }}
              src={
                "./images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
              }
              alt={"Aparito Logo"}
            />
          </Grid.Column>
        </Grid.Row>
        {brandImageUrl && (
          <Grid.Row
            centered
            rows={3}
            style={{
              backgroundImage: `url(${brandImageUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
              height: "100px",
            }}
          />
        )}
        <Grid.Row centered columns={3}>
          <Grid.Column
            width={10}
            textAlign={
              InternationalisationService.isRTL() ? "right" : undefined
            }
          >
            <Header as="h3" textAlign="center">
              {t("LOGIN_HEADER_TITLE", "Log in to your account")}
            </Header>
            {errorMessage.length > 0 && (
              <Message negative>
                {errorMessage}
                {["account locked", "password expired"].includes(errorType) && (
                  <Button onClick={handleForgottenPassword} primary basic fluid>
                    {errorType === "account locked" &&
                      t("LOGIN_FAILED_LOCKED_BUTTON", "Reset Password")}
                    {errorType === "password expired" &&
                      t("LOGIN_FAILED_EXPIRED_BUTTON", "Reset Password")}
                  </Button>
                )}
              </Message>
            )}
            <Grid.Row style={styles.accountTypeGroup}>
              <Radio
                label={t("LOGIN_AS_STAFF_BUTTON", "Log in as a clinician")}
                name="accountTypeGroup"
                value="staff"
                checked={accountType === "staff"}
                onChange={handleAccountTypeChange}
              />
              <Radio
                label={t("LOGIN_AS_SUBJECT_BUTTON", "Log in as a patient")}
                name="accountTypeGroup"
                value="subject"
                checked={accountType === "subject"}
                onChange={handleAccountTypeChange}
              />
            </Grid.Row>
            <Form.Input
              fluid
              icon={
                subjectAuthenticationStrategy ===
                SUBJECT_AUTHENTICATION_STRATEGY.SUBJECTCODE_PWD
                  ? "user"
                  : "mail"
              }
              iconPosition="left"
              placeholder={
                subjectAuthenticationStrategy ===
                SUBJECT_AUTHENTICATION_STRATEGY.SUBJECTCODE_PWD
                  ? t("GLOBAL_LABEL_USERNAME")
                  : t("GLOBAL_LABEL_EMAIL_ADDRESS", "Email address")
              }
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={requestPending}
            />

            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={t("GLOBAL_LABEL_PASSWORD", "Password")}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={requestPending}
              autoComplete={shoulDisablePasswordAutoComplete ? "off" : null}
            />
            <Button
              type="submit"
              primary
              fluid
              size="large"
              disabled={!validateForm() || requestPending}
            >
              {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid centered padded>
        <Grid.Row>
          <Button
            className="borderless"
            basic
            color={"orange"}
            size="small"
            disabled={requestPending}
            onClick={handleForgottenPassword}
          >
            {t("FORGOTTEN_PASSWORD", "Forgotten Password")}
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const styles = {
  container: {
    minHeight: "420px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  accountTypeGroup: {
    display: "flex",
    justifyContent: "space-around",
    padding: "32px 0px",
  },
};

const mapStateToProps = (state) => {
  return {
    eventDefinitions: getEventDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(LoginModal);
