import React, { Component } from "react";
import {
  Dimmer,
  Grid,
  Loader,
  Message,
  Transition,
  Button
} from "semantic-ui-react";

export default class AppLoader extends Component {
  state = {
    error: false,
  };

  constructor(props, context) {
    super(props, context);

    if (props.timeout) {
      this.timeoutId = setTimeout(() => {
        this.setState({ error: true });
      }, props.timeout);
    }
  }

  componentWillUnmount = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  };

  refreshPage = () => {
    window.location.reload(true);
  };


  render() {
    return (
      <Dimmer page inverted active>
        <Grid
          textAlign="center"
          style={{
            height: "100vh"
          }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 470 }}>
            {!this.state.error && <Loader active />}

            <Transition
              visible={this.state.error}
              animation="scale"
              duration={500}
            >
              <Message error
                style={{ padding: "2rem"
              }}>
                <Message.Header
                  style={{ marginBottom: "0.8rem" }}
                >Error</Message.Header>
                  <Message.Content>
                    An unexpected issue has occurred while loading the
                    application, please wait a few minutes then try and refresh your browser. If this
                    message still appears please contact your system administrator.
                      <Button
                        style={{
                          marginTop: "2rem"              
                        }}
                        primary
                        size="small"
                        onClick={this.refreshPage}
                      >
                        Refresh
                      </Button>
                  </Message.Content>
              </Message>
            </Transition>
          </Grid.Column>
        </Grid>
      </Dimmer>
    );
  }
}
