import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Icon, Label, Search } from 'semantic-ui-react';
import ConfigContext from '../../context/ConfigContext';
import SubjectService from '../../SubjectService';

const SubjectCodeLookup = (props) => {
  const { t, onChange, value } = props;

  const config = useContext(ConfigContext);

  const isMultiple = props.multiple != null ? props.multiple : false;
  const placeholder = props.placeholder != null ? props.placeholder : t('SUBJECTCODE_LOOKUP_INPUT_PLACEHOLDER', 'Subject code (minimum 5 characters)');

  const minInputCharacters = config?.ui?.subjects?.lookup?.minInputCharacters || 5;

  const [isLoading, setIsLoading] = useState(true);

  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState();

  const [selectedValues, setSelectedValues] = useState(undefined);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedValues !== value) {
      setSelectedValues(value || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const invokeOnChange = (vals) => {
    if (onChange && vals != null) {
      onChange(vals);
    }
  };

  const init = async () => {
    setIsLoading(false);
  };

  const handleQueryChange = async (_e, data) => {
    const value = data.value || '';
    setQuery(value);
    if (value.length >= minInputCharacters) {
      debouncedPerformSearch(value);  
    }
  };

  const initSearchState = async () => {
    setIsLoading(true);
    setSearchResults();
  };

  const performSearch = async (value) => {
    await initSearchState();
    try {
      const rawResults = await SubjectService.getSubjectsByLookup(value);
      const transFormedResults = rawResults.lookupEntries;
      setSearchResults(transFormedResults);
    } catch (error) {
      console.error('[SubjectCodeLookup][performSearch] Error', error);
      setSearchResults();
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedPerformSearch = useCallback(_.debounce(performSearch, 500), []);

  const handleSelectSubject = (e, data) => {
    e.preventDefault();

    const selectedItem = data?.result;
    const nullSafeSelectedValues = (selectedValues || []);
    const doesValueExist = nullSafeSelectedValues.some(sv => sv.subjectCode === selectedItem.subjectCode);
    if (!doesValueExist) {
      if (isMultiple) {
        const updatedSelectedValues = [...nullSafeSelectedValues];
        updatedSelectedValues.push(selectedItem);
        setSelectedValues(updatedSelectedValues);
        invokeOnChange(updatedSelectedValues);
      } else {
        setSelectedValues([selectedItem]);
        invokeOnChange([selectedItem]);
      }
    }
  };

  const handleDeleteSelectedValue = (data) => {
    if (selectedValues == null) {
      return;
    }
    const indexToRemove = selectedValues.findIndex(sv => sv.subjectCode === data?.subjectCode);
    if (indexToRemove !== -1) {
      const updatedSelectedValues = [...selectedValues];
      updatedSelectedValues.splice(indexToRemove, 1);
      setSelectedValues(updatedSelectedValues);
      invokeOnChange(updatedSelectedValues);
    }
  };

  const lookupItems = searchResults?.map((searchResult) => {
    return {
      title: searchResult.subjectCode,
      subjectCode: searchResult.subjectCode,
      subjectId: searchResult.subjectId
    };
  });

  const selectedItemLabels = selectedValues?.map((searchResult) => {
    return (
      <Label
        className='ui label'
        key={searchResult.subjectCode}
      >{searchResult.subjectCode}
        <Icon name='delete' onClick={() => handleDeleteSelectedValue(searchResult)} />
      </Label>
    );
  });

  const hasSelectedValues = selectedValues?.length > 0;
  const showSearch = isMultiple || (!isMultiple && !hasSelectedValues);

  return (
    <div>
      {showSearch && (
        <Search
          placeholder={placeholder}
          input={<Form.Input />}
          loading={isLoading}
          minCharacters={minInputCharacters}
          value={query}
          onSearchChange={handleQueryChange}
          results={lookupItems}
          onResultSelect={handleSelectSubject}
          noResultsMessage={t('SUBJECTCODE_LOOKUP_NORESULTS', 'No results found')}
        />
      )}
      {hasSelectedValues && (
        <div className='ui fluid multiple selection dropdown'>
          {selectedItemLabels}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SubjectCodeLookup);
