import RequestHelper from "../../RequestHelper";

const CacheService = {
  refreshExpressionContextAnswers: async () => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/cache/refresh/expression_context_answers",
      {},
      "POST",
      null,
      null
    );
  },
  refreshQuestionnaireDefinitions: async () => {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/cache/refresh/questionnaire_definitions",
      {},
      "POST",
      null,
      null
    );
  },
};

export default CacheService;
