import React, { useState } from "react";
import { Button, Header, Image, Menu, Modal } from "semantic-ui-react";
import BrandingService from "../services/BrandingService";
import { withTranslation } from "react-i18next";
import AboutModalContent from "./AboutModalContent";
import i18next from "i18next";

const Footer = ({t}) => {
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  const brandAparitoLogoImageUrl = BrandingService.getAparitoLogoFooter();

  // Only show the about link if the translations exist.
  // Assuming that if the main header is configured, the rest of them will be too.
  const shouldShowAbout = i18next.exists('ABOUT_MANUFACTURER');

  return (
    <>
      <Menu style={styles.footer}>
        {shouldShowAbout && (
          <>
            <Menu.Item>
              <Header>{t('FOOTER_LINKS')}</Header>
            </Menu.Item>
            <Menu.Item>
              <Button onClick={() => setIsAboutModalOpen(true)} style={styles.linkButton}>{t('FOOTER_ABOUT')}</Button>
            </Menu.Item>
          </>
        )}
        <Menu.Item style={styles.poweredByItem} position="right">
          <a href="https://aparito.com" target="_blank" rel="noopener noreferrer" style={{color: '#444444'}}>
            Powered by
            <Image src={brandAparitoLogoImageUrl} style={styles.image} alt="Aparito" />
          </a>
        </Menu.Item>
      </Menu>

      <Modal
        size={"small"}
        open={isAboutModalOpen}
        onClose={() => setIsAboutModalOpen(false)}
      >
        <AboutModalContent />
      </Modal>
    </>
  );
};

const styles = {
  footer: {
    minHeight: "10em",
    maxHeight: "12em",
    padding: "2em",
    borderRadius: 0,
    border: "none",
  },
  aboutContent: {
    borderTop: "1px solid #f2f1f1",
    borderBottom: "1px solid #f2f1f1",
  },
  linkButton: {
    backgroundColor: '#f8991d',
    color: '#ffffff',
  },
  poweredByItem: {
    width: "25%",
  },
  image: {
    width: 300,
  },
};

export default withTranslation()(Footer);
