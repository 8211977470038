import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/page/Page";
import { CardGroup } from "semantic-ui-react";
import StaffSummaryCard from "./StaffSummaryCard";
import AuthService from "../../services/AuthService";
import { Redirect } from "react-router-dom";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";
import RadarComparisonCard from "../../components/dashboard/cards/RadarComparisonCard";
import ConfigContext from "../../context/ConfigContext";
import { withTranslation } from "react-i18next";
import SinceLastLoginCard from "../../components/dashboard/cards/SinceLastLoginCard";
import ComplianceCardGroup from "../../components/dashboard/cards/ComplianceCardGroup";
import SubjectSummaryCard from "../../components/dashboard/cards/SubjectSummaryCard";
import BoundedComplianceCard from "../../components/dashboard/cards/BoundedComplianceCard";
import CardDisplay from "./CardDisplay";

const HomePage = ({ t }) => {
  const [accountType, setAccountType] = useState("");
  const [hasStaffCreatePermission, setHasStaffCreatePermission] =
    useState(false);

  const config = useContext(ConfigContext);

  useEffect(() => {
    const init = async () => {
      setAccountType(AuthService.getAccountType());
      if (AuthService.getAccountType() === "staff") {
        setHasStaffCreatePermission(
          await PermissionsService.hasPermissionInAnyGroup(
            GroupPermission.CREATE_STAFF
          )
        );
      }
    };
    init();
  }, []);

  const isStaff = accountType === "staff";
  const showSubjectsCard = config?.ui?.dashboard?.cards?.subjects;
  const showStaffCard =
    config?.ui?.dashboard?.cards?.staff && hasStaffCreatePermission;
  const showCountGroup = showSubjectsCard || showStaffCard;
  const showComplianceCard = config?.ui?.dashboard?.cards?.compliance;
  const showBoundedComplianceCard =
    config?.ui?.dashboard?.cards?.boundedCompliance;
  const showSinceLastLogin = config?.ui?.dashboard?.cards?.sinceLastLogin;
  const showRadarComparison = config?.ui?.dashboard?.cards?.radarComparison;

  return (
    <Page name="HOME">
      {isStaff && (
        <>
          {showCountGroup && (
            <CardGroup>
              {showSubjectsCard && <SubjectSummaryCard />}
              {showStaffCard && <StaffSummaryCard />}
            </CardGroup>
          )}

          {showComplianceCard && <ComplianceCardGroup />}
          {showBoundedComplianceCard && <BoundedComplianceCard />}
          {showSinceLastLogin && (
            <CardGroup itemsPerRow={3}>
              <SinceLastLoginCard />
            </CardGroup>
          )}
          {showRadarComparison && <RadarComparisonCard />}
        </>
      )}
      {<CardDisplay cardArray={config?.ui?.dashboard?.questionnaireAggregateCards} />}
      {accountType === "subject" && <Redirect to="/" />}
    </Page>
  );
};

export default withTranslation()(HomePage);
