import React, {useState, useEffect} from "react";
import {withTranslation} from "react-i18next";
import SensorDataService from "../../../services/sensordata/SensorDataService";
import {Table} from "semantic-ui-react";
import moment from "moment";
import {addPeriod} from "../../../services/helpers/graphHelper";
const ActivityDistanceTable = ({subjectId, t}) => {
    const [loading, setLoading] = useState(false)
    const [headings, setHeadings] = useState([]);
    const [data, setData] = useState([]);

    const [error, setError] = useState(false);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    const fetchData = async () => {
        setLoading(true);

        try {
            setLoading(true);
            const fromDate = moment().clone().add(-1, 'week').startOf('day');
            const activities = await SensorDataService.getActivityDistanceData(
                subjectId,
                fromDate.format('YYYY-MM-DD'),
                addPeriod(fromDate, 'week').format('YYYY-MM-DD')
            );

            let headings = [];
            let activitiesData = [];
            for (let i in activities) {
                headings.push(i);
                activitiesData.push(activities[i]);
            }

            setHeadings(headings);
            setData(activitiesData);
            setLoading(false);
        } catch (e) {
            console.error('Error loading Activity data', e);
            setError(true)
            setLoading(false)
        }
    }

    const generateTable = () => {
        return headings.reverse().map((tableHeading, idx) => {
            let reverseIdx = (headings.length - 1) - idx;
            return (<>
                <h4>{tableHeading}</h4>
                <Table selectable>
                    {tableHeader()}
                    {data[reverseIdx].map((d) => createRow(d))}
                </Table>
            </>)
        });
    };

    const tableHeader = () => {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{minHeight: "40px"}}>
                        {"Distance"}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{minHeight: "40px"}}>
                        {"Start Time"}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{minHeight: "40px"}}>
                        {"Av Speed"}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{minHeight: "40px"}}>
                        {"Type"}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{minHeight: "40px"}}>
                        {"Av Heartrate"}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{minHeight: "40px"}}>
                        {"Calories"}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{minHeight: "40px"}}>
                        {"Start Coordinates"}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    };

    const createRow = (line) => {
        return (
            <Table.Body>
                <Table.Row key={line.subjectId}>
                    <Table.Cell> {line.distance.toFixed(2)}m</Table.Cell>
                    <Table.Cell> {line.startTime.replace("T", " ")}</Table.Cell>
                    <Table.Cell> {line.averageSpeed.toFixed(2)}</Table.Cell>
                    <Table.Cell> {line.sportType}</Table.Cell>
                    <Table.Cell> {line.averageHeartrate}</Table.Cell>
                    <Table.Cell> {line.calories}</Table.Cell>
                    <Table.Cell> {
                        line.startLatitude.toFixed(7)},{' '}{line.startLongitude.toFixed(7)}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        )
    };

    if (loading) {
        return (<>
            Loading...
        </>)
    }

    if (error) {
        return (<>
            {error &&
                <p>{t("SENSOR_DATA_ERROR", "Error while fetching data, if the problem persists contact support")}</p>}
        </>)
    }

    if (!loading) {
        const table = generateTable();
        return (
            <>
                <h3>Garmin Activity with GPS</h3>
                {table}
            </>);
    }
}

export default withTranslation()(ActivityDistanceTable);
