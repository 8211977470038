import React, { useContext, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Card, Dropdown, Grid, Label } from "semantic-ui-react";
import ConfigContext from "../../../context/ConfigContext";
import GroupsContext from "../../../context/GroupsContext";
import UserContext from "../../../context/UserContext";
import GroupPermission from "../../../GroupPermission";
import GenericRadarChart from "../../../pages/subjects/graphs/chartcomponents/generic/GenericRadarChart";
import { getDefinitions } from "../../../redux/questionnaires/questionnaireDefinitionsSlice";
import ChartService from "../../../services/ChartService";
import DashboardService from "../../../services/DashboardService";
import GroupService from "../../../services/GroupService";
import GenericSelectionTable from "../../tables/GenericSelectionTable";

function RadarComparisonCard({ allDefinitions, t }) {
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const groups = useContext(GroupsContext);

  const [comparisonData, setComparisonData] = useState(null);
  const [comparedDefinition, setComparedDefinition] = useState(null);
  const [filteredComparisonData, setFilteredComparisonData] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupDropdownData, setGroupDropdownData] = useState([]);
  const [selectedDefinition, setSelectedDefinition] = useState(
    config?.ui?.dashboard?.cards?.radarComparison?.definitions?.[0]
  );
  const [definitionDropdownData, setDefinitionDropdownData] = useState([]);
  const [selection, setSelection] = useState([]);

  const getChartComparisonData = async () => {
    let definition = allDefinitions.find(
      (def) => def.code === selectedDefinition
    );
    setComparedDefinition(definition);

    let response =
      await DashboardService.getLatestQuestionnairesOfTypeForDefinition(
        selectedDefinition,
        selectedGroup
      );

    if (definition) {
      let comparisonData =
        ChartService.generateQuestionCategoryQuestionnaireComparisonData(
          definition,
          response
        );

      setComparisonData(comparisonData);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        let viewableGroups = GroupService.getGroupsWithPermissionFromProfile(
          user.profile,
          GroupPermission.VIEW_SUBJECT
        );

        let viewableGroupsDropdownData = viewableGroups.map((vG) => {
          let group = groups.find((g) => g.code === vG.code);
          return {
            key: group.code,
            text: group.label,
            value: group.code,
          };
        });

        let selectableDefinitionsDropdownData =
          config?.ui?.dashboard?.cards?.radarComparison?.definitions?.map(
            (definitionCode) => {
              let definition = allDefinitions.find(
                (d) => d.code === definitionCode
              );
              return {
                key: definition.code,
                text: definition.label,
                value: definition.code,
              };
            }
          );

        viewableGroupsDropdownData.sort((a, b) => (a.label < b.label ? -1 : 1));

        setGroupDropdownData(viewableGroupsDropdownData);
        setDefinitionDropdownData(selectableDefinitionsDropdownData);
        setSelectedDefinition(selectableDefinitionsDropdownData?.[0].value);
      } catch (e) {
        console.error(e);
      }
    };
    init();
  }, [
    allDefinitions,
    config.ui.dashboard.cards.radarComparison.definitions,
    groups,
    user.profile,
  ]);

  useEffect(() => {
    getChartComparisonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, selectedDefinition]);

  useEffect(() => {
    if (comparisonData) {
      const filteredComparisonData = { ...comparisonData };
      filteredComparisonData.datasets = filteredComparisonData.datasets.filter(
        (dS) => selection.includes(dS.label)
      );
      setFilteredComparisonData(filteredComparisonData);
    }
  }, [comparisonData, selection]);

  const onSelectionChange = (selection) => {
    setSelection(selection);
  };

  const dataForTable = useMemo(() => {
    if (comparisonData?.datasets) {
      return comparisonData.datasets.map((dataset) => {
        return {
          color: (
            <div
              style={{
                height: 20,
                width: 20,
                backgroundColor: dataset.borderColor,
                borderRadius: "100%",
              }}
            />
          ),
          subject: dataset.label,
        };
      });
    }
    return [];
  }, [comparisonData]);

  if (!filteredComparisonData || !comparedDefinition) {
    return null;
  }

  return (
    <Card fluid>
      <Card.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} style={{ maxWidth: "500px" }}>
              {definitionDropdownData.length > 1 && (
                <Grid.Row>
                  <Grid.Column>
                    <Dropdown
                      selection
                      fluid
                      defaultValue={definitionDropdownData?.[0]?.value}
                      options={definitionDropdownData}
                      onChange={(e, a) => {
                        a.value
                          ? setSelectedDefinition(a.value)
                          : setSelectedDefinition(null);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              <GenericRadarChart
                key={
                  selectedDefinition + filteredComparisonData?.datasets.length
                }
                data={filteredComparisonData}
              />
            </Grid.Column>
            <Grid.Column floated="right" width={8}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    {comparisonData?.labels?.map((label) => {
                      let foundQuestion = comparedDefinition.questions.find(
                        (q) => q.code === label
                      );
                      return (
                        <p>
                          <Label>{label}</Label>
                          {`  ${foundQuestion?.label}`}
                        </p>
                      );
                    })}
                  </Grid.Column>
                </Grid.Row>
                {groupDropdownData.length > 1 && (
                  <Grid.Row>
                    <Grid.Column>
                      <Dropdown
                        selection
                        clearable
                        placeholder={t("GLOBAL_ALL_GROUPS")}
                        options={groupDropdownData}
                        onChange={(e, a) => {
                          a.value
                            ? setSelectedGroup(a.value)
                            : setSelectedGroup(null);
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row>
                  <Grid.Column>
                    <GenericSelectionTable
                      data={dataForTable}
                      toIndexBy="subject"
                      columnMapping={[
                        { title: "", code: "color" },
                        { title: t("SUBJECT_VIEW_HEADER"), code: "subject" },
                      ]}
                      onSelectionChange={onSelectionChange}
                      shouldSelectAllByDefault
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
}

const mapStatetoProps = (state) => {
  return {
    allDefinitions: getDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStatetoProps));

export default enhance(RadarComparisonCard);
