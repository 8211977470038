/**
 * List of values allowed to be passed as thet type querystrinf to the questionnaire-definitions?type= end point
 * e.g. questionnaire-definitions?type=API_QUESTIONNAIRE_DEFINITION_TYPES.PRO
 */
const API_QUESTIONNAIRE_DEFINITION_TYPES = {
  CHART: 'CHART',
  CONTENT: 'CONTENT',
  EVENT: 'EVENT',
  PRO: 'PRO',
  VISIT: 'VISIT',
  MEDICATION: 'MEDICATION',
  DATA: 'DATA',
  SUBJECT_RECORD: 'SUBJECT_RECORD',
};

export default API_QUESTIONNAIRE_DEFINITION_TYPES;
