import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import BatchAttachmentService from '../../../services/BatchAttachmentService';
import { Checkbox, Segment, Table } from 'semantic-ui-react';
import DateTimeService from '../../../services/DateTimeService';
import GeneralHelpers from '../../../helpers/GeneralHelpers';
import ConfirmButton from '../../../components/dashboard/ConfirmButton';
import GroupPermission from '../../../GroupPermission';
import PermissionsService from '../../../services/PermissionsService';
import { Redirect } from 'react-router-dom';

const BatchUploadedFileList = (props) => {
  const { t } = props;
  const [permissions, setPermissions] = useState();

  const [files, setFiles] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    populatePermissions();
    populateFiles();
  }, []);

  useEffect(() => {
    if (!files) {
      return;
    }
    const allFiles = files.map((file) => file.fileName);
    if (allFiles.length > 0 && selectedItems.length === allFiles.length) {
      setIsSelectAllChecked(true);
    } else {
      setIsSelectAllChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const onCheckChanged = (e, target, fileName) => {
    if (target.checked) {
      const newSelectedItems = [...selectedItems];
      if (!newSelectedItems.some((i) => fileName === i)) {
        newSelectedItems.push(fileName);
      }
      setSelectedItems(newSelectedItems);
    } else {
      const newSelectedItems = [...selectedItems].filter((i) => {
        return fileName !== i;
      });
      setSelectedItems(newSelectedItems);
    }
  };

  const onCheckAllChanged = (e, target) => {
    setIsSelectAllChecked(target.checked);
    if (target.checked) {
      const allFiles = files.map((file) => file.fileName);
      setSelectedItems(allFiles);
    } else {
      setSelectedItems([]);
    }
  };

  const populatePermissions = async () => {
    const requiredPermissionKeys = [
      GroupPermission.BATCH_ATTACHMENTS_VIEW,
      GroupPermission.BATCH_ATTACHMENTS_DELETE_FILES
    ];

    const result = {};
    for (const permissionKey of requiredPermissionKeys) {
      const hasPermission = await PermissionsService.hasPermissionInAnyGroup(permissionKey);
      result[permissionKey] = hasPermission;
    }

    setPermissions(result);
  };

  if (permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_VIEW] !== true) {
    return <Redirect to='/' />;
  }

  const populateFiles = async () => {
    setSelectedItems([]);
    setIsSelectAllChecked(false);
    const response = await BatchAttachmentService.getUploadedFiles();
    setFiles(response?.files || []);
  };

  const handleFileDelete = async (file) => {
    await BatchAttachmentService.deleteUploadedFile(file.fileName);
    populateFiles();
  };

  const handleMultipleFileDelete = async () => {
    await BatchAttachmentService.deleteUploadedFiles(selectedItems);
    populateFiles();
  };

  const buildDataRow = (file, index) => {
    const fileSizeMB = file.size == null ? 0 : GeneralHelpers.round(file.size / 1024 / 1024, 2);
    const fileSizeText = `${fileSizeMB} ${t('BATCHUPLOADEDFILELIST_FILESIZE_UNITS', 'MB')}`;
    return (
      <Table.Row key={index}>
        <Table.Cell width={1} key={index + '_checkbox'}>
          <Checkbox
            onChange={(e, target) => onCheckChanged(e, target, file.fileName)}
            checked={selectedItems.some((i) => i === file.fileName)}
          />
        </Table.Cell>
        <Table.Cell width={6} key={index + '_fileName'}>
          {file.fileName}
        </Table.Cell>
        <Table.Cell width={1} key={index + '_size'}>
          {fileSizeText}
        </Table.Cell>
        <Table.Cell width={2} key={index + '_datetime'}>
          {DateTimeService.build.asDisplayDateTime(
            file.datetime
          )}
        </Table.Cell>
        <Table.Cell width={1} key={index + '_type'}>
          {file.mimeType}
        </Table.Cell>
        <Table.Cell width={2} key={index + '_controls'} textAlign={'right'}>
          {permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_DELETE_FILES] === true && (
            <ConfirmButton
              buttonText={t('BATCHUPLOADEDFILELIST_TABLE_ROW_DELETE_BUTTON', 'Delete')}
              headerText={t('BATCHUPLOADEDFILELIST_TABLE_ROW_DELETE_BUTTON', 'Delete')}
              contentText={t(
                'BATCHUPLOADEDFILELIST_TABLE_ROW_DELETE_PROMPT_TEXT',
                'Please confirm that you want to delete this file? This action is not reversible, and the file will be permanently deleted from the server. Please ensure you have a backup prior to doing this.'
              )}
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
              onConfirm={() => handleFileDelete(file)}
              color={'red'}
            />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  const countText = `${t('BATCHUPLOADEDFILELIST_COUNT', 'Count')} ${files.length}`;

  return (
    <>
      <Segment>
        {countText}
      </Segment>

      <Table id={'batchUploadedFileListTable'} selectable columns={12} compact basic size='small'>
        <Table.Header>
          <Table.Row key={'header'}>
            <Table.HeaderCell width={1} key={'header_checkbox'}>
              <Checkbox
                onChange={(e, target) => onCheckAllChanged(e, target)}
                checked={isSelectAllChecked}
              />
            </Table.HeaderCell>
            <Table.HeaderCell width={6} key={'header_fileName'}>
              {t('BATCHUPLOADEDFILELIST_TABLE_HEADER_FILENAME', 'Filename')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1} key={'header_size'}>
              {t('BATCHUPLOADEDFILELIST_TABLE_HEADER_SIZE', 'Size')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} key={'header_datetime'}>
              {t('BATCHUPLOADEDFILELIST_TABLE_HEADER_DATETIME', 'Date & TIme')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1} key={'header_type'}>
              {t('BATCHUPLOADEDFILELIST_TABLE_HEADER_TYPE', 'Type')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} key={'header_controls'}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {files &&
            files.map((file, index) =>
              buildDataRow(file, index)
            )}
        </Table.Body>
      </Table>
      {permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_DELETE_FILES] === true && selectedItems.length > 0 && (
        <ConfirmButton
          buttonText={t('BATCHUPLOADEDFILELIST_TABLE_ROW_DELETESELECTED_BUTTON', 'Delete all selected')}
          headerText={t('BATCHUPLOADEDFILELIST_TABLE_ROW_DELETESELECTED_BUTTON', 'Delete all selected')}
          contentText={t(
            'BATCHUPLOADEDFILELIST_TABLE_ROW_DELETESELECTED_PROMPT_TEXT',
            'Please confirm that you want to delete all of the selected files? This action is not reversible, and the files will be permanently deleted from the server. Please ensure you have a backup prior to doing this.'
          )}
          confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
          cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
          onConfirm={handleMultipleFileDelete}
          color={'red'}
        />
      )}
    </>
  );
};

export default withTranslation()(BatchUploadedFileList);
