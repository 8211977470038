import i18next from "i18next";
import React from "react";
import { Card } from "semantic-ui-react";

const EmptyDisplay = (props) => {
  const { type } = props;

  let typeString = type ? type : "DEFAULT";

  return (
    <Card
      fluid
      style={{ padding: "1rem", boxShadow: "0px 4px 16px rgba(0,0,0,0.1)" }}
    >
      <Card.Header>{i18next.t("LIST_EMPTY_" + typeString)}</Card.Header>
    </Card>
  );
};

export default EmptyDisplay;
