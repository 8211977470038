import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon, Message, Table, Form } from "semantic-ui-react";
import DrugService from "../../DrugService";
import { Link } from "react-router-dom";
import Page from "../../components/page/Page";
import Pagination from "semantic-ui-react/dist/commonjs/addons/Pagination";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

export class DrugListPage extends Component {
  state = {
    page: 1,
    pageSize: 10,
    beginsWith: "",
    drugs: [],
    loading: true,
  };

  constructor(props, context) {
    super(props, context);

    this.reloadDrugList();
  }

  reloadDrugList = () => {
    DrugService.listDrugs(
      this.state.page,
      this.state.pageSize,
      this.state.beginsWith
    )
      .then((drugs) => {
        this.setState({ drugs: drugs.content, totalPages: drugs.totalPages });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  searchChanged = (event) => {
    const newSearch = event.target.value;
    this.setState({ beginsWith: newSearch, page: 1 }, this.reloadDrugList);
  };

  toggleState = (drug) => {
    return DrugService.updateDrug(drug.id, drug.name, !drug.active).then(() => {
      this.reloadDrugList();
    });
  };

  renderDrugState = (drug) => {
    const { t, error } = this.props;
    let colour = drug.active ? "green" : "red";
    let stateLabel = drug.active ? "DRUG_STATE_ACTIVE" : "DRUG_STATE_INACTIVE";

    return (
      <Form
        onSubmit={(e) => this.handleSubmit(e, drug)}
        onCancel={this.onCancel}
        error={error}
      >
        <Message error header={t("DRUG_FORM_ERROR_TITLE")} content={error} />
        <Button
          type="submit"
          loading={this.state.submitting}
          size={"tiny"}
          color={colour}
        >
          {t(stateLabel)}
        </Button>
      </Form>
    );
  };

  handleSubmit = (event, drug) => {
    event.preventDefault();

    const promise = this.toggleState(drug);

    // If we have a promise, lets use that to determine when to show indication of submission
    if (promise) {
      this.setState({ submitting: true });

      promise.finally(() => {
        this.setState({ submitting: false });
      });
    }
  };

  render() {
    const { t, history } = this.props;

    return (
      <Page
        name="DRUG_LIST"
        header={t("DRUG_LIST_HEADER")}
        subheader={t("DRUG_LIST_SUBHEADER")}
        loading={this.state.loading}
      >
        <Table selectable columns={2} compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t("DRUG_LIST_TABLE_HEADER_NAME")}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign={"right"}>
                <Input
                  name={"beginsWith"}
                  onChange={this.searchChanged}
                  icon="search"
                  placeholder={t("GENERIC_TERM_SEARCH")}
                  aria-label={t("GENERIC_TERM_SEARCH")}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.drugs.map((drug) => (
              <Table.Row key={drug.id} style={{ cursor: "pointer" }}>
                <Table.Cell
                  textAlign={"left"}
                  onClick={() => {
                    history.push("/app/drugs/edit/" + drug.id);
                  }}
                >
                  {drug.name}
                </Table.Cell>
                <Table.Cell textAlign={"right"}>
                  {this.renderDrugState(drug)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={2}>
                <Pagination
                  defaultActivePage={1}
                  totalPages={this.state.totalPages}
                  activePage={this.state.page}
                  onPageChange={(event, data) => {
                    this.setState(
                      { page: data.activePage },
                      this.reloadDrugList
                    );
                  }}
                />
                <Button
                  floated="right"
                  primary
                  icon
                  as={Link}
                  labelPosition="left"
                  to={"/app/drugs/new"}
                >
                  {t(["DRUG_LIST_ADD_NEW", "GLOBAL_BUTTON_ADD_NEW"])} <Icon name="syringe md" />
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Page>
    );
  }
  n;
}

export default withTranslation()(DrugListPage);
