import RequestHelper, { CONTENT_TYPE } from "../RequestHelper";

const ScreenshotService = {
  getJobs: async (pageNo, pageSize) => {
    const queryParams = {
      pageNo:pageNo,
      pageSize:pageSize
    }
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/screenshots`;
    const response = await RequestHelper.send(
      url,
      {},
      "GET",
      queryParams
    );
    return response;
  },
  
  submitJob: async (branch, modules, languages, subject) => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/screenshots`;
    const requestBody = { 
      branchRef: branch, 
      modules: modules, 
      languages: languages,
      subjectId: subject 
    };
    return RequestHelper.send(
      url,
      {},
      "POST",
      null,
      requestBody
    );
  },

  downloadArtifact: async (jobId) => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/screenshots/${jobId}/download`;
    return RequestHelper.send(
      url,
      { Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM },
      "GET"
    );
  }
};

export default ScreenshotService;
