import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Popup, PopupContent, Table } from 'semantic-ui-react';
import DATA_QUERY_STATUS from '../../constants/dataquery/DATA_QUERY_STATUS';
import DATA_QUERY_TABLE_ACTIONS from '../../constants/dataquery/DATA_QUERY_TABLE_ACTIONS';
import DATA_QUERY_TABLE_COLUMNS from '../../constants/dataquery/DATA_QUERY_TABLE_COLUMNS';
import DateTimeService from '../../services/DateTimeService';

const DataQueryTable = ({t, queries = [], actionsToShow = [], columns = [], on,}) => {

  const headerRow = (
    <>
      {actionsToShow.length > 0 && <Table.HeaderCell>{t("DATA_QUERY_TABLE_ACTIONS")}</Table.HeaderCell>}
      {columns.map((column) => {
        return <Table.HeaderCell>{t("DATA_QUERY_TABLE_" + column)}</Table.HeaderCell>
      })}
    </>
  );

  const getDateCell = (value) => {
    const momentValue = moment(value);
    return (
      <Popup
        mouseEnterDelay={1000}
        mouseLeaveDelay={500}
        on="hover"
        flowing
        trigger={<Table.Cell>{DateTimeService.build.asDisplayDate(momentValue)}</Table.Cell>}
      >
        <PopupContent>{DateTimeService.build.asDisplayDateTime(momentValue)}</PopupContent>
      </Popup>
    );
  };

  const getCell = (query, column) => {
    let value = query[DATA_QUERY_TABLE_COLUMNS.asPropertyString(column)];
    if (value == null) {
      return (
        <Table.Cell>--</Table.Cell>
      );
    }

    if (column === DATA_QUERY_TABLE_COLUMNS.STATUS) {
      value = t("DATA_QUERY_TABLE_STATUS_" + value.toUpperCase());
    }

    const formatAsDate = DATA_QUERY_TABLE_COLUMNS.isDateTimeColumn(column);
    if (formatAsDate) {
      return getDateCell(value);
    }

    return (
      <Table.Cell>{value}</Table.Cell>
    );
  };

  const sortedQueries = queries.sort((q1, q2) => {
    return DATA_QUERY_STATUS[q1.status] - DATA_QUERY_STATUS[q2.status];
  });

  const queryRows = sortedQueries.map((query) => {
    const status = DATA_QUERY_STATUS[query.status];
    return (
      <Table.Row>
        {actionsToShow.length > 0 && (
          <Table.Cell>
            {actionsToShow.indexOf(DATA_QUERY_TABLE_ACTIONS.ANSWER) >= 0 && status === DATA_QUERY_STATUS.OPEN && (
              <Button onClick={() => on[DATA_QUERY_TABLE_ACTIONS.ANSWER](query)}>{t("DATA_QUERY_TABLE_ACTION_ANSWER")}</Button>
            )}
            {actionsToShow.indexOf(DATA_QUERY_TABLE_ACTIONS.CLOSE) >= 0 && status === DATA_QUERY_STATUS.ANSWERED && (
              <Button onClick={() => on[DATA_QUERY_TABLE_ACTIONS.CLOSE](query)}>{t("DATA_QUERY_TABLE_ACTION_CLOSE")}</Button>
            )}
            {actionsToShow.indexOf(DATA_QUERY_TABLE_ACTIONS.LINK_TO) >= 0 && (
              <Button onClick={() => on[DATA_QUERY_TABLE_ACTIONS.LINK_TO](query)}>{t("DATA_QUERY_TABLE_ACTION_LINK_TO")}</Button>
            )}
          </Table.Cell>
        )}
        {columns.map((column) =>  getCell(query, column))}
      </Table.Row>
    );
  });

  return (
    <Table>
      <Table.Header>
        {headerRow}
      </Table.Header>
      <Table.Body>
        {queryRows}
      </Table.Body>
    </Table>
  );
};

export default withTranslation()(DataQueryTable);