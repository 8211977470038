import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import LanguageSelection from "../../components/languageselection/LanguageSelection";
import InternationalisationService from "../../InternationalisationService";

class SelfOnboardSignupPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      error: null,
      message: null,
      requestPending: false,
    };
  }

  validateForm() {
    return AuthService.EMAIL_REGEX.test(this.state.email);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    const id = query.get("id");
    this.setState({ error: null, message: null, requestPending: true });

    AuthService.signup(id, token, email, "subject")
      .then(() => {
        AuthService.setAccountType("subject");
        this.setState({ message: true });
      })
      .catch((error) => {
        this.setState({ requestPending: false, error });
      });
  };

  render() {
    const { t } = this.props;

    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{ maxWidth: 450 }}
          textAlign={InternationalisationService.isRTL() ? "right" : undefined}
        >
          <Header as="h2" textAlign="center">
            {!this.state.forgottenPassword &&
              t("SELFONBOARD_SIGNUP_HEADER_TITLE")}
            {this.state.forgottenPassword &&
              t("RESET_PASSWORD_HEADER_TITLE", "Reset password")}
          </Header>
          <Message>{t("SUBJECT_ACCOUNT_CREATE_INFO_MESSAGE")}</Message>
          <Form size="large" onSubmit={this.handleSubmit}>
            <Segment>
              <Form.Input
                fluid
                icon="mail"
                iconPosition="left"
                name="email"
                placeholder={t("GLOBAL_LABEL_EMAIL_ADDRESS", "Email address")}
                value={this.state.email}
                onChange={this.handleChange}
                disabled={this.state.requestPending}
              />

              <Button
                type="submit"
                primary
                fluid
                size="large"
                disabled={!this.validateForm() || this.state.requestPending}
              >
                {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
              </Button>
            </Segment>
          </Form>

          {this.state.error && (
            <Message negative>
              <div className="header">{t("SELF_ONBOARD_FAILED_TITLE")}</div>
              <p>{t("SELF_ONBOARD_FAILED")}</p>
            </Message>
          )}

          {this.state.message && (
            <Message positive>
              {t("SELFONBOARD_CONFIRMATION_EMAIL_SENT")}
            </Message>
          )}
          <LanguageSelection inline style={{ float: "right" }} />
        </Grid.Column>
      </Grid>
    );
  }
}

export default withTranslation()(SelfOnboardSignupPage);
