import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

const MenuItem = ({to, text, icon, active, badgeValue, isButtonStyle, className}) => {

  if (isButtonStyle) {
    const showBadge = badgeValue != null && (badgeValue > 0 || !!badgeValue);

    const itemStyle = {
      ...styles.buttonStyle, 
      ...(active ? styles.selectedButtonStyle : {}),
      ...(showBadge ? styles.badgeButtonStyle : {}),
    };

    return (
      <Menu.Item
        className={className}
        as={Link}
        to={to}
        active={active}
        style={itemStyle}
      >
        <div>
          <div style={styles.buttonIconTextContainer}>
            {icon && <Icon name={icon} style={styles.buttonIcon} />}
            {text}
          </div>
          {showBadge && (<div style={styles.badge}>{badgeValue}</div>)}
        </div>
      </Menu.Item>
    );
  }

  return (
    <Menu.Item
      className={className}
      as={Link}
      to={to}
      active={active}
    >
      <div>        
        <div style={styles.iconLeftStyle}>{icon && <Icon name={icon} />}</div>
        {text}
      </div>
    </Menu.Item>
  );
};

const styles = {
  iconLeftStyle: {
    float:'left',
    width:30
  },
  buttonStyle: {
    borderRadius: 10, 
    marginBottom: 10,
    marginTop: 10,
    minHeight: 46,
    border: '2px solid #aaa',
  },
  badgeButtonStyle: {
    backgroundColor: '#f8991d', 
    color: '#ffffff', 
    minHeight: 46,
    border: '2px solid #f8991d',
  },
  badge: {
    display: 'inline-block',
    float: 'right',
  },
  buttonIconTextContainer: {
    display: 'inline-block',
  },
  buttonIcon: {
    margin: '0em 0.35714286em 0em 0em',
  },
  selectedButtonStyle: {
    boxShadow: 'inset 0px 0px 0px 2px #fff',
    border: '2px solid #f8991d',
    backgroundColor: '#ffffff', 
  },
}

export default MenuItem;
