import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Input,
  Message,
  Segment,
  Table
} from 'semantic-ui-react';
import Page from '../../../components/page/Page';
import { getDefinitions } from '../../../redux/questionnaires/questionnaireDefinitionsSlice';
import PermissionsService from '../../../services/PermissionsService';
import GroupPermission from '../../../GroupPermission';
import { Redirect } from 'react-router-dom';
import DateTimeService, { DATE_FORMATS } from '../../../services/DateTimeService';
import SubjectService from '../../../SubjectService';
import SubjectQuestionnaireService from '../../../services/SubjectQuestionnaireService';
import ConfigContext from '../../../context/ConfigContext';
import UserContext from '../../../context/UserContext';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import DateRangeControls from '../../../components/DateRangeControls';
import SubjectAnswerManagementService from '../../../services/SubjectAnswerManagementService';
import AnswerManagementEditQuestionnairesModal from './AnswerManagementEditQuestionnairesModal';

const AnswerManagementListQuestionnaires = (props) => {
  const { t, questionnaireDefinitions } = props;

  const params = useParams();
  const { subjectId } = params;

  const [subjectData, setSubjectData] = useState();
  const [allQuestionnaireDefinitions, setAllQuestionnaireDefinitions] = useState();

  const config = useContext(ConfigContext);
  const user = useContext(UserContext);

  const [hasPermission, setHasPermission] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState();

  // Search Inputs/Filters
  const [
    searchCriteriaQuestionnaireFilterOptions,
    setSearchCriteriaQuestionnaireFilterOptions
  ] = useState();

  const defaultSearchCriteria = {
    questionnaireDefinitionCodes: [],
    dateFrom: moment().subtract(7, 'days').format(DATE_FORMATS.DATE),
    dateTo: moment().format(DATE_FORMATS.DATE),
    questionnaireId: undefined,
  };
  const [searchCriteria, setSearchCriteria] = useState(defaultSearchCriteria);

  // Search Results
  const [searchResults, setSearchResults] = useState();
  const [searchResultsHasExceededLimit, setSearchResultsHasExceededLimit] =
    useState(false);

  useEffect(() => {
    populateSubjectData();
    populateAllQuestionnaireDefinitions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subjectData == null) {
      return;
    }
    if (allQuestionnaireDefinitions == null) {
      return;
    }
    init();

    performSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectData, allQuestionnaireDefinitions]);

  const populateSubjectData = async () => {
    const data = await SubjectService.getSubjectData(subjectId);
    setSubjectData(data);
  };

  const populateAllQuestionnaireDefinitions = async () => {
    const defs = await SubjectService.getSubjectRecordQuestionnaireDefinitions()
    setAllQuestionnaireDefinitions([...defs, ...questionnaireDefinitions]);
  };

  const checkPermisisons = async () => {
    const hasCorrectPermission =
      await PermissionsService.hasPermissionForSubject(
        subjectData?.groups,
        GroupPermission.EDIT_SUBJECT_QUESTIONNAIRES
      );
    setHasPermission(hasCorrectPermission);
  };

  const setupSearchCriteriaInputs = async () => {
    await setupSearchCriteriaQuestionnaireFilter();
  };

  /**
   * @private
   * @deprecated
   * Added to complete AT-1427 (the permissions filtering of questionnaires for AT-1419)
   * It is added to work with the current UI oriented handling of filtering based on Modules
   * In future we can make use of the changes in AT-1384, maybe with some additional
   *  development to handle it from nucleus. It would also need existing studies to have the
   *  config migrated too.
   */
  const _getQuestionnaireDefinitionsForUser = async (
    questionnaireDefinitions
  ) => {
    const tabsConfig = config.ui?.tabs ? config.ui?.tabs : [];
    const staffProfile = user.profile;
    const groups = user.profile.groupMappings.map((gm) => gm.group);
    const filtered = [];
    for (const qd of questionnaireDefinitions) {
      const cs =
        await SubjectQuestionnaireService.canStaffViewQuestionnaireModule(
          qd,
          groups,
          tabsConfig,
          staffProfile
        );
      if (cs === true) {
        filtered.push(qd);
      }
    }
    return filtered;
  };

  const setupSearchCriteriaQuestionnaireFilter = async () => {
    const permittedDefinitions = await _getQuestionnaireDefinitionsForUser(
      allQuestionnaireDefinitions,
      null
    );

    const definitionForFilter = permittedDefinitions
      .sort((a, b) => (a.label < b.label ? -1 : 1))
      .map((qd) => {
        let label = SubjectQuestionnaireService.getQuestionnaireLabel(qd);
        const questionnaireModuleLabels =
          SubjectQuestionnaireService.getQuestionnaireModuleLabels(t, qd);
        if (questionnaireModuleLabels !== undefined) {
          label = `${label} (${questionnaireModuleLabels})`;
        }
        return {
          key: qd.code,
          text: label,
          value: qd.code
        };
      });
    setSearchCriteriaQuestionnaireFilterOptions(definitionForFilter);
  };

  const init = async () => {
    await checkPermisisons();
    await setupSearchCriteriaInputs();
    setIsLoading(false);
  };

  const updateSearchCriteria = (criteria) => {
    setSearchCriteria(criteria);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    performSearch();
  };

  const handleResetSearchCriteria = (e) => {
    e.preventDefault();
    setSearchCriteria(defaultSearchCriteria);
    setErrorMessage();
    setSearchResults();
    setSearchResultsHasExceededLimit(false);
  };

  const initSearchState = async () => {
    setIsLoading(true);
    setErrorMessage();
    setSearchResultsHasExceededLimit(false);
    setSearchResults();
  };

  const performSearch = async () => {
    await initSearchState();
    try {
      const updatedSearchCriteria = searchCriteria != null ? { ...searchCriteria } : {};

      const results = await SubjectAnswerManagementService.getQuestionnairesBySearchCriteria(
        subjectId,
        updatedSearchCriteria
      );
      setSearchResults(results?.questionnaires);
      setSearchResultsHasExceededLimit(results?.hasExceededLimit);
    } catch (error) {
      setErrorMessage(error?.message ? error.message : error);
    } finally {
      setIsLoading(false);
    }
  };

  const buildSearchCriteriaFilterInputs = () => {
    return (
      <Grid columns='equal'>
        <Grid.Row>
          <Grid.Column width={6}>
            <label>
              {t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_SEARCH_INPUT_DATERANGE', 'Date Range')}
            </label>
            <DateRangeControls
              disableFormSubmit={true}
              notifyParentOnLoad={false}
              onChange={(startDate, endDate) => {
                const newSearchCriteria = { ...searchCriteria };
                newSearchCriteria.dateFrom = startDate;
                newSearchCriteria.dateTo = endDate;
                setSearchCriteria(newSearchCriteria);
              }}
              start={moment(searchCriteria.dateFrom)}
              end={moment(searchCriteria.dateTo)}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <label>
              {t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_SEARCH_INPUT_QUESTIONNAIRE_ID', 'Questionnaire Id')}
            </label>
            <Input
              fluid
              type='number'
              step={1}
              onChange={(_e, data) => {
                const newSearchCriteria = { ...searchCriteria };
                const id = data?.value.trim().length > 0 ? data.value.trim() : undefined;
                newSearchCriteria.questionnaireId = id;
                updateSearchCriteria(newSearchCriteria);
              }}
              placeholder={t(
                [
                  'SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_SEARCH_INPUT_QUESTIONNAIRE_ID_PLACEHOLDER',
                  'SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_SEARCH_INPUT_QUESTIONNAIRE_ID'
                ],
                'Questionnaire Id'
              )}
              value={searchCriteria?.questionnaireId || ''}
            />
          </Grid.Column>

          <Grid.Column width={12}>
            <label>
              {t(
                'SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_INPUT_QUESTIONNAIRE_DEFINITION',
                'Questionnaires'
              )}
            </label>
            <Dropdown
              placeholder={t(
                [
                  'SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_INPUT_QUESTIONNAIRE_DEFINITION_PLACEHOLDER',
                  'SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_INPUT_QUESTIONNAIRE_DEFINITION'
                ],
                'Questionnaires'
              )}
              fluid
              multiple
              selection
              options={searchCriteriaQuestionnaireFilterOptions || []}
              onChange={(_e, data) => {
                const newSearchCriteria = { ...searchCriteria };
                newSearchCriteria.questionnaireDefinitionCodes = data.value;
                updateSearchCriteria(newSearchCriteria);
              }}
              value={searchCriteria?.questionnaireDefinitionCodes || []}
            />
          </Grid.Column>

        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form onSubmit={handleFormSubmit}>
              <Button primary disabled={isLoading} type='submit'>
                {t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_SEARCH_BUTTON', 'Search')}
              </Button>
              <Button secondary disabled={isLoading} onClick={handleResetSearchCriteria}>
                {t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_RESET_BUTTON', 'Reset search')}
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const buildSearchResultsTable = () => {
    return (
      <Table selectable columns={12} compact>
        <Table.Header>
          <Table.Row key='header'>
            <Table.HeaderCell width={2} key='header_questionnaire_id'>
              {t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_RESULTS_TABLE_HEADER_QUESTIONNAIRE_ID', 'Questionnaire Id')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3} key='header_questionnaire_completionDate'>
              {t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_RESULTS_TABLE_HEADER_QUESTIONNAIRE_COMPLETIONDATE', 'Completed Date')}
            </Table.HeaderCell>
            <Table.HeaderCell width={5} key='header_questionnaire_label'>
              {t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_RESULTS_TABLE_HEADER_QUESTIONNAIRE_LABEL', 'Name')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1} key='header_questionnaire_type'>
              {t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_RESULTS_TABLE_HEADER_QUESTIONNAIRE_TYPE', 'Type')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1} key='header_questionnaire_controls'></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {searchResults &&
            searchResults.map((questionnaire) =>
              buildSearchResultsTableDataRow(questionnaire)
            )}
        </Table.Body>
      </Table>
    );
  };

  const getQuestionnaireDefinition = (questionnaireDefinitionId) => {
    const questionnaireDefinition = allQuestionnaireDefinitions.find(
      (qd) => qd.id === questionnaireDefinitionId
    );
    return questionnaireDefinition;
  };

  const getSearchResultCount = () => {
    const count = searchResults?.length;
    return count;
  };

  const selectQuestionnaireToEdit = (obj) => {
    setSelectedQuestionnaire(obj);
  };

  const buildSearchResultsTableDataRow = (questionnaire) => {
    const questionnaireDefinition = getQuestionnaireDefinition(questionnaire.definitionId);
    return (
      <Table.Row key={questionnaire.id}>
        <Table.Cell
          key={questionnaire.id + '_id'}
          verticalAlign='top'
        >
          {questionnaire.id}
        </Table.Cell>
        <Table.Cell
          key={questionnaire.id + '_completionDate'}
          verticalAlign='top'
        >
          {DateTimeService.build.asDisplayDateTime(questionnaire.completionDate)}
        </Table.Cell>
        <Table.Cell key={questionnaire.id + '_label'} verticalAlign='top'>
          {questionnaireDefinition?.label}
          <div style={{ color: '#707070', fontStyle: 'italic', fontSize: 11 }}>
            {questionnaireDefinition?.code}
          </div>
        </Table.Cell>
        <Table.Cell key={questionnaire.id + '_type'} verticalAlign='top'>
          {questionnaire?.type}
        </Table.Cell>
        <Table.Cell key={questionnaire.id + '_control'} verticalAlign='top'>
          <Button
            onClick={() => selectQuestionnaireToEdit({ questionnaire, questionnaireDefinition })}
          >
            {t('GLOBAL_BUTTON_EDIT', 'Edit')}
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  if (hasPermission === false) {
    return <Redirect to='/' />;
  }

  const title = t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES', 'List questionnaires');

  return (
    <Page
      name='AnswerManagementListQuestionnaires'
      header={`${title} - ${subjectData?.subjectCode}`}
    >
      {selectedQuestionnaire != null && (
        <AnswerManagementEditQuestionnairesModal
          open={selectedQuestionnaire != null}
          subjectId={subjectId}
          questionnaireId={selectedQuestionnaire?.questionnaire?.id}
          questionnaireDefinition={selectedQuestionnaire?.questionnaireDefinition}
          onClose={() => {
            setSelectedQuestionnaire();
            performSearch();
          }}
        />
      )}
      {errorMessage && (
        <Message
          error
          header={t('GLOBAL_ERROR_TITLE', 'Error')}
          content={
            'Fatal error, if it persists contact support: ' + errorMessage
          }
        />
      )}
      {buildSearchCriteriaFilterInputs()}
      {searchResultsHasExceededLimit && (
        <Message
          warning
          header={t('GLOBAL_INFO_TITLE', 'Warning')}
          content={t(
            'SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_RESULTS_EXCEEDED_LIMIT',
            'Your search returned a lot of results, it is advisable to add more filters to reduce the results.'
          )}
        />
      )}
      {getSearchResultCount() !== undefined && (
        <Segment>
          {t('SUBJECT_ANSWERMANAGEMENT_LIST_QUESTIONNAIRES_RESULTS_ITEM_COUNT', 'Count')}:{' '}
          {getSearchResultCount()}
        </Segment>
      )}
      {buildSearchResultsTable()}

    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    questionnaireDefinitions: getDefinitions(state)
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(AnswerManagementListQuestionnaires);
