const questionCodeValidator = (code, questions) => {
  return isCodeEmpty(code) && doesCodeContainIllegalCharacters(code) && !doesContainCapitalLetters(code) && isCodeAlreadyUsed(code, questions)
}

export const isCodeEmpty = (code) => {
  if(typeof code !== "string") return false;
  return code.trim().length > 0
}

export const doesCodeContainIllegalCharacters = (code) => {
  if(code.includes("_")) return false;
  if(code.includes(" ")) return false;
  return true;
}

export const doesContainCapitalLetters = (str) => {
  return str !== str.toLowerCase()
}

export const isCodeAlreadyUsed = (code, questions) => {
  const existingQuestionCodes = questions.map(q=>q.code);
  return !existingQuestionCodes.includes(code);
}

export default questionCodeValidator;