import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Button, Message } from "semantic-ui-react";
import NotificationService, {
  NOTIFICATION_PREFERENCE,
} from "../../services/NotificationService";
import { useDispatch, connect } from "react-redux";
import { getNotificationPreference } from "../../redux/notificationsSlice";

const NotificationPermissionBanner = (props) => {
  const { t, notificationPreference } = props;

  const dispatch = useDispatch();

  const handleNotificationPreferenceYesClick = async () => {
    await NotificationService.enableBrowserNotifications(dispatch);
  };

  const handleNotificationPreferenceNoClick = async () => {
    await NotificationService.disableBrowserNotifications(dispatch);
  };

  if (!NotificationService.showNotificationControls()) {
    return null;
  }

  if (notificationPreference !== NOTIFICATION_PREFERENCE.NONE) {
    return null;
  }

  return (
    <>
      <Message
        onDismiss={handleNotificationPreferenceNoClick}
        header={t("BROWSER_NOTIFICATION_PERMISSION_BANNER_TITLE")}
        content={
          <>
            {t("BROWSER_NOTIFICATION_PERMISSION_BANNER_TEXT")} <br />
            <Button
              primary
              size="large"
              onClick={handleNotificationPreferenceYesClick}
            >
              {t("BROWSER_NOTIFICATION_PERMISSION_BANNER_BUTTON")}
            </Button>
          </>
        }
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationPreference:
      getNotificationPreference(state) || NOTIFICATION_PREFERENCE.NONE,
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(NotificationPermissionBanner);
