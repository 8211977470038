import RequestHelper from "../RequestHelper"

export default class DataQueryService {
  static getQueryCount(filter) {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/dataqueries/count`,
      {},
      "GET",
      filter,
    );
  }

  static getAllQueries(pageSize, page, filter) {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/dataqueries/all/${pageSize}/${page}`,
      {},
      "GET",
      filter,
    );
  }

  static getQueries(questionCode, questionnaireId, subjectId) {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/dataqueries/raisedbyuser/${subjectId}`,
      {},
      "GET",
      {
        questionnaireId,
        questionCode,
      },
    );
  }

  static raiseQuery(questionCode, questionnaireId, subjectId, comment) {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/dataqueries/raise/${subjectId}`,
      {},
      "POST",
      null,
      {
        questionnaireId,
        questionCode,
        comment,
      },
    );
  }

  static answerQuery(queryId, subjectId, comment, newValue) {
    const body = {comment};
    if (Array.isArray(newValue)) {
      body.answerArray = newValue;
    } else {
      body.rawValue = newValue;
    }
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/dataqueries/answer/${queryId}/${subjectId}`,
      {},
      "POST",
      null,
      body,
    );
  }

  static closeQuery(queryId, subjectId, comment, accepted) {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/dataqueries/close/${queryId}/${subjectId}`,
      {},
      "POST",
      null,
      {
        comment,
        accepted,
      },
    );
  }

  static getValueHistory(questionCode, questionnaireId, subjectId) {
    return RequestHelper.send(
      `${process.env.REACT_APP_SERVER_ADDRESS}/dataqueries/valuehistory/${subjectId}`,
      {},
      "GET",
      {
        questionnaireId,
        questionCode,
      },
    );
  }

}