import { QUESTION_TYPES } from "atom5-branching-questionnaire";
import React, { useEffect, useState } from "react";
import AttachmentPreviewUnsupportedFormatError from "./AttachmentPreviewUnsupportedFormatError";
import AttachmentImagePreview from "./previews/AttachmentImagePreview";
import AttachmentPdfPreview from "./previews/AttachmentPdfPreview";

const RENDER_TYPE = {
  IMAGE: { component: AttachmentImagePreview },
  PDF: { component: AttachmentPdfPreview },
  UNSUPPORTED: { component: AttachmentPreviewUnsupportedFormatError },
};

const AttachmentPreview = (props) => {
  const { question, attachment, url, blob, maxHeight } = props;

  const containerMaxHeight = maxHeight ? maxHeight : styles.container.maxHeight;

  const [renderType, setRenderType] = useState(null);

  useEffect(() => {
    if (question && attachment) {
      if (question.type === QUESTION_TYPES.IMAGE) {
        setRenderType(RENDER_TYPE.IMAGE);
      } else if (question.type === QUESTION_TYPES.FILE) {
        const typeForViewer = convertMimeTypeToRenderType(attachment.mediaType);
        setRenderType(typeForViewer);
      } else {
        setRenderType(RENDER_TYPE.UNSUPPORTED);
      }
    }
  }, [question, attachment]);

  const convertMimeTypeToRenderType = (mimeType) => {
    const MIMETYPE_MAPPING_FOR_VIEWER = {
      "application/pdf": RENDER_TYPE.PDF,
      "image/jpeg": RENDER_TYPE.IMAGE,
      "image/png": RENDER_TYPE.IMAGE,
    };
    const type = MIMETYPE_MAPPING_FOR_VIEWER[mimeType];
    if (!type) {
      console.log(
        '[AttachmentPreview][convertMimeTypeForViewer] No preview type mapping for mimetype "' +
          mimeType +
          '"'
      );
      return RENDER_TYPE.UNSUPPORTED;
    }
    return type;
  };

  const getComponent = (renderProps = {}) => {
    if (!renderType) {
      return null;
    }
    return React.createElement(renderType.component, { ...renderProps });
  };

  const handleOnContextMenu = (e) => {
    // Disables right click
    e.preventDefault();
  };

  const previewProps = { url, blob, reference: attachment?.reference };
  const componentToRender = getComponent(previewProps);

  return (
    <>
      {renderType === RENDER_TYPE.UNSUPPORTED && <>{componentToRender}</>}
      {renderType !== RENDER_TYPE.UNSUPPORTED && (
        <div
          data-reference={attachment.reference}
          style={{
            ...styles.container,
            maxHeight: containerMaxHeight,
          }}
          onContextMenu={handleOnContextMenu}
        >
          <div style={styles.innerContainer}>{componentToRender}</div>
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    overflow: "scroll",
    resize: "both",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#444444",
    backgroundColor: "#FFFFFF",
    height: "auto",
    maxHeight: 400,
  },
  innerContainer: {
    padding: 0,
  },
};

export default AttachmentPreview;
