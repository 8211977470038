import React, {useState, useRef, useEffect} from "react";
import { withTranslation } from "react-i18next";
import { uploadTranslationCsv } from "../../services/admin/AdminTranslationService"

import {
    Button,
    Form,
    Grid, Image, Message, Modal
} from "semantic-ui-react";

const UploadTranslationsModal = ({open, onClose, t}) => {
    const fileInputRef = useRef();
    const [file, setFile] = useState()
    const [error, setError] = useState("")
    const [info, setInfo] = useState("")

    useEffect(() => {
        setError("")
        setInfo("")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])
    const doImport = async () => {
        if(!file){
            setError("Must select a file first")
            return
        }
        console.log(`File selected name: ${file.name}, type=${file.type}`)
        try{
            await uploadTranslationCsv(file)
            setInfo("Import file submitted. It can take a few minutes, check the jobs list for completion ")
        }catch (e) {
            console.error("Error importing file",e)
            setError(e)
        }
        setTimeout(() => {
            onClose()
            setInfo("")
        }, 3000)
    }

    const handleInitClick = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = async (event) => {
        const fileToUpload = event?.target?.files[0];

        setFile(fileToUpload)
    };


    return (
    <Modal
        size={"large"}
        open={open}
        onClose={onClose}
    >
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <Image
                            style={{ height: "25px" }}
                            src={
                                "../../images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal.Content scrolling>
                {error && error.length>0 &&  <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={error}
                />}
                {info && info.length>0  &&  <Message
                    info
                    header={t("GLOBAL_INFO_TITLE", "Info")}
                    content={info}
                />}
                <Form>

                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION_UPLOAD_FILE", "Select CSV file - make sure it has relevant columns and special characters are readable")}</label>
                        <input
                            ref={fileInputRef}
                            type="file"
                            hidden
                            accept={[".csv", "text/csv", ".zip"]}
                            onChange={handleFileSelect}
                        />
                        <label>File selected: {file && file?.name ? file.name : "None"}</label>

                        <Button
                            primary={true}
                            content={t("GLOBAL_BUTTON_SELECT_FILE", "Select file")}
                            labelPosition="left"
                            icon={"file"}
                            onClick={handleInitClick}
                        />
                    </Form.Field>



                </Form>
            </Modal.Content>
            <Grid centered padded>
                <Grid.Row>

                    <Button
                        color={"orange"}
                        size="small"
                        onClick={() => doImport()}
                    >
                        {t("GLOBAL_BUTTON_IMPORT", "Import")}
                    </Button>

                    <Button
                        basic
                        color={"orange"}
                        size="small"
                        onClick={onClose}
                    >
                        {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                    </Button>
                </Grid.Row>
            </Grid>

    </Modal>
    )
}

export default withTranslation()(UploadTranslationsModal);
