import SubjectService from "../SubjectService";
import { setDefinitions } from "../redux/questionnaires/questionnaireDefinitionsSlice";

const AppStateService = {
  getQuestionnaireDefinitionsFromServer: async (dispatch) => {
    const definitions = await Promise.all([
      SubjectService.getQuestionnaireDefinitions("PRO"),
      SubjectService.getQuestionnaireDefinitions("CONTENT"),
      SubjectService.getQuestionnaireDefinitions("EVENT"),
      SubjectService.getQuestionnaireDefinitions("DATA"),
    ]);

    dispatch(
      setDefinitions({
        proDefinitions: definitions[0],
        contentDefinitions: definitions[1],
        eventDefinitions: definitions[2],
        dataDefinitions: definitions[3],
      })
    );
  },
};

export default AppStateService;
