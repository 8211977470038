import React, { useContext, useEffect, useRef, useState } from "react";
import {
  EVENTS,
  QUESTION_TYPES,
} from "atom5-branching-questionnaire";
import { Form, Header } from "semantic-ui-react";
import i18next from "i18next";

import useForceRerender from "../hooks/useForceRerender";
import API_QUESTIONNAIRE_TYPES from "../constants/API_QUESTIONNAIRE_DEFINITION_TYPES";
import AuthService from "../services/AuthService";
import {
  replaceAnswerMap,
  replaceCursorHistoryStack,
  updateCurrentQuestionnaire,
  setCurrentQuestionnaireAsCompleted
} from "../redux/questionnaires/currentQuestionnaireSlice";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import QuestionHelper from "../helpers/QuestionHelper";
import UserContext from "../context/UserContext";
import branchingQuestionnaireHelper from "../helpers/branchingQuestionnaireHelper";
import { logevent } from "../services/FirebaseAnalytics";
import performStaffSignOffQuestionModification from "./utility/performStaffSignoffQuestionModification";

const Questionnaire = ({
  definition,
  questionnaire,
  onSubmit,
  onPageChange,
  isLastQuestionnaire = true,
  shouldShowSubmit = true,
  showSubmitAs,
  subjectId: passedSubjectId,
  isReadOnly,
  currentQuestionnaire,
  isMultipart,
  shouldShowTitle = true,
  shouldDispatchToStore = true,
  withoutPaging = false,
  withoutConditions = false,
  withoutValidation = false
}) => {
  const _questionsForCreation = useRef();
  const dispatch = useDispatch();
  const user = useContext(UserContext);

  const [subjectId, setSubjectId] = useState(passedSubjectId);
  const [hasQuestionnaireBuilt, setHasQuestionnaireBuilt] = useState(false);
  const [modifiedDefinition, setModifiedDefinition] = useState(null);
  const [metaData, setMetaData] = useState();

  const registerScreenView = (screenName) => {
    logevent(
    'screen_view', {
      firebase_screen: screenName,
      firebase_screen_class: 'Questonnaire'
    })
  }

  const onQuestionPageChange = (page_cursor) => {
      registerScreenView('questionnaire_' + definition.code + "_c" + page_cursor);
      onPageChange && onPageChange();
  }

  useEffect(() => {
    buildModifiedDefinition();
    let completeQuestionnaire = { ...questionnaire, definition };
    if (shouldDispatchToStore) {
      dispatch(
          updateCurrentQuestionnaire({
            questionnaire: completeQuestionnaire,
            subjectId,
          })
      );
      registerScreenView('questionnaire_' + definition.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildModifiedDefinition = () => {
    const updatedQuestions = definition.questions.map((q) => {
      const updatedQuestion = performStaffSignOffQuestionModification(user, definition, questionnaire, q, isReadOnly);

      if (q.type === QUESTION_TYPES.FIXED_VALUE) {
        const answerDisplayOrder = q?.config?.answerDisplayOrder?.mode;
        const shouldReOrder =
          user.accountType === "subject" &&
          !isReadOnly &&
          answerDisplayOrder === "RANDOM";

        if (shouldReOrder) {
          const answerDisplayAppendToEnd =
            q?.config?.answerDisplayOrder?.appendToEnd || [];
          const answersToRandomise = q.answers.filter((a) => {
            return !answerDisplayAppendToEnd.includes(a.code);
          });
          const answersToAppendToEnd = q.answers.filter((a) => {
            return answerDisplayAppendToEnd.includes(a.code);
          });

          const shuffledAnswers =
            QuestionHelper.shuffleArray(answersToRandomise);
          updatedQuestion.answers = [
            ...shuffledAnswers,
            ...answersToAppendToEnd,
          ];

          return updatedQuestion;
        }
      }
      return updatedQuestion;
    });
    setModifiedDefinition({ ...definition, questions: updatedQuestions });
  };

  useEffect(() => {
    const getSubjectIdFromProfile = async () => {
      if (AuthService.isSubject() && passedSubjectId == null) {
        const profile = await AuthService.getMyProfile();
        setSubjectId(profile.Id);
      }
    };
    getSubjectIdFromProfile();
  }, [passedSubjectId]);

  useEffect(() => {
    const populateMetaData = async () => {
      const isStaff = await AuthService.isStaff() && AuthService.isLoggedIn();
      const currentUser = {
        type: isStaff ? 'staff' : 'subject'
      }

      if (isStaff) {
        const staff = await AuthService.getMyProfile();
        currentUser.id = AuthService.isStaff() ? staff.id : undefined;
        currentUser.email = AuthService.isStaff() ? staff.email : undefined;
        currentUser.firstName = AuthService.isStaff() ? staff.firstName : undefined;
        currentUser.lastName = AuthService.isStaff() ? staff.lastName : undefined;
      }

      const data = {
        currentUser
      };
      setMetaData(data);
    };
    populateMetaData();
  }, []);

  const forceRerender = useForceRerender();

  const handleValueChange = (values, cursor, cursorHistoryStack) => {
    let newValues = _.cloneDeep(values);
    if (shouldDispatchToStore) {
      if(definition.type !== API_QUESTIONNAIRE_TYPES.CONTENT){
        dispatch(replaceAnswerMap(newValues));
      }
      dispatch(replaceCursorHistoryStack({ cursorHistoryStack, cursor }));
    }
    forceRerender();
  };

  const handleSubmit = (...args) => {
    if (shouldDispatchToStore) {
      dispatch(setCurrentQuestionnaireAsCompleted());
    }
    onSubmit(...args);
  };

  const [currentQuestionnaireCode, setCurrentQuestionnaireCode] =
    useState(null);

  if (!subjectId || !modifiedDefinition || !metaData) {
    return <></>;
  }

  // passedSubjectId is undefined on subject side
  if (
    modifiedDefinition.code !== currentQuestionnaireCode ||
    (passedSubjectId !== undefined && passedSubjectId !== subjectId)
  ) {
    let completeQuestionnaire = { ...questionnaire, definition };
    if (hasQuestionnaireBuilt) {
      setHasQuestionnaireBuilt(false);
    }
    if (shouldDispatchToStore) {
      dispatch(
        updateCurrentQuestionnaire({
          questionnaire: completeQuestionnaire,
          subjectId,
        })
      );
    }
  }

  if (
    currentQuestionnaire?.questionnaire?.definition?.code ===
      modifiedDefinition.code &&
    currentQuestionnaire.subjectId === subjectId &&
    !hasQuestionnaireBuilt
  ) {
    let builder = branchingQuestionnaireHelper.getBuilderWithRenderers();
    setHasQuestionnaireBuilt(true);

    const customSubmitText = (() => {
      if (showSubmitAs) {
        return showSubmitAs;
      }
      if (modifiedDefinition.type === API_QUESTIONNAIRE_TYPES.CONTENT) {
        return i18next.t("MARK_AS_READ");
      }
      return null;
    })();

    const calculatedAnswers = questionnaire
      ? questionnaire?.answers
      : modifiedDefinition?.answers;

    let answersForQuestionnaire = {
      ...calculatedAnswers,
    };

    if (!isMultipart) {
      answersForQuestionnaire = {
        ...calculatedAnswers,
        ...currentQuestionnaire?.answerMap,
      };
    }

    // isReadOnly should only be present if defined
    const isReadOnlyPropContainer = {};
    if(isReadOnly !== undefined){
      isReadOnlyPropContainer.isReadOnly = isReadOnly;
    }
    if(definition.type === API_QUESTIONNAIRE_TYPES.CONTENT){
      isReadOnlyPropContainer.isReadOnly = false
    }

    if (withoutValidation) {
      //builder = builder.withoutValidation();
    }
    const builtQuestionnaire = builder
      .on(EVENTS.RERENDER, handleValueChange)
      .on(EVENTS.SUBMIT_SUCCESS, handleSubmit)
      .on(EVENTS.CHANGE_PAGE, onQuestionPageChange)
      .withAdditionalProps({
        questionnaireId: questionnaire
          ? questionnaire.id
          : modifiedDefinition.id,
        isLastQuestionnaire,
        shouldShowSubmit,
        subjectId,
        customSubmitText,
        definition: modifiedDefinition,
        ...isReadOnlyPropContainer,
        questionnaire,
        metaData
      })
      .withoutPaging(withoutPaging)
      .withoutConditions(withoutConditions)
      .withDefaultValues(
        answersForQuestionnaire,
        isMultipart ? 0 : currentQuestionnaire.cursor,
        isMultipart ? [] : currentQuestionnaire.cursorHistoryStack
      )
      .withQuestions(modifiedDefinition.questions)
      .withWorkflowInstance(questionnaire?.questionnaireWorkflowInstance)
      .build();

    _questionsForCreation.current = builtQuestionnaire.render();

    setCurrentQuestionnaireCode(modifiedDefinition.code);
  }

  if (!_questionsForCreation.current) {
    return <></>;
  }

  const nonNullQuestionsForCreation = Object.values(
    _questionsForCreation.current
  ).filter((renderFunction) => !!renderFunction);

  return (
    <>
      <Form id={"form_" + modifiedDefinition.code} size={"big"}>
        {shouldShowTitle === true && modifiedDefinition.label && (
          <Header as={"h2"}>{modifiedDefinition.label}</Header>
        )}
        <div style={{ maxWidth: "800px", display: "flex", flexDirection: "row", flexWrap: 'wrap', margin:'-0.5rem' }}>
          {nonNullQuestionsForCreation.map(([component, props], index) => {

            return (
              React.createElement(component, {
                ...props,
                key: modifiedDefinition.code + "_" + props?.question?.code,
              })
          )})}
        </div>

      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentQuestionnaire: state.currentQuestionnaire,
  };
};

const enhance = compose(connect(mapStateToProps));

export default enhance(Questionnaire);
