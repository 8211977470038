import React from "react";
import { Form } from "semantic-ui-react";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";

const Decimal = ({ question, value, changeValue, isReadOnly }) => {
  const onChange = ({ target: { value } }) => {
    const newValue = parseFloat(value, 10);
    if (isNaN(newValue) && value !== "") {
      return;
    }
    changeValue(newValue);
  };

  return (
    <Form.Input
      data-question-answer-value={value}
      type={"number"}
      name={question.code}
      id={question.code}
      onChange={onChange}
      value={value != null ? value : ""}
      step={0.1}
      min={question.min}
      max={question.max}
      disabled={isReadOnly}
      style={question?.config?.style?.input}
    />
  );
};

export default withContainer(withLabel(withError(Decimal)));
