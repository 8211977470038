import React from "react";
import DOMPurify from "dompurify";

export default function ValueLabelSliderDisplay({
  value,
  label,
  lang,
  style,
  useMarkup,
  isLastLabel,
}) {
  // The importer has been changed to automatically translate language objects
  // but language objects will still be present in old configs.
  let parsedLabel = "";
  let hasLabel = false;
  if(typeof label === "object"){
    if(label && lang && lang in label){
      parsedLabel = label[lang]
      hasLabel = true
    }
  } else if(typeof label === "string") {
    parsedLabel = label;
    hasLabel = true;
  }
  let labelDisplay = null;
  if (hasLabel) {
    if (useMarkup) {
      labelDisplay = (
        <p
          style={{ fontSize: "1.25em", pointerEvents: "none", ...style }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(parsedLabel),
          }}
        />
      );
    } else {
      labelDisplay = (
        <p style={{ fontSize: "1.25em", pointerEvents: "none", ...style }}>
          {parsedLabel}
        </p>
      );
    }
  }

  return (
    <div
      style={{
        position: "relative",
        top: "-3rem",
        maxWidth: "80px",
        minWidth: isLastLabel ? "80px" : undefined,
        color: "#444",
        pointerEvents: "none",
      }}
    >
      <p style={{ fontSize: "1.5em", pointerEvents: "none" }}>{value}</p>
      {hasLabel && labelDisplay}
    </div>
  );
}
