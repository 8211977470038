import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, Pagination, Table } from "semantic-ui-react";

const DEFAULT_PAGE_LIMIT = 5;

export default function GenericSelectionTable({
  data,
  toIndexBy,
  columnMapping,
  onSelectionChange,
  shouldSelectAllByDefault,
}) {
  const [selected, setSelected] = useState(
    shouldSelectAllByDefault ? data.map((x) => x[toIndexBy]) : []
  );

  let [activePage, setActivePage] = useState(1);

  let headers = columnMapping.map(({ title }) => (
    <Table.HeaderCell>{title}</Table.HeaderCell>
  ));
  headers.unshift(
    <Table.HeaderCell>
      <Checkbox
        checked={selected.length === data.length}
        onChange={(_e, a) =>
          a.checked
            ? setSelected(data.map((x) => x[toIndexBy]))
            : setSelected([])
        }
      />
    </Table.HeaderCell>
  );

  let codeMapping = columnMapping.map(({ code }) => code);

  const onChange = (checked, value) => {
    if (!checked) {
      setSelected((oldSelected) => oldSelected.filter((s) => s !== value));
    } else {
      setSelected((oldSelected) => [...oldSelected, value]);
    }
  };

  useEffect(() => {
    onSelectionChange(selected);
  }, [selected, onSelectionChange]);

  const numberOfPages = useMemo(() => {
    return Math.ceil(data.length / DEFAULT_PAGE_LIMIT);
  }, [data]);

  const handlePageChange = (value, a) => setActivePage(a.activePage);

  const pageData = useMemo(() => {
    return data.filter(
      (_v, i) =>
        (activePage - 1) * DEFAULT_PAGE_LIMIT <= i &&
        i < activePage * DEFAULT_PAGE_LIMIT
    );
  }, [data, activePage]);

  return (
    <>
      <Table>
        <Table.Header>{headers}</Table.Header>
        <Table.Body>
          {pageData.map((row) => {
            let cells = codeMapping.map((code) => {
              if (typeof row[code] === "object") {
                return <Table.Cell>{row[code]}</Table.Cell>;
              }
              return <Table.Cell>{row[code]}</Table.Cell>;
            });
            cells.unshift(
              <Table.Cell>
                {
                  <Checkbox
                    checked={selected.includes(row[toIndexBy])}
                    onChange={(_e, a) => onChange(a.checked, row[toIndexBy])}
                  />
                }
              </Table.Cell>
            );

            return <Table.Row>{cells}</Table.Row>;
          })}
        </Table.Body>
      </Table>
      <Pagination
        defaultActivePage={1}
        totalPages={numberOfPages}
        onPageChange={handlePageChange}
      />
    </>
  );
}
