import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import SubjectInfoContext from '../../context/SubjectInfoContext';
import GroupPermission from '../../GroupPermission';
import DataQueryService from '../../services/DataQueryService';
import PermissionsService from '../../services/PermissionsService';
import AnswerDataQueryModal from './AnswerDataQueryModal';
import CloseDataQueryModal from './CloseDataQueryModal';
import RaiseDataQueryModal from './RaiseDataQueryModal';
import ViewDataQueriesModal from './ViewDataQueriesModal';
import ViewPreviousValuesModal from './ViewPreviousValuesModal';

const DataQueryButtons = ({t, question, questionnaireId, renderedQuestion, subjectId}) => {
  const [raiseModalOpen, setRaiseModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [answerModalOpen, setAnswerModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [viewPreviousValuesModalOpen, setViewPreviousValuesModalOpen] = useState(false);

  const [refreshExistingQueries, setRefreshExistingQueries] = useState(true);
  const [existingQueries, setExistingQueries] = useState([]);
  const [previousValues, setPreviousValues] = useState([]);

  const [selectedQuery, setSelectedQuery] = useState(null);

  const [canViewDataQueries, setCanViewDataQueries] = useState(false);
  const [canRaiseDataQueries, setCanRaiseDataQueries] = useState(false);
  const [canAnswerDataQueries, setCanAnswerDataQueries] = useState(false);
  const [canCloseDataQueries, setCanCloseDataQueries] = useState(false);

  const subjectGroups = useContext(SubjectInfoContext).subjectData.groups;

  useEffect(() => {
    const doGetPermissions = async () => {
      const view = await PermissionsService.hasPermissionForSubject(subjectGroups, GroupPermission.VIEW_DATA_QUERIES);
      const raise = await PermissionsService.hasPermissionForSubject(subjectGroups, GroupPermission.RAISE_DATA_QUERIES);
      const answer = await PermissionsService.hasPermissionForSubject(subjectGroups, GroupPermission.ANSWER_DATA_QUERIES);
      const close = await PermissionsService.hasPermissionForSubject(subjectGroups, GroupPermission.CLOSE_DATA_QUERIES);

      setCanViewDataQueries(view);
      setCanRaiseDataQueries(raise);
      setCanAnswerDataQueries(answer);
      setCanCloseDataQueries(close);
    };
    doGetPermissions();
  }, [subjectGroups])

  useEffect(() => {
    const getExistingQueries = async () => {
      const queries = await DataQueryService.getQueries(question.code, questionnaireId, subjectId);
      setExistingQueries(queries);
    };
    const getPreviousValues = async () => {
      const previousValues = await DataQueryService.getValueHistory(question.code, questionnaireId, subjectId);
      setPreviousValues(previousValues);
    };
    if (refreshExistingQueries && canViewDataQueries) {
      getExistingQueries();
      getPreviousValues();
      setRefreshExistingQueries(false);
    }
  }, [refreshExistingQueries, canViewDataQueries, question, questionnaireId, subjectId]);

  const openRaiseModal = (e) => {
    e.preventDefault();
    setRaiseModalOpen(true);
  };

  const openViewModal = (e) => {
    e.preventDefault();
    setViewModalOpen(true);
  };

  const openViewPreviousValuesModal = (e) => {
    e.preventDefault();
    setViewPreviousValuesModalOpen(true);
  };

  const openRaiseQueryModalButton = canRaiseDataQueries && (
    <Button
      primary
      icon
      labelPosition="left"
      onClick={openRaiseModal}
    >
      {t("DATA_QUERY_OPEN_RAISE_QUERY_MODAL")} <Icon name="plus" />
    </Button>
  );
  
  const actionableQueryCount = existingQueries.map((eq) => {
    if (canAnswerDataQueries && eq.status === "OPEN") {
      return 1;
    }
    if (canCloseDataQueries && eq.status === "ANSWERED") {
      return 1;
    }
    return 0;
  }).reduce((a, b) => a + b, 0);

  const openViewQueriesModalButton = existingQueries.length > 0 && (
    <Button
      primary={actionableQueryCount > 0}
      icon
      labelPosition="left"
      onClick={openViewModal}
    >
      {t("DATA_QUERY_OPEN_VIEW_QUERIES_MODAL")} <Icon name="search" />
    </Button>
  );

  const openViewPreviousValuesModalButton = canViewDataQueries && (
    <Button
      icon
      labelPosition="left"
      onClick={openViewPreviousValuesModal}
    >
      {t("DATA_QUERY_OPEN_VIEW_PREVIOUS_VALUES_MODAL")} <Icon name="copy" />
    </Button>
  );

  const onAnswer = (query) => {
    setAnswerModalOpen(true);
    setViewModalOpen(false);
    setSelectedQuery(query);
  };

  const onClose = (query) => {
    setCloseModalOpen(true);
    setViewModalOpen(false);
    setSelectedQuery(query);
  };

  const raiseModalProps = {
    onClose: () => {
      setRaiseModalOpen(false);
      setRefreshExistingQueries(true);
    }, 
    open: raiseModalOpen, 
    questionCode: question.code, 
    questionnaireId, 
    renderedQuestion, 
    subjectId,
  };

  const viewModalProps = {
    onClose: () => setViewModalOpen(false),
    open: viewModalOpen,
    queries: existingQueries,
    showAnswerButton: canAnswerDataQueries,
    showCloseButton: canCloseDataQueries,
    renderedQuestion,
    onAnswerPress: onAnswer,
    onClosePress: onClose,
  };

  const answerModalProps = {
    onClose: () => {
      setAnswerModalOpen(false);
      setViewModalOpen(true);
      setRefreshExistingQueries(true);
    },
    open: answerModalOpen,
    query: selectedQuery,
    question,
    renderedQuestion,
    subjectId,
  };

  const closeModalProps = {
    onClose: () => {
      setCloseModalOpen(false);
      setViewModalOpen(true);
      setRefreshExistingQueries(true);
    },
    open: closeModalOpen,
    query: selectedQuery,
    question,
    renderedQuestion,
    subjectId,
  };

  const viewPreviousValuesModalProps = {
    onClose: () => {
      setViewPreviousValuesModalOpen(false);
      setRefreshExistingQueries(true);
    },
    open: viewPreviousValuesModalOpen,
    previousValues,
    question,
    renderedQuestion,
    subjectId,
  };

  return (
    <div style={{paddingRight: 10, paddingTop: 16}}>
      {openViewQueriesModalButton}
      {openRaiseQueryModalButton}
      {openViewPreviousValuesModalButton}

      <RaiseDataQueryModal {...raiseModalProps} />
      <ViewDataQueriesModal {...viewModalProps} /> 
      {selectedQuery && <AnswerDataQueryModal {...answerModalProps} />}
      {selectedQuery && <CloseDataQueryModal {...closeModalProps} />}
      <ViewPreviousValuesModal {...viewPreviousValuesModalProps} />
    </div>
  );
};

export default withTranslation()(DataQueryButtons);