import API_QUESTIONNAIRE_DEFINITION_TYPES from "../constants/API_QUESTIONNAIRE_DEFINITION_TYPES";

export const filterCompletedQuestionnaires = (
  questionnaires,
  definitions,
  filterObject
) => {
  return questionnaires.filter((questionnaire) => {
    if (
      questionnaire.completionDate &&
      questionnaire.type !== API_QUESTIONNAIRE_DEFINITION_TYPES.CONTENT
    ) {
      if (
        typeof filterObject === "object" &&
        filterObject[questionnaire.type] != null &&
        ("only" in filterObject[questionnaire.type].completed ||
          "exclude" in filterObject[questionnaire.type].completed)
      ) {
        let definitionCode = definitions.find(
          (def) => def.id === questionnaire.definitionId
        )?.code;

        let only = filterObject[questionnaire.type].completed.only
          ? filterObject[questionnaire.type].completed.only.some(
              (onlyCode) => onlyCode === definitionCode
            )
          : true;

        let exclude = filterObject[questionnaire.type].completed.exclude
          ? !filterObject[questionnaire.type].completed.exclude.some(
              (onlyCode) => onlyCode === definitionCode
            )
          : true;

        if (!only || !exclude) {
          return false;
        }
      } else {
        // completed will not be shown by default
        return false;
      }
    }
    // unless it is of type content (or not completed)
    return true;
  });
};

export const shouldShowCompletedQuestionnaire = (definition, filterObject) => {
  let hasOnly = false;
  let hasExclude = false;

  if (typeof filterObject !== "object") {
    return false;
  }

  if (definition.type in filterObject) {
    hasOnly = "only" in filterObject[definition.type]?.completed;
    hasExclude = "exclude" in filterObject[definition.type]?.completed;
  } else {
    if (definition.type === API_QUESTIONNAIRE_DEFINITION_TYPES.CONTENT) {
      hasOnly = true;
      hasExclude = true;
    }
  }

  if (
    typeof filterObject === "object" &&
    typeof filterObject[definition.type] === "object" &&
    (hasOnly || hasExclude)
  ) {
    let only = hasOnly
      ? filterObject[definition.type].completed.only.some(
          (onlyCode) => onlyCode === definition.code
        )
      : true;

    let exclude = hasExclude
      ? !filterObject[definition.type].completed.exclude.some(
          (onlyCode) => onlyCode === definition.code
        )
      : true;

    if (!only || !exclude) {
      return false;
    }

    return true;
  } else {
    // completed will not be shown by default
    return false;
  }
};
