import React, {Fragment, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Button, Placeholder, Segment} from "semantic-ui-react";
import SensorDataService from "../../../../services/sensordata/SensorDataService";
import YumenHelper from "./YumenHelper";
import DateTimeService from "../../../../services/DateTimeService";
import { saveAs } from "file-saver";

const YumenPageHeader = ({limbType, subjectId, t}) => {

  const [hubId, setHubId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [])

  const exportFunction = async () => {

    if (hubId === null) {
      console.log('Hub Id not set, can not export');
      return;
    }

    let data = await SensorDataService.getYumenExportData(hubId, limbType);

    const csvBlobData = new Blob([data], {
      type: "text/csv;charset=utf-8;",
    });

    const theDate = DateTimeService.build.asDisplayDate(new Date());
    const filename = `WearableArmData-${theDate}.csv`;
    saveAs(csvBlobData, filename);
  };

  const getData = async () => {
    try {
      setLoading(true);

      let theHubId = await YumenHelper.findYumenHubId(subjectId);

      if (!theHubId) {
        setLoading(false);
        console.log("No hub Id found for subject");
        return;
      }
      setHubId(theHubId);

      let count = await SensorDataService.getYumenExportDataCount(subjectId, limbType);
      setDataCount(count);

      setLoading(false);
    } catch (e) {
      console.error('Error loading Yumen wearable data', e);
      setError(true);
      setLoading(false);
    }
  };


  return (
      <Fragment>
        {loading && (
            <Segment>
              <Placeholder fluid>
                <Placeholder.Header image>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                  <Placeholder.Line/>
                </Placeholder.Paragraph>
              </Placeholder>
            </Segment>
        )}

        {!loading && (
            <>
              {error && <p>{t("SENSOR_DATA_ERROR", "Error while fetching data, if the problem persists contact support")}</p>}

              {hubId && (<>
                <h2>{t("YUMEN_ARM_HEADER_CONNECTED", "Yumen Hub connected")} ({hubId})</h2>
                <div style={{marginBottom: "20px"}}>
                  {dataCount === 0 && (
                      <Button
                          disabled={dataCount === 0}>
                        {t("YUMEN_ARM_EXPORT_DATA", "Export all wearable data")}
                      </Button>)
                  }
                  {dataCount > 0 && (
                      <Button
                          primary
                          onClick={() => {
                            exportFunction();
                          }}
                      >
                        {t("YUMEN_ARM_EXPORT_DATA", "Export all wearable data")}
                      </Button>)}
                </div>
              </>)}

              {!hubId && (<>
                <h2>{t("YUMEN_ARM_HEADER_NOT_CONNECTED", "No Yumen Hub connected")}</h2>
                <h3>{t("YUMEN_ARM_HEADER2_NOT_CONNECTED",
                    "Please define the Yumen Wearable Hub ID in the Subject Records section")}</h3>
              </>)}
            </>
        )}
      </Fragment>
  );
}

export default withTranslation()(YumenPageHeader);
