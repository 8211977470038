import {QUESTION_TYPES} from "atom5-branching-questionnaire";

const DEFAULT_TRANSLATION_OBJECT = {
  client: "server",
  code: null,
  language: null,
  translation: "",
}

const DEFAULT_TRANSLATION_KEYS = ["label", "config", "validation-min", "validation-max", "validation-required", "validation-format"];
const VIDEO_TRANSLATION_KEYS = ["videoUrl", "posterUrl", "height", "width"]
const IMAGE_TRANSLATION_KEYS = ["url"]


const createNewTranslationsArrayForQuestion = (
    questionnaireDefinitionCode,
    questionDefinitionCode,
    questionType,
    languages,
    existingTranslationObjectArray) => {
  const newTranslations = [];
  const translationKeys = [...DEFAULT_TRANSLATION_KEYS];
  if([QUESTION_TYPES.RENDER_VIDEO, QUESTION_TYPES.MONITORED_VIDEO].includes(questionType)){
    translationKeys.push(...VIDEO_TRANSLATION_KEYS)
  }
  if(QUESTION_TYPES.RENDER_IMAGE === questionType){
    translationKeys.push(...IMAGE_TRANSLATION_KEYS);
  }

  for(const i in translationKeys){
    const translationCode = `questionnaire_${questionnaireDefinitionCode}_questions_${questionDefinitionCode}_${translationKeys[i]}`
    for(const j in languages){
      const existingTranslationIndex = existingTranslationObjectArray
          .findIndex(tO => tO.code === translationCode && tO.language === languages[j].code);

      if(existingTranslationIndex === -1) {
        newTranslations.push({...DEFAULT_TRANSLATION_OBJECT, language: languages[j].code, code: translationCode})
      }
    }
  }

  return newTranslations;
}

const createNewTranslationsArrayForAnswers = (
    questionnaireDefinitionCode,
    questionDefinitionCode,
    answerArray,
    languages,
    existingTranslationObjectArray) => {
  const newTranslations = [];
  for(const i in answerArray){
    const translationCode = `questionnaire_${questionnaireDefinitionCode}_questions_${questionDefinitionCode}_answers_${answerArray[i].code}_label`
    for(const j in languages){
      const existingTranslationIndex = existingTranslationObjectArray
          .findIndex(tO => tO.code === translationCode && tO.language === languages[j].code);

      if(existingTranslationIndex === -1) {
        newTranslations.push({...DEFAULT_TRANSLATION_OBJECT, language: languages[j].code, code: translationCode})
      }
    }
  }
  return newTranslations;
}

export {
  createNewTranslationsArrayForQuestion,
  createNewTranslationsArrayForAnswers
};
