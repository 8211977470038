import React, { Component } from "react";
import { Dropdown, Image } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import InternationalisationService from "../../InternationalisationService";
import LocalDataService from "../../services/LocalDataService";
import SubjectService from "../../SubjectService";
import AuthService from "../../services/AuthService";

class LanguageSelection extends Component {
  state = {
    languageOptions: [],
    showLanguageFlags: true,
  };

  constructor(props, context) {
    super(props, context);

    this.state.showLanguageFlags = !(
      Window.configuration.ui.showLanguageFlags === "false"
    );

    InternationalisationService.getLanguages().then((languages) => {
      const languageOptions = [];

      // eslint-disable-next-line
      for (let language of languages) {
        languageOptions.push({
          key: language.code,
          value: language.code,
          text: language.name,
          image: this.state.showLanguageFlags
            ? { src: this.getFlagImageUrl(language.code), alt: language.code }
            : null,
        });
      }

      this.setState({ languageOptions });
    });
  }

  changeLanguage = (event, item) => {
    InternationalisationService.changeLanguage(item.value);
    if (AuthService.getAccountType() === "subject") {
      SubjectService.updateSubjectPreferencesLanguage(item.value);
    }
    if (this.props.callback) {
      this.props.callback();
    }
    LocalDataService.setLanguage(item.value);
    window.location.reload(false);
  };

  render() {
    const { t } = this.props;
    const inline = !!this.props.inline;
    const fluid = !!this.props.fluid;

    const selectedLanguage = InternationalisationService.getLanguage();

    // If only one language the config, don't show the control - render as null
    if (this.state.languageOptions.length === 1) {
      return null;
    }

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {this.state.showLanguageFlags && (
          <Image
            src={this.getFlagImageUrl(selectedLanguage)}
            inline
            size="mini"
            spaced="right"
            alt={selectedLanguage}
          />
        )}
        <Dropdown
          inline={inline}
          selection={!inline}
          fluid={fluid}
          options={this.state.languageOptions}
          value={selectedLanguage}
          onChange={this.changeLanguage}
          placeholder={t("LANGUAGE_SELECTION_PLACEHOLDER")}
        />
      </div>
    );
  }

  getFlagImageUrl(code) {
    if (code) {
      const countryCode = code.substr(code.indexOf("_") + 1);
      return "/images/flags/" + countryCode.toUpperCase() + ".png";
    } else {
      return "/images/flags/_unknown.png";
    }
  }
}

export default withTranslation()(LanguageSelection);
