import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, Grid, Label, Loader } from "semantic-ui-react";
import UserContext from "../../../context/UserContext";
import InternationalisationService from "../../../InternationalisationService";
import DashboardService from "../../../services/DashboardService";
import DateTimeService from "../../../services/DateTimeService";

const SinceLastLoginCard = ({ t }) => {
  const user = useContext(UserContext);

  const [latestQuestionnaires, setLatestQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLatestQuestionnaires = async () => {
    let selectedLanguage = await InternationalisationService.getLanguage();
    const latestQuestionnaires =
      await DashboardService.getSubmittedQuestionnairesSinceLastLogin(
        selectedLanguage,
        user?.profile?.id
      );
    setLatestQuestionnaires(latestQuestionnaires);
    setLoading(false);
  };

  useEffect(() => {
    getLatestQuestionnaires();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header style={{ paddingBottom: "1rem" }}>
          {t("HOME_CARD_LAST_VISIT")}
        </Card.Header>
        {loading && <Loader />}
        <Grid>
          {latestQuestionnaires.length === 0 && (
            <Grid.Row>
              <Grid.Column>
                <p>{t("HOME_CARD_LAST_VISIT_EMPTY")}</p>
              </Grid.Column>
            </Grid.Row>
          )}
          {latestQuestionnaires.map((latestQuestionnaire) => {
            return (
              <Grid.Row>
                <Grid.Column
                  width={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p style={{ wordBreak: "break-word" }}>
                    {DateTimeService.build.asDisplayDate(
                      latestQuestionnaire.questionnaire.completionDate
                    )}
                  </p>
                </Grid.Column>
                <Grid.Column
                  width={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p>{latestQuestionnaire.subjectCode}</p>
                </Grid.Column>
                <Grid.Column
                  width={8}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Link
                    to={`/app/subject/${
                      latestQuestionnaire.subject.Id
                    }/questionnaire-type/${latestQuestionnaire.definition.type.toLowerCase()}/${
                      latestQuestionnaire.definition.code
                    }/view/${latestQuestionnaire.questionnaire.id}`}
                  >
                    <Label color="orange" size="large">
                      {latestQuestionnaire.definition.label}
                    </Label>
                  </Link>
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default withTranslation()(SinceLastLoginCard);
