import React, {useState, useEffect, useMemo, useCallback} from "react";
import { Dropdown, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import InternationalisationService from "../../InternationalisationService";
import {uniqBy} from 'lodash';

const LanguageSelectionGeneric = ({ inline, fluid, callback, language, useMobileLanguages=false, useWebLanguages=true }) => {
  const {t} = useTranslation();

  const [languageOptions, setLanguageOptions] = useState(null);

  const showLanguageFlags = !(Window.configuration.ui.showLanguageFlags === "false");

  const getFlagImageUrl = (code) => {
    if (code) {
      const countryCode = code.substr(code.indexOf("_") + 1);
      return "/images/flags/" + countryCode.toUpperCase() + ".png";
    } else {
      return "/images/flags/_unknown.png";
    }
  }
  const getLanguages = useCallback(async () => {
    const getWebLanguages = useWebLanguages ? InternationalisationService.getLanguages() : new Promise(()=>[])
    const getMobileLanguages = useMobileLanguages ? InternationalisationService.getMobileLanguages() : new Promise(()=>[])

    const [webLanguages, mobileLanguages] = await Promise.all([getWebLanguages, getMobileLanguages]);
    const languageOptions = uniqBy([...webLanguages, ...mobileLanguages], l=>l.code).map((language) => ({
      key: language.code,
      value: language.code,
      text: language.name,
      image: showLanguageFlags
          ? { src: getFlagImageUrl(language.code), alt: language.code }
          : null,
    }));
    setLanguageOptions(languageOptions);
  }, [showLanguageFlags, useMobileLanguages, useWebLanguages])
  useEffect(() => {
    getLanguages();
  }, [getLanguages, showLanguageFlags]);

  const changeLanguage = (event, item) => {
    callback(item.value)
  };

  const hasLoaded = useMemo(()=>{
    return languageOptions != null;
  }, [languageOptions])

  if(!hasLoaded){
    return null;
  }

  return(
      <div style={{ display: "flex", alignItems: "center" }}>
        {showLanguageFlags && (
          <Image
            src={getFlagImageUrl(language)}
            inline
            size="mini"
            spaced="right"
            alt={language}
          />
        )}
        <Dropdown
          inline={inline}
          selection={!inline}
          fluid={fluid}
          options={languageOptions}
          value={language}
          onChange={changeLanguage}
          placeholder={t("LANGUAGE_SELECTION_PLACEHOLDER")}
        />
      </div>
    );

}

export default LanguageSelectionGeneric;
