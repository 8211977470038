import React from "react";

const RadioSelector = (props) => {
  const { isSelected, disabled } = props;

  return (
    <div>
      {!disabled && isSelected && (
        <img
          src="/images/selectors/radioSelected.svg"
          alt="radio selector selected"
          height="20px"
          width="20px"
        />
      )}
      {!disabled && !isSelected && (
        <img
          src="/images/selectors/radioUnselected.svg"
          alt="radio selector unselected"
          height="20px"
          width="20px"
        />
      )}
      {disabled && isSelected && (
        <img
          src="/images/selectors/radioSelectedDisabled.svg"
          alt="radio selector selected disabled"
          height="20px"
          width="20px"
        />
      )}
      {disabled && !isSelected && (
        <img
          src="/images/selectors/radioUnselectedDisabled.svg"
          alt="radio selector unselected disabled"
          height="20px"
          width="20px"
        />
      )}
    </div>
  );
};

export default RadioSelector;
