import QUESTIONNAIRE_COLUMN_KEYS from "../../../constants/QUESTIONNAIRE_COLUMN_KEYS";

export default (columnType, task, hasCustomColumns) => {
  switch(columnType){
    case QUESTIONNAIRE_COLUMN_KEYS.CUSTOM_QUESTIONNAIRE_COLUMNS:
      return hasCustomColumns;
    case QUESTIONNAIRE_COLUMN_KEYS.TOTAL_SCORE:
    case QUESTIONNAIRE_COLUMN_KEYS.CREATION_TRIGGER:
    case QUESTIONNAIRE_COLUMN_KEYS.CREATED_DATE:
    case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_DATE:
    case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_BY:
    case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_LOCATION:
      return !hasCustomColumns;
    case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_SUBMIT_BUTTON:
      return task?.type === "USER";
    default:
      return true;
  }
}