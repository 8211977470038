import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Icon, Message, Segment } from "semantic-ui-react";
import Page from "../../components/page/Page";
import RewardService from "../../services/RewardService";

const ManageRewardsPage = (props) => {
  const { t } = props;

  const fileInputAccept = "text/csv";

  const [codes, setCodes] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const [availableRewards, setAvailableRewards] = useState(0);
  const [unfulfilledRewards, setUnfulfilledRewards] = useState(0);

  const fileInputRef = useRef();

  const refreshRewardCounts = async () => {
    setAvailableRewards(await RewardService.getAvailableRewardCount());
    setUnfulfilledRewards(await RewardService.getUnfulfilledRewardCount());
  };

  useEffect(() => {
    refreshRewardCounts();
  }, []);

  const changeHandler = async (event) => {
    const fileText = await event.target.files[0]?.text();
    const codeLines = fileText.split(/\r?\n/).slice(1);
    const codes = codeLines
      .map((line) => {
        return line.split(",")[1];
      })
      .filter((code) => code && code.length > 0);
    setCodes(codes);
    setIsSelected(true);
  };

  const handleSubmission = async () => {
    try {
      setIsUploading(true);
      await RewardService.uploadRewardCodes(codes);
      refreshRewardCounts();
      setIsUploaded(true);
    } catch (e) {
      console.log(e);
    }
    setIsUploading(false);
  };

  return (
    <Page
      name="MANAGE_REWARDS"
      header={t("MANAGE_REWARDS_HEADER")}
      subheader={t("MANAGE_REWARDS_SUBHEADER")}
    >
      <Segment>
        <Form>
          <p>{t("MANAGE_REWARDS_INFORMATION")}</p>
          {!isUploading && (
            <Message>
              {unfulfilledRewards > 0 ? (
                <>
                  <Icon name={"warning sign"} />
                  {t("MANAGE_REWARDS_UNFULFILLED").replace(
                    "{0}",
                    unfulfilledRewards
                  )}
                </>
              ) : (
                <>
                  <Icon name={availableRewards > 0 ? "check" : "x"} />
                  {t("MANAGE_REWARDS_AVAILABLE").replace(
                    "{0}",
                    availableRewards
                  )}
                </>
              )}
            </Message>
          )}
          {!isUploaded ? (
            <>
              <Form.Field>
                <Button
                  primary={!isSelected}
                  content={
                    !isSelected
                      ? t("MANAGE_REWARDS_CHOOSE_FILE")
                      : t("MANAGE_REWARDS_UPLOAD_READY")
                  }
                  labelPosition="left"
                  icon={"file"}
                  onClick={() => fileInputRef.current.click()}
                />
                <input
                  ref={fileInputRef}
                  type="file"
                  hidden
                  accept={fileInputAccept}
                  onChange={changeHandler}
                />
              </Form.Field>
              {isSelected && (
                <p>
                  {t("MANAGE_REWARDS_FILE_CONTAINS")
                    .replace("{0}", codes.length)
                    .replace("{1}", codes[0])}
                </p>
              )}
              <Form.Field>
                <Button
                  primary
                  content={
                    !isUploading ? t("GLOBAL_BUTTON_SUBMIT", "Submit") : "..."
                  }
                  onClick={handleSubmission}
                />
              </Form.Field>
            </>
          ) : (
            <p>{t("MANAGE_REWARDS_UPLOAD_SUCCESS")}</p>
          )}
        </Form>
      </Segment>
    </Page>
  );
};

export default withTranslation()(ManageRewardsPage);
