import InternationalisationService from "../InternationalisationService";
import RequestHelper from "../RequestHelper";

export default class RoleService {

    static newRole(role, feedback) {
        let feedbackHeader = feedback
            ? { "x-feedback-available": "Adding new Role" }
            : {};
        return RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/roles/new",
            {
                "Accept-Language": InternationalisationService.getLanguage(),
                ...feedbackHeader,
            },
            "POST",
            null,
            { ...role, feedback }
        );
    }

    static updateRole(role, feedback) {
        let feedbackHeader = feedback
            ? { "x-feedback-available": "Editing role " + role.code }
            : {};
        return RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/roles/update",
            {
                "Accept-Language": InternationalisationService.getLanguage(),
                ...feedbackHeader,
            },
            "POST",
            null,
            { ...role, feedback }
        );
    }

    static getRole(roleID) {
        return RequestHelper.send(
            process.env.REACT_APP_SERVER_ADDRESS + "/roles/get/" + roleID,
            {
                "Accept-Language": InternationalisationService.getLanguage(),
            },
            "GET",
            null
        );
    }

}