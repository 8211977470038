import React, { Component } from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import PasswordSetterInput from "./PasswordSetterInput";
import AuthService from "../../services/AuthService";
import UserContext from "../../context/UserContext";
export class PasswordChangeModal extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.rules = Window.configuration.passwords;
    this.state = {
      reason: "",
      currentPassword: "",
      password1: "",
      error: null,
      requestPending: false,
      visible: this.props.show,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.show !== this.props.show) {
      this.setState({ visible: this.props.show });
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ error: null });
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handlePasswordSetterInputChange = (event) => {
    this.setState({ error: null });
  };

  validateForm = () => {
    if (!this.state.currentPassword) {
      this.setState({ error: "PASSWORD_CHANGE_ENTER_CURRENT_PASSWORD" });
    } else if (!this.state.password1) {
      if (!this.state.error) {
        this.setState({ error: "PASSWORD_CHANGE_ENTER_NEW_PASSWORD" });
      }
      // otherwise Error already set from PasswordSetterInput
    } else {
      this.setState({ error: null });
    }
  };

  newPasswordValid = (newPassword) => {
    this.setState({ password1: newPassword }, this.validateForm);
  };
  newPasswordError = (errorState) => {
    this.setState({ password1: null, error: errorState.error });
  };

  onCancel = (event) => {
    this.setState(
      { currentPassword: "", password1: "", error: null },
      this.props.onClose
    );
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { currentPassword, password1 } = this.state;
    this.setState({ error: null, requestPending: true }, () => {
      AuthService.changePassword(currentPassword, password1)
        .then(() => {
          this.setState(
            {
              visible: false,
              reason: "",
              currentPassword: "",
              password1: "",
              error: null,
              requestPending: false,
            },
            () => {
              const { refreshAuthState } = this.context;
              refreshAuthState();
              this.props.onClose();
            }
          );
        })
        .catch((errors) => {
          let errorMessageKey = "ERROR_UNKNOWN";
          let errorMessage = errors.message || (errors[0] && errors[0].message); // Need to deal with response from either REST or GraphQL
          if (errorMessage.indexOf("Password does not match") !== -1) {
            errorMessageKey = "ERROR_PASSWORD_DOES_NOT_MATCH";
          } else if (
            errorMessage.indexOf(
              "Password changed too many times in allowed period"
            ) !== -1
          ) {
            errorMessageKey = "PASSWORD_HAS_BEEN_CHANGED_TOO_RECENTLY";
          }
          this.setState({ error: errorMessageKey, requestPending: false });
        });
    });
  };

  render() {
    const { t } = this.props;

    const isStaff = this.context.accountType === "staff";

    const modalHeaderText = isStaff
      ? t([
          "CHANGE_PASSWORD_MODAL_HEADER_TITLE_STAFF",
          "CHANGE_PASSWORD_MODAL_HEADER_TITLE",
        ])
      : t([
          "CHANGE_PASSWORD_MODAL_HEADER_TITLE_SUBJECT",
          "CHANGE_PASSWORD_MODAL_HEADER_TITLE",
        ]);

    const modalAdditionalInformationText = isStaff
      ? t("CHANGE_PASSWORD_MODAL_ADDITIONAL_TEXT_STAFF", "")
      : t("CHANGE_PASSWORD_MODAL_ADDITIONAL_TEXT_SUBJECT", "");

    return (
      <Modal
        open={this.state.visible}
        onClose={this.onCancel}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
      >
        <Modal.Header>{modalHeaderText}</Modal.Header>
        <Modal.Content>
          {modalAdditionalInformationText && (
            <Message>{modalAdditionalInformationText}</Message>
          )}
          <Form
            onSubmit={this.onSubmit}
            error={this.state.showErrors}
            noValidate
          >
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              name="currentPassword"
              value={this.state.currentPassword}
              placeholder={t("CHANGE_PASSWORD_CURRENT")}
              type="password"
              onChange={this.handleChange}
              disabled={this.state.requestPending}
              autoFocus
            />
            <PasswordSetterInput
              onChange={this.handlePasswordSetterInputChange}
              onValid={this.newPasswordValid}
              onError={this.newPasswordError}
              disabled={this.state.requestPending}
            />

            <Button
              type="submit"
              primary
              disabled={
                this.state.currentPassword.length === 0 ||
                !!this.state.error ||
                this.state.requestPending
              }
            >
              {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
            </Button>
            <Button
              type="button"
              primary
              basic
              onClick={this.onCancel}
              disabled={this.state.requestPending}
            >
              {t("GLOBAL_BUTTON_CANCEL")}
            </Button>

            {this.state.error && (
              <div className="ui negative message">{t(this.state.error)}</div>
            )}
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withTranslation()(PasswordChangeModal);
