import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Card, Label, List } from "semantic-ui-react";
import StaffService from "../../StaffService";

export class StaffSummaryCard extends Component {
  state = {
    staff: [],
    loading: true,
  };

  constructor(props, context) {
    super(props, context);

    this.reloadStaffList();
  }

  reloadStaffList = () => {
    StaffService.listStaff()
      .then((staff) => {
        this.setState({ staff });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { t } = this.props;

    return (
      <Card>
        <Card.Content>
          <Card.Header>{t("HOME_CARD_STAFF")}</Card.Header>
          <Card.Meta>
            <span className="date">{t("HOME_CARD_STAFF_DESCRIPTION")}</span>
          </Card.Meta>
          <Card.Description>
            <List>
              <List.Item key="active">
                <List.Content>
                  <Label horizontal>{t("GENERIC_TERM_STAFF_ACTIVE")}</Label>
                  {
                    this.state.staff.filter(
                      (staff) => staff.state === "ACTIVE" && !staff.superAdmin
                    ).length
                  }
                </List.Content>
              </List.Item>
              <List.Item key="unactivated">
                <List.Content>
                  <Label horizontal>
                    {t("GENERIC_TERM_STAFF_AWAITING_ACTIVATION")}
                  </Label>
                  {
                    this.state.staff.filter(
                      (staff) =>
                        staff.state === "AWAITING_ACTIVATION" &&
                        !staff.superAdmin
                    ).length
                  }
                </List.Content>
              </List.Item>
              <List.Item key="suspended">
                <List.Content>
                  <Label horizontal>{t("GENERIC_TERM_STAFF_SUSPENDED")}</Label>
                  {
                    this.state.staff.filter(
                      (staff) =>
                        staff.state === "SUSPENDED" && !staff.superAdmin
                    ).length
                  }
                </List.Content>
              </List.Item>
            </List>
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

export default withTranslation()(StaffSummaryCard);
