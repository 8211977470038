import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Header, Icon, List, Popup } from "semantic-ui-react";
import NotificationService, {
  NOTIFICATION_PREFERENCE,
} from "../../services/NotificationService";
import { useDispatch, connect } from "react-redux";
import {
  getNotificationPreference,
  getNotifications,
} from "../../redux/notificationsSlice";
import NotificationTime from "./NotificationTime";
import ConfigService from "../../services/ConfigService";

const NotificationIcon = (props) => {
  const { t, notifications, notificationPreference } = props;

  const dispatch = useDispatch();

  const defaultIconStyle = {
    name: "bell outline",
    colour: "#444",
    size: "large",
  };

  const [iconStyle, setIconStyle] = useState(defaultIconStyle);

  const handlePreferenceChange = async (e, data) => {
    const isChecked = data?.checked;
    if (isChecked) {
      await NotificationService.enableBrowserNotifications(dispatch);
    } else {
      await NotificationService.disableBrowserNotifications(dispatch);
    }
  };

  useEffect(() => {
    if (!notifications || notifications.length === 0) {
      setIconStyle(defaultIconStyle);
    } else {
      setIconStyle({
        name: "bell",
        colour: "orange",
        size: "big",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const handleResetNotifications = async () => {
    NotificationService.resetNotifications(dispatch);
  };

  const handleClearAll = async () => {
    NotificationService.deleteAllReceivedNotifications(dispatch);
  };

  const handleClearItem = async (id) => {
    if (!id) {
      return;
    }
    NotificationService.deleteReceivedNotification(dispatch, id);
  };

  if (!NotificationService.showNotificationControls()) {
    return null;
  }

  if (notificationPreference === NOTIFICATION_PREFERENCE.NONE) {
    return null;
  }

  const notificationsToShow = notifications.slice().splice(0, 5);
  const notficationListItems = notificationsToShow
    .map((notification) => {
      return (
        <List.Item key={notification.id}>
          <List.Content floated="right">
            <Icon
              color={"orange"}
              name={"close"}
              aria-label={t("BROWSER_NOTIFICATION_MENU_REMOVE")}
              onClick={() => handleClearItem(notification.id)}
              className={"mouse-pointer"}
            />
          </List.Content>
          <List.Icon
            name="bell outline"
            color="orange"
            verticalAlign="middle"
          />
          <List.Content>
            <List.Header>{notification.title}</List.Header>
            <List.Description>{notification.body}</List.Description>
          </List.Content>
        </List.Item>
      );
    })
    .reverse();

  const showPreferredNotificationTime =
    ConfigService.showPreferredNotificationTime();

  return (
    <>
      <Popup
        wide
        trigger={
          <Icon.Group>
            <Icon
              color={iconStyle.colour}
              size={iconStyle.size}
              name={iconStyle.name}
              aria-label={t("BROWSER_NOTIFICATION_MENU_ICON_ACCESSIBILITYHINT")}
              className={"mouse-pointer"}
              style={{ marginRight: 0 }}
            />
            {notifications.length > 0 && (
              <span style={styles.notificationNumber}>
                {notifications.length}
              </span>
            )}
          </Icon.Group>
        }
        content={
          <>
            <Header size="medium">
              {t("BROWSER_NOTIFICATION_MENU_TITLE")}
            </Header>
            {notificationPreference === NOTIFICATION_PREFERENCE.BLOCKED && (
              <>
                <div className="ui divider"></div>
                {t("BROWSER_NOTIFICATION_MENU_BLOCKED")}
                <Button
                  basic
                  color="orange"
                  style={{ marginTop: 10 }}
                  content={t("BROWSER_NOTIFICATION_MENU_BLOCKED_CLEAR")}
                  onClick={handleResetNotifications}
                />
              </>
            )}
            {notificationPreference === NOTIFICATION_PREFERENCE.ALLOWED && (
              <>
                <div className="ui divider"></div>
                {notficationListItems.length === 0 &&
                  t("BROWSER_NOTIFICATION_MENU_NONE")}
                {notficationListItems.length > 0 && (
                  <>
                    <List divided relaxed>
                      {notficationListItems}
                    </List>
                    {notificationsToShow.length < notifications.length && (
                      <>{t("BROWSER_NOTIFICATION_MENU_MORE")}</>
                    )}
                    <div className="ui divider"></div>
                    <Button
                      basic
                      color="orange"
                      content={t("BROWSER_NOTIFICATION_MENU_CLEAR")}
                      onClick={handleClearAll}
                    />
                  </>
                )}
              </>
            )}
            {showPreferredNotificationTime &&
              notificationPreference === NOTIFICATION_PREFERENCE.ALLOWED && (
                <>
                  <div className="ui divider"></div>
                  {t("NOTIFICATION_TIME_SELECT_TEXT")}
                  <NotificationTime style={{ marginTop: 10 }} />
                </>
              )}
            <>
              {notificationPreference === NOTIFICATION_PREFERENCE.DECLINED && (
                <>
                  <div className="ui divider"></div>
                  {t("BROWSER_NOTIFICATION_MENU_DECLINED")}
                </>
              )}
              {notificationPreference === NOTIFICATION_PREFERENCE.ALLOWED && (
                <>
                  <div className="ui divider"></div>
                  {t("BROWSER_NOTIFICATION_MENU_ALLOWED")}
                </>
              )}
              {notificationPreference !== NOTIFICATION_PREFERENCE.BLOCKED && (
                <>
                  <br />
                  <Checkbox
                    style={{ marginTop: 10 }}
                    toggle
                    checked={
                      notificationPreference === NOTIFICATION_PREFERENCE.ALLOWED
                        ? true
                        : false
                    }
                    onChange={handlePreferenceChange}
                  />
                </>
              )}
            </>
          </>
        }
        on="click"
        position="top right"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    notifications: getNotifications(state) || [],
    notificationPreference:
      getNotificationPreference(state) || NOTIFICATION_PREFERENCE.NONE,
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

const styles = {
  notificationNumber: {
    fontSize: "1rem",
    fontStyle: "normal",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    textAlign: "center",
    color: "white",
  },
};

export default enhance(NotificationIcon);
