import moment from "moment";

export const addPeriod = (momentDate, periodString) => {
    switch (periodString) {
        case 'day':
            return momentDate.clone().add(0, 'd')
        case 'week':
            return momentDate.clone().add(7, 'd')
        case 'fortnight':
            return momentDate.clone().add(14, 'd')
        case 'month':
        default:
            return momentDate.clone().add(1, 'month')

    }

}

export const ensureXAxisShows = (data, fromDate, toDate) => {
    const dataCopy = data.slice()
    if (dataCopy.length === 0 || fromDate.isBefore(moment(dataCopy[0].x))) {
        dataCopy.unshift({x: fromDate, y: null})
    }
    if (dataCopy.length <= 1 || moment(dataCopy[dataCopy.length - 1].x).isBefore(toDate)) {
        dataCopy.push({x: toDate, y: null})
    }
    return dataCopy
}