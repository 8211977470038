import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, Grid, Label, Loader } from "semantic-ui-react";
import SubjectService from "../../../SubjectService";
import { parse, eval as evaluate } from "expression-eval";
import ConfigContext from "../../../context/ConfigContext";
import InternationalisationService from "../../../InternationalisationService";

const MAX_SUBJECT_FETCH = 200;// todo this breaks everything 10000;

const SubjectSummaryCard = (props) => {
  const { t } = props;
  const [subjects, setSubjects] = useState([]);
  const [groupSubjects, setGroupSubjects] = useState([]);
  const [labelMap, setLabelMap] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(true);

  const config = useContext(ConfigContext);
  const cardConfig = config.ui.dashboard.cards.subjects;

  const filters = cardConfig.filters || [];

  useEffect(() => {
    reloadSubjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadSubjectList = async () => {
    if (!loading) {
      setLoading(true);
    }

    let selectedLanguage = await InternationalisationService.getLanguage();

    let subjects = await SubjectService.listSubjects(
      null,
      null,
      null,
      null,
      null,
      MAX_SUBJECT_FETCH
    );
    subjects.pageSubjects = subjects.pageSubjects.filter(
      (s) => s.endDate == null
    );

    const labelMap = {};
    const groupSubjects = { all: {} };
    subjects.pageSubjects.forEach((subject) => {
      const answerMap = {};
      let didSubjectPassEvaluation = {};

      subject.subjectRecords.forEach((sR) => {
        const prefix = sR.definition.code;
        Object.entries(sR.answers).forEach(([key, value]) => {
          if (key.includes(prefix + "_")) {
            answerMap[key] = value;
          } else {
            answerMap[prefix + "_" + key] = value;
          }
        });
      });

      const context = {
        subject: {
          data: {
            ...answerMap,
          },
        },
      };

      filters.forEach((config) => {
        const ast = parse(config.condition);
        const result = evaluate(ast, context);

        if (!(config.code in groupSubjects["all"])) {
          groupSubjects["all"][config.code] = [];
        }

        if (result) {
          groupSubjects["all"][config.code].push(subject);
        }
        didSubjectPassEvaluation[config.code] = result;
      });

      subject.groups.forEach((group) => {
        if (!(group.code in labelMap)) {
          labelMap[group.code] = group.label;
        }

        if (!(group.code in groupSubjects)) {
          groupSubjects[group.code] = { all: [] };
          filters.forEach((config) => {
            if (!(config.code in labelMap)) {
              labelMap[config.code] = config.label[selectedLanguage];
            }
            groupSubjects[group.code][config.code] = [];
          });
        }

        filters.forEach((config) => {
          if (didSubjectPassEvaluation[config.code]) {
            groupSubjects[group.code][config.code].push(subject);
          }
        });

        groupSubjects[group.code].all.push(subject);
      });
    });

    await Promise.all([
      setGroupSubjects(groupSubjects),
      setLabelMap(labelMap),
      setSubjects(subjects?.pageSubjects),
      setSelectedLanguage(selectedLanguage),
    ]);

    setLoading(false);
  };

  let columnWidth = Math.floor(12 / (filters.length + 1));
  if (columnWidth === 0) {
    columnWidth = 1;
  }

  let maxWidth = undefined;
  switch (columnWidth) {
    case 2:
      maxWidth = "800px";
      break;
    case 3:
      maxWidth = "700px";
      break;
    case 4:
      maxWidth = "600px";
      break;
    case 6:
      maxWidth = "500px";
      break;
    case 8:
      maxWidth = "400px";
      break;
    default:
      break;
  }

  return (
    <Card
      fluid
      style={{
        maxWidth: maxWidth,
      }}
    >
      <Card.Content>
        <Card.Header>{t("HOME_CARD_SUBJECTS")}</Card.Header>
        <Card.Meta>
          <span className="date">{t("HOME_CARD_SUBJECTS_DESCRIPTION")}</span>
        </Card.Meta>
        {loading && <Loader />}
        {!loading && (
          <Grid padded>
            <Grid.Row>
              <Grid.Column width={4}></Grid.Column>
              <Grid.Column width={columnWidth}>
                {cardConfig.label && cardConfig.label[selectedLanguage]}
              </Grid.Column>
              {filters.map((config) => {
                return (
                  <Grid.Column
                    style={{ display: "flex", justifyContent: "center" }}
                    width={columnWidth}
                  >
                    <p>{labelMap[config.code]}</p>
                  </Grid.Column>
                );
              })}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <p style={{ wordBreak: "break-word" }}>
                  {t("GENERIC_TERM_TOTAL")}
                </p>
              </Grid.Column>
              <Grid.Column
                style={{ display: "flex", justifyContent: "center" }}
                width={columnWidth}
              >
                <div>
                  <Label size="large">{subjects.length}</Label>
                </div>
              </Grid.Column>
              {Object.entries(groupSubjects.all).map(([k, v]) => {
                return (
                  <Grid.Column
                    style={{ display: "flex", justifyContent: "center" }}
                    width={columnWidth}
                  >
                    <div>
                      <Label size="large">{v.length}</Label>
                    </div>
                  </Grid.Column>
                );
              })}
            </Grid.Row>
            {Object.entries(groupSubjects)
              .sort(([ak, av], [bk, bv]) => {
                if (ak === "all") return -1;
                return labelMap[ak].localeCompare(labelMap[bk]);
              })
              .map(([k, v]) => {
                if (k === "all") {
                  return null;
                }
                return (
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <p style={{ wordBreak: "break-word" }}>{labelMap[k]}</p>
                    </Grid.Column>
                    <Grid.Column
                      style={{ display: "flex", justifyContent: "center" }}
                      width={columnWidth}
                    >
                      <div>
                        <Label size="large">{v.all.length}</Label>
                      </div>
                    </Grid.Column>
                    {Object.entries(v).map(([k1, v1]) => {
                      if (k1 === "all") {
                        return null;
                      }
                      return (
                        <Grid.Column
                          style={{ display: "flex", justifyContent: "center" }}
                          width={columnWidth}
                        >
                          <div>
                            <Label size="large">{v1.length}</Label>
                          </div>
                        </Grid.Column>
                      );
                    })}
                  </Grid.Row>
                );
              })}
          </Grid>
        )}
      </Card.Content>
    </Card>
  );
};

export default withTranslation()(SubjectSummaryCard);
